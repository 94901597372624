'use strict';

angular.module('bringgApp').factory('Announcement', function ($resource, WEB_API_URL) {
	var Announcement = $resource(
		WEB_API_URL + '/announcement/',
		{},
		{
			create_for_employee: {
				method: 'PUT',
				params: { user_id: '@user_id' },
				url: WEB_API_URL + '/announcement/user/:user_id'
			}
		}
	);

	return Announcement;
});
