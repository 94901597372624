'use strict';

angular
	.module('bringgApp')
	.directive(
		'kfcTaskInvoice',
		function (
			$translate,
			$timeout,
			$q,
			MerchantService,
			Teams,
			MerchantConfigurations,
			TimeManagerService,
			bringg_utils
		) {
			_.mixin({
				get: function (object, key, defaultValue) {
					if (!object) return defaultValue;

					return _.has(object, key) ? object[key] : defaultValue;
				}
			});

			return {
				restrict: 'E',
				scope: {
					tasks: '=',
					config: '=',
					printedCopies: '=',
					printCallback: '='
				},
				link: function (scope, element, attrs) {
					var teamIds = _.chain(scope.tasks).map('team_ids').flatten().uniq().value();

					$q.all([MerchantService.get(), Teams.getByIds(teamIds)]).then(function (results) {
						var merchant = results[0];
						scope.merchant_name = merchant.name;

						$timeout(scope.printCallback, 100, true, element);
					});

					scope.getTeamName = function (task) {
						if (task && task.team_ids) {
							var team = Teams.teamsByIdMap[_.first(task.team_ids)];
							return team && team.name;
						}
					};

					/*
         Generates ges survey code for task

         1. Part - 5 digits
             1. Store Id - 005
             2. Day - 19
         2. Part - 5 digits
             1. Month - 06
             2. Year - 17
             3. Prefix of Pos - 1
         3. Part - 5 digits
             1. Prefix of Pos - 718
             2. Pos code - 21
         */
					scope.getGESSurveyCode = function (task) {
						var storeIdToTeamIdMapping =
							bringg_utils.getValueFromApplicationMerchantConfiguration('team_mapping');

						var storeId = _.findKey(storeIdToTeamIdMapping, function (teamId) {
							return _.includes(task.team_ids, teamId);
						});

						var posCode = _.get(task.extras, 'pos', '');

						var gesSurveyCode = storeId;
						gesSurveyCode += TimeManagerService.format(task.created_at, 'DD-MMYY');
						gesSurveyCode += '1-718' + posCode;

						return gesSurveyCode;
					};
				},
				templateUrl: 'scripts/features/print/invoices/kfc-task-invoice.html'
			};
		}
	);
