'use strict';

angular.module('bringgApp').factory('InventoryManagement', function ($resource, WEB_API_URL) {
	return $resource(
		WEB_API_URL + '/inventory_management/',
		{},
		{
			all: { method: 'GET', url: WEB_API_URL + '/inventory_management/' }
		}
	);
});
