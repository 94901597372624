'use strict';
/* global google:false */
angular
	.module('bringgApp')
	.controller('AddVehicleController', function (
		$scope,
		$uibModalInstance,
		teamId,
		toastr,
		$translate,
		MerchantConfigurations,
		$rootScope,
		VehiclesService,
		VehicleTypesService
	) {
		$scope.merchantConfigurations = MerchantConfigurations;
		$scope.vehicle = {};
		$scope.sending = false;
		$scope.isTrailer = false;

		$scope.close = function () {
			$uibModalInstance.dismiss();
		};

		$scope.save = function () {
			$scope.sending = true;
			$scope.vehicle.team_id = teamId;

			if (!$scope.isTrailer) {
				$scope.vehicle.truck_id = null;
			}

			VehiclesService.create($scope.vehicle)
				.then(function (vehicle) {
					$rootScope.$broadcast('newVehicleAdded', vehicle);
					$uibModalInstance.close();
					toastr.success($translate.instant('VEHICLES.VEHICLE_ADDED_SUCCESSFULLY'));
					$scope.updateTrailerIfNeeded(vehicle.id);
				})
				.catch(function (error) {
					$scope.sending = false;
					toastr.error(error, $translate.instant('VEHICLES.VEHICLE_ADD_FAILED'));
				});
		};

		$scope.updateTrailerIfNeeded = function (truckId) {
			if ($scope.shouldUpdateTrailer()) {
				VehiclesService.update($scope.vehicle.trailer_id, { truck_id: truckId });
			}
		};

		$scope.shouldUpdateTrailer = function () {
			return !$scope.isTrailer && $scope.vehicle.trailer_id;
		};

		$scope.onTypeChanged = function () {
			var vehicleType = _.find($scope.vehicleTypes, function (vehicleType) {
				return vehicleType.id === $scope.vehicle.vehicle_type_id;
			});
			$scope.isTrailer = vehicleType.is_trailer;
		};

		$scope._init = function () {
			VehiclesService.getAll().then(function (vehicles) {
				var patitions = _.chain(vehicles)
					.filter(function (vehicle) {
						return vehicle.team_id === teamId;
					})
					.partition(function (vehicle) {
						return vehicle.is_trailer;
					})
					.value();

				$scope.trailers = patitions[0];
				$scope.trucks = patitions[1];
			});

			VehicleTypesService.getAll().then(function (vehicleTypes) {
				$scope.vehicleTypes = vehicleTypes;
			});
		};

		$scope._init();
	});
