/*global google:false */

'use strict';

angular.module('bringgApp').factory('TimeToBaseService', function (Employees, Teams, TravelTimeService, $q) {
	var getBase = function (employee, merchantConfiguration) {
		var teamsPromise = $q.resolve();
		if (merchantConfiguration.enable_teams) {
			teamsPromise = Teams.byUser(employee);
		}

		return teamsPromise.then(function (teams) {
			teams = teams || [];

			for (var i = 0; i !== teams.length; i++) {
				if (teams[i].lat && teams[i].lng) {
					employee.base = 'team ' + teams[i].name + ' base';
					return teams[i];
				}
			}
			employee.base = 'merchant base';
			return merchantConfiguration;
		});
	};

	return {
		convertActivity: function (activity) {
			switch (activity) {
				case 1: //not moving
					return google.maps.TravelMode.DRIVING;
				case 2: //walking
				case 3: //running
					return google.maps.TravelMode.WALKING;
				case 4: // bicycle
					return google.maps.TravelMode.BICYCLING;
				case 0: //unknown
				case 5: // driving
				default:
					return google.maps.TravelMode.DRIVING;
			}
		},

		updateTimeToBase: function (employee, merchantConfiguration, travelMode) {
			var startLat, startLng, startTime;
			if (!Employees.isFree(employee) || !employee.lat || !employee.lng) {
				employee.time_to_base = null;
				return;
			}

			startLat = employee.lat;
			startLng = employee.lng;

			return getBase(employee, merchantConfiguration).then(function (base) {
				return TravelTimeService.calculateWithCache(startLat, startLng, base.lat, base.lng, {
					travelMode: travelMode
				}).then(
					function (seconds) {
						employee.time_to_base = moment().add(seconds, 'seconds');
					},
					function () {
						employee.time_to_base = null;
					}
				);
			});
		},

		removeTimeToBase: function (employee) {
			employee.time_to_base = null;
		},

		// expose for tests
		__getBase: getBase
	};
});
