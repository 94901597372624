'use strict';

angular.module('bringgApp').factory('AddMapOverlayService', function ($q, $uibModal) {
	var AddMapOverlayService = {};
	AddMapOverlayService.open = function () {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'AddMapOverlayController',
			templateUrl: 'scripts/features/configuration/map_overlays/add-map-overlay.html'
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject(reason);
			}
		);

		return deferred.promise;
	};

	return AddMapOverlayService;
});
