/*global $:false */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('lateTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			return $.map(taskList, function (task) {
				return task.late ? true : null;
			});
		};
	})
	.filter('activeTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			return $.map(taskList, function (task) {
				if ([TASK_STATUS.DONE, TASK_STATUS.CANCELLED].indexOf(task.status) === -1) {
					return task;
				}
			});
		};
	})
	.filter('assignedTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			return $.map(taskList, function (task) {
				if (task.status !== TASK_STATUS.DONE) {
					return task;
				}
			});
		};
	})
	.filter('unassignedTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			return $.map(taskList, function (task) {
				if (task.status === TASK_STATUS.CREATED) {
					return task;
				}
			});
		};
	})
	.filter('unacceptedTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			return $.map(taskList, function (task) {
				if (task.status === TASK_STATUS.ASSIGNED) {
					return task;
				}
			});
		};
	})
	.filter('startedTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			$.map(taskList, function (task) {
				if (
					[TASK_STATUS.CREATED, TASK_STATUS.ACCEPTED, TASK_STATUS.ASSIGNED, TASK_STATUS.DONE].indexOf(
						task.status
					) === -1
				) {
					return task;
				}
			});
		};
	})
	.filter('doneTasks', function (TASK_STATUS) {
		return function (taskList) {
			if (!taskList) {
				return [];
			}
			$.map(taskList, function (task) {
				if (task.status === TASK_STATUS.DONE) {
					return task;
				}
			});
		};
	})
	.filter('statusString', function (TASK_STATUS, bringg_utils) {
		return function (taskStatus) {
			return bringg_utils.getStatusFromTask({ status: taskStatus });
		};
	})
	.filter('cancelReasonString', function (CancelTaskService) {
		return function (reasonId) {
			if (!reasonId) {
				return '';
			}

			var reasonsData = CancelTaskService._reasons;

			if (_.isUndefined(CancelTaskService._reasons) || _.isUndefined(reasonsData)) {
				return reasonId;
			}

			var reasonData = _.find(reasonsData, { id: reasonId * 1.0 });
			if (_.isUndefined(reasonData)) {
				return reasonId;
			}
			return reasonData.reason;
		};
	});
