'use strict';

angular
	.module('bringgApp')
	.controller('ManagerActionAuthorizationController', function (
		$scope,
		actionDescription,
		$uibModalInstance,
		validation
	) {
		$scope.actionDescription = actionDescription;
		$scope.validation = new RegExp(validation);

		$scope.authorize = function () {
			$uibModalInstance.close(true);
		};

		$scope.close = function () {
			$uibModalInstance.dismiss();
		};
	});
