'use strict';

angular.module('bringgApp').factory('Shift', function ($resource, WEB_API_URL) {
	var Shift = $resource(
		WEB_API_URL + '/users/:user_id/shifts',
		{ user_id: '@user_id' },
		{
			all: { method: 'GET', isArray: true, url: WEB_API_URL + '/users/:user_id/shifts' },
			total: { method: 'GET', url: WEB_API_URL + '/users/:id/shifts/count' }
		}
	);

	return Shift;
});
