'use strict';

angular
	.module('bringgApp')
	.controller(
		'TimeToBaseController',
		function (
			$scope,
			Employees,
			DriverConfigurations,
			TimeToBaseService,
			Tasks,
			Authentication,
			localStorageService,
			$q,
			$log,
			TimeManagerService,
			$rootScope,
			ALL_TEAMS_CHOICE_ID,
			Application,
			Teams
		) {
			var HIDE_ETA_TO_HOME_FOR_BUSY_DRIVERS = 'hideEtaToHomeForBusyDrivers';
			var ETA_TO_HOME_USE_DURATION = 'etaToHomeUseDuration';
			var localStorageHideEtaToHomeForBusyDrivers;
			var appMerchantConfHideEtaToHomeForBusyDrivers;

			function shouldShowEmployee(employee) {
				if (!Employees.isOnShift(employee)) {
					return false;
				}

				if ($scope.realtimeEtaEnabled) {
					if (_.isNil($scope.hideEtaToHomeForBusyDrivers)) {
						$scope.hideEtaToHomeForBusyDrivers =
							localStorageHideEtaToHomeForBusyDrivers ||
							appMerchantConfHideEtaToHomeForBusyDrivers ||
							false;
					}
					return employee.eta_to_home &&
						(($scope.hideEtaToHomeForBusyDrivers && Employees.isFree(employee)) ||
							!$scope.hideEtaToHomeForBusyDrivers)
						? true
						: false;
				} else {
					return Employees.isFree(employee);
				}
			}

			function shouldEstimateTimeToBase(employee) {
				return (
					Employees.hasLocation(employee) &&
					(_.isEmpty(Tasks.openForUser(employee.id)) || $scope.realtimeEtaEnabled)
				);
			}

			function filterByTeam(employee) {
				if (!$scope.selectedTeam || $scope.selectedTeam.id === ALL_TEAMS_CHOICE_ID) {
					return true;
				}

				return _.includes(employee.team_ids, $scope.selectedTeam.id);
			}

			function isEmployeeFilteredIn(employee) {
				if (
					_.isEmpty($scope.selectedTeamsIds) &&
					!($scope.featureFlags && $scope.featureFlags.empty_filtering)
				) {
					return true;
				}

				var selectedTeamsWithChildren = Teams.getTeamIdsWithChildIds($scope.selectedTeamsIds);

				return _.intersection(selectedTeamsWithChildren, employee.team_ids).length !== 0;
			}

			/**
			 * update all employees Eta
			 */
			$scope._updateAllEmployeesEta = function () {
				$scope.employees = _.chain($scope.baseEmployees)
					.filter(filterByTeam)
					.filter(isEmployeeFilteredIn)
					.filter(shouldShowEmployee)
					.each($scope.updateTimeToBase)
					.sortBy('time_to_base')
					.value();
			};

			$scope.$watch('selectedTeam', function (newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}
				$scope._updateAllEmployeesEta();
			});

			$scope.$watch('selectedTeamsIds', function (newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}
				$scope._updateAllEmployeesEta();
			});

			var _onNewEmployeeUpdate = function (employee) {
				var logPrefix =
					'TimeToBaseController._onNewEmployeeUpdate employee [' +
					employee.id +
					'] sub [' +
					employee.sub +
					'] shift_id [' +
					employee.active_shift_id +
					'] ';
				if (shouldShowEmployee(employee) && isEmployeeFilteredIn(employee)) {
					$log.info(logPrefix + 'showing employee');
					addEmployee(employee, $scope.employees);
					addEmployee(employee, $scope.baseEmployees);

					if (shouldEstimateTimeToBase(employee)) {
						$log.info(logPrefix + 'estimating time to base');
						$scope.updateTimeToBase(employee);
					}
				}
			};

			var _onExistingEmployeeUpdate = function (employeeIdx, employee) {
				var logPrefix =
					'TimeToBaseController._onExistingEmployeeUpdate employee [' +
					employee.id +
					'] sub [' +
					employee.sub +
					'] shift_id [' +
					employee.active_shift_id +
					'] ';

				// should we remove him?
				if (!(shouldShowEmployee(employee) && isEmployeeFilteredIn(employee))) {
					$log.info(logPrefix + ' removing from eta to base list');
					TimeToBaseService.removeTimeToBase($scope.employees[employeeIdx]);
					$scope.employees.splice(employeeIdx, 1);
					return;
				}

				// We should show him, update is eta.
				if (shouldEstimateTimeToBase(employee)) {
					$log.info(logPrefix + ' updating time to base');
					$scope.updateTimeToBase($scope.employees[employeeIdx]);
				}
			};

			$scope.handleEmployeeUpdate = function (evt, employee) {
				var employeeIdx = _.findIndex($scope.employees, { id: employee.id });

				if (employeeIdx !== -1) {
					_onExistingEmployeeUpdate(employeeIdx, employee);
				} else {
					_onNewEmployeeUpdate(employee);
				}
			};

			/**
			 * used only when feature flag is on
			 * @param employee
			 */
			var updateEmployeeTimeToBase = function (employee) {
				var logPrefix =
					'TimeToBaseController.updateEmployeeTimeToBase employee [' +
					employee.id +
					'] sub [' +
					employee.sub +
					'] eta_to_home [' +
					employee.eta_to_home +
					'] ';

				if (employee.eta_to_home) {
					$log.info(logPrefix + ' setting time to base');
					employee.time_to_base = moment(employee.eta_to_home);
				} else {
					$log.info(logPrefix + ' removing time to base');
					employee.time_to_base = null;
				}

				return employee;
			};

			$scope.updateTimeToBase = function (employee) {
				return updateEmployeeTimeToBase(employee);
			};

			$scope.handleEmployeeDeletion = function (evt, employee) {
				removeEmployee(employee, $scope.employees);
				removeEmployee(employee, $scope.baseEmployees);
			};

			function removeEmployee(employee, employees) {
				var employeeIdx = find_in_array(employees, employee.id);
				if (employeeIdx !== -1) {
					employees.splice(employeeIdx, 1);
				}
			}

			function addEmployee(employee, employees) {
				var employeeIdx = find_in_array(employees, employee.id);
				if (employeeIdx === -1) {
					employees.push(employee);
				}
			}

			$scope.handleMinimizeClick = function () {
				$scope.minimized = !$scope.minimized;
				$rootScope.$broadcast('minimize time to base', $scope.minimized);
			};

			/**
			 * store/load duration/hour based eta
			 */
			var loadPageConfigFromLocalStorage = function () {
				$scope.eta_use_duration = localStorageService.get(ETA_TO_HOME_USE_DURATION) || false;
				localStorageHideEtaToHomeForBusyDrivers = localStorageService.get(HIDE_ETA_TO_HOME_FOR_BUSY_DRIVERS);
			};

			var storeEtaFormat = function () {
				localStorageService.set(ETA_TO_HOME_USE_DURATION, $scope.eta_use_duration);
			};

			var storeHideEtaToHomeForBusyDrivers = function () {
				localStorageService.set(HIDE_ETA_TO_HOME_FOR_BUSY_DRIVERS, $scope.hideEtaToHomeForBusyDrivers);
			};

			$scope.setEtaDurationFormat = function () {
				$scope.eta_use_duration = true;
				storeEtaFormat();
				$scope._updateAllEmployeesEta();
			};

			$scope.setEta24hFormat = function () {
				$scope.eta_use_duration = false;
				storeEtaFormat();
				$scope._updateAllEmployeesEta();
			};

			$scope.onEtaToHomeChange = function () {
				$scope.hideEtaToHomeForBusyDrivers = !$scope.hideEtaToHomeForBusyDrivers;
				storeHideEtaToHomeForBusyDrivers();
				$scope._updateAllEmployeesEta();
			};

			$scope.setHideEtaToHomeForBusyDrivers = function () {
				$scope.hideEtaToHomeForBusyDrivers = !$scope.hideEtaToHomeForBusyDrivers;
			};

			var getRealTimeAppConfig = () =>
				Application.getConfigByAppUuid(Application.APPLICATIONS.RealtimeEta)
					.then(config => (_.isNil(config) ? null : config.data))
					.catch(_.noop);
			/**
			 * init function
			 */
			var init = function () {
				Application.isInstalledByUuid(Application.APPLICATIONS.RealtimeEta).then(function (installed) {
					$scope.realtimeEtaEnabled = installed;
				});

				loadPageConfigFromLocalStorage();
				$scope.minimized = false;

				$q.all([
					DriverConfigurations.get(),
					Employees.drivers(),
					Authentication.featureFlags(),
					getRealTimeAppConfig()
				]).then(function (results) {
					var driverConfiguration = results[0];
					$scope.baseEmployees = results[1];
					$scope.featureFlags = results[2];
					var appMerchantConf = results[3];
					appMerchantConfHideEtaToHomeForBusyDrivers = _.isNil(appMerchantConf)
						? null
						: appMerchantConf.hideEtaToHomeForBusyDrivers;

					$scope.defaultActivity = TimeToBaseService.convertActivity(
						driverConfiguration.default_user_activity
					);

					$scope._updateAllEmployeesEta();

					$scope.$on('employeeUpdated', $scope.handleEmployeeUpdate);
					$scope.$on('employeeDeleted', $scope.handleEmployeeDeletion);
				});
			};

			init();
		}
	)
	.filter('timeToBaseFormatter', function ($translate, TimezoneService, TimeManagerService) {
		function formatEta(eta, eta_use_duration, employee) {
			if (!eta) {
				return 'N/A';
			}

			if (moment().isAfter(eta)) {
				return $translate.instant('TIME_TO_BASE.VERY_CLOSE');
			}

			eta = TimezoneService.getDateByTask(employee, eta);

			return eta_use_duration ? eta.toNow(true) : TimeManagerService.format(eta, 'HH:mm A');
		}

		formatEta.$stateful = true;
		return formatEta;
	});
