'use strict';

angular
	.module('bringgApp')
	.directive('deliveryTimeSummary', function ($translate, TimeManagerService, OPTIMIZATION_TYPES) {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				selectedDriversByTeam: '=',
				selectedTasks: '=',
				optimizationType: '=',
				result: '=',
				isAnyServiceArea: '='
			},
			templateUrl: 'scripts/features/optimize/steps/delivery-time-summary.html',
			link: function (scope) {
				scope.getDisplayedDeliveryTime = function () {
					switch (scope.result.optimize_for) {
						case 'all_open_tasks':
							return $translate.instant('OPTIMIZE_SELECTED_ROUTE.AUTOMATIC');
						case 'today':
							return $translate.instant('OPTIMIZE_SELECTED_ROUTE.TODAY');
						case 'tomorrow':
							return $translate.instant('OPTIMIZE_SELECTED_ROUTE.TOMORROW');
						case 'next_shift':
							return $translate.instant('OPTIMIZE_SELECTED_ROUTE.NEXT_SHIFT');
						default:
							// custom
							return (
								TimeManagerService.format(scope.result.scheduled_date, 'YYYY-MM-DD') +
								', ' +
								TimeManagerService.format(scope.result.ui_period_times.start_period, 'HH:mm') +
								'-' +
								TimeManagerService.format(scope.result.ui_period_times.end_period, 'HH:mm')
							);
					}
				};

				scope.getTotalNumOfDrivers = function () {
					var totalDrivers = 0;
					_.each(scope.selectedDriversByTeam, function (team) {
						totalDrivers += team.drivers.length;
					});
					return totalDrivers;
				};

				scope.getWarningMessage = function () {
					if (scope.optimizationType === OPTIMIZATION_TYPES.ANONYMOUS && scope.isAnyServiceArea) {
						return $translate.instant('OPTIMIZE_SELECTED_ROUTE.SERVICE_AREA_NOT_SUPPORTED_MESSAGE');
					}

					return '';
				};
			}
		};
	});
