'use strict';

angular
	.module('bringgApp')
	.controller('DispatcherAlertSubscriptionsController', function (
		$scope,
		DispatcherAlertSubscription,
		Authentication,
		toastr,
		$translate,
		DISPATCHER_ALERTS_TYPES,
		DISPATCHER_ALERT_SUBSCRIPTION_TYPE
	) {
		$scope.select2Options = {
			multiple: true,
			simple_tags: true,
			tags: []
		};

		$scope.DISPATCHER_ALERTS_TYPES = DISPATCHER_ALERTS_TYPES;

		$scope.subscriptions_by_alert_type = {};
		$scope.subscriptions_by_alert_type[
			$scope.DISPATCHER_ALERTS_TYPES.order_not_accepted_x_minutes_after_creation
		] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[
			$scope.DISPATCHER_ALERTS_TYPES.order_not_started_x_minutes_after_creation
		] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[
			$scope.DISPATCHER_ALERTS_TYPES.order_not_started_x_minutes_before_schedule_time
		] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.customer_last_order_more_than_x_days_ago] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.customer_open_app_and_not_create_order] = {
			screen: false,
			phones: [],
			emails: []
		};

		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_leaving_route] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_long_time_on_site] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_ended_shift_with_orders] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_scans_outside_way_point_geofence] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_checkin_far_from_location] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.driver_ended_shift_prematurely] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[
			$scope.DISPATCHER_ALERTS_TYPES.order_not_assigned_x_minutes_before_schedule_time
		] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[
			$scope.DISPATCHER_ALERTS_TYPES.order_not_accepted_x_minutes_before_schedule_time
		] = {
			screen: false,
			phones: [],
			emails: []
		};
		$scope.subscriptions_by_alert_type[$scope.DISPATCHER_ALERTS_TYPES.failed_to_create_fleet_delivery] = {
			screen: false,
			phones: [],
			emails: []
		};

		$scope.headers = [
			['alert', $translate.instant('DISPATCHER_ALERT_SETTINGS.ALERT')],
			['screen', $translate.instant('DISPATCHER_ALERT_SETTINGS.SHOW_ON_ALERT_SCREEN')],
			['phones', $translate.instant('DISPATCHER_ALERT_SETTINGS.REAL_TIME_SMS')],
			['emails', $translate.instant('DISPATCHER_ALERT_SETTINGS.REAL_TIME_EMAIL')]
		];

		$scope.sort = {
			column: 'alert',
			descending: true,
			index: 0
		};

		$scope.selectedCls = function (columnIdx) {
			if (columnIdx === $scope.sort.index) {
				if ($scope.sort.descending) {
					return '&#9660;';
				} else {
					return '&#9650;';
				}
			}
		};

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.headers[columnIdx][0]) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.headers[columnIdx][0];
				sort.descending = false;
				sort.index = columnIdx;
			}
		};

		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			if (isLoggedIn) {
				DispatcherAlertSubscription.all(function (subscriptions) {
					subscriptions.forEach(function (subscription) {
						if ($scope.subscriptions_by_alert_type[subscription.alert_type.toString()]) {
							if (subscription.subscription_type === DISPATCHER_ALERT_SUBSCRIPTION_TYPE.screen) {
								$scope.subscriptions_by_alert_type[subscription.alert_type.toString()].screen = true;
							}
							if (subscription.subscription_type === DISPATCHER_ALERT_SUBSCRIPTION_TYPE.phone) {
								$scope.subscriptions_by_alert_type[subscription.alert_type.toString()].phones.push(
									subscription.phone
								);
							}
							if (subscription.subscription_type === DISPATCHER_ALERT_SUBSCRIPTION_TYPE.email) {
								$scope.subscriptions_by_alert_type[subscription.alert_type.toString()].emails.push(
									subscription.email
								);
							}
						}
					});
				});
			}
		});

		$scope.updateSubscriptions = function () {
			var subscriptions = [];

			function addEmail(alertType) {
				return function (email) {
					subscriptions.push({ alert_type: alertType, subscription_type: 2, email: email });
				};
			}

			function addPhone(alertType) {
				return function (phone) {
					subscriptions.push({ alert_type: alertType, subscription_type: 1, phone: phone });
				};
			}

			for (var alertType in $scope.subscriptions_by_alert_type) {
				var value = $scope.subscriptions_by_alert_type[alertType];
				if (value.screen) {
					subscriptions.push({ alert_type: alertType, subscription_type: 0 });
				}
				value.phones.forEach(addPhone(alertType));
				value.emails.forEach(addEmail(alertType));
			}
			DispatcherAlertSubscription.mass_update(
				{ subscriptions: subscriptions },
				function (data, status, headers, config) {
					toastr.success($translate.instant('DISPATCHER_ALERT_SETTINGS.ALERT_SETTINGS_UPDATED'));
				},
				function (data, status, headers, config) {
					toastr.error($translate.instant('DISPATCHER_ALERT_SETTINGS.ALERT_SETTINGS_UPDATE_FAILED'));
				}
			);
		};
	});
