'use strict';

angular.module('bringgApp').service('AirbrakeService', function (ENV_CONFIGURATION, $injector) {
	var service = {};

	service.getIgnoredErrors = function () {
		return ['$digest already in progress', "Cannot read property '0' of null", 'Unauthorized'];
	};

	service.init = function () {
		const didAirbrakeAlreadyInstrumentedElsewhere =
			window.firstAirbrakeInitSource !== undefined && window.firstAirbrakeInitSource !== 'angular';

		if (
			!ENV_CONFIGURATION.isLocalAgainstProd &&
			ENV_CONFIGURATION.airbrake_project_id &&
			ENV_CONFIGURATION.airbrake_project_key
		) {
			service.airbrake = new Airbrake.Notifier({
				projectId: ENV_CONFIGURATION.airbrake_project_id,
				projectKey: ENV_CONFIGURATION.airbrake_project_key,
				environment: ENV_CONFIGURATION.environment,
				// Disabling it here as the react part already does this, so we want to avoid duplicate errors
				instrumentation: didAirbrakeAlreadyInstrumentedElsewhere
					? {
							console: false,
							fetch: false,
							onerror: false,
							history: false,
							xhr: false,
							unhandledrejection: false
					  }
					: undefined
			});
			service.airbrake.addFilter(service.filterErrorMessages);

			window.firstAirbrakeInitSource = window.firstAirbrakeInitSource || 'angular';
		} else {
			delete service.airbrake;
			window.firstAirbrakeInitSource =
				window.firstAirbrakeInitSource === 'angular' ? undefined : window.firstAirbrakeInitSource;
		}
	};

	service.filterErrorMessages = function (notice) {
		var errorMessage = _.get(notice, 'errors[0].message');
		var result = notice;

		_.forEach(service.getIgnoredErrors(), function (ignoredMessage) {
			if (_.includes(errorMessage, ignoredMessage)) {
				result = null;
			}
		});

		return result;
	};

	service.notify = function (exception, params) {
		if (!service.airbrake) {
			return;
		}

		exception = exception || {};
		var currentUser = $injector.get('Authentication').currentUser() || {};

		var defaultParams = {
			env: ENV_CONFIGURATION.environment,
			user: currentUser.id,
			merchant: currentUser.merchant_id
		};

		_.extend(defaultParams, params);

		service.airbrake.notify({
			error: exception,
			params: defaultParams,
			context: {
				environment: ENV_CONFIGURATION.environment,
				user_id: currentUser.id,
				merchant_id: currentUser.merchant_id
			}
		});
	};

	service.init();
	return service;
});
