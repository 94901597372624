'use strict';

angular
	.module('bringgApp')
	.factory('Waypoint', function ($resource, WEB_API_URL, REAL_TIME_URL) {
		var Waypoint = $resource(
			REAL_TIME_URL + '/waypoints/:id',
			{ id: '@id', task_id: '@task_id' },
			{
				all: { method: 'GET', isArray: true, url: REAL_TIME_URL + '/tasks/:id/waypoints' },
				save: { method: 'POST', url: REAL_TIME_URL + '/tasks/:task_id/waypoints' },
				update: { method: 'PATCH', url: WEB_API_URL + '/tasks/:task_id/way_points/:id' },
				switch: { method: 'POST', url: WEB_API_URL + '/tasks/:task_id/way_points/:id/switch/' },
				delete: { method: 'DELETE', url: WEB_API_URL + '/tasks/:task_id/way_points/:id' }
			}
		);

		return Waypoint;
	})
	.constant('PICKUP_DROPOFF_OPTION', function () {
		return { pick_up: 0, drop_off: 1, both: 2 };
	})
	.factory('Waypoints', function (Waypoint, Tasks, $log, $q) {
		var Waypoints = {};

		Waypoints.update = function (taskId, wayPointId, changes) {
			var logPrefix = 'Waypoints.update task_id [' + taskId + '] way_point_id [' + wayPointId + ']';

			$log.info(logPrefix + ' called with requested changes: ' + JSON.stringify(changes));

			return Waypoint.update({ id: wayPointId, task_id: taskId }, changes).$promise.then(function (response) {
				if (response.success) {
					$log.info(logPrefix + ' updated successfuly');
					Tasks.updateWayPoint(taskId, response.way_point);
				} else {
					$log.info(logPrefix + ' failed, response: ' + JSON.stringify(response));
					return $q.reject(response);
				}
			});
		};

		return Waypoints;
	});
