/**
 * Created by liorsion on 6/18/16.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('PaymentMethodsController', function (
		$scope,
		$window,
		MerchantConfigurationService,
		Authentication,
		toastr,
		MerchantService,
		Payments,
		MerchantConfigurations,
		STRIPE_CLIENT_ID
	) {
		$scope.loading = true;
		$scope.saving = false;
		$scope.data = {
			enable_task_price: MerchantConfigurations.enable_task_price
		};

		$scope.enable_task_price = MerchantConfigurations.enable_task_price;

		$scope.$on('config updated', function () {
			$scope.data.enable_task_price = MerchantConfigurations.enable_task_price;
			$scope.enable_task_price = MerchantConfigurations.enable_task_price;
		});

		$scope.$watch('data.enable_task_price', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			$scope.enable_task_price = newValue;
		});

		$scope.connectToStripe = function () {
			if ($scope.readonly) {
				return;
			}
			$window.open(
				'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' +
					STRIPE_CLIENT_ID +
					'&scope=read_write'
			);
		};

		$scope.handleUpdateClick = function () {
			$scope.saving = true;
			MerchantConfigurationService.update({ enable_task_price: $scope.enable_task_price })
				.then(function () {
					//TODO: update cookiestore merchant info here
					MerchantConfigurations.enable_task_price = $scope.enable_task_price;
					toastr.success('Update saved, thank you!');
				})
				.catch(function () {
					toastr.error('Failed updating merchant settings.');
				});
		};
	});
