'use strict';
angular
	.module('bringgApp')
	.factory(
		'Tasks',
		function (
			$rootScope,
			SocketPubSub,
			Task,
			Employees,
			Customers,
			Authentication,
			TaskReassignReasonsService,
			geohash,
			$q,
			$log,
			Teams,
			MerchantConfigurations,
			DEFAULT_RT_THROTTLE,
			ActionInterceptorService,
			INTERCEPTABLE_ACTIONS,
			TASK_DEFAULT_TYPES,
			TASK_TYPE_TRANSLATIONS,
			TASK_TYPE_MAP,
			TaskInventoriesService,
			$translate,
			HtmlDecoder,
			TranslationService,
			HTTP_STATUS_CODE_FORBIDDEN,
			GroupedTasksService,
			TasksData
		) {
			var Tasks = {};

			TaskReassignReasonsService.init();

			Tasks.getInvalidTasks = function () {
				var deferred = $q.defer();

				Task.getInvalidTasks({ useCache: false }).then(function (tasks) {
					if (!Task.shouldUseNewApi()) {
						_.each(tasks, function (task) {
							Tasks._addTaskToArray(TasksData.getInvalidTasks(), task);
						});
					}
					deferred.resolve(tasks);
				});

				return deferred.promise;
			};

			Tasks.updateWayPoint = function (taskId, wayPoint) {
				var logPrefix =
					'Tasks.updateWayPoint task_id [' + taskId + '] way_point_id [' + (wayPoint && wayPoint.id) + '] ';

				var task =
					Tasks._findOpenById(taskId) ||
					_.find(TasksData.getPlanningTasks(), { id: taskId }) ||
					_.find(TasksData.getInvalidTasks(), { id: taskId });

				if (task) {
					$log.info(logPrefix + 'found task');
					var wayPointIdx = _.findIndex(task.way_points, { id: wayPoint.id });

					if (wayPointIdx !== -1) {
						$log.info(logPrefix + 'found way point, updating and notifying changes');
						task.way_points[wayPointIdx] = _.extend(task.way_points[wayPointIdx], wayPoint);
						Tasks.notifyChanges();
					}
				} else {
					$log.error(logPrefix + 'failed to find task to update in open or planing');
				}
			};

			Tasks.setActiveWayPoint = function (task) {
				if (task.way_points) {
					task.way_points.forEach(function (wayPoint) {
						if (task.active_way_point_id === wayPoint.id) {
							task.activeWayPoint = wayPoint;
						}
					});
				}
			};

			Tasks._setActiveTeamId = function (task) {
				task.team_id = _.last(task.team_ids);
			};

			Tasks._getDriverFullDetails = function (task) {
				const isClickAndCollect = task && task.task_type_id === TASK_DEFAULT_TYPES.CLICK_AND_COLLECT;
				const getEmployeePromise = Employees.get({ id: task.user_id, customer: isClickAndCollect }).then(
					function (user) {
						task.user = user;
						if (Employees.ready && _.isEmpty(task.user)) {
							$log.warn('User ' + task.user_id + ' was not found');
						}
					}
				);
				return getEmployeePromise;
			};

			Tasks._initCustomer = function (wayPoint) {
				var missingCustomerDataOnWayPoint = _.isEmpty(wayPoint.customer) && wayPoint.customer_id;
				if (missingCustomerDataOnWayPoint) {
					return Customers.get(wayPoint.customer_id).then(function (customer) {
						wayPoint.customer = customer;
					});
				}
				return $q.resolve();
			};

			Tasks._preProcessCustomer = function (task) {
				var addNewCustomerIfNeeded = function () {
					var newCustomer = task.customer && task.customer.id;
					if (newCustomer) {
						task.customer.name = HtmlDecoder.decodeHtml(task.customer.name);
						Customers.addNewCustomer(task.customer);
					}
				};
				var assignedToCustomerWithOutFullData = _.isEmpty(task.customer) && task.customer_id;
				var assignedToDifferentCustomerOnTask =
					!_.isEmpty(task.customer) && task.customer.id !== task.customer_id;
				if (assignedToCustomerWithOutFullData || assignedToDifferentCustomerOnTask) {
					// first look in way points for customer full information
					return $q
						.all(
							_.map(task.way_points, function (wayPoint) {
								return Tasks._initCustomer(wayPoint).then(function () {
									var foundFullCustomerOnWayPoint =
										wayPoint.customer && wayPoint.customer.id === task.customer_id;
									if (foundFullCustomerOnWayPoint) {
										task.customer = wayPoint.customer;
									}
								});
							})
						)
						.then(function () {
							var customerNotFoundOnWayPoints = _.isEmpty(task.customer);
							if (customerNotFoundOnWayPoints) {
								return Customers.get(task.customer_id).then(function (customer) {
									task.customer = customer;
								});
							}
						})
						.then(addNewCustomerIfNeeded);
				}

				addNewCustomerIfNeeded();
				return $q.resolve();
			};

			Tasks._preProcessWayPoints = function (task) {
				_.each(task.way_points, function (wayPoint) {
					if (_.isNil(task.scheduled_at)) {
						if (wayPoint.id === task.active_way_point_id) {
							task.scheduled_at = wayPoint.scheduled_at;
						}
					}

					if (!_.isEmpty(wayPoint.name)) {
						wayPoint.name = HtmlDecoder.decodeHtml(wayPoint.name);
					}

					if (wayPoint.customer && wayPoint.customer.id) {
						wayPoint.customer.name = HtmlDecoder.decodeHtml(wayPoint.customer.name);
						Customers.addNewCustomer(wayPoint.customer);
					}

					if (!_.isEmpty(task.task_inventories)) {
						wayPoint.task_inventories = TaskInventoriesService.getInventories(task, wayPoint);
					}

					if (!_.isEmpty(task.task_notes)) {
						wayPoint.task_notes = _.filter(task.task_notes, {
							way_point_id: wayPoint.id,
							type: 'TaskNote'
						});
					}
				});

				var firstWayPoint = _.first(task.way_points);
				if (firstWayPoint.lat && firstWayPoint.lng) {
					task.geohash_origin = geohash.encode_int(firstWayPoint.lat, firstWayPoint.lng, 34);
				}
				var lastWayPoint = _.last(task.way_points);
				if (lastWayPoint.lat && lastWayPoint.lng) {
					task.geohash_destination = geohash.encode_int(lastWayPoint.lat, lastWayPoint.lng, 34);
				}

				Tasks.setActiveWayPoint(task);
			};

			// fill up missing details from other tasks if that exists
			Tasks.getTaskCompleteDetails = function (task) {
				if (!Task.shouldUseNewApi()) {
					TasksData.getMap()[task.id] = task; // make sure its there first.
				}

				var promises = [];

				Tasks._setActiveTeamId(task);

				var taskAssignedToDriver = _.isEmpty(task.user) && task.user_id;
				if (taskAssignedToDriver) {
					promises.push(Tasks._getDriverFullDetails(task));
				}

				task.way_points = _.sortBy(task.way_points, 'position') || [];

				promises.push(
					Tasks._preProcessCustomer(task).then(function () {
						if (!_.isEmpty(task.way_points)) {
							Tasks._preProcessWayPoints(task);
						}
					})
				);

				return $q.all(promises).then(function () {
					return task;
				});
			};

			Tasks._mergeObject = function (destination, origin) {
				for (var key in origin) {
					if (origin.hasOwnProperty(key)) {
						if (
							['merchant_id', 'customer_id', 'user_id'].indexOf(key) !== -1 &&
							destination[key] != origin[key]
						) {
							var field = key.substring(0, key.length - 3);
							// the original data has no id directly, only the sub object, so we test it manually
							// field can also be null, if for example task is not assigned

							if (
								!_.isNull(destination[field]) &&
								!_.isUndefined(destination[field]) &&
								destination[field].id &&
								destination[field].id != origin[key]
							) {
								if (origin[field]) {
									destination[field] = origin[field];
								} else {
									destination[field] = null;
								}
							}
						}
						if (key === 'way_points') {
							// potentially should remove ids of way points that were deleted, it's an edge case that is not worked on right now, a better solution would be to send a way point removed event
							_.each(origin[key], function (originWayPoint) {
								var destinationWayPoint = _.find(destination[key], function (wayPoint) {
									return wayPoint.id === originWayPoint.id;
								});

								if (destinationWayPoint) {
									Tasks._mergeObject(destinationWayPoint, originWayPoint);
								} else {
									destination[key].push(originWayPoint);
								}
							});
						} else {
							destination[key] = origin[key];
						}
					}
				}
			};

			Tasks.broadcastTask = function (taskId, userIds) {
				var defer = $q.defer();

				Task.broadcastToDrivers({
					task_id: taskId,
					user_ids: userIds,
					message: $translate.instant('BROADCAST_TASKS.MESSAGE', {
						merchant_name: MerchantConfigurations.name
					})
				})
					.then(function () {
						defer.resolve();
					})
					.catch(function (error) {
						$log.error('TaskService:_broadcastTask failed with [' + JSON.stringify(error) + ']');
						defer.reject();
					});

				return defer.promise;
			};

			Tasks.onDemandGrabModeEnabled = function () {
				return MerchantConfigurations.grab_mode === MerchantConfigurations.GRAB_MODES.ON_DEMAND;
			};

			Tasks.mergeTask = function (destinationTask, newTask) {
				if (_.isEmpty(newTask.user)) {
					delete newTask.user;
				}

				if (newTask.run) {
					delete newTask.run;
				}

				if (!Task.shouldUseNewApi()) {
					Tasks._mergeObject(destinationTask, newTask);
				}

				Tasks.getTaskCompleteDetails(destinationTask);
				return destinationTask;
			};

			Tasks._addTaskToArray = function (arrayOfTask, newTask) {
				if (_.isUndefined(arrayOfTask)) {
					$log.log('TasksService receive new task but arrays are not ready yet');
					return;
				}

				var foundTaskIdx = _.findIndex(arrayOfTask, { id: newTask.id });

				if (foundTaskIdx === -1) {
					arrayOfTask.unshift(newTask);
					if (!Task.shouldUseNewApi() && _.isUndefined(TasksData.get(newTask.id))) {
						TasksData.getMap()[newTask.id] = newTask;
					}
					$rootScope.$broadcast('new task', newTask);
				} else {
					$log.log('TasksService task [' + newTask.id + '] received and it existed before');
					Tasks.mergeTask(arrayOfTask[foundTaskIdx], newTask);
				}
			};

			Tasks._removeTaskFromArrayIfExists = function (taskArray, taskId) {
				if (Task.shouldUseNewApi()) {
					return;
				}

				var index = _.findIndex(taskArray, { id: taskId });
				if (index !== -1) {
					taskArray.splice(index, 1);
				}
			};

			Tasks.addNewTask = function (newTask, options) {
				options = _.defaults({}, options, {
					notifyChanges: true
				});

				if (options.notifyChanges) {
					TasksData.updateFollowersCount(newTask);
				}

				TasksData.updateFollowers(newTask);

				if (!_.isUndefined(newTask.invalidated) && Task.isInvalid(newTask)) {
					$log.info(
						'taskList Received new task [' +
							newTask.id +
							'] with invalidated [' +
							newTask.invalidated +
							'] - not adding it'
					);

					if (!Task.shouldUseNewApi()) {
						Tasks._addTaskToArray(TasksData.getInvalidTasks(), newTask);
					}

					$rootScope.$broadcast('invalid task added', newTask);
					return newTask;
				}

				if (!_.isNull(newTask.group_leader_id) && !_.isUndefined(newTask.group_leader_id)) {
					$log.log(
						'Received new task [' +
							newTask.id +
							'] with group leader [' +
							newTask.group_leader_id +
							'] - not adding it'
					);
					return newTask;
				}

				if (_.isUndefined(newTask.status) || Task.isDone(newTask)) {
					$log.log(
						'Received new task [' +
							newTask.id +
							'] without status or status done [' +
							newTask.status +
							'] not adding it'
					);
					return newTask;
				}

				return Tasks.getTaskCompleteDetails(newTask).then(function () {
					if (Task.isDone(newTask)) {
						$log.log(
							'Added task (' + newTask.id + ') with status (' + newTask.status + ') is already done'
						);
						return newTask;
					}

					$log.log(
						'Added task (' +
							newTask.id +
							'] is isUnacknowledged [' +
							Task.isUnacknowledged(newTask) +
							'] and user can access it [' +
							Authentication.currentUser().has_access('acknowledge') +
							']'
					);

					if (Task.isUnacknowledged(newTask)) {
						var alreadyIn =
							!Task.shouldUseNewApi() && _.find(TasksData.getUnacknowledged(), { id: newTask.id });

						if (!alreadyIn) {
							TasksData.addUnacknowledged(newTask);
							$rootScope.$broadcast('new unacknowledged task', newTask);
						}

						return newTask;
					}

					if (!Task.shouldUseNewApi()) {
						if (Task.isPlanning(newTask)) {
							Tasks._addTaskToArray(TasksData.getPlanningTasks(), newTask);
						} else {
							Tasks._addTaskToArray(TasksData.getOpenTasks(), newTask);
							$rootScope.$broadcast('new open task', newTask);
						}
					} else {
						$rootScope.$broadcast('new task', newTask, { notifyChanges: options.notifyChanges });

						if (!Task.isPlanning(newTask)) {
							$rootScope.$broadcast('new open task', newTask);
						}
					}

					if (options.notifyChanges) {
						Tasks.notifyChanges();
					}

					return newTask;
				});
			};

			/**
			 * send list change notifiction
			 */
			Tasks.notifyChanges = _.throttle(function () {
				$rootScope.$broadcast('task list update');
			}, DEFAULT_RT_THROTTLE);

			/**
			 * send list change notification without throttling
			 */
			Tasks.notifyChangesSkipThrottle = function () {
				$rootScope.$broadcast('task list update');
			};

			Tasks.onTaskDeleted = function (deletedTask) {
				$log.log('Order (' + deletedTask.id + ') deleted: ', deletedTask);

				if (!Task.shouldUseNewApi()) {
					Tasks._removeTaskFromArrayIfExists(TasksData.getOpenTasks(), deletedTask.id);
					Tasks._removeTaskFromArrayIfExists(TasksData.getPlanningTasks(), deletedTask.id);
					Tasks._removeTaskFromArrayIfExists(TasksData.getUnacknowledged(), deletedTask.id);
					Tasks._removeTaskFromArrayIfExists(TasksData.getInvalidTasks(), deletedTask.id);

					delete TasksData.getMap()[deletedTask.id];
					delete TasksData.getFollowers()[deletedTask.id];
				}

				Tasks.notifyChanges();
			};

			Tasks.onTaskUpdated = function (updatedTask, previousTask) {
				$log.log('Task (' + updatedTask.id + ') received update: ', updatedTask);

				if (!previousTask) {
					$log.log('TasksService got task update, redirecting to new task: ', updatedTask.id);
					Tasks.addNewTask(updatedTask);
					return;
				}

				// work on the full merged task
				updatedTask = Tasks.mergeTask(TasksData.get(updatedTask.id), updatedTask);

				TasksData.updateFollowersCount(updatedTask, Task.isDone(updatedTask));
				TasksData.updateFollowers(updatedTask);

				if (
					Task.isDone(updatedTask) ||
					GroupedTasksService.isFollower(updatedTask) ||
					!Task.isValid(updatedTask)
				) {
					if (!Task.shouldUseNewApi()) {
						const openTasks = TasksData.getOpenTasks();
						var foundTaskIdx = _.findIndex(openTasks, { id: updatedTask.id });
						if (foundTaskIdx !== -1) {
							openTasks.splice(foundTaskIdx, 1);
						}

						// task completed remove from planning if needed
						const planningTasks = TasksData.getPlanningTasks();
						Tasks._removeTaskFromArrayIfExists(planningTasks, updatedTask.id);

						// task was canceled from ack window
						Tasks._removeTaskFromArrayIfExists(TasksData.getUnacknowledged(), updatedTask.id);
					}

					if (Task.isDone(updatedTask) || GroupedTasksService.isFollower(updatedTask)) {
						if (!Task.shouldUseNewApi()) {
							delete TasksData.getMap()[updatedTask.id];
						}
						$rootScope.$broadcast('task done', updatedTask);
					}
				}

				//update the unacknowledged task
				if (Task.isUnacknowledged(updatedTask)) {
					if (!Task.shouldUseNewApi()) {
						TasksData.getUnacknowledged().some(function (task, index) {
							if (task.id === updatedTask.id) {
								Tasks.mergeTask(TasksData.getUnacknowledged()[index], updatedTask);
								return true;
							}
						});
					}
				} else {
					if (Task.shouldUseNewApi()) {
						if (Task.isUnacknowledged(previousTask)) {
							$rootScope.$broadcast('task removed from unacknowleged', updatedTask);
							$rootScope.$broadcast('new task', updatedTask);
						}
					} else {
						var existingUpdatedTaskIndex = _.findIndex(TasksData.getUnacknowledged(), {
							id: updatedTask.id
						});

						if (existingUpdatedTaskIndex !== -1) {
							var oldTask = TasksData.get(updatedTask.id) || updatedTask;
							Tasks.mergeTask(oldTask, updatedTask);

							if (!Task.shouldUseNewApi()) {
								Tasks._addTaskToArray(TasksData.getOpenTasks(), updatedTask);
							}

							Tasks._removeTaskFromArrayIfExists(TasksData.getUnacknowledged(), updatedTask.id);

							$rootScope.$broadcast('task removed from unacknowleged', updatedTask);
							$rootScope.$broadcast('new task', updatedTask);
						}
					}
				}

				var wasInvalid = Task.isInvalid(previousTask);

				if (Task.isInvalid(updatedTask) && !Task.isDone(updatedTask)) {
					if (!Task.shouldUseNewApi()) {
						Tasks._addTaskToArray(TasksData.getInvalidTasks(), updatedTask);
					}
					$rootScope.$broadcast('invalid task added', updatedTask);
					$log.log('TasksService task', updatedTask.id, 'added to invalid tasks');
				} else if (wasInvalid && (Task.isValid(updatedTask) || Task.isDone(updatedTask))) {
					Tasks._removeTaskFromArrayIfExists(TasksData.getInvalidTasks(), updatedTask.id);
					$rootScope.$broadcast('invalid task removed', updatedTask);
					$log.log('TasksService task', updatedTask.id, 'removed from invalid tasks');

					if (!Task.shouldUseNewApi()) {
						if (Task.isPlanning(updatedTask)) {
							Tasks._addTaskToArray(TasksData.getPlanningTasks(), updatedTask);
						} else {
							Tasks._addTaskToArray(TasksData.getOpenTasks(), updatedTask);
						}
					}
				}

				if (Task.isPlanning(updatedTask)) {
					if (!Task.isPlanning(previousTask) && !Task.shouldUseNewApi()) {
						const openTasks = TasksData.getOpenTasks();
						var openTaskIdx = _.findIndex(openTasks, { id: updatedTask.id });
						var openOldTask = TasksData.getOpenTasks()[openTaskIdx];
						Tasks.mergeTask(openOldTask, updatedTask);

						openTasks.splice(openTaskIdx, 1);

						$log.log(
							'TasksService task',
							openOldTask.id,
							'found in open tasks list but is in planning mode. remove from open tasks and add to planning tasks'
						);
						const planningTasks = TasksData.getPlanningTasks();
						Tasks._addTaskToArray(planningTasks, openOldTask);
					}
				} else {
					if (Task.isPlanning(previousTask) && !Task.shouldUseNewApi()) {
						const planningTasks = TasksData.getPlanningTasks();
						var planningTaskIdx = _.findIndex(planningTasks, { id: updatedTask.id });
						var planningOldTask = planningTasks[planningTaskIdx];
						Tasks.mergeTask(planningOldTask, updatedTask);

						planningTasks.splice(planningTaskIdx, 1);
						$log.log(
							'TasksService task',
							updatedTask.id,
							'found in planning tasks list but is not in planning mode. remove from planning tasks and add to open tasks'
						);
						const openTasks = TasksData.getOpenTasks();
						Tasks._addTaskToArray(openTasks, planningOldTask);
					}
				}

				Tasks.notifyChanges();
			};

			Tasks.addWaypointToTasks = function (tasks, newWaypoint) {
				var newWaypointUserId;
				tasks.some(function (task) {
					if (task.id === newWaypoint.task_id) {
						if (!task.way_points) {
							task.way_points = [];
						}
						task.way_points.unshift(newWaypoint);
						if (_.isUndefined(newWaypointUserId)) {
							newWaypointUserId = task.user_id;
						}
						return newWaypointUserId;
					}
				});
				return newWaypointUserId;
			};

			Tasks.get = function (options) {
				options = options || {};
				var taskId = options.id;
				var task = null;

				if (!taskId) {
					$log.log('Tasks.get called without task id');
					return $q.reject(new Error('called without task id'));
				}

				// do not search for task in case of force get
				if (!options.forceGet) {
					task = TasksData.get(taskId);
				}

				if (task) {
					return $q.resolve(task);
				}

				return Task.get(taskId, { forceGet: options.forceGet }).then(Tasks.addNewTask);
			};

			Tasks.latest = function () {
				var user_id = arguments[0].user_id,
					successCb = getSuccessCallback(arguments);

				var latestTasks = _.filter(TasksData.getOpenTasks(), function (task) {
					return task.user_id == user_id;
				});

				successCb(latestTasks);
				return $q.resolve(latestTasks);
			};

			Tasks.openForUser = function (userId) {
				return _.filter(TasksData.getOpenTasks(), function (openTask) {
					return openTask.user_id == userId;
				});
			};

			Tasks._findOpenById = function (taskId) {
				var task = TasksData.get(taskId);

				// if we found task from the map - make sure it's open
				if (task && !Task.isPlanning(task) && !Task.isInvalid(task)) {
					return task;
				}

				// if we didn't found, search the tasks array
				if (!task) {
					return _.find(TasksData.getOpenTasks(), { id: taskId });
				}
			};

			Task.onUpdate(function (changeData) {
				$rootScope.$broadcast('task update', changeData.current);

				Tasks.onTaskUpdated(
					changeData.current,
					changeData.diff && {
						id: changeData.diff.id ?? changeData.current.id,
						status: changeData.diff.status ?? changeData.current.status,
						invalidated: changeData.diff.invalidated ?? changeData.current.invalidated,
						ready_to_execute: changeData.diff.ready_to_execute ?? changeData.current.ready_to_execute
					}
				);
			});

			Task.onCreate(function (changeData) {
				$log.log(
					'TasksService Employee (' + changeData.current.user_id + ') received new task: ',
					changeData.current
				);
				Tasks.addNewTask(changeData.current);
			});

			if (!Task.shouldUseNewApi()) {
				SocketPubSub.on('waypoint added', function (newWayPoint) {
					$log.log('Task (' + newWayPoint.task_id + ') received a new waypoint: ', newWayPoint);
					Tasks.addWaypointToTasks(TasksData.getOpenTasks(), newWayPoint);
				});

				SocketPubSub.on('new scan', function (newScan) {
					$log.log('TasksService Employee (' + newScan.user_id + ') received new scan: ', newScan);
					var task = Tasks._findOpenById(newScan.task_id);
					if (task) {
						task.scans = task.scans || [];
						task.scans.push(newScan);
					}
				});

				SocketPubSub.on('new note', function (newNote) {
					$log.log(
						'TasksService Employee (' + newNote.user_id + ') received new note for task: ',
						newNote.task_id
					);
					var task = Tasks._findOpenById(newNote.task_id);
					if (task) {
						if (!task.task_notes) {
							task.task_notes = [];
						}
						task.task_notes.push(newNote);
					}
				});
			}

			SocketPubSub.on('waypoint deleted', function (wayPointData) {
				if (Task.shouldUseNewApi()) {
					Tasks.notifyChanges();
					return;
				}

				var task_id = parseInt(wayPointData.task_id, 10),
					way_point_id = parseInt(wayPointData.way_point_id, 10);

				$log.log(
					'Task (' +
						wayPointData.task_id +
						') received way point ' +
						wayPointData.way_point_id +
						' delete event'
				);
				var task = Tasks._findOpenById(task_id);
				if (task) {
					for (var wayPointIdx in task.way_points) {
						if (task.way_points[wayPointIdx].id === way_point_id) {
							if (task.active_way_point_id === way_point_id) {
								$log.info('Task way point was the active way point, updating');
								task.scheduled_at = null;
								task.activeWayPoint = null;
							}
							task.way_points.splice(wayPointIdx, 1);
							break;
						}
					}

					Tasks.notifyChanges();
				}
			});

			SocketPubSub.on('task deleted', Tasks.onTaskDeleted);

			SocketPubSub.on('waypoint updated', function (updatedWaypointDetails) {
				var task_id = parseInt(updatedWaypointDetails.task_id, 10),
					way_point_id = parseInt(updatedWaypointDetails.way_point_id, 10);

				$log.log(
					'Task (' +
						updatedWaypointDetails.task_id +
						') received way point ' +
						updatedWaypointDetails.way_point_id +
						' update event'
				);
				var task = TasksData.get(task_id);

				function updateTaskWayPoint(task) {
					return function (updatedKey) {
						if (task.way_points[wayPointIdx][updatedKey] !== undefined) {
							if (!Task.shouldUseNewApi()) {
								task.way_points[wayPointIdx][updatedKey] = updatedWaypointDetails[updatedKey];
							}

							if (task.active_way_point_id === way_point_id) {
								if (updatedKey === 'scheduled_at') {
									task.scheduled_at = updatedWaypointDetails[updatedKey];
								}
							}
							Tasks.notifyChanges();
						}
					};
				}

				if (!_.isUndefined(task)) {
					for (var wayPointIdx in task.way_points) {
						if (task.way_points[wayPointIdx].id === way_point_id) {
							_.keys(updatedWaypointDetails).forEach(updateTaskWayPoint(task));
							break;
						}
					}
				}
			});

			Tasks.acknowledgeTask = function (taskId, cb) {
				return Task.acknowledgeTask(taskId)
					.then(function (response) {
						if (response.success) {
							Tasks.onTaskUpdated(response.task, TasksData.get(taskId));
						}

						if (cb) {
							cb(response);
						}

						return response;
					})
					.catch(function (err) {
						if (cb) {
							cb(err);
						}

						return err;
					});
			};

			Tasks.releaseToDrivers = function (args) {
				return Task.releaseToDrivers(args).then(function (result) {
					if (result.success) {
						_.each(args.ids, function (id) {
							var task = TasksData.get(id);

							const previousTask = {
								id: task.id,
								status: task.status,
								invalidated: task.invalidated,
								ready_to_execute: task.ready_to_execute
							};

							if (Task.shouldUseNewApi()) {
								Tasks.onTaskUpdated(task, previousTask);
							} else if (!task.ready_to_execute) {
								task.ready_to_execute = true;
								Tasks.onTaskUpdated(task, previousTask);
							}
						});
					}

					return result;
				});
			};

			Tasks.moveToPlanning = function (args) {
				return Task.moveToPlanning(args).then(function (result) {
					if (result.success) {
						_.each(args.task_ids, function (id) {
							var task = TasksData.get(id);

							const previousTask = {
								id: task.id,
								status: task.status,
								invalidated: task.invalidated,
								ready_to_execute: task.ready_to_execute
							};

							if (Task.shouldUseNewApi()) {
								Tasks.onTaskUpdated(task, previousTask);
							} else if (task.ready_to_execute) {
								task.ready_to_execute = false;
								Tasks.onTaskUpdated(task, previousTask);
							}
						});
					} else {
						toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_ORDER_MOVE_TO_PLANNING_FAILED'));
					}

					return result;
				});
			};

			Tasks.getDriversWithAvailableInventories = function (employees, task_id) {
				return Task.getDriversWithAvailableInventories({ task_id: task_id })
					.$promise.then(function (result) {
						if (!result.user_ids || result.user_ids.length === 0) {
							result.user_ids = [];
						}

						employees.forEach(function (employee) {
							employee.hasAvailavleInventory = result.user_ids.includes(employee.id);
						});

						return Promise.resolve(employees);
					})
					.catch(function (error) {
						$log.error('getDriversWithAvailableInventories request failed', error);
						return error.$promise;
					});
			};

			Tasks.massCancel = function (args) {
				return Task.massCancel(args).then(function (result) {
					if (result.success && !Task.shouldUseNewApi()) {
						_.each(args.task_ids, function (id) {
							// tasks has been canceled, lets remove them from both lists
							var planningTaskIndex = _.findIndex(TasksData.getPlanningTasks(), { id: id });
							if (planningTaskIndex !== -1) {
								TasksData.getPlanningTasks().splice(planningTaskIndex, 1);
							}

							// tasks has been canceled, lets remove them from both lists
							var openTaskIndex = _.findIndex(TasksData.getOpenTasks(), { id: id });
							if (openTaskIndex !== -1) {
								TasksData.getOpenTasks().splice(openTaskIndex, 1);
							}
						});
					}

					return result;
				});
			};

			Tasks.batchGet = function (ids) {
				return Task.batchGet(ids).then(function (tasks) {
					_.each(tasks, function (updatedTask) {
						TasksData.getOpenTasks().some(function (task, index) {
							if (task.id === updatedTask.id) {
								Tasks.mergeTask(TasksData.getOpenTasks()[index], updatedTask);
								return true;
							}
						});

						TasksData.getPlanningTasks().some(function (task, index) {
							if (task.id === updatedTask.id) {
								Tasks.mergeTask(TasksData.getPlanningTasks()[index], updatedTask);
								return true;
							}
						});
					});

					return tasks;
				});
			};

			Tasks.removeFromRun = function (task) {
				var previousRunId = task.run_id;
				var previousRunUuid = task.run_uuid;
				task.run_id = null;
				task.run_uuid = null;

				return Task.update({ run_id: null, run_uuid: null, id: task.id }).catch(function (error) {
					task.run_id = previousRunId;
					task.run_uuid = previousRunUuid;
					Tasks.notifyChangesSkipThrottle();

					if (error && error.status === HTTP_STATUS_CODE_FORBIDDEN) {
						return $q.reject($translate.instant('TASK.PERMISSION_DENIED'));
					}
					return $q.reject(error);
				});
			};

			Tasks.assignTask = function (task, userId) {
				var user = Employees.employeeByIdMap[parseInt(userId, 10)];

				if (!user && userId) {
					return $q.reject('User not found');
				}

				if (user && task.user_id === user.id) {
					return $q.reject('Task is already assigned to ' + user.name);
				}

				const oldTask = angular.copy(task);

				Tasks.notifyChangesSkipThrottle();
				let promise;
				let newUser = user;
				if (user) {
					promise = Tasks.massAssign(user.id, [task]);
				} else {
					promise = Tasks.massUnassign([task]);
				}

				return promise
					.then(function (result) {
						if (!result.success) {
							return $q.reject(new Error(result.message));
						}

						task.user = task.employee = newUser;
						task.user_id = newUser ? newUser.id : null;
						return result;
					})
					.catch(function (error) {
						Tasks.revertCachedAssignTaskToOldTask(task, oldTask);
						Tasks.notifyChangesSkipThrottle();
						if (error && error.status === HTTP_STATUS_CODE_FORBIDDEN) {
							return $q.reject($translate.instant('TASK.PERMISSION_DENIED'));
						}
						return $q.reject(error);
					});
			};

			Tasks.revertCachedAssignTaskToOldTask = function (task, oldTask) {
				task.priority = oldTask.priority;
				task.user = task.employee = oldTask.user;
				task.user_id = oldTask.user_id;

				Tasks.notifyChangesSkipThrottle();
			};

			Tasks.updatePreparationStatus = function (task, preparationStatus) {
				return Task.updatePreparationStatus({
					task_id: task.id,
					preparation_status: preparationStatus
				}).then(function (response) {
					$log.info('got server response', response);

					// If we got task in response which can happen in failure and success, we want to update the task:
					// * success - the task is marked for start preparation
					// * failure - the task is already marked in preparation/or ready for pickup
					if (response.task) {
						$log.info('out of sync tyring to invoking on task update');
						Tasks.onTaskUpdated(response.task, TasksData.get(task.id));
						return { success: true, task: response.task };
					}

					return response;
				});
			};

			Tasks.rescheduleVirtualTasks = function (taskIds, noLaterThan, noEarlierThan) {
				return Task.rescheduleVirtualTasks(taskIds, noLaterThan, noEarlierThan).then(function (taskIds) {
					return Task.batchGet(taskIds).then(function (tasks) {
						_.each(tasks, function (task) {
							Tasks.onTaskUpdated(task, TasksData.get(task.id));
						});
						return taskIds;
					});
				});
			};

			Tasks.massAssign = function (userId, tasks, options = {}) {
				return ActionInterceptorService.run(INTERCEPTABLE_ACTIONS.TASK_ASSIGMENT, {
					tasks: tasks,
					user_id: userId,
					options: options
				}).then(function () {
					return Task.massAssign({
						user_id: userId,
						task_ids: _.map(tasks, 'id'),
						options: options
					});
				});
			};

			Tasks.massUnassign = function (tasks) {
				var options = {};
				return ActionInterceptorService.run(INTERCEPTABLE_ACTIONS.TASK_ASSIGMENT, {
					tasks: tasks,
					user_id: null,
					options: options
				}).then(function () {
					return Task.massUnassign({
						task_ids: _.map(tasks, 'id'),
						options: options
					});
				});
			};

			Tasks.isMultiTeamTasksSelected = function (tasks) {
				if (tasks?.length === 0 || tasks[0]?.team_ids?.length === 0) {
					return false;
				}

				const firstTeamId = tasks[0] && tasks[0].team_ids[0];

				return tasks.some(function (task) {
					return firstTeamId !== task.team_ids[0];
				});
			};

			Tasks.ungroupAll = function (tasks) {
				const groupedTasksIds = tasks.filter(t => t.group_uuid).map(t => t.id);
				Task.massDeleteGroups({ group_leader_task_ids: groupedTasksIds })
					.then(() => {
						toastr.success(TranslationService.instant('DISPATCH_LIST.TOAST_UNGROUPING_SUCCESS'));
					})
					.catch(() => {
						toastr.error(TranslationService.instant('DISPATCH_LIST.TOAST_UNGROUPING_FAILED'));
					});
			};

			Tasks.calcMaxRoutePrice = function (tasks) {
				var routePrice = _.reduce(
					tasks,
					function (price, task) {
						return price + task.total_price || 0;
					},
					0
				);

				// return max 2 decimal places
				return Math.round(routePrice * 100) / 100;
			};

			Tasks.getTaskTypeName = function (taskTypeId) {
				let taskTypeName = TranslationService.instant(TASK_TYPE_TRANSLATIONS[TASK_TYPE_MAP[taskTypeId]]);
				if (!taskTypeName) taskTypeName = TranslationService.instant(TASK_TYPE_TRANSLATIONS.UNDEFINED);
				return taskTypeName;
			};

			Tasks.getTeamName = function (task) {
				var teamId = _.isEmpty(task.team_ids) ? 0 : _.last(task.team_ids);
				var team = Teams.teamsByIdMap[teamId];
				return team ? team.name : $translate.instant('DISPATCH_LIST.NO_TEAM');
			};

			return Tasks;
		}
	);
