'use strict';

angular
	.module('bringgApp')
	.factory('EmployeeScheduledBreaksResource', function (WEB_API_URL, $resource) {
		var EmployeeScheduledBreaksResource = $resource(
			WEB_API_URL + '/users/:id/user_scheduled_breaks/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/users/:id/user_scheduled_breaks/' }
			}
		);

		return EmployeeScheduledBreaksResource;
	})
	.factory('EmployeeScheduledBreaksService', function (EmployeeScheduledBreaksResource) {
		var EmployeeScheduledBreaksService = {};

		EmployeeScheduledBreaksService.get = function (driverId) {
			return EmployeeScheduledBreaksResource.get({ id: driverId }).$promise;
		};

		EmployeeScheduledBreaksService.update = function (driverId, configuration) {
			var update = EmployeeScheduledBreaksResource.update({ id: driverId, configuration: configuration });
			return update.$promise;
		};

		return EmployeeScheduledBreaksService;
	});
