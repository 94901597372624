'use strict';

angular
	.module('bringgApp')
	.controller('EmployeeShiftsController', function EmployeeShiftsController(
		$scope,
		$routeParams,
		Employees,
		Tasks,
		$rootScope,
		Shift,
		ITEMS_PER_PAGE
	) {
		$scope.currentPage = 0;
		$scope.numPerPage = ITEMS_PER_PAGE;
		$scope.totalItems = 0;
		$scope.allShifts = [];

		$rootScope.$watch($rootScope.isLoggedIn, function () {
			if ($rootScope.isLoggedIn) {
				Employees.get({ id: $routeParams.id }, function (data) {
					$scope.employee = data;
				});

				Shift.all({ user_id: $routeParams.id, page: 0 }, function (data) {
					$scope.allShifts[1] = data;
					$scope.currentPage = 1;
				});

				Shift.total({ id: $routeParams.id }, function (result) {
					$scope.totalItems = result.count;
				});
			}
		});

		$scope.$watch('currentPage + numPerPage', function () {
			var begin = ($scope.currentPage - 1) * $scope.numPerPage,
				end = begin + $scope.numPerPage;

			if ($scope.currentPage === 0) {
				return;
			}

			$scope.shifts = $scope.allShifts[$scope.currentPage];

			if (!$scope.shifts) {
				var bringPage = $scope.currentPage;
				Shift.all({ user_id: $routeParams.id, page: bringPage }, function (data) {
					$scope.shifts = data;
					$scope.allShifts[bringPage] = data;
				});
			}
		});
	});
