'use strict';

angular
	.module('bringgApp')
	.factory('PasswordRules', function (WEB_API_URL, $resource) {
		return $resource(
			WEB_API_URL + '/merchant/security_configuration/password_rules',
			{},
			{
				query: { method: 'GET', url: WEB_API_URL + '/merchant/security_configuration/password_rules_token' }
			}
		);
	})
	.factory('PasswordRulesService', function (PasswordRules) {
		var PasswordRulesService = {};

		PasswordRulesService.password_rules = [];

		/**
		 * get password rules
		 * @returns {*}
		 */
		PasswordRulesService.get = function () {
			if (PasswordRulesService.password_rules.$resolved) {
				return PasswordRulesService.password_rules;
			}
			return (PasswordRulesService.password_rules = PasswordRules.get());
		};

		/**
		 * post to get password rules service using reset password token
		 * @param token
		 */
		PasswordRulesService.getUsingToken = function (token) {
			return PasswordRules.query({ auth_token: token });
		};

		return PasswordRulesService;
	});
