/*global createMarker */
/*global google:false */
'use strict';

app.factory('map_utils', function (localStorageService) {
	var map_utils = {};
	map_utils.INITIAL_MAP_TYPE_KEY = 'dispatchMap.initialMapType';
	map_utils.START_WITH_TRAFFIC_LAYER_KEY = 'dispatchMap.startWithTrafficLayer';

	map_utils.destinationMarker = [];
	map_utils.bringgMap = null;

	map_utils.init = function (map) {
		//$scope.myMap
		map_utils.bringgMap = map;
	};

	map_utils.clearDestinationMarkers = function () {
		_.each(map_utils.destinationMarker, function (marker) {
			if (marker) {
				marker.setMap(null);
				google.maps.event.clearListeners(marker, 'click');
			}
		});
		map_utils.destinationMarker = [];
	};

	map_utils.getInitialMapType = function () {
		return localStorageService.get(map_utils.INITIAL_MAP_TYPE_KEY) || google.maps.MapTypeId.ROADMAP;
	};

	map_utils.saveInitialMapType = function (mapTypeID) {
		localStorageService.set(map_utils.INITIAL_MAP_TYPE_KEY, mapTypeID);
	};

	map_utils.CenterControl = function (controlDiv, map) {
		var trafficLayer = new google.maps.TrafficLayer();
		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.position = 'absolute';
		controlUI.style.borderRadius = '3px';
		controlUI.style.boxShadow = '0 0px 2px rgba(0,0,0,.3)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.left = '-10px';
		controlUI.style.top = '10px';
		controlUI.style.textAlign = 'center';
		controlUI.title = 'Traffic';

		controlDiv.appendChild(controlUI);

		var controlText = document.createElement('div');
		//Copied from the map type button of the map library. It's very ugly, but at least this ugly injected button looks the same as the other buttons that are already there.
		controlText.style.cssText =
			'direction: ltr; overflow: hidden; text-align: center; height: 40px; display: table-cell; vertical-align: middle; position: relative; color: rgb(86, 86, 86); font-family: Roboto, Arial, sans-serif; user-select: none; font-size: 18px; background-color: rgb(255, 255, 255); padding: 0px 17px; border-bottom-left-radius: 2px; border-top-left-radius: 2px; background-clip: padding-box; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; min-width: 36px; font-weight: 400;';
		controlText.innerHTML = 'Traffic';
		controlUI.appendChild(controlText);

		if (!_.isNil(localStorageService.get(map_utils.START_WITH_TRAFFIC_LAYER_KEY))) {
			trafficLayer.setMap(map);
			controlText.style.color = 'rgb(0, 0, 0)';
			controlText.style.fontWeight = 500;
		}

		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener('click', function () {
			if (trafficLayer && trafficLayer.getMap()) {
				trafficLayer.setMap(null);
				localStorageService.remove(map_utils.START_WITH_TRAFFIC_LAYER_KEY);
				controlText.style.color = 'rgb(86, 86, 86)';
				controlText.style.fontWeight = 400;
			} else {
				trafficLayer.setMap(map);
				//Can be set to any not nil value
				localStorageService.set(map_utils.START_WITH_TRAFFIC_LAYER_KEY, true);
				controlText.style.color = 'rgb(0, 0, 0)';
				controlText.style.fontWeight = 500;
			}
		});
		this.controlUI = controlUI;
	};

	map_utils.createDefaultZoomButton = function (map, elementId, centerMapByVisibleMarkers, setAutomaticResize) {
		const defaultZoomButton = document.createElement('div');
		defaultZoomButton.id = elementId;
		defaultZoomButton.classList.add('default-zoom-button');
		defaultZoomButton.textContent = 'Default zoom';
		defaultZoomButton.title = 'Default zoom';
		defaultZoomButton.type = 'button';
		defaultZoomButton.addEventListener('click', () => {
			setAutomaticResize(true);
			centerMapByVisibleMarkers();
		});
		return defaultZoomButton;
	};

	map_utils.loadDestinationMarkersByEmployee = function (
		tasks,
		employee,
		destinationMarkerImage,
		destinationMarkerClicked
	) {
		if (!tasks) {
			return;
		}

		map_utils.clearDestinationMarkers();

		_.each(tasks, function (task) {
			var location = map_utils._extractLocationFromTask(task);
			if (location && task.user_id === employee.id) {
				map_utils.destinationMarker.push(
					createMarker(
						map_utils.bringgMap,
						location.lat,
						location.lng,
						destinationMarkerImage,
						task.id,
						destinationMarkerClicked
					)
				);
			}
		});
	};

	map_utils.loadDestinationMarkers = function (tasks, destinationMarkerImage, destinationMarkerClicked, employee) {
		if (!tasks) {
			return;
		}

		tasks.forEach(function (task) {
			var location = map_utils._extractLocationFromTask(task);
			if (location) {
				map_utils.destinationMarker.push(
					createMarker(
						map_utils.bringgMap,
						location.lat,
						location.lng,
						destinationMarkerImage,
						task.id,
						destinationMarkerClicked
					)
				);
			}
		});
	};

	map_utils._extractLocationFromTask = function (task) {
		var location;

		if (task.lat && task.lng) {
			location = { lat: task.lat, lng: task.lng };
		}

		if (!location && task.activeWayPoint) {
			location = { lat: task.activeWayPoint.lat, lng: task.activeWayPoint.lng };
		}

		if (!location && task.way_points.length) {
			location = { lat: task.way_points[0].lat, lng: task.way_points[0].lng };
		}

		return location;
	};

	return map_utils;
});
