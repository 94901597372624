'use strict';

angular.module('bringgApp').service('PreviewMapLoader', function (MerchantConfigurations, $log) {
	var self = this;

	var markersByRouteIdx = {};
	var directionsByRouteIdx = {};
	var markerClusterer = null;

	var dashboardConfig = MerchantConfigurations.dashboard_ui_configuration || {};
	self.enableMarkerClusterer = dashboardConfig && dashboardConfig.enable_marker_clusterer === true;

	var previewMapConfig = (dashboardConfig && dashboardConfig.preview_map_configuration) || {};

	self.MAX_VISIBLE_MARKERS_ALLOWED = previewMapConfig.max_displayed_orders || 300;

	var MAX_SUPPORTED_WAYPOINTS_ON_ROUTE_DIRECTION = 25;

	var getMarkerClusterConfig = function () {
		var config = MerchantConfigurations.dashboard_ui_configuration
			? MerchantConfigurations.dashboard_ui_configuration
			: {};
		return {
			maxZoom: config.marker_cluster_tasks_max_zoom || 13,
			minimumClusterSize: config.marker_cluster_tasks_minimum_size || 5,
			imagePath: 'https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m'
		};
	};

	var hideDirection = function (routeIdx) {
		var routeDirections = directionsByRouteIdx[routeIdx];
		if (routeDirections) {
			routeDirections.setMap(null);
		}
	};

	var getVisibleMarkers = function () {
		var visibleMarkers = [];
		_.each(markersByRouteIdx, function (markers) {
			visibleMarkers = visibleMarkers.concat(_.filter(markers, { visible: true }));
		});
		return visibleMarkers;
	};

	var repaintMarkerClusterer = function (map) {
		var markers = getVisibleMarkers();
		if (_.isNil(markerClusterer)) {
			markerClusterer = new MarkerClusterer(map, markers, getMarkerClusterConfig());
		} else {
			markerClusterer.clearMarkers();
			markerClusterer.addMarkers(markers);
		}
		markerClusterer.setMap(map);
		google.maps.event.addListenerOnce(map, 'idle', function () {
			markerClusterer.repaint();
		});
	};

	var resizeMap = function (map) {
		var visibleMarkers = getVisibleMarkers();
		setAutomaticResize(true);
		resizeMapByMarkers(map, visibleMarkers);
	};

	self.getNumberOfVisibleMarkers = function () {
		var visibleMarkers = getVisibleMarkers();
		return visibleMarkers.length;
	};

	self.isDriverSelectAllowed = function (route) {
		if (self.enableMarkerClusterer) {
			return true;
		}
		return (
			route.visible || this.getNumberOfVisibleMarkers() + route.tasks.length < self.MAX_VISIBLE_MARKERS_ALLOWED
		);
	};

	var createMarker = function (map, task, route, getLocationByTask, onMarkerClicked) {
		var position = getLocationByTask(task);
		var marker = new google.maps.Marker({
			id: task.id,
			routeIdx: route.routeIdx,
			draggable: false,
			centered: false,
			position: { lat: position[0], lng: position[1] },
			icon: route.icon,
			label: { text: task.priority.toString(), color: route.labelColor },
			optimized: true,
			visible: true
		});
		marker.addListener('click', function (event, task) {
			onMarkerClicked(event, task);
		});
		if (!self.enableMarkerClusterer) {
			marker.setMap(map);
		}
		return marker;
	};

	var createDirections = function (map, route) {
		var wayPoints = route.directions.wayPoints;
		if (wayPoints.length >= MAX_SUPPORTED_WAYPOINTS_ON_ROUTE_DIRECTION) {
			$log.info(
				'OptimizationPreviewMapDirectiveService - directions were not created, numberOfWaypoints=' +
					wayPoints.length
			);
			return Promise.resolve();
		}

		var directionsService = new google.maps.DirectionsService();
		var directionsDisplay = new google.maps.DirectionsRenderer();
		var directionsOptions = {
			suppressMarkers: true,
			polylineOptions: route.directions.polylineOptions,
			draggable: false,
			preserveViewport: true,
			hideRouteList: true
		};

		directionsDisplay.setOptions(directionsOptions);

		return directionsService
			.route({
				origin: wayPoints[0],
				destination: wayPoints[wayPoints.length - 1],
				travelMode: google.maps.TravelMode.DRIVING,
				waypoints: wayPoints,
				optimizeWaypoints: false
			})
			.then(function (directions) {
				if (directionsByRouteIdx[route.routeIdx]) {
					directionsByRouteIdx[route.routeIdx].setMap(null);
				}
				directionsDisplay.setDirections(directions);
				directionsDisplay.setMap(map);
				directionsByRouteIdx[route.routeIdx] = directionsDisplay;
			})
			.catch(function (e) {
				$log.info('OptimizationPreviewMapDirectiveService - Directions request failed due to ' + e);
			});
	};

	var clearMarkersListeners = function () {
		_.each(markersByRouteIdx, function (marker) {
			google.maps.event.clearListeners(marker, 'click');
		});
	};

	self.clearListeners = function (map) {
		clearMarkersListeners();
		google.maps.event.clearListeners(map, 'idle');
	};

	self.clearData = function () {
		markersByRouteIdx = {};
		directionsByRouteIdx = {};
	};

	var addRoutesToMap = function (data) {
		_.each(data.changedRoutes, function (route) {
			var routeIdx = route.routeIdx;
			var routeMarkers = markersByRouteIdx[routeIdx] || [];
			var routeDirections = directionsByRouteIdx[routeIdx];

			if (!routeMarkers.length || data.forceFetch) {
				_.each(routeMarkers, function (marker) {
					marker.setMap(null);
				});
				routeMarkers = [];
				_.each(route.tasks, function (task) {
					routeMarkers.push(
						createMarker(data.map, task, route, data.getLocationByTask, data.onMarkerClicked)
					);
				});
			} else {
				_.each(routeMarkers, function (marker) {
					marker.setVisible(true);
				});
			}

			markersByRouteIdx[routeIdx] = routeMarkers;

			if (!routeDirections || data.forceFetch) {
				createDirections(data.map, route);
			} else {
				routeDirections.setMap(data.map);
			}
		});

		if (self.enableMarkerClusterer) {
			repaintMarkerClusterer(data.map);
		}
	};

	self.loadRoutesAndMarkers = function (map, changedRoutes, getLocationByTask, onMarkerClicked) {
		addRoutesToMap({
			map: map,
			changedRoutes: changedRoutes,
			getLocationByTask: getLocationByTask,
			onMarkerClicked: onMarkerClicked,
			forceFetch: true
		});
		resizeMap(map);
	};

	self.showRoute = function (map, route, getLocationByTask, onMarkerClicked) {
		addRoutesToMap({
			map: map,
			changedRoutes: [route],
			getLocationByTask: getLocationByTask,
			onMarkerClicked: onMarkerClicked
		});
		resizeMap(map);
	};

	self.hideRoute = function (map, routeIdx) {
		_.each(markersByRouteIdx[routeIdx], function (marker) {
			marker.setVisible(false);
		});
		if (self.enableMarkerClusterer) {
			repaintMarkerClusterer(map);
		}
		hideDirection(routeIdx);
		resizeMap(map);
	};
});
