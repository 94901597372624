'use strict';
/*global merge_objects:false */
angular
	.module('bringgApp')
	.factory('Merchant', function ($rootScope, $q, BringgSDK) {
		let _merchantSdk;

		function MerchantService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.merchant[method].apply(bringg.merchant, args);
				});
			}.bind(this);
		}

		BringgSDK.getInstancePromise().then(function (bringg) {
			_merchantSdk = bringg.merchant;
		});

		var merchant = {};

		merchant.get = MerchantService('get');
		merchant.update = MerchantService('update');
		merchant.all = MerchantService('getAll');
		merchant.createMerchantByAccountManager = MerchantService('createMerchantByAccountManager');
		merchant.copyMerchantSettingsValidate = MerchantService('validateCopyMerchantSettings');
		merchant.copyMerchantSettings = MerchantService('copyMerchantSettings');
		merchant.proFeaturesEnabled = MerchantService('proFeaturesEnabled');
		merchant.featureFlags = MerchantService('featureFlags');

		merchant.getLocal = () => {
			return _merchantSdk.getLocal();
		};

		return merchant;
	})
	.factory('MerchantService', function ($rootScope, $q, BringgSDK, Merchant) {
		return {
			get: Merchant.get,
			proFeaturesEnabled: Merchant.proFeaturesEnabled,
			featureFlags: Merchant.featureFlags
		};
	});
