/**
 * Created by liorsion on 9/15/15.
 */
'use strict';

angular
	.module('bringgApp')
	.factory('Tag', function ($resource, WEB_API_URL) {
		var Tag = $resource(
			WEB_API_URL + '/tags/:id',
			{ id: '@id' },
			{
				all: { method: 'GET', isArray: true, url: WEB_API_URL + '/tags/' },
				update: { method: 'PUT', url: WEB_API_URL + '/tags/:id' },
				create_tag: { method: 'POST', url: WEB_API_URL + '/tags/create_tag' },
				create_tag_merchant_configuration: {
					method: 'POST',
					url: WEB_API_URL + '/tags/:id/create_tag_merchant_configuration'
				},
				create_tag_customer_configuration: {
					method: 'POST',
					url: WEB_API_URL + '/tags/:id/create_tag_customer_configuration'
				},
				create_tag_rules_configuration: {
					method: 'POST',
					url: WEB_API_URL + '/tags/:id/create_tag_rules_configuration'
				},
				delete_tag_merchant_configuration: {
					method: 'DELETE',
					url: WEB_API_URL + '/tags/:id/delete_tag_merchant_configuration'
				},
				delete_tag_customer_configuration: {
					method: 'DELETE',
					url: WEB_API_URL + '/tags/:id/delete_tag_customer_configuration'
				},
				delete_tag_rules_configuration: {
					method: 'DELETE',
					url: WEB_API_URL + '/tags/:id/delete_tag_rules_configuration'
				},
				destroy: { method: 'DELETE', url: WEB_API_URL + '/tags/:id' }
			}
		);

		return Tag;
	})
	.factory('Tags', function ($rootScope, Tag, $q) {
		var Tags = { tags: [], tagsByIdMap: null };

		$rootScope.$on('loggedout', function (nothing, newAlert) {
			Tags.tags = null;
		});

		$rootScope.$on('loggedin', function (nothing, newAlert) {
			Tags._initTags();
		});

		Tags._initTags = function () {
			Tags.tags = Tag.all();
		};

		Tags._initTags();

		Tags.all = function (cb) {
			if (Tags.tags.$resolved) {
				if (cb) {
					cb(Tags.tags);
				}

				if (!Tags.tagsByIdMap) {
					Tags.tagsByIdMap = _.keyBy(Tags.tags, 'id');
				}

				return Tags.tags;
			} else {
				var _self = this;

				Tags.tags.$promise.then(function () {
					return _self.all(cb);
				});
				return Tags.tags;
			}
		};

		Tags.remove = function (tagId) {
			return Tag.destroy({ id: tagId }).$promise.then(function (response) {
				if (response.success) {
					return Tags.tags.$promise.then(function (tags) {
						var tagIdx = _.findIndex(tags, { id: tagId });
						if (tagIdx !== -1) {
							tags.splice(tagIdx, 1);
							Tags.tagsByIdMap = _.omit(Tags.tagsByIdMap, tagId);
						}
						return $q.resolve({ success: true, tags: tags });
					});
				} else {
					return $q.resolve(response);
				}
			});
		};

		Tags.tagsNamesById = function (ids) {
			return _.map(ids, function (tagId) {
				return Tags.tagsByIdMap[tagId].tag;
			});
		};

		return Tags;
	});
