'use strict';

angular
	.module('bringgApp.directives')
	.directive('customerSelect', function (Customers, $log) {
		return {
			restrict: 'A',
			require: '',
			link: function (scope, element, attrs, ngModel) {
				scope.customers = [];
				scope.stop = false;
				scope.lastLength = 0;

				scope._getNewItem = function () {
					return {
						name: scope.lastString,
						id: null,
						isTag: true
					};
				};

				scope.ErrorCallback = function (reason) {
					$log.error(reason);
				};

				scope.refreshCustomers = function (value) {
					scope.lastString = value;

					if (!value || value.length < 3) {
						if (value && value.length > 0) {
							scope.customers = [scope._getNewItem()];
						}
						return;
					}

					if (scope.stop) {
						if (scope.lastLength < value.length) {
							scope.customers[0].name = value;
							return;
						} else {
							scope.stop = false;
						}
					}

					scope.lastLength = value.length;

					Customers.find({ name: value }).then(function (data) {
						scope.customers = [scope._getNewItem()].concat(data.results || []);
						if (data.results.length === 0) {
							scope.stop = true;
						}
					});
				};
			}
		};
	})
	.directive('customerSelectByPhone', function (Customers, $log) {
		return {
			restrict: 'A',
			require: '',
			link: function (scope, element, attrs, ngModel) {
				scope.customers = [];

				scope.handlePhoneSelected = function ($item, $model, way_point) {
					if ($item.isTag) {
						way_point.phone = $item.phone;
					} else {
						scope.handleCustomerSelected($item, $model, way_point);
					}
				};

				scope.refreshCustomersByPhone = function (value) {
					Customers.find({ phone: value }).then(function (data) {
						scope.customers = data.results || [];

						var isFoundMatchingPhone = _.find(scope.customers, { phone: value });
						if (value && !isFoundMatchingPhone) {
							data.results.unshift({
								name: value,
								phone: value,
								id: null,
								isTag: true
							});
						}
					});
				};
			}
		};
	});
