'use strict';

angular
	.module('bringgApp.directives')
	.directive('kdsTaskCard', function (
		DispatcherAcknowledgeTaskStatusService,
		MerchantConfigurations,
		DEFAULT_PREPARATION_SCREEN,
		TASK_DEFAULT_TYPES
	) {
		return {
			templateUrl: 'scripts/features/kds/directives/kds-task-card-directive.html',
			scope: {
				task: '=',
				column: '@?',
				nextStatus: '=',
				handleClick: '=?'
			},
			link: function (scope) {
				scope.pickupWayPoint = _.first(scope.task.way_points);

				scope.isClickAndCollect = function () {
					return scope.task.task_type_id === TASK_DEFAULT_TYPES.CLICK_AND_COLLECT;
				};

				scope.shouldDisplayColorAndNumber = function () {
					if (!scope.isClickAndCollect()) {
						return false;
					}
					var uiData = scope.pickupWayPoint.ui_data;
					if (!_.isObject(uiData)) {
						return false;
					}
					return _.isString(uiData.color) && _.isNumber(uiData.number);
				};

				scope.pickupColor = function () {
					return { 'background-color': _.get(scope, 'pickupWayPoint.ui_data.color') };
				};

				scope.handleCardClick = function () {
					var modalConfig = {
						closeModal: {
							showing: true,
							translation: 'DISPATCHER_ACKNOWLEDGE.CLOSE',
							className: 'btn-green'
						},
						remindMe: {
							showing: false
						},
						reject: {
							showing: false
						},
						acknowledge: {
							showing: false
						},
						removeOnClose: true
					};

					var flowUiConfig = _.extend(
						{},
						DEFAULT_PREPARATION_SCREEN,
						MerchantConfigurations.preparation_flow_ui_config,
						modalConfig
					);

					DispatcherAcknowledgeTaskStatusService.addTask(scope.task, flowUiConfig, null, null, true, -1);
				};
			}
		};
	});
