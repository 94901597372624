'use strict';

angular
	.module('bringgApp.directives')
	.directive('optimizeButton', function ($timeout, OPTIMIZATION_TYPES, InProcessOptimization) {
		return {
			scope: {
				tasks: '=',
				enableRouteOptimization: '=',
				selectedItems: '=',
				handleOptimizeSelectedClicked: '=',
				handleOptimizeNewAndSaveExistingClicked: '=',
				showOptimizeNewAndSaveExisting: '=',
				isOptimizationNewAndSaveExistingDisabled: '=',
				optimizeAndSaveExistingTooltipText: '='
			},

			templateUrl: 'scripts/directives/tasks/optimize-button/optimize-button.html',

			link: function (scope) {
				scope.optimizationProgressValue = null;
				scope.optimizationProgressMax = 100;

				// progress bar update came outside of scope (socket) so we need to do apply
				// because there are multi updates we added throttle
				scope.updateProgressBar = function (progress) {
					$timeout(function () {
						// prevent race when realtime send done before updates
						scope.optimizationProgressValue = Number((progress.iteration / progress.totalIterations) * 100);
					});
				};

				var throttled = _.throttle(scope.updateProgressBar, 100);

				scope.$on('optimization progress', function (event, progress) {
					if (Object.keys(InProcessOptimization.pendingOptimizationUUIDsProperties).length) {
						throttled(progress);
					}
				});

				function clearProgressValue() {
					$timeout(function () {
						scope.optimizationProgressValue = null;
					}, 150);
				}

				scope.$on('all optimizations done', function () {
					clearProgressValue();
				});

				scope.$on('all optimizations cancelled', function () {
					clearProgressValue();
				});

				scope.stopOptimization = function () {
					return InProcessOptimization.cancelAllOptimizations();
				};

				scope.isEnabled = function () {
					return scope.enableRouteOptimization && !scope.optimizationProgressValue && !_.isEmpty(scope.tasks);
				};
			}
		};
	});
