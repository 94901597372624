'use strict';

angular
	.module('bringgApp')
	.controller(
		'AllCustomersController',
		function (
			$scope,
			$routeParams,
			$rootScope,
			Customers,
			ITEMS_PER_PAGE,
			AddCustomerService,
			$translate,
			localStorageService,
			Authentication,
			PhoneNumberService
		) {
			$scope.currentPage = 1;
			$scope.numPerPage = ITEMS_PER_PAGE;
			$scope.totalItems = 0;
			$scope.customers = [];
			$scope.loading = true;
			$scope.advancedSearch = false;
			$scope.replaceIdByExternalId = false;
			$scope._searchDisabled = false;
			$scope.customerKinds = Customers.getCustomerKinds();

			$scope.headers = [
				{
					column: 'external_id',
					title: 'CUSTOMERS.ID',
					default: true
				},
				{
					column: 'name',
					title: 'CUSTOMERS.NAME'
				},
				{
					column: 'address',
					title: 'CUSTOMERS.ADDRESS'
				},
				{
					column: 'phone',
					title: 'CUSTOMERS.PHONE'
				},
				{
					column: null,
					title: 'CUSTOMERS.ORDERS'
				},
				{
					column: null,
					title: 'CUSTOMERS.KINDS'
				}
			];

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.replaceIdByExternalId = Boolean(featureFlags.enable_external_id_display);
				if ($scope.replaceIdByExternalId) {
					const idHeader = $scope.headers.find(function (header) {
						return header.column === 'external_id';
					});
					idHeader.title = 'CUSTOMERS.EXTERNAL_ID';
				}
			});

			$rootScope.$watch($rootScope.isLoggedIn, function () {
				if ($rootScope.isLoggedIn) {
					$scope.loadCustomers();
				}
			});

			$scope.formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

			$scope.getCustomerKindLabel = function (kindId) {
				return $scope.customerKinds.find(customerKind => customerKind.value === kindId)?.label;
			};

			$scope.handleSearchButtonClick = function () {
				if (!$scope.customerName && !$scope.customerId && !$scope.customerAddress && !$scope.customerPhone) {
					toastr.error('You must use at least one field for the search');
					return;
				}

				if ($scope._searchDisabled) {
					toastr.error('Please wait few seconds before doing another search.');
					return;
				}

				$scope._searchDisabled = true;
				$scope
					.loadCustomers()
					.then(function () {
						$scope._searchDisabled = false;
					})
					.catch(function () {
						$scope._searchDisabled = false;
					});
			};

			$scope.handleAdvancedSearchButtonClick = function () {
				$scope.advancedSearch = !$scope.advancedSearch;
			};

			$scope.handleAddCustomerClick = function () {
				AddCustomerService.open().then(
					function (result) {},
					function (reason) {}
				);
			};

			/**
			 * handle realtime
			 */
			$scope.$on('newCustomerAdded', function (event, newCustomer) {
				if ($scope.currentPage === 1) {
					$scope.customers.unshift(newCustomer);
				}
				$scope.totalItems++;
			});

			/**
			 * load customers
			 */

			$scope._getQueryObject = function () {
				if (!$scope.advancedSearch) {
					return { name: $scope.customerName, advanced: true };
				} else {
					return {
						name: $scope.customerName,
						address: $scope.customerAddress,
						phone: $scope.customerPhone,
						id: $scope.customerId,
						advanced: true
					};
				}
			};

			$scope.isNextButtonDisabled = function () {
				return $scope.loading || _.isNil($scope.nextCursor);
			};

			$scope.isPreviousButtonDisabled = function () {
				return $scope.loading || _.isNil($scope.prevCursor);
			};

			var getCursorFromAction = function (action) {
				switch (action) {
					case 'next':
						return $scope.nextCursor;
					case 'previous':
						return $scope.prevCursor;
					default:
						return null;
				}
			};

			var getPageActionFromAction = function (action) {
				switch (action) {
					case 'next':
						return action;
					case 'previous':
						return action;
					default:
						return null;
				}
			};

			$scope.loadCustomers = function (action) {
				return Customers.all(
					{
						cursor: getCursorFromAction(action),
						pageAction: getPageActionFromAction(action),
						query: JSON.stringify($scope._getQueryObject())
					},
					function (data) {
						$scope.loading = false;
						$scope.customers = data.items;
						$scope.nextCursor = data.next_page_cursor;
						$scope.prevCursor = data.previous_page_cursor;
					}
				);
			};
		}
	);
