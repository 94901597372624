'use strict';
angular
	.module('bringgApp')
	.controller('EditMapOverlayController', function (
		$scope,
		$uibModalInstance,
		MapOverlays,
		GeoJsonService,
		toastr,
		mapOverlay
	) {
		$scope.id = mapOverlay.id;
		$scope.title = mapOverlay.title;
		$scope.description = mapOverlay.description;
		$scope.map_overlay = mapOverlay.map_overlay;
		$scope.filename = mapOverlay.filename;
		$scope.hidden = mapOverlay.hidden;

		$scope.close = function () {
			$uibModalInstance.dismiss();
		};

		$scope.sending = false;
		$scope.geoJsonFileContent = '';

		$scope.handleGeoJsonFileSelect = function (evt) {
			var file = evt.files[0];
			var reader = new FileReader();

			reader.onload = function (result) {
				var content = result.target.result;
				if (GeoJsonService.isValidGeoJson(content)) {
					$scope.geoJsonFileContent = content;
					$scope.filename = file.name;
					$scope.$apply();
				} else {
					toastr.error('file is not a valid geojson');
				}
			};

			reader.readAsText(file);
		};

		$scope.handleDeleteMapOverlayButtonClicked = function (evt) {
			MapOverlays.delete($scope.id, function (result) {
				if (result.success) {
					toastr.success('Map overlay was updated successfully!');
					$uibModalInstance.close('deleted');
				} else {
					$scope.sending = false;
					toastr.error(result.message);
				}
			});
		};

		$scope.save = function () {
			if ($scope.cantSave()) {
				return;
			}

			$scope.sending = true;

			MapOverlays.update(
				{
					id: $scope.id,
					title: $scope.title,
					description: $scope.description,
					hidden: $scope.hidden,
					filename: $scope.filename,
					map_overlay: $scope.geoJsonFileContent
				},
				function (result) {
					if (result.success) {
						toastr.success('Map overlay was deleted successfully!');
						$uibModalInstance.close('updated');
					} else {
						$scope.sending = false;
						toastr.error(result.message);
					}
				},
				function (errorResult) {
					$scope.sending = false;
					toastr.error('Error - missing data or bad format');
				}
			);
		};

		$scope.cantSave = function () {
			return _.isEmpty($scope.title) || $scope.sending;
		};
	});
