'use strict';

angular.module('bringgApp').directive('fixOrderAlert', function ($uibModal, Tasks) {
	return {
		restrict: 'E',
		templateUrl: 'scripts/features/fix_order_popup/fix-order-alert.html',
		scope: {
			color: '<',
			fontSize: '<',
			onTasksLengthChanged: '&'
		},
		link: function (scope, element) {
			scope.tasks = [];

			Tasks.getInvalidTasks().then(function (tasks) {
				if (tasks.length) {
					scope.tasks = _.filter(tasks, function (task) {
						return task.invalidated === true;
					});
				}
			});

			scope.$on('invalid task added', function (event, task) {
				var index = _.findIndex(scope.tasks, { id: task.id });

				if (index === -1) {
					scope.tasks.push(task);
				}
			});

			scope.$on('invalid task removed', function (event, task) {
				var index = _.findIndex(scope.tasks, { id: task.id });

				if (index !== -1) {
					scope.tasks.splice(index, 1);
				}
			});

			scope.openFixOrderModal = function () {
				return $uibModal.open({
					windowClass: 'fix-orders-modal-open',
					templateUrl: 'scripts/features/fix_order_popup/fix-order.html',
					controller: 'FixOrderController',
					resolve: {
						tasks: function () {
							return scope.tasks;
						}
					}
				});
			};
			scope.init = function () {
				if (!scope.color) {
					scope.color = 'rgba(255,255,255,0.5)';
				} else {
					scope.color = '#fff';
				}
			};

			scope.$watch('tasks', newVal => {
				scope.onTasksLengthChanged({ length: newVal.length });
			});

			scope.init();
			element.on('click', scope.openFixOrderModal);
		}
	};
});
