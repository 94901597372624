'use strict';

angular.module('bringgApp').factory('PlannedRoutesService', function (BringgSDK) {
	function plannedRoutesService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (sdk) {
				return sdk.plannedRoutes[method].apply(sdk.plannedRoutes, args);
			});
		};
	}

	return {
		getAll: plannedRoutesService('getAll'),
		getAllByTeam: plannedRoutesService('getAllByTeam')
	};
});
