'use strict';

/*globals google:false */

angular
	.module('bringgApp.directives')
	.directive('mobilePreview', function () {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/mobile_preview/mobile-preview.html',
			replace: true,
			scope: {
				baseColor: '=',
				textColor: '=',
				logo: '=',
				driverPin: '=',
				destinationPin: '=',
				visible: '=?',
				isNewCxEnabled: '=',
				newCxConfig: '='
			},
			controller: 'mobilePreviewController'
		};
	})
	.controller('mobilePreviewController', function (
		$scope,
		map_utils,
		MerchantConfigurations,
		$timeout,
		CustomerConfiguration
	) {
		$scope.mapOptions = {
			center: new google.maps.LatLng(MerchantConfigurations.lat, MerchantConfigurations.lng),
			zoom: 14,
			maxZoom: 18,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			draggable: false,
			gestureHandling: 'none',
			zoomControl: false,
			clickableIcons: false
		};

		$scope.$watch('visible', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			$timeout(function () {
				google.maps.event.trigger($scope.myMap, 'resize');
			});
		});

		$scope.destinationpin = '/images/pin_destination.png';
		$scope.driverpin = '/images/pin_delivery.png';

		$scope._applyStyles = function () {
			CustomerConfiguration.get(function (data) {
				$scope.myMap.setOptions({ styles: data.map_styles });
			});
		};

		$scope._applyStyles();
	});
