'use strict';
angular
	.module('bringgApp')
	.factory('PrintTasksService', function (
		PrinterService,
		TaskInventoriesService,
		MerchantConfigurations,
		Teams,
		CrossApplicationService
	) {
		var TasksPrint = {};

		/**
		 * prepare task for print
		 */
		TasksPrint.prepareTaskForPrint = function (task) {
			task.way_point = _.last(task.way_points);
			task.way_point.task_inventories = TaskInventoriesService.getInventories(task, task.way_point);
			if (task.team_ids && task.team_ids.length) {
				task.team = Teams.fetch(task.team_ids[0]);
			}

			// copy task notes from one object to another
			task.way_point.notes = _.chain(task.task_notes)
				.filter({ way_point_id: task.way_point.id })
				.map(function (taskNote) {
					return taskNote.note;
				})
				.valueOf();

			return task;
		};

		/**
		 * print tasks collection
		 */
		TasksPrint.printTasks = function (tasks) {
			if (!tasks) {
				return;
			}
			tasks = _.isArray(tasks) ? tasks : [tasks];
			tasks = _.chain(tasks).map(_.clone).map(TasksPrint.prepareTaskForPrint).valueOf();

			//This would called on clicking the print button
			var printData = {
				tasks: tasks,
				config: { logo: MerchantConfigurations.logo, color: MerchantConfigurations.header_color }
			};
			PrinterService.printTasksFromDirective(printData);
		};

		TasksPrint._subscribeToPrintRequests = function () {
			CrossApplicationService.on('PRINT_TASKS_REQUEST', function (tasks) {
				TasksPrint.printTasks(tasks);
			});
		};

		TasksPrint._subscribeToPrintRequests();

		return TasksPrint;
	});
