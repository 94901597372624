'use strict';

angular.module('bringgApp').factory('ReasonToChangeInventoryService', function ($rootScope, BringgSDK) {
	function reasonToChangeInventory(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.reasonToChangeInventory[method].apply(bringg.reasonToChangeInventory, args);
			});
		};
	}

	var ReasonToChangeInventoryService = {};

	ReasonToChangeInventoryService.getAll = reasonToChangeInventory('getAll');

	return ReasonToChangeInventoryService;
});
