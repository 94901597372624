'use strict';
angular
	.module('bringgApp')
	.constant('ADDRESS_TYPES', {
		COMMERCIAL: 1,
		RESIDENTIAL: 2,
		EDUCATIONAL: 3,
		GOVERNMENT: 4,
		MEDICAL: 5,
		INDUSTRIAL: 6
	})
	.filter('addressTypeName', function (ADDRESS_TYPES) {
		return function (addressType) {
			var ADDRESS_TYPES_NAMES = _.invert(ADDRESS_TYPES);
			return ADDRESS_TYPES_NAMES[addressType];
		};
	})
	.controller(
		'TaskController',
		function (
			$scope,
			MerchantConfigurations,
			$window,
			Teams,
			$q,
			LoadConstraintsService,
			DriverConfigurations,
			VehiclesService,
			VehicleTypesService,
			UserTypes,
			RouteAssigner,
			AssignDriverValidations,
			// PrintTasksService needs to be initialized to _subscribeToPrintRequests
			PrintTasksService
		) {
			let _isClusterModeEnabled;
			let _vehicleTypeByVehicleId;
			let _userTypes;

			$scope.data = { selectedTeams: [] };
			$scope.orderDetailsParams = {
				deps: {
					getWeightAndTotalWeight: function (tasks, run, driver, teamPlannedRoutes) {
						return LoadConstraintsService.getWeightAndTotalWeight(
							{
								vehicleTypeByVehicleId: _vehicleTypeByVehicleId,
								userTypes: _userTypes,
								employees: [driver],
								plannedRoutes: teamPlannedRoutes
							},
							tasks,
							{
								isClusterModeEnabled: _isClusterModeEnabled,
								doNotMultipleWeightByQuantity:
									MerchantConfigurations.ignore_inventory_quantity_for_capacity_calculation,
								dynamicCapacityMode: MerchantConfigurations.dynamic_capacity_values_mode
							},
							run,
							driver
						);
					},
					canOpenPlannedRunsUserAssigner: RouteAssigner.canOpenPlannedRunsUserAssigner(),
					isAllowedToAssignSpecificTask: AssignDriverValidations.isAllowedToAssignSpecificTask
				}
			};

			function onLoaded(results) {
				_isClusterModeEnabled = results[0];
				_vehicleTypeByVehicleId = VehicleTypesService.getVehicleTypeByVehicleIds(results[1], results[2]);
				_userTypes = results[3] && results[3].user_types;
			}

			function init() {
				var promises = [
					DriverConfigurations.isClusterModeEnabled(),
					VehiclesService.getAll(),
					VehicleTypesService.getAll(),
					UserTypes.getAllAsync()
				];

				if (MerchantConfigurations.enable_teams) {
					promises.push(Teams.all());
				}

				return $q
					.all(promises)
					.then(onLoaded)
					.catch(function (err) {
						if (err.status === 404) {
							alert('Task was not found');
							$window.history.back();
						}

						console.error('Error loading task', err);
					});
			}

			$scope.$on('config updated', init);

			init();
		}
	);
