'use strict';

angular
	.module('bringgApp')
	.controller(
		'AllDriversController',
		function (
			$scope,
			Employees,
			UserTypes,
			Employee,
			ITEMS_PER_PAGE,
			MerchantConfigurations,
			Authentication,
			AddEmployeeService,
			MerchantService,
			$translate,
			$filter,
			$q,
			localStorageService,
			QrCodeGeneratorService,
			$log,
			VehiclesService,
			DEFAULT_RT_THROTTLE,
			ReactModals,
			TranslationService,
			CrossApplicationService,
			PhoneNumberService,
			CROSS_APP_ACTIONS
		) {
			$scope.replaceIdByExternalId = false;
			$scope.headers = [
				{ id: 'id', title: $translate.instant('DRIVERS.ID') },
				{ id: 'name', title: $translate.instant('DRIVERS.NAME') },
				{ id: 'phone', title: $translate.instant('DRIVERS.PHONE') },
				{ id: 'job_description', title: $translate.instant('DRIVERS.JOB_DESCRIPTION') }
			];

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.replaceIdByExternalId = Boolean(featureFlags.enable_external_id_display);
				if ($scope.replaceIdByExternalId) {
					const externalIdHeader = { id: 'external_id', title: $translate.instant('DRIVERS.EXTERNAL_ID') };
					$scope.headers = [externalIdHeader].concat(
						$scope.headers.filter(function (header) {
							return header.id !== 'id';
						})
					);

					$scope.sort = {
						header: externalIdHeader,
						customSorter: externalIdHeader.id,
						descending: false
					};
				}
			});

			let eventSubscriptions = [];

			const sortHeader = _.first($scope.headers);
			$scope.sort = {
				header: sortHeader,
				customSorter: sortHeader.id,
				descending: false
			};
			$scope.currentPage = 0;
			$scope.numPerPage = ITEMS_PER_PAGE;
			$scope.totalItems = 0;
			$scope.allDrivers = [];
			$scope.readonly = Authentication.currentUser().partner_user;
			$scope.currentUser = Authentication.currentUser();
			$scope.driversSelectedVehicles = {};
			$scope.noVehicle = { id: 0, name: 'No Vehicle' };

			$scope._addHeader = function (id, title, enable, customSortFunction) {
				var headerExists = _.find($scope.headers, { id: id });

				if (enable) {
					if (_.isUndefined(headerExists)) {
						$scope.headers.push({ id: id, title: title, customSorter: customSortFunction });
					}
				} else {
					if (!_.isUndefined(headerExists)) {
						$scope.headers = _.without($scope.headers, headerExists);
					}
				}
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (!isLoggedIn) {
					return;
				}

				$scope.current_user = Authentication.currentUser();
			});

			/**
       * example for object

       {
      filter: 'driverUserTypeId',
      values: {
        '1': '#45435',
        '2': '#afafdfd'
      },
      default: '#231312'
    }

       {
      filter: 'driverOnShift',
      values: {
        'true': '#45435',
        'false': '#afafdfd'
      },
      default: '#231312'
    }

       * @param driver
       * @returns {{background-color: *}}
       */

			CrossApplicationService.on(CROSS_APP_ACTIONS.DRIVER_CREATED, user => {
				Employees.updateEmployee(user);
			});

			$scope.getDriverRowStyle = function (driver) {
				if (_.isEmpty($scope.customDriversPageRowColors)) {
					return {};
				}
				var values = $scope.customDriversPageRowColors.values || {};
				var defaultValue = $scope.customDriversPageRowColors.default;
				var filterName = $scope.customDriversPageRowColors.filter;

				var backgroundColor = _.isUndefined(defaultValue) ? null : defaultValue;

				if (filterName) {
					var filterResult = $filter(filterName)(driver);
					backgroundColor = values[filterResult] || backgroundColor;
				}

				return {
					'background-color': backgroundColor
				};
			};

			$scope.getDriverUserTypes = function (driver) {
				var result = $scope.userTypes;
				if (!_.isEmpty(driver.allowed_user_types)) {
					result = [UserTypes.emptyUserType].concat(driver.allowed_user_types);
				}
				return result;
			};

			$scope._configure = function () {
				$scope.teamsEnabled = MerchantConfigurations.enable_teams || false;
				$scope.shiftsActionsEnabled = MerchantConfigurations.enable_shifts_actions || false;
				$scope.customDriversPageRowColors = MerchantConfigurations.custom_drivers_page_row_colors || false;
				$scope.qrCodeLoginEnabled =
					MerchantConfigurations.qr_login_flow_options ==
						QrCodeGeneratorService.appearance_options.ON_DRIVER_LIST ||
					MerchantConfigurations.qr_login_flow_options ==
						QrCodeGeneratorService.appearance_options.ON_ALL_LOCATIONS;
				$scope.markHomeEnabled =
					MerchantConfigurations.driver_at_home_flow_options ==
						MerchantConfigurations.APPEARANCE_OPTIONS.ON_DRIVER_LIST ||
					MerchantConfigurations.driver_at_home_flow_options ==
						MerchantConfigurations.APPEARANCE_OPTIONS.ON_ALL_LOCATIONS;

				$scope.cashOutFlowEnabled = MerchantConfigurations.cash_out_flow_enabled || false;
				$scope.debriefFlowEnabled = MerchantConfigurations.debrief_flow_enabled || false;

				$scope._addHeader('active_shift_id', $translate.instant('DRIVERS.SHIFT'), $scope.shiftsActionsEnabled);
				$scope._addHeader(
					'teams',
					$translate.instant('DRIVERS.TEAMS'),
					$scope.teamsEnabled,
					$scope.teamsCustomSortFunction
				);
				$scope._addHeader('user_type_id', $translate.instant('DRIVERS.USER_TYPE'), $scope.userTypesEnabled);
				$scope._addHeader('vehicle_id', $translate.instant('DRIVERS.VEHICLE'), $scope.vehiclesEnabled);
				$scope._addHeader('cash_out', 'Cash Out', $scope.cashOutFlowEnabled);
				$scope._addHeader('debrief', $translate.instant('DRIVERS.DEBRIEF'), $scope.debriefFlowEnabled);
				$scope._addHeader('home', $translate.instant('DRIVERS.CLOCK_IN_OUT_TITLE'), $scope.markHomeEnabled);
				$scope._addHeader(
					'qr_code_login',
					$translate.instant('QR_CODE_GENERATOR.GENERATE_QR_CODE'),
					$scope.qrCodeLoginEnabled
				);
			};
			$scope.$on('config updated', $scope._configure);

			$scope._handleNewEmployeeAdded = function (event, employee) {
				if (_.isEmpty(employee)) {
					$log.info('modal closed not adding user');
					return;
				}

				if (!employee.driver) {
					return;
				}

				var exists = _.find($scope.allDrivers, { id: employee.id });
				if (!exists) {
					$scope.allDrivers.unshift(employee);
					$scope.totalItems++;
				}
				$scope.driversSelectedVehicles[employee.id] = 0;
			};

			$scope.$on('newEmployeeAdded', $scope._handleNewEmployeeAdded);

			$scope.formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

			$scope.teamsCustomSortFunction = function (driver) {
				return _.sortBy(_.map(driver.teams, 'name')).join(' ');
			};

			$scope.changeSorting = function (header) {
				// same index if, change order
				if ($scope.sort.header === header) {
					$scope.sort.descending = !$scope.sort.descending;
				} else {
					$scope.sort.header = header;
					$scope.sort.customSorter = header.customSorter || header.id;
					$scope.sort.descending = false;
				}
				localStorageService.set('all-drivers.sort', {
					headerId: $scope.sort.header.id,
					descending: $scope.sort.descending
				});
			};

			$scope.driverFilter = function (driver) {
				if (_.isEmpty($scope.driver_query)) return true;

				var query = $scope.driver_query.toLowerCase();

				var team_names = _.map(driver.teams, 'name').join().toLowerCase();
				if (team_names.indexOf(query) >= 0) return true;

				return _.chain(driver)
					.pick('name', 'job_description', 'phone', $scope.replaceIdByExternalId ? 'external_id' : 'id')
					.some(function (value) {
						return value && value.toString().toLowerCase().indexOf(query) >= 0;
					})
					.valueOf();
			};

			$scope.notifySelectDriverTeamAction = function (isSuccess) {
				if (isSuccess) {
					toastr.success(TranslationService.instant('DRIVERS.TOAST_STARTED_SUCCESSFULLY'));
				} else {
					toastr.error(TranslationService.instant('DRIVERS.TOAST_COULD_NOT_START'));
				}
			};

			$scope.shouldUserShowAllTeams = function () {
				return (
					MerchantConfigurations.allow_dispatcher_to_access_all_teams || Authentication.currentUser().admin
				);
			};

			$scope.startEmployeeShift = function (driver, teamId = null) {
				Employee.startShift({ id: driver.id, active_team_id: teamId }, function (data) {
					if (data.success) {
						driver.active_shift_id = data.shift.id;
						toastr.success(TranslationService.instant('DRIVERS.TOAST_STARTED_SUCCESSFULLY'));
					} else {
						toastr.error(
							TranslationService.instant('DRIVERS.TOAST_COULD_NOT_START') + ' ' + data.message ?? ''
						);
					}
				});
			};

			$scope.startShift = function (driver) {
				if (_.isNull(driver)) {
					return;
				} else if (driver.team_ids.length === 1) {
					$scope.startEmployeeShift(driver);
				} else {
					const userTeamIds = $scope.currentUser.team_ids;
					const overlapTeamsIds = $scope.shouldUserShowAllTeams()
						? driver.team_ids
						: driver.team_ids.filter(item => userTeamIds.includes(item));
					if (!MerchantConfigurations.allow_start_shift_on_all_teams && overlapTeamsIds.length === 1) {
						$scope.startEmployeeShift(driver);
					} else {
						ReactModals.openSelectDriverTeam({
							driverUserOverlapTeamsIds: overlapTeamsIds,
							driverId: driver.id,
							onCloseModal: $scope.notifySelectDriverTeamAction
						});
					}
				}
			};

			$scope.endShift = function (driver) {
				if (_.isNull(driver)) {
					return;
				}
				Employee.endShift({ id: driver.id }, function (data) {
					if (data.success) {
						driver.active_shift_id = null;
						toastr.success('Shift ended successfully');
					} else {
						if (data.message) {
							toastr.error('Shift could not be ended: ', data.message);
						} else {
							toastr.error('Shift could not be ended');
						}
					}
				});
			};

			$scope.driverLeftHome = function (driverId) {
				if (_.isUndefined(driverId)) {
					return;
				}
				Employee.leftHome(
					{ id: driverId },
					function (data) {
						if (data.success) {
							toastr.success(data.message);
						} else {
							toastr.error(data.message);
						}
					},
					function (data) {
						if (data.message) {
							toastr.error('Could not leave home:', data.message);
						} else {
							toastr.error('Could not leave home');
						}
					}
				);
			};

			$scope.driverGotHome = function (driverId) {
				Employee.gotHome(
					{ id: driverId },
					function (data) {
						if (data.success) {
							toastr.success(data.message);
						} else {
							toastr.error(data.message);
						}
					},
					function (data) {
						if (data.message) {
							toastr.error('Could not arrive home: ', data.message);
						} else {
							toastr.error('Could not arrive home');
						}
					}
				);
			};

			$scope.openAddDriverModal = function () {
				return ReactModals.openAddDriverModal({
					title: '',
					afterClose: () => {},
					onCancel: () => {}
				});
			};

			$scope.openEmployeeModal = function () {
				// New driver UI
				return ReactModals.openEmployeeModal({
					title: '',
					showUserTypeSelection: false,
					defaultUserType: 'driver',
					afterClose: () => {},
					onCancel: () => {}
				});
			};

			$scope.updateUserType = function (driver, userTypeId) {
				Employee.update(driver.id, { user_type_id: userTypeId })
					.then(function (user) {
						Employees.onUpdatedEmployee(user);
						toastr.success('Driver ' + driver.name + ' updated');
					})
					.catch(function (error) {
						var errorString = '';
						if (typeof error.details === 'string') {
							errorString = error.details;
						} else {
							for (var messageIdx in error.details) {
								errorString += messageIdx + ':' + error.details[messageIdx][0] + '\r\n';
							}
						}
						toastr.error(errorString);
					});
			};

			$scope.addDriversCSV = function () {
				if ($scope.readonly) {
					return;
				}

				MerchantService.get().then(function () {
					//if (data.unpaid) {
					//  ga('send', 'event', 'addTaskStopped', 'unpaidMerchant');
					//  toastr.error('Sorry but it seems you have a suspended account. Please contact our support team at support@bringg.com');
					//} else {

					AddEmployeeService.addEmployeeCSV().then(function () {});
					//}
				});
			};

			$scope.getUserTypeById = function (userTypeId) {
				if (!userTypeId) {
					return UserTypes.emptyUserType;
				}
				return _.find(UserTypes.userTypes.user_types, { id: userTypeId });
			};

			$scope.getUserNameById = function (userId) {
				var user = _.find($scope.allDrivers, { id: userId });
				return user && user.name;
			};

			//region Vehicles

			$scope.getDriverVehicles = function (driver) {
				if (!$scope.teamsEnabled) {
					return $scope.vehicles;
				}

				return VehiclesService.getAllByTeams(driver.team_ids, $scope.vehicles);
			};

			$scope.vehicleSelected = function (driver, vehicleId) {
				if (driver.vehicle_id === vehicleId) {
					return;
				}
				if (vehicleId === $scope.noVehicle.id) {
					return $scope.removeVehicle(driver);
				}
				return $scope.assignVehicle(driver, vehicleId);
			};

			$scope.removeVehicle = function (driver) {
				return VehiclesService.update(driver.vehicle_id, { user_id: null })
					.then(function (vehicle) {
						driver.vehicle_id = $scope.noVehicle.id;
						toastr.success('Vehicle ' + vehicle.name + ' updated');
					})
					.catch(function (error) {
						$scope.driversSelectedVehicles[driver.id] = driver.vehicle_id;
						toastr.error(error.message);
					});
			};

			$scope.assignVehicle = function (driver, vehicleId) {
				return VehiclesService.update(vehicleId, { user_id: driver.id })
					.then(function (vehicle) {
						driver.vehicle_id = vehicle.id;
						toastr.success('Vehicle ' + vehicle.name + ' updated');
					})
					.catch(function (error) {
						$scope.driversSelectedVehicles[driver.id] = driver.vehicle_id;
						toastr.error(error.message);
					});
			};

			$scope.getVehicleById = function (vehicleId) {
				return _.find($scope.vehicles, { id: vehicleId });
			};

			$scope.formatVehicleLabel = function (vehicle) {
				var label = vehicle.name;

				if (vehicle.available === false) {
					label += ' (' + $translate.instant('DRIVERS.VEHICLE_UNAVAILABLE') + ')';
				}

				if (vehicle.trailer) {
					label += ' [' + vehicle.trailer.name + ']';
				}

				return label;
			};

			function onVehicleDeleted(deletedVehicle) {
				var existingIndex = _.findIndex($scope.vehicles, function (vehicle) {
					return vehicle.id === deletedVehicle.id;
				});

				if (existingIndex < 0) {
					return;
				}

				$scope.vehicles.splice(existingIndex, 1);
				delete $scope.driversSelectedVehicles[deletedVehicle.user_id];
			}

			$scope.onVehicleUpdated = function (updatedVehicle) {
				var updatedVehicleIndex = _.findIndex($scope.vehicles, function (vehicle) {
					return updatedVehicle.id === vehicle.id;
				});
				if (updatedVehicleIndex < 0) {
					return;
				}
				$scope.vehicles[updatedVehicleIndex] = updatedVehicle;

				if (!updatedVehicle.user_id) {
					return;
				}

				$scope.driversSelectedVehicles[updatedVehicle.user_id] = updatedVehicle.id;
			};

			$scope.onVehicleAssigned = function (eventData) {
				var driver = _.find($scope.allDrivers, { id: eventData.user_id });
				if (!driver) {
					return;
				}
				$scope.driversSelectedVehicles[driver.id] = driver.vehicle_id = eventData.vehicle_id;
			};

			$scope.onVehicleRemoved = function (eventData) {
				var driver = _.find($scope.allDrivers, { id: eventData.user_id });
				if (!driver) {
					return;
				}
				$scope.driversSelectedVehicles[driver.id] = driver.vehicle_id = $scope.noVehicle.id;
			};
			$scope.$on(
				'employees list update',
				_.throttle(function () {
					Employees.drivers({}, function (drivers) {
						$scope.totalItems = drivers.length;
					});
				}, DEFAULT_RT_THROTTLE)
			);

			VehiclesService.onDelete(onVehicleDeleted).then(sub => {
				return eventSubscriptions.push(sub);
			});

			VehiclesService.onUpdate($scope.onVehicleUpdated).then(sub => {
				return eventSubscriptions.push(sub);
			});
			VehiclesService.onUnassigned($scope.onVehicleRemoved).then(sub => {
				return eventSubscriptions.push(sub);
			});

			//endregion

			$scope.$on('$destroy', () => {
				eventSubscriptions.forEach(eventSubscription => eventSubscription && eventSubscription());
				eventSubscriptions = [];
			});

			$scope.fillDriversMissingDetails = function (drivers) {
				var promises = [];
				drivers.forEach(driver => {
					if (driver.teams) return;
					promises.push(
						Employees.getEmployeeCompleteDetails(driver).finally(function () {
							$q.resolve();
						})
					);
				});
				return $q.all(promises);
			};

			$scope._init = function () {
				$scope.userTypesEnabled = MerchantConfigurations.enable_user_types || false;
				$scope.enableDeliveryBlocks = MerchantConfigurations.enable_delivery_blocks || false;
				$scope.enableCompanies = $scope.currentUser.admin || false;
				$scope.vehiclesEnabled = MerchantConfigurations.enable_vehicles || false;
				var promises = [Employees.drivers(), Authentication.featureFlags()];
				if ($scope.userTypesEnabled) {
					promises.push(
						UserTypes.getAllAsync().then(function (result) {
							$scope.userTypes = result.user_types;
						})
					);
				}
				if ($scope.vehiclesEnabled) {
					promises.push(
						VehiclesService.getAll().then(function (result) {
							$scope.vehicles = _.filter(result, function (vehicle) {
								return !vehicle.is_trailer;
							});
							$scope.vehicles.push($scope.noVehicle);
						})
					);
				}

				$q.all(promises).then(function (results) {
					var drivers = results[0];

					$scope.fillDriversMissingDetails(drivers).then(function () {
						$scope.beta = Authentication.currentUser().beta;

						// drivers
						$scope.allDrivers = drivers;
						$scope.currentPage = 1;
						$scope.totalItems = drivers.length;

						if ($scope.vehiclesEnabled) {
							_.each($scope.allDrivers, function (driver) {
								driver.vehicle_id = driver.vehicle_id || $scope.noVehicle.id;
								$scope.driversSelectedVehicles[driver.id] = driver.vehicle_id;
							});
						}

						$scope._configure();
						var savedSort = localStorageService.get('all-drivers.sort');
						if (savedSort) {
							var header = _.find($scope.headers, { id: savedSort.headerId });
							if (header) {
								$scope.sort.descending = savedSort.descending;
								$scope.sort.header = _.find($scope.headers, { id: savedSort.headerId });
								$scope.sort.customSorter = $scope.sort.header.customSorter || $scope.sort.header.id;
							}
						}
						$scope.initialized = true;
					});
				});
			};

			$scope.handleGenerateQRCodeClick = function (driver) {
				if (!$scope.checkIfCanImpersonate(driver)) {
					return;
				}

				QrCodeGeneratorService.open(driver.id, driver.name);
			};

			function getUserRankScore(user) {
				if (user.admin) return 3;
				if (user.dispatcher) return 2;
				if (user.driver) return 1;
				return 0;
			}

			$scope.checkIfCanImpersonate = function (driver) {
				return getUserRankScore($scope.current_user) > getUserRankScore(driver);
			};

			$scope._init();
		}
	);
