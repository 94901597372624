'use strict';

angular
	.module('bringgApp')
	.controller('DispatcherAlertsController', function (
		$scope,
		$rootScope,
		Authentication,
		DispatcherAlert,
		DispatcherAlerts,
		toastr,
		$translate,
		DISPATCHER_ALERTS_TYPES
	) {
		$scope.alerts = [];

		$scope.DISPATCHER_ALERTS_TYPES = DISPATCHER_ALERTS_TYPES;

		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			if (isLoggedIn) {
				$scope.alerts = DispatcherAlerts.all();
			}
		});

		$scope.dismiss = function (alertData) {
			DispatcherAlert.dismiss({ id: alertData.id }, function (deletedAlert) {
				if (deletedAlert.success) {
					DispatcherAlerts.remove(alertData.id);
					toastr.success($translate.instant('DISPATCHER_ALERTS.ALERT_DISMIISED'));
				} else {
					toastr.error($translate.instant('DISPATCHER_ALERTS.FAILED_DISMISSING_ALERT'));
				}
			});
		};
	});
