/**
 * Created by liorsion on 1/24/17.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('AllPrivilegesController', function (
		$scope,
		$rootScope,
		ITEMS_PER_PAGE,
		Privileges,
		AddPrivilegeService,
		$timeout,
		$translate
	) {
		$scope._init = function () {
			$scope.currentPage = 1;
			$scope.numPerPage = ITEMS_PER_PAGE;
			$scope.totalItems = 0;
			$scope.privileges = [];
			$scope.loading = true;
			$scope.advancedSearch = false;
			$scope._searchDisabled = false;
			$scope.headers = [
				['id', $translate.instant('GLOBAL.ID')],
				['name', $translate.instant('DRIVERS.TITLE')]
			];

			$scope.sort = {
				column: 'id',
				descending: false,
				index: 0
			};
		};

		$scope._init();

		function updatePrivilegesInScope(result) {
			$scope.loading = false;
			$scope.privileges = result.privileges;
			$scope.totalItems = result.count || 0;
		}

		$scope.$on('config updated', function () {
			Privileges.all(updatePrivilegesInScope);
		});

		$rootScope.$watch($rootScope.isLoggedIn, function () {
			if ($rootScope.isLoggedIn) {
				Privileges.all(updatePrivilegesInScope);
			}
		});

		$scope.$watch('currentPage + numPerPage', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			var begin = $scope.currentPage * $scope.numPerPage,
				end = begin + $scope.numPerPage;

			if ($scope.currentPage === 0) {
				return;
			}

			Privileges.all({ page: $scope.currentPage, query: $scope._getQueryObject() }, function (data) {
				$scope.privileges = data.privileges;
				$scope.totalItems = data.count;
			});
		});

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.headers[columnIdx][0]) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.headers[columnIdx][0];
				sort.descending = false;
				sort.index = columnIdx;
			}
		};

		$scope.selectedCls = function (columnIdx) {
			if (columnIdx === $scope.sort.index) {
				if ($scope.sort.descending) {
					return '&#9660;';
				} else {
					return '&#9650;';
				}
			}
		};

		$scope.handleSearchButtonClick = function () {
			if ($scope._searchDisabled) {
				toastr.error('Please wait few seconds before searching again.');
				return;
			}

			$scope._searchDisabled = true;
			Privileges.all({ page: $scope.currentPage, query: $scope._getQueryObject() }, function (data) {
				$timeout(function () {
					$scope._searchDisabled = false;
				}, 10000);
				$scope.privileges = data.privileges;
				$scope.totalItems = data.count;
			});
		};

		$scope._getQueryObject = function () {
			if (!$scope.advancedSearch) {
				return $scope.privilegeName;
			} else {
				return { name: $scope.privilegeName, /*description: $scope.teamDescription,*/ advanced: true };
			}
		};

		$scope.handleAdvancedSearchButtonClick = function () {
			$scope.advancedSearch = !$scope.advancedSearch;
		};

		$scope.handleAddPrivilegeClick = function () {
			AddPrivilegeService.open().then(
				function (result) {},
				function (reason) {}
			);
		};
	});
