'use strict';

angular
	.module('bringgApp')
	.controller(
		'ssoController',
		function ($rootScope, $routeParams, $window, SingleSignOn, ENV_CONFIGURATION, CURRENT_REGION) {
			var merchantUuid = $routeParams.merchant_uuid;
			$rootScope.topClass = 'sso-top-class';
			$rootScope.hideMenu = true;
			SingleSignOn.enableSSOFlow(merchantUuid);
			$window.location =
				ENV_CONFIGURATION.regions[CURRENT_REGION].web_api_url +
				'/users/saml/sign_in?merchant_uuid=' +
				merchantUuid;
		}
	);
