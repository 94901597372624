'use strict';

/*global google:false */

angular.module('bringgApp.directives').directive('geoServiceLocatingSelect', function ($filter, $q, GeocodingService) {
	return {
		restrict: 'A',
		require: '',
		link: function ($scope) {
			$scope.addresses = [];
			$scope.addressToComplete = null;

			$scope.getAddresses = function (addressToComplete) {
				return GeocodingService.autoComplete(addressToComplete).then(function (result) {
					if (!result.success) {
						throw new Error(result.message);
					}
					return result.addresses;
				});
			};

			$scope.refreshAddressesDebounced = function () {
				return $scope
					.getAddresses($scope.addressToComplete)
					.then(function (results) {
						$scope.addresses = results;
					})
					.catch(function (message) {
						$scope.addresses = [{ address: message, disabled: true }];
					});
			};

			$scope.refreshAddresses = function (addressToComplete) {
				if (!addressToComplete || addressToComplete.length < 3) {
					$scope.addresses = [];
					return;
				}
				$scope.addressToComplete = addressToComplete;
				$scope.addressCompletion();
			};

			$scope.addressCompletion = _.debounce($scope.refreshAddressesDebounced, 300);
		}
	};
});
