'use strict';

angular
	.module('bringgApp')
	.constant('MANAGED_ATTRIBUTES_CONSTS', {
		TASK_COLUMNS_PREFIX: 'managed-attribute-'
	})
	.factory(
		'ManagedAttributesService',
		function (BringgSDK, MANAGED_ATTRIBUTES_CONSTS, Employee, CollapsedLinkedTasksService) {
			/**
			 *
			 * @type {import('@bringg/dashboard-sdk').BringgDashboardSDK.v2.taskManagedAttributes}
			 */
			let taskManagedAttributesSdk;

			BringgSDK.getInstancePromise().then(function (bringg) {
				taskManagedAttributesSdk = bringg.v2.taskManagedAttributes;
			});

			/**
			 *
			 * @param {import('@bringg/common-utils').GetAvailableManagedAttributeHTTPResponse['attributes']['task'][number]} attribute
			 * @return {string}
			 */
			function getTranslationKeyForAttribute(attribute) {
				if (attribute.display_name) {
					return attribute.display_name;
				}

				const identifierKey = _.snakeCase(attribute.identifier).toUpperCase();

				return `MANAGED_ATTRIBUTES.IDENTIFIER.${identifierKey}.TITLE`;
			}

			const ManagedAttributesService = {
				fetched: false,

				/**
				 * @type {import('@bringg/common-utils').GetAvailableManagedAttributeHTTPResponse['attributes']['task']}
				 */
				attributes: []
			};

			ManagedAttributesService.loadForTasks = async function (ids) {
				if (!ids.length) {
					return;
				}

				const attributes = await ManagedAttributesService.loadAttributes().catch(error => {
					console.error('Failed to load managed attributes', error);
					return [];
				});

				if (!attributes.length) {
					return;
				}

				try {
					await (await BringgSDK.getInstancePromise()).v2.taskManagedAttributes.loadByIds(ids);
				} catch (error) {
					console.error('Failed to load tender notify status for tasks', error);
				}
			};

			ManagedAttributesService.listenForChanges = function (cb) {
				let cleanupCalled = false;
				let cleanupFn = () => {};
				BringgSDK.getInstancePromise().then(bringg => {
					if (cleanupCalled) {
						return;
					}
					cleanupFn = bringg.v2.taskManagedAttributes.onUpdate(cb);
				});

				return () => {
					cleanupCalled = true;
					cleanupFn();
				};
			};

			ManagedAttributesService.getForTask = function (taskId, identifier) {
				if (!taskManagedAttributesSdk) {
					return;
				}

				const managedAttributes = taskManagedAttributesSdk.get(taskId);
				if (!managedAttributes) {
					return;
				}

				return managedAttributes[identifier];
			};

			ManagedAttributesService.loadAttributes = async function () {
				if (ManagedAttributesService.fetched) {
					return ManagedAttributesService.attributes;
				}

				const response = await (await BringgSDK.getInstancePromise()).v2.taskManagedAttributes
					.getAvailableAttributes()
					.catch(error => {
						console.error('Failed to get available managed attributes', error);
						return {
							attributes: {
								task: []
							}
						};
					});

				ManagedAttributesService.fetched = true;
				ManagedAttributesService.attributes = (response.attributes.task || []).filter(
					attribute => attribute.visible_in_the_ui
				);

				return ManagedAttributesService.attributes;
			};

			ManagedAttributesService.generateTaskColumns = function () {
				return ManagedAttributesService.attributes.map(function (attribute) {
					return {
						id: `${MANAGED_ATTRIBUTES_CONSTS.TASK_COLUMNS_PREFIX}.${attribute.identifier}`,
						active: false,
						title: getTranslationKeyForAttribute(attribute),
						translate: true,
						sortable: false,
						getSearchValue: function (task) {
							const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(task.id);
							const taskId = collapseTaskData ? collapseTaskData.tasks[0].id : task.id;
							return ManagedAttributesService.getForTask(taskId, attribute.identifier);
						},
						getValue: function (task) {
							const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(task.id);
							const taskId = collapseTaskData ? collapseTaskData.tasks[0].id : task.id;
							const value = ManagedAttributesService.getForTask(taskId, attribute.identifier);

							if (attribute.data_type.entity === 'driver') {
								return Employee.getByIdFromStoredItems(value)?.name || value;
							}

							return value;
						}
					};
				});
			};

			return ManagedAttributesService;
		}
	);
