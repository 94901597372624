/**
 * Created by liorsion on 2/5/16.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('changePasswordController', function ($scope, Employee, toastr, $location, localStorageService) {
		var changePasswordToken = localStorageService.get('bringg_change_password_token');

		if (!changePasswordToken) {
			return $location.path('/login/');
		}
		$scope.changePassword = function () {
			var params = {
				token: changePasswordToken,
				password: $scope.changePasswordForm.password
			};

			Employee.changePassword(
				params,
				function (response) {
					if (response.success) {
						$location.path('/login/');
					} else {
						toastr.error(response.message);
					}
				},
				function (error) {
					toastr.error(error.statusText);
				}
			);
		};
	});
