'use strict';

angular.module('bringgApp.directives').directive('focusOn', function ($timeout, $parse) {
	return {
		//scope: true,   // optionally create a child scope
		link: function (scope, element, attrs) {
			var model = $parse(attrs.focusOn);
			scope.$watch(model, function (value) {
				if (value === true) {
					$timeout(function () {
						element[0].focus();
					});
				}
			});
			// to address @blesh's comment, set attribute value to 'false'
			// on blur event:
			// http://stackoverflow.com/questions/14833326/how-to-set-focus-on-input-field
			//element.on('blur', function() {
			//  scope.$apply(model.assign(scope, false));
			//});
		}
	};
});
