'use strict';
angular
	.module('bringgApp')
	.factory('TasksModalService', function ($uibModal, Tasks, Authentication, $q, $translate, ReactModals) {
		var TasksModalService = {};

		TasksModalService.addTask = function (employee, duplicatedTask, selectedTeamId) {
			if (!Authentication.isLoggedIn() || !Authentication.currentUser()) {
				return $q.reject(new Error($translate.instant('TASK.NOT_LOGGED_IN')));
			}

			return Authentication.featureFlags().then(function (featureFlags) {
				if (featureFlags.order_creation_modal_v2) {
					ReactModals.openCreateTaskModal({
						selectedTeamId
					});
				} else {
					return $uibModal
						.open({
							backdrop: true,
							keyboard: true,
							controller: 'AddTaskController',
							templateUrl: 'scripts/features/tasks/add_task/quick-add.html',
							resolve: {
								employee: function () {
									return employee;
								},
								duplicatedTask: function () {
									return duplicatedTask;
								}
							}
						})
						.result.then(function (result) {
							Tasks.addNewTask(result);
						});
				}
			});
		};

		TasksModalService.openScheduleTasksDialog = function (tasks) {
			return $uibModal
				.open({
					backdrop: true,
					keyboard: true,
					controller: 'RescheduleTaskController',
					templateUrl: 'scripts/features/reschedule_task/reschedule-task.html',
					resolve: {
						tasks: function () {
							return tasks;
						}
					}
				})
				.result.then(function (result) {
					$q.resolve(result);
				});
		};

		TasksModalService.addTaskCSV = function () {
			if (!Authentication.isLoggedIn() || !Authentication.currentUser()) {
				return $q.reject(new Error($translate.instant('TASK.NOT_LOGGED_IN')));
			}

			ReactModals.openTasksCsvImporter();

			return $q.resolve();
		};

		return TasksModalService;
	});
