/**
 * Created by liorsion on 4/26/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('apiAndWebhooksController', function (
		$scope,
		$http,
		MerchantConfigurationService,
		$location,
		Authentication,
		PRIVILEGES_TYPES,
		$window
	) {
		$scope._data = {};
		$scope.tabs = [];

		$scope.isWebhooksAllowed = Authentication.currentUser().has_access(PRIVILEGES_TYPES.ENABLE_WEBHOOKS);
		$scope.oauthApplicationsAllowed = Authentication.currentUser().feature_flags.oauth_applications_allowed;
		$scope.current_user = Authentication.currentUser();
	});
