/**
 * Created by liorsion on 1/24/17.
 */
'use strict';
/* global google:false */
angular
	.module('bringgApp')
	.controller(
		'AddPrivilegeController',
		function ($scope, $uibModalInstance, Privileges, Privilege, toastr, Authentication) {
			$scope.loading = true;
			$scope.showDriverPrivilege = false;

			$scope.data = {
				authorization_flags: {}
			};

			$scope.close = function () {
				$uibModalInstance.dismiss();
			};

			$scope.sending = false;

			$scope._init = function () {
				Privilege.getList()
					.$promise.then(function (allPrivileges) {
						const VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE_KEY = 'view_consumer_verification_code';

						$scope.allPrivileges = allPrivileges.privileges;
						_.each($scope.allPrivileges, function (privilege) {
							var privilegeObject = {
								title: privilege.title
							};

							if (privilege.sub_values) {
								privilegeObject.sub_values = privilege.sub_values;
								privilegeObject.default = privilege.default;

								privilege.values.forEach(function (subPrivlege) {
									privilegeObject[subPrivlege.field] = subPrivlege;
								});

								privilegeObject.value = privilege.default;
							} else {
								var flag = true;

								if (_.isBoolean(privilege.default)) {
									flag = privilege.default;
								}

								privilegeObject.value = flag;
							}

							privilege.is_driver_related && ($scope.showDriverPrivilege = true);
							privilegeObject.isDriverRelated = privilege.is_driver_related ?? false;

							// this part of the code(if/else) needs to filter out VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE until FF won't be released
							if (privilege.field === VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE_KEY) {
								if (Authentication.currentUser().feature_flags.enable_driver_verify_pin_code) {
									setAuthorizationFlag(privilege.field, privilegeObject);
								}
							} else {
								setAuthorizationFlag(privilege.field, privilegeObject);
							}
						});

						$scope.loading = false;
					})
					.catch(function () {
						$scope.loading = false;
						$scope.notFound = true;
					});
			};

			$scope._init();

			$scope.save = function () {
				var privilegeNames = Object.keys($scope.data.authorization_flags);

				privilegeNames.forEach(function (privilegeName) {
					var privilege = $scope.data.authorization_flags[privilegeName];

					// We add this key to separate Dispatcher and Driver Privilege.
					// Before sending the API request, we remove it from the object.
					privilege && delete privilege.isDriverRelated;
					if (privilege && privilege.sub_values) {
						var privilegeField = privilege.value;

						$scope.data.authorization_flags[privilegeName] = {
							title: privilege.title
						};

						$scope.data.authorization_flags[privilegeName][privilegeField] = true;
					}
				});

				$scope.sending = true;

				Privileges.add(
					{
						name: $scope.name,
						authorization_flags: $scope.data.authorization_flags
					},
					function (result) {
						if (result.success) {
							toastr.success('Privilege was added successfully!');
							$uibModalInstance.close();
						} else {
							$scope.sending = false;
							toastr.error(result.message);
						}
					},
					function (errorResult) {
						$scope.sending = false;
						toastr.error('Error - missing data or bad format');
					}
				);
			};

			function setAuthorizationFlag(privilegeKey, privilegeObject) {
				$scope.data.authorization_flags[privilegeKey] = privilegeObject;
			}
		}
	);
