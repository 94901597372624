'use strict';

// see http://www.williambrownstreet.net/blog/2013/07/angularjs-my-solution-to-the-ng-repeat-performance-problem/
angular.module('bringgApp.directives').directive('infiniteScroll', function ($rootScope, $window, $timeout) {
	return {
		link: function (scope, elem, attrs) {
			var checkWhenEnabled, handler, scrollDistance, scrollEnabled;
			$window = angular.element($window);
			elem.css('overflow-y', 'scroll');
			elem.css('overflow-x', 'hidden');
			elem.css('height', 'inherit');
			scrollDistance = 0;
			if (attrs.infiniteScrollDistance !== null) {
				scope.$watch(attrs.infiniteScrollDistance, function (value) {
					scrollDistance = parseInt(value, 10);
					return scrollDistance;
				});
			}
			scrollEnabled = true;
			checkWhenEnabled = false;
			if (attrs.infiniteScrollDisabled !== null) {
				scope.$watch(attrs.infiniteScrollDisabled, function (value) {
					scrollEnabled = !value;
					if (scrollEnabled && checkWhenEnabled) {
						checkWhenEnabled = false;
						return handler();
					}
				});
			}
			scope.$on('refreshStart', function (event, parameters) {
				elem.animate({ scrollTop: '0' });
			});
			handler = function () {
				var container, elementBottom, remaining, shouldScroll, containerBottom;
				container = $(elem.children()[0]);
				elementBottom = elem.offset().top + elem.height();
				containerBottom = container.offset().top + container.height();
				remaining = containerBottom - elementBottom;
				shouldScroll = remaining <= elem.height() * scrollDistance;
				if (shouldScroll && scrollEnabled) {
					if ($rootScope.$$phase) {
						return scope.$eval(attrs.infiniteScroll);
					} else {
						return scope.$apply(attrs.infiniteScroll);
					}
				} else if (shouldScroll) {
					checkWhenEnabled = true;
					return checkWhenEnabled;
				}
			};
			elem.on('scroll', handler);
			scope.$on('$destroy', function () {
				return $window.off('scroll', handler);
			});
			return $timeout(function () {
				if (attrs.infiniteScrollImmediateCheck) {
					if (scope.$eval(attrs.infiniteScrollImmediateCheck)) {
						return handler();
					}
				} else {
					return handler();
				}
			}, 0);
		}
	};
});
