'use strict';

angular
	.module('bringgApp')
	.factory('PdfExportService', function ($timeout, $log, toastr, AirbrakeService, TranslationService) {
		var PdfExportService = {};

		PdfExportService._loadScript = function (script, cb) {
			var options = {
				dataType: 'script',
				cache: true,
				url: script
			};

			jQuery
				.ajax(options)
				.fail(function (jqXHR, textStatus, errorThrown) {
					return cb(new Error(textStatus + ': ' + errorThrown));
				})
				.done(function () {
					return cb(null);
				});
		};

		PdfExportService.export = function (gridApi, args) {
			async.eachLimit(
				['node_modules/pdfmake/build/pdfmake.min.js', 'node_modules/pdfmake/build/vfs_fonts.js'],
				1,
				function (script, cb) {
					return PdfExportService._loadScript(script, cb);
				},
				function (error) {
					if (error !== null) {
						$log.error('PdfExportService.export failed to loadScripts', error);
						AirbrakeService.notify(new Error('failed to load pdfmake scripts'));
						toastr.error(TranslationService.instant('PDF_EXPORT.FAILED'));
						return;
					}

					$timeout(function () {
						gridApi.exporter.pdfExport.apply(gridApi.exporter, args);
					}, 100);
				}
			);
		};

		return PdfExportService;
	});
