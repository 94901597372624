'use strict';

angular
	.module('bringgApp.directives')
	.directive('passwordValidation', function (PasswordRulesService, $log, $translate) {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				ngModel: '=',
				ngModelController: '=',
				currentPasswordRules: '=',
				resetPasswordToken: '=?',
				user: '=?'
			},
			link: function (scope, element, attributes, ngModel) {
				var rolesPasswordRules = {};
				var anonymousPasswordRules = {};
				scope.currentPasswordRules = {};
				//expose model controller
				scope.ngModelController = ngModel;

				/**
         * current user password rules example
         {
           min_length: 8,
           lowercase_char: false,
           uppercase_char: false,
           digit: false,
           symbol: false
         }
         */

				/**
				 * reload current user password rules by his role
				 */
				var resetPasswordRules = function () {
					// user is not connected, use default one
					// assume we got only the current one
					if (scope.resetPasswordToken) {
						scope.currentPasswordRules = anonymousPasswordRules;
						return scope.currentPasswordRules;
					}

					if (!scope.user) {
						scope.currentPasswordRules = {};
						return;
					}
					// set password rules by user role
					else if (scope.user.admin) {
						scope.currentPasswordRules = rolesPasswordRules.admin;
					} else if (scope.user.dispatcher) {
						scope.currentPasswordRules = rolesPasswordRules.dispatcher;
					} else {
						scope.currentPasswordRules = rolesPasswordRules.user;
					}
				};

				var unbindFocus = element.on('focus', function () {
					scope.ngModelController.hasFocused = true;
				});

				scope.$on('destroy', unbindFocus);

				var updatePasswordPlaceholder = function () {
					var minLength = scope.currentPasswordRules.min_length;
					attributes.$set(
						'placeholder',
						$translate.instant('RESET.PASSWORD').replace('{min_length}', minLength)
					);
				};

				/**
				 * load password rules from api
				 * if user logged in user, use regular one
				 * if used is not logged in use reset password token
				 */
				var initPasswordRules = function () {
					// use reset password token is supplied
					if (scope.resetPasswordToken) {
						PasswordRulesService.getUsingToken(scope.resetPasswordToken).$promise.then(
							function (result) {
								anonymousPasswordRules = result;
								resetPasswordRules();
								ngModel.$validate();
								updatePasswordPlaceholder();
							},
							function (err) {
								$log.error('Failed to get anonymous password rules: ' + err);
							}
						);
					}

					// got user
					if (attributes.user) {
						PasswordRulesService.get().$promise.then(
							function (result) {
								rolesPasswordRules = result;
								resetPasswordRules();
								ngModel.$validate();
								ngModel.$setPristine();
								updatePasswordPlaceholder();
							},
							function (err) {
								$log.error('Failed to get password rules: ' + err);
							}
						);

						/**
						 * update validator on user update
						 */
						scope.$watch(
							'user',
							function (user) {
								if (user) {
									resetPasswordRules();
									ngModel.$validate();
								}
							},
							true
						);
					}
				};

				initPasswordRules();
				/**
				 * is user allowed empty password ?
				 * @returns {boolean}
				 */
				var isUserAllowedEmptyPassword = function (modelValue) {
					if (!scope.user) {
						return false;
					}
					// any user can have empty password
					return _.isEmpty(modelValue);
				};

				/**
				 * vaildate min length
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.min_length = function (modelValue) {
					if (
						isUserAllowedEmptyPassword(modelValue) ||
						!scope.currentPasswordRules ||
						!scope.currentPasswordRules.min_length
					) {
						return true;
					}

					return (modelValue || '').length >= scope.currentPasswordRules.min_length;
				};

				/**
				 * validate contains lowercase char
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.lowercase_char = function (modelValue) {
					if (modelValue === undefined && scope.resetPasswordToken) {
						return false;
					}
					if (
						isUserAllowedEmptyPassword(modelValue) ||
						!scope.currentPasswordRules ||
						!scope.currentPasswordRules.lowercase_char
					) {
						return true;
					}
					return /[a-z]/.test(modelValue);
				};

				/**
				 * validate contains uppercase char
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.uppercase_char = function (modelValue) {
					if (
						isUserAllowedEmptyPassword(modelValue) ||
						!scope.currentPasswordRules ||
						!scope.currentPasswordRules.uppercase_char
					) {
						return true;
					}
					return /[A-Z]/.test(modelValue);
				};

				/**
				 * validate contains digit
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.digit = function (modelValue) {
					if (
						isUserAllowedEmptyPassword(modelValue) ||
						!scope.currentPasswordRules ||
						!scope.currentPasswordRules.digit
					) {
						return true;
					}
					return /[0-9]/.test(modelValue);
				};

				/**
				 * validate contains symbol
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.symbol = function (modelValue) {
					if (
						isUserAllowedEmptyPassword(modelValue) ||
						!scope.currentPasswordRules ||
						!scope.currentPasswordRules.symbol
					) {
						return true;
					}
					return /(_|[^\w])/.test(modelValue);
				};
			}
		};
	})
	.directive('passwordValidationMessages', function () {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/password-validation/password-validation-messages.html',
			replace: true,
			scope: {
				passwordModelController: '=',
				currentPasswordRules: '='
			}
		};
	});
