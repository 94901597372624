'use strict';

angular
	.module('bringgApp')
	.controller('CustomerBillingController', function (
		$scope,
		Authentication,
		Merchant,
		toastr,
		Payments,
		dialogSrv,
		$uibModal,
		$log,
		MerchantService,
		SocketPubSub
	) {
		$scope.loading = true;
		$scope.upcomingInvoice = null;

		$scope._getUserBillingData = function () {
			Payments.get(
				function (result) {
					if (result.success) {
						$scope.loading = false;
						$scope.handlePaymentDataUpdated(result.data);
					} else {
						if (result.message) {
							toastr.error(result.message);
						}
					}
				},
				function () {
					toastr.error('Error getting billing data');
				}
			);
		};

		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			if (isLoggedIn && Authentication.currentUser()) {
				MerchantService.get().then(
					function (result) {
						$scope.merchant = result;
						if (result.stripe_id) {
							$scope._getUserBillingData();
							$scope._getUserBillingChangesData();
						}
					},
					function (reason) {}
				);
			}
		});

		$scope._onMerchantSubscriptionUpdated = function (merchantConfiguration) {
			if (Authentication.isLoggedIn() && Authentication.currentUser()) {
				$scope._getUserBillingData();
				$scope._getUserBillingChangesData();
			}
		};

		SocketPubSub.on('merchant subscription updated', $scope._onMerchantSubscriptionUpdated);

		$scope._getUserBillingChangesData = function () {
			Payments.billing_changes_history(
				function (result) {
					$scope.billingChangesHistory = result.data;
				},
				function (error) {}
			);
		};

		$scope.discount = { couponIdCode: '', canUse: false };
		$scope.redeemCouponClicked = function () {
			Payments.redeem_coupon({ coupon_id: $scope.discount.couponIdCode }, function (result) {
				if (result.success) {
					toastr.success('Coupon was applied to your account!');
					$scope._getUserBillingData();
					$scope._getUserBillingChangesData();
				} else {
					toastr.error(result.message);
				}
			});
		};
		//==============================================
		// Payments
		//==============================================
		$scope.customerInvoices = [];
		$scope.customerInvoicesHeaders = [
			['date', 'Date'],
			['invoice_period', 'Invoice Period'],
			['amount', 'Amount'],
			['paid', 'Paid'],
			['receipt_number', 'Receipt Number'],
			['report', 'Report']
		];

		$scope.handlePaymentDataUpdated = function (data) {
			$scope.customer_info = data.customer_data;
			$scope.upcomingInvoice = data.upcoming_invoice;
			$scope.customerInvoices = data.customer_invoices;
			$scope.subscription = data.subscription;
			$scope.activity_summary = data.activity_summary;

			$scope.discount.canUse = !data.customer_data.discount;
			if (data.customer_data.discount) {
				$scope.discount.couponIdCode = data.customer_data.discount.coupon.id;
			}

			$scope.default_credit_card = _.find(data.customer_data.credit_cards, function (card) {
				return card.id === data.customer_data.default_card;
			});
		};

		$scope.handleAddCreditCardClick = function () {
			var modalInstance = $uibModal.open({
				backdrop: 'static',
				keyboard: true,
				controller: 'AddCreditCardController',
				templateUrl: 'scripts/features/configuration/add_credit_card/add-credit-card.html'
			});

			modalInstance.result.then(
				function (result) {
					$scope.customer_info = result;
				},
				function (reason) {
					$log.info('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope._handleMakeDefaultResult = function (result) {
			if (result.success) {
				toastr.success('Default card was changed.');
				$scope.customer_info = result.data;
			} else {
				toastr.error(result.message);
			}
		};
		$scope.handleMakeDefaultClick = function (cardId) {
			Payments.change_default_credit_card({ credit_card_id: cardId }, $scope._handleMakeDefaultResult);
		};

		$scope._handleRemoveCardResult = function (result) {
			if (result.success) {
				toastr.success('Credit card was deleted from your account.');
				$scope.customer_info = result.data;
			} else {
				toastr.error(result.message);
			}
		};

		$scope.handleRemoveCardClick = function (cardId) {
			Payments.delete_credit_card({ credit_card_id: cardId }, $scope._handleRemoveCardResult);
		};

		$scope.handleCopyIdToClipdoard = function (invoiceId) {
			toastr.error('Not Implemented');
		};

		$scope.handleViewInvoiceClick = function (invoiceId) {
			toastr.error('Not Implemented');
		};

		$scope.handleViewReportsClick = function () {
			toastr.error('Not Implemented');
		};

		$scope.handleUpdateBillingEmailRecipientClick = function () {
			Payments.update_email_recipient({ email: $scope.customer_info.email }, function (response) {
				if (response.success) {
					toastr.success('Email recipient was updated.');
					$scope.customer_info = response.data;
				} else {
					if (response.message) {
						toastr.error(response.message);
					} else {
						toastr.error('Could not update the info at this time.');
					}
				}
			});
		};

		$scope.$on('$destroy', function iVeBeenDismissed() {
			$log.debug('CustomerBillingController destroyed');
			SocketPubSub.removeListener('merchant subscription updated', $scope._onMerchantSubscriptionUpdated);
		});

		//==============================================
		// Change subscription plan
		//==============================================
		$scope.handleChangeSubscriptionType = function () {
			Payments.openChangeSubscriptionTypeWindow($scope.subscription.plan.id);
		};
	});
