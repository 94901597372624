'use strict';

angular
	.module('bringgApp')
	.controller('EmployeeTasksController', function EmployeeTasksController(
		$scope,
		$routeParams,
		Employees,
		Tasks,
		$rootScope,
		Shift,
		dialogSrv,
		Task,
		Employee,
		ITEMS_PER_PAGE,
		bringg_utils,
		toastr
	) {
		$scope.currentPage = 0;
		$scope.numPerPage = ITEMS_PER_PAGE;
		$scope.totalItems = 0;
		$scope.allTasks = [];

		$scope.getStatusFromTask = bringg_utils.getStatusFromTask;

		$scope.headers = [
			['id', 'ID'],
			['title', 'Title'],
			['name', 'Name'],
			['phone', 'Phone'],
			['address', 'Address'],
			['scheduled_at', 'Scheduled At'],
			['status', 'Status'],
			['driver', 'Driver']
		];

		$rootScope.$watch($rootScope.isLoggedIn, function () {
			if ($rootScope.isLoggedIn) {
				Employees.get({ id: $routeParams.id }, function (data) {
					$scope.employee = data;
				});

				Employee.closedTasks($routeParams.id, 0)
					.then(function (tasks) {
						$scope.allTasks[1] = tasks;
						$scope.currentPage = 1;
					})
					.catch(function (error) {
						toastr.error(error.message);
					});

				Employee.assignedTasksCount($routeParams.id)
					.then(function (totalTasks) {
						$scope.totalItems = totalTasks;
					})
					.catch(function (error) {
						toastr.error(error.message);
					});
			}
		});

		$scope.$watch('currentPage + numPerPage', function () {
			var begin = ($scope.currentPage - 1) * $scope.numPerPage,
				end = begin + $scope.numPerPage;

			if ($scope.currentPage === 0) {
				return;
			}

			$scope.tasks = $scope.allTasks[$scope.currentPage];

			if (!$scope.tasks) {
				var bringPage = $scope.currentPage;
				Employee.closedTasks($routeParams.id, bringPage).then(function (tasks) {
					$scope.tasks = tasks;
					$scope.allTasks[bringPage] = tasks;
				});
			}
		});
	});
