'use strict';

angular.module('bringgApp').factory('authorization', function ($q, IdentityProvider, Merchant) {
	var authorization = {};

	authorization.AuthorizationError = function AuthorizationError(description) {
		this.authenticated = description != null;
		this.description = description || 'User authentication required.';
	};

	authorization.isMerchantCreatedBefore = (merchantCreatedAt, dateCreatedBefore) =>
		moment(merchantCreatedAt).isBefore(moment(dateCreatedBefore));

	authorization.AuthorizationError.prototype = Object.create(Error.prototype);
	authorization.AuthorizationError.prototype.constructor = authorization.AuthorizationError;

	// TODO: make possible to pass the feature flags for route access
	authorization.authorize = function (accessRights) {
		accessRights = accessRights || {};

		return IdentityProvider.byToken().then(function (user) {
			if (user == null) {
				return $q.reject(new authorization.AuthorizationError());
			}
			if (accessRights.adminRequired && !user.admin) {
				return $q.reject(new authorization.AuthorizationError('Admin required'));
			}
			if (accessRights.adminOrDispatcherRequired && !(user.admin || user.dispatcher)) {
				return $q.reject(new authorization.AuthorizationError('Admin of Dispatcher required'));
			}
			if (accessRights.authorizationFlag && !user.has_access(accessRights.authorizationFlag)) {
				return $q.reject(new authorization.AuthorizationError(accessRights.authorizationFlag + ' required'));
			}
			if (accessRights.featureFlag && !user.feature_flags[accessRights.featureFlag]) {
				return $q.reject(new authorization.AuthorizationError(accessRights.featureFlag + ' required'));
			}
			if (accessRights.merchantCreatedBefore) {
				return Merchant.get().then(merchant => {
					if (
						!authorization.isMerchantCreatedBefore(merchant.created_at, accessRights.merchantCreatedBefore)
					) {
						return $q.reject(
							new authorization.AuthorizationError(
								`Merchant created after ${accessRights.merchantCreatedBefore}`
							)
						);
					}

					return user;
				});
			}

			return user;
		});
	};

	return authorization;
});
