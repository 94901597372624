'use strict';

angular.module('bringgApp').service('LockedTaskService', function (Authentication) {
	const _this = this;
	_this.isLocked = isLocked;
	_this.isLockedByMe = isLockedByMe;
	_this.isLockedByOther = isLockedByOther;

	function isLocked(task) {
		return !_.isNil(task.dispatcher_id);
	}

	function isLockedByOther(task) {
		return isLocked(task) && !isLockedByMe(task);
	}

	function isLockedByMe(task) {
		return task.dispatcher_id === Authentication.currentUser().id;
	}
});
