'use strict';

angular.module('bringgApp').run(function (localStorageService, $http) {
	try {
		var authKey = localStorageService.get('auth_key');
		if (authKey !== null) {
			$http.defaults.headers.common.Authorization = localStorageService.get('auth_key');
		}
	} catch (err) {}

	//      $rootScope.$watch( Authentication.isLoggedIn, function ( isLoggedIn ) {
	//        $rootScope.isLoggedIn = isLoggedIn;
	//        if (isLoggedIn) {
	//          console.log('User ' + Authentication.currentUser().id + ' logged in. Hello, ' + Authentication.currentUser().name);
	//          $rootScope.currentUser = Authentication.currentUser();
	//          $rootScope.admin = $rootScope.currentUser.admin;
	//
	//
	//        } else {
	//          $rootScope.admin = false;
	//          console.log('User is not logged in. Hello new user!');
	//          //$locationProvider.hash('/login');
	//          if ($location.path() !== '/register/') {
	//            $location.path('/login/');
	//          }
	//        }
	//      });
});
