'use strict';

angular
	.module('bringgApp')
	.directive('optimizationPreviewMap', function () {
		return {
			replace: true,
			restrict: 'E',
			templateUrl: 'scripts/directives/optimization_preview_map/optimization-preview-map.html',
			scope: {
				//mandatory
				merchantConfigurations: '=',
				tasks: '=',
				serviceAreasById: '=',
				optimizationRequestId: '=',
				routeInfoByIndex: '=',

				//optional
				userTypes: '=?',
				users: '=?',
				markerClicked: '=?',
				optimizationData: '=?',
				type: '=?',
				optimizationDate: '=?',
				renderPinsDefault: '=?',
				plannedRoutes: '=?',
				runIdToBreak: '<?'
			},
			controller: 'OptimizationPreviewMapController'
		};
	})
	.controller(
		'OptimizationPreviewMapController',
		function (
			$rootScope,
			$scope,
			BigMapService,
			OPTIMIZATION_TYPES,
			OptimizationPreviewService,
			ServiceAreasService,
			WayPointsService,
			$translate,
			TimeManagerService,
			NgMap,
			PreviewMapLoader
		) {
			var unregisterMapUpdates = _.noop;
			var unregisterHideRoute = _.noop;

			$scope.map = null;
			$scope.sharedData = OptimizationPreviewService.sharedData;
			$scope.OPTIMIZATION_TYPES = OPTIMIZATION_TYPES;
			$scope.type = $scope.type || OPTIMIZATION_TYPES.ANONYMOUS;
			$scope.namedOptimizationTypes = [
				OPTIMIZATION_TYPES.NAMED_VRP,
				OPTIMIZATION_TYPES.NAMED_PDP,
				OPTIMIZATION_TYPES.CREW_BASED
			];
			$scope.unnamedOptimizationTypes = [OPTIMIZATION_TYPES.ANONYMOUS, OPTIMIZATION_TYPES.ROUTE_BASED];
			$scope.manualOptimizationType = 'custom_tasks';

			$scope.renderPinsDefault = $scope.renderPinsDefault || false;
			$scope.displayByRouteName = { value: false };
			$scope.shouldDisplayListFooter =
				!PreviewMapLoader.enableMarkerClusterer &&
				$scope.tasks &&
				$scope.tasks.length >= PreviewMapLoader.MAX_VISIBLE_MARKERS_ALLOWED;

			//==============================================
			// Map
			//==============================================
			$scope._map = {
				center: [$scope.merchantConfigurations.lat, $scope.merchantConfigurations.lng]
			};
			//==============================================
			// User interaction
			//==============================================
			$scope._markerClicked = function (event, task) {
				if ($scope.markerClicked && task) {
					$scope.markerClicked(task);
				}
			};
			//==============================================
			// Map styling
			//==============================================
			$scope.getMarkerIcon = function (color) {
				var svgStr =
					"<?xml version='1.0'  standalone='yes'?>" +
					"<svg width='34px' height='45px' viewBox='0 0 34 45' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
					"<path d='M17,45 C17,45 34,26.0896051 34,16.8072289 C34,7.5248527 26.3888407,0 17,0 C7.61115925,0 0,7.5248527 0,16.8072289 C0,26.0896051 17,45 17,45 Z' fill='" +
					color +
					"'></path>" +
					"<text x='17' y='17' fill='#" +
					BigMapService.getLabelColor(color) +
					"' text-anchor='middle' font-family='Source Sans Pro' alignment-baseline='middle'>" +
					'</text>' +
					'</svg>';
				return 'data:image/svg+xml;base64,' + btoa(svgStr);
			};

			$scope.showByRoutes = function () {
				return (
					$scope.type === OPTIMIZATION_TYPES.CREW_BASED ||
					$scope.type === OPTIMIZATION_TYPES.ROUTE_BASED ||
					$scope.displayByRouteName.value
				);
			};

			$scope.getListTitle = function () {
				if ($scope.showByRoutes()) {
					return $translate.instant('INTERACTIVE_MAP.BY_ROUTES');
				}

				if ($scope.type === OPTIMIZATION_TYPES.ANONYMOUS) {
					return $translate.instant('INTERACTIVE_MAP.BY_USER_TYPES');
				}

				return $translate.instant('INTERACTIVE_MAP.BY_DRIVERS');
			};

			$scope.numberOfDrivers = 0;
			$scope._getNumberOfDrivers = function (data) {
				return _.keys(data).length;
			};

			$scope.numberOfOrders = 0;
			$scope._getNumberOfOrders = function (data) {
				return _.chain(data).map('tasks').flatten().value().length;
			};

			$scope.fromToTime = '';
			$scope._getFromToTime = function (data) {
				//get the first and last task by scheduled a
				var times = _.chain(data)
					.map(function (user) {
						return user.tasks;
					})
					.flatten()
					.map(function (task) {
						var wp = WayPointsService.getDropOffWayPoint(task);
						if (wp) {
							return new Date(wp.scheduled_at);
						}
						return null;
					})
					.sort()
					.valueOf();
				return (
					TimeManagerService.format(moment(times[0]), 'hh:mm a') +
					' - ' +
					TimeManagerService.format(moment(times[times.length - 1]), 'hh:mm a')
				);
			};

			$scope.updateUILabels = function () {
				$scope.numberOfDrivers = $scope._getNumberOfDrivers($scope.sharedData.data);
				$scope.numberOfOrders = $scope._getNumberOfOrders($scope.sharedData.data);
				$scope.fromToTime = $scope._getFromToTime($scope.sharedData.data);
			};

			$scope.getWayPoint = function (task) {
				var wp = _.find(task.way_points, function (wp) {
					return wp.position === 2 && wp.lat && wp.lng;
				});

				return wp ? [wp.lat, wp.lng] : null;
			};
			//==============================================
			// Init
			//==============================================
			$scope._getDirections = function (tasks, color) {
				tasks = _.sortBy(tasks, 'priority');
				var result = { wayPoints: [], polylineOptions: { strokeColor: '#' + color } };

				_.each(tasks, function (task) {
					var wp = $scope.getWayPoint(task); //get the wp at pos 2

					if (wp) {
						result.wayPoints.push({ location: { lat: wp[0], lng: wp[1], stopover: true } });
					}
				});

				return result;
			};

			$scope.createData = function () {
				var groupedTasks;
				var data = {};
				var breaks = {};
				var userTypesIds = {};
				var optimizationsForWhichWeChangeUsersList = [
					OPTIMIZATION_TYPES.ANONYMOUS,
					OPTIMIZATION_TYPES.ROUTE_BASED,
					OPTIMIZATION_TYPES.CREW_BASED
				];
				var users = optimizationsForWhichWeChangeUsersList.indexOf($scope.type) !== -1 ? [] : $scope.users;

				$scope.optimizationData = _.filter($scope.optimizationData, function (data) {
					return data.type !== 'unassigned_task';
				});

				if ($scope.namedOptimizationTypes.indexOf($scope.type) !== -1) {
					var areaId;

					//when the map is rendering users with assigned tasks
					_.each($scope.optimizationData, function (item) {
						if (item.type === 'route_info') {
							areaId = item.area_id;
						}

						var foundTask = _.find($scope.tasks, { id: item.task_id });

						if (foundTask) {
							foundTask.route_idx = item.route_idx.toString();
							foundTask.user_id = item.user_id;
							foundTask.priority = item.priority;
							foundTask.temp_depot_etl = item.depot_etl;
							foundTask.temp_has_to_leave = item.has_to_leave;
							foundTask.area_id = areaId;

							WayPointsService.getDropOffWayPoint(foundTask).scheduled_at = new Date(item.eta * 1000);
							WayPointsService.getDropOffWayPoint(foundTask).etl = new Date(item.etl * 1000);
							foundTask.temp_time_on_site = item.etl - item.eta;
						}

						if ($scope.type === OPTIMIZATION_TYPES.CREW_BASED) {
							var plannedRoute = _.find($scope.plannedRoutes, { user_id: item.user_id });

							if (!_.isNil(plannedRoute)) {
								users.push({
									name: plannedRoute.drivers_names,
									route_name: plannedRoute.title,
									id: item.user_id,
									primary_driver_id: plannedRoute.user_id
								});
							}
						}

						if (item.type === 'actual_break') {
							breaks[item.route_idx] = item;
						}
					});

					groupedTasks = _.chain($scope.tasks).filter('route_idx').groupBy('route_idx').valueOf();
				}

				if ($scope.unnamedOptimizationTypes.indexOf($scope.type) !== -1) {
					users = [];

					_.each($scope.optimizationData, function (optData) {
						var task = _.find($scope.tasks, { id: optData.task_id });

						if (task) {
							task.route_idx = optData.route_idx.toString();
							task.user_id = optData.route_idx;
							task.user_type_id = optData.user_type_id;
							task.driver_idx = optData.driver_idx;
							task.temp_depot_etl = optData.depot_etl;
							task.temp_has_to_leave = optData.has_to_leave;
							//change the priorities of the tasks based on the data from the optimizations
							task.priority = optData.priority;

							WayPointsService.getDropOffWayPoint(task).scheduled_at = new Date(optData.eta * 1000);
							WayPointsService.getDropOffWayPoint(task).etl = new Date(optData.etl * 1000);
							task.temp_time_on_site = optData.etl - optData.eta;
							userTypesIds[optData.route_idx] = optData.user_type_id;

							if ($scope.type === OPTIMIZATION_TYPES.ANONYMOUS) {
								var userType = _.find($scope.userTypes, { id: optData.user_type_id });
								users.push({ name: userType.title, id: optData.route_idx, type_id: userType.id });
							} else {
								users.push({ name: 'Route-' + (optData.route_idx + 1), id: optData.route_idx });
							}
						}

						if (optData.type === 'actual_break') {
							breaks[optData.route_idx] = optData;
						}
					});

					users = _.uniq(users, 'id');

					groupedTasks = _.chain($scope.tasks).filter('route_idx').groupBy('route_idx').valueOf();
				}

				if ($scope.type === $scope.manualOptimizationType) {
					OptimizationPreviewService.optimizationRequests.push(
						OptimizationPreviewService.buildOptimizationRequests($scope.tasks, $scope.optimizationDate)
					);
					groupedTasks = _.groupBy($scope.tasks, function (task) {
						return task.run_id;
					});

					_.each(groupedTasks, function (tasks) {
						tasks = _.sortBy(tasks, function (task) {
							return new Date(WayPointsService.getDropOffWayPoint(task).scheduled_at);
						});

						tasks.forEach(function (task) {
							var lastWayPoint = WayPointsService.getDropOffWayPoint(task);

							lastWayPoint.temp_time_on_site =
								lastWayPoint.etl && lastWayPoint.eta
									? new Date(lastWayPoint.etl) - new Date(lastWayPoint.eta)
									: null;

							task.route_idx = task.run_id;

							if ($scope.runIdToBreak) {
								breaks[task.route_idx] = $scope.runIdToBreak[task.route_idx];
							}
						});
					});
				}

				var colors = BigMapService.getColors(_.keys(groupedTasks).length);
				var index = 0;

				_.each(groupedTasks, function (tasks, id) {
					tasks = _.chain(tasks).sortBy('priority').map().clone().valueOf();

					var user =
						_.find(users, function (user) {
							return user.id === tasks[0].user_id;
						}) || {};

					var firstTaskFromGroup = _.first(tasks);
					var runId = firstTaskFromGroup.run_id;
					var runName = runId ? 'Route-' + runId : 'Route-' + firstTaskFromGroup.route_idx;

					user.route_name = user.route_name || runName;
					user.service_area_suffix = ServiceAreasService.getServiceAreaSuffix(
						$scope.serviceAreasById,
						firstTaskFromGroup.area_id || user.service_area_id
					);

					data[id] = {
						user: user,
						tasks: tasks,
						color: '#' + colors[index],
						break: breaks[id],
						userTypeId: userTypesIds[id],
						routeIdx: id,
						visible: $scope.renderPinsDefault,
						icon: $scope.getMarkerIcon('#' + colors[index]),
						labelColor: '#' + BigMapService.getLabelColor(colors[index]),
						directions: $scope._getDirections(tasks, colors[index])
					};
					index++;
				});

				var initMap = function () {
					NgMap.getMap('optimization-preview-map').then(function (map) {
						$scope.map = map;
						unregisterMapUpdates = $rootScope.$on('update preview map', function (e, data) {
							$scope.updatePreviewMap(data);
						});
						unregisterHideRoute = $rootScope.$on('hide preview map route', function (e, route) {
							route.visible = false;
							$scope.toggleRouteVisibility(route);
						});
					});
				};

				$scope.sharedData.data = data;
				$scope.visibleRoutes = getVisibleRoutes();
				$scope.updateUILabels();
				initMap();
			};

			$scope.updatePreviewMap = function (data) {
				var changedRoutes = {};
				var originRouteIdx = data.originRouteIdx;
				var targetRouteIdx = data.targetRouteIdx;
				changedRoutes[originRouteIdx] = $scope.sharedData.data[data.originRouteIdx];
				changedRoutes[targetRouteIdx] = $scope.sharedData.data[data.targetRouteIdx];

				PreviewMapLoader.loadRoutesAndMarkers(
					$scope.map,
					changedRoutes,
					$scope.getWayPoint,
					$scope._markerClicked
				);
			};

			$scope.toggleRouteVisibility = function (route) {
				if (route.visible) {
					PreviewMapLoader.showRoute($scope.map, route, $scope.getWayPoint, $scope._markerClicked);
				} else {
					PreviewMapLoader.hideRoute($scope.map, route.routeIdx);
				}

				$scope.visibleRoutes = getVisibleRoutes();
			};

			$scope.maximumVisibleOrders = PreviewMapLoader.MAX_VISIBLE_MARKERS_ALLOWED;

			$scope.numberOfVisibleOrders = function () {
				return PreviewMapLoader.getNumberOfVisibleMarkers();
			};

			$scope.isDriverSelectAllowed = function (userData) {
				return PreviewMapLoader.isDriverSelectAllowed(userData);
			};

			$scope.shouldDisplayListFooterInfo = function () {
				return _.some($scope.sharedData.data, function (userData) {
					return !$scope.isDriverSelectAllowed(userData);
				});
			};

			$scope._showDirectoins = true;
			$scope._recalculateDirections = function (changedRoutes) {
				_.each(changedRoutes, function (routeIdx) {
					var route = $scope.sharedData.data[routeIdx];
					route.directions = $scope._getDirections(route.tasks, route.color.substr(1, 6));
				});
			};

			$scope.inited = false;
			$scope.init = function () {
				$scope.createData();
				$scope.inited = true;
			};

			var getVisibleRoutes = function () {
				return _.pickBy($scope.sharedData.data, function (route) {
					return route.visible;
				});
			};

			$scope.init();

			$scope.$on('$destroy', function () {
				PreviewMapLoader.clearListeners($scope.map);
				PreviewMapLoader.clearData($scope.map);
				unregisterMapUpdates();
				unregisterHideRoute();
			});
		}
	)
	.filter('optimizationPreviewMapTitle', function ($translate) {
		return function (user, displayByRoute) {
			if (displayByRoute) {
				return user.route_name + user.service_area_suffix;
			}

			if (user.name) {
				return user.name;
			}

			return $translate.instant('BIG_MAP.UNASSIGNED');
		};
	})
	.filter('optimizationPreviewMapMarkerLabel', function () {
		return function (priority, color) {
			return { text: priority.toString(), color: color };
		};
	});
