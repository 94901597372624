'use strict';

angular
	.module('bringgApp.directives')
	.directive('csvUploadField', function (
		MerchantConfigurationService,
		toastr,
		Authentication,
		Merchant,
		CsvUploadFieldsService
	) {
		return {
			restrict: 'E',
			scope: {
				type: '@'
			},
			templateUrl: 'scripts/features/configuration/csv_upload_fields/csv-upload-fields.html',
			link: function (scope) {
				scope.data = {};
				scope.loading = true;
				scope.saving = false;
				scope.dirty = false;

				if (scope.type === 'user') {
					scope.getCsvFields = MerchantConfigurationService.getUserCSVFields;
					scope.setCsvFields = MerchantConfigurationService.setUserCSVFields;
				} else {
					scope.getCsvFields = MerchantConfigurationService.getCSVFields;
					scope.setCsvFields = MerchantConfigurationService.setCSVFields;
				}

				scope.addCSVField = function () {
					var lastField = _.last(scope.data.CSVFields);
					var lastIndex = lastField ? lastField.field_index : null;
					var field = {
						field_index: CsvUploadFieldsService.nextFieldIndex(lastIndex),
						field_name: 'priority'
					};
					scope.data.CSVFields.push(field);
					scope.dirty = true;
				};

				scope.fieldChanged = function () {
					scope.dirty = true;
				};

				scope.removeCSVField = function (item) {
					scope.data.CSVFields = _.without(scope.data.CSVFields, _.find(scope.data.CSVFields, item));
					scope.dirty = true;
				};

				scope.saveCSVFieldConfiguration = function () {
					if (scope.saving) {
						return;
					}
					var repeatingFieldName = CsvUploadFieldsService.findRepeatingFieldName(scope.data.CSVFields);
					if (repeatingFieldName !== null) {
						toastr.error('field ' + repeatingFieldName + ' appears more than once');
						return;
					}

					scope.saving = true;

					var csvFields = _.map(scope.data.CSVFields, function (csvField) {
						var parsedNumber = parseInt(csvField.field_index, 10);
						if (_.isNaN(parsedNumber)) {
							return {
								field_index: CsvUploadFieldsService.excelColumnToNumber(csvField.field_index),
								field_name: csvField.field_name
							};
						} else {
							return csvField;
						}
					});

					scope
						.setCsvFields(csvFields)
						.then(function () {
							scope.saving = false;
							toastr.success('CSV fields configuration saved!');
							scope.dirty = false;
						})
						.catch(function () {
							toastr.error('CSV fields configuration failed to save.');
						});
				};

				scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
					if (isLoggedIn) {
						scope.getCsvFields().then(function (fields) {
							scope.data.CSVFields = _.sortBy(fields, 'field_index');
							scope.loading = false;
						});
						Merchant.get(function (data) {
							scope.localTimezone = data.time_zone;
						});
					}
				});

				scope.placeTimezoneInLabel = function (label) {
					return label.replace('{{ localTimezone }}', scope.localTimezone);
				};

				if (scope.type === 'user') {
					scope.csvFieldOptions = [
						{ value: 'external_id', label: 'External Id' },
						{ value: 'id', label: 'Id' },
						{ value: 'name', label: 'Name' },
						{ value: 'first_name', label: 'First Name' },
						{ value: 'last_name', label: 'Last Name' },
						{ value: 'team_id', label: 'Team Id' },
						{ value: 'team_name', label: 'Team Name' },
						{ value: 'team_external_id', label: 'Team External Id' },
						{ value: 'company_name', label: 'Company Name' },
						{ value: 'company_external_id', label: 'Company Id' },
						{ value: 'job_description', label: 'Job Description' },
						{ value: 'user_type_id', label: 'User Type Id' },
						{ value: 'user_type_external_id', label: 'User Type External Id' },
						{ value: 'allowed_user_types_ids', label: 'Allowed User Types Ids' },
						{ value: 'driver', label: 'Driver Flag' },
						{ value: 'dispatcher', label: 'Dispatcher Flag' },
						{ value: 'admin', label: 'Admin Flag' },
						{ value: 'phone', label: 'Phone' },
						{ value: 'email', label: 'Email' },
						{ value: 'password', label: 'Password' },
						{ value: 'skills', label: 'Skills' },
						{ value: 'privileges_name', label: 'Privileges Profile Name' },
						{ value: 'home_address', label: 'Home Address' },
						{ value: 'home_lat', label: 'Home Latitude' },
						{ value: 'home_lng', label: 'Home Longitude' }
					];
				} else {
					scope.csvFieldOptions = [
						{ value: 'external_id', label: 'External Id' },
						{ value: 'service_plan_external_id', label: 'Service Plan External Id' },
						{ value: 'date', label: 'Date' },
						{ value: 'time', label: 'Scheduled Time (today) (UTC)' },
						{ value: 'time_tz', label: 'Scheduled Time (today) with time zone: ({{ localTimezone }})' },
						{ value: 'datetime', label: 'Scheduled Date Time (UTC)' },
						{ value: 'datetime_tz', label: 'Scheduled Date Time with time zone: ({{ localTimezone }})' },
						{ value: 'time_window_with_tz', label: 'Delivery Window with time zone ({{ localTimezone }})' },
						{ value: 'customer_name', label: 'Customer Name' },
						{ value: 'customer_phone', label: 'Customer Phone' },
						{ value: 'customer_first_name', label: 'Customer First Name' },
						{ value: 'customer_last_name', label: 'Customer Last Name' },
						{ value: 'customer_address', label: 'Customer Address (all in one cell)' },
						{ value: 'customer_address_part_1', label: 'Customer Address Part 1' },
						{ value: 'customer_address_part_2', label: 'Customer Address Part 2' },
						{ value: 'customer_address_part_3', label: 'Customer Address Part 3' },
						{ value: 'customer_address_part_4', label: 'Customer Address Part 4' },
						{ value: 'customer_address_part_5', label: 'Customer Address Part 5' },
						{ value: 'street', label: 'Customer Street' },
						{ value: 'borough', label: 'Customer Borough' },
						{ value: 'city', label: 'Customer City' },
						{ value: 'state', label: 'Customer State' },
						{ value: 'zipcode', label: 'Customer Zipcode' },
						{ value: 'customer_external_id', label: 'Customer External Id' },
						{ value: 'zipcode', label: 'Zipcode / Postal Code' },
						{ value: 'address_second_line', label: 'Customer Address Second Line' },
						{ value: 'customer_email', label: 'Customer Email' },
						{ value: 'customer_lat', label: 'Customer Latitude' },
						{ value: 'customer_lng', label: 'Customer Longitude' },
						{ value: 'task_note', label: 'Task Note' },
						{ value: 'driver_name', label: "Driver's Name" },
						{ value: 'title', label: 'Task Title' },
						{ value: 'total_price', label: "Task's Total Price" },
						{ value: 'delivery_price', label: "Task's Delivery Price" },
						{ value: 'wp_lat', label: 'Waypoint Latitude' },
						{ value: 'wp_lng', label: 'Waypoint Longitude' },
						{ value: 'wp_position', label: 'Waypoint Position' },
						{ value: 'priority', label: 'Priority' },
						{ value: 'scan_code', label: 'Inventory Scan Code' },
						{ value: 'inventory_title', label: "Inventory's Title" },
						{ value: 'inventory_external_id', label: "Inventory's External Id" },
						{ value: 'inventory_price', label: "Inventory's Total Price" },
						{ value: 'inventory_quantity', label: "Inventory's Quantity" },
						{ value: 'task_inventory_note', label: "Task Inventory's Note" },
						{
							value: 'pickup_dropoff',
							label: 'Inventory needs to be picked up (value 0) or dropped off (value 1)'
						},
						{ value: 'weight', label: 'Inventory Weight' },
						{ value: 'width', label: 'Inventory Width' },
						{ value: 'length', label: 'Inventory Length' },
						{ value: 'height', label: 'Inventory Height' },
						{ value: 'team_id', label: 'Team ID' },
						{ value: 'tag_id', label: 'Tag ID' },
						{ value: 'tag', label: 'Tag' },
						{ value: 'delivery_method', label: 'Delivery Method' },
						{ value: 'delivery_confirmation_scan', label: 'Scan Delivery Confirmation Required' },
						{ value: 'delivery_confirmation_note', label: 'Note Delivery Confirmation Required' },
						{ value: 'delivery_confirmation_photo', label: 'Photo Delivery Confirmation Required' },
						{ value: 'delivery_confirmation_signature', label: 'Signature Delivery Confirmation Required' },
						{ value: 'delivery_confirmation_form', label: 'Form Delivery Confirmation Required' },
						{ value: 'delivery_confirmation_ocr', label: 'OCR Delivery Confirmation Required' },
						{ value: 'pickup_customer_name', label: 'Pickup Customer Name' },
						{ value: 'pickup_location_address_1', label: 'Pickup Customer Main Address' },
						{ value: 'pickup_location_address_second_line', label: 'Pickup Customer 2nd Line Address' },
						{ value: 'pickup_location_borough', label: 'Pickup Customer Borough' },
						{ value: 'pickup_location_city', label: 'Pickup Customer City' },
						{ value: 'pickup_location_state', label: 'Pickup Customer State' },
						{ value: 'pickup_location_zipcode', label: 'Pickup Customer Zipcode' },
						{ value: 'due_date', label: 'Due Date' },
						{ value: 'no_later_than', label: 'No Later Than (UTC)' },
						{ value: 'no_earlier_than', label: 'No Earlier Than (UTC)' },
						{ value: 'no_later_than_tz', label: 'No Later Than (my time zone: {{ localTimezone }})' },
						{ value: 'no_earlier_than_tz', label: 'No Earlier Than (my time zone: {{ localTimezone }})' },
						{ value: 'sack_id', label: 'Sack ID' },
						{ value: 'task_type_id', label: 'Task Type ID' },
						{ value: 'task_configuration_id', label: 'Task Configuration ID' },
						{ value: 'team_external_id', label: 'Team External ID' },
						{ value: 'etos', label: 'Waypoint ETOS' },
						{ value: 'extras', label: "Task's Extras" },
						{ value: 'required_skills', label: 'Required Skills' },
						{ value: 'scheduled_arrival', label: 'Scheduled Arrival (UTC)' },
						{
							value: 'scheduled_arrival_tz',
							label: 'Scheduled Arrival (my time zone: {{ localTimezone }})'
						}
					];
				}
			}
		};
	});
