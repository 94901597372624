'use strict';
angular
	.module('bringgApp')
	.service(
		'AssignDriverValidations',
		function (Authentication, Tasks, PRIVILEGES_TYPES, FleetChecker, TASK_DEFAULT_TYPES) {
			const assignDriverValidationsContext = this;
			let _userHasAssignDriverPrivileges = null;

			assignDriverValidationsContext.userHasAssignDriverPrivileges = () => {
				if (_userHasAssignDriverPrivileges === null) {
					_userHasAssignDriverPrivileges = Authentication.currentUser().has_access(
						PRIVILEGES_TYPES.ASSIGN_DRIVERS_TO_TASK
					);
				}

				return _userHasAssignDriverPrivileges;
			};

			assignDriverValidationsContext.isAllowedToAssignSpecificTask = function (task) {
				const isAllowedAnswer = _validateTasksFilter([task], specificTaskValidations);
				return assignDriverValidationsContext.userHasAssignDriverPrivileges() && isAllowedAnswer.canAssign;
			};

			assignDriverValidationsContext.isAllowedToBulkAssign = function (tasks) {
				const isAllowedAnswer = _validateBulkTasks(tasks, bulkAssignValidations);
				isAllowedAnswer.canAssign =
					assignDriverValidationsContext.userHasAssignDriverPrivileges() && isAllowedAnswer.canAssign;
				isAllowedAnswer.errMessage = ERROR_MESSAGE_BY_CODE[isAllowedAnswer.errSum.toString()] || '';
				return isAllowedAnswer;
			};

			assignDriverValidationsContext.isAllowedToAssignSelectedTasks = function (tasks) {
				const isAllowedToBulkAssign = _validateBulkTasks(tasks, bulkAssignValidations);
				const isAllowedToAssignSelectedTasks = _validateTasksFilter(tasks, specificTaskValidations);
				isAllowedToAssignSelectedTasks.errSum += isAllowedToBulkAssign.errSum;
				isAllowedToAssignSelectedTasks.canAssign =
					assignDriverValidationsContext.userHasAssignDriverPrivileges() && isAllowedToBulkAssign.canAssign;
				isAllowedToAssignSelectedTasks.errMessage =
					ERROR_MESSAGE_BY_CODE[isAllowedToAssignSelectedTasks.errSum.toString()] || '';
				return isAllowedToAssignSelectedTasks;
			};

			const _validateBulkTasks = function (tasks, validations) {
				return _processValidationResult(tasks, validations);
			};

			function _processValidationResult(task, validations) {
				let errSum = 0;
				let canAssign = true;
				validations.forEach(checker => {
					if (checker.validation(task)) {
						canAssign = false;
						errSum = errSum | checker.errCode;
					}
				});
				return { canAssign, errSum };
			}

			const _validateTasksFilter = function (tasks, validations) {
				let assignableTasks = [];
				let errSumTotal = 0;
				tasks.forEach(task => {
					const { canAssign, errSum } = _processValidationResult(task, validations);
					errSumTotal = errSumTotal | errSum;
					if (canAssign) {
						assignableTasks.push(task);
					}
				});
				return { canAssign: assignableTasks.length > 0, assignableTasks, errSum: errSumTotal };
			};

			function isTaskClickAndCollect(task) {
				return task.task_type_id === TASK_DEFAULT_TYPES.CLICK_AND_COLLECT;
			}

			const ASSIGN_USER_ERROR_CODE_ENUM = {
				noOrders: 1,
				multiTeamsSelected: 2,
				externalTeamSelected: 4,
				clickAndCollectSelected: 8
			};

			const ERROR_MESSAGE_BY_CODE = (function () {
				var errMessageByCode = {};
				errMessageByCode[ASSIGN_USER_ERROR_CODE_ENUM.noOrders.toString()] = 'DISPATCH_LIST.TOOLTIP_NO_ORDERS';
				errMessageByCode[ASSIGN_USER_ERROR_CODE_ENUM.multiTeamsSelected.toString()] =
					'DISPATCH_LIST.TOOLTIP_DIFFERENT_TEAMS';
				errMessageByCode[ASSIGN_USER_ERROR_CODE_ENUM.externalTeamSelected.toString()] =
					'DISPATCH_LIST.TOAST_SOME_TASKS_BELONG_TO_EXTERNAL_FLEET';
				errMessageByCode[ASSIGN_USER_ERROR_CODE_ENUM.clickAndCollectSelected.toString()] =
					'DISPATCH_LIST.TOAST_SOME_TASKS_ARE_CLICK_AND_COLLECT';
				errMessageByCode[
					(
						ASSIGN_USER_ERROR_CODE_ENUM.externalTeamSelected |
						ASSIGN_USER_ERROR_CODE_ENUM.clickAndCollectSelected
					).toString()
				] = 'DISPATCH_LIST.TOAST_CLICK_COLLECT_AND_EXTERNAL_FLEET';
				return errMessageByCode;
			})();

			const bulkAssignValidations = [
				{
					validation: tasks => tasks === undefined || tasks.length === 0,
					errCode: ASSIGN_USER_ERROR_CODE_ENUM.noOrders
				},
				{
					validation: Tasks.isMultiTeamTasksSelected,
					errCode: ASSIGN_USER_ERROR_CODE_ENUM.multiTeamsSelected
				}
			];

			const specificTaskValidations = [
				{
					validation: task => FleetChecker.isExternalFleet(task.fleet_id),
					errCode: ASSIGN_USER_ERROR_CODE_ENUM.externalTeamSelected
				},
				{
					validation: isTaskClickAndCollect,
					errCode: ASSIGN_USER_ERROR_CODE_ENUM.clickAndCollectSelected
				}
			];
		}
	);
