'use strict';

angular.module('bringgApp').service('FleetChecker', function (Merchant, $injector) {
	this.isExternalFleet = function (fleetId) {
		// when entity don't have fleet_id we consider it as assigned to an internal fleet
		if (!fleetId) {
			return false;
		}

		const merchant = Merchant.getLocal();
		const internalFleetId = merchant.internal_fleet_id;

		return fleetId !== internalFleetId;
	};

	this.isInternalFleet = fleetId => !this.isExternalFleet(fleetId);

	this.hasExternalFleets = function () {
		const merchant = Merchant.getLocal();
		const internalFleetId = merchant.internal_fleet_id;
		const Fleets = $injector.get('Fleets');

		return Object.values(Fleets.mappedById).filter(fleet => fleet.id !== internalFleetId).length > 0;
	};
});
