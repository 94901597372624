'use strict';

angular.module('bringgApp').directive('uiGridEditDropdown2', [
	'uiGridConstants',
	'uiGridEditConstants',
	'$timeout',
	function (uiGridConstants, uiGridEditConstants, $timeout) {
		return {
			require: ['?^uiGrid', '?^uiGridRenderContainer'],
			scope: true,
			compile: function () {
				return {
					pre: function ($scope, $elm, $attrs) {},
					post: function ($scope, $elm, $attrs, controllers) {
						var uiGridCtrl = controllers[0];
						var renderContainerCtrl = controllers[1];

						//set focus at start of edit
						$scope.$on(uiGridEditConstants.events.BEGIN_CELL_EDIT, function () {
							$timeout(function () {
								$elm[0].focus();
							});

							$elm[0].style.width = $elm[0].parentElement.offsetWidth - 1 + 'px';
							$elm.on('blur', function (evt) {
								$scope.stopEdit(evt);
							});
						});

						$scope.stopEdit = function (evt) {
							// no need to validate a dropdown - invalid values shouldn't be
							// available in the list
							$scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
						};

						$elm.on('keydown', function (evt) {
							switch (evt.keyCode) {
								case uiGridConstants.keymap.ESC:
									evt.stopPropagation();
									$scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
									break;
							}
							if (uiGridCtrl && uiGridCtrl.grid.api.cellNav) {
								evt.uiGridTargetRenderContainerId = renderContainerCtrl.containerId;
								if (uiGridCtrl.cellNav.handleKeyDown(evt) !== null) {
									$scope.stopEdit(evt);
								}
							} else {
								//handle enter and tab for editing not using cellNav
								switch (evt.keyCode) {
									case uiGridConstants.keymap.ENTER: // Enter (Leave Field)
									case uiGridConstants.keymap.TAB:
										evt.stopPropagation();
										evt.preventDefault();
										$scope.stopEdit(evt);
										break;
								}
							}
							return true;
						});
					}
				};
			}
		};
	}
]);
