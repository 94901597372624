'use strict';

angular
	.module('bringgApp')
	.controller(
		'BillingController',
		function (
			$scope,
			$http,
			$rootScope,
			$location,
			ENV_CONFIGURATION,
			MerchantConfigurations,
			Authentication,
			WindowCommunicationService,
			$window,
			$sce
		) {
			$scope.isBillingAppInSideMenu = true;

			Authentication.featureFlags().then(flags => {
				const FF = 'enable_delivery_hub_in_menu';
				const merchantConfiguration = 'delivery_hub_main_menu_ui';
				$scope.isBillingAppInSideMenu =
					_.get(MerchantConfigurations, FF) === true ||
					_.get(flags, FF) === true ||
					_.get(MerchantConfigurations, merchantConfiguration) === true;

				if (!$scope.isBillingAppInSideMenu) {
					$rootScope.hideMenu = true;
				}
			});

			var locationChangeStartDisposer = $scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
				var path = '#/billing';

				// As angularJS know nothing about internal Billing app routing and want to perform a route change but
				// actually from /billing to /billing then we are preventing it from route change
				// also `reloadOnSearch: false` in route config doesn't work either.
				if (newUrl && newUrl.indexOf(path) > -1 && oldUrl && oldUrl.indexOf(path) > -1) {
					return event.preventDefault();
				}
			});

			$scope.$on('$destroy', function () {
				if (!$scope.isBillingAppInSideMenu) {
					$rootScope.hideMenu = false;
				}

				locationChangeStartDisposer();
			});

			$scope.getBillingUrl = function () {
				// eslint-disable-next-line
				var childrenRoutes = $location.$$url.replace(/(\/)*billing(\/)*/g, '');
				var childrenRoutesString = childrenRoutes ? '?childrenRoutes=' + childrenRoutes : '';

				return $sce.trustAsResourceUrl(ENV_CONFIGURATION.billing_app_url + childrenRoutesString);
			};

			$scope.sendCredentials = function () {
				var contentWindow = document.getElementById('billingIFrame').contentWindow;

				WindowCommunicationService.sendCredentials(contentWindow);
			};
		}
	);
