'use strict';

angular.module('bringgApp').factory('CompaniesService', function (BringgSDK) {
	function companiesService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.companies[method].apply(bringg.companies, args);
			});
		}.bind(this);
	}

	var CompaniesService = {};

	CompaniesService.getAll = companiesService('getAll');
	CompaniesService.get = companiesService('get');

	return CompaniesService;
});
