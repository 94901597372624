'use strict';

angular
	.module('bringgApp')
	.directive(
		'leroyMerlinTaskInvoice',
		function ($translate, $timeout, $q, Employees, CompaniesService, TaskInventoriesService, Fleets) {
			return {
				restrict: 'E',
				scope: {
					tasks: '=',
					config: '=',
					printedCopies: '=',
					printCallback: '='
				},
				link: function (scope, element, attrs) {
					scope.getTotalWeight = function (task) {
						var wayPoint = _.last(task.way_points);
						var taskInventories = _.filter(task.task_inventories, { way_point_id: wayPoint.id });
						// calculate for parent task inventory + or orphand inventories
						taskInventories = _.filter(taskInventories, TaskInventoriesService.isParentTaskInventory);
						var totalWeight = _.reduce(
							taskInventories,
							function (totalWeight, taskInventory) {
								if (_.isNull(taskInventory.weight)) {
									return totalWeight;
								} else {
									var taskInventoryWeight = taskInventory.original_quantity * taskInventory.weight;
									return totalWeight + taskInventoryWeight;
								}
							},
							0
						);

						return totalWeight.toLocaleString('es');
					};

					scope.getWayPointNote = function (task, wayPointId) {
						var notes = _.chain(task.task_notes)
							.filter({ type: 'TaskNote', way_point_id: wayPointId })
							.map('note')
							.value();
						return notes.join(', ');
					};

					scope.onUserLoaded = function (user) {
						if (!_.isNumber(user.company_id)) {
							return;
						}
						user.company = _.find(scope.companies, { id: user.company_id });
					};

					function fetchFleetName(task) {
						if (!_.isNumber(task.fleet_id)) {
							return;
						}
						scope.fleetName = Fleets.getName(task.fleet_id);
					}

					function fetchTaskUser(task) {
						if (!_.isNil(task.user)) {
							scope.onUserLoaded(task.user);
							return $q.resolve();
						}

						if (!_.isNumber(task.user_id)) {
							return $q.resolve();
						}

						return Employees.get({ id: task.user_id }).then(function (user) {
							task.user = user;
							scope.onUserLoaded(task.user);
						});
					}

					scope.loading = true;
					scope.companies = [];

					$q.all([CompaniesService.getAll(), Fleets.init()]).then(function (result) {
						scope.companies = result[0].companies;
						_.map(scope.tasks, fetchFleetName);
						$q.all(_.map(scope.tasks, fetchTaskUser)).then(function () {
							scope.loading = false;
							$timeout(scope.printCallback, 100, true, element);
						});
					});
				},
				templateUrl: 'scripts/features/print/invoices/leroy-merlin-invoice.html'
			};
		}
	);
