'use strict';

/*global google:false */

angular.module('bringgApp.directives').directive('geoLocatingSelect', function ($filter, $q) {
	return {
		restrict: 'A',
		require: '',
		link: function (scope, element) {
			var getAddresses = function (value) {
				var deferred = $q.defer();

				var map = new google.maps.Map(document.createElement('div'));
				var placesService = new google.maps.places.PlacesService(map);

				placesService.textSearch(
					{
						query: value
					},
					function (results, status) {
						if (status === 'OK') {
							deferred.resolve(results);
						} else {
							var geocoder = new google.maps.Geocoder();
							geocoder.geocode(
								{
									address: value
								},
								function (results, status) {
									if (status === google.maps.GeocoderStatus.OK) {
										deferred.resolve(results);
									} else {
										deferred.reject(status);
									}
								}
							);
						}
					}
				);
				return deferred.promise;
			};

			scope.addresses = [];

			scope.refreshAddresses = function (value) {
				if (!value || value.length < 3) {
					return;
				}

				getAddresses(value)
					.then(function (results) {
						scope.addresses = results;
					})
					.catch(function (status) {
						scope.addresses = [
							{ formatted_address: $filter('googleGeolocationStringFilter')(status), disabled: true }
						];
					});
			};
		}
	};
});
