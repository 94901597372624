'use strict';

angular
	.module('bringgApp')
	.constant('PREPARATION_STATUS', {
		ACKNOWLEDGED: 0,
		START_PREPARATION: 1,
		PREPARATION_STARTED: 2,
		READY_FOR_PICK_UP: 3,
		PICKED_UP: 4,
		SEND_TO_PREPARATION_FAILED: 5,
		UNACKNOWLEDGED: 6
	})
	.constant('DEFAULT_PREPARATION_SCREEN_FAILED', {
		alert: {
			translation: 'DISPATCHER_ACKNOWLEDGE.SEND_TO_PREPARATION_FAILED'
		},
		acknowledge: {
			showing: true,
			translation: 'DISPATCHER_ACKNOWLEDGE.SEND_TO_PREPARATION_MANUALLY',
			className: 'btn-green'
		}
	})
	.constant('PAYMENT_METHODS', {
		UNKNOWN: 0,
		CASH: 1,
		CREDIT: 2,
		CHEQUE: 3,
		CREDIT_CARD_TERMINAL: 4
	})
	.constant('DEFAULT_PREPARATION_SCREEN', {
		templateUrl: 'scripts/features/dispatcher_acknowledge/dispatcher-acknowledge-task-kds.html',
		ack_type: 1,
		sound_index: 4,
		alert: {
			translation: 'DISPATCHER_ACKNOWLEDGE.SEND_TO_PREPARATION_FAILED'
		},
		modal: {
			showing: true,
			modalType: 'kds',
			windowClass: 'preparation',
			fontSize: 'preparation-font'
		},
		remindMe: {
			showing: true,
			translation: 'DISPATCHER_ACKNOWLEDGE.REMIND_ME_LATER'
		},
		reject: {
			showing: false,
			translation: 'DISPATCHER_ACKNOWLEDGE.REJECT'
		},
		acknowledge: {
			showing: true,
			translation: 'DISPATCHER_ACKNOWLEDGE.SEND_TO_PREPARATION',
			className: 'btn-green'
		},
		task_fields: [
			{
				field: 'user.name',
				filter: 'ackIsFieldEmpty',
				label: 'DISPATCHER_ACKNOWLEDGE.DRIVER_NAME'
			},
			{
				field: 'preparation_ready_for_pickup_time_planned',
				filter: 'human_time',
				label: 'DISPATCHER_ACKNOWLEDGE.TIME_TO_PICKUP'
			},
			{
				field: 'extras.cpf',
				filter: 'ackIsFieldEmpty',
				label: 'DISPATCHER_ACKNOWLEDGE.CPF'
			},
			{
				field: 'payment_method',
				filter: 'paymentMethod',
				label: 'DISPATCHER_ACKNOWLEDGE.TYPE_OF_PAYMENT'
			},
			{
				field: 'total_price',
				filter: 'currency',
				label: 'TASK.TOTAL_PRICE'
			}
		],
		way_points_position: [2],
		way_points_fields: [
			{
				field: 'address',
				advanced_filter: 'ackWayPointAddress',
				label: 'ORDER.ADDRESS'
			},
			{
				filter: 'human_time',
				field: 'scheduled_at',
				label: 'ORDER.SCHEDULED_FOR'
			},
			{
				field: 'customer.name',
				advanced_filter: 'ackWayPointCustomerName',
				label: 'ORDER_INVOICE.CUSTOMER'
			},
			{
				field: 'taskInventory',
				label: 'DISPATCHER_ACKNOWLEDGE.INVENTORY',
				fields: ['id', 'name'],
				path: 'scripts/features/dispatcher_acknowledge/task-inventory.html'
			}
		]
	})
	.factory(
		'KdsService',
		function (
			$rootScope,
			$filter,
			SOUND_TYPE,
			DEFAULT_PREPARATION_SCREEN,
			DEFAULT_PREPARATION_SCREEN_FAILED,
			$log,
			toastr,
			$interval,
			Task,
			PREPARATION_STATUS,
			TASK_STATUS,
			$route,
			SoundPlayerService,
			DispatcherAcknowledgeTaskStatusService,
			MerchantConfigurations,
			Tasks,
			TasksLoader,
			$q,
			$translate,
			DEFAULT_RT_THROTTLE,
			Authentication,
			TasksData
		) {
			var ADVANCE_TASKS_TICK = 1000;
			var kdsService = {};
			var PREPARATION_FLOWS = [
				{
					status: PREPARATION_STATUS.ACKNOWLEDGED,
					nextStatus: PREPARATION_STATUS.START_PREPARATION,
					property: 'preparation_start_time_planned'
				}
			];
			var loopingSoundSendToPreparation = SoundPlayerService.initLoopingSounds();

			kdsService.ignoreShowPreparationPopup = false;

			/**
			 * Map from task preparation status to the task
			 * The preparation statuses can be: accepted, in preparation and ready for pickup
			 */
			kdsService._byStatus = {};
			kdsService._tasks = [];

			kdsService.getTaskStatus = function (task) {
				return task.preparation_status;
			};

			kdsService._shouldShowTask = function (task) {
				return _.includes(
					[
						PREPARATION_STATUS.ACKNOWLEDGED,
						PREPARATION_STATUS.START_PREPARATION,
						PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED,
						PREPARATION_STATUS.PREPARATION_STARTED,
						PREPARATION_STATUS.READY_FOR_PICK_UP
					],
					task.preparation_status
				);
			};

			kdsService.filterTaskByStatus = function () {
				kdsService._tasks = _.filter(TasksData.getOpenTasks(), kdsService._shouldShowTask);
				kdsService._byStatus = _.groupBy(kdsService._tasks, kdsService.getTaskStatus);

				_.each(kdsService._byStatus[PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED], function (task) {
					kdsService.trySendToPreparation(task);
				});

				$rootScope.$broadcast('kds list update');
			};

			kdsService._filterTasksByStatus = function (status, property) {
				var now = moment();
				return _.chain(kdsService._byStatus[status])
					.filter(function (task) {
						return task[property] && moment(now).isSameOrAfter(task[property]);
					})
					.orderBy(function (task) {
						return task[property] && moment(task[property]).valueOf();
					})
					.value();
			};

			kdsService._promoteTasks = function (tasks) {
				if (kdsService.ignoreShowPreparationPopup) {
					return $q.resolve();
				}

				_.each(tasks, function (task) {
					kdsService.trySendToPreparation(task);
				});

				return $q.resolve();
			};

			kdsService._advanceTasks = function () {
				var needToPromote = _.map(PREPARATION_FLOWS, function (preparationFlow) {
					if (_.isNil(preparationFlow.property)) {
						return;
					}

					var tasksToPromoteToNextStatus = kdsService._filterTasksByStatus(
						preparationFlow.status,
						preparationFlow.property
					);
					if (!_.isEmpty(tasksToPromoteToNextStatus)) {
						return {
							tasks: tasksToPromoteToNextStatus,
							flow: preparationFlow
						};
					}
				});

				needToPromote = _.compact(needToPromote);

				return $q.all(
					_.map(needToPromote, function (promote) {
						return kdsService._promoteTasks(promote.tasks, promote.flow);
					})
				);
			};

			kdsService._runAdvanceTasksLoop = function () {
				return $interval(function () {
					kdsService._advanceTasks();
				}, ADVANCE_TASKS_TICK);
			};

			kdsService.trySendToPreparation = function (task) {
				if (MerchantConfigurations.enable_looping_sound_send_to_preparation_kds) {
					loopingSoundSendToPreparation.play(task.id);
				}

				if (
					task.preparation_status === PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED &&
					!task.preparation_failed
				) {
					task.preparation_failed = true;
					task.last_history = {};
					Task.history({ id: task.id }).$promise.then(function (taskHistories) {
						var prepStatusChangedType = 41;
						task.last_history =
							_.find(taskHistories, {
								event: prepStatusChangedType,
								new_value: _.toString(PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED)
							}) || {};
					});
				}

				if (
					!Authentication.currentUser().dispatcher ||
					!Authentication.currentUser().has_access('send_to_preparation')
				) {
					return;
				}

				var failedExetensionConfiguration =
					task.preparation_status === PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED
						? DEFAULT_PREPARATION_SCREEN_FAILED
						: {};
				DispatcherAcknowledgeTaskStatusService.addTask(
					task,
					_.extend(
						{},
						DEFAULT_PREPARATION_SCREEN,
						MerchantConfigurations.preparation_flow_ui_config,
						failedExetensionConfiguration
					),
					function (task) {
						var nextStatus =
							task.preparation_status === PREPARATION_STATUS.SEND_TO_PREPARATION_FAILED
								? PREPARATION_STATUS.PREPARATION_STARTED
								: PREPARATION_STATUS.START_PREPARATION;
						return kdsService.onAcknowledgeClicked(task, nextStatus);
					},
					null,
					task.preparation_failed
				);
			};

			kdsService.onAcknowledgeClicked = function (task, nextStatus) {
				return kdsService.updatePreparationStatus(task, nextStatus).then(function (response) {
					if (!_.isNil(response.task)) {
						DispatcherAcknowledgeTaskStatusService.removeTask(response.task);
					}
					return response;
				});
			};

			kdsService.updatePreparationStatus = function (task, preparationStatus) {
				$log.info('trying to update task:' + task.id + 'with status: ' + preparationStatus);

				if (MerchantConfigurations.enable_looping_sound_send_to_preparation_kds) {
					loopingSoundSendToPreparation.stop(task.id);
				}

				return Tasks.updatePreparationStatus(task, preparationStatus)
					.then(function (response) {
						// If task started preparation - then we get in response: task
						// update the task
						if (response.success) {
							delete task.preparation_failed;
							delete task.last_history;

							// Force run the filtering and don't rely on task update events
							// since they are throttle and filter tasks by status is throttle
							kdsService.filterTaskByStatus();

							toastr.success($filter('translatedStatus')(response));
						}

						// If we got an error without task - as in, we can't recover from this failure
						// show error to the user
						if (!response.success && _.isEmpty(response.task)) {
							$log.info('updatePreparationStatus returned error', response.message);
							toastr.error(response.message);
						}

						return response;
					})
					.catch(function (error) {
						$log.info('updatePreparationStatus catch error', error);
						toastr.error(error.details, $translate.instant('ERRORS.ERROR_OCCURRED'));
						return { success: false, message: error.message };
					});
			};

			kdsService.taskCancelled = function (event, task) {
				// remove task from unacknowledged.
				if (!DispatcherAcknowledgeTaskStatusService.removeTask(task)) {
					// show modal only if we are on KDS screen and task.status is cancelled or rejected
					var status = task.status === TASK_STATUS.CANCELLED || task.status === TASK_STATUS.REJECTED;
					if ($route.current.$$route.title === 'KDS' && status) {
						var defaultCancelModalConfiguration = {
							sound_index: SOUND_TYPE.CANCELED_TASK_LOUD,
							templateUrl: 'scripts/features/dispatcher_acknowledge/dispatcher-acknowledge-task-kds.html',
							modal: {
								showing: true,
								modalType: 'kds',
								windowClass: 'cancellation',
								fontSize: 'cancellation-font'
							},
							remindMe: {
								showing: false,
								translation: 'DISPATCHER_ACKNOWLEDGE.REMIND_ME_LATER'
							},
							reject: {
								showing: false,
								translation: 'DISPATCHER_ACKNOWLEDGE.REJECT'
							},
							acknowledge: {
								showing: true,
								translation: 'DISPATCHER_ACKNOWLEDGE.CANCELLED_TASK',
								className: 'btn-red'
							}
						};

						var config = _.extend({}, DEFAULT_PREPARATION_SCREEN, defaultCancelModalConfiguration);

						if (MerchantConfigurations.sound_on_new_order && config.sound_index) {
							SoundPlayerService.playSoundAt(config.sound_index);
						}

						DispatcherAcknowledgeTaskStatusService.addTask(task, config, function () {
							return $q.resolve({
								success: true
							});
						});
					}
				}
			};

			var filterTaskByStatus = _.throttle(function () {
				kdsService.filterTaskByStatus();
			}, DEFAULT_RT_THROTTLE);

			kdsService.isLoaded = false;
			kdsService.advanceTasksInterval = null;
			kdsService.init = function () {
				if (!MerchantConfigurations.enable_kds) {
					return;
				}

				$log.info('kdsService init');

				$log.info('filtering tasks by status');
				kdsService.filterTaskByStatus();

				if (kdsService.isLoaded) {
					return;
				}

				kdsService.isLoaded = true;

				$log.info('starting _runAdvanceTasksLoop');
				kdsService.advanceTasksInterval = kdsService._runAdvanceTasksLoop();

				$rootScope.$on('task list update', function () {
					filterTaskByStatus();
				});

				TasksLoader.loadOpenForTable();
				$rootScope.$on('lazy task list update', function () {
					filterTaskByStatus();
				});

				$rootScope.$on('task done', kdsService.taskCancelled);

				$rootScope.$on('loggedout', function () {
					if (kdsService.advanceTasksInterval) {
						$interval.cancel(kdsService.advanceTasksInterval);
						kdsService.advanceTasksInterval = null;
					}
				});

				$rootScope.$on('loggedin', function () {
					if (kdsService.isLoaded && !kdsService.advanceTasksInterval) {
						kdsService.advanceTasksInterval = kdsService._runAdvanceTasksLoop();
					}
				});

				Authentication.featureFlags().then(function (featureFlags) {
					kdsService.ignoreShowPreparationPopup = featureFlags.ignore_show_preparation_popup || false;
				});
			};

			return kdsService;
		}
	)
	.filter('translatedStatus', function ($translate, PREPARATION_STATUS) {
		return function (response) {
			var kdsPrefix = 'KDS.' + _.invert(PREPARATION_STATUS)[response.task.preparation_status].toUpperCase();

			return (
				$translate.instant('KDS.TASK') +
				' # ' +
				response.task.external_id +
				' ' +
				$translate.instant('KDS.TASK_WAS_MOVED_TO') +
				' ' +
				$translate.instant(kdsPrefix)
			);
		};
	});
