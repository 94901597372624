'use strict';

angular
	.module('bringgApp')
	.factory('TeamShiftsScheduleResource', function (WEB_API_URL, $resource) {
		var TeamShiftsScheduleResource = $resource(
			WEB_API_URL + '/teams/:id/shifts_schedule/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/teams/:id/shifts_schedule/' }
			}
		);

		return TeamShiftsScheduleResource;
	})
	.factory('TeamShiftsScheduleService', function (TeamShiftsScheduleResource, $log, $q) {
		var TeamShiftsScheduleService = {};

		TeamShiftsScheduleService.get = function (driverId) {
			var deferred = $q.defer();

			TeamShiftsScheduleResource.get({ id: driverId }, function (result) {
				if (result.success) {
					deferred.resolve({ data: result.shift_schedules, custom: result.custom });
				} else {
					deferred.reject(result);
				}
			});

			return deferred.promise;
		};

		TeamShiftsScheduleService.update = function (driverId, configuration, resetToDefault) {
			var deferred = $q.defer();

			var newConfig = new TeamShiftsScheduleResource({
				id: driverId,
				configuration: configuration,
				reset_to_default: resetToDefault
			});
			newConfig.$update(
				function (data) {
					deferred.resolve({ data: data.shift_schedules, custom: data.custom });
				},
				function () {
					deferred.reject('Failed updating merchant');
				}
			);

			return deferred.promise;
		};

		return TeamShiftsScheduleService;
	});
