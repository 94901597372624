'use strict';

angular
	.module('bringgApp')
	.factory('BetaProgramService', function (dialogSrv, $q, $log, $rootScope, MerchantConfigurationService, toastr) {
		var BetaProgramService = {};

		$rootScope.$on('admin.enroll.beta', function (evt, data) {
			MerchantConfigurationService.requestBetaProgram(data)
				.then(function () {
					toastr.success('Thank you, we will contact you shortly.');
				})
				.catch(function () {
					toastr.error(
						'There was an error submitting your request please try latter or contact us at support@bringg.com'
					);
				});
		});

		BetaProgramService.open = function (featureName) {
			dialogSrv.confirmDialog(
				featureName + ' is enabled for our Beta users.',
				'Would you like to become our beta member?',
				'admin.enroll.beta',
				featureName
			);
		};

		return BetaProgramService;
	});
