'use strict';

angular
	.module('bringgApp')
	.controller('TipDriverController', function (
		$scope,
		MerchantConfigurationService,
		toastr,
		MerchantService,
		Payments,
		MerchantConfigurations
	) {
		$scope.loading = true;
		$scope.saving = false;
		$scope.data = {};
		$scope.data.type = '0';
		$scope.data.tip_driver_enabled = false;
		$scope.data.sign = '%';
		$scope.data.currency = '';
		$scope.data.otherAmountEnabled = true;
		$scope.data.requireSignature = true;

		$scope.enable_task_price = MerchantConfigurations.enable_task_price;
		$scope.$on('config updated', function () {
			$scope.enable_task_price = MerchantConfigurations.enable_task_price;
		});

		$scope.$watch('data.use_tip_driver', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			$scope.data.tip_driver_enabled = newValue;
		});

		MerchantConfigurationService.getTipDriverConfiguration().then(function (tipDriverConfiguration) {
			$scope.data.tip_driver_enabled = tipDriverConfiguration.tip_driver_enabled;
			$scope.data.type = tipDriverConfiguration.tip_type.toString();
			if ($scope.data.type === '0') {
				$scope.data.sign = '%';
			} else {
				$scope.data.sign = tipDriverConfiguration.tip_currency;
			}
			$scope.data.currency = tipDriverConfiguration.tip_currency;
			$scope.data.requireSignature = tipDriverConfiguration.tip_require_signature;
			$scope.data.otherAmountEnabled = tipDriverConfiguration.tip_other_amount_enabled;
			$scope.data.amounts = tipDriverConfiguration.tip_amounts;
			$scope.loading = false;
		});

		//$scope.$watch('data.type', function (newValue, oldValue) {
		//  if (newValue === oldValue) {
		//    return;
		//  }
		//
		//  if (newValue === '0') {
		//    $scope.data.sign = '%';
		//  } else {
		//    $scope.data.sign = $scope.data.currency ? $scope.data.currency : '';
		//  }
		//});

		//$scope.$watch('data.currency', function (newValue, oldValue) {
		//  if (newValue === oldValue) {
		//    return;
		//  }
		//
		//  $scope.data.sign = newValue;
		//});

		$scope._tipDriverData = function () {
			return {
				tip_driver_configuration: {
					tip_amounts: JSON.stringify($scope.data.amounts),
					tip_type: $scope.data.type,
					tip_currency: $scope.data.currency,
					tip_require_signature: $scope.data.requireSignature,
					tip_other_amount_enabled: $scope.data.otherAmountEnabled,
					tip_driver_enabled: $scope.data.tip_driver_enabled
				}
			};
		};

		$scope.handleUpdateClick = function () {
			$scope.saving = true;
			MerchantService.proFeaturesEnabled().then(function (result) {
				if (result) {
					MerchantConfigurationService.setTipDriverConfiguration($scope._tipDriverData())
						.then(function () {
							$scope.saving = false;
							toastr.success('Driver tip settings updated');
						})
						.catch(function (error) {
							$scope.saving = false;
							toastr.error(error);
						});
				} else {
					$scope.saving = false;
					Payments.openUpdateToProWindow();
				}
			});
		};
	});
