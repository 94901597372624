'use strict';
/*global app:false */
/*global io:false */
/*global google:false */

app.factory('bringg_utils', function (MerchantConfigurations, $window, TranslationService) {
	var helper = {
		support: !!($window.FileReader && $window.CanvasRenderingContext2D),
		isFile: function (item) {
			return angular.isObject(item) && item instanceof $window.File;
		},
		isImage: function (file) {
			var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
			return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
		}
	};

	var lightOrDark = function (color) {
		// Variables for red, green, blue values
		var r, g, b, hsp;

		if (!color) {
			color = '#222222';
		}

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {
			// If HEX --> store the red, green, blue values in separate variables
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

			r = color[1];
			g = color[2];
			b = color[3];
		} else {
			// If RGB --> Convert it to HEX: http://gist.github.com/983661
			var HEX_TO_RGB = function (hex) {
				var regExp = new RegExp('.', 'g');
				hex = +('0x' + hex.slice(1).replace(hex.length < 5 && regExp, '$&$&'));
				var r = hex >> 16;
				var g = (hex >> 8) & 255;
				var b = hex & 255;
				return [r, g, b];
			};
			color = HEX_TO_RGB(color);

			r = color[0];
			g = color[1];
			b = color[2];
		}

		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

		// Using the HSP value, determine whether the color is light or dark
		if (hsp > 127.5) {
			return 'light';
		} else {
			return 'dark';
		}
	};

	var getValueFromApplicationMerchantConfiguration = function (key) {
		if (!MerchantConfigurations.application_merchant_configurations) return;
		var relevantConfig = MerchantConfigurations.application_merchant_configurations.find(function (amc) {
			return amc.data && amc.data.hasOwnProperty(key);
		});
		if (!relevantConfig) return;
		return relevantConfig.data[key];
	};

	var getValueFromApplications = function (key) {
		if (!MerchantConfigurations.applications) return;
		var relevantAppConfig = MerchantConfigurations.applications.find(function (app) {
			return app.configuration && app.configuration.hasOwnProperty(key);
		});
		if (!relevantAppConfig) return;
		return relevantAppConfig.configuration[key];
	};

	return {
		getNestedField: function (object, propName) {
			var fields = propName.split('.');
			var subTaskValue = object;

			for (var fieldIdx = 0; fieldIdx < fields.length; fieldIdx++) {
				if (_.isString(fields[fieldIdx]) && fields[fieldIdx].toLowerCase() === 'last') {
					subTaskValue = subTaskValue[subTaskValue.length - 1];
				} else if (_.isString(fields[fieldIdx]) && fields[fieldIdx].toLowerCase() === 'first') {
					subTaskValue = subTaskValue[0];
				} else {
					subTaskValue = subTaskValue[fields[fieldIdx]];
				}
				if (_.isUndefined(subTaskValue) || _.isNull(subTaskValue)) {
					break;
				}
			}

			return subTaskValue;
		},
		getStatusFromTask: function (task) {
			if (_.isEmpty(task) || _.isNil(task.status)) {
				return '';
			}
			switch (parseInt(task.status, 10)) {
				case 0:
					return TranslationService.instant('UTILS.ORDER_STATUS_NOT_ASSIGNED');
				case 1:
					return TranslationService.instant('UTILS.ORDER_STATUS_ASSIGNED');
				case 2:
					return TranslationService.instant('UTILS.ORDER_STATUS_ON_THE_WAY');
				case 3:
					return TranslationService.instant('UTILS.ORDER_STATUS_CHECKED_IN');
				case 4:
					return TranslationService.instant('UTILS.ORDER_STATUS_DONE');
				case 5:
					return TranslationService.instant('UTILS.ORDER_STATUS_LATE');
				case 6:
					return TranslationService.instant('UTILS.ORDER_STATUS_ACCEPTED');
				case 7:
					return TranslationService.instant('UTILS.ORDER_STATUS_CANCELLED');
				case 8:
					return TranslationService.instant('UTILS.ORDER_STATUS_REJECTED');
				case 9:
					return TranslationService.instant('UTILS.ORDER_STATUS_UNACKNOWLEDGED');
				case 10:
					return TranslationService.instant('UTILS.ORDER_STATUS_PENDING');
				default:
					return TranslationService.instant('UTILS.ORDER_STATUS_UNKNOWN');
			}
		},
		// objectWithLocation can be employee, way point, etc.
		center_map: function (map, objectWithLocation) {
			if (_.isNil(map)) {
				return;
			}

			var lat = MerchantConfigurations.lat,
				lng = MerchantConfigurations.lng;

			if (
				!_.isUndefined(objectWithLocation) &&
				objectWithLocation.lat !== undefined &&
				objectWithLocation.lat !== null &&
				objectWithLocation.lat !== 0 &&
				objectWithLocation.lat !== 0
			) {
				lat = objectWithLocation.lat;
				lng = objectWithLocation.lng;
			}

			if (!_.isNull(lat) && !_.isNull(lng)) {
				map.setCenter(new google.maps.LatLng(lat, lng));
			}
		},
		loadFile: function (params, callback) {
			if (!helper.isFile(params._file)) {
				return;
			}
			if (!helper.isImage(params._file)) {
				return;
			}

			var reader = new FileReader();

			reader.onload = callback;
			reader.readAsDataURL(params._file);
		},
		getStars: function (employee) {
			if (_.isUndefined(employee)) {
				return;
			}
			if (_.isUndefined(employee.average_rating) || _.isNull(employee.average_rating)) {
				employee.average_rating = 0;
			}
			var starsArray = [];
			for (var starIndex = 0; starIndex < 5; starIndex++) {
				starsArray.push({ filled: starIndex <= employee.average_rating - 1, id: starIndex });
			}
			return starsArray;
		},
		getAvgRatingForTask: function (task) {
			if (_.isUndefined(task) || _.isUndefined(task.way_points)) {
				return 'unknown';
			}

			var ratingsCounter = 0,
				ratingsTotal = 0;

			task.way_points.forEach(function (wayPoint) {
				if (_.isUndefined(wayPoint.rating) || _.isNull(wayPoint.rating)) {
					return;
				}
				ratingsCounter++;
				ratingsTotal += wayPoint.rating.rating;
			});

			return ratingsTotal / ratingsCounter;
		},
		getActiveTeam: function (task) {
			if (_.isEmpty(task.team_ids)) {
				return 0;
			} else {
				return _.last(task.team_ids);
			}
		},
		imageFilter: function () {
			return {
				name: 'imageFilter',
				fn: function (item) {
					var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
					return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
				}
			};
		},
		sizeFilter: function (size) {
			return {
				name: 'sizeFilter',
				fn: function (item) {
					return item.size < size;
				}
			};
		},
		guid: function () {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}

			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		},
		/*
		 * Returns current region encoded in format that we can put in urls
		 */
		encodeRegion: function (region) {
			return _.map(region.split('-'), function (regionPart) {
				return regionPart[0];
			}).join('');
		},
		lightOrDark: lightOrDark,
		naturalSortByKey: function (arr, key) {
			var rx = /(\d+)|(\D+)/g,
				hasNumber = /\d+/;
			arr.sort(function (a, b) {
				if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return;
				a = a[key].toLowerCase().match(rx);
				b = b[key].toLowerCase().match(rx);
				while (a.length && b.length) {
					var a1 = a.shift();
					var b1 = b.shift();
					if (hasNumber.test(a1) || hasNumber.test(b1)) {
						if (!hasNumber.test(a1)) return 1;
						if (!hasNumber.test(b1)) return -1;
						if (a1 != b1) return a1 - b1;
					} else if (a1 != b1) return a1 > b1 ? 1 : -1;
				}
				return a.length - b.length;
			});
			return arr;
		},
		getValueFromApplicationMerchantConfiguration: getValueFromApplicationMerchantConfiguration,

		getValuesFromApplicationMerchantConfiguration: function (keys) {
			var result = {};
			_.forEach(keys, function (key) {
				var value = getValueFromApplicationMerchantConfiguration(key);
				result[key] = value;
			});
			return result;
		},
		getValueFromApplications: getValueFromApplications,
		/*
		 *
		 * This method is replacing a pattern we had in the code:
		 * ```
		 *  arr.length = 0;
		 *  Array.prototype.push.apply(arr, fromData);
		 * ```
		 *
		 * We did this trick to:
		 *   - keep the array reference the same for angular
		 *   - `push.apply` is bit faster than iterating the original array and doing push
		 *
		 * The problem with `push.apply` that with array more than 140k~ items it throws an error "Maximum call size exceeded",
		 * to solve this issue and get good performance, we will do `push.apply` with chunks.
		 *
		 * Benchmarks:
		 * ```
		 *   pushApply 5k x 73,212 ops/sec ±0.24% (99 runs sampled)
		 *   pushApply 10k x 36,569 ops/sec ±0.74% (99 runs sampled)
		 *   pushApply 100k x 807 ops/sec ±0.38% (92 runs sampled)
		 *   pushApplyChunked 5k x 66,754 ops/sec ±0.30% (95 runs sampled)
		 *   pushApplyChunked 10k x 33,436 ops/sec ±0.44% (97 runs sampled)
		 *   pushApplyChunked 100k x 1,010 ops/sec ±0.56% (96 runs sampled)
		 *   pushApplyChunked 150k x 687 ops/sec ±0.23% (87 runs sampled)
		 *   pushMany 5k x 35,457 ops/sec ±4.13% (95 runs sampled)
		 *   pushMany 10k x 20,507 ops/sec ±0.18% (94 runs sampled)
		 *   pushMany 100k x 657 ops/sec ±0.16% (95 runs sampled)
		 *   pushMany 150k x 643 ops/sec ±4.90% (95 runs sampled)
		 * ```
		 *
		 * (pushApply - just calling push.apply, pushApplyChunked - this function with chunk size of 10k items, pushMany - iterating and push for every item)
		 *
		 */
		setArrayItems: function setArrayItems(to, from) {
			const chunk = 10 * 1000;

			let index = 0;
			let length = from.length;
			to.length = 0;
			while (index < length) {
				to.push.apply(to, from.slice(index, (index += chunk)));
			}

			return to;
		}
	};
});
