/**
 * Created by asaf david on 4/20/16.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('UserTypeController', function (
		$scope,
		UserType,
		UserTypes,
		$routeParams,
		toastr,
		$q,
		$translate,
		Authentication
	) {
		$scope.loading = true;
		$scope.notFound = false;
		$scope.enableAdditionalDriverCostFields = Authentication.currentUser().feature_flags.enable_additional_driver_cost_fields;
		$scope.updateUserType = function () {
			UserTypes.update(this.userType)
				.then(function () {
					toastr.success('user role was updated!');
					$scope.editMode = false;
				})
				.catch(function (error) {
					toastr.error(error.details, 'Failed updating user role');
				});
		};

		$scope.userTypeSubmit = function () {
			if ($routeParams.id) {
				this.updateUserType();
			}
		};

		$scope._renderUserType = function (data) {
			if (data) {
				$scope._originalUserType = data;
				$scope.userType = angular.copy(data);
			} else {
				$scope.loading = false;
				$scope.notFound = true;
			}
		};

		$scope._getDrivers = function (data) {
			$scope.drivers = data;
		};

		if ($routeParams.id) {
			$q.all([
				UserType.get($routeParams.id).then($scope._renderUserType),
				UserType.getDrivers($routeParams.id).then($scope._getDrivers)
			])
				.then(function () {
					$scope.loading = false;
				})
				.catch(function () {
					$scope.loading = false;
					$scope.notFound = true;
				});
		}

		$scope.headers = [
			['id', $translate.instant('USER_TYPE.ID')],
			['name', $translate.instant('USER_TYPE.NAME')],
			['phone', $translate.instant('USER_TYPE.PHONE')],
			['description', $translate.instant('USER_TYPE.DESCRIPTION')]
		];

		$scope.editMode = false;
		$scope.handleEditUserTypeClick = function () {
			$scope.editMode = true;
		};

		$scope.handleCancelClick = function () {
			$scope.editMode = false;
			$scope._renderUserType(angular.copy($scope._originalUserType));
		};
	});
