'use strict';

angular.module('bringgApp').directive('kfc3inchCashOutTemplate', function ($translate, $timeout) {
	return {
		restrict: 'E',
		scope: {
			data: '=',
			employee: '=',
			config: '=',
			totals: '=',
			footers: '=',
			date: '=',
			printCallback: '='
		},
		link: function (scope, element) {
			var employeeTeam = _.first(scope.employee.teams);
			scope.teamName = employeeTeam && employeeTeam.name;

			scope.priceSummary = {
				total: _.chain(scope.data)
					.map('total_price')
					.reduce(function (total, price) {
						return total + price;
					})
					.valueOf(),
				entities: _.chain(scope.data)
					.groupBy('payment_type_name')
					.map(function (tasks, paymentTypeName) {
						return {
							paymentTypeName: paymentTypeName,
							price: _.reduce(
								tasks,
								function (total, task) {
									return total + task.total_price;
								},
								0
							)
						};
					})
					.valueOf()
			};

			scope.ordersSummary = {
				total: scope.data.length,
				entities: _.countBy(scope.data, 'payment_type_name')
			};

			scope.normalizeTypeName = function (name) {
				return name == 'null' ? 'N/A' : name;
			};

			$timeout(scope.printCallback, 100, true, element);
		},
		templateUrl: 'scripts/features/print/cash_out/kfc-3inch-cash-out-template.html'
	};
});
