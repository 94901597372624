'use strict';

angular
	.module('bringgApp')
	.controller(
		'CustomerAppConfigurationController',
		function (
			$scope,
			CustomerConfiguration,
			Authentication,
			FileUploader,
			WEB_API_URL,
			localStorageService,
			CustomerSpecialFeature,
			toastr,
			MerchantConfigurationService,
			Payments,
			DriverPinGalleryService,
			MerchantConfigurations,
			$rootScope,
			$log,
			MerchantService,
			bringg_utils,
			IMAGE_SIZE,
			Application,
			CustomerExperienceConfig,
			$translate,
			CrossApplicationService,
			CROSS_APP_ACTIONS
		) {
			$scope.isNewCXEnabled = false;
			$scope.newCXConfig = {};

			var specialFeaturesLoaded = false;
			var configLoaded = false;
			//==============================================
			// Get Data
			//==============================================
			CustomerSpecialFeature.all(function (customerSpecialFeatures) {
				$scope.customerSpecialFeatures = customerSpecialFeatures;
				specialFeaturesLoaded = true;
				$scope._setUpSpecialFeatures();
			});

			$scope.normalizeConfiguration = function (imagesConfiguration, merchantConfiguration) {
				return _.extend({}, merchantConfiguration, {
					logo: imagesConfiguration.customerAppLogo,
					destination_pin: imagesConfiguration.clientLocationPin,
					delivery_pin: imagesConfiguration.driverLocationPin
				});
			};
			$scope.setNewCXConfig = function () {
				if (!$scope.isNewCXEnabled) {
					return;
				}
				var normalizedConfig = $scope.normalizeConfiguration(
					$scope.images_merchantConfiguration,
					$scope.merchantConfiguration
				);
				$scope.newCXConfig = CustomerExperienceConfig.getCXConfig(
					normalizedConfig,
					$scope.customerExperienceWebAppConfig.disable_cx_color_manipulation
				);
				$scope.newCXConfig.showDeliveryStatus = $scope.customerConfiguration.show_delivery_status;
			};

			const setCustomerConfiguration = data => {
				$scope.customerConfiguration = data;

				configLoaded = true;
				$scope._setUpSpecialFeatures();
				$scope._createUploaders();
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (isLoggedIn) {
					Promise.all([
						MerchantConfigurationService.get(),
						Application.isInstalledByUuid(Application.APPLICATIONS.CustomerExperienceWebApp),
						Application.getConfigByAppUuid(Application.APPLICATIONS.CustomerExperienceWebApp).catch(_.noop),
						CustomerConfiguration.get().$promise
					]).then(function (result) {
						$scope.customerExperienceWebAppConfig = _.defaults(_.get(result[2], 'data'), {
							vehicle_details_form_configuration: {
								show_parking_spot: true,
								show_license_plate: true,
								show_car_model: true,
								show_car_color: true
							},
							disable_cx_color_manipulation: false
						});
						$scope.merchantConfiguration = result[0];
						$scope.images_merchantConfiguration = {
							customerAppLogo: $scope.merchantConfiguration.logo,
							emailLogo: $scope.merchantConfiguration.email_logo,
							driverLocationPin: $scope.merchantConfiguration.delivery_pin,
							clientLocationPin: $scope.merchantConfiguration.destination_pin
						};
						setCustomerConfiguration(result[3]);

						$scope.isNewCXEnabled = result[1];
						$scope.setNewCXConfig();
					});
				}
			});

			//==============================================
			// Handle user interaction
			//==============================================
			$scope.handleSelectPinClick = function () {
				MerchantService.proFeaturesEnabled().then(function (result) {
					if (result) {
						DriverPinGalleryService.open($scope.images_merchantConfiguration.driverLocationPin).then(
							function (result) {
								MerchantConfigurations.alternate_delivery_pin = result;
								MerchantConfigurationService.deliveryPinChange(result).then(
									function (alternate_delivery_pin) {
										//This one is emited in order to notify the merchantConfigurationController
										$rootScope.$emit('alternateDeliveryPinUpdated', alternate_delivery_pin);
										$scope.images_merchantConfiguration.driverLocationPin = alternate_delivery_pin;
									},
									function (reason) {
										$log.error(reason);
									}
								);
							}
						);
					} else {
						Payments.openUpdateToProWindow();
					}
				});
			};

			$scope.saveVehicleDetailsConfiguration = function () {
				var appId = _.find(MerchantConfigurations.applications, {
					uuid: Application.APPLICATIONS.CustomerExperienceWebApp
				}).id;

				if (
					_.every(
						$scope.customerExperienceWebAppConfig.vehicle_details_form_configuration,
						function (fieldValue) {
							return !fieldValue;
						}
					)
				) {
					toastr.error($translate.instant('CUSTOMER_APP.ONE_MUST_BE_SELECTED'));
					return;
				}

				Application.updateConfig(appId, $scope.customerExperienceWebAppConfig)
					.then(() => {
						toastr.success($translate.instant('CUSTOMER_APP.UPDATED_SUCCESSFULLY'));
					})
					.catch(() => {
						toastr.error($translate.instant('CUSTOMER_APP.FAILED_UPDATING_SETTINGS'));
					});
			};

			$scope.handleSaveClick = function () {
				MerchantService.proFeaturesEnabled().then(function (result) {
					if (result) {
						MerchantConfigurationService.update($scope.merchantConfiguration)
							.then(function () {
								CustomerConfiguration.update($scope.customerConfiguration, function (response) {
									if (response.success) {
										toastr.success('Update saved, thank you');
										$scope.customerConfiguration = response.customer_configration;
										MerchantConfigurations.$refresh();
										CrossApplicationService.emit(
											CROSS_APP_ACTIONS.CUSTOMER_APP_CONFIGURATION_UPDATED
										);
									} else {
										toastr.error('Failed updating settings.');
									}
								});
							})
							.catch(function () {
								toastr.error('Failed updating settings.');
							});
					} else {
						Payments.openUpdateToProWindow();
					}
				});
			};

			$scope.handleSaveSpecialFeaturesClick = function () {
				MerchantService.proFeaturesEnabled().then(function (result) {
					if (result) {
						$scope.customerConfiguration.special_features = [];
						$scope.customerSpecialFeatures.forEach(function (customerSpecialFeature) {
							$scope.customerConfiguration.special_features.push({
								id: customerSpecialFeature.id,
								approved: customerSpecialFeature.approved
							});
						});

						var customerConfiguration = new CustomerConfiguration($scope.customerConfiguration);
						customerConfiguration.$update(
							function (result) {
								toastr.success('Update saved, thank you');
							},
							function (reason) {
								toastr.error('Failed updating settings.');
							}
						);
					} else {
						Payments.openUpdateToProWindow();
					}
				});
			};

			$scope.handleResetPinClick = function (event, target) {
				MerchantService.proFeaturesEnabled().then(function (result) {
					if (result) {
						if (target === 'driverLocation') {
							MerchantConfigurationService.resetDeliveryPin().then(function (url) {
								$scope.images_merchantConfiguration.driverLocationPin = url;
							});
						} else if (target === 'clientLocation') {
							MerchantConfigurationService.resetDestinationPin().then(function (url) {
								$scope.images_merchantConfiguration.clientLocationPin = url;
							});
						} else if (target === 'customerLogo') {
							MerchantConfigurationService.resetClientAppLogo().then(function (url) {
								$scope.images_merchantConfiguration.customerAppLogo = url;
							});
						} else if (target === 'emailLogo') {
							MerchantConfigurationService.resetEmailLogo().then(function (url) {
								$scope.images_merchantConfiguration.emailLogo = url;
							});
						}
					} else {
						Payments.openUpdateToProWindow();
					}
				});
			};
			//==============================================
			// Private stuff
			//==============================================
			$scope._setUpSpecialFeatures = function () {
				if (specialFeaturesLoaded && configLoaded) {
					if ($scope.customerSpecialFeatures && $scope.customerConfiguration.customer_special_features) {
						$scope.customerSpecialFeatures.forEach(function (customerSpecialFeature) {
							$scope.customerConfiguration.customer_special_features.forEach(function (special_feature) {
								if (special_feature.id === customerSpecialFeature.id) {
									customerSpecialFeature.approved = true;
								}
							});
						});
					}
				}
			};

			//==============================================
			// Image uploads
			//==============================================
			$scope._createUploaders = function () {
				$scope.customer_app_image_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				});
				$scope.email_logo_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				});

				$scope.client_location_pin_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				});

				$scope.driver_location_pin_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				});

				$scope.customer_app_image_uploader.filters.push(bringg_utils.imageFilter());
				$scope.email_logo_uploader.filters.push(bringg_utils.imageFilter());
				$scope.client_location_pin_uploader.filters.push(bringg_utils.imageFilter());
				$scope.driver_location_pin_uploader.filters.push(bringg_utils.imageFilter());

				$scope.customer_app_image_uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.MERCHANT_LOGO));
				$scope.email_logo_uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.MERCHANT_LOGO));
				$scope.client_location_pin_uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.CUSTOMER));
				$scope.driver_location_pin_uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.CUSTOMER));

				function afterAddingFileFn(itemId, uploader) {
					return function (addedFile) {
						uploader.queue[uploader.queue.length - 1].url =
							WEB_API_URL + '/merchant/merchant_configuration/' + itemId + '?' + new Date().getTime();
						uploader.uploadAll();
					};
				}

				function afterUploadingFileFn(itemId) {
					return function (item, response, status, headers) {
						if (status === 200 && response.success) {
							$scope.images_merchantConfiguration[itemId] = response.url + '?' + new Date().getTime();
							toastr.success('Image was added successfully');
							MerchantConfigurations.$refresh();
							$scope.setNewCXConfig();
						}
					};
				}

				function onWhenAddingFileFailed(itemId) {
					return function (item, filter, options) {
						if (filter.name === 'imageFilter') {
							toastr.error('Only image files please. SUPPORTED: jpg, jpeg, png, bmp, gif');
						} else if (filter.name === 'sizeFilter') {
							toastr.error(`Your image is too big please provide a different one. MAX SIZE: 1 MB`);
						}
					};
				}

				$scope.customer_app_image_uploader.onAfterAddingFile = afterAddingFileFn(
					'logo',
					$scope.customer_app_image_uploader
				);
				$scope.customer_app_image_uploader.onWhenAddingFileFailed = onWhenAddingFileFailed('logo');
				$scope.customer_app_image_uploader.onSuccessItem = afterUploadingFileFn('customerAppLogo');

				$scope.email_logo_uploader.onAfterAddingFile = afterAddingFileFn(
					'email_logo',
					$scope.email_logo_uploader
				);
				$scope.email_logo_uploader.onWhenAddingFileFailed = onWhenAddingFileFailed('email_logo');
				$scope.email_logo_uploader.onSuccessItem = afterUploadingFileFn('emailLogo');

				$scope.client_location_pin_uploader.onAfterAddingFile = afterAddingFileFn(
					'destination_pin',
					$scope.client_location_pin_uploader
				);
				$scope.client_location_pin_uploader.onWhenAddingFileFailed = onWhenAddingFileFailed('destination_pin');
				$scope.client_location_pin_uploader.onSuccessItem = afterUploadingFileFn('clientLocationPin');

				$scope.driver_location_pin_uploader.onAfterAddingFile = afterAddingFileFn(
					'delivery_pin',
					$scope.driver_location_pin_uploader
				);
				$scope.driver_location_pin_uploader.onWhenAddingFileFailed = onWhenAddingFileFailed('delivery_pin');
				$scope.driver_location_pin_uploader.onSuccessItem = afterUploadingFileFn('driverLocationPin');
			};

			$scope.cancelCXEventTypeValues = [
				{ value: 0, label: $translate.instant('CX_CUSTOMER_CANCELLATION_UNTIL_ASSIGNED') },
				{ value: 1, label: $translate.instant('CX_CUSTOMER_CANCELLATION_UNTIL_START') }
			];

			$scope.handleUploadButtonClick = function (event, target) {
				MerchantService.proFeaturesEnabled().then(function (result) {
					if (result) {
						angular.element('#' + target).trigger('click');
					} else {
						Payments.openUpdateToProWindow();
					}
				});
			};
		}
	);
