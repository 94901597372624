'use strict';

angular
	.module('bringgApp.filters')
	.filter('firstName', function () {
		return function (str) {
			if (str) {
				return str.split(' ')[0];
			}
			return '';
		};
	})
	.filter('arrayToString', function () {
		return function (arr) {
			if (arr) {
				return arr
					.map(function (elem) {
						return elem;
					})
					.join(',');
			}
			return '';
		};
	})
	.filter('customer_phone_or_email', function () {
		return function (customer) {
			if (customer) {
				return customer.phone ? customer.phone : customer.email;
			}
			return '';
		};
	})
	.filter('currencyStringToSign', function () {
		return function (str) {
			switch (str) {
				case 'AUD':
					return '$';
				case 'BRL':
					return '$';
				case 'CAD':
					return '$';
				case 'CZK':
					return 'Kč';
				case 'DKK':
					return 'kr';
				case 'EUR':
					return '€';
				case 'HKD':
					return '$';
				case 'HUF':
					return 'Ft';
				case 'ILS':
					return '₪';
				case 'JPY':
					return '¥';
				case 'MYR':
					return 'RM';
				case 'MXN':
					return '$';
				case 'NOK':
					return 'kr';
				case 'NZD':
					return '$';
				case 'PHP':
					return '₱';
				case 'PLN':
					return 'zł';
				case 'GBP':
					return '£';
				case 'SGD':
					return '$';
				case 'SEK':
					return 'kr';
				case 'CHF':
					return 'CHF';
				case 'TWD':
					return '$';
				case 'THB':
					return '฿';
				case 'TRY':
					return '₺';
				case 'USD':
					return '$';
				case 'CLP':
					return '$';
			}
		};
	})
	.filter('googleGeolocationStringFilter', function () {
		var result = '';
		return function (obj) {
			switch (obj) {
				case 'ZERO_RESULTS':
					result = 'Address not found';
					break;
				case 'OVER_QUERY_LIMIT':
					result = 'Address not found';
					break;
				case 'REQUEST_DENIED':
					result = 'Address not found';
					break;
				case 'INVALID_REQUEST':
					result = 'Address not found';
					break;
				case 'UNKNOWN_ERROR':
					result = 'Unknown Error contact support.';
					break;
				case 'ERROR':
					result = 'Error: check internet connection.';
					break;
				default:
					result = 'Searching';
					break;
			}
			return result;
		};
	})
	.filter('billingChangeHistoryStringFilter', function () {
		var result = '';
		return function (obj) {
			switch (parseInt(obj.change_type)) {
				case 0:
					result = 'Unknown';
					break;
				case 1:
					result = 'New Driver was added';
					break;
				case 2:
					result = 'A Driver was removed';
					break;
				case 3:
					result = 'User was updated';
					break;
				case 4:
					result = 'Changed subscription type (Plan)';
					break;
				case 5:
					result = 'Added new credit card';
					break;
				case 6:
					result = 'Removed credit card';
					break;
				case 7:
					result = 'Default credit card changed';
					break;
				case 8:
					result = 'Subscription created';
					break;
				case 9:
					result = 'Coupon Redeemed';
					break;
				case 10:
					result = 'Account Deactivated';
					break;
				case 11:
					result = 'Invoice Paid Manually';
					break;
				case 12:
					result = 'Email Recipient Changed';
					break;
				case 13:
					if (obj.metered_price < 300) {
						//temp hack for old payments
						result = 'Metered price changed to ¢' + parseInt(obj.metered_price);
					} else {
						result = 'Metered price changed to $' + parseInt(obj.metered_price);
					}
					break;
				default:
					result = 'Unknown';
					break;
			}

			if (obj.admin_id && obj.admin_name) {
				result += ' - by ' + obj.admin_name;
			}

			return result;
		};
	});
