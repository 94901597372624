'use strict';

angular
	.module('bringgApp')
	.controller('oAuthController', function ($rootScope, $scope, $routeParams, WEB_API_URL, Authentication) {
		$scope.url = WEB_API_URL;
		$scope.client_id = $routeParams.client_id;
		$scope.redirect_uri = $routeParams.redirect_uri;
		$scope.response_type = $routeParams.response_type;
		$scope.auth_token = Authentication.currentUser().authentication_token;
	});
