'use strict';

angular.module('bringgApp').factory('TranslationService', function ($translate) {
	var instant = _.memoize($translate.instant);

	return {
		instant: instant,
		clearCache: function () {
			instant.cache.clear();
		}
	};
});
