angular
	.module('bringgApp')
	.factory('ReportsService2', function ($log, toastr, $location, reportsSerivce, $rootScope, SocketPubSub) {
		var ReportsService2 = {};

		ReportsService2.updateShortCode = function (shortCode) {
			$location.search('short_code', shortCode);
		};

		/**
		 * generate report
		 * @param report
		 * @param usersIds
		 * @param teamsIds
		 * @param dateRange
		 * @param shortCode
		 * @param selectDateLabel
		 * @param selectTeams
		 */
		ReportsService2.generateReport = function (
			report,
			usersIds,
			teamsIds,
			dateRange,
			shortCode,
			selectDateLabel,
			selectTeams
		) {
			var scheduled_at = [moment(dateRange.startDate).format(), moment(dateRange.endDate).format()].join(' - ');
			var selectDateLabelIndex = selectDateLabel || '';
			var reports = report ? [report] : [];
			var allTeams = false;
			var allEmployees = false;

			// support for old style api
			reports = _.map(reports, function (report) {
				return {
					id: report.id
				};
			});

			// init report from short code
			if (!shortCode) {
				this.updateShortCode(null);
			}

			// if we use teams, drop users and vice versa
			if (selectTeams) {
				usersIds = [];
				if (_.isEmpty(teamsIds)) {
					allTeams = true;
				}
			} else {
				teamsIds = null;
				if (_.isEmpty(usersIds)) {
					allEmployees = true;
				}
			}

			// this one used in $scope.$on('report ready')
			// var reportReady = false;
			// var reportInProcess = true; // this one used in error too $scope.$on('report error')
			var options = {
				reportsList: reports,
				timePeriod: scheduled_at,
				employee_ids: _.map(usersIds, function (userId) {
					return Number(userId);
				}),
				allEmployees: allEmployees,
				allTeams: allTeams,
				team_ids: _.map(teamsIds, function (teamId) {
					return Number(teamId);
				}),
				tags: null,
				dateRange: selectDateLabelIndex.toString(),
				shortCode: shortCode
			};

			reportsSerivce
				.generateReport(options)
				.success(function (response) {
					if (!response.success) {
						$log.error(response);
						return;
					}

					toastr.success('Report generated successfully');
				})
				.error(function (err) {
					$log.error(err);
					toastr.error(err);
				});
		};

		return ReportsService2;
	});
