'use strict';

angular
	.module('bringgApp')
	.factory('UserType', function ($resource, WEB_API_URL, BringgSDK, Employee) {
		function userTypeService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.userTypes[method].apply(bringg.userTypes, args);
				});
			}.bind(this);
		}

		var UserType = {};

		UserType.getAll = userTypeService('getAll');
		UserType.create = userTypeService('create');
		UserType.update = userTypeService('update');
		UserType.get = userTypeService('get');
		UserType.getDrivers = Employee.byUserType;

		UserType.getUserTypeById = function (userTypes, userTypeId) {
			return userTypes.find(function (type) {
				return type.id === userTypeId;
			});
		};

		return UserType;
	})
	.factory(
		'UserTypes',
		function ($rootScope, UserType, MerchantConfigurations, $q, $log, BringgSDK, TranslationService) {
			var defaultUserTypeChangedListeners = [];
			var UserTypes = {
				userTypes: {
					user_types: [],
					count: 0
				},
				emptyUserType: { id: 0, title: TranslationService.instant('DRIVERS.NO_ACTIVE_ROLE') },
				defaultUserType: null
			};

			$rootScope.$on('loggedout', function () {
				UserTypes.reset();
			});

			UserTypes.reset = function () {
				UserTypes.userTypes = {
					user_types: [],
					count: 0
				};
				UserTypes.defaultUserType = null;
				$log.info('user type reset successfully');
			};

			UserTypes.getAllAsync = function () {
				return UserTypes._getAll();
			};

			var userTypesPromise = null;
			UserTypes._getAll = function () {
				if (MerchantConfigurations.enable_user_types) {
					if (userTypesPromise === null) {
						userTypesPromise = UserType.getAll().then(function (result) {
							// Reset the cached the promise, so we can allow refresh of user types
							// since we don't have realtime
							userTypesPromise = null;

							UserTypes.userTypes = result;
							UserTypes.userTypes.user_types.unshift(UserTypes.emptyUserType);

							if (result.count > 0) {
								UserTypes.defaultUserType = _.find(UserTypes.userTypes.user_types, {
									default_for_merchant: true
								});
							}

							return UserTypes.userTypes;
						});
					}

					return userTypesPromise;
				} else {
					return $q.resolve(UserTypes.userTypes);
				}
			};

			UserTypes._initUserTypes = function () {
				if (MerchantConfigurations.enable_user_types) {
					return UserTypes.getAllAsync();
				}
			};

			$rootScope.$on('config updated', UserTypes._initUserTypes);
			BringgSDK.getInstancePromise().then(function () {
				UserTypes._initUserTypes();
			});

			UserTypes.update = function (userTypeData) {
				var defaultForMerchant = userTypeData.default_for_merchant;
				return UserType.update(userTypeData.id, userTypeData).then(function (result) {
					if (result) {
						if (defaultForMerchant) {
							UserTypes._replaceDefaultUserType(result);
						}
						UserTypes._updateUserType(result);
					}
				});
			};

			UserTypes._updateUserType = function (userType) {
				var idx = _.findIndex(UserTypes.userTypes.user_types, { id: userType.id });
				UserTypes.userTypes.user_types[idx] = userType;
			};

			UserTypes._replaceDefaultUserType = function (defaultUserType) {
				if (UserTypes.defaultUserType) {
					UserTypes.defaultUserType = null;
				}
				UserTypes.defaultUserType = defaultUserType;
				_.each(defaultUserTypeChangedListeners, function (listener) {
					listener(defaultUserType);
				});
			};

			UserTypes.onDefaultUserTypeChanged = function (listener) {
				if (_.isFunction(listener) && !_.includes(defaultUserTypeChangedListeners, listener)) {
					defaultUserTypeChangedListeners.push(listener);
				}
			};

			UserTypes.get = function (id) {
				var userType = _.find(UserTypes.userTypes.user_types, { id: id });
				if (!userType) {
					return UserType.get(id).then(function (userType) {
						UserTypes.userTypes.user_types.push(userType);
						UserTypes.userTypes.count++;
						return userType;
					});
				}

				return userType;
			};

			UserTypes.byIds = function (userTypeIds) {
				// If we don't have allowed user types ids, quit
				if (!_.isArray(userTypeIds) || _.isEmpty(userTypeIds)) {
					return $q.resolve([]);
				}

				// Make sure we fetched all, or wait to fetch all
				return UserTypes.getAllAsync().then(function () {
					return _.map(userTypeIds, function (allowedUserTypeId) {
						return _.find(UserTypes.userTypes.user_types, { id: allowedUserTypeId });
					});
				});
			};

			UserTypes.byUser = function (employee) {
				var driverHasNoUserType = _.isNull(employee.user_type_id);

				return UserTypes.getAllAsync().then(function () {
					var userTypesByUser = _.find(UserTypes.userTypes.user_types, { id: employee.user_type_id });
					var userType = driverHasNoUserType ? UserTypes.defaultUserType : userTypesByUser;

					return $q.resolve(userType);
				});
			};

			UserTypes.all = function () {
				var cb = getSuccessCallback(arguments),
					page,
					query;

				if (arguments[0]) {
					page = arguments[0].page;
					query = arguments[0].query;
				}

				if (UserTypes.userTypes.count > 0) {
					if (cb) {
						cb(UserTypes.userTypes);
					}
					return $q.resolve(UserTypes.userTypes);
				} else {
					return UserTypes.getAllAsync().then(function (userTypes) {
						if (cb) {
							cb(userTypes);
						}

						return $q.resolve(userTypes);
					});
				}
			};

			return UserTypes;
		}
	);
