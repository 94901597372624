'use strict';

angular
	.module('bringgApp.directives')
	.filter('numberToHoursFilter', function () {
		return function (number) {
			return number + ':00';
		};
	})
	.filter('hoursFilter', function () {
		return function (hours, min, max) {
			return _.filter(hours, function (hour) {
				var result = true;

				if (!_.isUndefined(min)) {
					result = hour.value >= min;
				}

				if (!_.isUndefined(max)) {
					result = result && hour.value <= max;
				}

				return result;
			});
		};
	})
	.directive('dayShifts', function ($filter) {
		return {
			templateUrl: 'scripts/directives/shifts/day-shifts.html',
			require: 'ngModel',
			scope: {
				ngModel: '=',
				isDisabled: '='
			},
			link: function (scope, elem, attr, ngModel) {
				var initUIfromModel = function () {
					initDefaultShiftsValues();

					// fix model shifts to get only first two even if their empty
					if (scope.ngModel.shifts.length == 0) {
						scope.ngModel.shifts.push(scope.firstShift);
						scope.ngModel.shifts.push(scope.secondShift);
					} else if (scope.ngModel.shifts.length == 1) {
						scope.ngModel.shifts.push(scope.secondShift);
					} else {
						// remove the rest
						scope.ngModel.shifts = _.take(scope.ngModel.shifts, 2);
					}
					scope.firstShift = scope.ngModel.shifts[0];
					scope.secondShift = scope.ngModel.shifts[1];

					// assign same refs
					if (scope.ngModel.lunchBreak) {
						scope.lunchBreak = scope.ngModel.lunchBreak;
					} else {
						scope.ngModel.lunchBreak = scope.lunchBreak;
					}

					scope.firstShift.enabled =
						_.isNumber(scope.firstShift.startHour) && _.isNumber(scope.firstShift.endHour);
					scope.secondShift.enabled =
						_.isNumber(scope.secondShift.startHour) && _.isNumber(scope.secondShift.endHour);
					scope.lunchBreak.enabled =
						_.isNumber(scope.lunchBreak.startHour) && _.isNumber(scope.lunchBreak.endHour);
				};

				/**
				 * init default shifts values
				 */
				var initDefaultShiftsValues = function () {
					scope.firstShift = {
						enabled: false,
						startHour: null,
						endHour: null
					};

					scope.secondShift = {
						enabled: false,
						startHour: null,
						endHour: null
					};

					scope.lunchBreak = {
						enabled: false,
						startHour: null,
						endHour: null
					};
				};

				/**
				 * init function
				 */
				var init = function () {
					initDefaultShiftsValues();

					scope.hours = _.map(_.range(25), function (hour) {
						return {
							value: hour,
							display: $filter('numberToHoursFilter')(hour)
						};
					});

					scope.$watch('ngModel', function (ngModel) {
						if (!ngModel) {
							return;
						}

						initUIfromModel();
					});
				};

				init();
			}
		};
	});
