'use strict';

angular
	.module('bringgApp')
	.controller(
		'OptimizationPreviewController',
		function (
			$scope,
			optimizationData,
			type,
			isManual,
			$uibModalInstance,
			team,
			tasks,
			plannedRoutes,
			runIdToBreak,
			optimizationDate,
			serviceAreasById,
			MerchantConfigurations,
			DriverConfigurations,
			$q,
			UserTypes,
			Employees,
			OptimizationPreviewService,
			Application,
			VehiclesService,
			VehicleTypesService
		) {
			var EtaResponseType = {
				RouteInfo: 'route_info',
				Task: 'task',
				UnassignedTask: 'unassigned_task',
				ActualBreak: 'actual_break',
				PreassignedBreak: 'preassigned_break',
				Directions: 'directions'
			};

			$scope.merchantConfigurations = MerchantConfigurations;
			$scope.routesPlannerEnable = MerchantConfigurations.applications.some(Application.isRouteOptimizer2);
			$scope.sharedData = OptimizationPreviewService.sharedData;
			$scope.optimizationData = optimizationData;
			$scope.routeInfoByIndex = optimizationData
				? _.keyBy(
						optimizationData.filter(function (row) {
							return row.type === EtaResponseType.RouteInfo;
						}),
						'route_idx'
				  )
				: {};

			$scope.previewMapOptimizationType = !$scope.routesPlannerEnable && isManual ? 'custom_tasks' : type;

			$scope.type = type;
			$scope.isManual = isManual;
			$scope.team = team;
			$scope.loading = true;
			$scope.plannedRoutes = plannedRoutes;
			$scope.serviceAreasById = serviceAreasById;
			$scope.runIdToBreak = runIdToBreak;
			$scope.optimizationDate = optimizationDate;

			var optimizationResponseByTeam = _.find(
				OptimizationPreviewService.optimizationResponses,
				function (response) {
					return response.team_id === $scope.team.id;
				}
			);

			$scope.optimizationRequestId =
				optimizationResponseByTeam && !isManual ? optimizationResponseByTeam.request_uuid : '';

			$scope._init = function () {
				var tasksPromise;
				tasksPromise = $q.resolve(tasks);
				$q.all([
					tasksPromise,
					UserTypes.getAllAsync(),
					Employees.drivers(),
					DriverConfigurations.isClusterModeEnabled(),
					VehiclesService.getAll(),
					VehicleTypesService.getAll()
				]).then(function (results) {
					$scope.tasks = angular.copy(results[0].filter(task => task.team_id === team.id));
					$scope.userTypes = !_.isUndefined(results[1]) ? results[1].user_types : [];
					$scope.users = results[2];
					$scope.loading = false;
				});
			};

			$scope._init();

			$scope.handleApplyClick = function (routesPlannerChanges) {
				var data = $scope.routesPlannerEnable
					? routesPlannerChanges
					: OptimizationPreviewService.exportData(
							$scope.sharedData.data,
							$scope.previewMapOptimizationType,
							$scope.routeInfoByIndex
					  );
				$uibModalInstance.close(data);
			};

			$scope.handleCancelClick = function () {
				$uibModalInstance.dismiss();
			};
		}
	);
