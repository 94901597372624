'use strict';

angular
	.module('bringgApp')
	.constant('INTERCEPTABLE_ACTIONS', {
		TASK_ASSIGMENT: 0,
		TASK_CANCELATION: 1,
		EMPLOYEE_CASH_OUT: 2
	})
	.factory('ActionInterceptorService', function ($q) {
		var ActionInterceptorService = {};

		ActionInterceptorService._interceptorsByAction = {};

		ActionInterceptorService.interceptOn = function (action, interceptor) {
			if (!_.has(ActionInterceptorService._interceptorsByAction, action)) {
				ActionInterceptorService._interceptorsByAction[action] = [];
			}

			ActionInterceptorService._interceptorsByAction[action].push(interceptor);
		};

		ActionInterceptorService.run = function (action, data) {
			var interceptors = ActionInterceptorService._interceptorsByAction[action] || [];

			var promise = $q.resolve();

			_.each(interceptors, function (interceptor) {
				promise = promise.then(function (value) {
					return interceptor(action, data);
				});
			});

			return promise;
		};

		return ActionInterceptorService;
	});
