'use strict';

angular.module('bringgApp').factory('Support', function ($resource, WEB_API_URL) {
	var Support = $resource(
		WEB_API_URL + '/support/:id',
		{ id: '@id' },
		{
			supportTasks: { method: 'GET', url: WEB_API_URL + '/support/search_tasks' },
			impersonate: { method: 'POST', url: WEB_API_URL + '/support/impersonate' }
		}
	);

	return Support;
});
