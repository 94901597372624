'use strict';

angular
	.module('bringgApp')
	.controller('BroadcastOrderModalController', function (
		$scope,
		$uibModalInstance,
		$translate,
		$q,
		$log,
		Tasks,
		toastr,
		taskIds,
		drivers
	) {
		$scope.drivers = prepareDrivers(drivers);

		$scope.filteredDrivers = $scope.drivers;
		$scope.selectedDrivers = {};

		$scope.filterOptions = [
			{ name: $translate.instant('BROADCAST_TASKS.ALL'), value: 'all' },
			{
				name: $translate.instant('BROADCAST_TASKS.ON_SHIFT_DRIVERS'),
				value: 'onshift',
				filter: function (driver) {
					return _.isNumber(driver.active_shift_id);
				}
			},
			{
				name: $translate.instant('BROADCAST_TASKS.ONLINE_DRIVERS'),
				value: 'online',
				filter: function (driver) {
					return driver.status === 'online';
				}
			}
		];

		$scope.selectedFilter = $scope.filterOptions[0];

		$scope.handleSendBroadcast = function () {
			var driverIds = _.filter(_.keys($scope.selectedDrivers), function (key) {
				return $scope.selectedDrivers[key];
			});

			$uibModalInstance.close();

			var promises = _.map(taskIds, function (taskId) {
				return Tasks.broadcastTask(taskId, driverIds);
			});

			$q.all(promises)
				.then(function () {
					toastr.success($translate.instant('BROADCAST_TASKS.SUCCESS'));
				})
				.catch(function () {
					$log.error('Failed to broadcast tasks for task_ids: ', taskIds);
				});
		};

		$scope.filterUpdate = function (filter) {
			$scope.filteredDrivers = filter ? _.pick($scope.drivers, filter) : $scope.drivers;
			$scope.selectedDrivers = {};
		};

		$scope.closeModal = function () {
			$uibModalInstance.close();
		};

		$scope.isSendEnabled = function () {
			return _.some($scope.selectedDrivers, function (value) {
				return value;
			});
		};

		function prepareDrivers(drivers) {
			return _.chain(drivers)
				.filter(function (driver) {
					return _.isNumber(driver.id);
				})
				.map(function (driver) {
					return {
						id: driver.id,
						img: driver.profile_image,
						name: driver.name || '',
						status: driver.status,
						active_shift_id: driver.active_shift_id
					};
				})
				.value();
		}
	});
