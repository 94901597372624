'use strict';
/*global $:false */
/*global google:false */

angular.module('bringgApp').controller('waypointController', function ($scope, Waypoint, Employee) {
	$scope.waypoint = new Waypoint();

	var geocoder = new google.maps.Geocoder();

	$scope.geocodeWaypointAddress = function (index) {
		$scope.waypoint.lat = null;
		$scope.waypoint.lng = null;
		if (this.waypoint.address === undefined || this.waypoint.address.length === 0) {
			return;
		}
		geocoder.geocode({ address: this.waypoint.address }, function (results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				$scope.$apply(function () {
					$scope.waypoint.lat = results[0].geometry.location.lat();
					$scope.waypoint.lng = results[0].geometry.location.lng();
				});
			} else {
				alert('Failed parsing address location not successful for the following reason: ' + status);
			}
		});
	};
});
