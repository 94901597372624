/*global google:false */
/*global clearMarkers:false */
/*global resizeMapByMarkers:false */

'use strict';

angular
	.module('bringgApp')
	.controller(
		'BigMapAnalysisController',
		function (
			$scope,
			$translate,
			Tasks,
			bringg_utils,
			Employees,
			$timeout,
			SubmitTicketService,
			$templateRequest,
			$compile,
			Task,
			toastr,
			$q,
			$window,
			$routeParams,
			OptimizationProcessDatum,
			Waypoint
		) {
			//props
			$scope.map = null;
			$scope._markers = [];
			$scope._colorsCollection = [
				'C0C0C0',
				'3cb247',
				'e99b6f',
				'd3bf53',
				'35cf70',
				'bc428e',
				'5c7cb9',
				'41a561',
				'd9422f',
				'd83145',
				'41a889',
				'326a38',
				'b1699c',
				'a089e7',
				'6786e2',
				'4883ef',
				'805f27',
				'78aa1f',
				'714d94',
				'a52d68',
				'f08db5'
			];
			$scope._usersWithTasks = {
				null: {
					user: { name: 'unassigned', id: null },
					wayPoints: [],
					color: $scope._colorsCollection[0]
				}
			};
			$scope.selectedDriver = null;
			$scope.loading = true;
			$scope.markersByDriverId = {};
			$scope.optimizationProcessDatum = $routeParams.id;

			$scope.loadOptimizationResults = function () {
				OptimizationProcessDatum.get({ id: $scope.optimizationProcessDatum }, function (results) {
					if (results && results.success) {
						$scope.resultsOptimizationDatum = results.optimization_process_datum;
					} else {
						toastr.error(results.message);
					}
					$scope._loadEmployees();
				});
			};

			$scope._loadEmployees = function () {
				Employees.drivers({}, function (result) {
					$scope.employees = result;
					$scope._buildDataCollection();
					$scope.employees.push({
						id: null,
						name: 'unassigned'
					});
					$scope._renderMap();
					$scope.loading = false;
				});
			};

			$scope.loadOptimizationResults();

			//maps stuff
			$scope._createMap = function () {
				var mapOptions = {
					zoom: 3,
					center: new google.maps.LatLng(20, 0)
				};

				$scope.map = new google.maps.Map(document.getElementById('gmap'), mapOptions);
				$scope.infoWindow = new google.maps.InfoWindow({});
			};

			$scope._addMarker = function (location, color, count, user, task) {
				if (_.isNull(location.lat) || _.isNull(location.lng)) {
					return;
				}
				var marker = new google.maps.Marker({
					position: { lat: location.lat, lng: location.lng },
					map: $scope.map
				});

				marker.set('id', $scope._markers.length);
				marker.set('task', task);
				updateMarkerProperties(marker, user.id, task.priority, count);

				var listenrId = google.maps.event.addListener(marker, 'click', function () {
					wayPointMarkerClicked(marker);
				});
				marker.set('listener_id', listenrId);

				$scope._markers.push(marker);
				$scope.markersByDriverId[user.id].push(marker);
			};

			function updateMarkerProperties(marker, driverId, priority, order) {
				marker.set('driverId', driverId);
				marker.set('priority', priority);
				marker.set('order', order);
			}

			function getMarkerIdFromElementId(elementId, type) {
				return new RegExp('taskMarker' + type + '(\\d+)').exec(elementId)[1];
			}

			if (!$window.driversDropDownOpenedInBigMap) {
				$window.driversDropDownOpenedInBigMap = function (element) {
					var id = getMarkerIdFromElementId(element.id, 'Driver');
					var scope = $scope._markers[id].get('scope');
					scope.isDriversDropDownOpened = !scope.isDriversDropDownOpened;
					$compile(angular.element(element.parentNode).contents())(scope);
				};
			}

			if (!$window.priorityChangedInBigMap) {
				$window.priorityChangedInBigMap = function (element) {
					var id = getMarkerIdFromElementId(element.id, 'Order');
					var oldMarker = $scope._markers[id];
					var driverId = oldMarker.get('driverId');
					var newOrder = Math.min(
						Math.max(Number(element.value), 1),
						$scope._usersWithTasks[driverId].tasks.length
					); // Make sure the new order is valid
					var oldOrder = oldMarker.get('order');
					var oldPriority = oldMarker.get('priority');
					var color = $scope._usersWithTasks[driverId].color;
					var step = (oldOrder - newOrder) / Math.abs(oldOrder - newOrder);
					var updatePrioritiesData = { data: [] };
					var newMarker;

					if (newOrder === oldOrder) {
						return;
					}

					// Reorder all the tasks between oldOrder and newOrder. so if we start with the array: [1,2,3,4,5,6],
					// and want to update task #2 to be with order 5 (instead of 2), we'll do the following steps:
					// [1,2,3,4,5,6]->[1,2,3,4,2,6]->[1,2,3,5,2,6]->[1,2,4,5,2,6]->[1,3,4,5,2,6].
					// If we want to update task #5 to be with order 2, we'll move in the opposite direction:
					// [1,2,3,4,5,6]->[1,5,3,4,5,6]->[1,5,2,4,5,6]->[1,5,2,3,5,6]->[1,5,2,3,4,6].
					for (var i = newOrder - 1; i + 1 !== oldOrder + step; i += step) {
						newMarker = $scope.markersByDriverId[driverId][i];

						oldMarker.set('priority', newMarker.get('priority'));
						oldMarker.set('order', i + 1);
						oldMarker.setIcon(
							'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=' +
								(i + 1) +
								'|' +
								color +
								'|000000'
						);

						updatePrioritiesData.data.push({
							task_id: oldMarker.get('task').id,
							priority: oldMarker.get('priority')
						});

						$scope.markersByDriverId[driverId][i] = oldMarker;
						oldMarker = newMarker;
					}

					_.last(updatePrioritiesData.data).priority = oldPriority;

					Task.update_priorities(updatePrioritiesData).$promise.catch(function () {
						toastr.error('Failed to save new order');
					});
				};
			}

			var wayPointMarkerClicked = function (marker) {
				var driverId = marker.get('driverId'),
					order = marker.get('order'),
					templates = {
						templateUrl: 'scripts/features/big_map/info-window.html'
					};
				$templateRequest(templates.templateUrl).then(function (html) {
					var scope = $scope.$new(true, $scope);
					scope.driver = _.find($scope.employees, { id: driverId });
					scope.order = order;
					scope.drivers = $scope.employees;
					scope.marker = marker;
					scope.isDriversDropDownOpened = false;
					scope.driverChanged = function () {
						var task = marker.get('task');
						var newDriver = scope.driver;
						var oldDriverId = task.user_id;
						if (!$scope._usersWithTasks[newDriver.id]) {
							// We don't know of this driver yet
							$scope._usersWithTasks[newDriver.id] = {
								user: newDriver,
								tasks: [],
								color:
									_.difference($scope._colorsCollection, _.map($scope._usersWithTasks, 'color'))[0] ||
									_.sample($scope._colorsCollection)
							};
							$scope.markersByDriverId[newDriver.id] = [];
						}
						var lastMarkerForDriver = _.last($scope.markersByDriverId[newDriver.id]);
						task.user_id = newDriver.id;
						task.user = newDriver;
						task.priority = lastMarkerForDriver ? lastMarkerForDriver.get('priority') + 1 : 1;
						$scope.markersByDriverId[newDriver.id].push(marker);
						$scope._usersWithTasks[newDriver.id].tasks.push(task);
						$scope.markersByDriverId[oldDriverId] = _.reject($scope.markersByDriverId[oldDriverId], marker);
						$scope._usersWithTasks[oldDriverId].tasks = _.reject(
							$scope._usersWithTasks[oldDriverId].tasks,
							{
								id: task.id
							}
						);

						scope.order = lastMarkerForDriver ? lastMarkerForDriver.get('order') + 1 : 1;
						scope.isDriversDropDownOpened = false;
						updateMarkerProperties(marker, newDriver.id, task.priority, scope.order);
						$compile(angular.element(html))(scope);
						$timeout(function () {
							$scope.infoWindow.setContent(template.html());
						});

						marker.setIcon(
							'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=' +
								scope.order +
								'|' +
								$scope._usersWithTasks[newDriver.id].color +
								'|000000'
						);
						Task.update(task).catch(function () {
							toastr.error('Failed to change the assigned driver');
						});
					};

					marker.set('scope', scope);

					var template = angular.element(html);
					$compile(template.contents())(scope);

					$timeout(function () {
						$scope.infoWindow.open($scope.map, marker);
						$scope.infoWindow.setContent(template.html());
					});
				});
			};

			$scope._drawTaskWayPoints = function (tasks, user) {
				_.each(tasks, function (task, count) {
					var wayPoint = task.way_points[task.way_points.length - 1];
					$scope._addMarker(wayPoint, user.color, count + 1, user.user, task);
				});
			};

			$scope._renderMap = function () {
				if (!$scope.map) {
					$scope._createMap();
				} else {
					clearMarkers($scope._markers);
				}
				var locations = $scope._getLoctations($scope.tasks);
				//center the map based on the tasks
				bringg_utils.center_map($scope.map, $scope._getLoctations($scope.tasks));
				//add the markets to the map
				_.each($scope._usersWithTasks, function (user) {
					$scope.markersByDriverId[user.user.id] = [];
					$scope._drawTaskWayPoints(user.tasks, user);
				});

				resizeMapByMarkers($scope.map, $scope._markers);
			};

			//data stuff
			$scope._getLoctations = function (tasks) {
				var result = [];
				_.each(tasks, function (task) {
					_.each(task.way_points, function (way_point) {
						result.push({ lat: way_point.lat, lng: way_point.lng });
					});
				});
				return result;
			};

			$scope._resetStuff = function () {
				$scope._usersWithTasks = {
					null: {
						user: { name: 'unassigned', id: null },
						tasks: [],
						color: $scope._colorsCollection[0]
					}
				};

				$scope.selectedDriver = null;
			};

			function getDriverIdFromSolutionByWayPointId(wayPointId) {
				var foundDriverId = undefined;
				_.each(_.keys($scope.resultsOptimizationDatum.result.solution), function (driverId) {
					var foundSolution = _.find(
						$scope.resultsOptimizationDatum.result.solution[driverId],
						function (driverStop) {
							return parseInt(driverStop.location_id, 10) === wayPointId;
						}
					);

					if (!_.isUndefined(foundSolution)) {
						foundDriverId = parseInt(driverId, 10);
					}
				});
				return foundDriverId;
			}

			$scope._buildDataCollection = function (tasks) {
				$scope._resetStuff();

				//sort tasks by priority
				var color = 1;
				var wayPointIds = _.flatten(
					_.map($scope.resultsOptimizationDatum.result.solution, function (solution) {
						var internalWayPointId = _.map(solution, function (wayPoint) {
							if (wayPoint.location_id !== 'depot') {
								return wayPoint.location_id;
							}
							return;
						});
						return _.compact(internalWayPointId);
					})
				);

				Task.mass_get({ way_point_ids: wayPointIds }, function (results) {
					$scope.tasks = tasks = results.tasks;
					var color = 1;
					_.each(tasks, function (task) {
						if (task.user_id) {
							if ($scope._usersWithTasks[task.user_id]) {
								$scope._usersWithTasks[task.user_id].tasks.push(task);
							} else {
								var driver = _.find($scope.employees, function (driver) {
									return driver.id === task.user_id;
								});

								if (driver) {
									$scope._usersWithTasks[task.user_id] = {
										user: driver,
										tasks: [task],
										color: $scope._colorsCollection[color]
									};

									color++;
									if (color === $scope._colorsCollection.length) {
										color = 0;
									}
								} else {
									$scope._usersWithTasks[null].tasks.push(task);
								}
							}
						} else {
							$scope._usersWithTasks[null].tasks.push(task);
						}
					});

					$scope._renderMap();
				});
			};

			//user interaction
			$scope.handleDriverClicked = function (driver) {
				$scope.selectedDriver = driver;
				clearMarkers($scope._markers);
				var locations = $scope._getLoctations(driver.wayPoints);
				//center the map based on the tasks
				bringg_utils.center_map($scope.map, $scope._getLoctations(driver.wayPoints));
				var count = 1;
				//add the markets to the map
				$scope._drawTaskWayPoints(driver.wayPoints, driver, count);

				resizeMapByMarkers($scope.map, $scope._markers);

				$timeout(function () {
					$scope.$apply();
				}, 1);
			};

			//$scope.showTasksFilter = 1;
			$scope.filterOptions = [
				{ name: 'Show All Tasks', value: 0 },
				{ name: 'Show Task Ready To Execute', value: 1 },
				{ name: 'Show Task Not Ready To Execute', value: 2 }
			];

			$scope.$watch('showTasksFilter', function (newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}

				switch (newValue.value) {
					case 0:
						$scope.loadAllTasks();
						break;
					case 1:
						$scope.loadOpenTasks();
						break;
					case 2:
						$scope.loadPlanningTasks();
						break;
					default:
						$scope.loadAllTasks();
						break;
				}

				$scope._renderMap();
			});

			$scope.submitTicket = function () {
				SubmitTicketService.open();
			};

			$scope.$on('$destroy', function () {
				delete $window.driversDropDownOpenedInBigMap;
				delete $window.priorityChangedInBigMap;
			});
		}
	);
