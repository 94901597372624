/*global Slick:false */

'use strict';

angular
	.module('bringgApp')
	.controller('SelectDriversController', function (
		$scope,
		$uibModalInstance,
		$translate,
		toastr,
		Employees,
		$rootScope,
		$filter
	) {
		$scope.grid = {};
		$scope.filterObject = { searchString: '' };

		var checkboxSelector = new Slick.CheckboxSelectColumn();
		$scope.onSelection = function () {
			if (!$scope.$$phase) {
				$scope.$apply();
			}
		};

		$scope.selectedItems = [];

		$scope.getItemMetadata = function (oldFunction) {
			return function (row) {
				var item = this.getItem(row);
				var ret = oldFunction(row);

				return ret;
			};
		};

		$scope.gridColumns = [
			checkboxSelector.getColumnDefinition(),
			{
				id: 'id',
				name: 'ID',
				field: 'id',
				width: 1,
				sortable: true,
				toolTip: 'ID if the driver'
			},
			{
				id: 'name',
				name: 'Name',
				field: 'name',
				width: 400,
				sortable: true,
				toolTip: 'Name of the driver'
			},
			{
				id: 'status',
				name: 'Status',
				field: 'status',
				width: 200,
				sortable: true,
				toolTip: 'Status of the driver'
			}
		];

		Employees.drivers({}, function (result) {
			$scope.employees = result;
		});

		$rootScope.$on('employees list update', function () {
			Employees.drivers().then(function (drivers) {
				$scope.employees = drivers;
			});
		});

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};

		$scope.handleSubmitClick = function () {
			$uibModalInstance.close(
				_.map($scope.selectedItems, function (item) {
					return item.id;
				})
			);
		};

		$scope.handleSkipClicked = function () {
			$uibModalInstance.close(null);
		};
	});
