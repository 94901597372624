'use strict';

/*global $:false */
/*global google:false */
/*global merge_objects:false */

angular
	.module('bringgApp')
	.factory(
		'AuthenticationUtils',
		function (
			$rootScope,
			$location,
			localStorageService,
			$log,
			$injector,
			$window,
			// Please do not remove Alerts from here even though it is not used inside.
			// This guarantee when user logged in - AlertsService initialized already and all listeners initiated
			// otherwise somehow the service initialization happens too late
			Alerts,
			CURRENT_REGION
		) {
			return {
				addCurrentUserMethods: function () {
					if (_.get($rootScope, 'sdk.session.user')) {
						$rootScope.sdk.session.user.has_access = function (field, subField) {
							if (!_.get($rootScope, 'sdk')) {
								return;
							}

							if (_.get($rootScope, 'sdk.session.user.admin')) {
								return true;
							}

							var privilege = this.authorization_flags && this.authorization_flags[field];

							if (!_.isUndefined(privilege)) {
								if (subField) {
									return privilege[subField];
								}

								return privilege.value;
							}

							return subField ? false : true;
						};
						$rootScope.isAdmin = $rootScope.sdk.session.user.admin;
					}
				},

				$rootScopeHasCurrentUser: function () {
					try {
						// $rootScope.sdk.session.user = localStorageService.get('overvyoo_user');
						if (_.isEmpty($rootScope.sdk.session.user)) {
							var path = $location.path();
							if (
								path !== '/login/' &&
								path !== '/reset/' &&
								path !== '/confirmation/' &&
								path !== '/sso/saml'
							) {
								$location.path('/login/');
								return false;
							}
						} else {
							if (!$rootScope.sdk.session.user.feature_flags) {
								$rootScope.sdk.session.user.feature_flags = {};
							}
							if (!$rootScope.sdk.session.user.authorization_flags) {
								$rootScope.sdk.session.user.authorization_flags = {};
							}
							$rootScope.canLogin =
								$rootScope.sdk.session.user.admin || $rootScope.sdk.session.user.dispatcher;
							$rootScope.isLoggedIn = true;
							return !_.isUndefined($rootScope.sdk.session.user.id);
						}
					} catch (e) {
						$log.error('Authenticated cookie returned ' + e);
						return false;
					} finally {
						this.addCurrentUserMethods();
					}
				},

				updateEnableLogs: function (user) {
					if (_.isUndefined(user.enable_logs)) {
						return;
					}
					$log.enable(user.enable_logs);
					localStorageService.set('logEnabled', user.enable_logs);
				},
				beginPartialLogin: function () {
					localStorage.setItem('partial-login', true);
				},

				endPartialLogin: function () {
					localStorage.removeItem('partial-login');
				},
				isPartialLogin: function () {
					return !!localStorage.getItem('partial-login');
				},

				updateUser: function (user) {
					user.feature_flags = user.feature_flags || {};
					user.authorization_flags = user.authorization_flags || {};

					this.updateEnableLogs(user);
					this.addCurrentUserMethods();

					localStorageService.set('overvyoo_user', user);
					$rootScope.$broadcast('currentUserUpdated', $rootScope.sdk.session.user);
				},
				setAuthenticationHeaders: function (email, token) {
					var key = 'Token token=' + token;
					var http = $injector.get('$http');
					http.defaults.headers.common.Authorization = key;
					http.defaults.headers.common['x-ov-user-email'] = email;
					localStorageService.set('auth_key', key);
				},
				setSdk: function (sdk) {
					$rootScope.sdk = sdk;
				},
				doLogin: function (user) {
					var isSameRegion = true;

					$rootScope.$broadcast('loggedout');

					if (CURRENT_REGION !== user.region) {
						isSameRegion = false;
					}

					localStorage.setItem('bringg-region', user.region);

					this.setAuthenticationHeaders(user.email, user.authentication_token);
					this.updateUser(user);

					$rootScope.isLoggedIn = true;
					$rootScope.canLogin = $rootScope.sdk.session.user.admin || $rootScope.sdk.session.user.dispatcher;

					if (!isSameRegion && !localStorageService.get('impersonate_original_user')) {
						$window.location.reload();
					} else {
						$rootScope.$broadcast('loggedin');
					}
				}
			};
		}
	)
	.factory(
		'Authentication',
		function (
			$http,
			UserSession,
			$rootScope,
			$route,
			$location,
			localStorageService,
			$q,
			$cookies,
			Token,
			toastr,
			affirmationAlertsService,
			$window,
			MerchantService,
			CURRENT_REGION,
			$log,
			SocketPubSub,
			Support,
			$injector,
			AuthenticationUtils,
			BringgSDK,
			SingleSignOn,
			ReactSdkActions
		) {
			var AuthenticationService = {};

			AuthenticationService.destroyServerSession = function () {
				var impersonated = Boolean(localStorageService.get('impersonate_original_user'));

				if (impersonated) {
					return;
				}
				if (!_.isUndefined($rootScope.session) && !_.isNull($rootScope.session)) {
					$rootScope.session.$destroy();
					$rootScope.session = null;
				} else if (!_.isNull(AuthenticationService.currentUser())) {
					new UserSession().$destroy();
				}
			};

			AuthenticationService.reload = function () {
				location.reload();
			};

			AuthenticationService.doLocalLogout = function () {
				localStorageService.clearAll();

				$http.defaults.headers.common.Authorization = '';
				$http.defaults.headers.common['x-ov-user-email'] = '';
				$rootScope.canLogin = false;
				$rootScope.isLoggedIn = false;
				$rootScope.sdk = null;
				ReactSdkActions.updateSdk();
				BringgSDK.resetSdkPromise();
				$rootScope.$broadcast('loggedout');

				if (!AuthenticationService.isLoggedIn()) {
					$location.path('/login').search({});
					AuthenticationService.reload();
					return;
				}
			};

			AuthenticationService.doLogout = function (destroySession) {
				if (destroySession === undefined || destroySession) {
					AuthenticationService.destroyServerSession();
				}
				affirmationAlertsService.clearAllAlerts();
				AuthenticationService.doLocalLogout();
			};

			/**
			 * this method wraps the provided closure with a lazy-loaded initializer and assigns to provided service object..
			 *
			 * @param {Object} service
			 * @param {string} methodName
			 * @param {function} closure
			 */
			function defineWrappedMethod(service, methodName, closure) {
				service[methodName] = function () {
					return closure.apply(service, arguments);
				};
			}

			defineWrappedMethod(AuthenticationService, 'switch_accounts', function (cb) {
				Token.switch_accounts(function (data) {
					if (data.success) {
						AuthenticationService.doLogout();
						AuthenticationUtils.doLogin(data.user);
						$window.location.reload();

						if (cb) {
							return cb();
						}
					} else {
						return;
					}
				});
			});

			defineWrappedMethod(AuthenticationService, 'impersonateToSupportByTeamId', function (teamId) {
				var oldUser = this.currentUser();

				return Support.impersonate({ team_id: teamId }).$promise.then(function (result) {
					if (result.success) {
						var user = result.user;
						localStorageService.set('impersonate_original_user', oldUser);
						localStorageService.set('overvyoo_user', user);
						user.impersonated = true;
						return user;
					}
					return $q.reject(new Error(result.message));
				});
			});

			defineWrappedMethod(AuthenticationService, 'canLogin', function () {
				if (this.isLoggedIn()) {
					return this.currentUser().admin || this.currentUser().dispatcher;
				} else {
					return false;
				}
			});

			defineWrappedMethod(AuthenticationService, 'codedLogin', function (code, session) {
				$rootScope.session = new UserSession({ code: code, session: session });
				$rootScope.session.$saveWithCode(
					function (data, status, headers, config) {
						// success will only be defined on failure
						if (data && _.isUndefined(data.success)) {
							if (!data.admin && !data.dispatcher) {
								toastr.error('Only administrators can currently log in to the dashboard.');
								return;
							}
							data.email = $rootScope.session.email;
							AuthenticationUtils.doLogin(data);
						} else {
							if (data.success) {
								if (data.rc === 1) {
									localStorageService.set('bringg_change_password_token', data.token);
									return $location.path('/change_password/');
								} else if (data.rc === 2) {
									return $location.path('/remind/');
								}
							} else {
								toastr.error('Login failed: ' + data.message);
							}
						}
					},
					function (data, status, headers, config) {
						if (status === 401) {
							toastr.error(data.message);
						} else if (data && data.message) {
							toastr.error('Failed login due to ' + data.message + ' please try again');
						} else {
							toastr.error('Failed to login, please try again');
						}
					}
				);
			});

			AuthenticationService.loginWithAccessToken = function (email, authentication_token, merchantUuid, region) {
				return BringgSDK.loginWithToken(region, authentication_token).then(function (data) {
					$rootScope.sdk = data;
					AuthenticationUtils.doLogin(data.session.user);
				});
			};

			AuthenticationService.isCurrentUser = function (id) {
				return _.get($rootScope, 'sdk.session.user') && $rootScope.sdk.session.user.id === parseInt(id, 10);
			};

			AuthenticationService.login = function (
				email,
				password,
				merchant_uuid,
				region,
				recaptcha,
				recaptcha_qa_token,
				multipleLoginCB,
				captchaCB,
				failureCB
			) {
				if (!window._bringg) {
					console.log('Please wait for the app to load and try again');
					return;
				}

				BringgSDK.login(email, password, merchant_uuid, region, recaptcha, recaptcha_qa_token)
					.then(function (data) {
						var user = data.session.user;
						if (!user.admin && !user.dispatcher) {
							toastr.error('Only administrators can currently log in to the dashboard.');
							return;
						}
						$rootScope.sdk = data;
						AuthenticationUtils.doLogin(user);
					})
					.catch(function (exception) {
						if (exception.reason?.name === 'HTTP_FAILED_DEPENDENCY' && multipleLoginCB) {
							if (exception.data.length === 1) {
								var merchantData = _.first(exception.data);
								var region = merchantData.region;
								localStorage.setItem('bringg-region', region);

								AuthenticationService.login(
									email,
									password,
									merchantData.uuid,
									region,
									null,
									recaptcha_qa_token,
									multipleLoginCB,
									captchaCB,
									failureCB
								);
							} else {
								multipleLoginCB(exception.data);
							}
						} else if (exception.reason?.name === 'HTTP_LOCKED' && captchaCB) {
							captchaCB(exception.data);
						} else if (exception.reason?.name === 'HTTP_UNAUTHORIZED') {
							if (!_.isNull(exception.data)) {
								failureCB(exception.data);
							}

							toastr.error(exception.message, 'Login failed');
						} else {
							$log.error('AuthenticationService.login - error during login:', exception.message);
							toastr.error(exception.message, 'Login failed');
						}
					});
			};

			AuthenticationService.resendConfirmationEmail = function (loginParams) {
				return BringgSDK.resendConfirmationEmail(loginParams);
			};

			AuthenticationService.impersonate = function (userId, cb) {
				return $rootScope.sdk.impersonate(userId).then(function (sdk) {
					var oldUser = AuthenticationService.currentUser();
					var user = sdk.session.user;
					localStorageService.set('impersonate_original_user', oldUser);
					localStorageService.set('overvyoo_user', user);

					return user;
				});
			};

			AuthenticationService.assignUrl = function (url) {
				$window.location.assign(url);
			};

			AuthenticationService.returnToOriginalUser = function () {
				const impersonateOriginalUser = localStorageService.get('impersonate_original_user');
				localStorageService.remove('impersonate_original_user');
				localStorageService.set('overvyoo_user', impersonateOriginalUser);
				return impersonateOriginalUser;
			};

			AuthenticationService.logout = function (destroySession) {
				var currentUser = AuthenticationService.currentUser();
				if (_.isNull(currentUser)) {
					$location.path('/login').search({});
					return;
				}

				var logoutUrl = SingleSignOn.getLogoutUrl(currentUser.authentication_token);

				if (logoutUrl) {
					AuthenticationService.doLocalLogout();
					AuthenticationService.assignUrl(logoutUrl);
					SingleSignOn.clearSSOIdentifiers();
				} else {
					AuthenticationService.doLogout(destroySession, true);
				}
			};

			AuthenticationService.isLoggedIn = function () {
				return !_.isEmpty($rootScope.sdk);
			};

			AuthenticationService.doLogin = AuthenticationUtils.doLogin;

			AuthenticationService.currentUser = function () {
				return $rootScope.sdk ? $rootScope.sdk.session.user : null;
			};

			AuthenticationService.isImpersonatedMode = function () {
				const currentUser = AuthenticationService.currentUser();
				return Boolean(currentUser && currentUser.original_user_id);
			};

			AuthenticationService.featureFlags = function () {
				var userFeatureFlags =
					AuthenticationService.currentUser() && AuthenticationService.currentUser().feature_flags;

				var Teams = $injector.get('Teams');

				return $q.all([MerchantService.featureFlags(), Teams.featureFlags()]).then(function (results) {
					var merchantFeatureFlags = results[0];
					var teamsFeatureFlags = results[1];
					return _.extend({}, merchantFeatureFlags, teamsFeatureFlags, userFeatureFlags);
				});
			};

			AuthenticationService.userHasAccess = function (privilege, subField) {
				const currentUser = AuthenticationService.currentUser();
				if (!currentUser) {
					return false;
				}
				if (currentUser.admin) {
					return true;
				}
				return currentUser.hasAccess(privilege, subField);
			};

			return AuthenticationService;
		}
	);
