'use strict';

angular.module('bringgApp.directives').directive('showHidePassword', function () {
	return {
		template:
			'<span class="input-group-addon" ng-click="handleShowPasswordClick()"><i class="bringg-icon bringg-icon-eye" ng-if="showPassword"></i> <i class="bringg-icon bringg-icon-eye-off" ng-if="!showPassword"></i></span>',
		replace: true,
		link: function (scope, element, attrs) {
			scope.showPassword = false;

			scope.handleShowPasswordClick = function () {
				scope.showPassword = !scope.showPassword;
			};
		}
	};
});
