'use strict';

angular.module('bringgApp').directive('dispatchMapLegend', function () {
	return {
		replace: true,
		restrict: 'E',
		templateUrl: 'scripts/directives/dispatch-map/legend/dispatch-map-legend.html',
		controller: function (
			$scope,
			$translate,
			dispatchMapIconsService,
			Teams,
			$q,
			Tags,
			Tasks,
			$timeout,
			Employees,
			MerchantConfigurations
		) {
			$scope.selectOptions = [
				{ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_DEFAULT'), value: null },
				{ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_ORDER_STATUS'), value: 'order_status' },
				{ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_DRIVER'), value: 'driver' },
				{ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_STOP_NUMBER'), value: 'stop_number' },
				{ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_TAG'), value: 'tag' }
			];

			if (MerchantConfigurations.enable_teams) {
				$scope.selectOptions.push({ label: $translate.instant('DISPATCH_LIST.LEGEND_BY_TEAM'), value: 'team' });
			}

			var orderStatusLegendList = [
				{ label: $translate.instant('UTILS.ORDER_STATUS_NOT_ASSIGNED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_ASSIGNED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_ON_THE_WAY') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_CHECKED_IN') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_DONE') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_LATE') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_ACCEPTED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_CANCELLED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_REJECTED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_UNACKNOWLEDGED') },
				{ label: $translate.instant('UTILS.ORDER_STATUS_UNKNOWN') }
			];

			var stopNumberLegendList = [
				{ label: $translate.instant('DISPATCH_LIST.FIRST_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.SECOND_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.THIRD_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.FOURTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.FIFTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.SIXTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.SEVENTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.EIGHTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.NINTH_STOP') },
				{ label: $translate.instant('DISPATCH_LIST.TENTH_STOP') }
			];

			$scope.selectedLegend = dispatchMapIconsService.taskIconsBase
				? _.find($scope.selectOptions, { value: dispatchMapIconsService.taskIconsBase })
				: _.first($scope.selectOptions);
			$scope.legendList = [];
			$scope.inited = false;

			/**
			 * redraw legend items
			 */
			var redrawLegend = function () {
				if (!$scope.selectedLegend) {
					return;
				}

				switch ($scope.selectedLegend.value) {
					case 'order_status':
						$scope.legendList = _.map(orderStatusLegendList, function (orderStatusLegend, index) {
							return {
								label: orderStatusLegend.label,
								svg: dispatchMapIconsService.getTaskIconByOrderStatus(index)
							};
						});
						break;
					case 'driver':
						var visibleTasks = _.filter($scope.tasks, function (task) {
							return $scope.markers.tasks[task.id] && $scope.markers.tasks[task.id].visible;
						});

						$scope.legendList = _.chain(visibleTasks)
							.uniq(function (task) {
								return task.user_id;
							})
							.map(function (task) {
								var driver = _.find($scope.drivers, { id: task.user_id });
								var driverName = driver && driver.name;
								return {
									label: task.user_id ? driverName : 'Unassigned',
									svg: dispatchMapIconsService.getTaskIconByDriver(task)
								};
							})
							.valueOf();

						break;
					case 'tag':
						var visibleTasks = _.filter($scope.tasks, function (task) {
							return $scope.markers.tasks[task.id] && $scope.markers.tasks[task.id].visible;
						});

						$scope.legendList = _.chain(visibleTasks)
							.uniq(function (task) {
								return task.tag_id;
							})
							.map(function (task) {
								var tag = _.find(Tags.tags, { id: task.tag_id });
								var tagName = tag && tag.tag;
								return {
									label: task.tag_id ? tagName : 'No Tag',
									svg: dispatchMapIconsService.getTaskIconByTag(task.tag_id)
								};
							})
							.valueOf();
						break;
					case 'team':
						var visibleTasks = _.filter($scope.tasks, function (task) {
							return $scope.markers.tasks[task.id] && $scope.markers.tasks[task.id].visible;
						});

						$scope.legendList = _.chain(visibleTasks)
							.uniq(function (task) {
								return task.team_id;
							})
							.map(function (task) {
								return {
									label: Teams.teamsByIdMap[task.team_id] && Teams.teamsByIdMap[task.team_id].name,
									svg: dispatchMapIconsService.getTaskIconByTeam(task.team_id)
								};
							})
							.valueOf();
						break;
					case 'stop_number':
						$scope.legendList = _.map(stopNumberLegendList, function (stopNumberLegend, index) {
							return {
								label: stopNumberLegend.label,
								svg: dispatchMapIconsService.getTaskIconByStopNumber(index + 1)
							};
						});

					default:
				}
			};

			$scope.onSelect = function () {
				dispatchMapIconsService.setTaskIconsBase($scope.selectedLegend.value);
				redrawLegend();
				$scope.showLegend = $scope.selectedLegend && $scope.selectedLegend.value;

				$timeout(function () {
					$scope.refreshMarkers({ withoutDrivers: true });
				}, 200);
			};

			/**
			 * init function
			 */
			var init = function () {
				var promises = [Tags.tags.$promise];

				$q.all(promises).then(function (results) {
					$scope.inited = true;
					$scope.minimized = MerchantConfigurations && !MerchantConfigurations.show_eta_to_home;
					$scope.showLegend = $scope.selectedLegend && $scope.selectedLegend.value;
					$timeout(redrawLegend, 10);

					// register to task list update - for legend refresh
					$scope.$emit('redrawLegend', redrawLegend);
					$scope.$on('minimize time to base', function (event, minimized) {
						$scope.minimized = minimized;
					});
				});
			};

			init();
		}
	};
});
