'use strict';

angular.module('bringgApp').factory('CancelTaskModalService', function ($uibModal) {
	var CancelTaskServiceModal = {};

	CancelTaskServiceModal.start = function (tasks) {
		return $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'CancelTaskController',
			templateUrl: 'scripts/features/cancel_task/cancel-task.html',
			size: 'xs',
			resolve: {
				tasksIds: function () {
					return tasks.map(t => t.id);
				},
				tagsIds: function () {
					return tasks.map(t => t.tag_id);
				},
				taskTypeIds: function () {
					return tasks.map(t => t.task_type_id);
				}
			}
		}).result;
	};

	return CancelTaskServiceModal;
});
