'use strict';

angular
	.module('bringgApp')
	.controller('InventoryManagementController', function ($scope, Teams, $q, InventoryManagementService, $translate) {
		$scope.gridOptions = {
			enableSorting: true,
			enableCellEdit: false,
			columnDefs: [
				{
					name: 'external_id',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.ID'
				},
				{
					name: 'inventory_history_type',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.STATUS',
					cellFilter: 'inventoryHistoryEventTypeFilter'
				},
				{
					name: 'first_appear_on_last_warehouse_datetime',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.WAREHOUSE_ARRIVAL',
					cellFilter: 'utc_minutes_ago'
				},
				{
					name: 'address',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.DESTINATION'
				},
				{
					name: 'task_external_id',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.ASSOCIATED_ORDER',
					cellTemplate:
						'<div class="ui-grid-cell-contents"><a href="/#/history/{{row.entity.task_id}}">{{COL_FIELD CUSTOM_FILTERS}}</a></div>'
				},
				{
					name: 'scan_user_id',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.LAST_VERIFIED_OWNER',
					cellTemplate:
						'<div class="ui-grid-cell-contents"><a href="/#/drivers/{{COL_FIELD}}">{{row.entity.scan_user_name}}</a></div>'
				},
				{
					name: 'team_id',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.TEAM_NAME',
					cellTemplate:
						'<div class="ui-grid-cell-contents"><a href="/#/drivers/teams/{{COL_FIELD}}">{{row.entity.team_name}}</a></div>'
				},
				{
					name: 'cancelled_delivery_attempts',
					headerCellFilter: 'translate',
					displayName: 'INVENTORY_MANAGEMENT.DELIVERY_ATTEMPT',
					cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD || 0}}</div>'
				}
			],
			data: 'data'
		};

		$scope.gridOptions.onRegisterApi = function (gridApi) {
			//set gridApi on scope
			$scope.gridApi = gridApi;
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
				//update the row
				$scope.$apply();
			});
		};

		$scope.init = function () {
			var promises = [Teams.all(), InventoryManagementService.all()];

			// load all employees and teams
			$q.all(promises).then(function (results) {
				$scope.teams = angular.copy(results[0]);
				$scope.teams.unshift({ id: -1, name: $translate.instant('INVENTORY_MANAGEMENT.ALL') });

				$scope.data = results[1].inventories;
				$scope.originalData = results[1].inventories;
			});
		};

		$scope.handleTeamSelected = function (item, model) {
			if (_.get(item, 'id', -1) !== -1) {
				$scope.data = _.filter($scope.originalData, { team_id: item.id.toString() });
			} else {
				$scope.data = $scope.originalData;
			}
		};

		$scope.init();
	});
