'use strict';

angular.module('bringgApp').controller('UpgradeNowController', function ($scope, $uibModalInstance, Payments, toastr) {
	$scope.handleChangeSubscriptionTypeResult = function (result) {
		if (result.success) {
			toastr.success('Your plan was successfully changed.');
			$uibModalInstance.close();
		} else {
			if (result.message) {
				toastr.error(result.message);
			} else {
				toastr.error('There was a problem changing your subscription type.');
			}
		}
	};

	$scope.handleUpgradeClick = function () {
		Payments.changeSubscriptionType(
			{ selected_subscription_type: 'pro_driver_plan' },
			$scope.handleChangeSubscriptionTypeResult
		);
	};

	$scope.handleCancelClick = function () {
		$uibModalInstance.dismiss();
	};
});
