'use strict';

angular.module('bringgApp').factory('AvailableReportsService', function (MerchantConfigurations) {
	var sharingModes = {
		all: 0,
		email_only: 1,
		phone_only: 2,
		no_sharing: 3
	};
	var emailSharingMode =
		MerchantConfigurations.sharing_mode === sharingModes.all ||
		MerchantConfigurations.sharing_mode === sharingModes.email_only;
	var smsSharingMode =
		MerchantConfigurations.sharing_mode === sharingModes.all ||
		MerchantConfigurations.sharing_mode === sharingModes.phone_only;

	var availableReports = [
		{
			title: 'REPORTS.REPORT_TYPE.TIMES_PER_WAYPOINT',
			selected: false,
			id: 'times_per_waypoint',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'created_at',
					title: 'Created',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/task-created.html'
				},
				{
					id: 'title',
					title: 'Title',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-title.html'
				},
				{
					id: 'status',
					title: 'Status',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-status.html'
				},
				{
					id: 'wp1_customer_name',
					title: 'Customer 1',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp1_customer_name.html'
				},
				{
					id: 'wp1_scheduled_at',
					title: 'WP1 Scheduled Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp1_scheduled_at.html'
				},
				{
					id: 'wp1_checkin_time',
					title: 'WP1 Arrive Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp1_checkin_time.html'
				},
				{
					id: 'wp1_checkout_time',
					title: 'WP1 Left Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp1_checkout_time.html'
				},
				{
					id: 'wp2_customer_name',
					title: 'Customer 2',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp2_customer_name.html'
				},
				{
					id: 'wp2_scheduled_at',
					title: 'WP2 Scheduled Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp2_scheduled_at.html'
				},
				{
					id: 'wp2_checkin_time',
					title: 'WP2 Arrive Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp2_checkin_time.html'
				},
				{
					id: 'wp2_checkout_time',
					title: 'WP2 Left Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp2_checkout_time.html'
				},
				{
					id: 'wp3_customer_name',
					title: 'Customer 3',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp3_customer_name.html'
				},
				{
					id: 'wp3_scheduled_at',
					title: 'WP3 Scheduled Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp3_scheduled_at.html'
				},
				{
					id: 'wp3_checkin_time',
					title: 'WP3 Arrive Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp3_checkin_time.html'
				},
				{
					id: 'wp3_checkout_time',
					title: 'WP3 Left Time',
					templateUrl: 'scripts/features/reports/cell_templates/times_per_waypoint/wp3_checkout_time.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ORDER_PER_SHIFT',
			selected: false,
			id: 'tasks_per_shift',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-header.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/sub-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'title',
					title: 'Title',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-title.html'
				},
				{
					id: 'started_time',
					title: 'Started',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-start.html'
				},
				{
					id: 'ended_time',
					title: 'Ended',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-end.html'
				},
				{
					id: 'total_time',
					title: 'Total Time',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-age.html'
				},
				{
					id: 'rating',
					title: 'Rating',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-rating.html'
				},
				{
					id: 'status',
					title: 'Status',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-status.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.CUSTOMERS_EXPERIENCE',
			selected: false,
			id: 'customer_experience',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-header.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/sub-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/customer-address.html'
				},
				{
					id: 'customer',
					title: 'Customer',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/customer-name.html'
				},
				{
					id: 'route_sms_view_count',
					hidden: !smsSharingMode,
					title: 'Route SMS viewed',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_view_count_route.html'
				},
				{
					id: 'route_sms_delivered',
					hidden: !smsSharingMode,
					title: 'Route SMS delivered at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_delivered_route.html'
				},
				{
					id: 'route_sms_view_time',
					hidden: !smsSharingMode,
					title: 'Route SMS first seen at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_first_seen_route.html'
				},
				{
					id: 'start_sms_view_count',
					hidden: !smsSharingMode,
					title: 'Start SMS viewed',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_view_count_start.html'
				},
				{
					id: 'start_sms_delivered',
					hidden: !smsSharingMode,
					title: 'Start SMS delivered at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_delivered_start.html'
				},
				{
					id: 'start_sms_view_time',
					hidden: !smsSharingMode,
					title: 'Start SMS first seen at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_first_seen_start.html'
				},
				{
					id: 'arrived_sms_view_count',
					hidden: !smsSharingMode,
					title: 'Arrived SMS viewed',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/sms_view_count_arrived.html'
				},
				{
					id: 'arrived_sms_delivered',
					hidden: !smsSharingMode,
					title: 'Arrived SMS delivered at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/sms_delivered_arrived.html'
				},
				{
					id: 'arrived_sms_view_time',
					hidden: !smsSharingMode,
					title: 'Arrived SMS first seen at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/sms_first_seen_arrived.html'
				},
				{
					id: 'left_sms_view_count',
					hidden: !smsSharingMode,
					title: 'Left SMS viewed',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_view_count_left.html'
				},
				{
					id: 'left_sms_delivered',
					hidden: !smsSharingMode,
					title: 'Left SMS delivered at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_delivered_left.html'
				},
				{
					id: 'left_sms_view_time',
					hidden: !smsSharingMode,
					title: 'Left SMS first seen at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/sms_first_seen_left.html'
				},
				{
					id: 'start_email_view_count',
					hidden: !emailSharingMode,
					title: 'Start email viewed',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_view_count_start.html'
				},
				{
					id: 'start_email_delivered',
					hidden: !emailSharingMode,
					title: 'Start email delivered at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_delivered_start.html'
				},
				{
					id: 'start_email_view_time',
					hidden: !emailSharingMode,
					title: 'Start email first seen at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_first_seen_start.html'
				},
				{
					id: 'arrived_email_view_count',
					hidden: !emailSharingMode,
					title: 'Arrived email viewed',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_view_count_arrived.html'
				},
				{
					id: 'arrived_email_delivered',
					hidden: !emailSharingMode,
					title: 'Arrived email delivered at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_delivered_arrived.html'
				},
				{
					id: 'arrived_email_view_time',
					hidden: !emailSharingMode,
					title: 'Arrived email first seen at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_first_seen_arrived.html'
				},
				{
					id: 'left_email_view_count',
					hidden: !emailSharingMode,
					title: 'Left email viewed',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_view_count_left.html'
				},
				{
					id: 'left_email_delivered',
					hidden: !emailSharingMode,
					title: 'Left email delivered at',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/email_delivered_left.html'
				},
				{
					id: 'left_email_view_time',
					hidden: !emailSharingMode,
					title: 'Left email first seen at',
					templateUrl:
						'scripts/features/reports/cell_templates/customer_experience/email_first_seen_left.html'
				},
				{
					id: 'rating',
					title: 'Rating',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/rating.html'
				},
				{
					id: 'rating_reason',
					title: 'Rating Reason',
					templateUrl: 'scripts/features/reports/cell_templates/customer_experience/rating-reason.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ALL_DONE_ORDERS',
			selected: false,
			id: 'all_orders',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/all_deliveries/data-header.html',
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'driver_name',
					title: 'Driver',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/driver-name.html'
				},
				{
					id: 'customer',
					title: 'Customer',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/customer-name.html'
				},
				{
					id: 'address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/customer-address.html'
				},
				{
					id: 'phone',
					title: 'Phone',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/customer-phone.html'
				},
				{
					id: 'created_at',
					title: 'Created Time',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/task-created.html'
				},
				{
					id: 'scheduled_at',
					title: 'Scheduled Time',
					templateUrl: 'scripts/features/reports/cell_templates/late_deliveries/scheduled_time.html'
				},
				{
					id: 'start_time',
					title: 'Start Time',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/way-point-start.html'
				},
				{
					id: 'checkin_time',
					title: 'Arrive Time',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkin-time.html'
				},
				{
					id: 'checkout_time',
					title: 'Left Time',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkout-time.html'
				},
				{
					id: 'status',
					title: 'Status',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-status.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.LATE_ORDERS',
			selected: false,
			id: 'late_deliveries',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/late_deliveries/data-header.html',
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/late_deliveries/way-point-id.html'
				},
				{
					id: 'address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/customer-address.html'
				},
				{
					id: 'customer',
					title: 'Customer',
					templateUrl: 'scripts/features/reports/cell_templates/late_deliveries/customer-name.html'
				},
				{
					id: 'start_time',
					title: 'Start Time',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-start.html'
				},
				{
					id: 'scheduled_at',
					title: 'Scheduled Time',
					templateUrl: 'scripts/features/reports/cell_templates/late_deliveries/scheduled_time.html'
				},
				{
					id: 'checkin_time',
					title: 'Checkin Time',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkin-time.html'
				},
				{
					id: 'minutes_late',
					title: 'Time Late',
					templateUrl: 'scripts/features/reports/cell_templates/late_deliveries/minutes_late.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ORDERS_DETAILS',
			selected: false,
			id: 'detailed_orders',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-header.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/sub-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'started_time',
					title: 'Started',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-start.html'
				},
				{
					id: 'checkin_time',
					title: 'Arrived',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkin-time.html'
				},
				{
					id: 'checkout_time',
					title: 'Left',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkout-time.html'
				},
				{
					id: 'ended_time',
					title: 'Ended',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-end.html'
				},
				{
					id: 'distance_traveled',
					title: 'Distance',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-distance.html'
				},
				{
					id: 'total_time',
					title: 'Total Time',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-age.html'
				},
				{
					id: 'rating',
					title: 'Rating',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-rating.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.TOP_LINE',
			selected: false,
			id: 'top_line',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/top_line/data-row.html',
			fields: [
				{
					id: 'id',
					title: 'Name',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/name.html'
				},
				{
					id: 'shifts',
					title: 'Shifts',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/shifts.html'
				},
				{
					id: 'total_time_heading',
					title: 'Total Time Heading',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/total-time-heading.html'
				},
				{
					id: 'total_time_on_site',
					title: 'Total Time On Site',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/total-time-on-site.html'
				},
				{
					id: 'total_time',
					title: 'Total Time',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/total-time.html'
				},
				{
					id: 'total_distance',
					title: 'Total Distance',
					templateUrl: 'scripts/features/reports/cell_templates/top_line/total-distance.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.TIME_AND_DISTANCE',
			selected: false,
			id: 'distances_and_times',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/distances_and_times/data-header.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/sub-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'started_time',
					title: 'Started',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-start.html'
				},
				{
					id: 'checkin_time',
					title: 'Arrived',
					templateUrl: 'scripts/features/reports/cell_templates/distances_and_times/task-arrived.html'
				},
				{
					id: 'ended_time',
					title: 'Ended',
					templateUrl: 'scripts/features/reports/cell_templates/distances_and_times/task-ended.html'
				},
				{
					id: 'distance_traveled',
					title: 'Distance',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-distance.html'
				},
				{
					id: 'time_heading',
					title: 'Time On The Way',
					templateUrl: 'scripts/features/reports/cell_templates/distances_and_times/task-time-on-the-way.html'
				},
				{
					id: 'time_on_site',
					title: 'Time On Site',
					templateUrl: 'scripts/features/reports/cell_templates/distances_and_times/task-time-on-site.html'
				},
				{
					id: 'total_time',
					title: 'Total Time',
					templateUrl: 'scripts/features/reports/cell_templates/distances_and_times/task-age.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.RECONCILIATIONS',
			selected: false,
			id: 'reconciliations',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/reconciliations/data-header.html',
			subHeaderTemplateUrl: null,
			subFooterTemplateUrl: 'scripts/features/reports/cell_templates/reconciliations/data-footer.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: 'shifts',
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/customer-address.html'
				},
				{
					id: 'total_price',
					title: 'Price',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/task-price.html'
				},
				{
					id: 'delivery_price',
					title: 'Delivery Fee',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/delivery-price.html'
				},
				{
					id: 'total_credit',
					title: 'Credit',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/task-credit.html'
				},
				{
					id: 'left_to_be_paid',
					title: 'Cash',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/task-cash.html'
				},
				{
					id: 'tip',
					title: 'Tip',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/task-tip.html'
				},
				{
					id: 'pre_tip',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/pre-tip.html',
					title: 'Pre Tip'
				},
				{
					id: 'post_tip_cash',
					title: 'Post Tip Cash',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/post-tip-cash.html'
				},
				{
					id: 'post_tip_credit',
					title: 'Post Tip Credit',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/post-tip-credit.html'
				},
				{
					id: 'total_distance_per_shift',
					title: 'Total Distance',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/total-distance.html'
				},
				{
					id: 'to_be_paid_to_driver',
					title: 'Paid To Driver',
					templateUrl: 'scripts/features/reports/cell_templates/reconciliations/to_be_paid_to_driver.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.DETAILED_DRIVER_ACTIVITY',
			selected: false,
			id: 'detailed_driver_activity_report',
			description: 'For a given range, receive orders done by drivers, times and distance traveled',
			headerTemplateUrl:
				'scripts/features/reports/cell_templates/detailed_driver_activity_report/data-header.html',
			subHeaderTemplateUrl: null,
			subFooterTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'external_id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'created_at',
					title: 'Created',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/created-at.html'
				},
				{
					id: 'started_time',
					title: 'Started',
					templateUrl: 'scripts/features / reports / cell_templates / tasks_per_shift / task - start.html'
				},
				{
					id: 'ended_time',
					title: 'Ended',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-end.html'
				},
				{
					id: 'distance_traveled',
					title: 'Distance Traveled',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-distance.html'
				},
				{
					id: 'rating',
					title: 'Rating',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-rating.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ACTIVE_DRIVERS',
			selected: false,
			id: 'active_drivers',
			templateUrl: 'scripts/features/reports/active-drivers.html'
		},
		{
			title: 'REPORTS.REPORT_TYPE.DAILY_ENGAGEMENT_REPORT',
			selected: false,
			id: 'daily_engagement_report',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/daily_engagement_report/data-header.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/daily_engagement_report/sub-header.html',
			subFooterTemplateUrl: null,
			dataRowTemplateUrl: null
		},
		{
			title: 'REPORTS.REPORT_TYPE.DONE_VS_CANCELLED_ORDERS_PER_DRIVER',
			selected: false,
			id: 'done_vs_cancelled_per_driver',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/data-header.html',
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'user_id',
					title: 'Driver ID',
					templateUrl: 'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/driver-id.html'
				},
				{
					id: 'driver_name',
					title: 'Driver',
					templateUrl: 'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/driver-name.html'
				},
				{
					id: 'done_count',
					title: 'Done Orders',
					templateUrl: 'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/done_count.html'
				},
				{
					id: 'cancelled_count',
					title: 'Cancelled Orders',
					templateUrl:
						'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/cancelled_count.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ORDERS_BY_TAG',
			selected: false,
			id: 'orders_by_tag',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/orders_by_tag/data-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/orders_by_tag/data-row.html',
			subHeaderTemplateUrl: null,
			subFooterTemplateUrl: null,
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
				},
				{
					id: 'started_time',
					title: 'Started',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-start.html'
				},
				{
					id: 'checkin_time',
					title: 'Arrived',
					templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-checkin-time.html'
				},
				{
					id: 'ended_time',
					title: 'Ended',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-end.html'
				},
				{
					id: 'destination_address',
					title: 'Destination Address',
					templateUrl: 'scripts/features/reports/cell_templates/orders_by_tag/destination-address.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.CUSTOMERS_PER_DAY',
			selected: false,
			id: 'customers_per_day',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/customers_per_day/data-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/customers_per_day/data-row.html',
			subHeaderTemplateUrl: null,
			subFooterTemplateUrl: null,
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-id.html'
				},
				{
					id: 'name',
					title: 'Name',
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-name.html'
				},
				{
					id: 'address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-address.html'
				},
				{
					id: 'phone',
					title: 'Phone',
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-phone.html'
				},
				{
					id: 'created_at',
					title: 'Registered At',
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-created-at.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.CUSTOMER_ORDERS_REPORT',
			selected: false,
			id: 'customers_orders',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/customers_orders_report/data-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/customers_orders_report/data-row.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/customers_orders_report/sub-header.html',
			subFooterTemplateUrl: 'scripts/features/reports/cell_templates/customers_orders_report/data-footer.html',
			fields: [
				{
					id: 'id',
					title: 'Customer ID',
					hidden: false,
					templateUrl: 'scripts/features/reports/cell_templates/customers_per_day/customer-id.html'
				},
				{
					id: 'customer_name',
					title: 'Customer',
					hidden: true,
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-customer-name.html'
				},
				{
					id: 'created_at',
					title: 'Ordered At',
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-order-date.html'
				},
				{
					id: 'sku',
					title: 'SKU',
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-sku.html'
				},
				{
					id: 'name',
					title: 'Product Name',
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-name.html'
				},
				{
					id: 'price',
					title: 'Price',
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-price.html'
				},
				{
					id: 'quantity',
					title: 'Quantity',
					templateUrl:
						'scripts/features/reports/cell_templates/customers_orders_report/customers-orders-inventory-quantity.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ALL_SHIFTS',
			selected: false,
			id: 'shift_report',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/shift_report/data-row.html',
			subField: null,
			fields: [
				{
					id: 'id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/shift_report/shift-id.html'
				},
				{
					id: 'driver_name',
					title: 'Driver',
					templateUrl: 'scripts/features/reports/cell_templates/all_deliveries/driver-name.html'
				},
				{
					id: 'start_shift',
					title: 'Shift Started',
					templateUrl: 'scripts/features/reports/cell_templates/shift_report/shift-time.html'
				},
				{
					id: 'end_shift',
					title: 'Shift Ended',
					templateUrl: 'scripts/features/reports/cell_templates/shift_report/shift-time.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.CANCELLED_ORDERS',
			selected: false,
			id: 'cancelled_tasks',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'created_at',
					title: 'Created At',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/created-at.html'
				},
				{
					id: 'external_id',
					title: 'ID',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/task-id.html'
				},
				{
					id: 'driver_name',
					title: 'Driver',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/driver-name.html'
				},
				{
					id: 'customer_name',
					title: 'Customer',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/customer-name.html'
				},
				{
					id: 'cancel_reason',
					title: 'Cancellation Reason',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/cancel-reason.html'
				},
				{
					id: 'cancel_text',
					title: 'Cancellation Text',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/cancel-text.html'
				},
				{
					id: 'cancel_origin',
					title: 'Cancellation Origin',
					templateUrl: 'scripts/features/reports/cell_templates/cancelled_tasks/cancel-origin.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.ALERTS_REPORT',
			selected: false,
			id: 'alerts_report',
			headerTemplateUrl: 'scripts/features/reports/cell_templates/alerts_report/header-template.html',
			subHeaderTemplateUrl: 'scripts/features/reports/cell_templates/alerts_report/data-header.html',
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/alerts_report/data-row.html',
			subField: null,
			fields: [
				{
					id: 'created_at',
					title: 'Created At',
					templateUrl: 'scripts/features/reports/cell_templates/alerts_report/created-at.html'
				},
				{
					id: 'alert_type',
					title: 'Alert Type',
					templateUrl: 'scripts/features/reports/cell_templates/alerts_report/alert-type.html'
				}
			]
		},
		{
			title: 'REPORTS.REPORT_TYPE.DELIVERY_ORDERS_REPORT',
			selected: true,
			id: 'delivery_orders_report',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			subFooterTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/data-row.html',
			fields: [
				{
					id: 'external_id',
					title: 'Order ID',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id.html'
				},
				{
					id: 'customer_name',
					title: 'Customer Name',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id.html'
				},
				{
					id: 'customer_email',
					title: 'Customer Email',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id.html'
				},
				{
					id: 'way_point_address',
					title: 'Address',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id.html'
				},
				{
					id: 'created_at',
					title: 'Placed',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'scheduled_at',
					title: 'Promised Time',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'scheduled_to_be_ready',
					title: 'Ready For Pickup',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'placed_vs_promised',
					title: 'Placed vs Promised',
					templateUrl:
						'scripts/features/reports/cell_templates/delivery_orders_report/placed-vs-promised.html'
				},
				{
					id: 'last_assigned_time',
					title: 'Time Assigned',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'run_start_time',
					title: 'Time Dispatched',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'way_point_checkin_time',
					title: 'Time Arrived',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'way_point_checkout_time',
					title: 'Time Delivered',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'run_end_time',
					title: 'Time Returned',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'utc_time_to_locale'
				},
				{
					id: 'out_the_door',
					title: 'Out the door',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/out-the-door.html'
				},
				{
					id: 'to_the_door',
					title: 'To the door',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/to-the-door.html'
				},
				{
					id: 'total',
					title: 'Gross Sale',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'currency'
				},
				{
					id: 'pre_delivery_tip',
					title: 'Pre-tip',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'currency'
				},
				{
					id: 'discount',
					title: 'Discount',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'currency'
				},
				{
					id: 'total_price',
					title: 'Total',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'currency'
				},
				{
					id: 'post_delivery_tip',
					title: 'Post-tip',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/post-delivery-tip.html'
				},
				{
					id: 'post_delivery_type',
					title: 'Post-tip type',
					templateUrl:
						'scripts/features/reports/cell_templates/delivery_orders_report/post-delivery-tip-type.html'
				},
				{
					id: 'driver_name',
					title: 'Driver Name',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id.html'
				},
				{
					id: 'distance_traveled',
					title: 'Miles',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/miles.html'
				},
				{
					id: 'distance_pay_per_km',
					title: '$/mile',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'currency'
				},
				{
					id: 'milage_paid',
					title: 'Mileage Paid',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/mileage-cost.html'
				},
				{
					id: 'way_point_checkin_time',
					title: 'Day Part',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'day_part_filter'
				},
				{
					id: 'task_status',
					title: 'State',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/task-status.html'
				},
				{
					id: 'order_tracked',
					title: 'Order Tracked',
					templateUrl: 'scripts/features/reports/cell_templates/delivery_orders_report/order-tracked.html',
					filter: 'currency'
				},
				{
					id: 'avg_rating',
					title: 'Rating',
					templateUrl: 'scripts/features/reports/cell_templates/generic_id_with_filter.html',
					filter: 'number'
				}
			]
		}
	];

	if (MerchantConfigurations.enable_teams) {
		var report = _.find(availableReports, { id: 'customers_orders' });
		report.fields.push({
			id: 'team_name',
			title: 'Team',
			templateUrl: 'scripts/features/reports/cell_templates/customers_orders_report/team-name.html'
		});

		availableReports.push(
			{
				title: 'REPORTS.REPORT_TYPE.DONE_VS_CANCELLED_ORDERS_PER_TEAM',
				selected: false,
				id: 'done_vs_cancelled_per_team',
				headerTemplateUrl:
					'scripts/features/reports/cell_templates/done_vs_cancelled_per_driver/data-header.html',
				subHeaderTemplateUrl:
					'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/sub-header.html',
				dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
				subField: null,
				fields: [
					{
						id: 'forDate',
						title: 'Day',
						templateUrl: 'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/day.html'
					},
					{
						id: 'tasks_counter',
						title: 'Total Orders',
						templateUrl:
							'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/order-counter.html'
					},
					{
						id: 'unassigned_tasks_counter',
						title: 'Unassigned Orders',
						templateUrl:
							'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/unassigned_counter.html'
					},
					{
						id: 'done_tasks_counter',
						title: 'Done Orders',
						templateUrl:
							'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/done_count.html'
					},
					{
						id: 'cancelled_tasks_counter',
						title: 'Cancelled Orders',
						templateUrl:
							'scripts/features/reports/cell_templates/done_vs_cancelled_per_team/cancelled_count.html'
					}
				]
			},
			{
				title: 'REPORTS.REPORT_TYPE.DETAILED_RESPONSE_AND_DELIVERY_TIMES',
				selected: false,
				id: 'detailed_delivery_times_per_tag_per_team',
				headerTemplateUrl: null,
				subHeaderTemplateUrl: null,
				dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
				subField: null,
				fields: [
					{
						id: 'day',
						title: 'Date',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/day.html'
					},
					{
						id: 'external_id',
						title: 'ID',
						templateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/task-id.html'
					},
					{
						id: 'scheduled_vs_created',
						title: 'Scheduled vs. Created',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/order-time-span-template.html'
					},
					{
						id: 'minutes_to_start',
						title: 'Minutes To Start',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/order-time-span-template.html'
					},
					{
						id: 'minutes_to_get_to_first_way_point',
						title: 'Minutes To First Stop',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/order-time-span-template.html'
					},
					{
						id: 'minutes_to_deliver',
						title: 'Minutes To Deliver',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/order-time-span-template.html'
					},
					{
						id: 'name',
						title: 'Team',
						templateUrl:
							'scripts/features/reports/cell_templates/detailed_delivery_times_per_tag_per_team/order_team.html'
					}
				]
			}
		);
	}

	if (MerchantConfigurations.$restaurantMode()) {
		availableReports.push({
			title: 'REPORTS.REPORT_TYPE.TIME_AND_DISTANCE_DETAILS_PER_DRIVERS_AND_ORDER',
			selected: false,
			id: 'tasks_per_driver',
			headerTemplateUrl: null,
			subHeaderTemplateUrl: null,
			dataRowTemplateUrl: 'scripts/features/reports/cell_templates/tasks_per_shift/data-row.html',
			subField: null,
			fields: [
				{
					id: 'date',
					title: 'Date',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/date.html'
				},
				{
					id: 'pickup_location',
					title: 'PickUp Location',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/pickup_location.html'
				},
				{
					id: 'dropoff_location',
					title: 'DropOff Location',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/dropoff_location.html'
				},
				{
					id: 'air_distance_m',
					title: 'Air Distance',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/air_distance.html'
				},
				{
					id: 'distance_traveled_km',
					title: 'Distance Traveled',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/distance_traveled.html'
				},
				{
					id: 'driver_name',
					title: 'Driver Name',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/driver_name.html'
				},
				{
					id: 'company_name',
					title: 'Company Name',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/company_name.html'
				},
				{
					id: 'customer_name',
					title: 'Customer Name',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/customer_name.html'
				},
				{
					id: 'consumer_name',
					title: 'Consumer Name',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/consumer_name.html'
				},
				{
					id: 'tracking_number',
					title: 'Tracking #',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/tracking_number.html'
				},
				{
					id: 'started_time',
					title: 'Started Time',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/started_time.html'
				},
				{
					id: 'left_warehouse',
					title: 'Left Warehouse',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/left_warehouse.html'
				},
				{
					id: 'arrive_to_customer',
					title: 'Arrived to Customer',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/arrive_to_customer.html'
				},
				{
					id: 'left_customer',
					title: 'Left Customer',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/left_customer.html'
				},
				{
					id: 'original_eta',
					title: 'Original ETA',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/original_eta.html'
				},
				{
					id: 'delivery_confirmation',
					title: 'Delivery Confirmation',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/delivery_confirmation.html'
				},
				{
					id: 'rating',
					title: 'Average Rating',
					templateUrl: 'scripts/features/reports/cell_templates/tasks_per_driver/rating.html'
				}
			]
		});
	}

	if (MerchantConfigurations.enable_task_price) {
		var updateReport = _.find(availableReports, { id: 'detailed_orders' });
		updateReport.fields.push({
			id: 'total_price',
			title: MerchantConfigurations.tip_currency || '$',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-price.html'
		});
		updateReport.fields.push({
			id: 'left_to_be_paid',
			title: 'Cash to collect',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-payment-left-to-be-paid.html'
		});
		updateReport.fields.push({
			id: 'tip',
			title: 'Tip',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-tip.html'
		});
		updateReport = _.find(availableReports, { id: 'distances_and_times' });
		updateReport.fields.push({
			id: 'total_price',
			title: '$',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-price.html'
		});
		updateReport.fields.push({
			id: 'left_to_be_paid',
			title: 'Cash to collect',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-payment-left-to-be-paid.html'
		});
		updateReport.fields.push({
			id: 'tip',
			title: 'Tip',
			templateUrl: 'scripts/features/reports/cell_templates/orders_details/task-tip.html'
		});
	}

	if (
		!_.isEmpty(MerchantConfigurations.only_show_report_ids) &&
		MerchantConfigurations.only_show_report_ids.length > 0
	) {
		var sideReports = [];
		_.each(MerchantConfigurations.only_show_report_ids, function (report_id) {
			var report = _.find(availableReports, { id: report_id });
			if (!_.isUndefined(report)) {
				sideReports.push(report);
			}
		});
		availableReports = sideReports;
	}

	return {
		availableReports: availableReports
	};
});
