'use strict';

angular
	.module('bringgApp')
	.controller(
		'MerchantSpecificConfigurationController',
		function (
			$scope,
			MerchantConfigurations,
			Authentication,
			BetaProgramService,
			Application,
			toastr,
			MerchantConfigurationService,
			MerchantService,
			Payments,
			$translate
		) {
			var self = this;
			$scope.data = { plugins: [], enableRouteOptimization: false };
			$scope.enableRouteOptimization = false;

			$scope.current_user = Authentication.currentUser();
			$scope.merchantConfiguration = {};

			$scope.grabModeAlertTypeValues = ['No Alerts', 'Only At Home', 'Always'];
			$scope.grabModeTypeValues = ['Off', 'Driver Grab', 'Broadcast'];

			$scope.dynamicCapacityModeValues = [
				$translate.instant('DYNAMIC_CAPACITY.OFF_OPTION'),
				$translate.instant('DYNAMIC_CAPACITY.ALL_OPTION'),
				$translate.instant('DYNAMIC_CAPACITY.CURRENT_LOAD_OPTION'),
				$translate.instant('DYNAMIC_CAPACITY.EXPECTED_PICKUP_OPTION')
			];

			$scope.uniqueExternalIdRuleValues = [
				$translate.instant('UNIQUE_EXTERNAL_ID_RULE.FALSE'),
				$translate.instant('UNIQUE_EXTERNAL_ID_RULE.AMONG_OPEN_ORDERS'),
				$translate.instant('UNIQUE_EXTERNAL_ID_RULE.AMONG_ALL_ORDERS')
			];

			$scope.uniqueCustomerFieldsOptions = ['external_id', 'phone', 'email'];
			$scope.uniqueInventoryFieldsOptions = ['scan_string', 'external_id', 'name', 'sku'];
			$scope.uniqueUserFieldsOptions = ['email', 'external_id', 'phone'];
			$scope.defaultDispatcherWelcomeUrlIdOptions = [
				'top_menu_dispatch_list',
				'top_menu_planning',
				'top_menu_history',
				'top_menu_drivers',
				'top_menu_reports',
				'top_menu_bi_dashboard',
				'top_menu_support'
			];

			$scope.defaultAddressFormatValue = ['address', 'city', 'state'];
			$scope.addressFormatOptions = [...$scope.defaultAddressFormatValue, 'zipcode', 'address_second_line'];

			var pluginsData = [
				{ id: 'OnlineDrivers', title: 'Online Drivers', active: false },
				{ id: 'TeamDrivers', title: 'Only Drivers Belonging To A Team', active: false },
				{ id: 'OnShiftDrivers', title: 'Drivers On Shift', active: false },
				{ id: 'UnassignedDriversOnly', title: 'Unassigned Drivers Only', active: false },
				{ id: 'driversWithInventory', title: "Drivers With Order's Inventory Only", active: false },
				{ id: 'ClosestDrivers', title: 'Closest Drivers To Order', active: false },
				{
					id: 'UpdateDriverLocationToLastDestination',
					title: 'Update Drivers Current Location To Current Ending Location',
					active: false
				},
				{ id: 'DriversCloseToHome', title: 'Closest Driver To Home', active: false },
				{ id: 'getHighestRatedDrivers', title: 'Order By Highest Rated Drivers', active: false },
				{ id: 'ExternalCall', title: 'Call External API', active: false }
			];

			$scope.getTranslation = function (key) {
				return $translate.instant(key);
			};

			$scope._isRouteOptimizationEnabled = function () {
				return _.some($scope.merchantConfiguration.applications, Application.isRouteOptimizer);
			};

			$scope.updateMerchantSpecificConfiguration = function () {
				var merchantSpecificConfiguration = $scope.merchantConfiguration;

				if (
					(!$scope._isRouteOptimizationEnabled() && $scope.data.enableRouteOptimization) ||
					($scope._isRouteOptimizationEnabled() && !$scope.data.enableRouteOptimization)
				) {
					merchantSpecificConfiguration.route_optimization_options = {
						optimizer: 'RouteOptimizer2',
						enabled: $scope.data.enableRouteOptimization
					};
				}

				// DB address_format format
				/*
				address_format: {
					values: [
						{ key: 'state' },
						{ key: 'zipcode' },
						{ key: 'city' },
						{ key: 'address' }
					]
				};
				*/
				if ($scope.merchantConfiguration.addressFormatField) {
					$scope.merchantConfiguration.address_format = {
						values: $scope.merchantConfiguration.addressFormatField.map(key => ({ key }))
					};
				}

				MerchantConfigurationService.update(merchantSpecificConfiguration)
					.then(function () {
						toastr.success($translate.instant('MERCHANT_SPECIFIC_CONFIGURATION.UPDATES_SAVED_THANK_YOU'));
					})
					.catch(function () {
						toastr.error($translate.instant('MERCHANT_SPECIFIC_CONFIGURATION.FAILED_UPDATING_MERCHANT'));
					});
			};

			this.loadApps = function () {
				$scope.data.enableRouteOptimization = $scope._isRouteOptimizationEnabled();
			};

			this.loadUserFlags = function () {
				Authentication.featureFlags().then(function (featureFlags) {
					$scope.newCsvUpload = Boolean(featureFlags.new_csv_upload);
					$scope.editPrinterType = Boolean(featureFlags.edit_printer_type);
				});
			};

			$scope.toggleGrabMode = function () {
				$scope.grabMode = !$scope.grabMode;
			};

			// we clone the service so changes wont be apply (this is a ui - aka draft screen)
			this.cloneConfig = function () {
				angular.copy(MerchantConfigurations, $scope.merchantConfiguration);
				$scope.selectedGrabModeAlertType = MerchantConfigurations.grab_mode_alert_type;
				$scope.selectedGrabModeType = MerchantConfigurations.grab_mode;
				$scope.selectedDynamicCapacityMode = MerchantConfigurations.dynamic_capacity_values_mode;
				$scope.selectedUniqueExternalIdRule = MerchantConfigurations.unique_external_id_rule;

				// setting defaults if not present
				$scope.merchantConfiguration.unique_customer_fields = MerchantConfigurations.unique_customer_fields || [
					'external_id'
				];
				$scope.merchantConfiguration.unique_inventory_fields =
					MerchantConfigurations.unique_inventory_fields || ['external_id'];
				$scope.merchantConfiguration.unique_user_fields = MerchantConfigurations.unique_user_fields || [
					'external_id'
				];
				$scope.merchantConfiguration.default_dispatcher_welcome_url_id =
					MerchantConfigurations.default_dispatcher_welcome_url_id || 'top_menu_planning';

				$scope.merchantConfiguration.addressFormatField = getAddressFormatFieldValue(
					MerchantConfigurations.address_format
				);

				this.loadUserFlags();
				this.loadApps();
			};

			function init() {
				MerchantConfigurations.$refresh().then(function () {
					self.cloneConfig();
					$scope.$on('config updated', function () {
						self.cloneConfig();
					});
				});
			}

			function getAddressFormatFieldValue(addressFormat) {
				if (Array.isArray(addressFormat)) {
					return addressFormat;
				}

				if (addressFormat?.values) {
					return addressFormat.values.map(({ key }) => key);
				}

				return $scope.defaultAddressFormatValue;
			}

			init();
		}
	);
