'use strict';

angular.module('bringgApp.filters').filter('ccep_number', function ($translate) {
	return function (customer) {
		if (customer.extras && customer.extras.ccep_customer_number) {
			return customer.extras.ccep_customer_number;
		}

		if (customer.external_id) {
			return customer.external_id;
		}

		return $translate.instant('CUSTOMER.NO_CCEP_NUMBER');
	};
});
