'use strict';

angular
	.module('bringgApp')
	.constant('IMAGE_SIZE', {
		DEFAULT: 2040000,
		TAG: 500000,
		CUSTOMER: 64000
	})
	.controller(
		'AddEmployeeController',
		function (
			$scope,
			$uibModalInstance,
			Employee,
			$rootScope,
			dialogSrv,
			FileUploader,
			WEB_API_URL,
			localStorageService,
			Authentication,
			bringg_utils,
			Employees,
			MerchantConfigurations,
			IMAGE_SIZE
		) {
			$scope.user = new Employee();
			$scope.securedPasswordSetup =
				MerchantConfigurations.secured_setup || MerchantConfigurations.enable_secured_user_passwords;

			var uploader = ($scope.image_uploader = new FileUploader({
				withCredentials: true,
				headers: {
					'x-ov-user-email': Authentication.currentUser().email,
					Authorization: localStorageService.get('auth_key')
				}
			}));

			uploader.filters.push(bringg_utils.imageFilter());
			uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.DEFAULT));

			uploader.onAfterAddingFile = function (addedFile) {
				console.info('onAfterAddingAll', addedFile);
				$('#profileImage').triggerHandler('$new_file', addedFile);
			};
			uploader.onProgressItem = function (fileItem, progress) {
				console.info('onProgressItem', fileItem, progress);
			};
			uploader.onProgressAll = function (progress) {
				console.info('onProgressAll', progress);
			};

			$scope.save = function () {
				if (this.newUser.$valid) {
					if ($scope.choice === 'EMAIL' && $scope.user.phone === '') {
						$scope.user.phone = undefined;
					}

					Employee.save($scope.user)
						.then(function (user) {
							if ($scope.image_uploader.queue.length > 0) {
								$scope.image_uploader.queue[0].url =
									WEB_API_URL + '/users/' + user.id + '/profile_image';

								$scope.image_uploader.uploadAll();

								$scope.image_uploader.onCompleteItem = function () {
									Employees.updateEmployee(user);
									dialogSrv.notifyDialog(
										user.name + ' was added successfully',
										'Now you can add additional employees or move on to creating, managing and dispatching orders!'
									);

									$uibModalInstance.close(user);
								};
							} else {
								Employees.updateEmployee(user);
								dialogSrv.notifyDialog(
									user.name + ' was added successfully',
									'Now you can add more employees or continue to create your orders!'
								);

								$uibModalInstance.close(user);
							}
						})
						.catch(function (error) {
							dialogSrv.notifyDialog('Error creating driver', error.message);
						});
				}
			};

			$scope.handleUploadButtonClick = function () {
				angular.element('#uploadMe').trigger('click');
			};

			$scope.close = function () {
				$uibModalInstance.close(undefined);
			};
		}
	);
