'use strict';

angular
	.module('bringgApp')
	.factory(
		'PrinterService',
		function (
			$rootScope,
			$compile,
			$http,
			$timeout,
			$q,
			$window,
			$templateCache,
			$injector,
			MerchantConfigurations,
			Authentication,
			Teams,
			toastr
		) {
			var PrinterService = {};
			var invoiceTemplate = _.template(
				'<<%= invoiceTemplateName %>-task-invoice <%= invoiceParams %> print-callback="printCallback"></<%= invoiceTemplateName %>-task-invoice>'
			);
			var cashOutTemplate = _.template(
				'<<%= cashOutTemplateName %>-cash-out-template <%= cashOutParams %> print-callback="printCallback"></<%= cashOutTemplateName %>-cash-out-template>'
			);

			Authentication.featureFlags().then(function (feature_flags) {
				PrinterService.fastPrint = feature_flags && feature_flags.fast_print;
			});

			PrinterService._printHtml = function (html) {
				return $q(function (resolve) {
					//create a hidden iframe
					var hiddenFrame = $('<iframe></iframe>').appendTo('body')[0];
					//add a function to close and destroy to it
					hiddenFrame.contentWindow.printAndRemove = function () {
						hiddenFrame.contentWindow.print();
						$(hiddenFrame).remove();
					};
					//set up a new html for the directive to be in
					var htmlContent =
						'<!doctype html>' +
						'<html>' +
						'<body onload="printAndRemove();">' +
						html +
						'</body>' +
						'</html>';
					//get the document of the iframe
					var doc = hiddenFrame.contentWindow.document.open('text/html', 'replace');
					//add the html to it
					doc.write(htmlContent);
					//close the it
					doc.close();
					resolve();
				});
			};

			PrinterService._openNewWindow = function (html) {
				var path = {
					templateUrl: 'scripts/features/print/task-invoice.html'
				};

				var newWindow = $window.open(path.templateUrl);
				if (_.isNull(newWindow)) {
					toastr.error('Error opening window, please check your browser settings');
					return;
				}

				newWindow.addEventListener(
					'load',
					function () {
						$(newWindow.document.body).html(html);
					},
					false
				);
			};

			PrinterService._getPrinterType = function () {
				var result;

				if (
					_.isArray(Authentication.currentUser().team_ids) &&
					!_.isEmpty(Authentication.currentUser().team_ids)
				) {
					var team = Teams.fetch(Authentication.currentUser().team_ids[0]);
					result = team && team.printer_type ? team.printer_type : '';
				} else {
					result = MerchantConfigurations.printer_type ? MerchantConfigurations.printer_type : '';
				}
				return result;
			};

			PrinterService._templateParams = function (data) {
				return _.chain(data)
					.keys()
					.map(function (key) {
						return key + '="' + key + '"';
					})
					.join(' ')
					.value();
			};

			PrinterService.printTasksFromDirective = function (data) {
				var templateParams = {
					invoiceTemplateName: MerchantConfigurations.task_invoice_template_name
						? MerchantConfigurations.task_invoice_template_name
						: 'default'
				};

				var printerType = PrinterService._getPrinterType();

				//check that template of the type exists
				if ($injector.has(templateParams.invoiceTemplateName + printerType + 'TaskInvoiceDirective')) {
					templateParams.invoiceTemplateName = templateParams.invoiceTemplateName + printerType;
				}

				data.printed_copies = _.range(MerchantConfigurations.invoice_printed_copies);

				templateParams.invoiceParams = PrinterService._templateParams(data);

				var invoiceTemplateStr = invoiceTemplate(templateParams);
				PrinterService.printFromDirective(data, invoiceTemplateStr);
			};

			PrinterService.printCashOutFromDirective = function (data) {
				var templateParams = {
					cashOutTemplateName: MerchantConfigurations.cash_out_template_name
						? MerchantConfigurations.cash_out_template_name
						: 'default'
				};

				var printerType = PrinterService._getPrinterType();

				//check that template of the type exists
				if ($injector.has(templateParams.cashOutTemplateName + printerType + 'CashOutTemplateDirective')) {
					templateParams.cashOutTemplateName = templateParams.cashOutTemplateName + printerType;
				}

				templateParams.cashOutParams = PrinterService._templateParams(data);

				var cashOutTemplateStr = cashOutTemplate(templateParams);
				PrinterService.printFromDirective(data, cashOutTemplateStr);
			};

			PrinterService.printFromDirective = function (data, template) {
				var templateScope = $rootScope.$new(true);
				angular.extend(templateScope, data, {
					printCallback: function (element) {
						if (PrinterService.fastPrint) {
							PrinterService._printHtml(element.html());
						} else {
							PrinterService._openNewWindow(element.html());
						}
						templateScope.$destroy();
					}
				});

				$compile(template)(templateScope);
			};

			PrinterService.print = function (data) {
				$http.get(data.templateUrl, { cache: $templateCache }).success(function (template) {
					var printScope = $rootScope.$new();
					angular.extend(printScope, data);
					var element = $compile($('<div>' + template + '</div>'))(printScope);
					var waitForRenderAndPrint = function () {
						if (printScope.$$phase || $http.pendingRequests.length) {
							$timeout(waitForRenderAndPrint, 1000);
						} else {
							// Replace printHtml with openNewWindow for debugging
							PrinterService._openNewWindow(element.html());
							//PrinterService._printHtml(element.html());
							printScope.$destroy();
						}
					};
					waitForRenderAndPrint();
				});
			};

			PrinterService.printFromScope = function (templateUrl, scope) {
				$rootScope.isBeingPrinted = true;
				$http.get(templateUrl, { cache: $templateCache }).success(function (template) {
					var printScope = scope;
					var element = $compile($('<div>' + template + '</div>'))(printScope);
					var waitForRenderAndPrint = function () {
						if (printScope.$$phase || $http.pendingRequests.length) {
							$timeout(waitForRenderAndPrint);
						} else {
							PrinterService._printHtml(element.html()).then(function () {
								$rootScope.isBeingPrinted = false;
							});
						}
					};
					waitForRenderAndPrint();
				});
			};

			return PrinterService;
		}
	);
