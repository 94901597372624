/*global google:false */

'use strict';

angular
	.module('bringgApp')
	.controller(
		'BigMapController',
		function (
			$scope,
			Tasks,
			TasksData,
			TasksLoader,
			WayPointsService,
			Employees,
			$timeout,
			SubmitTicketService,
			Task,
			toastr,
			$q,
			NgMap,
			BigMapService,
			localStorageService,
			$rootScope,
			$log,
			Waypoint,
			Waypoints,
			$document,
			$translate,
			ALL_TEAMS_CHOICE_ID,
			NO_TEAMS_CHOICE_ID,
			MerchantConfigurations
		) {
			$scope.selectedTeam = { id: ALL_TEAMS_CHOICE_ID };
			$scope.planning_phase_exists = MerchantConfigurations.planning_phase_exists;
			$scope.wayPointsWithoutGeolocationCount = 0;
			$scope.visibleDriversIds = {};

			$scope.markerClicked = function (marker, task) {
				$scope.currentInfoWindowTask = task;
				$scope.currentInfoWindowTaskDriver = _.find($scope.drivers, { id: task.user_id });

				$scope.currentInfoWindowWayPoint = _.find(task.way_points, { position: 2 });

				$scope.currentInfoWindowTaskOrder =
					_.findIndex($scope.visibleTasksByDrivers[task.user_id], { id: task.id }) + 1;
				$scope.map.showInfoWindow('taskAssignWindow', this);
			};

			$scope.updateDriverVisibility = function (driver) {
				$scope.visibleDriversIds[driver.id] = driver.visible;
			};

			/**
			 * select driver from left side
			 * @param driver
			 */
			$scope.handleDriverClicked = function (driver) {
				if (!driver) {
					return;
				}

				// all drivers
				$scope.visibleTasks = $scope._getVisibleTasksByFilter();

				var driverTasks = _.filter($scope.visibleTasks, { user_id: driver.id });

				$scope.zoomToIncludeMarkers(driverTasks);
			};

			$scope.submitTicket = function () {
				SubmitTicketService.open();
			};

			/**
			 * fit map to visible tasks
			 * @param visibleTasks
			 */
			$scope.zoomToIncludeMarkers = function (visibleTasks) {
				var visibleTasksWithCoordinates = _.filter(_.map(visibleTasks, $scope.getTaskMarkerPosition));
				if (!visibleTasksWithCoordinates.length) {
					return;
				}

				var bounds = new google.maps.LatLngBounds();
				_.each(visibleTasksWithCoordinates, function (coordinates) {
					var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);
					bounds.extend(latLng);
				});

				$scope.map.fitBounds(bounds);
			};

			/**
			 * calculate uniq color for each driver
			 * return object with
			 * {
			 *  'driverId': 'color'
			 * }
			 */
			$scope.calculateColorForEachDriverWithTasks = function () {
				var driverWithTasks = {};

				// leave only those with tasks
				_.each($scope.tasksCountByDrivers, function (tasksCount, driverId) {
					if (tasksCount) {
						driverWithTasks[driverId] = tasksCount;
					}
				});

				var colorsArray = BigMapService.getColors(_.keys(driverWithTasks).length);
				var driverIds = _.keys(driverWithTasks);
				return _.zipObject(driverIds, colorsArray);
			};

			$scope.getMarkerIcon = function (task) {
				var markerWidth = 12;
				var markerHeight = 3 * markerWidth;
				var markerTipWidth = 1;
				var strokeWidth = 2;
				var markerPath = [
					'M',
					markerWidth + markerTipWidth + strokeWidth,
					markerHeight + markerWidth + strokeWidth,
					'c',
					0,
					(-2 * markerHeight) / 3,
					markerWidth,
					(-2 * markerHeight) / 3,
					markerWidth,
					-markerHeight,
					'c',
					0,
					(-4 * markerWidth) / 3,
					-2 * markerWidth - markerTipWidth,
					(-4 * markerWidth) / 3,
					-2 * markerWidth - markerTipWidth,
					0,
					'c',
					0,
					markerHeight / 3,
					markerWidth,
					markerHeight / 3,
					markerWidth,
					markerHeight,
					'z'
				].join(' ');
				var color = $scope.colorByDriverId[task.user_id];
				var taskOrder = _.findIndex($scope.visibleTasksByDrivers[task.user_id], { id: task.id }) + 1;
				var svgStr =
					"<svg xmlns='http://www.w3.org/2000/svg' width='" +
					(2 * markerWidth + markerTipWidth + 2 * strokeWidth) +
					"' height='" +
					(2 * strokeWidth + (markerWidth + markerHeight)) +
					"'>" +
					"<path d='" +
					markerPath +
					"' fill='#" +
					color +
					"' stroke='#444' stroke-width='" +
					strokeWidth +
					"'></path>" +
					"<text x='" +
					(markerWidth + markerTipWidth / 2 + strokeWidth) +
					"' y='" +
					(markerWidth + strokeWidth) +
					"' fill='#" +
					BigMapService.getLabelColor(color) +
					"' text-anchor='middle' alignment-baseline='middle'>" +
					taskOrder +
					'</text>' +
					'</svg>';
				return 'data:image/svg+xml;utf8,' + escape(svgStr);
			};

			$scope.groupTasksByDriversAndSortedByPriority = function (tasks) {
				var tasksByDrivers = _.groupBy(tasks, 'user_id');
				// for each task per drivers, lets sort them by priority
				_.each(tasksByDrivers, function (tasks, driverId) {
					tasksByDrivers[driverId] = _.sortBy(tasks, 'priority');
				});

				return tasksByDrivers;
			};

			/**
			 *
			 * @param tasks
			 */
			$scope.prepareData = function (tasks) {
				$scope.colorByDriverId = $scope.calculateColorForEachDriverWithTasks();
				$scope.tasksByDrivers = $scope.groupTasksByDriversAndSortedByPriority(tasks);
			};

			/**
			 *
			 * @param tasks
			 */
			$scope.prepareVisibleData = function (tasks) {
				$scope.tasksCountByDrivers = _.countBy(tasks, 'user_id');
				$scope.colorByDriverId = $scope.calculateColorForEachDriverWithTasks();
				$scope.visibleTasksByDrivers = $scope.groupTasksByDriversAndSortedByPriority(tasks);
			};

			/**
			 * make list of all tasks without geolocation
			 * @param tasks
			 */
			$scope.prepareTasksWithoutGeolocation = function (tasks) {
				$scope.tasksWithoutGeolocation = _.filter(tasks, function (task) {
					var secondWayPointPosition = $scope.getTaskMarkerPosition(task);

					if (!secondWayPointPosition) {
						return false;
					}
					return !_.isNumber(secondWayPointPosition[0]) || !_.isNumber(secondWayPointPosition[1]);
				});

				_.each($scope.tasksWithoutGeolocation, $scope.initAlertWindowData);
			};

			/**
			 * get visible tasks by filter
			 */
			$scope._getVisibleTasksByFilter = function () {
				var visibleTasks = [];

				switch ($scope.filters.execution.value) {
					case 'open':
						visibleTasks = $scope.openTasks;
						break;

					case 'planning':
						visibleTasks = $scope.planningTasks;
						break;

					case 'all':
						visibleTasks = $scope.openTasks.concat($scope.planningTasks);
				}

				if ($scope.filters.time.value !== 'all') {
					var now = moment().startOf('day');

					visibleTasks = _.filter(visibleTasks, function (task) {
						var scheduledAt = moment(task.scheduled_at).startOf('day');
						var daysDiff = scheduledAt.diff(now, 'day');

						return (
							($scope.filters.time.value === 'today' && daysDiff === 0) ||
							($scope.filters.time.value === 'tomorrow' && daysDiff === 1)
						);
					});
				}

				if ($scope.selectedTeam.id !== ALL_TEAMS_CHOICE_ID) {
					visibleTasks = _.filter(visibleTasks, function (task) {
						if (task && task.team_ids) {
							return _.includes(task.team_ids, $scope.selectedTeam.id);
						}

						return false;
					});
				}

				return visibleTasks;
			};

			$scope.filterBySelectedTeam = function (selectedTeam) {
				$scope.selectedTeam = selectedTeam;
				$scope.taskFilterUpdate();
			};

			/**
			 * tasks filter update
			 */
			$scope.taskFilterUpdate = function (options) {
				options = options || {};

				$scope.prepareData($scope.openTasks.concat($scope.planningTasks));

				$scope.visibleTasks = $scope._getVisibleTasksByFilter();

				$scope.prepareVisibleData($scope.visibleTasks);

				$scope.prepareTasksWithoutGeolocation($scope.visibleTasks);

				if (!options.skipZoom) {
					$scope.zoomToIncludeMarkers($scope.visibleTasks);
				}

				if (options.saveState) {
					localStorageService.set('bigMapSelectedFilterOptionsValue', $scope.filters);
				}
			};

			/**
			 * alerts window handlers
			 */
			$scope.onAlertWindowAssignDriver = function (oldDriverId, driver, task, newPosition) {
				var oldDriver = _.find($scope.drivers, { id: oldDriverId });
				assignTaskToDriver(oldDriver, driver, newPosition - 1, task);

				$scope.initAlertWindowData(task);
			};

			$scope.onAlertWindowReorderTask = function (driverId, oldPosition, newPosition) {
				if (newPosition == oldPosition) {
					return;
				}
				changeTaskPriority(driverId, oldPosition - 1, newPosition - 1);
			};

			$scope.alertWindowData = {};

			$scope.initAlertWindowData = function (task) {
				var destinationWayPoint = _.find(task.way_points, { position: 2 });

				$scope.alertWindowData[task.id] = {
					originalUser: task.user_id,
					wayPoint: destinationWayPoint,
					newUser: _.find($scope.drivers, { id: task.user_id }),
					originalTaskPositionValue:
						(_.findIndex($scope.visibleTasksByDrivers[task.user_id], { id: task.id }) || 0) + 1,
					newTaskPositionValue:
						(_.findIndex($scope.visibleTasksByDrivers[task.user_id], { id: task.id }) || 0) + 1,
					orderMaxValue: ($scope.visibleTasksByDrivers[task.user_id] || []).length
				};
			};

			/***
			 * info window
			 */

			/**
			 * once task is assigned to driver, need to reset priority according to position
			 * @param driver
			 * @param newPriorityPosition
			 * @returns {*}
			 */
			var reoffsetTaskPriorityAfterReassign = function (oldDriverId, driver, newPriorityPosition, task) {
				var oldDriverTasks = oldDriverId ? $scope.tasksByDrivers[oldDriverId] || [] : [];
				var newDriverTasks = driver ? $scope.tasksByDrivers[driver.id] || [] : [];

				// in case we came here before real time updates tasks, lets remove old task from old driver and add to new driver

				var task_id = task.id;

				// find task in old driver task and remove it
				var taskInOldUser = _.find(oldDriverTasks, { id: task_id });
				oldDriverTasks = _.without(oldDriverTasks, taskInOldUser);

				// if task is not yet in new driver tasks, add it
				var newDriverTask = _.find(newDriverTasks, { id: task_id });
				if (!newDriverTask) {
					newDriverTasks.push(task);
				}

				// old driver just got one less task, lets just shrink
				var oldDriverTasksPriorityChanges = BigMapService.getPriorityChanges(oldDriverTasks);
				// new driver reposition and shrink
				var newDriverTasksPriorityChanges = BigMapService.getPriorityChanges(
					newDriverTasks,
					newDriverTasks.length - 1,
					newPriorityPosition
				);

				var tasksPriorityChanges = []
					.concat(oldDriverTasksPriorityChanges)
					.concat(newDriverTasksPriorityChanges);

				return Task.update_priorities({ data: tasksPriorityChanges })
					.$promise.then(function (result) {
						if (result.success) {
							return;
						}
						toastr.error($translate.instant('BIG_MAP.FAILED_TO_SAVE_NEW_ORDER_POSITION'));
					})
					.catch(function () {
						toastr.error($translate.instant('BIG_MAP.FAILED_TO_SAVE_NEW_ORDER_POSITION'));
					});
			};

			var assignTaskToDriver = function (oldDriver, driver, newTaskPriority, task) {
				/*
       very tricky scenario
       userA and userB
       userA tasks, priority = [ {id: 1,priority:1},{id: 2,priority:2},{id: 3,priority:3}
       userB tasks, priority = [ {id: 4,priority:1},{id: 5,priority:2},{id: 6,priority:3}
       task id = 2, if we reassign from userA to userB
       userB will have two task with the same priority
       to prevent this, reset task priority, by other user priority
       */
				return Tasks.assignTask(task, driver.id).then(
					function (result) {
						if (!result.task) {
							$log.info('no task was returned, not reOffSetting task priority');
							return;
						}

						return reoffsetTaskPriorityAfterReassign(oldDriver, driver, newTaskPriority, result.task).then(
							function () {
								$scope.taskFilterUpdate({ skipZoom: true });
								toastr.success('Task updated successfully');
							}
						);
					},
					function (reason) {
						if (reason) {
							toastr.error(reason);
						}
						$scope.currentInfoWindowTask.user_id = oldDriver;
					}
				);
			};

			/**
			 * on info window assign to driver
			 * @param driver
			 */
			$scope.onInfoWindowAssignDriver = function (driver) {
				var oldDriver = $scope.currentInfoWindowTask.user_id;
				$scope.map.hideInfoWindow('taskAssignWindow');

				assignTaskToDriver(
					oldDriver,
					driver,
					$scope.currentInfoWindowTaskOrder - 1,
					$scope.currentInfoWindowTask
				);
			};

			/**
			 *
			 * @param task
			 * @param currentPriorityIndex
			 * @param taskNewOrderPriorityIndex
			 */
			var changeTaskPriority = function (driverId, currentPriorityIndex, taskNewOrderPriorityIndex) {
				var userTasksOrders = $scope.tasksByDrivers[driverId] || [];
				// lets map the visible tasks order to 'all tasks' order
				var visibleUserTasksOrders = $scope.visibleTasksByDrivers[driverId] || [];

				currentPriorityIndex = visibleUserTasksOrders[currentPriorityIndex]
					? _.findIndex(userTasksOrders, { id: visibleUserTasksOrders[currentPriorityIndex].id })
					: 0;
				taskNewOrderPriorityIndex = visibleUserTasksOrders[taskNewOrderPriorityIndex]
					? _.findIndex(userTasksOrders, { id: visibleUserTasksOrders[taskNewOrderPriorityIndex].id })
					: 0;

				var tasksPriorityChanges = BigMapService.getPriorityChanges(
					userTasksOrders,
					currentPriorityIndex,
					taskNewOrderPriorityIndex
				);

				Task.update_priorities({ data: tasksPriorityChanges })
					.$promise.then(function (result) {
						if (result.success) {
							return;
						}
						toastr.error($translate('BIG_MAP.FAILED_TO_SAVE_NEW_ORDER'));
					})
					.catch(function () {
						toastr.error($translate('BIG_MAP.FAILED_TO_SAVE_NEW_ORDER'));
					});
			};

			$scope.destinationAddressChanged = function (taskId, wayPoint, selectedAddress) {
				var logPrefix =
					'BigMapController.destinationAddressChanged: task_id [' +
					taskId +
					'] way_point_id [' +
					wayPoint.id +
					'] new_address [' +
					selectedAddress.formatted_address +
					']';

				if (wayPoint.address === selectedAddress.formatted_address) {
					$log.info(logPrefix + " skipping since address wasn't changed");
					return;
				}

				var updatedAddress = {
					address: selectedAddress.formatted_address,
					lat: selectedAddress.geometry.location.lat(),
					lng: selectedAddress.geometry.location.lng()
				};

				$log.info(logPrefix + ' sending update request for changes: ' + JSON.stringify(updatedAddress));

				Waypoints.update(taskId, wayPoint.id, updatedAddress)
					.then(function (response) {
						$log.info(logPrefix + ' updated successfuly');
						toastr.success($translate.instant('BIG_MAP.TASK_UPDATED_SUCCESSFULLY'));

						// Call taskFilterUpdate in order to re-zoom
						$scope.taskFilterUpdate();
					})
					.catch(function (error) {
						toastr.error($translate.instant('BIG_MAP.COULD_NOT_UPDATE_TASK'));
						$log.error(logPrefix + ' failed, response: ' + error);
					});
			};

			$scope.priorityChangedInBigMap = function (taskNewOrder) {
				var currentPriorityIndex = $scope.currentInfoWindowTaskOrder - 1; // -1 to start from zero
				var taskNewOrderPriorityIndex = taskNewOrder - 1; // same thing

				// nothing to change
				if (currentPriorityIndex == taskNewOrderPriorityIndex) {
					return;
				}
				$scope.map.hideInfoWindow('taskAssignWindow');

				changeTaskPriority(
					$scope.currentInfoWindowTaskDriver.id,
					currentPriorityIndex,
					taskNewOrderPriorityIndex
				);
			};

			/**
			 * end of info window
			 */

			/**
			 * @param task
			 * @returns {*}
			 */
			$scope.getTaskMarkerPosition = function (task) {
				var secondWayPoint = _.find(task.way_points, { position: 2 });
				if (!secondWayPoint) {
					$log.warn(
						'bigMap getTaskMarkerPosition: Task ' + task.id + ' is missing way point with position 2'
					);
					return null;
				}
				return [secondWayPoint.lat, secondWayPoint.lng];
			};

			/*
			 * Load big map filters fom local storage or uses
			 * defaults
			 */
			var loadFilters = function () {
				var filters = localStorageService.get('bigMapSelectedFilterOptionsValue');

				return _.defaults(
					{
						time: _.first($scope.timeFilterOptions),
						execution: _.first($scope.executionFilterOptions)
					},
					filters
				);
			};

			/**
			 * init data
			 */
			var init = function () {
				$scope.map = null;

				$scope.executionFilterOptions = [
					{ name: 'BIG_MAP.PLANNING_AND_OPEN', value: 'all' },
					{ name: 'BIG_MAP.PLANNING_ONLY', value: 'planning' },
					{ name: 'BIG_MAP.OPEN_ONLY', value: 'open' }
				];

				$scope.timeFilterOptions = [
					{ name: 'ORDERS_TIME_FILTER.SHOW_ALL', value: 'all' },
					{ name: 'ORDERS_TIME_FILTER.SCHEDULED_FOR_TODAY', value: 'today' },
					{ name: 'ORDERS_TIME_FILTER.SCHEDULED_FOR_TOMORROW', value: 'tomorrow' }
				];

				$scope.filters = loadFilters();

				TasksLoader.loadOpenForTable();
				TasksLoader.loadPlanningForTable();

				var promises = [Employees.drivers(), NgMap.getMap()];

				$scope.inited = false;
				$q.all(promises).then(function (results) {
					$scope.openTasks = TasksData.getOpenTasks();
					$scope.planningTasks = TasksData.getPlanningTasks();
					$scope.unassignedUser = { name: $translate.instant('BIG_MAP.UNASSIGNED'), id: null };

					// adding unassigned driver as option selection for all unassigned tasks
					$scope.drivers = [].concat(results[0], $scope.unassignedUser);
					$scope.map = results[1];

					$scope.taskFilterUpdate();

					$scope.$on('lazy task list update', function () {
						if (Task.shouldUseNewApi()) {
							if ($scope.openTasks) {
								$scope.openTasks.length = 0;
								Array.prototype.push.apply($scope.openTasks, TasksData.getOpenTasks());
							}

							if ($scope.planningTasks) {
								$scope.planningTasks.length = 0;
								Array.prototype.push.apply($scope.planningTasks, TasksData.getPlanningTasks());
							}
						}

						$scope.taskFilterUpdate({ skipZoom: true });
					});

					$scope.$on('task list update', function () {
						if (Task.shouldUseNewApi()) {
							$scope.openTasks = TasksData.getOpenTasks();
							$scope.planningTasks = TasksData.getPlanningTasks();
						}

						$scope.taskFilterUpdate({ skipZoom: true });
					});

					google.maps.event.addListener($scope.map, 'rightclick', $scope.geolocateHandleRightClick);
				});
			};

			init();

			$rootScope.$on('employees list update', function () {
				Employees.drivers().then(function (employees) {
					// adding unassigned driver as option selection for all unassigned tasks
					$scope.drivers = [].concat(employees, $scope.unassignedUser);
				});
			});

			//==============================================
			// Geolocate tasks by hand
			//==============================================
			$scope.geolocationListStyle = { top: '0px', left: '0px' };
			var geoListXOffset = 125;
			var geoListYOffset = 85;
			$scope.lastGeolocationLatLng = null;
			$scope.handleGeolocateTaskClick = function (task) {
				if (!$scope.lastGeolocationLatLng) {
					return;
				}

				var wp = _.find(task.way_points, { position: 2 });

				if (!wp) {
					return;
				}

				Waypoint.update(
					{ task_id: task.id },
					{ id: wp.id, lat: $scope.lastGeolocationLatLng.lat, lng: $scope.lastGeolocationLatLng.lng },
					function (response) {
						if (response.success) {
							$scope.handleGeolocationCloseClick();
							toastr.success($translate.instant('BIG_MAP.TASK_UPDATED_SUCCESSFULLY'));
						} else {
							toastr.error($translate.instant('BIG_MAP.COULD_NOT_UPDATE_TASK'));
						}
					}
				);
			};

			$scope.getGeolocationListTaskAddress = function (task) {
				var wayPoint2 = WayPointsService.getDropOffWayPoint(task);
				return wayPoint2 && wayPoint2.address;
			};

			$scope.handleGeolocationCloseClick = function () {
				$scope.lastGeolocationLatLng = null;
				$document.off('click', $scope.handleGeolocationCloseClick);
				$timeout(function () {
					$scope.$apply();
				}, 1);
			};

			$scope.geolocateHandleRightClick = function (event) {
				if (!$scope.tasksWithoutGeolocation.length) {
					return;
				}

				$document.on('click', $scope.handleGeolocationCloseClick);

				$scope.lastGeolocationLatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
				$scope.geolocationListStyle.top = event.pixel.y - geoListYOffset + 'px';
				$scope.geolocationListStyle.left = event.pixel.x + geoListXOffset + 'px';
				$scope.$apply();
			};

			$scope.handleListContainerClick = function (event) {
				event.stopPropagation();
			};

			$scope.isMarkerVisible = function (task) {
				if (task.user_id) {
					return $scope.visibleDriversIds[task.user_id];
				}

				return $scope.unassignedUser.visible;
			};
		}
	);
