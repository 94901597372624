'use strict';

angular.module('bringgApp').factory('CustomerAvailabilityHoursService', function (BringgSDK) {
	function customerAvailabilityHoursService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (sdk) {
				return sdk.customerAvailabilityHours[method].apply(sdk.customerAvailabilityHours, args);
			});
		};
	}

	return {
		getTaskCustomerAvailabilityHours: customerAvailabilityHoursService('getTaskCustomerAvailabilityHours')
	};
});
