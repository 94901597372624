/* eslint-disable angular/di-unused */
'use strict';

angular.module('bringgApp.directives').directive('tableIconsLegend', function (TranslationService) {
	return {
		templateUrl: 'scripts/directives/table-icons-legend/table-icons-legend.html',
		scope: {},
		link: function ($scope) {
			$scope.showLegend = false;
			$scope.totalOnShiftTranslation = TranslationService.instant('ICONS_LEGEND.TOTAL_ON_SHIFT');

			$scope.dataArray = [
				{
					icon: 'online-icon',
					title: TranslationService.instant('ICONS_LEGEND.ONLINE') + ' / ' + $scope.totalOnShiftTranslation
				},
				{
					icon: 'offline-icon',
					title: TranslationService.instant('ICONS_LEGEND.OFFLINE')
				},
				{
					icon: 'busy-drivers-icon',
					title:
						TranslationService.instant('ICONS_LEGEND.BUSY_DRIVERS') + ' / ' + $scope.totalOnShiftTranslation
				},
				{
					icon: 'drivers-base-icon',
					title:
						TranslationService.instant('ICONS_LEGEND.BASE_DRIVERS') + ' / ' + $scope.totalOnShiftTranslation
				},
				{
					icon: 'return-to-base-icon',
					title:
						TranslationService.instant('ICONS_LEGEND.RETURN_DRIVERS') +
						' / ' +
						$scope.totalOnShiftTranslation
				},
				{
					icon: 'max-weight-icon',
					title: TranslationService.instant('ICONS_LEGEND.MAX_WEIGHT')
				},
				{
					icon: 'total-price-icon',
					title: TranslationService.instant('ICONS_LEGEND.COST_OF_DELIVERY')
				},
				{
					icon: 'car-icon',
					title: TranslationService.instant('ICONS_LEGEND.VEHICLE')
				},
				{
					icon: 'trailer-icon',
					title: TranslationService.instant('ICONS_LEGEND.TRAILER')
				},
				{
					icon: 'user-icon',
					title: TranslationService.instant('ICONS_LEGEND.DRIVER')
				}
			];

			$scope.toggleLegend = function toggleLegend(show) {
				$scope.showLegend = show;
			};
		}
	};
});
