/**
 * Created by liorsion on 4/26/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('FlowAndBehaviorConfigurationController', function (
		$scope,
		MerchantConfigurations,
		$rootScope,
		toastr,
		MerchantConfigurationService
	) {
		$scope.data = {};

		$scope.merchantConfiguration = MerchantConfigurations;

		$scope.$on('config updated', function () {
			$scope.merchantConfiguration = MerchantConfigurations;
		});

		$scope.updateMerchantConfiguration = function () {
			MerchantConfigurationService.update($scope.merchantConfiguration)
				.then(function () {
					//TODO: update cookiestore merchant info here
					toastr.success('Update saved, thank you');
					//$window.history.back();
				})
				.catch(function (/*data, status, headers, config*/) {
					toastr.error('Failed updating merchant');
				});
		};
	});
