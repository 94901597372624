'use strict';

angular
	.module('bringgApp.directives')
	.directive('customerSelectTeams', function (Customers, MerchantConfigurations, $log) {
		return {
			restrict: 'E',
			require: '',
			templateUrl: 'scripts/directives/customer-select.html',
			transclude: true,
			scope: {
				wayPoint: '=?',
				handleCustomerSelected: '=',
				selectedTeams: '=?',
				className: '@',
				translation: '@',
				searchField: '@',
				required: '=?'
			},
			link: function (scope, element, attrs, ngModel) {
				scope.customers = [];
				scope.stop = false;
				scope.lastLength = 0;
				scope.forceCustomerLinkedToTeam = MerchantConfigurations.force_customer_linked_to_team || false;

				scope._getNewItem = function () {
					return {
						name: scope.lastString,
						id: null,
						isTag: true
					};
				};

				scope.ErrorCallback = function (reason) {
					$log.error(reason);
				};

				scope.filterCustomersByTeam = function (customers) {
					var teamIds = _.map(scope.selectedTeams, 'id');
					if (!_.isEmpty(teamIds)) {
						return _.filter(customers, function (customer) {
							return _.some(customer.team_ids, function (teamId) {
								return _.indexOf(teamIds, teamId) !== -1;
							});
						});
					}

					// do not filter when no team is selected
					return customers;
				};

				scope.refreshCustomers = function (value) {
					scope.lastString = value;

					if (!value || value.length < 3) {
						if (value && value.length > 0) {
							scope.customers = [scope._getNewItem()];
						}
						return;
					}

					if (scope.stop) {
						if (scope.lastLength < value.length) {
							scope.customers[0].name = value;
							return;
						} else {
							scope.stop = false;
						}
					}

					scope.lastLength = value.length;

					var searchParams = {};
					searchParams[scope.searchField] = value;

					Customers.find(searchParams).then(function (data) {
						var customers = scope.forceCustomerLinkedToTeam
							? scope.filterCustomersByTeam(data.results)
							: data.results;

						scope.customers = [scope._getNewItem()].concat(customers || []);
						if (data.results.length === 0) {
							scope.stop = true;
						}
					});
				};
			}
		};
	});
