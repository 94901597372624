'use strict';

angular
	.module('bringgApp.directives')
	.directive('bringgDraggable', function ($rootScope) {
		return {
			restrict: 'A',
			link: function (scope, el) {
				function handleDragStart(e) {
					if (_.isNil(e.dataTransfer)) {
						return;
					}
					e.dataTransfer.setData('dragged-id', e.target.id);
					$rootScope.$emit('BRINGG-DRAG-START', e);
				}

				function handleDragEnd(e) {
					$rootScope.$emit('BRINGG-DRAG-END', e);
					angular.element(e.target).removeClass('bringg-drag-over');
				}

				el[0].addEventListener('dragstart', handleDragStart);
				el[0].addEventListener('dragend', handleDragEnd);

				scope.$on('$destroy', function () {
					el[0].removeEventListener('dragstart', handleDragStart);
					el[0].removeEventListener('dragend', handleDragEnd);
					BringgDragEnd();
				});

				var BringgDragEnd = $rootScope.$on('BRINGG-DRAG-END', function (a, e) {
					angular.element(e.target).removeClass('bringg-drag-target');
					angular.element(e.target).removeClass('bringg-drag-over');
				});
			}
		};
	})
	.directive('bringgDropTarget', function ($rootScope) {
		return {
			restrict: 'A',
			scope: {
				onDrop: '&',
				droppable: '='
			},
			link: function (scope, el) {
				function handleDragOver(e) {
					if (e.preventDefault) {
						e.preventDefault(); // Necessary. Allows us to drop.
					}

					e.dataTransfer.dropEffect = 'move'; // See the section on the DataTransfer object.
					return false;
				}

				function handleDragEnter(e) {
					if (angular.element(e.target).attr('droppable')) {
						angular.element(e.target).addClass('bringg-drag-over');
					} else {
						angular.element(e.target.parentElement).addClass('bringg-not-droppable');
					}
				}

				function handleDragLeave(e) {
					angular.element(e.target).removeClass('bringg-drag-over');
				}

				function handleDrop(e) {
					if (e.preventDefault) {
						e.preventDefault(); // Necessary. Allows us to drop.
					}

					if (e.stopPropagation) {
						e.stopPropagation(); // Necessary. Allows us to drop.
					}

					scope.onDrop({
						dragId: e.dataTransfer.getData('dragged-id'),
						dropId: e.target.id || e.target.parentElement.id
					});
				}

				el[0].addEventListener('dragover', handleDragOver);
				el[0].addEventListener('dragenter', handleDragEnter);
				el[0].addEventListener('dragleave', handleDragLeave);
				el[0].addEventListener('drop', handleDrop);

				scope.$on('$destroy', function () {
					el[0].removeEventListener('dragover', handleDragOver);
					el[0].removeEventListener('dragenter', handleDragEnter);
					el[0].removeEventListener('dragleave', handleDragLeave);
					el[0].removeEventListener('drop', handleDrop);
					BringgDragStart();
					BringgDragEnd();
				});

				var BringgDragStart = $rootScope.$on('BRINGG-DRAG-START', function (a, e) {
					angular.element(e.target).addClass('bringg-drag-target');
				});

				var BringgDragEnd = $rootScope.$on('BRINGG-DRAG-END', function (a, e) {
					angular.element(e.target).removeClass('bringg-drag-target');
					angular.element(e.target).removeClass('bringg-drag-over');
				});
			}
		};
	});
