'use strict';

angular.module('bringgApp').factory('SkillsService', function ($rootScope, BringgSDK) {
	function skillsService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.skills[method].apply(bringg.skills, args);
			});
		}.bind(this);
	}

	var SkillsService = {};
	SkillsService.skillsById = {};

	SkillsService.getAll = skillsService('getAll');
	SkillsService.create = skillsService('create');
	SkillsService.update = skillsService('update');
	SkillsService.delete = skillsService('delete');

	SkillsService.createSkill = function (skillName) {
		return SkillsService.create(skillName);
	};

	SkillsService.getAllSkills = function () {
		var allSkills = SkillsService.getAll();
		return allSkills;
	};

	SkillsService.updateSkill = function (skillId, skillName) {
		return SkillsService.update(skillId, skillName);
	};

	SkillsService.deleteSkill = function (skillId) {
		return SkillsService.delete(skillId);
	};

	SkillsService.setSkillsById = function (skills) {
		SkillsService.skillsById = _.keyBy(skills, 'id');
	};

	return SkillsService;
});
