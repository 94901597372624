'use strict';

function WidgetOpenTasksController($scope, ov_widget) {
	$scope.value = 0;
	$scope.last = 0;
	$scope.difference = 0;
	$scope.moreinfo = '';
	$scope.updatedAtMessage = '';

	ov_widget.registerCallback('WidgetOpenTasksController', 'task:new', 'open_tasks', function (data) {
		if (data.data === null) {
			data.data = 0;
		}
		$scope.updatedAtMessage = '';
		$scope.last = $scope.value;
		$scope.difference = data.data - $scope.value;
		$scope.value = data.data;
	});

	$scope.arrow = function () {
		if ($scope.last) {
			if (parseInt($scope.value) > parseInt($scope.last)) {
				return 'icon-arrow-up';
			} else {
				return 'icon-arrow-down';
			}
		} else {
			return 'icon-arrow-up';
		}
	};
}

WidgetOpenTasksController.$inject = ['$scope', 'ov_widget'];
