'use strict';

angular
	.module('bringgApp.filters')
	.filter('sumInventory', function () {
		return function (inventories) {
			var result = 0;
			_.each(inventories, function (inventory) {
				result += inventory.price * inventory.quantity;
			});
			return result;
		};
	})
	.filter('formatBoolean', function () {
		return function (char) {
			// rails 5 support
			if (char === 't' || char === true) return 'Yes';
			/// rails 5 support
			if (char === 'f' || char === false) return 'No';
		};
	})
	.filter('formatBooleanArray', function () {
		return function (csv_str) {
			if (csv_str == null) return '';

			var human_arr = _.map(csv_str.split(','), function (val) {
				if (val === 't' || val === true) return 'Yes';
				if (val === 'f' || val === false) return 'No';
			}).join();

			return human_arr;
		};
	})
	.filter('dailyEngagementReportTotalOrders', function () {
		return function (orders) {
			return orders.length;
		};
	})
	.filter('dailyEngagementReportAssignedOrders', function () {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.user_id !== null;
			}).true;

			return result !== undefined ? result : 0;
		};
	})
	.filter('dailyEngagementReportAssignedPercentageOrders', function () {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.user_id !== null;
			}).true;

			return result !== undefined ? Math.round((result / orders.length) * 100) : 0;
		};
	})
	.filter('dailyEngagementReportCompletedOrders', function (TASK_STATUS) {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.status === TASK_STATUS.DONE;
			}).true;

			return result !== undefined ? result : 0;
		};
	})
	.filter('dailyEngagementReportCompletedPercentageOrders', function (TASK_STATUS) {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.status === TASK_STATUS.DONE;
			}).true;

			return result !== undefined ? Math.round((result / orders.length) * 100) : 0;
		};
	})
	.filter('dailyEngagementReportCancelledOrders', function (TASK_STATUS) {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.status === TASK_STATUS.CANCELLED;
			}).true;

			return result !== undefined ? result : 0;
		};
	})
	.filter('dailyEngagementReportCancelledPercentageOrders', function (TASK_STATUS) {
		return function (orders) {
			var result = _.countBy(orders, function (order) {
				return order.status === TASK_STATUS.CANCELLED;
			}).true;

			return result !== undefined ? Math.round((result / orders.length) * 100) : 0;
		};
	})
	.filter('dailyEngagementReportTotalDistanceDriver', function (TASK_STATUS) {
		return function (orders) {
			var result = 0;
			_.each(orders, function (order) {
				result += order.distance_traveled;
			});
			return result;
		};
	})
	.filter('dailyEngagementReportTotalManHours', function () {
		return function (shifts) {
			var sum = 0;

			_.each(shifts, function (shift) {
				if (shift.end_shift && shift.start_shift) {
					sum += Math.round(
						moment.duration(moment(shift.end_shift).diff(moment(shift.start_shift))).asHours()
					);
				}
			});

			return sum;
		};
	})
	.filter('day_part_filter', function () {
		return function (time) {
			var localTime = moment(moment.utc(time).toDate());
			var hours = localTime.hour();
			var minutes = localTime.minute();

			if (hours >= 0 && hours < 9) {
				return 'Breakfast';
			}

			if (hours >= 9 && hours < 11) {
				return 'AM Chill';
			}

			if (hours >= 11 && hours < 14) {
				return 'Lunch';
			}

			if (hours >= 14 && hours < 17) {
				return 'PM Chill';
			}

			if (hours >= 17 || (hours > 23 && minutes <= 59)) {
				return 'Dinner';
			}
		};
	})
	.filter('numberPrefixFilter', function ($filter) {
		return function (number, fractionSize) {
			if (fractionSize) {
				number = $filter('number')(number, fractionSize);
			}

			if (number > 0) {
				return '+' + number;
			}

			return number;
		};
	})
	.filter('inventoryHistoryEventTypeFilter', function ($translate) {
		var eventTypesToTranslateKeys = {
			0: 'TASK_INVENTORY.PICKED_UP',
			1: 'TASK_INVENTORY.DROPPED_OFF',
			2: 'TASK_INVENTORY.STAGED'
		};

		return function (eventType) {
			var translateKey = eventTypesToTranslateKeys[eventType];
			return $translate.instant(translateKey || 'TASK_INVENTORY.UNKNOWN');
		};
	})
	.filter('pickup_dropoff_option_filter', function ($translate, PICKUP_DROPOFF_OPTION) {
		var options = PICKUP_DROPOFF_OPTION();
		var optionToTranslateKeys = {};
		optionToTranslateKeys[options.pick_up] = 'ORDER_INVENTORY.PICK_UP';
		optionToTranslateKeys[options.drop_off] = 'ORDER_INVENTORY.DROP_OFF';
		optionToTranslateKeys[options.both] = 'ORDER_INVENTORY.PICK_UP_AND_DROP_OFF';

		return function (eventType) {
			var translateKey = optionToTranslateKeys[eventType];
			return $translate.instant(translateKey || 'TASK_INVENTORY.UNKNOWN');
		};
	})
	.filter('payment_method', function ($translate, PAYMENT_METHODS) {
		return function (method) {
			if (!_.isUndefined(method) && !_.isNull(method)) {
				var methodKey = _.invert(PAYMENT_METHODS)[method] || 'UNKNOWN';
				return $translate.instant('PAYMENT_METHODS.' + methodKey);
			}

			//empty for grid ui grouping
			return '';
		};
	});
