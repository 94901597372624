'use strict';

angular
	.module('bringgApp')
	.factory('MapOverlay', function ($resource, WEB_API_URL) {
		var endPoint = WEB_API_URL + '/map_overlays/';
		return $resource(
			endPoint + ':id',
			{
				id: '@id'
			},
			{
				all: {
					method: 'GET',
					isArray: false,
					url: endPoint
				},
				update: {
					method: 'PATCH',
					url: endPoint + ':id'
				},
				delete: {
					method: 'DELETE',
					url: endPoint + ':id'
				}
			}
		);
	})
	.factory('MapOverlays', function ($rootScope, MapOverlay, MerchantConfigurations, $q) {
		var MapOverlays = {
			mapOverlays: {
				map_overlays: [],
				count: 0
			}
		};

		var findMapOverlaykeyById = function (id) {
			return _.findIndex(MapOverlays.mapOverlays.map_overlays, function (mapOverlay) {
				return mapOverlay.id === id;
			});
		};

		$rootScope.$on('loggedout', function () {
			MapOverlays.reset();
			delete MapOverlays.mapOverlays.$resolved;
		});

		MapOverlays.reset = function () {
			MapOverlays.mapOverlays = {
				map_overlays: [],
				count: 0
			};
		};

		MapOverlays._initMapOverlays = function () {
			if (MerchantConfigurations.enable_map_overlays) {
				MapOverlays.mapOverlays = MapOverlay.all(function (result) {
					if (result.count === 0) {
						MapOverlays.reset();
						MapOverlays.mapOverlays.$resolved = true;
						MapOverlays.mapOverlays.$promise = $q.defer().resolve();
					}
				});
			} else {
				MapOverlays.mapOverlays.$resolved = true;
				MapOverlays.mapOverlays.$promise = $q.defer().resolve();
			}
		};

		$rootScope.$on('config updated', MapOverlays._initMapOverlays);
		MapOverlays._initMapOverlays();

		MapOverlays.add = function (mapOverlayData, cb) {
			var mapOverlay = new MapOverlay(mapOverlayData);
			mapOverlay.$save(function (result) {
				if (result.success) {
					MapOverlays.mapOverlays.map_overlays.push(result.map_overlay);
					MapOverlays.mapOverlays.count++;
				}
				if (cb) {
					cb(result);
				}
			});
		};

		MapOverlays.update = function (mapOverlayData, cb) {
			var mapOverlay = new MapOverlay(mapOverlayData);
			MapOverlay.update({ id: mapOverlay.id }, mapOverlay, function (result) {
				if (result.success) {
					MapOverlays._updateMapOverlay(mapOverlay);
				}
				if (cb) {
					cb(result);
				}
			});
		};

		MapOverlays.delete = function (id, cb) {
			MapOverlay.delete({ id: id }, function (result) {
				if (result.success) {
					var idx = findMapOverlaykeyById(id);
					MapOverlays.mapOverlays.map_overlays.splice(idx, 1);
				}
				if (cb) {
					cb(result);
				}
			});
		};

		MapOverlays._updateMapOverlay = function (mapOverlay) {
			var idx = findMapOverlaykeyById(mapOverlay.id);
			MapOverlays.mapOverlays.map_overlays[idx] = mapOverlay;
		};

		MapOverlays.get = function (id) {
			var idx = findMapOverlaykeyById(id);
			return MapOverlays.mapOverlays[idx];
		};

		MapOverlays.all = function () {
			var cb = getSuccessCallback(arguments);

			if (MapOverlays.mapOverlays.$resolved) {
				if (cb) {
					cb(MapOverlays.mapOverlays);
				}
				return MapOverlays.mapOverlays;
			} else {
				var _self = this;
				MapOverlays.mapOverlays.$promise.then(function () {
					return _self.all(cb);
				});
				return MapOverlays.mapOverlays;
			}
		};

		return MapOverlays;
	});
