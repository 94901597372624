'use strict';

angular
	.module('bringgApp.directives')
	.directive('tasksStatsFooter', function (Employees, DEFAULT_RT_THROTTLE, InProcessOptimization, Authentication) {
		return {
			templateUrl: 'scripts/directives/tasks/footer/tasks-stats-footer.html',
			link: function (scope) {
				scope.onlineDrivers = 0;
				scope.onShiftDrivers = 0;
				scope.optimizationProgress = null;
				scope.showLoadingIndication = Authentication.currentUser().feature_flags.list_footer_inline_spinner;

				/**
				 * calculate stats
				 * @param drivers
				 */
				var calculateStats = function () {
					Employees.drivers({}, function (drivers) {
						var onlineDrivers = 0;
						var onShiftDrivers = 0;

						_.each(drivers, function (driver) {
							if (driver.status.toLowerCase() === 'online') {
								onlineDrivers++;
							}

							if (driver.active_shift_id != null) {
								onShiftDrivers++;
							}
						});

						scope.onlineDrivers = onlineDrivers;
						scope.onShiftDrivers = onShiftDrivers;
					});
				};

				// we dont care if it updates slowly
				var debounceCalculateStats = _.throttle(calculateStats, DEFAULT_RT_THROTTLE * 2, { leading: false });

				/**
				 * on employees list update
				 */
				scope.$on('employees list update', debounceCalculateStats);

				scope.$on('optimization progress', function (event, data) {
					if (InProcessOptimization?.pendingOptimizationUUIDs?.length) {
						scope.optimizationProgress = data.iteration / data.totalIterations;
					}
				});

				scope.$on('optimization done', function () {
					if (_.isEmpty(InProcessOptimization.pendingOptimizationUUIDs)) {
						scope.optimizationProgress = null;
					}
				});

				scope.$on('all optimizations done', function () {
					scope.optimizationProgress = null;
				});

				scope.$on('all optimizations cancelled', function () {
					scope.optimizationProgress = null;
				});

				/**
				 * init load driverss
				 */
				debounceCalculateStats();
			}
		};
	});
