'use strict';

angular
	.module('bringgApp')
	.constant('ALLOWED_UPDATE_ENTITIES', {
		task: {
			service: 'Task',
			method: 'updateTask',
			paramsMethod: function (taskId, entityId, changes) {
				return [taskId, changes];
			}
		},
		waypoint: {
			service: 'Waypoints',
			method: 'update',
			paramsMethod: function (taskId, entityId, changes) {
				return [taskId, entityId, changes];
			}
		}
	})
	.controller('FixOrderController', function (
		$scope,
		$q,
		$uibModalInstance,
		$translate,
		tasks,
		ALLOWED_UPDATE_ENTITIES,
		$injector
	) {
		$scope.close = function () {
			$uibModalInstance.dismiss(null);
		};

		_.each(tasks, function (task) {
			task.resolved = task.invalidated === false;
			task.invalid_reason = _.map(task.invalid_reasons, 'reason').join(' ');
		});

		$scope.tasks = tasks;
		$scope.selectedTask = _.first($scope.tasks);

		$scope.selectTask = function (task) {
			$scope.selectedTask = task;
			_.each(task.invalid_reasons, function (invalidReason) {
				$scope.setInitialValue(invalidReason);
			});
		};

		$scope.getDirectionLabel = function (direction) {
			if (direction) {
				return $translate.instant('ORDER_INVENTORY.PICKED_UP');
			} else {
				return $translate.instant('ORDER_INVENTORY.DROPPED_OFF');
			}
		};

		$scope.disableUpdate = function (task) {
			function isTimeEdited(invalidReason) {
				return moment(invalidReason.field_value).format('HH:mm') === '00:00';
			}

			return _.some(task.invalid_reasons, function (invalidReason) {
				return _.isNil(invalidReason.field_value) || isTimeEdited(invalidReason);
			});
		};

		$scope.setInitialValue = function (invalidReason) {
			if (invalidReason.field_type === 'time_in_seconds') {
				invalidReason.field_value = new Date();
				invalidReason.field_value.setHours(0, 0, 0, 0);
			}
		};

		$scope.getInputType = function (invalidReasonFieldType) {
			return invalidReasonFieldType === 'time_in_seconds' ? 'time' : 'text';
		};

		$scope.getPlaceholder = function (invalidReasonFieldType) {
			return invalidReasonFieldType === 'time_in_seconds' ? 'HH:mm' : '';
		};

		$scope.updateInvalidFields = function (task) {
			async.eachLimit(
				task.invalid_reasons,
				1,
				function (invalidReason, callback) {
					var entityType = invalidReason.entity_type;
					var entityId = invalidReason.entity_id;
					var invalidField = invalidReason.invalid_field;

					var changes = {};
					if (invalidReason.field_type === 'time_in_seconds') {
						changes[invalidField] =
							invalidReason.field_value.getMinutes() * 60 + invalidReason.field_value.getHours() * 3600;
					} else {
						changes[invalidField] = invalidReason.field_value;
					}

					var updateEntity = ALLOWED_UPDATE_ENTITIES[entityType.toLocaleLowerCase()];
					var service = $injector.get(updateEntity.service);
					var method = updateEntity.method;
					var params = updateEntity.paramsMethod(task.id, entityId, changes);

					service[method].apply(service, params).then(callback);
				},
				function () {
					task.resolved = true;
					// if we have another invalid tasks we will show data for the first one.
					var selectedTask = _.first($scope.tasks);
					if (selectedTask) {
						$scope.selectTask(selectedTask);
					}
				}
			);
		};
	});
