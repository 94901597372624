/**
 * Created by liorsion on 5/3/16.
 */
'use strict';

angular.module('merchantLogsServices', ['ngResource']).factory('MerchantLog', function ($resource, WEB_API_URL) {
	var MerchantLog = $resource(
		WEB_API_URL + '/merchant_logs/:id',
		{ id: '@id' },
		{
			all: { method: 'GET', url: WEB_API_URL + '/merchant_logs/' }
		}
	);

	return MerchantLog;
});
