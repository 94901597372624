'use strict';

/**
 * Service to user when you need to lazy load a dependency file.
 */

angular.module('bringgApp.services').factory('LazyLoadJsService', function ($q) {
	var LazyLoadJsService = {};

	LazyLoadJsService.loadFile = function (path) {
		var deferred = $q.defer();

		var head = document.getElementsByTagName('head')[0];
		var script = document.createElement('script');
		script.src = path;
		script.crossorigin = 'anonymous';
		script.onload = function () {
			deferred.resolve();
			script.onload = null;
			script.onerror = null;
			head.removeChild(script);
		};
		script.onerror = function () {
			deferred.reject();
			script.onload = null;
			script.onerror = null;
			head.removeChild(script);
		};
		head.appendChild(script);

		return deferred.promise;
	};

	return LazyLoadJsService;
});
