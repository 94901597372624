'use strict';

angular
	.module('bringgApp')
	.factory('DispatcherPrintService', function (
		$interpolate,
		$window,
		$timeout,
		$rootScope,
		$filter,
		Employees,
		Authentication,
		$location,
		localStorageService,
		Tasks,
		toastr,
		$q
	) {
		var DispatcherPrintService = {};

		DispatcherPrintService._getWayPointData = function (task, wayPointId) {
			var notes = _.filter(task.task_notes, function (note) {
				return note.way_point_id === wayPointId && note.type === 'TaskNote';
			});
			var inventories = _.filter(task.task_inventories, function (inventory) {
				return inventory.way_point_id === wayPointId;
			});

			return { inventories: inventories, notes: notes };
		};

		/**
		 *
		 * The function returns tasks grouped by teams and drivers
		 *
		 * @param scope
		 * @returns {Object}
		 * @private
		 */
		DispatcherPrintService._extractData = function (tasks) {
			var resultObject = {};
			var tasksCollection = angular.copy(tasks);

			//group by user -> teams -> tasks
			_.each(tasksCollection, function (task) {
				if (task.user_id) {
					resultObject[task.user_id] = resultObject[task.user_id] || {};

					//insert tasks by team(loop for tasks with more than 1 team)
					_.each(task.team_ids, function (teamId) {
						resultObject[task.user_id][teamId] = resultObject[task.user_id][teamId] || [];
						resultObject[task.user_id][teamId].push(task);
					});

					//format waypoint data
					_.each(task.way_points, function (wayPoint) {
						wayPoint.wpData = DispatcherPrintService._getWayPointData(task, wayPoint.id);
					});
				}
			});

			// sort all the tasks by priority descending
			_.each(resultObject, function (driver, driverId) {
				_.each(driver, function (tasks, teamId) {
					resultObject[driverId][teamId] = _.sortBy(tasks, 'priority');
				});
			});

			//add driver name and teamIds to the driver object
			return Employees.allEmployees.then(function (employees) {
				_.each(resultObject, function (driver, driverId) {
					var user = _.find(employees, { id: parseInt(driverId) });

					driver.teamIds = _.keys(driver).sort();
					driver.name = user.name;
					driver.jobDescription = user.job_description;
				});

				return resultObject;
			});
		};

		DispatcherPrintService.handlePrintClicked = function (tasks) {
			var taskIds = _.map(tasks, 'id');
			var removeInventoryFromPrint = Authentication.currentUser().feature_flags
				.remove_inventories_from_print_schedule;

			$q.all([Tasks.batchGet(taskIds).then(DispatcherPrintService._extractData)]).then(function (data) {
				localStorageService.set('dispatchListPrintData', data[0]);
				localStorageService.set('removeInventoryFromPrint', Boolean(removeInventoryFromPrint));

				var path = $location.protocol() + '://' + $location.host() + ':' + $location.port();
				var pwindow = $window.open(
					path + '/#/dispatcher-list-print/',
					'_blank',
					'width=1084,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
				);
				if (_.isNull(pwindow)) {
					localStorageService.remove('dispatchListPrintData');
					localStorageService.remove('removeInventoryFromPrint');
					toastr.error('Error opening new window, please check your browser settings');
					return;
				}
				pwindow.focus();
			});
		};

		return DispatcherPrintService;
	});
