'use strict';
/*global ov_widget:false */

// angular.module('bringgApp')
// .controller('WidgetsAverageTimeOnSiteCtrl', ['$scope', '$rootScope', 'Authentication',
function WidgetsAverageTimeOnSiteCtrl($scope, $rootScope, Authentication, ov_widget, $routeParams) {
	$scope.value = 0;

	$rootScope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
		if (isLoggedIn) {
			ov_widget.registerCallback(
				'WidgetOpenTasksController',
				'merchant:' + Authentication.currentUser().merchant.id + ':avg_time_on_site',
				'avg_time_on_site ' + $routeParams.id,
				function (data) {
					if (data.data === null) {
						return;
					}

					var currentdate = new Date();
					$scope.updatedAtMessage =
						currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds();

					$scope.value = data.data;
				}
			);
		}
	});
}
// ]);

WidgetsAverageTimeOnSiteCtrl.$inject = ['$scope', '$rootScope', 'Authentication', 'ov_widget', '$routeParams'];
