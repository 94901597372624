'use strict';

angular
	.module('bringgApp')
	.constant('ERROR_CODE', {
		NO_DRIVER_TYPE_PASSED: 1,
		NO_ROUTE_START_IN_DRIVER_SHIFT: 2,
		NO_DRIVER_PASSED: 3,
		NO_DRIVER_TYPES_PASSED: 4,
		NO_TASKS_PASSED: 5,
		NO_ROUTES_FOR_CALCULATE_ETA: 6,
		NO_DROP_OFF_FOR_PICK_UP_TASK: 7,
		NO_PICK_UP_AND_DROP_OFF_FOR_TASK: 8,
		INVALID_ROUTE_START_LOCATION: 9,
		INVALID_TIME_WINDOW_ON_BREAK: 10,
		INVALID_BREAK_DURATION_LESS_THEN_ZERO: 11,
		INVALID_DRIVERS_LOCATION: 12,
		INVALID_TASK_PRIORITY: 13,
		INVALID_TIME_WINDOW_ON_WAY_POINT: 14,
		INVALID_COORDINATE: 15,
		INVALID_RUN_ID: 16,
		ABNORMAL_REQUEST: 17,
		INVALID_VEHICLE_DIMENSIONS: 18,
		INVALID_TASK_DIMENSIONS: 19,
		INVALID_DRIVER_TIME_WINDOW: 20,
		INVALID_DRIVERS_QUANTITY: 21,
		INVALID_ETOS: 22
	})
	.directive('optimizationTimeLine', function () {
		return {
			replace: true,
			restrict: 'E',
			templateUrl: 'scripts/directives/optimization_time_line/optimization-time-line.html',
			controller: 'OptimizationTimeLineController'
		};
	})
	.controller(
		'OptimizationTimeLineController',
		function (
			$rootScope,
			$scope,
			$document,
			$timeout,
			Optimization,
			Tasks,
			$translate,
			$window,
			OptimizationPreviewService,
			localStorageService,
			OPTIMIZATION_TYPES,
			toastr,
			$log,
			Authentication,
			TimeManagerService,
			ERROR_CODE,
			ScopeApplier,
			WayPointsService
		) {
			$scope.sharedData = OptimizationPreviewService.sharedData;
			$scope.byUserType = OptimizationPreviewService.optimizationType === OPTIMIZATION_TYPES.ANONYMOUS;
			$scope.isUnamed =
				OptimizationPreviewService.optimizationType === OPTIMIZATION_TYPES.ROUTE_BASED ||
				OptimizationPreviewService.optimizationType === OPTIMIZATION_TYPES.ANONYMOUS;
			$scope.recalculateEtaRequests = [];
			$scope.recalculateEtaInProgress = false;
			var ACTUAL_BREAK = 'actual_break';
			var BREAK = 'break';
			var _lastOriginIndex;
			var _lastTargetIndex;
			var _lastMovedTaskId;
			var _isManualOptimization = Object.keys($scope.routeInfoByIndex).length === 0;

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.enableRecalculateEta = featureFlags.recalculate_eta_in_preview_map || false;
			});

			//types of the scales that are displayed on the timeline
			$scope.scale = {
				data: localStorageService.get('optimizationTimeLinePreset') || { value: 1, label: '10 minutes' },
				options: [
					{ value: 1, label: $translate.instant('OPTIMIZATION_TIME_LINE.TEN_MINUTES') },
					{ value: 2, label: $translate.instant('OPTIMIZATION_TIME_LINE.THIRTY_MINUTES') },
					{ value: 3, label: $translate.instant('OPTIMIZATION_TIME_LINE.SIXTY_MINUTES') }
				],
				config: {
					1: {
						range: 144,
						minutes: 10,
						className: 'ten-minutes'
					},
					2: {
						range: 48,
						minutes: 30,
						className: 'half-an-hour'
					},
					3: {
						range: 24,
						minutes: 60,
						className: 'hour'
					}
				}
			};

			//watch the user changing the scale and update the UI according to it
			$scope.$watch('scale.data', function (newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}
				$scope.reRenderTimeLine();
			});

			$scope.reRenderTimeLine = function () {
				$timeout(function () {
					$scope.timeLineData = $scope._createTimeLineData($scope.scale.config[$scope.scale.data.value]);
					$scope.scaleClassName = $scope.scale.config[$scope.scale.data.value].className;
					localStorageService.set('optimizationTimeLinePreset', $scope.scale.data);
					calculateTasksTimeFramesData();
				});
			};

			//this function creates the timeline elements based on the selected scale
			$scope._createTimeLineData = function (describer) {
				return _.chain(_.range(0, describer.range))
					.map(function (index) {
						var hour = parseInt((index * describer.minutes) / 60);
						var minutes = parseInt((index * describer.minutes) % 60);
						var date = new Date();
						date.setHours(hour);
						date.setMinutes(minutes);
						//each item describes the time column. and it is used while drawing the grid.
						return {
							uuid: _.uniqueId(),
							index: index,
							hour: hour,
							minutes: minutes,
							label: minutes === 0 ? TimeManagerService.format(moment(date), 'hh:mmA') : ''
						};
					})
					.valueOf();
			};

			//create the timeline based on the scale
			$scope.timeLineData = $scope._createTimeLineData($scope.scale.config[$scope.scale.data.value]);
			$scope.scaleClassName = $scope.scale.config[$scope.scale.data.value].className;

			//get the new priority data for a list of tasks
			//first sort them by scheduled at of the second wp
			//then create a new priority for them
			$scope._updateTaskPriorityChanges = function (tasks, currentBreak) {
				var priority = 1;

				var localTasks;

				if (currentBreak) {
					localTasks = tasks.concat([Object.assign({ isBreak: true }, currentBreak)]);
				} else {
					localTasks = tasks;
				}

				_.chain(localTasks)
					.filter(function (task) {
						return task.isBreak === true || WayPointsService.getDropOffWayPoint(task) || false;
					})
					.sortBy(function (item) {
						if (item.isBreak) {
							var date = new Date(item.estimated_start_time * 1000);
							return date.getMinutes() + date.getHours() * 60;
						} else {
							var date = new Date(WayPointsService.getDropOffWayPoint(item).scheduled_at);
							return date.getMinutes() + date.getHours() * 60;
						}
					})
					.each(function (task) {
						if (task.isBreak) {
							currentBreak.position = priority;
						} else {
							task.priority = priority;
						}

						priority++;
					})
					.valueOf();
			};

			$scope.dropped = function (dragId, dropId) {
				var dragEl = angular.element('#' + dragId);
				var dropEl = angular.element('#' + dropId);
				var originTaskId = parseInt(dragEl.find('.time-line-task').attr('task-id'));
				if (!originTaskId) return;

				$scope.sharedData.changed = true;
				var drags = [dragEl];

				var originRouteIdx = dragEl.attr('route-idx');
				var targetRouteIdx = dropEl.attr('route-idx');
				if (!targetRouteIdx) return;

				//update changedRoutes for specific reloading
				$scope.changedRoutes = { originRouteIdx: originRouteIdx, targetRouteIdx: targetRouteIdx };

				var originDriver = $scope.sharedData.data[originRouteIdx];
				var targetRoute = $scope.sharedData.data[targetRouteIdx];
				var originDriverTasksSortedByPriority = _.sortBy(originDriver.tasks, 'priority');
				var originalTaskIndex = _.findIndex(originDriverTasksSortedByPriority, function (task) {
					return task.id === originTaskId;
				});

				var tasks = [_.find(originDriver.tasks, { id: originTaskId })];
				var teamId = _.first(_.first(tasks).team_ids);

				var destinationTaskIndex,
					originDriverNextTaskId,
					isSameRoute = originRouteIdx === targetRouteIdx;
				var destinationUserTypeId = targetRoute && targetRoute.user.user_type_id;
				var originUserTypeId = originDriver.userTypeId;

				_lastOriginIndex = originRouteIdx;
				_lastTargetIndex = targetRouteIdx;
				_lastMovedTaskId = originTaskId;

				//if the task was moved between two user_types
				//1 need to move the actual task
				//2 need to update the colors and stuff
				if (!isSameRoute) {
					// if task moved between drivers, we take next task id, to  know it original position before he was removed from the list
					var originDriverNextTask =
						originDriverTasksSortedByPriority[originalTaskIndex + 1] ||
						originDriverTasksSortedByPriority[originalTaskIndex - 1];

					originDriverNextTaskId = originDriverNextTask && originDriverNextTask.id;

					if ($scope.type === OPTIMIZATION_TYPES.NAMED_PDP && tasks[0].linked_task_id) {
						//if the task has a linked task we need to reassign them both
						var linkedTask = _.find(originDriver.tasks, { id: tasks[0].linked_task_id });

						if (linkedTask) {
							tasks.push(linkedTask);
							drags.push($('div').find('[task-id="' + linkedTask.id + '"]'));

							var linkedTaskDragIndex = drags[1].parent().attr('data-td-index');
							var linkedTaskDropElement = dropEl
								.parent()
								.children('[data-td-index=' + linkedTaskDragIndex + ']');

							drags[1].detach();
							linkedTaskDropElement.append(drags[1]);
						}
					}
					var firstTaskOfList = _.first(targetRoute.tasks);
					var newDriverIdx =
						firstTaskOfList && !_.isNull(firstTaskOfList.driver_idx)
							? _.first(targetRoute.tasks).driver_idx
							: null;

					//update the tasks
					_.each(tasks, function (task) {
						var taskIndex = originDriver.tasks.indexOf(task);
						originDriver.tasks.splice(taskIndex, 1);
						task.route_idx = targetRouteIdx;
						task.user_id = targetRoute.user.id;
						task.driver_idx = newDriverIdx;
						task.user_type_id = targetRoute.user.type_id;
						targetRoute.tasks.push(task);
					});
				}

				//update the task scheduled at, updating only hours and minutes
				var droppedTimeLineIndex = dropEl.attr('time-line-index');
				var droppedTimeLineDatum = $scope.timeLineData[droppedTimeLineIndex];

				var wp = WayPointsService.getDropOffWayPoint(tasks[0]);
				var oldSchedule = new Date(wp.scheduled_at);
				oldSchedule.setHours(droppedTimeLineDatum.hour);
				oldSchedule.setMinutes(droppedTimeLineDatum.minutes);
				wp.scheduled_at = oldSchedule.toISOString();
				wp.etl = $scope.getEtlByTimeOnSite(wp);

				//update the tasks priorities of the user_type
				$scope._updateTaskPriorityChanges(targetRoute.tasks, targetRoute.break);
				$scope._updateTaskPriorityChanges(originDriver.tasks, originDriver.break);
				//sort tasks by priority after priority update
				originDriverTasksSortedByPriority = _.sortBy(originDriver.tasks, 'priority');

				if ($scope.enableRecalculateEta) {
					var driverId = originDriver.user.primary_driver_id || originDriver.user.id;

					if ($scope.isUnamed) {
						driverId = originUserTypeId;
					}
					//check whether task was moved in same route
					if (isSameRoute) {
						//find new index of task id
						destinationTaskIndex = _.findIndex(originDriverTasksSortedByPriority, function (task) {
							return task.id === originTaskId;
						});

						//check which index smaller and use it task id
						if (originalTaskIndex < destinationTaskIndex) {
							$scope.createRecalculateEtaRequest(
								originDriverTasksSortedByPriority,
								driverId,
								teamId,
								originDriverTasksSortedByPriority[originalTaskIndex].id
							);
						} else {
							$scope.createRecalculateEtaRequest(
								originDriverTasksSortedByPriority,
								driverId,
								teamId,
								originTaskId
							);
						}
					} else {
						if (originDriverNextTaskId) {
							$scope.createRecalculateEtaRequest(
								originDriverTasksSortedByPriority,
								driverId,
								teamId,
								originDriverNextTaskId
							);
						}

						driverId = $scope.isUnamed
							? destinationUserTypeId
							: targetRoute.user.primary_driver_id || targetRoute.user.id;
						$scope.createRecalculateEtaRequest(
							_.sortBy($scope.sharedData.data[targetRouteIdx].tasks, 'priority'),
							driverId,
							teamId,
							originTaskId
						);
					}

					$scope.executeEtaRequests(teamId);
				}

				//set to changed as it will enable the apply button in the UI
				$scope.sharedData.changed = true;

				if (!$scope.enableRecalculateEta) {
					$scope._recalculateDirections($scope.changedRoutes);
					$scope.updateUILabels();
					$scope.reRenderTimeLine();
					$rootScope.$broadcast('update preview map', $scope.changedRoutes);
				}
			};

			$scope.getEtlByTimeOnSite = function (wayPoint) {
				if (wayPoint.scheduled_at && wayPoint.temp_time_on_site) {
					return new Date(
						new Date(wayPoint.scheduled_at).getTime() + wayPoint.temp_time_on_site
					).toISOString();
				}
				return wayPoint.etl;
			};

			$scope.createRecalculateEtaRequest = function (tasks, driverId, teamId, taskId) {
				var options = {};

				if (_.first(tasks).id === taskId) {
					options.isFirstTask = true;
				}

				var tasksNeededToRecalculate = $scope._getTasksToRecalculate(tasks, taskId);
				var request = $scope._buildRequestObjectForDriver(tasksNeededToRecalculate, driverId, options);

				if (request.tasks.length) {
					$scope.recalculateEtaRequests.push(request);
				}
			};

			$scope._getTasksToRecalculate = function (tasks, taskId) {
				var taskIndex = _.findIndex(tasks, function (task) {
					return task.id === taskId;
				});

				if (taskIndex > 0) {
					taskIndex--;
				}

				return _.drop(tasks, taskIndex);
			};

			$scope.insertBreakInRequest = function (tasks, driverBreak, localTasks) {
				var startTime = driverBreak.estimated_start_time * 1000;

				var tempTaskList = _.map(tasks, function (task) {
					var fullTask = _.find(localTasks, function (localTask) {
						return localTask.id === task.task_id;
					});

					//in other route
					if (!fullTask) {
						fullTask = $scope.getTaskFromAllTasks(task.task_id);
					}

					var dropOffWayPoint = WayPointsService.getDropOffWayPoint(fullTask);

					return {
						task_id: task.task_id,
						way_point_id: task.way_point_id,
						eta: moment(dropOffWayPoint.scheduled_at).valueOf()
					};
				});

				tempTaskList.push({
					task_id: BREAK,
					eta: moment(startTime).valueOf(),
					duration: driverBreak.scheduled_duration,
					estimated_end_time: driverBreak.estimated_end_time
				});

				return _.sortBy(tempTaskList, 'eta');
			};

			$scope._buildRequestObjectForDriver = function (taskList, driverId, options) {
				var firstTaskToRecalculate = _.first(taskList);
				var firstTaskWayPoint = WayPointsService.getDropOffWayPoint(firstTaskToRecalculate);

				var data = $scope.getDataByUser(driverId);
				var driverBreak = _.get(data, 'break');

				if (!options.isFirstTask) {
					taskList.shift();
				}

				var requestObject = {
					driver_id: driverId,
					tasks: taskList.map(function (task) {
						return {
							task_id: task.id,
							way_point_id: WayPointsService.getDropOffWayPoint(task).id
						};
					})
				};

				var result;
				if (driverBreak) {
					result = $scope.insertBreakInRequest(requestObject.tasks, driverBreak, data.tasks);
				}

				if (!options.isFirstTask) {
					var firstItem = _.first(result);

					if (result && firstItem.task_id === BREAK) {
						requestObject = _.extend(requestObject, {
							start: moment(firstItem.estimated_end_time * 1000)
								.toDate()
								.toISOString(),
							start_location: {
								id: 'recalculate_eta',
								lat: firstTaskWayPoint.lat,
								lng: firstTaskWayPoint.lng
							}
						});

						result.shift();
					} else {
						requestObject = _.extend(requestObject, {
							start: firstTaskWayPoint.etl || firstTaskWayPoint.scheduled_at,
							start_location: {
								id: 'recalculate_eta',
								lat: firstTaskWayPoint.lat,
								lng: firstTaskWayPoint.lng
							}
						});
					}
				}

				if (driverBreak && result) {
					requestObject.tasks = result;
				}

				$log.info(
					new Date().toISOString() +
						' preview map recalculat eta sending start location - task_id:' +
						firstTaskToRecalculate.id +
						' way_point_id:' +
						firstTaskWayPoint.id +
						' lat' +
						firstTaskWayPoint.lat +
						' lng' +
						firstTaskWayPoint.lng
				);

				return requestObject;
			};

			$scope.executeEtaRequests = function (teamId) {
				if ($scope.recalculateEtaRequests.length) {
					var optimizationResponse = _.find(OptimizationPreviewService.optimizationResponses, {
						team_id: teamId
					});
					var optimizationRequest = _.find(OptimizationPreviewService.optimizationRequests, {
						team_id: teamId
					});
					var request_uuid = null;
					var taskIds = [];

					if (optimizationResponse) {
						request_uuid = optimizationResponse.request_uuid;
					}

					//user better values to optimization
					optimizationRequest.driver_ids = _.map($scope.recalculateEtaRequests, 'driver_id');
					$scope.recalculateEtaRequests.forEach(function (etaRequest) {
						taskIds = taskIds.concat(_.map(etaRequest.tasks, 'task_id'));
					});
					optimizationRequest.task_ids = _.filter(taskIds, function (taskId) {
						return taskId !== 'break';
					});

					if ($scope.isUnamed) {
						optimizationRequest.optimization_type = OptimizationPreviewService.optimizationType;
					} else {
						optimizationRequest.optimization_type = OPTIMIZATION_TYPES.NAMED_VRP;
					}

					var request = Object.assign({}, optimizationRequest, {
						routes: $scope.recalculateEtaRequests,
						request_uuid: request_uuid
					});
					Optimization.recalculateEta(request);
					$scope.recalculateEtaRequests = [];
				}
			};

			$scope.rePaintPreviewMap = function () {
				$scope._recalculateDirections($scope.changedRoutes);
				$scope.updateUILabels();
				$scope.reRenderTimeLine();

				$rootScope.$broadcast('update preview map', $scope.changedRoutes);
			};

			$scope.getTimelineTitle = function () {
				if ($scope.byUserType && !$scope.displayByRouteName.value) {
					return $translate.instant('INTERACTIVE_MAP.BY_USER_TYPES');
				}

				if ($scope.displayByRouteName.value) {
					return $translate.instant('INTERACTIVE_MAP.BY_ROUTES');
				}

				return $translate.instant('INTERACTIVE_MAP.BY_DRIVERS');
			};

			$scope.getDataByUser = function (userId) {
				var currentData;

				_.each($scope.sharedData.data, function (currentItem) {
					if (currentItem.user && currentItem.user.id === userId) {
						currentData = currentItem;
					}
				});

				return currentData;
			};

			$scope.updateBreak = function (etaResponse) {
				var routeBreaks = _.filter(etaResponse, function (item) {
					return item.type === ACTUAL_BREAK;
				});

				_.each(routeBreaks, function (routeBreak) {
					if (routeBreak) {
						// sharedData keeps key run id but we get after named optimization only user_id on break, so we need to find the break belongs to the user
						var currentData = $scope.getDataByUser(routeBreak.user_id);

						currentData.break = routeBreak;
					}
				});
			};

			$scope.updatePrioritiesForAllRoutes = function (etaResponse) {
				var routes = _.filter(etaResponse, function (item) {
					return item.type === 'route_info';
				});

				_.each(routes, function (route) {
					var currentData = $scope.getDataByUser(route.user_id);
					$scope._updateTaskPriorityChanges(currentData.tasks, currentData.break);
				});
			};

			$scope.handleRecalculateEtaDone = function (event, etaRecalculateResult) {
				if (etaRecalculateResult.success) {
					$scope.updateTasksWayPointsNewEta(etaRecalculateResult.result);
					$scope.updateBreak(etaRecalculateResult.result);
					$scope.recalculateEtaInProgress = false;
					$timeout(function () {
						$scope.updatePrioritiesForAllRoutes(etaRecalculateResult.result);
						updateOriginalRouteInfos(etaRecalculateResult.result);
						$scope.rePaintPreviewMap();
						ScopeApplier.safeApply($scope);
					}, 50);
				} else {
					clearLastRouteIndexes();
					var errors = etaRecalculateResult.result;

					if (_.isString(errors)) {
						toastr.error(errors);
					} else if (errors.length === 1) {
						var errorCode = _.first(errors).error_code;
						var errorPrefix = 'OPTIMIZATION_TIME_LINE.' + _.invert(ERROR_CODE)[errorCode].toUpperCase();
						toastr.error($translate.instant(errorPrefix));
					} else {
						_.forEach(errors, function (error) {
							var errorCode = error.error_code;
							var errorPrefix = 'OPTIMIZATION_TIME_LINE.' + _.invert(ERROR_CODE)[errorCode].toUpperCase();
							toastr.error($translate.instant(errorPrefix));
						});
					}
					$log.warn('Cannot recalculate eta', etaRecalculateResult.result);
					$scope.recalculateEtaInProgress = false;
				}
			};

			$scope.updateTasksWayPointsNewEta = function (etaRecalculateResult) {
				_.each(etaRecalculateResult, function (result) {
					var taskId = result.task_id;

					if (taskId) {
						var task = $scope.getTaskFromAllTasks(taskId);
						var wp = WayPointsService.getDropOffWayPoint(task);
						wp.scheduled_at = moment(result.eta * 1000)
							.toDate()
							.toISOString();
						wp.etl = moment(result.etl * 1000)
							.toDate()
							.toISOString();

						//set has_to_leave to the new first prioritized task
						if (result.priority === 1) {
							wp.has_to_leave = result.has_to_leave;
						}
					}
				});
			};

			function updateOriginalRouteInfos(etaRecalculateResult) {
				var routesFullyOptimized = getFullyOptimizedRoutes(etaRecalculateResult);
				var targetRouteInfo;
				var sourceRouteInfo = undefined;
				var taskWasMovedInSameRoute = _lastTargetIndex === _lastOriginIndex;

				if (taskWasMovedInSameRoute) {
					targetRouteInfo = etaRecalculateResult.find(function (row) {
						return row.type === 'route_info';
					});
				} else {
					var targetTaskRow = etaRecalculateResult.find(function (row) {
						return row.type === 'task' && row.task_id === _lastMovedTaskId;
					});

					if (targetTaskRow) {
						targetRouteInfo = etaRecalculateResult.find(function (row) {
							return row.type === 'route_info' && row.route_idx === targetTaskRow.route_idx;
						});

						sourceRouteInfo = etaRecalculateResult.find(function (row) {
							return row.type === 'route_info' && row.route_idx !== targetTaskRow.route_idx;
						});
					}
				}

				updateOriginalRouteInfo(targetRouteInfo, _lastTargetIndex, routesFullyOptimized);
				updateOriginalRouteInfo(sourceRouteInfo, _lastOriginIndex, routesFullyOptimized);
				clearLastRouteIndexes();
			}

			function getFullyOptimizedRoutes(etaRecalculateResult) {
				var routesFullyOptimized = {};

				var lastBreakBeforeFirstTaskByRouteIndex = etaRecalculateResult
					.filter(result => result.type === ACTUAL_BREAK)
					.sort((rowA, rowB) => rowA.position - rowB.position)
					.reduce((breaks, actualBreak) => {
						if (actualBreak.position === 1) {
							breaks[actualBreak.route_idx] = actualBreak;
						} else if (
							breaks[actualBreak.route_idx] &&
							breaks[actualBreak.route_idx].position === actualBreak.position - 1
						) {
							breaks[actualBreak.route_idx] = actualBreak;
						}

						return breaks;
					}, {});

				_.each(etaRecalculateResult, function (result) {
					var taskId = result.task_id;

					if (taskId) {
						var task = $scope.getTaskFromAllTasks(taskId);
						var actualBreak = lastBreakBeforeFirstTaskByRouteIndex[result.route_idx];

						if (task.priority === 1 || (actualBreak && actualBreak.position === task.priority - 1)) {
							routesFullyOptimized[result.route_idx] = true;
						}
					}
				});

				return routesFullyOptimized;
			}

			function clearLastRouteIndexes() {
				_lastOriginIndex = undefined;
				_lastTargetIndex = undefined;
				_lastMovedTaskId = undefined;
			}

			function updateOriginalRouteInfo(newRouteInfo, originRouteIndex, routesFullyOptimized) {
				if (!newRouteInfo) {
					return;
				}

				var originalRouteInfo = $scope.routeInfoByIndex[originRouteIndex];

				if (!originalRouteInfo) {
					originalRouteInfo = { type: 'route_info' };
					$scope.routeInfoByIndex[originRouteIndex] = originalRouteInfo;
				}

				if (_isManualOptimization) {
					originalRouteInfo.run_id = originRouteIndex;
				}

				originalRouteInfo.depot_back_eta = newRouteInfo.depot_back_eta;

				if (routesFullyOptimized[newRouteInfo.route_idx]) {
					originalRouteInfo.depot_etl = newRouteInfo.depot_etl;
					originalRouteInfo.transport_distance = newRouteInfo.transport_distance;
				} else {
					originalRouteInfo.transport_distance = undefined;
				}
			}

			$scope.getTaskFromAllTasks = function (taskId) {
				var task = null,
					tempTask;

				_.each($scope.sharedData.data, function (timeLineRow) {
					tempTask = _.find(timeLineRow.tasks, { id: taskId });

					if (tempTask) {
						task = tempTask;
					}
				});

				return task;
			};

			$scope.$on('eta recalculate done', $scope.handleRecalculateEtaDone);

			$scope.isClicked = function (task) {
				if (
					$scope.infoWindow &&
					$scope.infoWindow.clickedTask &&
					$scope.infoWindow.clickedTask.id === task.id
				) {
					return true;
				}

				if ($scope.infoWindow && $scope.infoWindow.linkedTask && $scope.infoWindow.linkedTask.id === task.id) {
					return true;
				}
				return false;
			};

			$scope.infoWindow = {};
			$scope.handleItemClicked = function (event) {
				if (!event.target.attributes['task-id']) return;

				//extract info about the clicked element
				var taskId = event.target.attributes['task-id'].value;
				var routeIdx = event.currentTarget.attributes['route-idx'].value;

				//store the clicked element related task
				$scope.infoWindow.clickedTask = _.find($scope.sharedData.data[routeIdx].tasks, {
					id: parseInt(taskId, 10)
				});

				//if the task has a linked task find it
				if ($scope.infoWindow.clickedTask.linked_task_id) {
					$scope.infoWindow.linkedTask = _.find($scope.sharedData.data[routeIdx].tasks, {
						id: $scope.infoWindow.clickedTask.linked_task_id
					});
				}

				//get the full info about the clicked task
				Tasks.get({ id: $scope.infoWindow.clickedTask.id, forceGet: true }).then(function (fullTask) {
					//store the full info about the clicked task
					$scope.infoWindow.clickedFullTask = fullTask;

					//move the info window to the right position
					$timeout(function () {
						var pos = $(event.target).offset();
						var height = $('#infoWindow').height();
						$scope.infoWindow.position = {
							left: pos.left - 142 + 'px',
							top: pos.top - height - 80 + 'px'
						};
						ScopeApplier.safeApply($scope);

						if (!isInViewport($('#infoWindow')[0])) {
							var reduceLeft =
								$('#infoWindow')[0].getBoundingClientRect().left +
								$('#infoWindow')[0].getBoundingClientRect().width -
								window.innerWidth;

							$scope.infoWindow.position.left = pos.left - 142 - reduceLeft + 'px';
							$('.info-window .top-arrow').css(
								'right',
								parseInt($('.info-window .top-arrow').css('right')) - reduceLeft + 'px'
							);
						}
					});
				});
			};

			//get tasks for a particular time frame
			// don't use directly (use cached tasksTimeFramesByDriver) unless it's for tests
			$scope.getTaskTimeFrame = function (tasks, timeLineDatum) {
				var result = [];

				var hour = timeLineDatum.hour;
				var minutes = timeLineDatum.minutes;
				var timeScaleOffset = $scope.scale.config[$scope.scale.data.value].minutes;

				_.each(tasks, function (task) {
					var wp = WayPointsService.getDropOffWayPoint(task);
					if (wp) {
						var scheduledAt = new Date(wp.scheduled_at);
						var scheduledAtMinutes = scheduledAt.getMinutes();
						var scheduledAtHours = scheduledAt.getHours();

						if (
							scheduledAtHours === hour &&
							((scheduledAtMinutes > minutes && scheduledAtMinutes < minutes + timeScaleOffset) ||
								scheduledAtMinutes === minutes)
						) {
							result.push(task);
						}
					}
				});

				return result;
			};

			// show break in timeline
			$scope.hasBreak = function (_break, timeLineDatum) {
				_break = _break || {};
				var startBreakMinutes = moment(_break.estimated_start_time * 1000);
				var endBreakMinutes = moment(_break.estimated_end_time * 1000);
				var blockTimeLine = moment()
					.year(startBreakMinutes.year())
					.month(startBreakMinutes.month())
					.date(startBreakMinutes.date())
					.hours(timeLineDatum.hour)
					.minutes(timeLineDatum.minutes);

				return blockTimeLine.isBetween(startBreakMinutes, endBreakMinutes);
			};

			function calculateTasksTimeFramesData() {
				$scope.timeLineRoutes = {};
				_.forIn($scope.sharedData.data, function (route, routeIdx) {
					_.forEach($scope.timeLineData, function (timeLineDatum) {
						if (!$scope.timeLineRoutes[routeIdx]) {
							$scope.timeLineRoutes[routeIdx] = {};
						}

						$scope.timeLineRoutes[routeIdx][timeLineDatum.uuid] = {
							timeFrames: $scope.getTaskTimeFrame(route.tasks, timeLineDatum),
							break: $scope.hasBreak(route.break, timeLineDatum)
						};
					});
				});
			}
			calculateTasksTimeFramesData();

			$scope.hideRoute = function (route) {
				$rootScope.$broadcast('hide preview map route', route);
			};

			//this is used to close the info window if (on next click it will close)
			var hideInfoPopup = function (e) {
				if (!_.includes(e.target.classList, 'time-line-item') && $scope.infoWindow.clickedFullTask) {
					$scope.infoWindow.clickedFullTask = null;
					ScopeApplier.safeApply($scope);
				}
			};
			$window.addEventListener('click', hideInfoPopup);

			$scope.$on('$destroy', function () {
				$window.removeEventListener('click', hideInfoPopup);
			});

			$scope.isDraggable = function (timeLineDatum, routeIdx) {
				if (
					!$scope.timeLineRoutes[routeIdx][timeLineDatum.uuid].break &&
					!!$scope.timeLineRoutes[routeIdx][timeLineDatum.uuid].timeFrames.length
				) {
					angular
						.element(document.getElementById(routeIdx + '-' + timeLineDatum.index))
						.attr('draggable', 'true');
				}
			};

			$scope.isDroppable = function (timeLineDatum, routeIdx) {
				return !$scope.timeLineRoutes[routeIdx][timeLineDatum.uuid].break;
			};

			function isInViewport(element) {
				var rect = element.getBoundingClientRect();
				return (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= (window.innerWidth || document.documentElement.clientWidth)
				);
			}
		}
	)
	/**
	 * This directive is used to create a vertical scroll on two separated divs.
	 */
	.directive('combineVerticalScrolls', function () {
		var scrollTop = 0;

		function combine(elements) {
			elements.on('scroll', function (e) {
				if (e.isTrigger) {
					e.target.scrollTop = scrollTop;
				} else {
					scrollTop = e.target.scrollTop;
					elements.each(function (element) {
						if (!this.isSameNode(e.target)) {
							$(this).trigger('scroll');
						}
					});
				}
			});
		}

		return {
			restrict: 'A',
			replace: false,
			compile: function (element, attrs) {
				combine(element.find('.' + attrs.combineVerticalScrolls));
			}
		};
	})
	/**
	 * This directive is used for counting the number of stacked tasks on a single rect in the UI
	 */
	.filter('tasks_count_filter', function () {
		return function (tasks) {
			if (tasks.length > 1) {
				return tasks.length;
			}

			return '';
		};
	})
	.filter('wp_at_position_2', function () {
		return function (wayPoints) {
			return _.find(wayPoints, { position: 2 });
		};
	})
	.filter('info_window_address', function () {
		return function (wayPoint) {
			return wayPoint.address;
		};
	})
	.filter('displayDriverName', function ($translate) {
		return function (driver, displayByRouteName) {
			if (displayByRouteName) {
				return driver.user.route_name;
			}

			return driver.user.name || driver.user.title || $translate.instant('BIG_MAP.UNASSIGNED');
		};
	})
	.filter('info_window_time_window', function ($filter) {
		return function (task) {
			var result = $filter('taskGridTimeWindowFilter')()(null, null, null, null, task);
			if (result !== '<span>-- : --</span>') {
				return result;
			}

			var wp = _.find(task.way_points, { position: 2 });
			return $filter('humanize')(wp.scheduled_at);
		};
	})
	.filter('info_window_customer_note', function () {
		return function (task) {
			var wp = _.find(task.way_points, { position: 2 });
			var note = _.find(task.task_notes, { way_point_id: wp.id, type: 'TaskNote' });
			return note ? note.note : '';
		};
	});
