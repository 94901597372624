'use strict';

angular
	.module('bringgApp')
	.controller('ReasonToChangeInventoryController', function (
		$scope,
		$uibModalInstance,
		ReasonToChangeInventoryService,
		tagId
	) {
		$scope.reasonToChangeInventory = null;
		$scope.reasonToChangeInventoryText = '';

		$scope.submit = function () {
			$uibModalInstance.close({
				id: $scope.reasonToChangeInventory.id,
				text: $scope.reasonToChangeInventoryText
			});
		};

		$scope.dismiss = function () {
			$uibModalInstance.dismiss(false);
		};

		$scope._init = function () {
			ReasonToChangeInventoryService.getAll(tagId).then(function (reasonsToChangeInventory) {
				_.each(reasonsToChangeInventory, function (reasonToChangeInventory) {
					if (reasonToChangeInventory.is_default) {
						reasonToChangeInventory.reason =
							'TASK_CHANGE_INVENTORY_REASONS.' + reasonToChangeInventory.reason;
					}
				});

				$scope.reasonsToChangeInventory = reasonsToChangeInventory;
			});
		};

		$scope._init();
	});
