'use strict';
/*global */

angular
	.module('bringgApp')
	.directive(
		'routesTemplate',
		function (
			OptimizationPreviewService,
			ServiceAreasService,
			Optimization,
			Teams,
			OPTIMIZATION_TYPES,
			Authentication,
			toastr,
			ReactModals,
			RouteAssigner,
			MerchantConfigurations,
			Application,
			TasksData
		) {
			return {
				restrict: 'E',
				scope: {
					optimizationResults: '=',
					userTypesByTeam: '=',
					plannedRoutesByTeam: '=',
					serviceAreasById: '=',
					dispatch: '=',
					isCrewBased: '=',
					selectedTasks: '='
				},
				templateUrl: 'scripts/features/optimize/steps/routes-template.html',
				link: function (scope) {
					scope.selectTeam = function (team) {
						scope.activeTeam = team;
						scope.refreshData();
					};
					scope.getRoleName = function (roleId) {
						var role = _.find(scope.currentRoles, { id: roleId });

						return role.title;
					};
					scope.handlePreviewClicked = function (optimizationResult) {
						var type = scope.isCrewBased ? OPTIMIZATION_TYPES.CREW_BASED : OPTIMIZATION_TYPES.ROUTE_BASED;
						var teamPlannedRoutes = _.find(scope.plannedRoutesByTeam, { id: optimizationResult.team_id });
						var currentUser = Authentication.currentUser();
						var merchant_id = currentUser.merchant_id;
						var team = Teams.teamsByIdMap[optimizationResult.team_id];
						const routesPlannerEnable = MerchantConfigurations.applications.some(
							Application.isRouteOptimizer2
						);
						const teamSelectedTasks = scope.selectedTasks.filter(task => task.team_id === team.id);
						if (routesPlannerEnable) {
							ReactModals.openRoutesPlannerModal({
								isOpenFromDispatch: false,
								optimizationType: scope.optimizationType,
								teamId: team.id,
								selectedTasks: teamSelectedTasks,
								optimizationUuid: optimizationResult.request_uuid,
								isManualOptimization: false,
								optimizationResults: optimizationResult,
								onApplyChanges: userChanges => (optimizationResult.data = userChanges.data)
							});
						} else {
							OptimizationPreviewService.open(
								optimizationResult.data,
								team,
								type,
								scope.dispatch,
								scope.selectedTasks,
								teamPlannedRoutes && teamPlannedRoutes.plannedRoutes,
								null,
								null,
								scope.serviceAreasById
							).then(function (userChanges) {
								var request = {
									team_id: optimizationResult.team_id,
									request_uuid: optimizationResult.request_uuid,
									merchant_id: merchant_id,
									data: userChanges
								};
								//send the new optimization data to the backend
								Optimization.updateOptimizationModifiedData(request)
									.then(function (result) {
										if (result.success) {
											optimizationResult.data = userChanges;
											toastr.success('Route was updated');
										} else {
											toastr.error('Failed updating route');
										}
									})
									.catch(function () {
										toastr.error('Failed updating route');
									});
							});
						}
					};
					scope.init = function () {
						scope.activeTeam = scope.optimizationResults[0];
						scope.refreshData();
					};

					scope.refreshData = function () {
						var teamData = _.find(scope.optimizationResults, { team_id: scope.activeTeam.team_id });
						var groupedResultsByRoute = _.groupBy(teamData.data, 'route_idx');

						if (!scope.isCrewBased) {
							scope.currentRoles = _.find(scope.userTypesByTeam, {
								id: scope.activeTeam.team_id
							}).userTypes;
							scope.routes = _.map(groupedResultsByRoute, scope.mapOptimizationData);
						} else {
							scope.currentPlannedRoutes = _.find(scope.plannedRoutesByTeam, {
								id: scope.activeTeam.team_id
							}).plannedRoutes;
							scope.routes = _.map(groupedResultsByRoute, scope.mapCrewOptimizationData);
						}
					};

					scope.mapOptimizationData = function (optimizationData) {
						return {
							routeName:
								'Route-' +
								(optimizationData[0].route_idx + 1) +
								ServiceAreasService.getServiceAreaSuffix(scope.serviceAreasById, optimizationData[0]),
							numberOfTasks: scope.getNumberOfTasks(optimizationData),
							startTime: optimizationData[0].depot_etl * 1000,
							endTime: optimizationData[0].depot_back_eta * 1000,
							roleId: optimizationData[0].user_type_id
						};
					};

					scope.mapCrewOptimizationData = function (optimizationData) {
						var plannedRoute = _.find(scope.currentPlannedRoutes, {
							user_id: optimizationData[0].user_id
						});

						var plannedRouteTitle = plannedRoute && plannedRoute.title ? plannedRoute.title : '';

						return {
							routeName:
								plannedRouteTitle +
								ServiceAreasService.getServiceAreaSuffix(scope.serviceAreasById, optimizationData[0]),
							numberOfTasks: scope.getNumberOfTasks(optimizationData),
							startTime: optimizationData[0].depot_etl * 1000,
							endTime: optimizationData[0].depot_back_eta * 1000,
							driverName: plannedRoute && plannedRoute.drivers_names
						};
					};

					scope.getNumberOfTasks = function (optimizationData) {
						return _.chain(optimizationData).filter({ type: 'task' }).uniqBy('task_id').value().length;
					};

					scope.init();
				}
			};
		}
	);
