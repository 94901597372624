angular.module('bringgApp.directives').directive('customizeColumns', function () {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'scripts/directives/customize_columns/customize-columns.html',
		scope: {
			onSave: '&?',
			targetGridId: '@',
			configName: '@'
		},
		link: function (scope, element, attrs) {
			scope.onCancel = function () {
				scope.customizeListColumnsShown = false;
			};
			scope.save = function () {
				if (scope.onSave) {
					scope.onSave();
				}
				scope.customizeListColumnsShown = false;
			};
		}
	};
});
