/**
 * Created by liorsion on 8/16/15.
 */
'use strict';

angular.module('bringgApp').controller('DemoController', function ($scope, toastr, $http, WEB_API_URL) {
	$scope.data = {
		demos: [
			{ title: 'Demo order with inventory', id: 1 },
			{ title: 'Demo order with pickup inventory', id: 2 },
			{ title: 'Demo order with dropoff inventory', id: 3 },
			{ title: 'Demo order with dropoff inventory that must be done', id: 4 },
			{ title: 'Demo order with html task', id: 5 },
			{ title: 'Demo order with scanning inventory without editing', id: 6 },
			{ title: 'Demo order with scanning inventory (with sub inventories)', id: 7 },
			{ title: 'Demo order with formatted task note', id: 8 },
			{ title: 'Demo order with pickup and drop off inventories', id: 9 },
			{ title: 'Demo order for parcel shops (first and last teams)', id: 10 },
			{ title: 'DHL Demo', id: 11 },
			{ title: 'Demo order with dropoff Inventory and dropoff Package inventory', id: 12 },
			{ title: 'Demo order with nested inventory (no scan string)', id: 13 },
			{ title: 'Prepare for batching', id: 14 },
			{ title: 'Prepare group order', id: 15 },
			{ title: 'Prepare linked order', id: 16 }
		],
		radioModel: 1
	};

	$scope.sending = false;
	$scope.handleSubmitClick = function () {
		$scope.sending = true;
		$http
			.post(WEB_API_URL + '/demo/', {
				demo_type: $scope.data.radioModel
			})
			.success(function (data) {
				$scope.sending = false;
				if (data.success) {
					toastr.success('Orders created successfully');
				} else if (!data.success) {
					if (data.message) {
						toastr.error('Could not create order.', data.message);
					} else {
						toastr.error('Could not create order');
					}
				}
			})
			.error(function (data, status, headers, config) {
				$scope.sending = false;
				if (data.message) {
					toastr.error('Could not create order.', data.message);
				} else {
					toastr.error('Could not create order');
				}
			});
	};
});
