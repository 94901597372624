/**
 * Created by liorsion on 12/24/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller(
		'CreateMerchantController',
		function ($scope, toastr, Authentication, MerchantService, Merchant, MerchantPrototypeService, dialogSrv) {
			function generatePassword() {
				var length = 8,
					charset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
					retVal = '';
				for (var i = 0, n = charset.length; i < length; ++i) {
					retVal += charset.charAt(Math.floor(Math.random() * n));
				}
				return retVal;
			}

			var EnvTypes = {
				Production: '0',
				Staging: '1',
				Internal: '2'
			};

			$scope.envTypes = Object.keys(EnvTypes).map(type => ({ label: type, value: EnvTypes[type] }));

			$scope.data = {
				newMerchant: {
					email: '',
					time_zone: '',
					name: '',
					adminName: '',
					password: generatePassword(),
					address: '',
					phone: '',
					crm_id: '',
					env_type: EnvTypes.Internal,
					merchant_prototype_id: 0
				}
			};

			$scope.merchantPrototypes = [];

			MerchantService.proFeaturesEnabled().then(function (proFeaturesEnabled) {
				$scope.proFeaturesEnabled = proFeaturesEnabled;
			});

			MerchantPrototypeService.getAll().then(function (response) {
				$scope.merchantPrototypes = response;
			});

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn, oldValue) {
				if (isLoggedIn) {
				}
			});

			$scope.isEnvTypeInternal = function () {
				return $scope.data.newMerchant.env_type === EnvTypes.Internal;
			};

			$scope.createEnabled = true;
			$scope.createMerchant = function () {
				$scope.createEnabled = false;

				var merchantData = Object.assign({}, $scope.data.newMerchant);

				if (merchantData.env_type === EnvTypes.Internal) {
					merchantData.crm_id = null;
				}

				if (!merchantData.merchant_prototype_id) {
					$scope.createEnabled = true;
					return toastr.error('Select merchant template');
				}

				MerchantPrototypeService.createMerchantByAccountManager(merchantData)
					.then(function (response) {
						$scope.linkToShare = response.merchant_guid;
						dialogSrv.notifyDialog('Merchant Created', response.merchant_guid);
						toastr.success('Customer created!');
					})
					.catch(function (response) {
						var errorString = '';
						if (typeof response.message === 'string') {
							errorString = response.message;
						} else {
							for (var messageIdx in response.message) {
								errorString += messageIdx + ':' + response.message[messageIdx][0] + '\r\n';
							}
						}
						toastr.error(errorString);
					})
					.finally(function () {
						$scope.createEnabled = true;
					});
			};

			$scope.copyData = {};
			$scope.validating = false;
			$scope.validateClicked = function () {
				$scope.validating = true;
				return Merchant.copyMerchantSettingsValidate($scope.copyData)
					.then(function (result) {
						$scope.validating = false;
						$scope.validatedData = result;
						$scope.copyInProgress = false;
					})
					.catch(function (error) {
						toastr.error(error.details, 'Something is wrong with the IDs you provided');
						$scope.validating = false;
						$scope.copyInProgress = false;
					});
			};

			$scope.fields = {
				merchant: true,
				merchant_configuration: true,
				user_configuration: true,
				customer_configuration: true,
				security_configuration: true,
				payment_configuration: true,
				applications: true,
				application_merchant_configuration: true,
				services: true,
				user_types: true,
				reasons_to_cancel_tasks: true,
				shift_schedule: true,
				task_csv_structures: true,
				user_csv_structures: true
			};

			$scope.copyInProgress = false;
			$scope.copyClicked = function () {
				if (!$scope.validatedData) {
					toastr.error('First validate ALWAYS.');
					return;
				}
				$scope.copyInProgress = true;

				var result = confirm('Have you validated that the names match?');

				if (result) {
					Merchant.copyMerchantSettings($scope.copyData, $scope.fields)
						.then(function () {
							toastr.success(
								'Configurations copied from ' +
									$scope.validatedData.origin_merchant_name +
									' to ' +
									$scope.validatedData.destination_merchant_name
							);
						})
						.catch(function () {
							$scope.copyInProgress = false;
							toastr.error('Something is wrong with the IDs you provided');
						});
				}
			};
		}
	);
