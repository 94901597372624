'use strict';

angular.module('bringgApp').directive('taskInvoiceInventoryItem', function ($translate) {
	return {
		restrict: 'E',
		scope: {
			inventory: '&',
			type: '@',
			level: '=',
			color: '@',
			minQuantityToDisplay: '@',
			minLevelToDisplay: '@',
			showPricesOnTopLevelOnly: '@',
			showPrice: '@'
		},
		link: function (scope) {
			scope.minQuantityToDisplay = scope.minQuantityToDisplay || 0;
			scope.minLevelToDisplay = scope.minLevelToDisplay || 0;
			scope.inventory = scope.inventory();
			scope.subInventories = scope.inventory.inventories;

			scope.getDirectionLabel = function (direction) {
				if (direction) {
					return $translate.instant('ORDER_INVENTORY.PICKED_UP');
				} else {
					return $translate.instant('ORDER_INVENTORY.DROPPED_OFF');
				}
			};

			scope.showPrices = function () {
				if (scope.showPrice === 'false') {
					return false;
				}

				if (scope.inventory.price <= 0) {
					return false;
				}

				if (scope.showPricesOnTopLevelOnly === 'true') {
					return scope.level === 0;
				}

				return true;
			};
		},
		templateUrl: 'scripts/features/print/task-invoice-inventory-item.html'
	};
});
