'use strict';

angular
	.module('bringgApp.directives')
	.directive('signedIn', function (Authentication, ClientStatsService, SubmitTicketService, PRIVILEGES_TYPES) {
		return {
			templateUrl: 'scripts/directives/signed_in/signed-in.html',
			scope: {
				currentUser: '=',
				merchant: '='
			},
			link: function (scope) {
				scope.isSettingPageEnabled = Authentication.currentUser().has_access(
					PRIVILEGES_TYPES.ENABLE_SETTING_PAGE
				);

				scope.signOutClicked = function () {
					Authentication.logout();
				};

				scope.submitTicket = function () {
					SubmitTicketService.open();
				};

				scope.handleMerchantInfoClicked = function () {
					ClientStatsService.downloadLogs();
				};
			}
		};
	});
