'use strict';

angular.module('bringgApp').directive('optimizeProgress', function (toastr, $translate, $log) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			onOptimizationDone: '=',
			totalTeamsToOptimize: '=',
			current: '='
		},
		templateUrl: 'scripts/features/optimize/steps/optimize-progress.html',
		link: function (scope) {
			function hanleOptimizatoinResult(event, optimizationResult) {
				var status = 'success';
				if (optimizationResult.error) {
					status = 'error';
				}
				if (optimizationResult.isPartial) {
					status = 'partial';
				}
				scope.onOptimizationDoneEvent(status, optimizationResult);
			}

			scope.$on('optimization done', hanleOptimizatoinResult);

			scope.onOptimizationDoneEvent = function (status, optimizationResult) {
				$log.info('Optimization done');

				switch (status) {
					case 'success':
						// done success
						// toastr.success($translate.instant('OPTIMIZE_TASKS.OPTIMIZATION_DONE'));
						break;
					case 'partial':
						toastr.warning($translate.instant('OPTIMIZE_TASKS.OPTIMIZATION_STOPPED'));
						break;
					case 'error':
						{
							let error;
							try {
								error = JSON.parse(optimizationResult.error)[0].message;
							} catch (e) {
								error = optimizationResult.error;
							}
							toastr.error($translate.instant('OPTIMIZE_TASKS.OPTIMIZATION_ERROR') + error);
						}
						break;
				}
			};
		}
	};
});
