/**
 * Created by liorsion on 4/26/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller(
		'ServicesController',
		function ($scope, Authentication, ServiceSetup, toastr, copyToClipboard, WEB_API_URL) {
			$scope.extendedTableUI = false;
			$scope.showOAuth2Column = false;

			$scope.data = {};
			$scope.servicesValues = [];
			$scope.serviceLabelById = new Map();

			Authentication.featureFlags().then(featureFlags => {
				$scope.extendedTableUI = Boolean(featureFlags.enable_api_page_oauth_url_column);
				$scope.showOAuth2Column = $scope.extendedTableUI && Boolean(featureFlags.oauth_applications_allowed);
			});

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn, oldValue) {
				if (isLoggedIn) {
					Promise.all([ServiceSetup.all().$promise, ServiceSetup.list().$promise]).then(function ([
						services,
						servicesValues
					]) {
						$scope.servicesValues = servicesValues;

						$scope.serviceLabelById = new Map(
							servicesValues.map(function (serviceValue) {
								return [serviceValue.id, serviceValue.label];
							})
						);
						// pay attention custom field is added to Resource, should be fine since update / save is not used
						for (const service of services) {
							service.label = $scope.serviceLabelById.get(service.action);
						}

						$scope.services = services;
						$scope.WEB_API_URL = WEB_API_URL;
						$scope.$apply();
					});
				}
			});

			$scope.addService = function () {
				if (!$scope.data || !$scope.data.action) {
					toastr.error('First choose the API action.');
				} else {
					ServiceSetup.save($scope.data, function (response) {
						if (response.success) {
							response.service.tag = $scope.data.tag;
							response.service.label = $scope.serviceLabelById.get(response.service.action);
							$scope.services.push(response.service);
						} else {
							toastr.error(response.error?.message);
						}
					});
				}
			};

			$scope.deleteService = function (service) {
				ServiceSetup.delete({ id: service.id }, function (response) {
					if (response.success) {
						$scope.services.splice($scope.services.indexOf(service), 1);
					} else {
						toastr.error(response.error.message);
					}
				});
			};

			$scope.getServiceShortUrl = function (service) {
				const { second_auth_key } = service;
				const keyParts = second_auth_key.split('-');
				const [urlFirstPart] = WEB_API_URL.split('.');

				return `${urlFirstPart}...${keyParts[keyParts.length - 1]}/`;
			};

			$scope.copyServiceUrl = function (service, isOAuth2 = false) {
				const targetUrl = isOAuth2
					? `${WEB_API_URL}/services/${service.action_name}`
					: `${WEB_API_URL}/services/${service.action}/${service.first_auth_key}/${service.second_auth_key}/`;

				if (copyToClipboard(targetUrl)) {
					toastr.success('Copied to Clipboard');
				} else {
					toastr.error(`Can't copy to Clipboard`);
				}
			};
		}
	)
	.filter('servicesDocumentationFilter', function () {
		return function (action) {
			return `https://developers.bringg.com/v1.0/reference/${action}`;
		};
	});
