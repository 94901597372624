'use strict';

angular.module('bringgApp.directives').directive('driverDeliveryBlock', function ($log, toastr, BringgSDK) {
	return {
		templateUrl: 'scripts/directives/driver-delivery-block/driver-delivery-block.html',
		scope: {
			driverId: '<'
		},
		link: function (scope) {
			scope.loading = false;
			scope.month = 1; // need better indication from server for next blocks
			scope.visibleBlocks = 5;
			scope.driversBlocks = [];
			scope.startTime = moment().startOf('day').unix();
			scope.endTime = moment().add(scope.month, 'M').unix();

			scope._fetch = function (driverId, startTime, endTime) {
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.deliveryBlocks.getByDriverId(driverId, startTime, endTime);
				});
			};

			scope.loadMore = function () {
				if (!scope.loading) {
					scope.loading = true;

					scope
						._fetch(scope.driverId, scope.startTime, scope.endTime)
						.then(function (driversBlocks) {
							$log.info(
								'Loaded Delivery Blocks for user:',
								scope.driverId,
								'delivery blocks:',
								driversBlocks
							);
							scope.driversBlocks = _.sortBy(driversBlocks, 'start_time');
							scope.month++;
							scope.endTime = moment().add(scope.month, 'M').unix();
							scope.loading = false;
						})
						.catch(function (error) {
							scope.loading = false;
							$log.error(error);
							toastr.error('Error Getting delivery Block', error.message);
						});
				}
			};

			scope.init = function () {
				if (!scope.driverId) {
					$log.error('No driver Id was provided');
					return;
				}

				scope.loadMore();
			};

			scope.init();
		}
	};
});
