'use strict';

angular
	.module('bringgApp')
	.controller(
		'AnalyticsDashboardsController',
		function (
			$scope,
			$rootScope,
			$location,
			ENV_CONFIGURATION,
			MerchantConfigurations,
			WindowCommunicationService,
			$window,
			$sce,
			$translate,
			Employees,
			$route
		) {
			let initialTopClass = $rootScope.topClass;
			const basePath = '/analytics/';

			const getIframeUrl = function () {
				return $location.url().replace(basePath, '');
			};

			$rootScope.hideMenu = false;

			$rootScope.bodyClass = 'analytics-reports-app';

			$scope.getAnalyticsDashboardsUrl = function () {
				return $sce.trustAsResourceUrl(ENV_CONFIGURATION.analytics_reports_app_url);
			};

			$scope.onInit = function () {
				//send initial data
				var contentWindow = document.getElementById('analyticsDashboardsIFrame').contentWindow;
				WindowCommunicationService.sendCredentials(contentWindow);
				WindowCommunicationService.sendInitialData(contentWindow, {
					legacyReports: [],
					initialRoute: getIframeUrl(),
					language: $translate.use(),
					countryCode: MerchantConfigurations.country_code
				});

				//collapse menu
				initialTopClass = $rootScope.topClass;
				$rootScope.topClass = 'collapsed';
			};

			let lastRoute = $route.current;
			const unsubscribeLocationChangeSuccess = $scope.$on('$locationChangeSuccess', function () {
				// hack for disable reload iframe on route change
				// eslint-disable-next-line angular/no-private-call
				if (lastRoute.$$route.originalPath === $route.current.$$route.originalPath) {
					WindowCommunicationService.sendNewLocation(
						document.getElementById('analyticsDashboardsIFrame').contentWindow,
						getIframeUrl()
					);
					$route.current = lastRoute;
				}
			});

			const unsubscribeOnLocationChanges = WindowCommunicationService.subscribeOnLocationChanges(function (url) {
				if (getIframeUrl() !== url) {
					$location.url(basePath + url);
					$scope.$apply();
				}
			});

			const unsubscribeOnOpenWindow = WindowCommunicationService.subscribeOnOpenWindow(function (url) {
				window.open(url).focus();
			});

			const unsubscribeOnRequestEmployeeData = WindowCommunicationService.subscribeOnGeneric(
				'request_employee_data',
				function (info, postResponse) {
					const searchString = String(info.search);
					Employees.asyncSearchByName(searchString, info.options).then(function (usersList) {
						postResponse({ users: usersList, requestId: info.requestId });
					});
				},
				'employee_data'
			);

			$scope.$on('$destroy', function () {
				$rootScope.topClass = initialTopClass;
				$rootScope.bodyClass = undefined;

				$location.hash('');
				unsubscribeLocationChangeSuccess();
				unsubscribeOnLocationChanges();
				unsubscribeOnOpenWindow();
				unsubscribeOnRequestEmployeeData();
			});
		}
	);
