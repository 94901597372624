'use strict';

angular
	.module('bringgApp')
	.controller(
		'DispatcherAcknowledgeTaskStatusController',
		function (
			$scope,
			$uibModalInstance,
			item,
			pendingTasks,
			Task,
			Tasks,
			toastr,
			$route,
			$timeout,
			$document,
			DispatcherAcknowledgeTaskStatusService,
			TASK_STATUS,
			$rootScope,
			$log,
			Authentication,
			TaskInventoriesService,
			$translate,
			PrintTasksService,
			bringg_utils,
			$filter,
			MerchantConfigurations,
			flowUiConfig,
			PAYMENT_METHODS,
			PREPARATION_STATUS
		) {
			$scope.task = item.task;
			$scope.taskId = item.taskId || item.task.id;
			$scope.configs = flowUiConfig;
			$scope.sending = false;
			$scope.pendingTasks = pendingTasks;

			// fire and ignore for analytics only if we have ack_type
			if (flowUiConfig.ack_type) {
				Task.acknowledgeTaskView({ ack_type: item.ack_type, task_id: item.task.id })
					.$promise.then(function () {})
					.catch(function (err) {
						$log.error(
							'Failed to mark task ' +
								item.task.id +
								' for acknowledgeTaskView, error: ' +
								JSON.stringify(err)
						);
					});
			}

			function addTimerElement(item) {
				var timerElement = angular.element(
					'<div class="circle-animation-wrapper"><div class="pie spinner"></div> <div class="pie filler"></div> <div class="mask"></div> </div>'
				);

				var onInteraction = function () {
					clearTimeout(timerElement.time);
					timerElement.off('click', timerElement.clickHandler);
					delete item.task._acknowledge_remind_me_latter;
					DispatcherAcknowledgeTaskStatusService.endSnoozeItem(item);
					timerElement.remove();
				};

				timerElement.time = setTimeout(function () {
					onInteraction();
				}, 180000);

				timerElement.clickHandler = function () {
					onInteraction();
				};

				timerElement.cancel = function () {
					clearTimeout(timerElement.time);
					timerElement.remove();
				};

				timerElement.on('click', timerElement.clickHandler);

				item.task._acknowledge_remind_me_latter = true;

				$document.find('body').eq(0).append(timerElement);
				DispatcherAcknowledgeTaskStatusService.registerTimerElement(timerElement, item);
			}

			$scope.handleCloseModal = function () {
				if ($scope.configs.removeOnClose) {
					DispatcherAcknowledgeTaskStatusService.removeTask(item.task);
				}

				$uibModalInstance.close();
			};

			$scope.handleRemindMeLaterClick = function () {
				DispatcherAcknowledgeTaskStatusService.snoozeItem(item);
				addTimerElement(item);

				$uibModalInstance.close();
			};

			$scope.handleRejectClick = function () {
				$scope.sending = true;

				if ($scope.task.status === TASK_STATUS.CANCELLED) {
					toastr.error($translate.instant('DISPATCHER_ACKNOWLEDGE.TASK_IS_ALREADY_REJECTED'));
					$uibModalInstance.dismiss('cancel');
					DispatcherAcknowledgeTaskStatusService.removeTask(item.task);
					return;
				}

				item.onRejectedClicked(item.task)
					.then(function () {
						toastr.success($translate.instant('DISPATCHER_ACKNOWLEDGE.TASK_REJECTED'));
						$scope.sending = false;

						DispatcherAcknowledgeTaskStatusService.removeTask(item.task);
						$uibModalInstance.close();
					})
					.catch(function (error) {
						$scope.sending = false;
						toastr.error(error.details, $translate.instant('DISPATCHER_ACKNOWLEDGE.FAILED_REJECTING_TASK'));
					});
			};

			$scope.handleAcknowledgeClick = function () {
				$scope.sending = true;
				item.onAcknowledgeClicked(item.task)
					.then(function (response) {
						DispatcherAcknowledgeTaskStatusService.removeTask(item.task);
						if (response.success) {
							// print task if we have print on ack enabled
							if ($scope.printOnAckEnabled) {
								PrintTasksService.printTasks(response.task);
							}
						} else {
							toastr.error(response.message);
						}

						$uibModalInstance.close();
					})
					.catch(function () {
						toastr.error('Error processing acknowledgment');
					});
			};

			$scope.handleTaskRemoveFromUnacknowleged = function (event, task) {
				if (task.id === $scope.taskId) {
					delete $scope.task._acknowledge_remind_me_latter;
					$uibModalInstance.close();
				}
			};

			$scope.$on('task removed from unacknowleged', $scope.handleTaskRemoveFromUnacknowleged);
			$scope.$on('task done', $scope.handleTaskRemoveFromUnacknowleged);

			$scope.extractValue = function (object, config) {
				if (config.advanced_filter) {
					return $filter(config.advanced_filter)(object);
				}

				var value = bringg_utils.getNestedField(object, config.field);

				if (config.filter === 'paymentMethod') {
					return (value = $filter(config.filter)(value, object));
				}

				if (config.filter) {
					return (value = $filter(config.filter)(value));
				}

				return value;
			};

			$scope.getIcon = function (paymentMethod) {
				switch (paymentMethod) {
					case PAYMENT_METHODS.CASH:
						return 'bringg-icon-coins';
					case PAYMENT_METHODS.CREDIT:
						return 'bringg-icon-credit-card';
					case PAYMENT_METHODS.CREDIT_CARD_TERMINAL:
						return 'bringg-icon-cashier';
					default:
						return '';
				}
			};

			$scope.showPendingTasksCounter = function () {
				return pendingTasks.length > 1;
			};

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.printOnAckEnabled = featureFlags.print_on_ack_enabled;
				if (!featureFlags.new_preparation_status_flow) {
					$log.info('KdsController, not using new_preparation_status_flow, not showing display ID');
					$scope.showPreparationId = false;
					return;
				}
				$scope.showPreparationId = true;
				$scope.preparationId = { value: _.get(item, 'task.preparation_external_id') };
				$scope.editMode = false;
				$scope.taskInUpdate = false;

				$scope.toggleEditMode = function () {
					$scope.editMode = !$scope.editMode;
				};

				$scope.updatePreparationId = function () {
					if ($scope.taskInUpdate) {
						$log.info('KdsController, updatePreparationId pressed while still updating task', $scope.task);
						return;
					}
					$scope.taskInUpdate = true;
					//first update task to new preparation_status
					Task.updateTask($scope.taskId, { preparation_external_id: $scope.preparationId.value })
						.then(function () {
							//if task is in status start_prep we need to move it to prep started
							if ($scope.task.preparation_status === PREPARATION_STATUS.START_PREPARATION) {
								return Tasks.updatePreparationStatus(
									$scope.task,
									PREPARATION_STATUS.PREPARATION_STARTED
								).catch(function (error) {
									$log.error(
										'failed updating task with new status and preparation_external_id',
										error
									);
									$scope.preparationId.value = $scope.task.preparation_external_id;
								});
							}
						})
						.catch(function (error) {
							$log.error('failed updating task with new status and preparation_external_id', error);
							$scope.preparationId.value = $scope.task.preparation_external_id;
						})
						.finally(function () {
							$scope.taskInUpdate = false;
							$scope.toggleEditMode();
						});
				};
			});
		}
	)
	.filter('ackWayPointCustomerName', function () {
		return function (wayPoint) {
			return (
				wayPoint.customer.name +
				' ' +
				(wayPoint.customer.phone
					? wayPoint.customer.phone
					: wayPoint.customer.email
					? wayPoint.customer.email
					: '')
			);
		};
	})
	.filter('ackIsFieldEmpty', function () {
		return function (field) {
			if (!field) {
				return '--';
			}
			return field;
		};
	})
	.filter('paymentMethod', function (PAYMENT_METHODS, $translate, $filter) {
		return function (paymentMethod, task) {
			if (!paymentMethod) {
				return '--';
			}
			var kdsPrefix = 'PAYMENT_METHOD.' + _.invert(PAYMENT_METHODS)[paymentMethod];

			return $filter('currency')(task.total_price) + ' - ' + $translate.instant(kdsPrefix);
		};
	})
	.filter('ackWayPointAddress', function () {
		return function (wayPoint) {
			return wayPoint.address + ' ' + (wayPoint.address_second_line ? wayPoint.address_second_line : '');
		};
	})
	.filter('wayPointPositionToShow', function () {
		return function (wayPoints, configs) {
			if (!configs.way_points_position) {
				return wayPoints;
			}

			return _.filter(wayPoints, function (wayPoint) {
				return _.includes(configs.way_points_position, wayPoint.position);
			});
		};
	})
	.filter('wayPointPositionByTaskTypeId', function (TASK_DEFAULT_TYPES) {
		return function (wayPoints, task) {
			function getPosition(taskTypeId) {
				switch (taskTypeId) {
					case TASK_DEFAULT_TYPES.CLICK_AND_COLLECT:
						return 1;
					case TASK_DEFAULT_TYPES.DELIVERY:
						return 2;
					default:
						return 2;
				}
			}

			var position = getPosition(task.task_type_id);
			return _.filter(wayPoints, function (wayPoint) {
				return wayPoint.position === position;
			});
		};
	});
