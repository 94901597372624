'use strict';
angular.module('bringgApp.directives').directive('cxPoweredByBringg', function () {
	return {
		templateUrl: 'scripts/directives/customer-experience/powered-by-bringg-directive.html',
		replace: true,
		restrict: 'E',
		scope: {
			color: '@'
		}
	};
});
