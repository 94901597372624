'use strict';

angular
	.module('bringgApp')
	.factory('DashboardResource', function (BringgSDK) {
		function dashboardService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (sdk) {
					return sdk.dashboard[method].apply(sdk.dashboard, args);
				});
			};
		}

		return {
			getDashboard: dashboardService('getDashboard'),
			getWidgetsStructure: dashboardService('getWidgetsStructure'),
			getWidgetData: dashboardService('getWidgetData')
		};
	})
	.factory('DashboardService', function (DashboardResource, $q, $log) {
		var DashboardService = {};

		DashboardService.WIDGET_TYPE = {
			TABLE: 1,
			COUNTER: 2,
			GAUGE: 3,
			LINE: 4,
			BARS: 5,
			PIE: 6,
			VERTICAL_TABLE: 7,
			REFLECTING_GAUGE: 8,
			PREPARATION_STATUS: 12
		};

		DashboardService.GROUPING_TYPE = {
			TOTAL: 1,
			TEAMS: 2,
			USERS: 3
		};

		DashboardService.AGGREGATION_TYPE = {
			AVG: 1,
			SUM: 2,
			MAX: 3,
			MIN: 4,
			COUNT: 5
		};

		DashboardService.TIME_RANGE_TYPE = {
			EVER: 0,
			LAST_MINUTE: 10,
			LAST_HOUR: 20,
			LAST_DAY: 30,
			LAST_WEEK: 40,
			LAST_MONTH: 50
		};

		DashboardService.get = function (teamId) {
			if (_.isNumber(teamId)) {
				$log.info('DashboardService.get - fetching by team id: ' + teamId);
			} else {
				$log.info('DashboardService.get - fetching all, team id is null');
			}

			return DashboardResource.getDashboard(teamId);
		};

		return DashboardService;
	});
