'use strict';

angular.module('bringgApp.directives').directive('multiSelect', function () {
	return {
		templateUrl: 'scripts/directives/multi_select/multi-select.html',
		scope: {
			items: '=',
			selectedItems: '='
		},
		controller: function ($scope) {
			$scope.selectedItems = {};
			$scope.searchText = '';

			$scope.selectAll = function () {
				_.each($scope.items, function (item) {
					$scope.selectedItems[item.id] = true;
				});
			};

			$scope.clearAll = function () {
				$scope.selectedItems = {};
			};

			$scope.toggleItem = function (itemId) {
				$scope.selectedItems[itemId] = !$scope.selectedItems[itemId];
			};

			$scope.showItem = function (name) {
				if ($scope.searchText === '') {
					return true;
				}
				return _.isString(name) && name.toLowerCase().indexOf($scope.searchText.toLocaleLowerCase()) !== -1;
			};
		}
	};
});
