'use strict';

angular
	.module('bringgApp')
	.constant('TAG_RULES_CONFIGURATION_RULE_OPTIONS', {
		MANUAL: 0,
		AFTER_ARRIVAL: 1,
		BEFORE_LEAVING: 2,
		DEFAULT: 3,
		NOT_ALLOWED: 4
	})
	.controller(
		'TagsController',
		function (
			$scope,
			Tags,
			bringg_utils,
			FileUploader,
			Authentication,
			localStorageService,
			ReasonToCancelTask,
			Application,
			CrossApplicationService,
			WEB_API_URL,
			Tag,
			toastr,
			$uibModal,
			$log,
			$timeout,
			MerchantConfigurations,
			$translate,
			dialogSrv,
			TAG_RULES_CONFIGURATION_RULE_OPTIONS,
			WAY_POINT_ARRIVAL_TYPES,
			IMAGE_SIZE,
			CustomerExperienceConfig,
			CustomerConfiguration,
			TaskCancelReasonActionsProvider
		) {
			$scope.sharingModeValues = [
				'All methods',
				'Only via email',
				'Only via SMS',
				'Do not share with customer',
				'Only webhook'
			];
			$scope.alertOnCheckoutValues = ['Never', 'On every checkout', 'On every checkout if was not rated'];
			$scope.merchantConfiguration = MerchantConfigurations;

			$scope.planning_phase_exists = MerchantConfigurations.planning_phase_exists;
			$scope.reasonNumbers = _.range(30);
			$scope.days_ahead_numbers = _.map(_.range(-1, 30), function (number) {
				return {
					value: number,
					text: number === -1 ? $translate.instant('TASK_CANCEL_CONFIGURATION.SAME_RUN') : number
				};
			});

			$scope._shouldEnableSendToDispatch = function (reason) {
				var enableSendToDispatch =
					(_.isNil(reason.days_ahead) && reason.reschedule_as_virtual_from === 0) || reason.days_ahead === 0;
				if (!enableSendToDispatch && reason.send_to_dispatch) {
					reason.send_to_dispatch = false;
				}
				return enableSendToDispatch;
			};

			$scope.isNewCXEnabled = false;
			$scope.newCXConfig = {};
			$scope.disableReturnOrder = false;

			$scope.way_point_arrival_types = [
				{
					value: WAY_POINT_ARRIVAL_TYPES.ANYTIME,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.ANYTIME')
				},
				{
					value: WAY_POINT_ARRIVAL_TYPES.BEFORE_ARRIVAL,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.BEFORE_ARRIVAL')
				},
				{
					value: WAY_POINT_ARRIVAL_TYPES.AFTER_ARRIVAL,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.AFTER_ARRIVAL')
				}
			];

			var failedDeliveryApp = _.find(MerchantConfigurations.applications, {
				uuid: Application.APPLICATIONS.FailedDeliveryManager
			});
			$scope.failedDeliveryApplicationInstalled = !_.isUndefined(failedDeliveryApp);

			Application.isInstalledByUuid(Application.APPLICATIONS.CustomerExperienceWebApp).then(function (
				isInstalled
			) {
				$scope.isNewCXEnabled = isInstalled;
			});

			$scope.$on('config updated', function () {
				$scope.merchantConfiguration = MerchantConfigurations;
			});

			Tags.all(function (result) {
				$scope.tags = result;
			});

			$scope.selectedTag = null;
			$scope.notificationTemplatesEnabled = Authentication.currentUser().feature_flags.use_notification_templates;
			$scope.waypointArrivalTypeEnabled =
				Authentication.currentUser().feature_flags.waypoint_arrival_type_enabled;
			$scope.cxCalendarFFEnabled = Authentication.currentUser().feature_flags.calendar_view_for_delivery_options;

			$scope.handleTagClicked = function (tag) {
				CrossApplicationService.emit('TAG_SELECTED', tag.id);
				$scope.selectedTag = tag;

				$scope.cancelReasonsConfiguration = tag.tag_reasons_to_cancel_task_configuration || [];
				$scope.actionDataByReason = TaskCancelReasonActionsProvider.getByReason(
					$scope.cancelReasonsConfiguration
				);
				TaskCancelReasonActionsProvider.filterExcluded(
					$scope.cancelReasonsConfiguration,
					$scope.actionDataByReason
				);

				if ($scope.failedDeliveryApplicationInstalled) {
					Application.getConfigByAppUuid(Application.APPLICATIONS.FailedDeliveryManager).then(config => {
						$scope.disableReturnOrder1 = config?.data?.disable_return_order || false;
					});
				}

				$scope.add_defaults_to_reasons();
				$scope.cancelReasonsConfigurationEnabled = tag.tag_reasons_to_cancel_task_configuration?.length > 0;
				if (tag.tag_merchant_configuration) {
					$scope.selectedSharingMode = tag.tag_merchant_configuration.sharing_mode;

					if ($scope.isNewCXEnabled) {
						Application.getConfigByAppUuid(Application.APPLICATIONS.CustomerExperienceWebApp).then(
							config => {
								$scope.customerExperienceWebAppConfig = _.defaults(_.get(config, 'data'), {
									vehicle_details_form_configuration: {
										show_parking_spot: true,
										show_license_plate: true,
										show_car_model: true,
										show_car_color: true
									},
									disable_cx_color_manipulation: false
								});
								$scope.newCXConfig = CustomerExperienceConfig.getCXConfig(
									tag.tag_merchant_configuration,
									$scope.customerExperienceWebAppConfig?.disable_cx_color_manipulation
								);
							}
						);
					}
				}

				if (tag.tag_customer_configuration) {
					$scope.alertOnCheckoutMode = tag.tag_customer_configuration.alert_on_checkout;
					$scope.newCXConfig.showDeliveryStatus = tag.tag_customer_configuration.show_delivery_status;
				}
				if (
					_.has(tag.tag_rules_configuration, 'take_signature_rule') &&
					!$scope.selectedTag.tag_rules_configuration.disclaimer
				) {
					var signatureActionData = _.find(tag.tag_rules_configuration.action_data, function (data) {
						return data.value === 'take_signature';
					});
					if (signatureActionData && signatureActionData.data) {
						$scope.selectedTag.tag_rules_configuration.disclaimer = signatureActionData.data.disclaimer;
					}
				}

				$scope.createUploader();
			};

			//==============================================
			// Image uploads
			//==============================================
			$scope.createUploader = function () {
				if (!$scope.selectedTag.tag_merchant_configuration) {
					return;
				}

				if (
					!$scope.selectedTag.tag_merchant_configuration.logo_uploader &&
					$scope.selectedTag.tag_merchant_configuration
				) {
					$scope.selectedTag.tag_merchant_configuration.logo_uploader = $scope.getNewUploader();
					$scope.selectedTag.tag_merchant_configuration.logo_uploader.onAfterAddingFile =
						$scope.afterAddingFileFn(
							'#logoUploaderPreview',
							$scope.selectedTag.tag_merchant_configuration.logo_uploader,
							'logo'
						);
					$scope.selectedTag.tag_merchant_configuration.logo_uploader.onSuccessItem =
						$scope.afterUploadingFileFn(
							'logo',
							$scope.selectedTag.tag_merchant_configuration.logo_uploader
						);

					$scope.selectedTag.tag_merchant_configuration.delivery_pin_uploader = $scope.getNewUploader();
					$scope.selectedTag.tag_merchant_configuration.delivery_pin_uploader.onAfterAddingFile =
						$scope.afterAddingFileFn(
							'#deliveryPinUploaderPreview',
							$scope.selectedTag.tag_merchant_configuration.delivery_pin_uploader,
							'delivery_pin'
						);
					$scope.selectedTag.tag_merchant_configuration.delivery_pin_uploader.onSuccessItem =
						$scope.afterUploadingFileFn(
							'delivery_pin',
							$scope.selectedTag.tag_merchant_configuration.delivery_pin_uploader
						);

					$scope.selectedTag.tag_merchant_configuration.destination_pin_uploader = $scope.getNewUploader();
					$scope.selectedTag.tag_merchant_configuration.destination_pin_uploader.onAfterAddingFile =
						$scope.afterAddingFileFn(
							'#destinationPinUploaderPreview',
							$scope.selectedTag.tag_merchant_configuration.destination_pin_uploader,
							'destination_pin'
						);
					$scope.selectedTag.tag_merchant_configuration.destination_pin_uploader.onSuccessItem =
						$scope.afterUploadingFileFn(
							'destination_pin',
							$scope.selectedTag.tag_merchant_configuration.destination_pin_uploader
						);

					$scope.selectedTag.tag_merchant_configuration.email_logo_uploader = $scope.getNewUploader();
					$scope.selectedTag.tag_merchant_configuration.email_logo_uploader.onAfterAddingFile =
						$scope.afterAddingFileFn(
							'#emailLogoUploaderPreview',
							$scope.selectedTag.tag_merchant_configuration.email_logo_uploader,
							'email_logo'
						);
					$scope.selectedTag.tag_merchant_configuration.email_logo_uploader.onSuccessItem =
						$scope.afterUploadingFileFn(
							'email_logo',
							$scope.selectedTag.tag_merchant_configuration.email_logo_uploader
						);
				}
			};

			$scope.afterAddingFileFn = function (tag, uploader, type) {
				return function (addedFile) {
					$(tag).triggerHandler('$new_file', addedFile);
					uploader.queue[0].url = WEB_API_URL + '/tags/' + $scope.selectedTag.id + '/' + type + '/';

					uploader.uploadAll();
				};
			};

			$scope.afterUploadingFileFn = function (field, uploader) {
				return function (item, response, status) {
					uploader.queue.length = 0;
					if (status === 200 && response.success) {
						$scope.selectedTag.tag_merchant_configuration[field] = response.url + '?' + new Date();
						toastr.success($translate.instant('TAGS_CONTROLLER.IMAGE_WAS_UPLOADED_SUCCESSFULY'));
						$scope.setNewCXConfig();
					}
				};
			};

			$scope.onWhenAddingFailed = function (item, filter) {
				if (filter.name === 'imageFilter') {
					toastr.error($translate.instant('TAGS_CONTROLLER.ONLY_IMAGE_FILES_PLEASE_SUPPORTED_JPG_JPEG_PNG'));
				} else if (filter.name === 'sizeFilter') {
					toastr.error(
						$translate.instant(
							'TAGS_CONTROLLER.YOUR_IMAGE_IS_TOO_BIG_PLEASE_PROVIDE_A_DIFFERENT_ONE_MAX_SIZE_ONE_MB'
						)
					);
				}
			};

			$scope.getNewUploader = function () {
				var fileUploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				});

				fileUploader.filters.push(bringg_utils.imageFilter());
				fileUploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.TAG));
				fileUploader.onWhenAddingFileFailed = $scope.onWhenAddingFailed;
				return fileUploader;
			};

			$scope.handleUploadButtonClick = function (event, target) {
				angular.element('#' + target).trigger('click');
			};

			$scope._handleUpdateTagResult = function (result) {
				return new Promise(function (resolve) {
					if (result.success) {
						CustomerConfiguration.installRequiredApplications(result.tag.tag_customer_configuration)
							.then(function () {
								toastr.success('Tag updated');
								resolve();
							})
							.catch(function () {
								toastr.error($translate.instant('ERRORS.ERROR_OCCURRED'));
								resolve();
							});
					} else {
						if (result.message) {
							toastr.error(result.message);
						} else {
							toastr.error($translate.instant('ERRORS.ERROR_OCCURRED'));
						}

						resolve();
					}
				});
			};

			$scope.handleTagUpdateClicked = function () {
				if ($scope.cancelReasonsConfigurationEnabled && $scope.cancelReasonsConfiguration.length == 0) {
					dialogSrv.notifyDialog(
						$translate.instant(
							'TAGS_CONTROLLER.THERE_MUST_BE_AT_LEAST_ONE_CANCELLATION_REASON_SET_UP_IN_ORDER_TO_SAVE'
						),
						$translate.instant(
							'TAGS_CONTROLLER.PLEASE_ADD_ONE_REASON_OR_REMOVE_THE_CANCELLATION_FLOW_CONFIGURATION_FROM_THIS_TAG'
						)
					);
					return;
				}
				$scope.selectedTag.tag_reasons_to_cancel_task_configuration = $scope.cancelReasonsConfiguration;
				var clone = angular.copy($scope.selectedTag);

				//remove the uploaders they break the JSON
				if (clone.tag_merchant_configuration) {
					delete clone.tag_merchant_configuration.delivery_pin_uploader;
					delete clone.tag_merchant_configuration.destination_pin_uploader;
					delete clone.tag_merchant_configuration.email_logo_uploader;
					delete clone.tag_merchant_configuration.logo_uploader;
				}

				if (clone.tag_rules_configuration && clone.tag_rules_configuration.disclaimer) {
					var disclaimer = clone.tag_rules_configuration.disclaimer;
					delete clone.tag_rules_configuration.disclaimer;
					_.each(clone.tag_rules_configuration.action_data, function (actionData) {
						if (actionData.value === 'take_signature') {
							actionData.data = Object.assign({}, actionData.data, { disclaimer: disclaimer });
						}
					});
				}

				if ($scope.cancelReasonsConfigurationEnabled) {
					_.each($scope.cancelReasonsConfiguration, function (cancelReason) {
						ReasonToCancelTask.userTypeToFlags(cancelReason);
					});

					clone.tag_reasons_to_cancel_task_configuration = TaskCancelReasonActionsProvider.appendExcluded(
						$scope.cancelReasonsConfiguration,
						$scope.actionDataByReason
					);

					ReasonToCancelTask.updateTagReasonsToCancelTask(
						{ tag_id: $scope.selectedTag.id, tag: clone },
						function () {
							Tag.update({ id: $scope.selectedTag.id, tag: clone }, $scope._handleUpdateTagResult);
						}
					);
				} else {
					Tag.update({ id: $scope.selectedTag.id, tag: clone }, $scope._handleUpdateTagResult);
				}
			};

			$scope._handleTagDestroyResult = function () {
				return Tags.remove($scope.selectedTagId).then(function (response) {
					if (response.success) {
						$scope.tags = response.tags;
						toastr.success('Tag was deleted');
						$scope.selectedTag = null;
					} else {
						if (response.message) {
							toastr.error(response.message);
						} else {
							toastr.error($translate.instant('ERRORS.ERROR_OCCURRED'));
						}
					}
				});
			};

			$scope.addCancelReason = function () {
				$scope.cancelReasonsConfiguration.push({
					reason: '',
					order_type: $scope.reason_types[0].value,
					user_type: ReasonToCancelTask.USER_TYPES.BOTH,
					days_ahead: 0,
					way_point_arrival_type: WAY_POINT_ARRIVAL_TYPES.ANYTIME,
					return_order: true
				});
			};

			$scope.removeCancelReason = function (item) {
				$scope.cancelReasonsConfiguration = _.without(
					$scope.cancelReasonsConfiguration,
					_.find($scope.cancelReasonsConfiguration, item)
				);
			};

			$scope.handleTagDeleteClicked = function (event, tagId) {
				event.stopPropagation();
				var result = confirm('Are you sure you want to delete this tag?');

				if (result) {
					$scope.selectedTagId = tagId;
					return ReasonToCancelTask.deleteTagReasonsToCancelTaskConfiguration(
						{ tag_id: $scope.selectedTagId },
						function () {
							$scope._handleTagDestroyResult();
						}
					);
				}
			};

			$scope.onChangeOrderType = function (reason) {
				if ($scope.isCurbside(reason)) {
					reason.days_ahead = null;
					reason.reschedule_as_virtual_from = null;
					reason.max_attempts = null;
				} else {
					reason.days_ahead = reason.days_ahead || 0;
				}
			};

			$scope.isCurbside = function (reason) {
				return reason.order_type === 6;
			};

			$scope.isSameRun = function (reason) {
				return reason.days_ahead === -1;
			};

			$scope.onDaysAheadChange = function (reason) {
				if ($scope.isSameRun(reason)) {
					reason.reschedule_as_virtual_from = null;
					reason.max_attempts = null;
				}
			};

			$scope.handleAddTagClicked = function () {
				var modalInstance = $uibModal.open({
					backdrop: 'static',
					keyboard: true,
					controller: 'CreateTagController',
					templateUrl: 'scripts/features/tags/create_tag/create-tag.html'
				});

				modalInstance.result.then(
					function (result) {
						Tags.tags.unshift(result);
						$scope.tags = Tags.tags;
						$scope.handleTagClicked(result);
					},
					function () {
						$log.info('Modal dismissed at: ' + new Date());
					}
				);
			};

			$scope.search = { query: '' };
			$scope.handleFilterClicked = function () {
				$scope.tags = _.filter(Tags.tags, function (item) {
					return !_.isString(item.tag) ||
						item.tag.toLowerCase().indexOf($scope.search.query.toString().toLowerCase()) === -1
						? false
						: true;
				});

				$timeout(function () {
					$scope.$apply();
				}, 1);
			};

			$scope.selectTab = function (tab) {
				$scope.activeTab = tab;
			};

			$scope.creatingConfiguration = {};

			$scope.handleTagConfiguration = function (tag, type) {
				$scope.creatingConfiguration[type] = true;
				if (type === 'reasons_to_cancel_task') {
					ReasonToCancelTask.createTagReasonsToCancelTaskConfiguration({ tag_id: tag.id }, function (result) {
						$scope.creatingConfiguration[type] = false;
						if (result.success) {
							$scope.selectedTag['tag_reasons_to_cancel_task_configuration'] =
								result.tag['tag_reasons_to_cancel_task_configuration'];
							$scope.cancelReasonsConfiguration =
								$scope.selectedTag['tag_reasons_to_cancel_task_configuration'] || [];
							$scope.actionDataByReason = TaskCancelReasonActionsProvider.getByReason(
								$scope.cancelReasonsConfiguration
							);

							$scope.add_defaults_to_reasons();
							$scope.cancelReasonsConfigurationEnabled = true;
						} else {
							if (result.rc === 1) {
								toastr.error($translate.instant('TAGS_CONTROLLER.TAG_NOT_FOUND'));
							} else {
								toastr.error(result.message);
							}
						}
					});
				} else {
					Tag['create_tag_' + type + '_configuration']({ id: tag.id }, function (result) {
						$scope.creatingConfiguration[type] = false;
						if (result.success) {
							$scope.selectedTag['tag_' + type + '_configuration'] =
								result.tag['tag_' + type + '_configuration'];
							if (type === 'customer') {
								CrossApplicationService.emit('TAG_SELECTED', tag.id);
							}
						} else {
							toastr.error(result.message);
						}
					});
				}
			};

			$scope.handleDeleteTagConfiguration = function (tag, type) {
				$scope.creatingConfiguration[type] = true;
				if (type === 'reasons_to_cancel_task') {
					ReasonToCancelTask.deleteTagReasonsToCancelTaskConfiguration({ tag_id: tag.id }, function (result) {
						$scope.creatingConfiguration[type] = false;
						if (result.success) {
							$scope.selectedTag['tag_reasons_to_cancel_task_configuration'] = null;
							$scope.cancelReasonsConfiguration = [];
							$scope.cancelReasonsConfigurationEnabled = false;
						} else {
							if (result.rc === 1) {
								toastr.error($translate.instant('TAGS_CONTROLLER.TAG_NOT_FOUND'));
							} else {
								toastr.error(result.message);
							}
						}
					});
				} else {
					Tag['delete_tag_' + type + '_configuration']({ id: tag.id }, function (result) {
						$scope.creatingConfiguration[type] = false;
						if (result.success) {
							$scope.selectedTag['tag_' + type + '_configuration'] = null;
						} else {
							toastr.error(result.message);
						}
					});
				}
			};

			$scope.ruleOptions = {
				'CONFIGURATION.TAGS.RULES.OPTIONS.MANUAL': 0,
				'CONFIGURATION.TAGS.RULES.OPTIONS.AFTER_ARRIVAL': 1,
				'CONFIGURATION.TAGS.RULES.OPTIONS.BEFORE_LEAVING': 2,
				'CONFIGURATION.TAGS.RULES.OPTIONS.DEFAULT': 3,
				'CONFIGURATION.TAGS.RULES.OPTIONS.NOT_ALLOWED': 4
			};

			$scope.reason_types = [
				{ value: 0, label: $translate.instant('TASK_CANCEL_CONFIGURATION.DEFAULT') },
				{ value: 1, label: $translate.instant('TASK_CANCEL_CONFIGURATION.PICKUP') },
				{ value: 2, label: $translate.instant('TASK_CANCEL_CONFIGURATION.DROPOFF') },
				{ value: 3, label: $translate.instant('TASK_CANCEL_CONFIGURATION.PICK_UP_AND_DROPOFF') },
				{ value: 6, label: $translate.instant('TASK_CANCEL_CONFIGURATION.CURBSIDE') }
			];

			$scope.user_types = ReasonToCancelTask.user_types;

			$scope.add_defaults_to_reasons = function () {
				_.each($scope.cancelReasonsConfiguration, function (cancelReason) {
					cancelReason.return_order = cancelReason.return_order ?? true;
					cancelReason.days_ahead = cancelReason.days_ahead || 0;
					ReasonToCancelTask.flagsToUserType(cancelReason);
				});
			};

			$scope.tagRulesConfigurationRuleOptions = TAG_RULES_CONFIGURATION_RULE_OPTIONS;

			$scope.hasTagRuleConfiguration = function (name) {
				return $scope.selectedTag.tag_rules_configuration[name] !== '';
			};

			$scope.setNewCXConfig = function () {
				if (!$scope.isNewCXEnabled) {
					return;
				}
				$scope.newCXConfig = CustomerExperienceConfig.getCXConfig(
					$scope.selectedTag.tag_merchant_configuration,
					$scope.customerExperienceWebAppConfig.disable_cx_color_manipulation
				);
			};
		}
	);
