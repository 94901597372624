'use strict';

angular
	.module('bringgApp')
	.factory('TeamScheduledBreaksResource', function (WEB_API_URL, $resource) {
		var TeamScheduledBreaksResource = $resource(
			WEB_API_URL + '/teams/:id/scheduled_breaks/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/teams/:id/scheduled_breaks/' }
			}
		);

		return TeamScheduledBreaksResource;
	})
	.factory('TeamScheduledBreaksService', function (TeamScheduledBreaksResource) {
		var TeamScheduledBreaksService = {};

		TeamScheduledBreaksService.get = function (driverId) {
			return TeamScheduledBreaksResource.get({ id: driverId }).$promise;
		};

		TeamScheduledBreaksService.update = function (driverId, configuration) {
			var newConfig = new TeamScheduledBreaksResource({ id: driverId, configuration: configuration });
			return newConfig.$update();
		};

		return TeamScheduledBreaksService;
	});
