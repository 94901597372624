'use strict';

angular.module('bringgApp').factory('affirmationAlertsService', function ($sce, $log) {
	var affirmationAlertsService = {};

	affirmationAlertsService.alerts = [];

	affirmationAlertsService._createAlertMarkup = function (alertLevel, alertText) {
		var alertSignType = '';
		switch (alertLevel) {
			case 'alert-success':
				alertSignType = 'ok';
				break;
			case 'alert-warning':
				alertSignType = 'exclamation';
				break;
			case 'alert-info':
				alertSignType = 'info';
				break;
			case 'alert-danger':
				alertSignType = 'exclamation';
				break;
		}

		var alertMarkup =
			'<span class="glyphicon glyphicon-' +
			alertSignType +
			'-sign alert-icon" /><span class="alert-text">' +
			alertText +
			'</span>';
		return alertMarkup;
	};

	affirmationAlertsService.removeAlert = function (alertId) {
		var alert = _.find(affirmationAlertsService.alerts, { id: alertId });
		if (alert) {
			var alertIndex = affirmationAlertsService.alerts.indexOf(alert);
			affirmationAlertsService.alerts.splice(alertIndex, 1);
		}
	};

	affirmationAlertsService.alertAlreadyExists = function (alertId, alertLevel, alertText) {
		var htmlElement = $sce.trustAsHtml(affirmationAlertsService._createAlertMarkup(alertLevel, alertText));
		var found = _.find(affirmationAlertsService.alerts, function (alert) {
			return (
				alert.id === alertId && alert.level === alertLevel && alert.text.toString() === htmlElement.toString()
			);
		});

		return found;
	};

	affirmationAlertsService.addAlert = function (alertId, alertLevel, alertText) {
		if (affirmationAlertsService.alertAlreadyExists(alertId, alertLevel, alertText)) {
			$log.debug('affirmationAlertsService skipping alert because its already shown');
			return;
		}

		var alertMarkup = affirmationAlertsService._createAlertMarkup(alertLevel, alertText);
		var alert = {
			id: alertId,
			level: alertLevel,
			text: $sce.trustAsHtml(alertMarkup)
		};
		affirmationAlertsService.alerts.push(alert);
	};

	affirmationAlertsService.clearAllAlerts = function () {
		affirmationAlertsService.alerts.length = 0;
	};

	return affirmationAlertsService;
});
