'use strict';

angular.module('bringgApp').factory('BroadcastTasksService', function ($uibModal) {
	var BroadcastTasksService = {};

	BroadcastTasksService.broadcastToDriversDialog = function (taskIds, drivers) {
		return $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'BroadcastOrderModalController',
			templateUrl: 'scripts/features/broadcast_task/broadcast-task-modal.html',
			resolve: {
				taskIds: function () {
					return angular.copy(taskIds);
				},
				drivers: function () {
					return angular.copy(drivers);
				}
			}
		});
	};

	return BroadcastTasksService;
});
