'use strict';

angular
	.module('bringgApp')
	.controller(
		'clientLoginController',
		function (
			$scope,
			$location,
			Authentication,
			AuthenticationUtils,
			vcRecaptchaService,
			$routeParams,
			RECAPTCHA_SITE_KEY,
			MerchantConfigurations,
			MainNavigationVer2Service,
			toastr,
			$translate
		) {
			$scope.loginForm = {};
			$scope.show_captcha = false;
			$scope.show_login = false;
			$scope.show_resend_confirmation = false;
			$scope.merchantIdFromRegion = null;
			$scope.isSSOFlow = false;

			$scope.recaptcha = null;
			$scope.widgetId = null;
			$scope.model = {
				key: RECAPTCHA_SITE_KEY
			};

			$scope.setRecaptcha = function (recaptcha) {
				$scope.recaptcha = recaptcha;
			};
			$scope.setWidgetId = function (widgetId) {
				$scope.widgetId = widgetId;
				if ($scope.show_captcha) {
					vcRecaptchaService.reload($scope.widgetId);
				}
			};
			$scope.cbExpiration = function () {
				$scope.recaptcha = null;
			};
			$scope.selectRegion = function (data) {
				$scope.merchantIdFromRegion = data.uuid;
				$scope.region = data.region;
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (
					AuthenticationUtils.isPartialLogin() ||
					($location.path().includes('login') && angular.isDefined($location.search().authentication_token))
				) {
					//partial or sso
					$scope.show_login = 'pending';
				} else if (isLoggedIn) {
					if ($location.path().includes('login')) {
						MerchantConfigurations.$refresh().then(function () {
							$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
						});
					}
					$scope.show_login = 'hide';
				} else if (!$location.path().includes('login')) {
					$location.path('/login/');
					$scope.show_login = 'show';
				} else {
					$scope.show_login = 'show';
				}
			});

			var sortByName = function (a, b) {
				return a > b ? +1 : a < b ? -1 : 0;
			};

			$scope.login = function () {
				Authentication.login(
					$scope.loginForm.email,
					$scope.loginForm.password,
					$scope.loginForm.merchantId ? $scope.loginForm.merchantId.uuid : $scope.merchantIdFromRegion,
					$scope.region ? $scope.region : null,
					$scope.show_captcha ? vcRecaptchaService.getResponse($scope.widgetId) : '',
					$scope.recaptcha_qa_token,
					function callback(merchantsData) {
						$scope.merchantsData = merchantsData.sort(function (merchant) {
							return sortByName(merchant.region) || sortByName(merchant.name.toLowerCase());
						});
						$scope.show_captcha = false;
						$scope.show_resend_confirmation = false;
						$scope.$apply();
					},
					function captchaCB(recaptchaToken) {
						if (recaptchaToken) {
							$scope.stoken = recaptchaToken;
							$scope.show_captcha = true;
							if ($scope.widgetId !== null) {
								vcRecaptchaService.reload($scope.widgetId);
							}
						} else {
							$scope.show_captcha = false;
						}

						$scope.$apply();
					},
					function failureCB(data) {
						if (_.isObject(data) && data.resend_confirmation_available) {
							$scope.show_resend_confirmation = true;
						} else {
							$scope.show_resend_confirmation = false;
						}

						$scope.$apply();
					}
				);
			};

			function getRegion() {
				return $scope.region || localStorage.getItem('bringg-region');
			}

			$scope.resendConfirmation = function () {
				Authentication.resendConfirmationEmail({
					email: $scope.loginForm.email,
					password: $scope.loginForm.password,
					merchantUuid: $scope.loginForm.merchantId
						? $scope.loginForm.merchantId.uuid
						: $scope.merchantIdFromRegion,
					region: getRegion(),
					recaptchaToken: $scope.show_captcha ? vcRecaptchaService.getResponse($scope.widgetId) : '',
					qaToken: $scope.recaptcha_qa_token
				})
					.then(function () {
						toastr.success($translate.instant('LOGIN.CONFIRMATION_SENT'));
					})
					.catch(function () {
						toastr.error($translate.instant('LOGIN.RESEND_CONFIRMATION_FAILED'));
					});
			};

			$scope.keys = [{ code: 13, action: $scope.login }];
			$scope.$on('keydown', function (msg, code) {
				if (!$scope.loginForm.$invalid) {
					$scope.keys.forEach(function (o) {
						if (o.code !== code) {
							return;
						}
						o.action();
						$scope.$apply();
					});
				}
			});

			if ($routeParams.recaptcha_qa_token !== null) {
				$scope.recaptcha_qa_token = $routeParams.recaptcha_qa_token;
			} else if ($routeParams.code) {
				Authentication.codedLogin($routeParams.code, $routeParams.session);
			}
		}
	);
