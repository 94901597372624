'use strict';

angular.module('bringgApp').factory('ReasonToReassignTasksService', function ($rootScope, BringgSDK) {
	function reasonToReassignTasksService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.reasonToReassignTasks[method].apply(bringg.reasonToReassignTasks, args);
			});
		};
	}

	var ReasonToReassignTasksService = {};

	ReasonToReassignTasksService.getAll = reasonToReassignTasksService('getAll');

	return ReasonToReassignTasksService;
});
