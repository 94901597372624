'use strict';

angular.module('bringgApp').filter('userGridFilter', function () {
	return function (userId, row) {
		if (row.groupHeader) {
			var treeNode = row.treeNode;
			while (!_.isEmpty(treeNode.children)) {
				treeNode = _.first(treeNode.children);
			}

			if (treeNode && treeNode.row && treeNode.row.entity) {
				return treeNode.row.entity.user_name;
			}
			return null;
		}

		if (!row.entity) {
			return '';
		}
		return row.entity.user_name;
	};
});
