'use strict';
/*global $:false */
/*global geo_position_js:false */

angular
	.module('geolocationService', [])
	.factory('Geolocation', function (Authentication, WEB_API_URL, $log, GOOGLE_STATIC_MAP_API_KEY) {
		var geolocationIntervalId;
		var userId;

		function success_callback(that, p) {
			$log.info('Got location (' + p.coords.latitude + ',' + p.coords.longitude + ')');

			$.ajax({
				url: WEB_API_URL + '/api/user/',
				type: 'PUT',
				data: { location_data: p },
				success: function (data) {
					$log.info('Updating position success');
				}
			});
		}

		function error_callback() {
			$log.error('Could not get location');
		}

		function getGeoLocation(that) {
			geo_position_js.getCurrentPosition(
				function (p) {
					success_callback(that, p);
				},
				error_callback,
				{ enableHighAccuracy: true }
			);
		}

		var that = { service: this, user_id: null };

		var generateStaticMapExtended = function (markers, cameraCenterLat, cameraCenterLong) {
			var markersString = markers
				.map(function (m) {
					m.icon = m.icon ? encodeURIComponent(m.icon) : undefined;
					m.color = m.color || 'blue';

					var style = m.icon ? 'icon:' + m.icon : 'color:' + m.color;

					return '&markers=' + style + '|' + m.lat + ',' + m.long;
				})
				.join('');

			return (
				'https://maps.googleapis.com/maps/api/staticmap?key=' +
				GOOGLE_STATIC_MAP_API_KEY +
				'&size=600x300&maptype=roadmap' +
				markersString +
				'&center=' +
				cameraCenterLat +
				',' +
				cameraCenterLong
			);
		};

		var generateStaticMap = function (markerLat, markerLong, cameraCenterLat, cameraCenterLong) {
			return generateStaticMapExtended([{ lat: markerLat, long: markerLong }], cameraCenterLat, cameraCenterLong);
		};

		return {
			start: function (userId) {
				that.user_id = userId;
				if (geo_position_js.init()) {
					//var that = {timer : this, callback : getGeoLocation};
					this.geolocationIntervalId = setInterval(function () {
						getGeoLocation(that);
					}, 15000);
				} else {
					alert('Functionality not available');
				}
			},
			stop: function () {
				var geolocationIntervalId;

				if (geolocationIntervalId !== undefined) {
					clearInterval(geolocationIntervalId);
					geolocationIntervalId = undefined;
				}
			},
			generateStaticMapExtended: generateStaticMapExtended,
			generateStaticMap: generateStaticMap
		};
	});
