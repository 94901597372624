'use strict';

angular
	.module('bringgApp')
	.controller(
		'alertsPopoverController',
		function ($scope, $uibModalInstance, Alerts, $location, $rootScope, ypos, xpos, toastr, $timeout) {
			Alerts.loadLatest().then(function (alerts) {
				$scope.alerts = alerts;
			});

			$rootScope.$on('alerts update', function () {
				$scope.alerts = Alerts.getLatestAlerts();
			});

			$timeout(function () {
				angular.element('.alerts-popover').css('top', ypos + 10);
				// if there is place to the popover open under the bell, if not push to left from the ball( 600 = popover width, 25 padding space)
				var popoverX = {};
				var minimalXCssPadding = 25;
				var popoverWidth = 600;
				var minimalXPosPadding = popoverWidth / 2;

				popoverX.value = minimalXCssPadding;
				if (xpos < minimalXPosPadding) {
					popoverX.side = 'left';
				} else if (window.innerWidth - xpos < minimalXPosPadding) {
					popoverX.side = 'right';
				} else {
					popoverX.value = xpos - minimalXPosPadding;
					popoverX.side = 'left';
				}
				angular.element('.alerts-popover').css(popoverX.side, popoverX.value);
			});

			$scope.handleShowAllClick = function () {
				$uibModalInstance.close();
			};

			$scope.handleCloseClick = function () {
				$uibModalInstance.close();
			};

			$scope.handleRemoveAllAlertsClick = function () {
				Alerts.dismissAll().then(
					function (result) {
						toastr.success(result);
						$uibModalInstance.close();
					},
					function (reason) {
						toastr.error(reason);
					}
				);
			};
		}
	);
