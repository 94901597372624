'use strict';

angular
	.module('bringgApp.directives')
	.directive('uiSelectRequired', function () {
		return {
			require: 'ngModel',
			link: function (scope, elm, attrs, ctrl) {
				ctrl.$validators.uiSelectRequired = function (modelValue) {
					return !_.isEmpty(modelValue);
				};
			}
		};
	})
	.directive('uiSelectOpenOnFocus', function ($timeout) {
		return {
			require: 'uiSelect',
			restrict: 'A',
			link: function ($scope, el, attrs, uiSelect) {
				$scope.closing = false;
				$scope.cooling = false;

				angular.element(uiSelect.focusser).on('focus', function () {
					if (!$scope.closing && !$scope.cooling) {
						uiSelect.activate();
					}
				});

				$scope.$watch('closing', function (newValue, oldValue) {
					if (newValue === oldValue || !$scope.closing) {
						return;
					}

					uiSelect.close(true);
					$scope.cooling = true;
					$scope.closing = false;

					$timeout(function () {
						$scope.cooling = false;
					}, 500);
				});

				angular.element(uiSelect.searchInput).on('blur', function () {
					if ($scope.closing) {
						return;
					}
					$scope._delayClose(500);
				});

				$scope.$on('uis:close', function () {
					$scope._delayClose(100);
				});

				$scope._delayClose = function (timeout) {
					$timeout(function () {
						$scope.closing = true;
					}, timeout);
				};
			}
		};
	});
