'use strict';
/*global Slick:false */

angular.module('bringgApp').directive('selectRolesWithQuantity', function (DispatchListService, $translate, $timeout) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			userTypesByTeam: '='
		},
		templateUrl: 'scripts/features/optimize/steps/select-roles-with-quantity.html',
		link: function (scope) {
			scope.activeTeamIndex = 0;
			scope.isTeamSelected = function (idx) {
				return scope.activeTeamIndex === idx;
			};
			scope.selectTeam = function (idx) {
				scope.activeTeamIndex = idx;
			};
			scope.hasAnySelection = function (team) {
				return _.some(team.userTypes, 'quantity');
			};
		}
	};
});
