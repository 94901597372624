'use strict';
/*global Howl */

angular
	.module('bringgApp.services')
	.constant('SOUND_TYPE', {
		DOOR_BELL: 0,
		DOOR_BELL_TWICE: 1,
		CANCELED_TASK: 2,
		DOOR_BELL_LOUD: 3,
		DOOR_BELL_TWICE_LOUD: 4,
		CANCELED_TASK_LOUD: 5
	})
	.constant('SOUND_PATHS', [
		'sounds/door_bell.mp3',
		'sounds/door_bell_twice.mp3',
		'sounds/canceled_task.mp3',
		'sounds/door_bell_loud.mp3',
		'sounds/door_bell_twice_loud.mp3',
		'sounds/canceled_task_loud.mp3'
	])
	.factory(
		'SoundPlayerService',
		function ($rootScope, MerchantConfigurations, Authentication, SOUND_TYPE, SOUND_PATHS, $interval, $log) {
			var SoundPlayerService = {
				loopingSounds: {}
			};

			if (window.Howl && window.Howler) {
				SoundPlayerService._player = SOUND_PATHS.map(function (path) {
					return new Howl({
						src: [path]
					});
				});
			}

			$rootScope.$on('new task', function (event, newTask, opts) {
				const options = _.defaults({}, opts, {
					notifyChanges: true
				});

				if (!options.notifyChanges) {
					return;
				}

				// task acknowledge flow will play sound before the ack
				if (!MerchantConfigurations.enable_task_acknowledge_flow) {
					SoundPlayerService.play({ task: newTask });
				}
			});

			const SOUND_THROTTLE_TIME = 1000;
			const throttledPlaySound = _.throttle(
				function (soundType) {
					SoundPlayerService._player[soundType].play();
				},
				SOUND_THROTTLE_TIME,
				{ leading: true, trailing: false }
			);

			SoundPlayerService.play = function (item) {
				var currentUser = Authentication.currentUser();
				if (
					!currentUser.admin &&
					MerchantConfigurations.sound_on_new_order &&
					!MerchantConfigurations.enable_looping_alert_sound
				) {
					if (
						!MerchantConfigurations.enable_teams ||
						_.intersection(currentUser.team_ids, item.task.team_ids) > 0
					) {
						if (SoundPlayerService._player) {
							var soundType =
								(item && item.flowUiConfig && item.flowUiConfig.sound_index) || SOUND_TYPE.DOOR_BELL;
							throttledPlaySound(soundType);
						}
					}
				}
			};

			SoundPlayerService.playSoundAt = function (index) {
				var currentUser = Authentication.currentUser();
				if (!currentUser.admin && SoundPlayerService._player) {
					SoundPlayerService._player[index].play();
				}
			};

			SoundPlayerService.initLoopingSounds = function (loopInterval, soundType) {
				var loopingSoundId = _.uniqueId('loopingSound-');

				SoundPlayerService.loopingSounds[loopingSoundId] = {
					intervals: {},
					playing: false
				};

				soundType = soundType || SOUND_TYPE.DOOR_BELL_TWICE_LOUD;
				loopInterval = loopInterval || 2000;

				return {
					play: function (id) {
						try {
							if (
								SoundPlayerService.loopingSounds[loopingSoundId].intervals[id] ||
								SoundPlayerService.loopingSounds[loopingSoundId].playing
							) {
								return;
							}

							var interval = $interval(function () {
								SoundPlayerService.playSoundAt(soundType);
							}, loopInterval);

							SoundPlayerService.loopingSounds[loopingSoundId].playing = true;
							SoundPlayerService.loopingSounds[loopingSoundId].intervals[id] = interval;
						} catch (error) {
							$log.warn(error);
						}
					},
					stop: function (id) {
						var intervalId = SoundPlayerService.loopingSounds[loopingSoundId].intervals[id];

						$interval.cancel(intervalId);
						SoundPlayerService.loopingSounds[loopingSoundId].playing = false;

						delete SoundPlayerService.loopingSounds[loopingSoundId].intervals[id];
					},
					clear: function () {
						delete SoundPlayerService.loopingSounds[loopingSoundId];
					}
				};
			};

			return SoundPlayerService;
		}
	);
