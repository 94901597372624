'use strict';

angular
	.module('bringgApp')
	.factory(
		'OptimizationPreviewService',
		function ($uibModal, WayPointsService, TimeManagerService, optimizationTypesService) {
			var OptimizationPreviewService = {};

			OptimizationPreviewService.optimizationRequests = [];
			OptimizationPreviewService.optimizationResponses = [];
			OptimizationPreviewService.sharedData = { changed: false };
			OptimizationPreviewService.preserveCurrentRuns = false;

			OptimizationPreviewService.open = function (
				optimizationData,
				team,
				type,
				dispatch,
				tasks,
				plannedRoutes,
				runIdToBreak,
				optimizationDate,
				serviceAreasById,
				isManual
			) {
				OptimizationPreviewService.sharedData = { changed: false };
				OptimizationPreviewService.optimizationType = type;

				return $uibModal.open({
					backdrop: 'static',
					keyboard: false,
					controller: 'OptimizationPreviewController',
					templateUrl: 'scripts/features/optimization_preview/optimization-preview.html',
					windowClass: 'optimization-preview',
					size: 'lg',
					resolve: {
						dispatch: function () {
							return dispatch;
						},
						optimizationData: function () {
							return optimizationData;
						},
						type: function () {
							return type;
						},
						team: function () {
							return team;
						},
						tasks: function () {
							return tasks;
						},
						plannedRoutes: function () {
							return plannedRoutes;
						},
						runIdToBreak: function () {
							return runIdToBreak;
						},
						serviceAreasById: function () {
							return serviceAreasById || {};
						},
						optimizationDate: function () {
							return optimizationDate;
						},
						isManual: function () {
							return isManual;
						}
					}
				}).result;
			};

			OptimizationPreviewService.exportData = function (data, optimizationType, routeInfoByIndex) {
				var result = [];

				_.each(data, function (userItem, userItemKey) {
					if (userItemKey !== 'undefined') {
						//filter out the tasks that are not assigned to anyone
						var currentBreak = userItem.break;
						var runId;

						if (userItem.tasks && userItem.tasks[0] && routeInfoByIndex[userItem.tasks[0].route_idx]) {
							result.push(routeInfoByIndex[userItem.tasks[0].route_idx]);
						}

						_.each(userItem.tasks, function (task) {
							var wp = WayPointsService.getDropOffWayPoint(task);
							if (!wp) {
								return;
							}

							var eta = OptimizationPreviewService._convertTime(wp.scheduled_at);
							var etl = wp.etl
								? OptimizationPreviewService._convertTime(wp.etl)
								: OptimizationPreviewService._convertTime(wp.scheduled_at) + task.temp_time_on_site;

							var dataObject = {
								task_id: task.id,
								priority: task.priority,
								way_point_id: wp.id,
								eta: eta,
								etl: etl,
								depot_etl: task.temp_depot_etl
							};

							if (task.priority === 1) {
								dataObject.has_to_leave = wp.has_to_leave || task.temp_has_to_leave;
							}

							if (!_.isNull(task.user_type_id)) {
								dataObject.user_type_id = parseInt(task.user_type_id);
							}
							if (!_.isNull(task.driver_idx)) {
								dataObject.driver_idx = parseInt(task.driver_idx);
							}
							if (!_.isNull(task.route_idx) && !_.isUndefined(task.route_idx)) {
								dataObject.route_idx = parseInt(task.route_idx);

								if (optimizationType === 'custom_tasks') {
									dataObject.run_id = parseInt(task.route_idx);
									runId = parseInt(task.route_idx);
								}
							}
							if (
								optimizationTypesService.isNamed(optimizationType) ||
								optimizationType === 'custom_tasks'
							) {
								dataObject.user_id = parseInt(task.user_id);
							}

							result.push(dataObject);
						});

						if (currentBreak) {
							if (runId) {
								result.push(Object.assign({ run_id: runId }, currentBreak));
							} else {
								result.push(currentBreak);
							}
						}
					}
				});

				return result;
			};

			OptimizationPreviewService._convertTime = function (date) {
				if (date instanceof Date) {
					return parseInt(date / 1000);
				}

				return parseInt(new Date(date) / 1000);
			};

			OptimizationPreviewService.buildOptimizationRequests = function (tasks, optimizationDate) {
				var PERIOD = 5,
					START_HOUR = 0,
					END_HOUR = 23;
				var tasksIds = _.map(tasks, function (task) {
					return task.id;
				});

				var diversIds = _.uniq(
					_.map(tasks, function (task) {
						return task.user_id;
					})
				);

				var teamId = tasks[0].team_id;

				return {
					team_id: teamId,
					optimize_for: 'custom',
					optimization_type: 2, //named_vrp
					custom_period: {
						start_hour: START_HOUR,
						end_hour: END_HOUR
					},
					scheduled_date: TimeManagerService.format(optimizationDate, 'YYYY-MM-DD'),
					period: PERIOD,
					driver_ids: diversIds,
					task_ids: tasksIds
				};
			};

			return OptimizationPreviewService;
		}
	);
