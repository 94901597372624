'use strict';
angular.module('bringgApp.directives').directive('slickGridWrapper', function () {
	return {
		restrict: 'E',
		templateUrl: 'scripts/directives/slick-grid-wrapper/slick-grid-wrapper.html',
		scope: {
			gridId: '@',
			data: '=',
			columns: '=',
			groupByValues: '=?',
			filterObject: '=',
			filterFunction: '=?',
			grid: '=grid',
			onSelection: '=?',
			onInit: '=?',
			selectedItems: '=?',
			selectedRows: '=?',
			selectActiveRow: '=?',
			sortFields: '=?',
			getItemMetadata: '=?',
			storeColumnsWidth: '@',
			filteredItems: '=?'
		},
		controller: 'SlickGridWrapperController'
	};
});
