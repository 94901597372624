'use strict';

angular.module('bringgApp').factory('CsvUploadFieldsService', function () {
	var findRepeatingFieldName = function (fields) {
		var names = {};
		for (var i = fields.length - 1; i > 0; i--) {
			var name = fields[i].field_name;
			if (name === 'task_note') {
				continue;
			}
			if (names[name] === true) {
				return name;
			}
			names[name] = true;
		}
		return null;
	};

	var excelColumnToNumber = function (x) {
		var result = 0,
			multiplier = 1;
		for (var i = x.length - 1; i >= 0; i--) {
			var value = x[i].charCodeAt(0) - 'A'.charCodeAt(0) + 1;
			result = result + value * multiplier;
			multiplier = multiplier * 26;
		}
		return result;
	};

	var columnNumberToExcelColumn = function (n) {
		var dividend = n;
		var excelColumn = '';
		var modulo;

		while (dividend > 0) {
			modulo = (dividend - 1) % 26;
			excelColumn = String.fromCharCode(65 + modulo) + excelColumn;
			dividend = Math.floor((dividend - modulo) / 26);
		}

		return excelColumn;
	};

	var nextFieldIndex = function (index) {
		if (_.isUndefined(index) || _.isNull(index)) {
			return 'A';
		}
		return _.isString(index) ? columnNumberToExcelColumn(1 + excelColumnToNumber(index)) : 1 + index;
	};

	return {
		findRepeatingFieldName: findRepeatingFieldName,
		excelColumnToNumber: excelColumnToNumber,
		columnNumberToExcelColumn: columnNumberToExcelColumn,
		nextFieldIndex: nextFieldIndex
	};
});
