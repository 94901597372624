'use strict';

angular.module('bringgApp.filters').filter('taskCancelReasonFilter', function () {
	return function (taskCancelReasonId) {
		switch (taskCancelReasonId) {
			case 0:
				return 'Customer was not at destination';
			case 1:
				return 'Customer refused to accept';
			case 2:
				return 'Incorrect address';
			case 3:
				return 'Damaged goods';
			case 4:
				return 'Accident / technical difficulties';
			case 4:
				return 'Other';
			default:
				return 'N/A';
		}
	};
});
