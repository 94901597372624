'use strict';

angular.module('bringgApp.services').factory('DriverPinGalleryService', function ($uibModal, $q) {
	var DriverPinGalleryService = {};

	DriverPinGalleryService.open = function (currentSelection) {
		var deferred = $q.defer();
		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'DriverPinGalleryController',
			templateUrl: 'scripts/features/configuration/driver_pin_gallery/driver-pin-gallery.html',
			resolve: {
				currentSelection: function () {
					return currentSelection;
				}
			}
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return DriverPinGalleryService;
});
