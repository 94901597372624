'use strict';
/*globals google:false */

angular.module('bringgApp').factory('GeoJsonService', function () {
	var validTypes = [
		'Point',
		'MultiPoint',
		'LineString',
		'MultiLineString',
		'Polygon',
		'MultiPolygon',
		'GeometryCollection',
		'Feature',
		'FeatureCollection'
	];
	return {
		isValidGeoJson: function (json) {
			// this is a very crude check but enough for client-side
			var parsed = JSON.parse(json);
			return _.isString(parsed.type) && _.includes(validTypes, parsed.type) && _.isArray(parsed.features);
		},

		getFeatureCenter: function (feature) {
			var coordinates = null;
			if (feature.geometry.type === 'Polygon') {
				var bounds = new google.maps.LatLngBounds();
				coordinates = feature.geometry.coordinates[0];
				for (var i = 0; i < coordinates.length; i++) {
					bounds.extend(new google.maps.LatLng(coordinates[i][1], coordinates[i][0]));
				}
				return bounds.getCenter();
			} else if (feature.geometry.type === 'Point') {
				coordinates = feature.geometry.coordinates;
				return new google.maps.LatLng(coordinates[1], coordinates[0]);
			}

			console.error('feature type', feature.geometry.type, 'is not supported');
			return null;
		}
	};
});
