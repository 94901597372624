'use strict';

angular
	.module('bringgApp')
	.constant('ENABLE_REASSIGN_REASONS_TYPES', {
		DISABLED: 0,
		REASSIGN_ACTIVE_TASK: 1,
		REASSIGN_AUTO_ASSIGNED_TASK: 2,
		ANY_MANUAL_ASSIGN: 3
	})
	.constant('REASSIGN_REASON_TYPES', {
		ALL: 0,
		ACTIVE_TASK: 1,
		MANUAL_REASSIGNMENT: 2,
		MANUAL_ASSIGNMENT: 3
	});
