/*global moment:false */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('timeago', function (TimezoneService) {
		return function (date, task) {
			if (_.isNull(date) || _.isUndefined(date)) {
				return '-- : --';
			}

			date = task ? TimezoneService.getDateByTask(task, date) : moment(date);

			return moment(date).calendar();
		};
	})
	.filter('humanize', function (TimezoneService, TimeManagerService) {
		return function (date, format, task) {
			if (!date) {
				return;
			}

			date = task ? TimezoneService.getDateByTask(task, date) : moment(date);

			if (format) {
				return TimeManagerService.format(date, format);
			} else {
				return TimeManagerService.format(date, 'dddd, MMMM Do YYYY, h:mm A');
			}
		};
	})
	.filter('minutes_ago', function () {
		return function (date) {
			return moment(date).fromNow();
		};
	})
	.filter('utc_minutes_ago', function () {
		return function (date) {
			return moment(date + '+0000').fromNow();
		};
	})
	.filter('time_only', function (TimezoneService, TimeManagerService) {
		return function (date, task) {
			if (!date) {
				return '-- : --';
			}

			var convertedDate = task ? TimezoneService.getDateByTask(task, date) : moment(date);

			return TimeManagerService.format(convertedDate, 'LT');
		};
	})
	.filter('date_only', function (TimezoneService) {
		return function (date, task) {
			if (!date) {
				return '-- : --';
			}

			var convertedDate = task ? TimezoneService.getDateByTask(task, date) : moment(date);

			return convertedDate.format('L');
		};
	})
	.filter('time_only_with_seconds', function (TimezoneService, TimeManagerService) {
		return function (date, task) {
			if (!date) {
				return '-- : --';
			}

			var convertedDate = task ? TimezoneService.getDateByTask(task, date) : moment(date);

			return TimeManagerService.format(convertedDate, 'LTS');
		};
	})
	.filter('time_only_by_team', function (TimezoneService, TimeManagerService) {
		return function (date, teamId) {
			if (!date) {
				return '-- : --';
			}

			var convertedDate = teamId ? TimezoneService.getDateByTeam(teamId, date) : moment(date);

			return TimeManagerService.format(convertedDate, 'LT');
		};
	})
	.filter('iso8601_locale_time_only', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate);
			return TimeManagerService.format(then, 'LT');
		};
	})
	.filter('locale_time_only', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate + '+0000');
			return TimeManagerService.format(then, 'LT');
		};
	})
	.filter('utc_time_to_locale', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate + '+0000');
			return TimeManagerService.format(then, 'L LT');
		};
	})
	.filter('utc_iso8601_time_to_locale', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate);
			return TimeManagerService.format(then, 'L LT');
		};
	})
	.filter('utc_time_to_system_timezone', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate);
			return TimeManagerService.format(then.local(), 'L hh:mm a');
		};
	})
	.filter('date_iso8601_to_locale', function () {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate);
			return then.format('L');
		};
	})
	.filter('date_to_locale', function () {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			var then = moment(otherDate + ' +0000');
			return then.format('L');
		};
	})
	.filter('human_time', function (TimezoneService, TimeManagerService) {
		return function (otherDate, task) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}

			var then = task ? TimezoneService.getDateByTask(task, otherDate) : moment(otherDate);

			return TimeManagerService.format(then, 'L LT');
		};
	})
	.filter('timezone', function (TimezoneService) {
		return function (text, task) {
			if (!task) {
				return text;
			}

			var timeZone = TimezoneService.getTaskTimezone(task, { formatTimezone: true }) || '';

			return text + ' ' + timeZone;
		};
	})
	.filter('timezone_by_team', function (TimezoneService) {
		return function (text, teamId) {
			if (!teamId) {
				return text;
			}

			var timeZone = TimezoneService.getTeamTimezone(teamId, { formatTimezone: true }) || '';

			return text + ' ' + timeZone;
		};
	})
	.filter('human_date', function (TimeManagerService) {
		return function (otherDate) {
			if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
				return '-- : --';
			}
			return TimeManagerService.format(moment(otherDate), 'dddd, MMMM Do YYYY');
		};
	})
	.filter('byDate', [
		'',
		function () {
			function isToday(otherDate) {
				if (_.isNull(otherDate) || _.isUndefined(otherDate)) {
					return false;
				}
				var today = new Date();
				return today.toDateString() === new Date(otherDate).toDateString();
			}

			return function (taskList) {
				if (!taskList) {
					return [];
				}
				return $.map(taskList, function (task) {
					if (isToday(task.scheduled_at)) {
						return task;
					}
				});
			};
		}
	])
	.filter('reports_time_passed', function () {
		return function (item, first, second) {
			if (!_.isNull(first) && !_.isNull(second)) {
				return moment
					.duration(new Date(first) - new Date(second))
					.as('minutes')
					.toFixed(2);
			} else {
				return 0;
			}
		};
	})
	.filter('daterange', function () {
		return function (scheduled_tasks, date_range) {
			var result = [],
				now = new Date().getTime();

			if (_.isUndefined(date_range) || _.isEmpty(date_range)) {
				return scheduled_tasks;
			}

			var seperator_pos = date_range.indexOf(' - '),
				start_date = date_range.substr(0, seperator_pos),
				end_date = date_range.substr(seperator_pos + 3);

			// date filters
			start_date = start_date && !isNaN(Date.parse(start_date)) ? Date.parse(start_date) : 0;
			end_date = end_date && !isNaN(Date.parse(end_date)) ? Date.parse(end_date) : now;

			// if the conversations are loaded
			if (scheduled_tasks && scheduled_tasks.length > 0) {
				scheduled_tasks.forEach(function (task) {
					var scheduled_at = new Date(task.scheduled_at).getTime();

					if (scheduled_at >= start_date && scheduled_at <= end_date) {
						result.push(task);
					}
				});

				return result;
			}
		};
	})
	.filter('time', function () {
		var conversions = {
			ss: angular.identity,
			mm: function (value) {
				return value * 60;
			},
			hh: function (value) {
				return value * 3600;
			}
		};

		var padding = function (value, length) {
			var zeroes = length - ('' + value).length,
				pad = '';
			while (zeroes-- > 0) {
				pad += '0';
			}
			return pad + value;
		};

		return function (value, unit, format, isPadded) {
			var totalSeconds = conversions[unit || 'ss'](value),
				hh = Math.floor(totalSeconds / 3600),
				mm = Math.floor((totalSeconds % 3600) / 60),
				ss = totalSeconds % 60;

			format = format || 'hh:mm:ss';
			isPadded = angular.isDefined(isPadded) ? isPadded : true;
			hh = isPadded ? padding(hh, 2) : hh;
			mm = isPadded ? padding(mm, 2) : mm;
			ss = isPadded ? padding(ss, 2) : ss;

			return format.replace(/hh/, hh).replace(/mm/, mm).replace(/ss/, parseInt(ss));
		};
	})
	.filter('one_hour_segment', function () {
		return function (hourOfDay, format) {
			var hour = moment(moment.utc().hour(hourOfDay).minute(0).second(0).toDate());
			var nextHour = moment(hour).add(1, 'hour');
			format = format || 'hh:mm';
			return hour.format(format) + ' - ' + nextHour.format(format);
		};
	})
	.filter('one_hour_segment_24h', function (one_hour_segmentFilter) {
		return function (hourOfDay) {
			return one_hour_segmentFilter(hourOfDay, 'HH:mm');
		};
	})
	.filter('duration', function () {
		return function (seconds) {
			seconds = parseFloat(seconds);
			var days = moment.duration(seconds, 'seconds').days();

			var format = days > 0 ? 'd [days], HH:mm:ss' : 'HH:mm:ss';

			return moment('1900-01-01 00:00:00').subtract(1, 'd').add(seconds, 'seconds').format(format);
		};
	})
	.filter('humanize_duration', function () {
		return function (seconds) {
			return moment.duration(seconds * 1000).humanize();
		};
	})
	.filter('duration_from_seconds', function () {
		return function (seconds) {
			return moment.duration(seconds, 'seconds').format('HH:mm:ss', { trim: false });
		};
	})
	.filter('seconds_to_hh_mm', function () {
		return function (seconds) {
			return moment.duration(seconds, 'seconds').format('hh:mm', { trim: false });
		};
	})
	.filter('have_worked', function () {
		return function (time) {
			return moment.duration(time).asSeconds() > 0;
		};
	})
	.filter('duration_from_interval', function (TimeManagerService) {
		return function (seconds) {
			var days = moment.duration(seconds, 'seconds').days();

			var format = days > 0 ? 'd [days], HH:mm:ss' : 'HH:mm:ss';

			return TimeManagerService.format(moment.duration(seconds), format, { trim: false });
		};
	})
	.filter('alerts_date_format', function ($translate, TimeManagerService) {
		return function (date) {
			var baseDate = TimeManagerService.format(date, '[' + $translate.instant('ACTIVITY_LOG.AT') + '] LT');
			var isSameDay = moment(date).isSame(moment(), 'day');
			var isSameYear = moment(date).isSame(moment(), 'year');
			var isSameWeek = moment(date).isSame(moment(), 'week');

			if (isSameDay) {
				return $translate.instant('OPTIMIZE_SELECTED_ROUTE.TODAY') + ' ' + baseDate;
			}
			if (isSameWeek) {
				return moment(date).format('dddd') + ' ' + baseDate;
			}
			if (!isSameYear) {
				return moment(date).format('DD MMM') + ', ' + moment(date).format('YYYY') + ' ' + baseDate;
			}

			return moment(date).format('DD MMM') + ' ' + baseDate;
		};
	});
