'use strict';

angular
	.module('bringgApp')
	.controller(
		'RescheduleTaskController',
		function RescheduleTaskController(
			$scope,
			$uibModalInstance,
			tasks,
			Tasks,
			WEB_API_URL,
			toastr,
			TimezoneService
		) {
			$scope.tasks = tasks;
			$scope.deliveryWindow = {};
			$scope.dateRangePickerOptions = {
				timePicker: true,
				linkedCalendars: false,
				opens: 'left',
				timePickerIncrement: 5,
				timePicker12Hour: TimezoneService.is12HourFormat()
			};

			$scope.reschedule = function () {
				if (!_.isEmpty($scope.tasks)) {
					$scope.currentlySaving = true;
					var noEarlierThan = $scope.deliveryWindow.startDate
						? TimezoneService.applyTimezonByTask(
								$scope.tasks[0],
								$scope.deliveryWindow.startDate
						  ).toString()
						: null;
					var noLaterThan = $scope.deliveryWindow.endDate
						? TimezoneService.applyTimezonByTask($scope.tasks[0], $scope.deliveryWindow.endDate).toString()
						: null;
					var taskIds = _.map($scope.tasks, 'id');
					Tasks.rescheduleVirtualTasks(taskIds, noLaterThan, noEarlierThan).then(function (taskIds) {
						$scope.close();

						if (taskIds) {
							toastr.success(
								'Delivery window was successfully updated for tasks : ' + taskIds.join(', ')
							);
						} else {
							toastr.error('Failed to update the delivery window');
						}
					});
				}
			};

			$scope.close = function () {
				$scope.currentlySaving = false;
				$uibModalInstance.dismiss(undefined);
			};
		}
	);
