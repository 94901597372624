'use strict';

angular
	.module('bringgApp')
	.service(
		'OptimizationTasksValidationService',
		function (
			WayPointsService,
			Locations,
			OPTIMIZATION_TYPES,
			optimizationTypesService,
			Teams,
			MerchantConfigurations
		) {
			let _this = this;
			_this.validate = validate;

			const _errorTypes = {
				noTeamWasFound: 'NO_TEAM_WAS_FOUND',
				invalidAddress: 'INVALID_ADDRESS',
				noDropOffWayPoint: 'NO_DROP_OFF_WAY_POINT',
				tasksFromMultipleTeams: 'TASKS_FROM_MULTIPLE_TEAMS',
				differentOptimizationTypes: 'DIFFERENT_OPTIMIZATION_TYPES',
				differentOptimizationTypesTeams: 'DIFFERENT_OPTIMIZATION_TYPES_TEAMS_LIST',
				noWayPointAddress: 'NO_WAY_POINT_ADDRESS',
				tooFarFromTeam: 'TOO_FAR_FROM_TEAM_LOCATION'
			};

			function validate(tasks) {
				return getInvalidTeams(tasks) || getInvalidTasks(tasks);
			}

			function getInvalidTeams(tasks) {
				const optimizationTypesByTeam = tasks.reduce((typesByTeam, task) => {
					typesByTeam[task.team_id] = optimizationTypesService.getConfiguredOptimizationType(task.team_id);
					return typesByTeam;
				}, {});

				const uniqTypes = new Set(Object.values(optimizationTypesByTeam));

				if (uniqTypes.size > 1) {
					const validTeams = Object.keys(optimizationTypesByTeam)
						.filter(teamId => optimizationTypesByTeam[teamId] === OPTIMIZATION_TYPES.COMBINED)
						.map(teamId => Teams.teamsByIdMap[teamId].name);

					return {
						errors: {
							[_errorTypes.differentOptimizationTypes]: new Set(),

							[_errorTypes.differentOptimizationTypesTeams]: new Set(validTeams)
						},
						type: 'team',
						allowedToProceed: false
					};
				}
			}

			function addTasksByError(tasksByError, errorType, task) {
				if (tasksByError[errorType]) {
					tasksByError[errorType].add(task.id);
				} else {
					tasksByError[errorType] = new Set([task.id]);
				}
				task.linked_task_id && tasksByError[errorType].add(task.linked_task_id);
			}

			function getInvalidTasks(tasks) {
				const optimizationType =
					optimizationTypesService.getConfiguredOptimizationType(tasks[0].team_id) ||
					OPTIMIZATION_TYPES.ANONYMOUS;

				const tasksByError = {};

				if (optimizationType === OPTIMIZATION_TYPES.COMBINED) {
					tasks.forEach(task => {
						if (!task.team_id) {
							addTasksByError(tasksByError, _errorTypes.noTeamWasFound, task);
						}
						if (!Locations.validateLatLng(task.lat, task.lng)) {
							addTasksByError(tasksByError, _errorTypes.invalidAddress, task);
						}
						if (!WayPointsService.getDropOffWayPoint(task)) {
							addTasksByError(tasksByError, _errorTypes.noDropOffWayPoint, task);
						}
						if (!Locations.validateLatLngOnWayPoint(task)) {
							addTasksByError(tasksByError, _errorTypes.noWayPointAddress, task);
						}
						var maxDistanceFromCenterInMeters =
							MerchantConfigurations.max_distance_for_optimization_in_meters;
						const team = Teams.teamsByIdMap[task.team_ids[0]];
						if (team && Locations.validateToFarTasks(task, team, maxDistanceFromCenterInMeters)) {
							addTasksByError(tasksByError, _errorTypes.tooFarFromTeam, task);
						}
					});
				}

				return {
					errors: tasksByError,
					type: 'tasks',
					allowedToProceed: true
				};
			}
		}
	);
