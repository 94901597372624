'use strict';

angular
	.module('bringgApp.directives')
	.directive(
		'customizeListColumns',
		function ($log, SlickGridConfigService, CrossApplicationService, CROSS_APP_ACTIONS, PresetViewsService) {
			return {
				templateUrl: 'scripts/directives/customize_list_columns/customize_list_columns.html',
				scope: {
					onSave: '&',
					onCancel: '&',
					targetGridId: '@',
					configName: '@'
				},
				link: function (scope, element, attrs) {
					/**
					 * sortable options
					 */
					scope.sortableOptions = {
						handle: '> .drag-handler'
					};

					/**
					 * cancel button
					 */
					scope.cancel = scope.onCancel;

					/**
					 * apply button
					 */
					scope.save = function () {
						if (!scope.configName) {
							$log.error('[customizeListColumns.save] task fields has an empty config name, aborting');
							return;
						}

						SlickGridConfigService.tableColumnsUpdated(
							scope.configName,
							scope.list,
							scope.targetGridId,
							scope.onSave
						);
					};

					/**
					 * set defaults values
					 */
					scope.defaults = function () {
						if (!scope.configName) {
							$log.error(
								'[customizeListColumns.defaults] task fields has an empty config name, aborting'
							);
							return;
						}

						if (PresetViewsService.enabledForConfigKey(scope.configName)) {
							// TODO: when preset is enabled currently columns default to default as initial state of columns of preset(before any changes)
							// investigate which is the proper way the `default` button should work with preset
							scope.list = PresetViewsService.resetPresetViewColumns(scope.configName);
						} else {
							SlickGridConfigService.getInitialColumnsConfig(scope.configName).then(function (columns) {
								scope.list = columns || GridDefaultColumnsService.getDefaultsColumns(configName);
							});
						}
					};

					//lets order them the same array that task fields has to keep user order in eye
					if (!scope.configName) {
						$log.error('[customizeListColumns.init] task fields has an empty config name, aborting');
					} else {
						if (PresetViewsService.enabledForConfigKey(scope.configName)) {
							// since preset are already has position of column we can easily sort only by position
							scope.list = SlickGridConfigService.getColumnsFromMerchantConfigurations(
								scope.configName
							).sort((a, b) => a.position - b.position);
						} else {
							// support of old merchant config
							scope.list = _.sortBy(
								SlickGridConfigService.getColumnsFromMerchantConfigurations(scope.configName),
								sortColumns
							);
						}
					}

					function sortColumns(column) {
						var index = _.findIndex(SlickGridConfigService.getGridColumns(scope.configName), {
							id: column.id
						});

						// sort by the list saved in the MerchantConfigurations else by the column Id
						return index > -1 ? index : column.id;
					}

					CrossApplicationService.on(
						// the component should closed after presets event since event will init UI changes
						CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED,
						scope.cancel
					);

					scope.$on('$destroy', function () {
						CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, scope.cancel);
					});
				}
			};
		}
	);
