'use strict';

angular.module('bringgApp').service('TimezoneService', function (localStorageService, MerchantConfigurations, Teams) {
	var TimezoneService = {},
		TimezoneTypes = {
			TASK: 'task',
			TEAM_ID: 'team_id'
		};

	TimezoneService.getDateByTask = function (task, date) {
		return TimezoneService._convertDateToTimezone(task, TimezoneTypes.TASK, date);
	};

	TimezoneService.getDateByTeam = function (teamId, date) {
		return TimezoneService._convertDateToTimezone(teamId, TimezoneTypes.TEAM_ID, date);
	};

	TimezoneService.getTaskTimezone = function (task, options) {
		return TimezoneService._getTimezone(task, TimezoneTypes.TASK, options);
	};

	TimezoneService.getTeamTimezone = function (teamId, options) {
		return TimezoneService._getTimezone(teamId, TimezoneTypes.TEAM_ID, options);
	};

	TimezoneService._getTimezone = function (item, type, options) {
		if (!TimezoneService.shouldShowInTeamTimezone()) {
			return undefined;
		}

		options = options || {};

		_.defaults(options, {
			formatTimezone: false
		});

		var timeZone;

		if (type === TimezoneTypes.TASK) {
			timeZone =
				item.team_ids && Teams.teamsByIdMap[item.team_ids[0]]
					? Teams.teamsByIdMap[item.team_ids[0]].time_zone
					: undefined;
		}
		if (type === TimezoneTypes.TEAM_ID) {
			timeZone = Teams.teamsByIdMap[item] ? Teams.teamsByIdMap[item].time_zone : undefined;
		}

		if (options.formatTimezone) {
			timeZone = timeZone ? ' (' + timeZone + ')' : undefined;
		}

		return timeZone;
	};

	TimezoneService._convertDateToTimezone = function (item, type, date) {
		if (!date) {
			return undefined;
		}

		var teamTimeZone;

		if (type === TimezoneTypes.TASK) {
			teamTimeZone = TimezoneService.getTaskTimezone(item);
		}
		if (type === TimezoneTypes.TEAM_ID) {
			teamTimeZone = TimezoneService.getTeamTimezone(item);
		}

		if (!TimezoneService.shouldShowInTeamTimezone() || !teamTimeZone) {
			return moment(date);
		}

		return moment(date).tz(teamTimeZone);
	};

	TimezoneService.shouldShowInTeamTimezone = function () {
		return MerchantConfigurations.localize_task_timezones_by_teams;
	};

	/**
	 * This functions lets apply timezone to certain date without changing the date
	 * @param task
	 * @param date
	 * @returns {date}
	 */
	TimezoneService.applyTimezonByTask = function (task, date) {
		return TimezoneService.applyTimezone(task, TimezoneTypes.TASK, date);
	};

	/**
	 * This functions lets apply timezone to certain date without changing the date
	 * @param team
	 * @param date
	 * @returns {date}
	 */
	TimezoneService.applyTimezonByTeam = function (team, date) {
		return TimezoneService.applyTimezone(team, TimezoneTypes.TEAM_ID, date);
	};

	/**
	 * This functions lets apply timezone to certain date without changing the date
	 * @param item
	 * @param type
	 * @param date
	 * @returns {date}
	 */
	TimezoneService.applyTimezone = function (item, type, date) {
		if (!TimezoneService.shouldShowInTeamTimezone()) {
			return moment(date);
		}

		var timeZone = moment.tz.guess();

		if (type === TimezoneTypes.TASK) {
			timeZone = TimezoneService.getTaskTimezone(item) || moment.tz.guess();
		}

		if (type === TimezoneTypes.TEAM_ID) {
			timeZone = TimezoneService.getTeamTimezone(item) || moment.tz.guess();
		}

		return moment(date).tz(timeZone, true);
	};

	/**
	 * This functions return which time format we currently use according to moment locale
	 * @param task
	 * @param date
	 * @returns {date}
	 */
	TimezoneService.is12HourFormat = function () {
		var formattedDate = moment().format('LT');

		return formattedDate.indexOf('AM') > -1 || formattedDate.indexOf('PM') > -1;
	};

	return TimezoneService;
});
