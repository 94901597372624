'use strict';

angular.module('bringgApp.directives').directive('alerts', function () {
	return {
		restrict: 'E',
		replace: true,
		template: '<div id="exceptions-portal" class="react-component exceptions" params="orderDetailsParams"></div>',
		controller: 'TaskController'
	};
});
