'use strict';

angular
	.module('bringgApp')
	.constant('SINGLE_REASON', 1)
	.controller('TaskReassignReasonsController', function (
		$scope,
		$uibModalInstance,
		ReasonToReassignTasksService,
		REASSIGN_REASON_TYPES,
		reassignReasonTypes,
		SINGLE_REASON
	) {
		$scope.reasonToReassignTypes = reassignReasonTypes;
		$scope.submit = function () {
			$uibModalInstance.close({
				id: $scope.reasonToReassignTask.id,
				text: $scope.reasonToReassignTaskText
			});
		};

		$scope.dismiss = function () {
			$uibModalInstance.dismiss(false);
		};

		$scope.reasonToReassignDescription = function () {
			if ($scope.reasonToReassignTypes.length !== SINGLE_REASON) {
				return 'TASK_REASSIGN_REASONS.REASSIGN_DESCRIPTION_MULTIPLE';
			}

			var reassignReasonType = $scope.reasonToReassignTypes[0];

			switch (reassignReasonType) {
				case REASSIGN_REASON_TYPES.ACTIVE_TASK:
					return 'TASK_REASSIGN_REASONS.ACTIVE_TASK';
				case REASSIGN_REASON_TYPES.MANUAL_REASSIGNMENT:
					return 'TASK_REASSIGN_REASONS.REASSIGN_DESCRIPTION_INACTIVE_TASK';
				case REASSIGN_REASON_TYPES.MANUAL_ASSIGNMENT:
					return 'TASK_REASSIGN_REASONS.REASSIGN_DESCRIPTION_FIRST_ASSIGNMENT';
				default:
					return 'TASK_REASSIGN_REASONS.REASSIGN_DESCRIPTION_MULTIPLE';
			}
		};

		$scope.init = function () {
			$scope.reasonToReassignTaskText = '';
			ReasonToReassignTasksService.getAll().then(function (reasons) {
				$scope.reasonToReassignTasks = _.chain(reasons)
					.filter(function (reason) {
						return (
							_.includes(reason.types, REASSIGN_REASON_TYPES.ALL) ||
							_.some(_.intersection($scope.reasonToReassignTypes, reason.types))
						);
					})
					.map(function (reason) {
						if (reason.is_default) {
							reason = _.clone(reason);
							reason.reason = 'TASK_REASSIGN_REASONS.' + reason.reason;
						}

						return reason;
					})
					.value();
				$scope.reasonToReassignTask = null;
			});
		};

		$scope.init();
	});
