'use strict';

angular.module('bringgApp.directives').directive('skillsAutocomplete', function (SkillsService) {
	return {
		templateUrl: 'scripts/directives/skills/skills-autocomplete.html',
		scope: {
			skills: '='
		},
		controller: function ($scope, PRIVILEGES_TYPES, Authentication) {
			$scope.canCreateSkills = Authentication.userHasAccess(PRIVILEGES_TYPES.CREATE_DRIVER_SKILLS);
		},
		link: function ($scope) {
			$scope.uiSkills = [];
			$scope.merchantSkills = [];

			//This function is needed in order for tagging and autocomplete to work together
			//Also, we cannot return the name as simple string because ui-select expects to receive object and append to it 'isSet' property to show that it is a new tag
			$scope.transformSingleSkillToUiSelect = function (skillName) {
				return { name: skillName };
			};

			$scope.transformSkillsToUiSelect = function (skills) {
				return _.map(skills, $scope.transformSingleSkillToUiSelect);
			};

			$scope.onSkillSelect = function (newSkill) {
				$scope.skills.push(newSkill.name);
			};

			$scope.shouldShowSkill = function (skill) {
				return !_.includes($scope.skills, skill.name);
			};

			$scope.onSkillRemove = function (removedSkill) {
				var skillToRemoveIndex = $scope.skills.indexOf(removedSkill.name);

				$scope.skills.splice(skillToRemoveIndex, 1);
			};

			$scope.initSkills = function () {
				SkillsService.getAllSkills().then(function (skills) {
					$scope.merchantSkills = skills;
					$scope.uiSkills = _.sortBy($scope.transformSkillsToUiSelect($scope.skills), 'name');
				});
			};

			$scope.initSkills();
		}
	};
});
