'use strict';

var TOP_MARGIN_FROM_ELEMENT = 10;
var DEBOUNCE_WAIT_IN_MIILIS = 300;
var TOOLTIP_ID_SUFFIX = '_tooltip';

angular
	.module('bringgApp.directives')
	.service('CustomerTooltipService', function (
		$compile,
		$rootScope,
		BringgSDK,
		Customers,
		CustomerAvailabilityHoursService
	) {
		var CustomerTooltipService = {};
		var scope = $rootScope.$new();

		function createTooltip(elementId) {
			var element = angular.element('#' + elementId)[0];

			var tooltip = document.createElement('customer-tooltip');
			tooltip.id = element.id + TOOLTIP_ID_SUFFIX;

			var elementLocation = element.getBoundingClientRect();
			var bottom = elementLocation.y - TOP_MARGIN_FROM_ELEMENT;
			var left = elementLocation.x + elementLocation.width / 2;

			tooltip.style.bottom = 'calc(100vh - ' + bottom + 'px)';
			tooltip.style.left = left + 'px';
			tooltip.style.transform = 'translateX(-50%)';
			tooltip.style.visibility = 'hidden';

			var compiledTooltip = $compile(tooltip)(scope)[0];

			angular.element(document.body).append(compiledTooltip);
		}

		function getCustomerAvailabilityHours(taskId, customer, elementId) {
			CustomerAvailabilityHoursService.getTaskCustomerAvailabilityHours({
				task_id: taskId,
				customer_id: customer.id
			}).then(function (availabilityHours) {
				scope.customer = {
					name: customer.name,
					phone: customer.phone,
					email: customer.email,
					externalId: customer.external_id,
					availabilityHours: availabilityHours
				};

				var tooltip = document.getElementById(elementId + TOOLTIP_ID_SUFFIX);

				if (tooltip) {
					tooltip.style.visibility = 'visible';
				}
			});
		}

		var debounceGetAvailabilityHours = _.debounce(getCustomerAvailabilityHours, DEBOUNCE_WAIT_IN_MIILIS);

		CustomerTooltipService.open = function (elementId, taskId, customerId) {
			const tooltipsArray = document.querySelectorAll('.customer-tooltip');
			if (tooltipsArray.length > 0) {
				CustomerTooltipService.removeAll();
			}
			createTooltip(elementId);

			Customers.get(customerId).then(function (customer) {
				debounceGetAvailabilityHours(taskId, customer, elementId);
			});
		};

		CustomerTooltipService.removeAll = function () {
			const tooltipsArray = document.querySelectorAll('.customer-tooltip');

			angular.forEach(tooltipsArray, function (tooltip) {
				tooltip.remove();
				scope.customer = null;
			});
		};

		return CustomerTooltipService;
	});
