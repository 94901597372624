'use strict';

angular.module('bringgApp').service('TaskInventoriesService', function () {
	var TaskInventoriesService = {};

	TaskInventoriesService.isParentTaskInventory = function (taskInventory) {
		return (
			_.isNull(taskInventory.parent_task_inventory_id) || _.isUndefined(taskInventory.parent_task_inventory_id)
		);
	};

	/**
	 * get task inventories
	 * @param task
	 * @param wayPoint
	 * @returns {*}
	 */
	TaskInventoriesService.getInventories = function (task, wayPoint) {
		if (!task.task_inventories) {
			return [];
		}

		var cloned_inventories = angular.copy(task.task_inventories);
		var taskInventories = _.filter(cloned_inventories, function (taskInventory) {
			if (wayPoint) {
				return (
					taskInventory.way_point_id === wayPoint.id &&
					TaskInventoriesService.isParentTaskInventory(taskInventory)
				);
			}
			return taskInventory.way_point_id === null && TaskInventoriesService.isParentTaskInventory(taskInventory);
		});

		var subTaskInventories = _.filter(cloned_inventories, function (taskInventory) {
			if (wayPoint) {
				return (
					taskInventory.way_point_id === wayPoint.id &&
					!TaskInventoriesService.isParentTaskInventory(taskInventory)
				);
			}
			return taskInventory.way_point_id === null && !TaskInventoriesService.isParentTaskInventory(taskInventory);
		});

		_.each(subTaskInventories, function (subTaskInventory) {
			var found = _.find(_.union(taskInventories, subTaskInventories), {
				id: subTaskInventory.parent_task_inventory_id
			});

			if (!found) {
				return;
			}

			found.inventories = found.inventories || [];

			if (found.inventories.some(inventory => inventory.id === subTaskInventory.id)) {
				return;
			}

			found.inventories.push(subTaskInventory);
		});

		return taskInventories;
	};

	return TaskInventoriesService;
});
