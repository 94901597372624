'use strict';

angular.module('bringgApp').factory('TaskInventoryService', function ($rootScope, BringgSDK) {
	function taskInventory(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.taskInventory[method].apply(bringg.taskInventory, args);
			});
		};
	}

	var TaskInventoryService = {};

	TaskInventoryService.massQuantityUpdate = taskInventory('massQuantityUpdate');

	return TaskInventoryService;
});
