'use strict';

angular
	.module('bringgApp')
	.controller('DriverPinGalleryController', function ($scope, $uibModalInstance, currentSelection) {
		$scope.data = { currentSelection: currentSelection };

		$scope.items = [
			{ src: '/images/driver_pin/pin1.png' },
			{ src: '/images/driver_pin/pin2.png' },
			{ src: '/images/driver_pin/pin3.png' },
			{ src: '/images/driver_pin/pin4.png' },
			{ src: '/images/pin_delivery.png' }
		];

		$scope.handleDoubleClick = function (item) {
			$uibModalInstance.close(item.src);
		};

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};

		$scope.handleSaveClick = function () {
			$uibModalInstance.close($scope.data.currentSelection);
		};
	});
