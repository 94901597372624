/*global moment:false */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('distanceByMerchant', function (MerchantConfigurations) {
		return function (distanceInKm) {
			if (_.isNil(distanceInKm)) {
				return null;
			}

			var unit = MerchantConfigurations.country_code === 'us' ? 0.621371 : 1;
			return distanceInKm * unit;
		};
	})
	.filter('distanceByMerchantWithUnit', function (MerchantConfigurations, $filter) {
		return function (distanceInMeters, precision) {
			if (_.isNil(distanceInMeters)) {
				return null;
			}

			precision = precision || 1;

			var distanceInKm = distanceInMeters / 1000;
			var distanceInUnit = $filter('distanceByMerchant')(distanceInKm);

			var distanceFixed = parseFloat(distanceInUnit.toFixed(precision));

			var unit = MerchantConfigurations.country_code === 'us' ? 'Miles' : 'km';
			return distanceFixed + ' ' + unit;
		};
	});
