'use strict';

angular
	.module('bringgApp')
	.controller('KdsController', function (
		$scope,
		$log,
		KdsService,
		PREPARATION_STATUS,
		DispatcherAcknowledgeTaskStatusService
	) {
		$scope.PREPARATION_STATUS = PREPARATION_STATUS;
		$scope.tasksByStatus = {};
		$scope.taskInUpdate = {};

		$scope.promoteStatusAndRemoveFromDispatcher = function (task, nextStatus) {
			DispatcherAcknowledgeTaskStatusService.removeTask(task);
			$scope.promoteStatus(task, nextStatus);
		};

		$scope.promoteStatus = function (task, nextStatus) {
			if ($scope.taskInUpdate[task.id]) {
				$log.info('KdsController, promoteStatus pressed while still updating task', task, nextStatus);
				return;
			}
			$scope.taskInUpdate[task.id] = true;
			$log.info('KdsController, promoteStatus', task, nextStatus);
			KdsService.updatePreparationStatus(task, nextStatus)
				.then(function () {
					$log.info('KdsController, promoteStatus was successful', task, nextStatus);
					delete $scope.taskInUpdate[task.id];
				})
				.catch(function (error) {
					$log.error('KdsController, promoteStatus was unsuccessful', error);
					delete $scope.taskInUpdate[task.id];
				});
		};

		$scope.updateStateFromKdsService = function () {
			$log.info('KdsController, setStateFromKds invoked');
			$scope.tasksByStatus = KdsService._byStatus;
		};

		$scope.init = function () {
			$log.info('KdsController, init called');

			$scope.$on('kds list update', function () {
				$scope.updateStateFromKdsService();
			});

			$log.info('KdsController, invoking KdsService.init');
			KdsService.init();
		};

		$scope.$on('$viewContentLoaded', function () {
			$log.info('KdsController, view content loaded invoking $scope.init');
			$scope.init();
		});
	});
