'use strict';

angular
	.module('bringgApp')
	.controller('BecomeDeveloperController', function ($scope, $uibModalInstance, BecomeDeveloperService) {
		$scope.becomeDeveloper = {};
		$scope.sending = false;
		$scope.devEnv = 1;

		$scope.handleSubmitClick = function () {
			$scope.sending = true;
			BecomeDeveloperService.submit({
				team_size: $scope.teamSize,
				company_type: $scope.companyType,
				dev_env: $scope.devEnv,
				text: $scope.text,
				technical_contact_email: $scope.techContactEmail,
				technical_contact_name: $scope.techContactName,
				administrative_name: $scope.adminContactName,
				administrative_contact_email: $scope.adminContactEmail
			}).then(
				function (result) {
					$uibModalInstance.close();
				},
				function (reason) {
					$scope.sending = false;
				}
			);
		};

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};
	});
