'use strict';

angular.module('bringgApp').factory('ServiceAreasService', function ($rootScope, BringgSDK, $q) {
	let _serviceAreasSdk;

	function serviceAreasService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.v2.serviceAreas[method].apply(bringg.v2.serviceAreas, args);
			});
		}.bind(this);
	}

	BringgSDK.getInstancePromise().then(function (bringg) {
		_serviceAreasSdk = bringg.v2.serviceAreas;
	});

	var ServiceAreas = {};

	ServiceAreas.getByIds = function (ids) {
		return _serviceAreasSdk.getByIds(ids);
	};

	ServiceAreas.loadAll = serviceAreasService('loadAll');

	ServiceAreas.loadAllByTeams = serviceAreasService('loadAllByTeams');

	ServiceAreas.getServiceAreaById = function (teamsIds) {
		return ServiceAreas.loadAllByTeams({ teams_ids: teamsIds }).then(function (results) {
			const serviceAreasById = {};

			results.forEach(function (result) {
				if (!_.isEmpty(result.service_areas)) {
					result.service_areas.forEach(function (serviceArea) {
						serviceAreasById[serviceArea.id] = serviceArea;
					});
				}
			});

			return serviceAreasById;
		});
	};

	ServiceAreas.getServiceAreaSuffix = function (serviceAreasById, areaId) {
		if (!areaId) {
			return '';
		}

		var serviceArea = serviceAreasById && serviceAreasById[areaId];

		if (!serviceArea || !serviceArea.name) {
			return '';
		}

		return '-' + serviceArea.name;
	};

	return ServiceAreas;
});
