'use strict';

angular.module('bringgApp').service('GroupedTasksService', function () {
	const _this = this;
	_this.isFollower = isFollower;

	function isFollower(task) {
		return !_.isNull(task.group_leader_id) && !_.isUndefined(task.group_leader_id);
	}
});
