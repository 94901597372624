'use strict';

angular.module('bringgApp.directives').directive('timeToBase', function () {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			selectedTeam: '=?',
			merchantConfiguration: '=?',
			selectedTeamsIds: '=?'
		},
		templateUrl: 'scripts/features/time_to_base/time-to-base.html',
		controller: 'TimeToBaseController'
	};
});
