'use strict';

angular
	.module('bringgApp')
	.controller(
		'affirmationAlertsController',
		function ($scope, $sce, $timeout, affirmationAlertsService, Authentication, SocketPubSub, $rootScope) {
			function createRealtimeMessage(reconnectAttemptCount) {
				var text = 'Realtime is disconnected for too long, please consider refreshing the screen';
				if (reconnectAttemptCount && reconnectAttemptCount > 0) {
					text += ' (attempt ' + reconnectAttemptCount + ')';
				}
				text += ' <a href="#" onClick="window.location.reload()" class="alert-link">Refresh</a>';
				return text;
			}

			//==============================================
			// real time warning
			//==============================================
			$scope._handleRealtimeWarning = function (nothing, warningData) {
				var existingAlert = _.find(affirmationAlertsService.alerts, function (affirmationAlert) {
					return affirmationAlert.id === 'realtime warning';
				});

				if (_.isUndefined(existingAlert)) {
					affirmationAlertsService.addAlert(
						'realtime warning',
						'alert-' + warningData.level,
						createRealtimeMessage(warningData.reconnectAttemptCount)
					);
				} else {
					updateAlert(
						'realtime warning',
						'alert-' + warningData.level,
						createRealtimeMessage(warningData.reconnectAttemptCount)
					);
				}
				if (!$scope.$$phase) {
					$scope.$apply();
				}
			};
			$scope.$on('realtime warning', $scope._handleRealtimeWarning);

			//==============================================
			// remove x
			//==============================================
			$scope._handleRemoveX = function (event, eventData) {
				affirmationAlertsService.removeAlert(eventData.alertStringId);
				if (!$scope.$$phase) {
					$scope.$digest();
				}
			};
			$scope.$on('remove x', $scope._handleRemoveX);
			//==============================================
			// loading tasks
			//==============================================
			$scope._handleLoadingTasks = function (nothing, warningData) {
				var existingAlert = _.find(affirmationAlertsService.alerts, function (affirmationAlert) {
					return affirmationAlert.id === 'loading tasks';
				});

				if (_.isUndefined(existingAlert)) {
					affirmationAlertsService.addAlert('loading tasks', 'alert-info', 'Loading initial data (orders)');
				}
			};
			$scope.$on('loading tasks', $scope._handleLoadingTasks);
			//==============================================
			// loading closed tasks
			//==============================================
			$scope._handleLoadingClosedTasks = function (event, loadingData) {
				var existingAlert = _.find(affirmationAlertsService.alerts, function (affirmationAlert) {
					return affirmationAlert.id === event.name;
				});

				if (_.isUndefined(existingAlert)) {
					affirmationAlertsService.addAlert(
						event.name,
						'alert-info',
						'Loading page ' + loadingData.page + ' out of ' + loadingData.pageCount
					);
				} else {
					updateAlert(
						event.name,
						'alert-info',
						'Loading page ' + loadingData.page + ' out of ' + loadingData.pageCount
					);
				}
			};
			$scope.$on('loading closed tasks', $scope._handleLoadingClosedTasks);
			//==============================================
			// remove loading closed tasks
			//==============================================
			$scope._handleRemoveLoadingCloedTasks = function () {
				affirmationAlertsService.removeAlert('loading closed tasks');
			};
			$scope.$on('remove loading closed tasks', $scope._handleRemoveLoadingCloedTasks);
			//==============================================
			// removed realtime warning
			//==============================================
			$scope._handleRemoveRealtimeWarning = function () {
				affirmationAlertsService.removeAlert('realtime warning');
			};
			$scope.$on('remove realtime warning', $scope._handleRemoveRealtimeWarning);
			//==============================================
			// remove loading tasks
			//==============================================
			$scope._handleRemoveLoadingTasks = function () {
				affirmationAlertsService.removeAlert('loading tasks');
			};
			$scope.$on('remove loading tasks', $scope._handleRemoveLoadingTasks);

			function updateAlert(alertId, alertLevel, alertText) {
				var alertMarkup = affirmationAlertsService._createAlertMarkup(alertLevel, alertText);
				var existingAlert = _.find(affirmationAlertsService.alerts, function (affirmationAlert) {
					return affirmationAlert.id === alertId;
				});

				if (existingAlert) {
					existingAlert.text = $sce.trustAsHtml(alertMarkup);
					existingAlert.level = alertLevel;
					if (!$scope.$$phase) {
						$scope.$apply();
					}
				}
			}

			$scope.alerts = affirmationAlertsService.alerts;
		}
	);
