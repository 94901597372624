'use strict';

angular
	.module('bringgApp')
	.controller('DeveloperConfigurationController', function (
		$scope,
		toastr,
		Token,
		Authentication,
		RECAPTCHA_SITE_KEY,
		vcRecaptchaService,
		MerchantService,
		$window
	) {
		$scope.auth = {
			authorized: false,
			adminPassword: null,

			recaptcha_required: false,
			recaptcha_site_key: RECAPTCHA_SITE_KEY,
			recaptcha_token: null,
			recaptcha_widget_id: null
		};

		$scope.setWidgetId = function (widgetId) {
			$scope.auth.recaptcha_widget_id = widgetId;
			vcRecaptchaService.reload(widgetId);
		};

		$scope.onRecaptchaExpired = function () {
			$scope.auth.recaptcha_token = null;
		};
		$scope.onRecaptchaToken = function (token) {
			$scope.auth.recaptcha_token = token;
		};

		$scope.authorize = function () {
			var request = { password: $scope.auth.adminPassword };
			if ($scope.auth.recaptcha_token) {
				request.recaptcha = $scope.auth.recaptcha_token;
			}

			return Token.getDeveloperKeys(request)
				.$promise.then(function (response) {
					if (response.success) {
						$scope.auth.authorized = true;
						$scope.partner = response.partner;
					} else {
						toastr.error(response.message);

						if (response.recaptcha_stoken) {
							$scope.auth.recaptcha_required = true;
						}
					}
				})
				.catch(function (error) {
					toastr.error(error.message);
				});
		};

		$scope.handleDocumentationClicked = function () {
			$window.open('http://developers.bringg.com/v1.0/docs');
		};
	});
