'use strict';
/*global Slick:false */

angular.module('bringgApp').directive('selectRoles', function (bringg_utils) {
	return {
		restrict: 'E',
		scope: {
			userTypesByTeam: '='
		},
		templateUrl: 'scripts/features/optimize/steps/select-roles.html',
		link: function (scope) {
			scope.isTeamSelected = function (teamIndex) {
				return scope.activeTeamIndex === teamIndex;
			};

			scope.onTeamSelect = function (teamIndex) {
				scope.activeTeamIndex = teamIndex;
				scope.currentRoles = scope.userTypesByTeam[teamIndex].userTypes;
			};

			scope.hasAnySelection = function (team) {
				return _.some(team.userTypes, 'quantity');
			};

			scope.toggleAllRolesSelect = function () {
				_.each(scope.currentRoles, function (role) {
					role.quantity = scope.allSelected[scope.activeTeamIndex];
				});
			};

			scope.init = function () {
				scope.allSelected = {};
				scope.activeTeamIndex = 0;

				var sortedUserTypes =
					_.isArray(scope.userTypesByTeam[scope.activeTeamIndex].userTypes) &&
					bringg_utils.naturalSortByKey(scope.userTypesByTeam[scope.activeTeamIndex].userTypes, 'title');
				var currentRoles = sortedUserTypes.length
					? sortedUserTypes
					: scope.userTypesByTeam[scope.activeTeamIndex].userTypes;
				scope.currentRoles = scope.userTypesByTeam && currentRoles;
			};

			scope.init();
		}
	};
});
