'use strict';

angular
	.module('bringgApp.directives')
	.directive('superUser', function (Authentication, localStorageService, $window, $location) {
		return {
			templateUrl: 'scripts/directives/super_user/super-user.html',
			restrict: 'E',
			scope: {
				isImpersonateVisible: '&'
			},
			link: function (scope) {
				function reload(isLoggedIn) {
					const currentUser = Authentication.currentUser();

					scope.showBack =
						Authentication.currentUser() && localStorageService.get('impersonate_original_user') !== null;
					scope.show = !scope.showBack && isLoggedIn && Boolean(Authentication.currentUser().can_impersonate);

					scope.isImpersonateVisible({ visible: scope.show || scope.showBack });
					scope.isOriginalUserBringgEmployee = currentUser.original_merchant_id === 1;
				}

				scope.data = { userId: null };

				scope.$on('config updated', reload);

				scope.$watch(Authentication.isLoggedIn, reload);

				scope.impersonate = function () {
					if (scope.data.userId !== null && scope.show) {
						scope.loading = true;
						Authentication.impersonate(scope.data.userId)
							.then(function (user) {
								alert('User ' + user.name + ' - ' + scope.data.userId + ' identitiy taken.');

								$window.location.replace('/#' + $location.path());
								$window.location.reload();
							})
							.catch(e => {
								alert(`Failed to impersonate.\n${e}`);
							})
							.finally(() => {
								scope.loading = false;
								try {
									scope.$apply();
								} catch (e) {}
							});
					} else if (localStorageService.get('impersonate_original_user')) {
						scope.loading = true;

						try {
							const user = Authentication.returnToOriginalUser();

							alert('User ' + user.name + ' is back.');
							localStorageService.remove('newNavigation');

							if (localStorageService.get('impersonated_by_task')) {
								localStorageService.remove('impersonated_by_task');
								localStorageService.set('init_route', '/support/');
							}

							$window.location.replace('/#' + $location.path());
							$window.location.reload();
						} catch (e) {
							alert(`Failed to exit impersonate.\n${e}`);
						} finally {
							scope.loading = false;
							try {
								scope.$apply();
							} catch (e) {}
						}
					} else {
						alert('Invalid original user found, refreshing the page..');

						$window.location.replace('/#' + $location.path());
						$window.location.reload();
					}
				};
			}
		};
	});
