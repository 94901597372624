/* eslint-disable angular/di-unused */
/*global Slick:false */
'use strict';

angular
	.module('bringgApp')
	.service(
		'DispatchListService',
		function (
			$translate,
			MerchantConfigurations,
			Authentication,
			$filter,
			$log,
			TimeManagerService,
			$q,
			Task,
			Tasks,
			Employees,
			LockedTaskService
		) {
			var DispatchListService = {};

			DispatchListService.getOnShiftDriversByTeam = function (teamId) {
				return Employees.onShiftDriversByTeam.get(teamId) || new Set();
			};

			DispatchListService.getAtBaseDriversByTeam = function (teamId) {
				return Employees.atBaseDriversByTeam.get(teamId) || new Set();
			};

			DispatchListService.getReturnToBaseDriversByTeam = function (teamId) {
				return Employees.returnToBaseDriversByTeam.get(teamId) || new Set();
			};

			DispatchListService.getOnlineDriversByTeam = function (teamId) {
				return Employees.onlineDriversByTeam.get(teamId) || new Set();
			};

			DispatchListService.groupByOptions = [
				{ label: 'DISPATCH_LIST.NO_GROUPING', value: 'None' },
				{ label: 'DISPATCH_LIST.DRIVER', value: 'Driver' }
			];

			if (MerchantConfigurations.enable_teams) {
				DispatchListService.groupByOptions.push({ label: 'DISPATCH_LIST.TEAM', value: 'Team' });
				DispatchListService.groupByOptions.push({
					label: 'DISPATCH_LIST.TEAM_AND_DRIVER',
					value: 'TeamDriver'
				});
			}

			DispatchListService.groupByOptions.push({ label: 'DISPATCH_LIST.ROUTE', value: 'Route' });
			DispatchListService.groupByOptions.push({ label: 'DISPATCH_LIST.TEAM_AND_ROUTE', value: 'TeamRoute' });

			if (MerchantConfigurations.enable_fleets) {
				DispatchListService.groupByOptions.push({ label: 'DISPATCH_LIST.FLEET', value: 'Fleet' });
				DispatchListService.groupByOptions.push({ label: 'DISPATCH_LIST.FLEET_AND_TEAM', value: 'FleetTeam' });
			}

			DispatchListService.getItemMetadata = function (oldFunction) {
				return function (row) {
					var item = this.getItem(row);
					var ret = oldFunction(row);

					if (item) {
						ret = ret || {};

						if (item.late) {
							ret.cssClasses = (ret.cssClasses || '') + ' late';
						}

						if (item.status === 7) {
							ret.cssClasses = (ret.cssClasses || '') + ' cancelled';
						}

						if (LockedTaskService.isLockedByOther(item) || item.__group) {
							ret.selectable = false;
							ret.disabled = true;
						} else {
							ret.selectable = true;
							ret.disabled = false;
						}
					}

					return ret;
				};
			};

			/**
			 * new code to get defaults columns - right now used only by dispatcher
			 */
			DispatchListService.getDefaultsColumns = function () {
				var currentUser = Authentication.currentUser();

				// default values
				var defaults = [
					{
						id: 'id',
						field: 'external_id',
						width: 55,
						filter: 'taskIdGridFilter',
						title: 'DISPATCH_LIST.ID',
						toolTip: 'ID if the task',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.external_id;
						}
					},
					{
						id: 's',
						field: 'status',
						width: 30,
						filter: 'taskGridStatusFilter',
						title: 'DISPATCH_LIST.STATUS',
						translate: true,
						sortable: true
					},
					{
						id: 'order',
						noFilter: true,
						field: 'title',
						width: 240,
						title: 'DISPATCH_LIST.ORDER',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.title;
						}
					},
					{
						id: 'priority',
						title: 'DISPATCH_LIST.PRIORITY',
						translate: true,
						active: false,
						position: 4,
						getValue: function (item) {
							const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(item.id);
							return collapseTaskData ? collapseTaskData.priorities : item.priority;
						}
					},
					{
						id: 'orderCreated',
						field: 'created_at',
						width: 100,
						filter: 'taskGridScheduledForFilter',
						title: 'DISPATCH_LIST.ORDER_CREATED',
						translate: true,
						sortable: true
					},
					{
						id: 'assignedTo',
						field: 'user_id',
						width: 130,
						filter: 'taskGridDriverFilter',
						filterField: 'assignedTo',
						customSortField: 'user_id',
						title: 'DISPATCH_LIST.ASSIGNED_TO',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							var value = 'Unassigned';
							if (item.user_id) {
								var employee = Employees.employeeByIdMap[item.user_id];
								value = employee ? employee.name : value;
							}

							return value;
						}
					},

					{
						id: 'taskProgress',
						field: 'external_id',
						width: 100,
						filter: 'taskGridProgressFilter',
						requiredFields: [
							'way_points.all.id',
							'way_points.all.late',
							'way_points.all.done',
							'active_way_point_id',
							'status'
						],
						title: 'DISPATCH_LIST.PROGRESS',
						translate: true,
						sortable: false
					},
					{
						id: 'nextStop',
						field: 'address',
						width: 240,
						requiredFields: ['active_way_point_id', 'way_points.all.id', 'way_points.all.address'],
						filter: 'taskGridAddressFilter',
						title: 'DISPATCH_LIST.NEXT_STOP',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.address;
						}
					},
					{
						id: 'done_planning',
						field: 'planning_done',
						filter: 'taskDonePlanningFilter',
						title: 'DISPATCH_LIST.DONE_PLANNING',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.done_planning;
						}
					},
					{
						id: ' ',
						title: ' ',
						field: 'late',
						width: 30,
						filter: 'taskGridLateFilter',
						translate: false,
						sortable: true
					},
					{
						id: 'scheduledFor',
						field: 'scheduled_at',
						title: 'DISPATCH_LIST.SCHEDULED_FOR',
						width: 140,
						filter: 'taskGridScheduledForFilter',
						formatterField: 'scheduledFor',
						translate: true,
						sortable: true
					},

					// new adds
					{
						id: 'way_points.first.address',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).address;
						}
					},
					{
						id: 'way_points.first.scheduled_at',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_SCHEDULED_AT',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.eta',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ETA',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.etl',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ETL',
						postDefaultFilter: 'taskGridScheduledForFilter',
						active: false,
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.customer.phone',
						field: 'way_points.first.phone',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_PHONE',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).phone;
						}
					},
					{
						id: 'way_points.first.customer.name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_CUSTOMER_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: true,
						filter: 'customerNameFilter',
						getSearchValue: function (item) {
							return (
								_.first(item.way_points) &&
								_.first(item.way_points).customer &&
								_.first(item.way_points).customer.name
							);
						}
					},
					{
						id: 'way_points.first.zipcode',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ZIPCODE',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).zipcode;
						}
					},

					// new adds
					{
						id: 'way_points.last.address',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).address;
						}
					},
					{
						id: 'way_points.last.full_address',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_FULL_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).full_address;
						}
					},
					{
						id: 'way_points.last.scheduled_at',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_SCHEDULED_AT',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.eta',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ETA',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.etl',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ETL',
						postDefaultFilter: 'taskGridScheduledForFilter',
						active: false,
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.customer.phone',
						field: 'way_points.last.phone',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_PHONE',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).phone;
						}
					},
					{
						id: 'way_points.last.customer.name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_CUSTOMER_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: false,
						filter: 'customerNameFilter',
						getSearchValue: function (item) {
							return (
								_.last(item.way_points) &&
								_.last(item.way_points).customer &&
								_.last(item.way_points).customer.name
							);
						}
					},
					{
						id: 'way_points.last.zipcode',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ZIPCODE',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).zipcode;
						}
					},
					{
						id: 'print_receipt',
						active: false,
						filter: 'taskGridPrintReceiptFilter',
						field: 'id',
						title: 'DISPATCH_LIST.PRINT_RECEIPT',
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.time_until_scheduled',
						title: 'DISPATCH_LIST.TIME_UNTIL_FIRST_WAY_POINT_SCHEDULED',
						active: false,
						getValue: function (item) {
							var activeWayPoint = _.find(item.way_points, { id: item.active_way_point_id });
							if (_.isNil(activeWayPoint)) {
								return '';
							}

							var scheduledTime = activeWayPoint.no_later_than || item.scheduled_at;
							if (_.isNil(scheduledTime)) {
								return '';
							}

							var now = moment().valueOf();
							var scheduledTimeValue = moment(scheduledTime).valueOf();

							if (scheduledTimeValue < now) {
								return '';
							}

							var result = '';
							if (scheduledTimeValue > moment(now).add(1, 'days').valueOf()) {
								result = $translate.instant('DISPATCH.FUTURE');
							} else {
								var diff = moment.utc(scheduledTimeValue - now);
								diff = diff.seconds(Math.ceil(diff.seconds() / 60) * 60);
								result = TimeManagerService.format(diff, 'HH:mm');
							}

							return result;
						},
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'task_type_id',
						title: 'DISPATCH_LIST.TASK_TYPE',
						translate: true,
						active: false,
						sortable: true,
						getValue: item => Tasks.getTaskTypeName(item.task_type_id)
					}
				];

				// total price in case enabled in merchant configurations
				if (MerchantConfigurations.enable_task_price) {
					defaults.push({
						id: 'total_price',
						title: 'DISPATCH_LIST.PRICE',
						field: 'total_price',
						width: 30,
						sortable: true,
						translate: true,
						getSearchValue: function (item) {
							return item.total_price;
						}
					});
				}

				// geohash
				if (MerchantConfigurations.$rdsMode() && currentUser.beta) {
					defaults.push({
						id: 'geohash',
						title: 'DISPATCH_LIST.GEOHASH',
						translate: true,
						field: 'geohash',
						width: 30,
						sortable: true
					});

					defaults.push({
						id: 'geohash_origin',
						title: 'DISPATCH_LIST.GEOHASH_ORIGIN',
						field: 'geohash_origin',
						active: false,
						width: 150,
						translate: true,
						sortable: true
					});

					defaults.push({
						id: 'geohash_destination',
						title: 'DISPATCH_LIST.GEOHASH_DESTINATION',
						field: 'geohash_destination',
						active: false,
						width: 150,
						translate: true,
						sortable: true
					});
				}

				// set position for each one
				_.each(defaults, function (defaultColumn, index) {
					defaultColumn.position = index;
				});

				return defaults;
			};

			/**
			 * update grid columns from settings
			 * @param gridColumns
			 */

			return DispatchListService;
		}
	);
