'use strict';

angular.module('bringgApp').factory('Inventory', function ($resource, WEB_API_URL, REAL_TIME_URL) {
	var Inventory = $resource(
		REAL_TIME_URL + '/inventories/:id',
		{ id: '@id' },
		{
			all: { method: 'GET', isArray: true, url: REAL_TIME_URL + '/inventories/:id' },
			save: { method: 'POST', url: REAL_TIME_URL + '/inventories/:id' },
			update: { method: 'PATCH', url: WEB_API_URL + '/inventories/:id' },
			delete: { method: 'DELETE', url: WEB_API_URL + '/inventories/:id' }
		}
	);

	return Inventory;
});
