'use strict';

angular.module('bringgApp').factory('ReportPreferences', function (BringgSDK) {
	function reportPreferencesService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.reportPreferences[method].apply(bringg.reportPreferences, args);
			});
		}.bind(this);
	}

	var ReportPreferences = {
		upsert: reportPreferencesService('upsert'),
		remove: reportPreferencesService('delete')
	};

	return ReportPreferences;
});
