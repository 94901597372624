'use strict';

angular
	.module('bringgApp')
	.factory(
		'ApplicationActionService',
		function ($rootScope, MerchantConfigurations, $timeout, $q, $http, SocketPubSub, bringg_utils, WEB_API_URL) {
			var ApplicationActionService = {};

			var _backgroundTasks = {};
			var _supportedActions = {};

			var DEFAULT_TIMEOUT = 10000;

			ApplicationActionService.getActionsThatSupportLabel = function (label) {
				return _.chain(_supportedActions)
					.values()
					.filter(function (action) {
						return _.includes(action.supported, label);
					})
					.value();
			};

			ApplicationActionService.executeAsync = function (action, data, options) {
				options = options || {};
				var deferred = $q.defer();
				var merchantId = MerchantConfigurations.id;

				if (_supportedActions[action]) {
					var uuid = options.uuid || bringg_utils.guid();

					_backgroundTasks[uuid] = {
						promise: deferred,
						timer: _createTimeout(
							uuid,
							options.timeout || _supportedActions[action].timeout || DEFAULT_TIMEOUT
						),
						action: action
					};

					ApplicationActionService._sendBackgroundTask(uuid, merchantId, action, data)
						.then(function () {
							if (options.rpc === false) {
								_resolvePromise(uuid, true);
							}
						})
						.catch(function (response) {
							_resolvePromise(uuid, false, { message: response.statusText });
						});
				} else {
					deferred.reject({ message: action + ' unsupported' });
				}

				return deferred.promise;
			};

			function _createTimeout(uuid, timeout) {
				return $timeout(function () {
					if (_backgroundTasks[uuid]) {
						_backgroundTasks[uuid].promise.reject({ message: _backgroundTasks[uuid].action + ' timeout' });
						delete _backgroundTasks[uuid];
					}
				}, timeout);
			}

			ApplicationActionService._sendBackgroundTask = function (uuid, merchantId, action, data) {
				return $http.post(WEB_API_URL + '/application_action', {
					uuid: uuid,
					merchant_id: merchantId,
					action_name: action,
					data: data
				});
			};

			ApplicationActionService._onBackgroundTaskDone = function (result) {
				_resolvePromise(result.uuid, result.success, result.success ? result.data : result.error);
			};

			function _resolvePromise(uuid, success, result) {
				if (_backgroundTasks[uuid]) {
					$timeout.cancel(_backgroundTasks[uuid].timer);

					if (success) {
						_backgroundTasks[uuid].promise.resolve(result);
					} else {
						_backgroundTasks[uuid].promise.reject(result);
					}

					delete _backgroundTasks[uuid];
				}
			}

			SocketPubSub.on('application action done', ApplicationActionService._onBackgroundTaskDone);

			$rootScope.$on('config updated', function () {
				_supportedActions = _.chain(MerchantConfigurations.applications)
					.filter(function (app) {
						return (
							app.configuration && app.configuration.application && app.configuration.application.actions
						);
					})
					.map(function (app) {
						return app.configuration.application.actions;
					})
					.flatten()
					.keyBy('action')
					.value();

				$rootScope.$broadcast('application actions loaded');
			});

			return ApplicationActionService;
		}
	);
