'use strict';

angular
	.module('bringgApp')
	.controller(
		'DriverDiagnosticsController',
		function ($scope, $http, WEB_API_URL, $translate, DriverConfigurations, toastr) {
			$scope.diagnosticsConfigurationPoolingInterval = null;
			$scope.diagnosticsConfigurationPoolingIntervals = [0, 5, 10, 30, 60];

			$scope.usersIds = null;
			$scope.usersPercentage = null;
			$scope.diagnosticInterval = null;
			$scope.diagnosticPeriod = null;

			$scope.diagnosticIntervals = [0, 1, 5, 15, 60, 240, 360];
			$scope.diagnosticPeriods = [-1, 0, 5, 15, 60, 240, 360];
			$scope.usersPercentages = [
				{ value: 0.5, label: '5%' },
				{ value: 0.1, label: '10%' },
				{ value: 0.2, label: '20%' },
				{ value: 0.5, label: '50%' },
				{ value: 0.1, label: '100%' }
			];

			$scope.lastTestStart = null;
			$scope.lastTestEnd = null;

			$scope.sending = false;

			$scope._getConfig = function () {
				return {
					diagnostics: {
						user_ids: $scope.usersIds && $scope.usersIds.length ? $scope.usersIds.split(',') : null,
						users_percentage: $scope.usersPercentage,
						interval: $scope.diagnosticInterval,
						period: $scope.diagnosticPeriod
					}
				};
			};

			$scope.handleStartDiagnosticsClick = function () {
				if (_.isEmpty($scope.usersIds) && _.isNull($scope.usersPercentage)) {
					toastr.error($translate.instant('DRIVER_DIAGNOSTICS.MUST_SET_USER_IDS'));
					return;
				}

				$scope.sending = true;

				$http
					.post(WEB_API_URL + '/diagnostics/start_client_diagnostics', $scope._getConfig())
					.success(function (data) {
						$scope.sending = false;
						toastr.success($translate.instant('DRIVER_DIAGNOSTICS.DIAGNOSTICS_STARTED'));
					})
					.error(function (data, status) {
						$scope.sending = false;
						toastr.success($translate.instant('DRIVER_DIAGNOSTICS.FAILED_STARTING_DIAGNOSTICS'));
					});
			};

			$scope.handleStopDiagnosticsClick = function () {
				$scope.sending = true;
				$http
					.post(WEB_API_URL + '/diagnostics/stop_client_diagnostics')
					.success(function (data) {
						$scope.sending = false;
						toastr.success($translate.instant('DRIVER_DIAGNOSTICS.DIAGNOSTICS_STOPPED'));
					})
					.error(function (data, status) {
						$scope.sending = false;
						toastr.success($translate.instant('DRIVER_DIAGNOSTICS.FAILED_STOPPING_DIAGNOSTICS'));
					});
			};

			$scope.handleUpdateDiagnosticsConfigurationClick = function () {
				$scope.sending = true;
				DriverConfigurations.update({
					diagnostics_configuration_polling_interval: $scope.diagnosticsConfigurationPoolingInterval
				})
					.then(function () {
						$scope.sending = false;
						toastr.success($translate.instant('DRIVER_DIAGNOSTICS.UPDATE_SAVED'));
					})
					.catch(function () {
						$scope.sending = false;
						toastr.error($translate.instant('DRIVER_DIAGNOSTICS.FAILED_UPDATING'));
					});
			};

			$scope.init = function () {
				DriverConfigurations.get().then(function (result) {
					$scope.diagnosticsConfigurationPoolingInterval = result.diagnostics_configuration_polling_interval;
					$scope.diagnosticInterval = result.diagnostics_interval;
					$scope.lastTestStart = result.diagnostics_started_at;
					$scope.lastTestEnd = result.diagnostics_end_at;
				});
			};

			$scope.init();
		}
	);
