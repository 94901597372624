'use strict';
angular.module('bringgApp').factory('AutoDispatchAnalytic', function (BringgSDK, MerchantConfigurations, TasksData) {
	function AutoDispatchService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.vrpAutoDispatchAnalytic[method].apply(bringg.vrpAutoDispatchAnalytic, args);
			});
		}.bind(this);
	}

	var autoDispatchAnalytics = { autoDispatchState: {} };
	autoDispatchAnalytics._onDoneEvent = AutoDispatchService('onDoneEvent');
	autoDispatchAnalytics._getTeamsStates = AutoDispatchService('getTeamsStates');

	autoDispatchAnalytics.UI_STATUSES_TRANSLATIONS = {
		FAILED: 'AUTO_DISPATCH_STATE.TASK.FAILED',
		IN_PROCESS: 'AUTO_DISPATCH_STATE.TASK.IN_PROCESS',
		DISPATCHED: 'AUTO_DISPATCH_STATE.TASK.DISPATCHED',
		NOT_APPLIED: 'AUTO_DISPATCH_STATE.TASK.NOT_APPLIED'
	};

	autoDispatchAnalytics.AUTO_DISPATCH_STATUS_TO_UI_STATUS = {
		DELAYED_ASSIGNMENT: autoDispatchAnalytics.UI_STATUSES_TRANSLATIONS.IN_PROCESS,
		OPTIMIZATION: autoDispatchAnalytics.UI_STATUSES_TRANSLATIONS.FAILED,
		ASSIGNED_TO_EXTERNAL_FLEET: autoDispatchAnalytics.UI_STATUSES_TRANSLATIONS.DISPATCHED,
		ASSIGNED: autoDispatchAnalytics.UI_STATUSES_TRANSLATIONS.DISPATCHED
	};

	autoDispatchAnalytics._update = function (autoDispatchTeamState) {
		if (autoDispatchTeamState) {
			var teamId = autoDispatchTeamState.team_id,
				state = autoDispatchTeamState.state,
				timestamp = autoDispatchTeamState.timestamp;

			autoDispatchAnalytics.autoDispatchState[teamId] = state;
			autoDispatchAnalytics.autoDispatchState[teamId].timestamp = timestamp;
		}
	};

	autoDispatchAnalytics.subscribe = function () {
		return autoDispatchAnalytics._onDoneEvent(autoDispatchAnalytics._update);
	};

	autoDispatchAnalytics.load = function () {
		return autoDispatchAnalytics._getTeamsStates().then(function (teamsStates) {
			_.forEach(teamsStates, autoDispatchAnalytics._update);
		});
	};

	autoDispatchAnalytics.getTaskStatus = function (teamId, task) {
		const taskId = task.id;
		var state = autoDispatchAnalytics.autoDispatchState;
		var taskState = _.get(state, teamId + '.' + taskId);
		if (taskState) {
			if (taskState.isAssigned) {
				return autoDispatchAnalytics.AUTO_DISPATCH_STATUS_TO_UI_STATUS.ASSIGNED;
			} else {
				return autoDispatchAnalytics.AUTO_DISPATCH_STATUS_TO_UI_STATUS[taskState.reason];
			}
		}
		if (_.get(task, 'user_id')) {
			return autoDispatchAnalytics.AUTO_DISPATCH_STATUS_TO_UI_STATUS.ASSIGNED;
		}
		return '';
	};

	autoDispatchAnalytics.isVrpAutoDispatchEnabled = function () {
		return _.some(MerchantConfigurations.applications, function (app) {
			return _.isMatch(app, { name: 'VrpAutoDispatch' });
		});
	};

	autoDispatchAnalytics.init = function () {
		if (autoDispatchAnalytics.isVrpAutoDispatchEnabled()) {
			autoDispatchAnalytics.load();
			autoDispatchAnalytics.subscribe();
		}
	};

	return autoDispatchAnalytics;
});
