'use strict';

angular
	.module('bringgApp')
	.constant('IMAGE_SIZE', {
		DEFAULT: 2040000,
		TAG: 500000,
		CUSTOMER: 64000,
		MERCHANT_LOGO: 1000000
	})
	.controller(
		'EmployeeController',
		function EmployeeController(
			$scope,
			$rootScope,
			Employees,
			$routeParams,
			bringg_utils,
			Privilege,
			$q,
			Teams,
			FileUploader,
			WEB_API_URL,
			localStorageService,
			Employee,
			toastr,
			Authentication,
			MerchantConfigurations,
			EmployeeShiftsScheduleService,
			MerchantShiftScheduleService,
			UserTypes,
			$log,
			EmployeeScheduledBreaksService,
			QrCodeGeneratorService,
			$translate,
			$timeout,
			SkillsService,
			VehiclesService,
			ReactUserSettings,
			CompaniesService,
			PRIVILEGES_TYPES,
			IMAGE_SIZE,
			GeocodingService,
			PhoneNumberService
		) {
			$scope.editMode = false;
			$scope.employee = null;
			$scope.saving = false;
			$scope.loading = true;
			$scope.starsArray = [];
			$scope.smsInvitationSent = false;
			$scope.emailInvitationSent = false;
			$scope.profile_image = null;
			$scope.qrCodeLoginEnabled = false;
			$scope.driverId = $routeParams.id;
			$scope.skillsEnabled = MerchantConfigurations.enable_skills;
			$scope.vehiclesEnabled = MerchantConfigurations.enable_vehicles;
			$scope.enableDeliveryBlocks = MerchantConfigurations.enable_delivery_blocks;
			$scope.data = {
				selectedTeamIds: [],
				selectedPrivileges: []
			};
			$scope.tempSkills = [];
			$scope.skillsLoaded = false;
			$scope.languageCode = null;

			$scope.assignDepositsEnabled = Authentication.currentUser()
				? Authentication.currentUser().feature_flags.assign_deposits_enabled || false
				: false;
			$scope.isCurrentUserAdmin = Authentication.currentUser() ? Authentication.currentUser().admin : false;
			$scope.canAssignToCompany = Authentication.userHasAccess(PRIVILEGES_TYPES.ASSIGN_USER_TO_COMPANY);

			$scope.canChangeSkills = Authentication.userHasAccess(PRIVILEGES_TYPES.CHANGE_DRIVER_SKILLS);
			$scope.canCreateSkills = Authentication.userHasAccess(PRIVILEGES_TYPES.CREATE_DRIVER_SKILLS);

			$scope.selectTeamsConfig = {
				valueField: 'id',
				labelField: 'name',
				searchField: 'name',
				plugins: ['remove_button'],
				placeholder: $translate.instant('REPORTS.CHOOSE_TEAMS')
			};

			this._initialize = function () {
				$scope.teamsEnabled = MerchantConfigurations.enable_teams || false;
				$scope.userTypesEnabled = MerchantConfigurations.enable_user_types || false;
				$scope.qrCodeLoginEnabled =
					MerchantConfigurations.qr_login_flow_options ==
						QrCodeGeneratorService.appearance_options.ON_DRIVER_PAGE ||
					MerchantConfigurations.qr_login_flow_option ==
						QrCodeGeneratorService.appearance_options.ON_ALL_LOCATIONS;

				let teamPromise;

				if (
					Authentication.currentUser().has_access(PRIVILEGES_TYPES.ENABLE_SELF_ASSIGNMENT_TO_TEAMS) &&
					Authentication.currentUser().id == $routeParams.id
				) {
					teamPromise = Teams.byMerchantId(Authentication.currentUser().merchant_id);
				} else {
					teamPromise = Teams.all();
				}

				$q.all([teamPromise, UserTypes.getAllAsync(), Authentication.featureFlags()]).then(function (results) {
					$scope.allTeams = results[0];
					$scope.allUserTypes = results[1].user_types;
					var featureFlags = results[2];
					$scope.driverPublicTranspotEnabled = featureFlags.allow_public_transport_for_driver || false;
				});

				function showPasswordField() {
					const securedPasswordSetup =
						MerchantConfigurations.secured_setup || MerchantConfigurations.enable_secured_user_passwords;

					if ($scope.isCurrentUserAdmin || !securedPasswordSetup) {
						return true;
					}

					return !$scope.editMode && $routeParams.id == Authentication.currentUser().id;
				}

				$scope.showPasswordField = showPasswordField();
			};

			this._initialize();

			$scope.$on('config updated', this._initialize);

			$scope.notFound = false;

			$rootScope.$watch($rootScope.isLoggedIn, function () {
				if (!$rootScope.isLoggedIn) {
					return;
				}

				$scope.current_user = Authentication.currentUser();
				$scope._loadEmployee();
			});

			$scope.formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

			$scope._employeeLoaded = function (employee) {
				$scope.notFound = false;
				$scope._originalEmoloyee = employee;
				$scope.languageCode = employee.language;
				$scope._updateEmployee(angular.copy(employee));

				$scope._createUploader();

				Employee.logs({ id: $routeParams.id }, function (result) {
					$scope.logs = result;
				});

				if ($scope.shouldDisplayShiftSchedule()) {
					loadEmployeeShiftsSchedules();
				}
			};

			$scope._loadEmployee = function () {
				Employees.get({ id: $routeParams.id, force: true })
					.then(function (data) {
						$scope._employeeLoaded(data);
					})
					.catch(function (error) {
						$log.error('Failed to load user: ' + error.message);
						$scope.notFound = true;
						$scope.loading = false;
					});
			};

			$scope._updateEmployee = function (employee) {
				$scope.employee = employee;
				$scope.profile_image = employee.profile_image;
				$scope.starsArray = bringg_utils.getStars($scope.employee);

				var promises = [];
				var privilegePromise = $q.resolve();

				if ($scope.isCurrentUserAdmin) {
					privilegePromise = Privilege.all().$promise.then(function (response) {
						$scope.privileges = response.privileges;
						$scope.userPrivilege = _.find($scope.privileges, { id: employee.privilege_id });
					});
				} else {
					if (employee.privilege_id) {
						privilegePromise = Privilege.get({ id: employee.privilege_id }).$promise.then(function (
							privilege
						) {
							$scope.userPrivilege = privilege;
						});
					}
				}

				var companiesPromise = $q.resolve();

				if ($scope.canAssignToCompany) {
					companiesPromise = CompaniesService.getAll().then(function (response) {
						$scope.companies = response.companies;
						$scope.data.selectedCompany = _.find($scope.companies, { id: employee.company_id });
					});
				} else if (employee.company_id) {
					companiesPromise = CompaniesService.get(employee.company_id).then(function (response) {
						$scope.data.selectedCompany = response.company;
					});
				}

				Teams.byUser($scope.employee, function (teams) {
					$scope.teams = teams;
					$scope.data.selectedTeamIds = _.map(teams, 'id');
				});

				var userTypesPromise = UserTypes.byUser($scope.employee).then(function (userType) {
					$scope.userType = userType;
				});

				var vehicleServicePromise = VehiclesService.byUser($scope.employee).then(function (vehicle) {
					$scope.vehicle = vehicle;
				});

				promises.push(privilegePromise, companiesPromise, userTypesPromise, vehicleServicePromise);

				$q.all(promises)
					.catch(function (error) {
						$log.error('Failed to update Employee: ' + (error ? error.message : ''));
					})
					.finally(function () {
						$scope.loading = false;
					});
			};

			//==============================================
			// User interactions
			//==============================================
			$scope.handleAskForLogsClick = function () {
				if ($scope.editMode) {
					return;
				}

				Employee.ask_for_log({ id: $scope.employee.id }, function (data) {
					toastr.success('Request for log was sent successfully', 'Continue to Bringg it');
				});
			};

			$scope.editUserDisabled =
				!MerchantConfigurations.allow_dispatcher_to_edit_drivers &&
				Authentication.currentUser().dispatcher &&
				!Authentication.currentUser().admin && // if this is a dispatcher only
				(!$scope.employee || $scope.employee.admin);

			$scope.handleEditDriverClick = function () {
				if ($scope.editMode) {
					return;
				}

				$scope.data.selectedTeamIds = _.map($scope.teams, 'id');
				$scope.data.selectedUserType = $scope.userType;
				$scope.data.selectedPrivilege = $scope.userPrivilege;

				if ($scope.userTypesEnabled) {
					// if user has any user type for him , use it, if empty use all merchant user types
					$scope.employee.allowed_user_types = _.isEmpty($scope.employee.allowed_user_types)
						? $scope.allUserTypes
						: $scope.employee.allowed_user_types;
				}

				$scope.editMode = true;
				$scope.tempSkills = angular.copy($scope.employee.skills);
				$scope.skillsLoaded = true;
			};

			$scope.getSortedSkills = function () {
				return _.sortBy($scope.employee.skills).join(', ');
			};

			$scope.handleCancelClick = function () {
				$scope.editMode = false;
				//reset the user configuration to what it was before the edit button was clicked
				$scope._updateEmployee(angular.copy($scope._originalEmoloyee));
			};

			/**
			 * update user shifts if form is dirty
			 */
			$scope._updateShifts = function () {
				// form is not dirty, dont change anything
				if ($scope.employeeForm.shiftsForm && !$scope.employeeForm.shiftsForm.$dirty) {
					return $q.resolve(false);
				}

				// shifts update
				var result = {
					lunchBreaks: [],
					shifts: []
				};

				if ($scope.customShifts) {
					result = MerchantShiftScheduleService.translateToDb($scope.uiShiftsPerDay);
				}

				return $q
					.all([
						EmployeeShiftsScheduleService.update($scope.employee.id, result.shifts),
						EmployeeScheduledBreaksService.update($scope.employee.id, result.lunchBreaks)
					])
					.then(function (results) {
						_applyShiftsDataToUI(results[0], results[1]);
						$scope.employeeForm.shiftsForm.$setPristine(); // set as undirty.
						toastr.success('Driver settings updated.');
					});
			};

			$scope.handleApplyClick = function () {
				if ($scope.saving) {
					return;
				}

				var teamIds = _.map($scope.data.selectedTeamIds || [], function (teamId) {
					return parseInt(teamId);
				});
				$scope.employee.team_ids = teamIds;

				$scope.employee.skills = $scope.tempSkills;

				var employeeConfiguration = Object.assign({}, $scope.employee);

				// do not run validations or post to server when password hasnt changed
				var isPasswordDirty = $scope.employeeForm.password && $scope.employeeForm.password.$dirty;
				if (!isPasswordDirty && $scope.employee.password) {
					delete employeeConfiguration.password;
				}

				// solve when empty string
				if (employeeConfiguration.password === '') {
					employeeConfiguration.password = undefined;
				}

				if ($scope.employeeForm.$valid) {
					$scope.saving = true;

					employeeConfiguration.user_type_id = $scope.data.selectedUserType
						? $scope.data.selectedUserType.id
						: null;

					employeeConfiguration.privilege_id = $scope.data.selectedPrivilege
						? $scope.data.selectedPrivilege.id
						: null;

					employeeConfiguration.company_id = $scope.data.selectedCompany
						? $scope.data.selectedCompany.id
						: null;

					if ($scope.userTypesEnabled) {
						// user has all user types, assuming default for merchant
						// convert to ids to not send all objects
						employeeConfiguration.allowed_user_types_ids =
							$scope.employee.allowed_user_types.length === $scope.allUserTypes.length
								? []
								: _.map(employeeConfiguration.allowed_user_types, 'id');
					}

					// handle react language change update
					if ($scope.employee.language && $scope.languageCode !== $scope.employee.language) {
						$scope.languageCode = $scope.employee.language;
						ReactUserSettings.setLanguage($scope.employee.language);
					}

					$q.all([Employee.update(employeeConfiguration.id, employeeConfiguration), Teams.getByIds(teamIds)])
						.then(function (results) {
							var user = results[0];
							var userTeams = results[1];

							$scope.teams = userTeams;
							$scope.userType = $scope.data.selectedUserType;
							$scope.userPrivilege = $scope.data.selectedPrivilege;

							//we just updated a user and if its a current employee. We dispatch it from here (if realtime did not work)
							if (Authentication.isCurrentUser(employeeConfiguration.user)) {
								$rootScope.$broadcast('currentUserUpdated', employeeConfiguration.user);
							}

							// update employees in case sockets are down
							var promises = [Employees.updateEmployee(user)];

							// don't validate shifts forms if delivery blocks are enabled and the user has the privilege to edit shifts
							if (!$scope.enableDeliveryBlocks && $scope.shouldDisplayShiftSchedule()) {
								promises.unshift($scope._updateShifts());
							}

							return $q.all(promises).then(
								function () {
									$scope.saving = false;
									$scope.editMode = false;
								},
								function (reason) {
									toastr.error(reason);
									$scope.saving = false;
								}
							);
						})
						.catch(function (error) {
							var errorString = '';
							$scope.saving = false;
							if (typeof error.details === 'object') {
								_.each(error.details, function (val, key) {
									errorString += key + ' : ' + val + '\r\n';
								});
							} else if (typeof error.details === 'string') {
								errorString = error.details;
							} else if (!error.details && error.message) {
								errorString = error.message;
							}

							toastr.error(errorString);
						});
				}
			};

			$scope.handleRegeneratePasswordClick = function () {
				if ($scope.editMode) {
					return;
				}

				if (_.isNull($scope.employee) || !$scope.employee.id) {
					return;
				}

				if (!$scope.employee.email) {
					toastr.error('User must have an email to reset password.');
					return;
				}

				if (
					confirm(
						'This will send an email to ' +
							$scope.employee.name +
							' ' +
							'(' +
							$scope.employee.email +
							')' +
							' with a link to change password.'
					)
				) {
					Employee.sendResetPasswordInstructions({ email: $scope.employee.email }, function (data) {
						if (data.success) {
							toastr.success('User should get an email to update his password.', 'Continue to Bringg it');
						} else {
						}
					});
				}
			};

			$scope.handleResendEmailInvitationClick = function () {
				if ($scope.editMode) {
					return;
				}

				if (_.isNull($scope.employee) || !$scope.employee.email) {
					return;
				}

				Employee.inviteByEmail($scope.employee.id)
					.then(function () {
						toastr.success('Email invitation was sent to driver.');
						$scope.emailInvitationSent = true;
					})
					.catch(function (error) {
						toastr.error('There was a problem sending invitation to driver.', error.message);
						$scope.emailInvitationSent = false;
					});
			};

			$scope.handleUnlockUser = function () {
				Employee.unlock($scope.employee.id)
					.then(function () {
						toastr.success('Driver was unlocked');
					})
					.catch(function (error) {
						toastr.error('There was a problem unlocking the driver.', error.message);
					});
			};

			$scope.handleResendSmsInvitationClick = function () {
				if ($scope.editMode) {
					return;
				}

				if (_.isNull($scope.employee) || !$scope.employee.phone) {
					return;
				}

				Employee.invite($scope.employee.id)
					.then(function () {
						$scope.smsInvitationSent = true;
						toastr.success('SMS invitation was sent to driver.');
						$timeout(function () {
							$scope.smsInvitationSent = false;
						}, 5000);
					})
					.catch(function (error) {
						toastr.error('There was a problem sending invitation to driver.', error.message);
						$scope.smsInvitationSent = false;
					});
			};

			$scope.handleUploadButtonClick = function () {
				angular.element('#uploadMe').trigger('click');
			};

			$scope.handleRemoveClick = function () {
				if (
					confirm($translate.instant('DELETE_USER_CONFIRMATION_MESSAGE') + ' ' + $scope.employee.name + '?')
				) {
					Employee.delete($scope.employee.id)
						.then(function () {
							toastr.success(
								$scope.employee.name + ' ' + $translate.instant('DELETE_USER_DIALOG.SUCCESSFULLY')
							);
							window.history.back();
						})
						.catch(function (error) {
							const toastrTitle = [1, 2, 3, 4].includes(error.rc)
								? $translate.instant('DELETE_USER_DIALOG.ERROR_CODE.RC' + error.rc)
								: error.details || undefined;
							toastr.error(
								$translate.instant('DELETE_USER_DIALOG.ERROR_MESSAGE') + ' ' + $scope.employee.name,
								toastrTitle
							);
						});
				}
			};

			//==============================================
			// Shifts
			//==============================================
			var loadEmployeeShiftsSchedules = function () {
				$q.all([
					EmployeeShiftsScheduleService.get($scope.employee.id),
					EmployeeScheduledBreaksService.get($scope.employee.id)
				]).then(
					function (results) {
						_applyShiftsDataToUI(results[0], results[1]);
					},
					function (reason) {
						toastr.error('Failed loading shifts configuration');
					}
				);
			};

			//update the ui based on the stuff from serv
			var _applyShiftsDataToUI = function (shifts, breaks) {
				$scope.customShifts = shifts.custom || breaks.custom;
				$scope.uiShiftsPerDay = MerchantShiftScheduleService.translateFromDb(
					shifts.data,
					breaks.scheduled_breaks
				);
			};

			$scope.shouldDisplayShiftSchedule = function () {
				return (
					$scope.current_user.admin ||
					$scope.current_user.driver ||
					$scope.current_user.has_access('maintain_driver_shift_times')
				);
			};

			//update the ui based on the stuff from serv
			//==============================================
			// Image uploading
			//==============================================
			$scope._createUploader = function () {
				var uploader = ($scope.image_uploader = new FileUploader({
					url: WEB_API_URL + '/users/' + $scope.employee.id + '/profile_image',
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				}));

				uploader.filters.push(bringg_utils.imageFilter());
				uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.DEFAULT));

				uploader.onWhenAddingFileFailed = function (item, filter, options) {
					if (filter.name === 'imageFilter') {
						toastr.error('Only image files please. SUPPORTED: jpg, jpeg, png, bmp, gif');
					} else if (filter.name === 'sizeFilter') {
						toastr.error('Your image is too big please provide a different one. MAX SIZE: 2 MB');
					}
				};

				uploader.onAfterAddingFile = function (addedFile) {
					$scope.image_uploader.queue.push(addedFile);
					$scope.image_uploader.uploadAll();
				};

				uploader.onCompleteItem = function (item, response) {
					if (response.success) {
						$scope.profile_image = response.profile_image.url;
						toastr.success($translate.instant('ADD_DRIVER.IMAGE_UPLOADED_MESSAGE'));
					}
				};
			};

			/**
			 * show password validations
			 */
			$scope.showPasswordValidations = function () {
				// empty password is allowed
				if (_.isEmpty($scope.employeeForm.password.$viewValue)) {
					return false;
				}
				// show if password had focused at least once (prevent validation errors when password hasnt changed)
				return $scope.employeeForm.password.hasFocused;
			};

			$scope.handleGenerateQRCodeClick = function () {
				QrCodeGeneratorService.open($scope.employee.id, $scope.employee.name);
			};

			$scope.handleAddressSearch = function (search, refreshAddresses, employee) {
				if (!search || !search.length) {
					$scope.setEmptyHomeAddress(employee);
				}

				return refreshAddresses(search);
			};

			$scope.handleAddressSelected = function (item, model, employee) {
				if (!item) {
					$scope.setEmptyAddress(employee);
					return;
				}
				return GeocodingService.getPlaceDetails(item.place_id)
					.then(function (placeDetails) {
						if (!placeDetails.success) {
							$log.error('handleAddressSelected got unsuccessful result' + placeDetails.message);
							$scope.setEmptyAddress(employee);
							return;
						}
						employee.home_lat = placeDetails.address_details.lat;
						employee.home_lng = placeDetails.address_details.lng;
						employee.home_address = item.address;
					})
					.catch(function (error) {
						$log.error('handleAddressSelected got error' + error);
						$scope.setEmptyHomeAddress(employee);
					});
			};

			$scope.setEmptyHomeAddress = function (employee) {
				employee.home_lat = null;
				employee.home_lng = null;
				employee.home_address = null;
			};
		}
	);
