'use strict';

angular.module('bringgApp').factory('CancelTaskService', function (ReasonToCancelTask, Tasks, $q) {
	var CancelTaskService = {};

	CancelTaskService._reasons = [];

	CancelTaskService._getReasons = function (tagId) {
		return ReasonToCancelTask.all({ tag: tagId }).$promise.then(function (response) {
			CancelTaskService._reasons = response.reasons_to_cancel_task;
			return response.reasons_to_cancel_task;
		});
	};

	CancelTaskService.cancelTasks = function (tasksIds, reason, text) {
		if (text && text.length > 255) {
			return $q.reject({ message: 'Cancellation reason too long' });
		} else {
			return Tasks.massCancel({ task_ids: tasksIds, reason: reason, text: text });
		}
	};

	return CancelTaskService;
});
