'use strict';

angular.module('bringgApp').service('SendAnnouncmentService', function ($uibModal) {
	var service = {};
	service.popoverShown = false;

	service.open = function (employee) {
		if (service.popoverShown) {
			return;
		}
		service.popoverShown = true;
		var modalInstance = $uibModal.open({
			backdrop: true,
			keyboard: true,
			controller: 'SendAnnouncementController',
			templateUrl: 'scripts/features/announcement/send-announcment.html',
			resolve: {
				employee: function () {
					return employee;
				}
			}
		});

		modalInstance.result.then(function () {
			service.popoverShown = false;
		});
	};

	return service;
});
