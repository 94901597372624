'use strict';
/*global google:false */

angular
	.module('bringgApp')
	.controller(
		'AddTaskController',
		function AddTaskController(
			$scope,
			$uibModalInstance,
			employee,
			duplicatedTask,
			dialogSrv,
			Task,
			Waypoint,
			MerchantConfigurations,
			Employees,
			EmployeesTasks,
			WEB_API_URL,
			MerchantService,
			Teams,
			Lightbox,
			Customers,
			Tags,
			$translate,
			Geolocation,
			toastr,
			GeocodingService,
			$log,
			Authentication
		) {
			$scope.remoteFindPath = WEB_API_URL + '/customers/find?searchstr=';
			$scope.currentlySaving = false;
			$scope.merchantConfiguration = MerchantConfigurations;
			$scope.allowPrices = MerchantConfigurations.enable_task_price;
			$scope.data = { selectedTeams: [] };
			$scope.allowMultiWayPoints = Authentication.currentUser().feature_flags.allow_more_than_two_way_points;
			$scope.onlyDeliveryTask = Authentication.currentUser().feature_flags.add_task_only_delivery_task;

			$scope.TASK_TYPES = {
				PICKUP: 2,
				DELIVERY: 4
			};

			if (duplicatedTask && duplicatedTask.id) {
				if (MerchantConfigurations.unique_external_id) {
					delete duplicatedTask.external_id;
				}

				delete duplicatedTask.id;
				$scope.task = new Task(duplicatedTask);
				$scope.task.way_points = duplicatedTask.way_points;

				$scope.data.selectedTeams = [Teams.fetch(duplicatedTask.team_id)];
			} else {
				$scope.task = new Task(duplicatedTask);
				$scope.task.way_points = [new Waypoint()];
				var scheduledAt = moment().add(
					MerchantConfigurations.default_minutes_buffer_for_scheduled_orders,
					'minutes'
				);
				$scope.task.way_points[0].scheduled_at = scheduledAt.toDate();
				if ($scope.onlyDeliveryTask) {
					$scope.task.task_type_id = $scope.TASK_TYPES.DELIVERY;
				}
			}

			$scope._loadTags = function () {
				Tags.all(function (result) {
					$scope.tags = angular.copy(result);
				});
			};

			Teams.all(function (teams) {
				$scope.teams = teams;
			});

			$scope._loadTags();

			$scope.$on('config updated', function () {
				Teams.all(function (teams) {
					$scope.teams = teams;
				});
			});

			$scope.handleCustomerSelected = function (item, model, wayPoint) {
				if (!item || !wayPoint) {
					return;
				}

				var wayPointPosition = parseInt($scope._getWaypointIndex(wayPoint));

				if (_.isNull(item.id) || _.isUndefined(item.id)) {
					if (wayPoint.customer && wayPoint.customer.id && wayPoint.customer.name !== item.name) {
						wayPoint.customer_id = undefined;
					}
				} else {
					$scope.chooseCustomer(item, wayPointPosition);
				}
			};

			$scope.chooseCustomer = function (customer, way_point_position) {
				// we save old address to know if old address was default customer address
				var old_customer_address;
				if (
					$scope.task.way_points[way_point_position].customer &&
					$scope.task.way_points[way_point_position].customer.address
				) {
					old_customer_address = $scope.task.way_points[way_point_position].customer.address;
				}

				$scope.task.way_points[way_point_position].customer_id = customer.id;
				$scope.task.way_points[way_point_position].customer = customer;

				if (
					customer.address !== null &&
					($scope.task.way_points[way_point_position].address === undefined ||
						$scope.task.way_points[way_point_position].address.length === 0 ||
						$scope.task.way_points[way_point_position].address === old_customer_address)
				) {
					$scope.task.way_points[way_point_position].address = customer.address;
					$scope.task.way_points[way_point_position].address_second_line = customer.address_second_line;
					$scope.geocodeAddress($scope.task.way_points[way_point_position]);
				}
				$scope.task.way_points[way_point_position].phone = customer.phone;
				$scope.task.way_points[way_point_position].email = customer.email;

				$scope.validateEmail(way_point_position);
				$scope.geocodeWaypointAddress(way_point_position);
			};

			$scope.addWaypoint = function () {
				var lastWayPoint = _.last($scope.task.way_points);
				var lastScheduledAt = lastWayPoint ? moment(lastWayPoint.scheduled_at) : moment();
				var minutesSinceLastWaypoint =
					lastWayPoint && lastWayPoint.asap
						? MerchantConfigurations.default_minutes_buffer_for_asap_orders
						: MerchantConfigurations.default_minutes_buffer_for_scheduled_orders;
				var scheduledAt = lastScheduledAt.add(minutesSinceLastWaypoint, 'minutes');
				var newWayPoint = new Waypoint();
				newWayPoint.scheduled_at = scheduledAt.toDate();
				$scope.task.way_points.push(newWayPoint);

				$scope.syncWayPointConfig();
			};

			$scope.validateEmail = function (index) {
				var wayPoint = $scope.task.way_points[index],
					email = wayPoint.email;
				if (_.isEmpty(email)) {
					wayPoint.email_ok = undefined;
					return;
				}
				wayPoint.email_ok = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
			};

			var geocoder = new google.maps.Geocoder();
			$scope.geocodeWaypointAddress = function (index) {
				var wayPoint = $scope.task.way_points[index];
				wayPoint.lat = null;
				wayPoint.lng = null;
				if (_.isUndefined(wayPoint.address) || _.isEmpty(wayPoint)) {
					wayPoint.ok = undefined;
					return;
				}
				wayPoint.ok = false;
				var language_code = $scope.merchantConfiguration.language_code;

				geocoder.geocode({ address: wayPoint.address }, function (results, status) {
					if (status === google.maps.GeocoderStatus.OK) {
						$scope.$apply(function () {
							wayPoint.ok = true;
							wayPoint.lat = results[0].geometry.location.lat();
							wayPoint.lng = results[0].geometry.location.lng();
						});
					}
				});
			};

			$scope.geocodeAddress = function (way_point) {
				geocoder.geocode(
					{
						address: way_point.address
					},
					function (results, status) {
						if (status === google.maps.GeocoderStatus.OK) {
							$scope.$apply(function () {
								way_point.lat = results[0].geometry.location.lat();
								way_point.lng = results[0].geometry.location.lng();
							});
						} else {
							//alert('Geocode was not successful for the following reason: ' + status);
						}
					}
				);
			};

			$scope.handleSeeInMapClick = function (way_point) {
				Lightbox.openModal(
					[
						{
							url: Geolocation.generateStaticMap(
								way_point.lat,
								way_point.lng,
								way_point.lat,
								way_point.lng
							)
						}
					],
					0
				);
			};

			$scope.handleAddressSelected = function (item, model, wayPoint) {
				if (!item) {
					$scope.setEmptyAddress(wayPoint);
					return;
				}
				return GeocodingService.getPlaceDetails(item.place_id)
					.then(function (placeDetails) {
						if (!placeDetails.success) {
							$log.error('handleAddressSelected got unsuccessful result' + placeDetails.message);
							$scope.setEmptyAddress(wayPoint);
							return;
						}
						wayPoint.lat = placeDetails.address_details.lat;
						wayPoint.lng = placeDetails.address_details.lng;
						wayPoint.address = item.address;
						wayPoint.ok = true;
					})
					.catch(function (error) {
						$log.error('handleAddressSelected got error' + error);
						$scope.setEmptyAddress(wayPoint);
					});
			};

			$scope.setEmptyAddress = function (wayPoint) {
				wayPoint.lat = null;
				wayPoint.lng = null;
				wayPoint.address = null;
				wayPoint.ok = false;
			};

			$scope.handleDriverSelected = function (item, model) {
				$scope.task.user_id = item.id;
				$scope.task.user = item;
			};

			$scope.handleTagSelected = function (tagId) {
				if (_.isNumber(tagId)) {
					$scope.setTagSelected(tagId);
				} else {
					$scope.unsetTagSelected();
				}
			};

			$scope.setTagSelected = function (tagId) {
				$scope.addClearTagOption();
				$scope.task.tag_id = tagId;
			};

			$scope.unsetTagSelected = function () {
				$scope.removeClearTagOption();
				$scope.data.tagSelected = undefined;
				$scope.task.tag_id = undefined;
			};

			$scope.addClearTagOption = function () {
				if (!$scope.isClearTagOptionExists()) {
					$scope.tags.unshift({ clearSelected: true, tag: $translate.instant('NEW_ORDER.UNSELECT_TAG') });
				}
			};

			$scope.removeClearTagOption = function () {
				if ($scope.isClearTagOptionExists()) {
					$scope.tags.shift();
				}
			};

			$scope.isClearTagOptionExists = function () {
				return $scope.tags[0] && $scope.tags[0].clearSelected;
			};

			const PICKUP_DROPOFF_OPTION = {
				pickup: 0,
				dropoff: 1,
				both: 2
			};

			// when a task has two waypoint, position should be 2 for Route Optimization reasons [SERVER-38819]
			const SECOND_POSITION = 2;

			$scope.onTypeChange = function () {
				$scope.syncWayPointConfig();
			};

			$scope.syncWayPointConfig = function () {
				if ($scope.task.way_points.length === 1) {
					const taskTypeId = +$scope.task.task_type_id;
					let pickup_dropoff_option;

					if (taskTypeId === $scope.TASK_TYPES.DELIVERY) {
						pickup_dropoff_option = PICKUP_DROPOFF_OPTION.dropoff;
					} else if (taskTypeId === $scope.TASK_TYPES.PICKUP) {
						pickup_dropoff_option = PICKUP_DROPOFF_OPTION.pickup;
					}

					$scope.task.way_points[0].pickup_dropoff_option = pickup_dropoff_option;
					$scope.task.way_points[0].position = SECOND_POSITION;
				} else {
					$scope.task.task_type_id = $scope.TASK_TYPES.DELIVERY;

					$scope.task.way_points.forEach(wayPoint => {
						delete wayPoint.position;
						delete wayPoint.pickup_dropoff_option;
					});
				}
			};

			if (employee) {
				$scope.employee = employee;
				$scope.task.user_id = employee.id;
				$scope.task.user = employee;
			}

			$scope._createCustomers = function () {
				var customer = $scope.addedCustomers.pop();

				Customers.create(
					customer.customer,
					function (saveResponse) {
						if (saveResponse.success === false) {
							var errorString = '';
							if (_.isArray(saveResponse.message)) {
								for (var messageIdx in saveResponse.message) {
									if (_.isArray(saveResponse.message[messageIdx])) {
										errorString += messageIdx + ':' + saveResponse.message[messageIdx][0] + '\r\n';
									} else {
										errorString += saveResponse.message[messageIdx];
									}
								}
							} else {
								errorString = saveResponse.message[0];
							}
							alert(errorString);
						} else {
							$scope.task.way_points[customer.index].customer_id = saveResponse.customer.id;
							Customers.addNewCustomer(saveResponse.customer);
							$scope.save();
						}
					},
					function (errorResult) {
						alert('Error - missing data or bad format');
					}
				);
			};

			$scope.save = function () {
				MerchantService.get().then(function (data) {
					//if(data.unpaid){
					//  ga('send', 'event', 'addTaskStopped', 'unpaidMerchant');
					//  toastr.error('Sorry but it seems you have a suspended account. Please contact our support team at support@bringg.com');
					//}else{
					$scope.currentlySaving = true;

					$scope.addedCustomers = [];

					if (!$scope.task.user_id && $scope.employee) {
						$scope.task.user_id = $scope.employee.id;
					}
					var customer_added = false;
					_.each($scope.task.way_points, function (way_point, index) {
						if (_.isUndefined(way_point.customer_id)) {
							customer_added = true;
							if (way_point.customer) {
								way_point.customer.id = undefined;
							}
							if (way_point.customer && way_point.customer.name.length > 0) {
								if (!way_point.customer.address) {
									way_point.customer.address = way_point.address;
									way_point.customer.lat = way_point.lat;
									way_point.customer.lng = way_point.lng;
								}
								way_point.customer.phone = way_point.phone;
								way_point.customer.email = way_point.email;
								$scope.addedCustomers.push({ customer: way_point.customer, index: index });
							}
						} else {
							var customer_edited = false;
							if (way_point.phone && !way_point.customer.phone) {
								way_point.customer.phone = way_point.phone;
								customer_edited = true;
							}
							if (way_point.email && !way_point.customer.email) {
								way_point.customer.email = way_point.email;
								customer_edited = true;
							}
							if (customer_edited) {
								Customers.update(
									way_point.customer,
									function (saveResponse) {
										if (saveResponse.success === false) {
											var errorString = '';
											for (var messageIdx in saveResponse.message) {
												errorString +=
													messageIdx + ':' + saveResponse.message[messageIdx][0] + '\r\n';
											}
											alert(errorString);
										} else {
										}
									},
									function (errorResult) {
										alert('Error - missing data or bad format');
									}
								);
								$scope.currentlySaving = false;
							}
						}
					});

					if (customer_added || $scope.addedCustomers.length) {
						$scope._createCustomers();
						return;
					} else {
						var task = new Task($scope.task);
						task.teams = _.map($scope.data.selectedTeams, function (selectedTeam) {
							if (!_.isUndefined(selectedTeam)) {
								return selectedTeam.id;
							}
						});
						_.forEach(task.way_points, function (way_point) {
							if (way_point.customer) {
								delete way_point.customer.image; //remove image as it is not changed in this scope, prevents uploading signed urls
							}
						});

						task.$save(
							function (saveResponse) {
								$scope.currentlySaving = false;

								if (saveResponse.success === false) {
									toastr.error(saveResponse.message);
								} else {
									$uibModalInstance.close(saveResponse.task);
								}
							},
							function (errorResponse) {
								dialogSrv.errorDialog('Error adding new task', 'Maybe some details were missing?');
								$scope.currentlySaving = false;
							}
						);
					}
				});
			};

			$scope.close = function () {
				$uibModalInstance.dismiss(undefined);
			};

			$scope.removeWaypoint = function (way_point) {
				var index = $scope.task.way_points.indexOf(way_point);
				if (index >= 0) {
					$scope.task.way_points.splice(index, 1);
				}

				$scope.syncWayPointConfig();
			};

			$scope.backwardWaypoint = function (way_point) {
				var index = $scope.task.way_points.indexOf(way_point);
				if (index >= 1) {
					$scope.task.way_points.splice(index, 1);
					$scope.task.way_points.splice(index - 1, 0, way_point);
				}
			};

			$scope.forwardWaypoint = function (way_point) {
				var index = $scope.task.way_points.indexOf(way_point);
				if (index < $scope.task.way_points.length - 1) {
					$scope.task.way_points.splice(index, 1);
					$scope.task.way_points.splice(index + 1, 0, way_point);
				}
			};

			$scope.validateTask = function () {
				_.each($scope.task.way_points, function (wayPoint) {
					if (!wayPoint.ok) {
						return false;
					}
					if (wayPoint.phone && wayPoint.phone_ok !== undefined && !wayPoint.phone_ok) {
						return false;
					}
					if (wayPoint.email && wayPoint.email_ok !== undefined && !wayPoint.email_ok) {
						return false;
					}
				});

				if (!_.isNil($scope.teams) && $scope.teams.length >= 1) {
					if (_.isEmpty($scope.data.selectedTeams)) {
						return false;
					}
				}

				if (!$scope.task.task_type_id) {
					return false;
				}

				return true;
			};

			$scope._getWaypointIndex = function (way_point) {
				return _.findIndex($scope.task.way_points, way_point);
			};

			//==============================================
			// Init employees list
			//==============================================
			Employees.drivers({}, function (result) {
				$scope.employees = EmployeesTasks.optimizedEmployeeListForTask(result);
			});

			$scope.employeeFunct = EmployeesTasks.formatEmployeeSelection;
		}
	);
