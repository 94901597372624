'use strict';

angular.module('bringgApp').constant('TASK_HISTORY_ORIGIN', {
	UNKNOWN: 0,
	ROUTE_OPTIMIZATION: 1,
	AUTO_DISPATCH: 2,
	BROADCAST: 3,
	MANUAL: 4,
	REJECT: 5,
	AUTO_REJECT: 6,
	USER_ACCEPTED: 7,
	FLEET: 8
});
