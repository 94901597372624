'use strict';

angular.module('bringgApp').factory('AddCustomerService', function ($q, $uibModal) {
	var AddCustomerService = {};
	AddCustomerService.open = function () {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'AddCustomerController',
			templateUrl: 'scripts/features/add_customer/add-customer.html'
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return AddCustomerService;
});
