'use strict';

angular
	.module('bringgApp')
	.controller(
		'CancelTaskController',
		function (
			$scope,
			dialogSrv,
			$uibModalInstance,
			CancelTaskService,
			tasksIds,
			tagsIds,
			taskTypeIds,
			CANCEL_REASON_ORDER_TYPES,
			BringgCommonUtils,
			Authentication,
			$translate
		) {
			$scope.selectedReason = null;
			$scope.reasonText = null;
			$scope.sending = false;
			$scope.loading = true;

			$scope.handleCancelClick = function () {
				$uibModalInstance.dismiss('cancel');
			};

			$scope.close = function () {
				$uibModalInstance.dismiss('cancel');
			};

			$scope._validateTagsAndGetReasons = function () {
				tagsIds = _.uniq(tagsIds);
				if (tagsIds.length > 1) {
					dialogSrv.notifyDialog(
						$translate.instant('CANCEL_TASK_CONTROLLER.THESE_ORDERS_BELONG_TO_MORE_THAN_ONE_TAG')
					);
					$uibModalInstance.close('cancel');
				} else {
					CancelTaskService._getReasons(_.first(tagsIds)).then(
						function (reasons) {
							Authentication.featureFlags().then(function (featureFlags) {
								if (!featureFlags.filter_cancel_reasons_by_task_type) {
									$scope.cancelReasons = reasons;
									$scope.loading = false;
									return;
								}

								const uniqueSelectedTaskTypes = _.uniq(_.compact(taskTypeIds));
								if (uniqueSelectedTaskTypes.length > 1) {
									const reasonsWithoutOrderType = reasons.filter(
										({ order_type }) => order_type === CANCEL_REASON_ORDER_TYPES.NONE
									);
									if (reasonsWithoutOrderType.length) {
										$scope.cancelReasons = reasonsWithoutOrderType;
										$scope.loading = false;
										return;
									}

									dialogSrv.notifyDialog(
										$translate.instant(
											'CANCEL_TASK_CONTROLLER.ORDERS_BELONG_TO_MORE_THAN_ONE_TASK_TYPE'
										)
									);
									$uibModalInstance.close('cancel');
									return;
								}

								const taskTypeId = _.first(uniqueSelectedTaskTypes);
								$scope.cancelReasons = BringgCommonUtils.filterReasonsByOrderType(reasons, taskTypeId);
								$scope.loading = false;
							});
						},
						function () {
							toastr.error($translate.instant('CANCEL_TASK_CONTROLLER.FAILED_LOADING_THE_REASONS'));
							$uibModalInstance.close('cancel');
						}
					);
				}
			};

			$scope.handleOkClicked = function () {
				if ($scope.reasonText && $scope.reasonText.length > 255) {
					toastr.error('Cancellation reason too long');
					return;
				}
				$scope.sending = true;

				CancelTaskService.cancelTasks(tasksIds, $scope.selectedReason.id, $scope.reasonText).then(
					function (result) {
						$uibModalInstance.close(result);
					},
					function (reason) {
						$scope.sending = false;
						toastr.error(reason.message);
					}
				);
			};
		}
	);
