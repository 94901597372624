'use strict';

angular
	.module('bringgApp')
	.controller(
		'AnalyticsReportsController',
		function (
			$scope,
			$http,
			$rootScope,
			$location,
			ENV_CONFIGURATION,
			MerchantConfigurations,
			WindowCommunicationService,
			$window,
			$sce,
			reportsSerivce,
			$translate,
			Employees,
			$route
		) {
			var basePath = '/analytics/reports/';

			var getIframePath = function () {
				return $location.path().replace(basePath, '');
			};

			$rootScope.hideMenu = false;

			$rootScope.bodyClass = 'analytics-reports-app';
			$scope.$on('$destroy', function () {
				$rootScope.bodyClass = undefined;
			});

			$scope.getAnalyticsReportsUrl = function () {
				return $sce.trustAsResourceUrl(ENV_CONFIGURATION.analytics_reports_app_url);
			};

			$scope.sendInitialData = function () {
				var oldLegacyReports = reportsSerivce.availableReports().map(function (item) {
					return {
						id: item.id,
						name: $translate.instant(item.title),
						type: 'old'
					};
				});
				var contentWindow = document.getElementById('analyticsReportsIFrame').contentWindow;
				WindowCommunicationService.sendCredentials(contentWindow);
				WindowCommunicationService.sendInitialData(contentWindow, {
					parentApp: 'own-fleet',
					legacyReports: oldLegacyReports,
					initialRoute: getIframePath(),
					language: $translate.use()
				});
			};

			var lastRoute = $route.current;
			var unsubscribeLocationChangeSuccess = $scope.$on('$locationChangeSuccess', function () {
				// hack for disable reload iframe on route change
				// eslint-disable-next-line angular/no-private-call
				if (lastRoute.$$route.originalPath === $route.current.$$route.originalPath) {
					WindowCommunicationService.sendNewLocation(
						document.getElementById('analyticsReportsIFrame').contentWindow,
						getIframePath()
					);
					$route.current = lastRoute;
				}
			});

			var unsubscribeOnLocationChanges = WindowCommunicationService.subscribeOnLocationChanges(function (path) {
				if (getIframePath() !== path) {
					$location.path(basePath + path);
					$scope.$apply();
				}
			});

			var unsubscribeOnOpenWindow = WindowCommunicationService.subscribeOnOpenWindow(function (path) {
				window.open(path).focus();
			});

			$scope.$on('$destroy', function () {
				$location.hash('');
				unsubscribeLocationChangeSuccess();
				unsubscribeOnLocationChanges();
				unsubscribeOnOpenWindow();
			});
		}
	);

//TODO: remove it after migration on the url system without hash
angular.module('bringgApp').controller('AnalyticsReportsRootController', function ($location, $timeout) {
	$timeout(function () {
		var path = $location.hash() || 'my';
		$location.hash('');
		$location.path($location.path() + '/' + path);
	}, 0);
});
