'use strict';

angular.module('bringgApp').constant('GRID_DATA', {
	DISPATCH: {
		id: 'dispatch-task-list',
		fieldsAdminConfigName: 'task_fields'
	},
	PLANNING: {
		id: 'planning',
		fieldsAdminConfigName: 'planning_fields'
	}
});
