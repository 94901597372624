'use strict';

angular
	.module('bringgApp.filters')
	.filter('taskIdGridFilter', function ($translate, CollapsedLinkedTasksService) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(dataContext.id);
				if (collapseTaskData) {
					const title = $translate.instant(dataContext.external_id);
					return '<span>' + title + ' (' + collapseTaskData.tasks.length + ')' + '</span>';
				}
				return '<a href="#/history/' + dataContext.id + '">' + dataContext.external_id + '</a>';
			};
		};
	})
	.filter('secondsToHoursMinutesGridFilter', function ($filter) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				return $filter('seconds_to_hh_mm')(value);
			};
		};
	})
	.filter('tagName', function (Tags, CollapsedLinkedTasksService) {
		var tags = Tags.all();
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				if (!tags.$resolved) {
					return '';
				} else {
					const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(dataContext.id);
					if (collapseTaskData) {
						const tasksTagIds = collapseTaskData.tag_ids;
						return tasksTagIds.length
							? tags
									.map(tag => (tasksTagIds.includes(tag.id) ? tag.tag : null))
									.filter(Boolean)
									.toString()
							: '';
					} else if (dataContext.tag_id) {
						// There is a data integrity issue where some tag_id does not exist in the database
						var tag = _.find(tags, { id: dataContext.tag_id });
						return tag ? tag.tag : '';
					} else {
						return '';
					}
				}
			};
		};
	})
	.filter('wayPointCountFiler', function () {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				return value.length;
			};
		};
	})
	.filter('taskGridCustomItemRenderer', function ($filter) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				if (_.isFunction(propName)) {
					return propName(dataContext);
				}
				if (!_.isUndefined(dataContext[propName])) {
					return dataContext[propName];
				} else if (
					!_.isNull(dataContext.extras) &&
					!_.isUndefined(dataContext.extras) &&
					!_.isUndefined(dataContext.extras[propName])
				) {
					return dataContext.extras[propName];
				} else {
					var fields = propName.split('.'),
						subTaskValue = dataContext;

					for (var fieldIdx = 0; fieldIdx < fields.length; fieldIdx++) {
						if (_.isString(fields[fieldIdx]) && fields[fieldIdx].toLowerCase() === 'last') {
							subTaskValue = subTaskValue[subTaskValue.length - 1];
						} else if (_.isString(fields[fieldIdx]) && fields[fieldIdx].toLowerCase() === 'first') {
							subTaskValue = subTaskValue[0];
						} else {
							subTaskValue = subTaskValue[fields[fieldIdx]];
						}
						if (_.isUndefined(subTaskValue) || _.isNull(subTaskValue)) {
							break;
						}
					}

					if (!_.isUndefined(columnDef.filter)) {
						subTaskValue = $filter(columnDef.filter)(subTaskValue);
					} else if (!_.isUndefined(columnDef.postDefaultFilter)) {
						subTaskValue = $filter(columnDef.postDefaultFilter)(propName)(
							row,
							cell,
							subTaskValue,
							columnDef,
							dataContext
						);
					} else {
						subTaskValue = _.escape(subTaskValue);
					}

					return subTaskValue;
				}
			};
		};
	})
	.filter('taskGridLateFilter', function () {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				if (value) {
					return '<img src="/images/alert.png" class="alert-image ng-scope" title="Order is currently late" />';
				}
			};
		};
	})
	.filter('taskGridStatusFilter', function (bringg_utils) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var tooltip = bringg_utils.getStatusFromTask(dataContext);
				return '<span class="circle task_status_' + dataContext.status + '" title="' + tooltip + '"></span>';
			};
		};
	})
	.filter('taskDonePlanningFilter', function ($translate) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				if (dataContext.planning_done) {
					var planned = $translate.instant('DISPATCH_LIST.DONE_PLANNING_FIELD');
					return '<span class="done-planning">' + planned + '</span>';
				}

				return '<span></span>';
			};
		};
	})
	.filter('taskPromiseTimeFilter', function (TimezoneService, TimeManagerService, WayPointsService) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var actionableWayPoint = WayPointsService.getActionableWaypoint(dataContext);
				var promiseStartTime = _.get(actionableWayPoint, propName);

				if (!promiseStartTime) {
					return;
				}

				var timeZone = TimezoneService.getTaskTimezone(dataContext, { formatTimezone: true }) || '';
				var dateFormat = 'L LT';

				return (
					TimeManagerService.format(
						TimezoneService.getDateByTask(dataContext, promiseStartTime),
						dateFormat
					) +
					' ' +
					timeZone
				);
			};
		};
	})
	.filter('taskGridScheduledForFilter', function (TimezoneService, ROGUE_REASONS, TimeManagerService) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				if (_.isNull(value) || _.isUndefined(value)) {
					return '<span>' + '-- : --' + '</span>';
				}

				var then = TimezoneService.getDateByTask(dataContext, value);
				var timeZone = TimezoneService.getTaskTimezone(dataContext, { formatTimezone: true }) || '';

				var span = '<span>';
				var rogueReasons = _.map(dataContext.rogue_reasons, 'reason');
				if (_.includes(rogueReasons, ROGUE_REASONS.TIME_WINDOW)) {
					span = '<span style="color: red">';
				}

				return span + TimeManagerService.format(then, 'L LT') + timeZone + '</span>';
			};
		};
	})
	.filter('taskGridOrderCreatedFilter', function () {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				return '<span>' + moment(value).fromNow() + '</span>';
			};
		};
	})
	.filter('taskGridPrintReceiptFilter', function ($translate) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				return (
					'<button class="glyphicon glyphicon-print" onclick="$(window).trigger(\'' +
					"handleTaskGridPrintReceiptFilter'" +
					' ,' +
					value +
					')"></button>'
				);
			};
		};
	})
	.filter('taskGridPrintLabelFilter', function ($translate) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				return (
					'<button class="print-label-button" onclick="$(window).trigger(\'' +
					"handleTaskGridPrintLabelFilter'" +
					' , [' +
					value +
					' ,' +
					"'zpl']" +
					')"><i class="bringg-icon bringg-icon-file-alt"></i>&nbsp;ZPL</button>' +
					'&nbsp;' +
					'<button class="print-label-button" onclick="$(window).trigger(\'' +
					"handleTaskGridPrintLabelFilter'" +
					' , [' +
					value +
					' ,' +
					"'pdf']" +
					')"><i class="bringg-icon bringg-icon-pdf-file"></i>&nbsp;PDF</button>'
				);
			};
		};
	})
	.filter('taskGridAddressFilter', function ($filter) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var result = '';
				if (dataContext.activeWayPoint) {
					if (!dataContext.activeWayPoint.lat) {
						result +=
							'<span class="glyphicon glyphicon-map-marker" style="color:#f0ad4e" title="Address is not Geo-Located!"></span>';
					}

					if (dataContext.activeWayPoint.find_me) {
						result += '<span>' + $filter('translate')('DISPATCH.FIND_ME_DEFAULT_ADDRESS') + '</span>';
					} else if (dataContext.activeWayPoint.showAddress === true || !dataContext.activeWayPoint.title) {
						result += '<span>' + _.escape(dataContext.activeWayPoint.address) + '</span>';
					} else {
						result += '<span>' + _.escape(dataContext.activeWayPoint.title) + '</span>';
					}
				}
				return result;
			};
		};
	})
	.filter('taskGridTeamFilter', function (Tasks) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var teamName = Tasks.getTeamName(dataContext);

				if (_.isEmpty(teamName)) {
					return '';
				}
				return '<span>' + _.escape(teamName) + '</span>';
			};
		};
	})
	.filter(
		'taskGridDriverFilter',
		function (TASK_STATUS, Employees, $translate, AssignDriverValidations, FleetChecker, LockedTaskService) {
			return function (propName) {
				return function (row, cell, value, columnDef, dataContext) {
					var employee = value ? Employees.employeeByIdMap[value] : null;
					var unassigned = $translate.instant('GLOBAL.UNASSIGNED');

					var onclickHtml = !AssignDriverValidations.isAllowedToAssignSpecificTask(dataContext)
						? 'disabled'
						: `onclick="$(window).trigger(\'handleTaskGridDriverFilter',${dataContext.id});return false;"`;
					var caretHtml = '<span ng-show="!readonly" class="caret"></span></a>';
					var extraClasses = '';

					if (LockedTaskService.isLockedByOther(dataContext)) {
						if (employee) {
							return employee.name;
						} else {
							return unassigned;
						}
					} else {
						var result =
							'<input id="list_task_' + dataContext.id + '_employee_list" style="display:none"/><div>';
						if (employee) {
							var assignEmployee = $translate.instant('MAP_USER_PROFILE.DRIVR_ASSIGN');
							result +=
								'<a id="list_task_' +
								dataContext.id +
								'_employee" title="' +
								assignEmployee +
								'"' +
								onclickHtml +
								' class="' +
								extraClasses +
								'">' +
								_.escape(employee.name) +
								caretHtml;

							if (dataContext.status === TASK_STATUS.ASSIGNED) {
								result += '<img src="/images/check_assigned.png" title="Task Assigned"/>';
							}

							if (
								[
									TASK_STATUS.ACCEPTED,
									TASK_STATUS.ON_THE_WAY,
									TASK_STATUS.CHECKED_IN,
									TASK_STATUS.DONE
								].indexOf(dataContext.status) !== -1
							) {
								result += '<img src="/images/check2_accepted.png" title="Task Accepted"/>';
							}
						} else {
							var title = unassigned;
							// fleet assign and no driver, searching driver
							if (
								FleetChecker.isExternalFleet(dataContext.fleet_id) &&
								dataContext.fleet_delivery_external_id
							) {
								title = 'Searching For Driver...';
							}

							result +=
								'<a id="list_task_' +
								dataContext.id +
								'_employee"' +
								onclickHtml +
								' class="' +
								extraClasses +
								'">' +
								_.escape(title) +
								caretHtml;
						}
						result += '</a></div>';
						return result;
					}
				};
			};
		}
	)
	.filter(
		'taskGridFleetFilter',
		function (Fleets, MerchantConfigurations, $filter, Authentication, PRIVILEGES_TYPES, FleetChecker) {
			return function (propName) {
				return function (row, cell, value, columnDef, dataContext) {
					const taskAlreadyStartedStatus = 2;
					const taskAlreadyStartedDropOffStatus = 3;
					const reassignFleetPrivileges = Authentication.currentUser().has_access(
						PRIVILEGES_TYPES.REASSIGN_FLEET
					);
					const pickUpWayPoint = dataContext.way_points[0];
					const isTaskPickedUp = pickUpWayPoint && pickUpWayPoint.position === 1 && pickUpWayPoint.done;
					const taskInProgress =
						dataContext.status === taskAlreadyStartedStatus ||
						dataContext.status === taskAlreadyStartedDropOffStatus;
					const isPickUpAlreadyHappened =
						taskInProgress && FleetChecker.isExternalFleet(dataContext.fleet_id) && isTaskPickedUp;

					function isTaskAssignToDriverAndNotToFleet() {
						return dataContext.user_id && !dataContext.fleet_id;
					}

					function createOnClickCompileText() {
						return (
							'' +
							"var taskId = '" +
							dataContext.id +
							"';\n" +
							"var $scope = angular.element('#list_container').isolateScope();\n" +
							'$scope.compileAssignFleetField(taskId);'
						);
					}

					function createHtmlField() {
						const cellText = _.isNumber(dataContext.fleet_id)
							? Fleets.getName(dataContext.fleet_id)
							: $filter('translate')('ASSIGN_FLEET.UNASSIGNED');
						if (!dataContext.fleet_id || reassignFleetPrivileges) {
							return (
								'<assign-fleet-field task="task" get-grid-selected-items="getGridSelectedItems"><a><span>' +
								cellText +
								'</span><span class="caret"></span></a></assign-fleet-field>'
							);
						}

						return Fleets.getName(dataContext.fleet_id);
					}

					function createTemporalElement() {
						var div = document.createElement('div');
						div.setAttribute('id', 'list_task_' + dataContext.id + '_fleet');
						div.setAttribute('onclick', createOnClickCompileText());
						div.innerHTML = createHtmlField();
						return div;
					}

					if (isPickUpAlreadyHappened) {
						return Fleets.getName(dataContext.fleet_id);
					} else if (!FleetChecker.hasExternalFleets()) {
						return $filter('translate')('ASSIGN_FLEET.INTERNAL_FLEET_TEXT');
					} else if (isTaskAssignToDriverAndNotToFleet()) {
						return $filter('translate')('ASSIGN_FLEET.UNASSIGNED');
					}

					return createTemporalElement().outerHTML;
				};
			};
		}
	)
	.filter('taskAutoDispatchFilter', function (AutoDispatchAnalytic, $translate) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var div = document.createElement('div');
				if (dataContext) {
					var taskId = dataContext.id;
					var teamId = dataContext.team_id;
					if (taskId && teamId) {
						var status = AutoDispatchAnalytic.getTaskStatus(teamId, dataContext);
						div.innerHTML = createTaskStatusHtml(status);
					}
				}

				function createTaskStatusHtml(status) {
					var iconClass = '';
					if (status) {
						switch (status) {
							case AutoDispatchAnalytic.UI_STATUSES_TRANSLATIONS.FAILED:
								iconClass = 'bringg-icon bringg-icon-warning auto-dispatch-state-icon failed';
								break;
							case AutoDispatchAnalytic.UI_STATUSES_TRANSLATIONS.IN_PROCESS:
								iconClass = 'bringg-icon bringg-icon-hourglass auto-dispatch-state-icon in-process';
								break;
							case AutoDispatchAnalytic.UI_STATUSES_TRANSLATIONS.DISPATCHED:
								iconClass = 'bringg-icon bringg-icon-selected auto-dispatch-state-icon dispatched';
								break;
						}
						return '<span><i class="' + iconClass + '"></i>' + $translate.instant(status) + '</span>';
					}
					return (
						'<span class="auto-dispatch-state not-applied">' +
						$translate.instant(AutoDispatchAnalytic.UI_STATUSES_TRANSLATIONS.NOT_APPLIED) +
						'</span>'
					);
				}
				return div.outerHTML;
			};
		};
	})
	.filter('taskGridProgressFilter', function (Task) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var no_waypoint_html = '<span>---</span>';
				var getActiveWaypointIndex = function (task) {
					for (var wayPointIdx = 0; wayPointIdx < task.way_points.length; wayPointIdx++) {
						if (task.way_points[wayPointIdx].id === task.active_way_point_id) {
							return wayPointIdx;
						}
					}
					return -1;
				};

				var isTaskActive = Task.isActive(dataContext);

				if (!dataContext.way_points || _.isUndefined(dataContext.way_points)) {
					return no_waypoint_html;
				} else {
					if (dataContext.way_points.length === 0) {
						return no_waypoint_html;
					} else {
						var totalWayPoints = dataContext.way_points.length,
							waypointPosition = getActiveWaypointIndex(dataContext);
						var divver = '<table class="stops"><tr><td class="edge_left"></td>',
							previous;

						for (var wayPointIndex = 0; wayPointIndex < totalWayPoints; wayPointIndex++) {
							var color;
							if (dataContext.way_points[wayPointIndex].late) {
								color = 'red';
							} else if (wayPointIndex === waypointPosition && isTaskActive) {
								color = 'blue';
							} else if (
								wayPointIndex === waypointPosition &&
								!dataContext.way_points[wayPointIndex].done
							) {
								color = 'white';
							} else if (wayPointIndex <= waypointPosition) {
								color = 'blue';
							} else {
								color = 'white';
							}

							if (previous) {
								divver += '<td class="' + previous + '_' + color + '"></td>';
							}
							divver += '<td class="' + color + '"></td>';
							previous = color;
						}

						divver += '<td class="edge_right"></td><tr></table>';
						return divver;
					}
				}
			};
		};
	})
	.filter('taskGridTimeWindowFilter', function (TimezoneService, ROGUE_REASONS, TimeManagerService) {
		return function (propName) {
			return function (row, cell, value, columnDef, dataContext) {
				var wp = _.last(dataContext.way_points);
				if (!wp || !wp.no_earlier_than || !wp.no_later_than) {
					return '<span>' + '-- : --' + '</span>';
				}

				var noEarlier = TimezoneService.getDateByTask(dataContext, wp.no_earlier_than);
				var noLater = TimezoneService.getDateByTask(dataContext, wp.no_later_than);
				var timeZone = TimezoneService.getTaskTimezone(dataContext, { formatTimezone: true }) || '';

				var span = '<span>';
				var rogueReasons = _.map(dataContext.rogue_reasons, 'reason');
				if (_.includes(rogueReasons, ROGUE_REASONS.TIME_WINDOW)) {
					span = '<span style="color: red">';
				}
				return (
					span +
					TimeManagerService.format(noEarlier, 'L LT') +
					' - ' +
					TimeManagerService.format(noLater, 'L LT') +
					timeZone +
					'</span>'
				);
			};
		};
	})
	.filter('orderTagFilter', function ($translate, TASK_DEFAULT_TYPES) {
		return function () {
			return function (row, cell, value, columnDef, dataContext) {
				if (dataContext.task_type_id !== TASK_DEFAULT_TYPES.CLICK_AND_COLLECT) {
					return '';
				}

				var orderUniqueDetails = _.get(dataContext, 'way_points[0].ui_data') || {};
				var tagColor = orderUniqueDetails.color;
				var tagNumber = orderUniqueDetails.number;
				var orderTag = '';

				if (!_.isNil(tagColor) && !_.isNil(tagNumber)) {
					orderTag =
						'<div class="customer-pickup-tag" style="background-color: ' +
						tagColor +
						'">' +
						tagNumber +
						'</div>';
				}

				var collect = $translate.instant('DISPATCH_LIST.COLLECT');
				return (
					'<div class="customer-pickup-order-tag-container">' +
					'<div class="customer-pickup-tag customer-pickup-tag-collect">' +
					collect +
					'</div>' +
					orderTag +
					'</div>'
				);
			};
		};
	})
	.filter('customerNameFilter', function () {
		return function () {
			return function (row, cell, value, columnDef, dataContext) {
				function createOnMouseOverScript(elementId, customerId) {
					var taskId = dataContext.id;

					return (
						"var customerTooltipService = angular.element('#list_container').injector().get('CustomerTooltipService');" +
						"customerTooltipService.open('" +
						elementId +
						"', '" +
						taskId +
						"', '" +
						customerId +
						"')"
					);
				}

				function createOnMouseOutScript() {
					return (
						"var customerTooltipService = angular.element('#list_container').injector().get('CustomerTooltipService');" +
						'customerTooltipService.removeAll()'
					);
				}

				function getCustomer(dataContext) {
					if (_.isEmpty(dataContext.way_points)) {
						return null;
					}

					if (columnDef.isFirstWayPoint) {
						return _.first(dataContext.way_points).customer;
					} else {
						return _.last(dataContext.way_points).customer;
					}
				}

				function createElement() {
					var span = document.createElement('span');

					var customer = getCustomer(dataContext);

					if (_.isNil(customer)) {
						return span;
					}

					span.innerHTML = customer.name;

					var spanId = 'list_task_' + row + '_' + cell;
					span.setAttribute('id', spanId);

					span.setAttribute('onmouseover', createOnMouseOverScript(spanId, customer.id));
					span.setAttribute('onmouseleave', createOnMouseOutScript());

					return span;
				}

				return createElement().outerHTML;
			};
		};
	})
	.filter('requiredSkillsFilter', function () {
		return function () {
			return function (row, cell, value, columnDef, dataContext) {
				if (!value) {
					return '';
				}

				var getTooltipService =
					"var customSlickgridTooltip = angular.element('#list_container').injector().get('CustomSlickgridTooltip');";

				function createOnMouseOverScript(elementId, skills) {
					return getTooltipService + "customSlickgridTooltip.open('" + elementId + "', '" + skills + "')";
				}

				function createOnMouseOutScript(elementId) {
					return getTooltipService + "customSlickgridTooltip.remove('" + elementId + "')";
				}

				var skills = _.uniq(value).join(', ');

				function createElement(skills) {
					var span = document.createElement('span');

					span.innerHTML = skills;

					var spanId = 'list_task_' + row + '_' + cell;
					span.className = 'slickgrid-inner-cell';
					span.setAttribute('id', spanId);

					span.setAttribute('onmouseover', createOnMouseOverScript(spanId, skills));
					span.setAttribute('onmouseleave', createOnMouseOutScript(spanId));

					return span;
				}

				return createElement(skills).outerHTML;
			};
		};
	});
