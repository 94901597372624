/**
 * Created by liorsion on 1/24/17.
 */
'use strict';

angular.module('bringgApp').factory('AddPrivilegeService', function ($q, $uibModal) {
	var AddPrivilegeService = {};
	AddPrivilegeService.open = function () {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'AddPrivilegeController',
			templateUrl: 'scripts/features/privileges/add_privilege/add-privilege.html'
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return AddPrivilegeService;
});
