'use strict';

angular
	.module('bringgApp')
	.factory(
		'Task',
		function (
			BringgSDK,
			$resource,
			WEB_API_URL,
			REAL_TIME_URL,
			TASK_STATUS,
			Authentication,
			$q,
			SocketPubSub,
			$injector,
			$rootScope
		) {
			var Task = $resource(
				WEB_API_URL + '/tasks/:id',
				{ id: '@id' },
				{
					updateOld: { method: 'PATCH' },
					update_priorities: { method: 'PATCH', url: WEB_API_URL + '/tasks/update_priorities' },
					cancelAll: { method: 'POST', url: WEB_API_URL + '/tasks/cancel_all' },
					openTasksKeySetOld: { method: 'GET', url: WEB_API_URL + '/tasks/open' },
					planningTasksKeySetOld: { method: 'GET', url: WEB_API_URL + '/tasks/planning' },
					closedTasksWithQuery: { method: 'GET', url: WEB_API_URL + '/tasks/closed_with_query' },
					history: { method: 'GET', isArray: true, url: WEB_API_URL + '/tasks/:id/history' },
					massAssignOld: { method: 'POST', url: WEB_API_URL + '/tasks/mass_assign' },
					massUnassignOld: { method: 'POST', url: WEB_API_URL + '/tasks/mass_unassign' },
					massCancelOld: { method: 'POST', url: WEB_API_URL + '/tasks/mass_cancel' },
					massFinish: { method: 'POST', url: WEB_API_URL + '/tasks/mass_finish' },
					lock: { method: 'POST', url: WEB_API_URL + '/tasks/lock' },
					unlock: { method: 'POST', url: WEB_API_URL + '/tasks/unlock' },
					moveToPlanningOld: { method: 'POST', url: WEB_API_URL + '/tasks/mark_not_ready_to_execute' },
					getDriversWithAvailableInventories: {
						method: 'GET',
						url: WEB_API_URL + '/floating_inventories/users_with_available_floating_inventory'
					},
					releaseToDriversOld: { method: 'POST', url: WEB_API_URL + '/tasks/release' },
					updatePreparationStatusOld: {
						method: 'POST',
						url: WEB_API_URL + '/tasks/update_preparation_status'
					},
					acknowledgeTaskOld: { method: 'POST', url: WEB_API_URL + '/tasks/acknowledge_task' },
					acknowledgeTaskView: { method: 'POST', url: WEB_API_URL + '/tasks/acknowledge_task_view' },
					mass_get: { method: 'GET', url: WEB_API_URL + '/tasks/mass_get' }, // expect way point ids
					notGeocoded: { method: 'POST', url: WEB_API_URL + '/tasks/not_geocoded' }, // expect task ids,
					broadcastToDriversOld: { method: 'POST', url: WEB_API_URL + '/tasks/broadcast_to_drivers' },
					massDeleteGroupsOld: { method: 'POST', url: WEB_API_URL + '/tasks/mass_delete_groups' }
				}
			);

			const _endpointMapping = {
				batchGet: 'loadMany',
				planningTasksKeySet: 'loadPlanning',
				openTasksKeySet: 'loadOpen',
				getInvalidTasks: 'loadInvalid',
				get: 'load'
			};

			function tasksService(method) {
				return function () {
					var args = arguments;
					return BringgSDK.getInstancePromise().then(function (bringg) {
						if (Task.shouldUseNewApi()) {
							const name = _endpointMapping[method] || method;
							return bringg.v2.tasks[name].apply(bringg.v2.tasks, args);
						} else {
							return bringg.tasks[method].apply(bringg.tasks, args);
						}
					});
				};
			}

			Task.planningTasksKeySet = function (params) {
				if (Task.shouldUseNewApi()) {
					const options = {
						useCache: false,
						chunkSize: params.limit,
						cursor: params.cursor,
						teamIds: params.team_ids
					};
					return tasksService('planningTasksKeySet')(params.columns, options);
				} else {
					return Task.planningTasksKeySetOld(params).$promise;
				}
			};

			Task.openTasksKeySet = function (params) {
				if (Task.shouldUseNewApi()) {
					const options = {
						useCache: false,
						chunkSize: params.limit,
						cursor: params.cursor,
						teamIds: params.team_ids
					};
					return tasksService('openTasksKeySet')(params.columns, options);
				} else {
					return Task.openTasksKeySetOld(params).$promise;
				}
			};

			const _originalGet = Task.get;

			Task.get = function (id, options) {
				if (Task.shouldUseNewApi()) {
					return tasksService('get')(id, { useCache: !options.forceGet });
				} else {
					return _originalGet({ id }).$promise;
				}
			};

			Task.broadcastToDrivers = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('broadcastToDrivers')(params.task_id, params.userIds, params.message);
				} else {
					return Task.broadcastToDriversOld(params).$promise;
				}
			};

			Task.acknowledgeTask = function (taskId) {
				if (Task.shouldUseNewApi()) {
					return tasksService('acknowledgeTask')(taskId).then(task => ({ success: true, task }));
				} else {
					return Task.acknowledgeTaskOld({ task_id: taskId }).$promise.then(result => {
						if (!result.success) {
							return $q.reject(result);
						}

						return result;
					});
				}
			};

			Task.releaseToDrivers = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('releaseToDrivers')(params.ids);
				} else {
					return Task.releaseToDriversOld(params).$promise;
				}
			};

			Task.moveToPlanning = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('moveToPlanning')(params.task_ids);
				} else {
					return Task.moveToPlanningOld(params).$promise;
				}
			};

			Task.massCancel = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('massCancel')(params.task_ids, params.reason, params.text);
				} else {
					return Task.massCancelOld(params).$promise;
				}
			};

			Task.update = function (task) {
				if (Task.shouldUseNewApi()) {
					return tasksService('update')(task);
				} else {
					return Task.updateOld(task).$promise.then(result => {
						if (!result.success) {
							return $q.reject(result);
						}

						return result;
					});
				}
			};

			Task.massAssign = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('massAssign')(params.user_id, params.task_ids, params.options);
				} else {
					return Task.massAssignOld(params).$promise;
				}
			};

			Task.massUnassign = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('massUnassign')(params.task_ids, params.options);
				} else {
					return Task.massUnassignOld(params).$promise;
				}
			};

			Task.updatePreparationStatus = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('updatePreparationStatus')(params.task_id, params.preparation_status).then(
						task => ({ success: true, task })
					);
				} else {
					return Task.updatePreparationStatusOld(params).$promise;
				}
			};

			Task.massDeleteGroups = function (params) {
				if (Task.shouldUseNewApi()) {
					return tasksService('massDeleteGroups')(params.group_leader_task_ids);
				} else {
					return Task.massDeleteGroupsOld(params).$promise.then(result => {
						if (!result.success) {
							return $q.reject(result);
						}

						return result;
					});
				}
			};

			Task.cancel = tasksService('cancel');
			Task.getInvalidTasks = tasksService('getInvalidTasks');
			Task.updateTask = tasksService('update');
			Task.batchGet = tasksService('batchGet');
			Task.rescheduleVirtualTasks = tasksService('rescheduleVirtualTasks');

			Task.getAllById = function () {
				return $rootScope.sdk.v2.tasks.getAllById();
			};

			Task.getGroup = function (groupType, groupValue) {
				return $rootScope.sdk.v2.tasks.getGroup(groupType, groupValue);
			};

			Task.isInGroup = function (taskId, groupType, groupValue) {
				return $rootScope.sdk.v2.tasks.isInGroup(taskId, groupType, groupValue);
			};

			Task.getFromStore = function (id) {
				return $rootScope.sdk.v2.tasks.get(id);
			};

			Task.onCreate = function (callback) {
				if (Task.shouldUseNewApi()) {
					return tasksService('onCreate')(callback);
				} else {
					SocketPubSub.on('new task', task => {
						callback({ current: task });
					});
				}
			};

			Task.onUpdate = function (callback) {
				if (Task.shouldUseNewApi()) {
					return tasksService('onUpdate')(callback);
				} else {
					SocketPubSub.on('task update', task => {
						const previousTask = $injector.get('TasksData').get(task.id);

						callback({
							current: task,
							diff: previousTask && {
								id: previousTask.id,
								status: previousTask.status,
								invalidated: previousTask.invalidated,
								ready_to_execute: previousTask.ready_to_execute
							}
						});
					});
				}
			};

			Task.isDone = function (task) {
				if (!task) {
					return false;
				}
				return [TASK_STATUS.DONE, TASK_STATUS.CANCELLED].indexOf(task.status) !== -1;
			};

			Task.isCancelled = function (task) {
				if (!task) {
					return false;
				}
				return TASK_STATUS.CANCELLED === task.status;
			};

			Task.isLate = function (task) {
				if (!task) {
					return false;
				}
				return task.late;
			};

			Task.isStarted = function (task) {
				if (!task) {
					return false;
				}
				return Task.isActive(task) || Task.isDone(task);
			};

			//user for finding out if the task is actually active
			Task.isActive = function (task) {
				if (!task) {
					return false;
				}
				return [TASK_STATUS.ON_THE_WAY, TASK_STATUS.CHECKED_IN].indexOf(task.status) !== -1;
			};

			Task.isUnacknowledged = function (task) {
				if (!task) {
					return true;
				}
				return [TASK_STATUS.UNACKNOWLEDGED].indexOf(task.status) !== -1;
			};

			Task.isPlanning = function (task) {
				if (!task) {
					return true;
				}
				return task.ready_to_execute === false;
			};

			Task.isValid = function (task) {
				if (!task) {
					return false;
				}
				return task.invalidated === false;
			};

			Task.isInvalid = function (task) {
				if (!task) {
					return false;
				}
				return task.invalidated === true;
			};

			Task.shouldUseNewApi = function () {
				return Authentication.currentUser() && Authentication.currentUser().feature_flags.tasks_v2_api;
			};

			return Task;
		}
	);
