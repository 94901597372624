'use strict';

angular.module('bringgApp').service('TaskCancelReasonActionsProvider', function ($translate) {
	var _this = this;
	_this.getByReason = getByReason;
	_this.filterExcluded = filterExcluded;
	_this.appendExcluded = appendExcluded;

	var defaultActionData;
	var actionsToDisplay;
	var NEW_REASON_ID = undefined;

	function ctor() {
		defaultActionData = [
			{
				label: $translate.instant('TASK_CANCEL_CONFIGURATION.TAKE_PICTURE'),
				value: {
					value: 'take_picture',
					title: 'Take Picture',
					id: 2,
					rule: 2
				}
			},
			{
				label: $translate.instant('TASK_CANCEL_CONFIGURATION.TAKE_NOTE'),
				value: {
					value: 'take_note',
					title: 'Comment',
					id: 1,
					rule: 2
				}
			},
			{
				label: $translate.instant('TASK_CANCEL_CONFIGURATION.TAKE_SIGNATURE'),
				value: {
					value: 'take_signature',
					title: 'Take Signature',
					id: 3,
					rule: 2
				}
			}
		];

		actionsToDisplay = {};

		defaultActionData.forEach(function (defaultActionData) {
			actionsToDisplay[defaultActionData.value.value] = true;
		});
	}

	function getByReason(taskCancelReasons) {
		var byReason = {};

		taskCancelReasons.forEach(function (taskCancelReason) {
			var excluded = [];
			var included = {};

			taskCancelReason.action_data &&
				taskCancelReason.action_data.forEach(function (actionData) {
					if (actionsToDisplay[actionData.value]) {
						addReason(included, actionData, actionData.title);
					} else {
						excluded.push(actionData);
					}
				});

			defaultActionData.forEach(function (defaultActionData) {
				addReason(included, defaultActionData.value, defaultActionData.label);
			});

			byReason[taskCancelReason.id] = {
				excluded: excluded,
				included: Object.values(included)
			};
		});

		byReason[NEW_REASON_ID] = {
			excluded: [],
			included: defaultActionData.map(function (data) {
				return {
					label: data.label,
					value: data.value
				};
			})
		};

		return byReason;
	}

	function filterExcluded(cancelReasons, actionDataByReason) {
		cancelReasons.forEach(function (cancelReason) {
			cancelReason.action_data =
				cancelReason.action_data &&
				cancelReason.action_data.filter(function (actionData) {
					var excludedValues = actionDataByReason[cancelReason.id].excluded.map(function (action) {
						return action.value;
					});

					return excludedValues.indexOf(actionData.value) === -1;
				});
		});
	}

	function appendExcluded(cancelReasons, actionDataByReason) {
		var cancelReasonsToSave = JSON.parse(JSON.stringify(cancelReasons));

		cancelReasonsToSave.forEach(function (cancelReason) {
			var action = actionDataByReason[cancelReason.id];

			if (action && cancelReason.action_data) {
				cancelReason.action_data = cancelReason.action_data.concat(action.excluded);
			}
		});

		return cancelReasonsToSave;
	}

	function addReason(allActionData, actionData, title) {
		if (allActionData[actionData.value]) {
			return;
		}

		allActionData[actionData.value] = {
			label: title,
			value: actionData
		};
	}

	ctor();
});
