'use strict';

angular
	.module('bringgApp')
	.factory(
		'PresetViewsService',
		function (Authentication, GridDefaultColumnsService, BringgSDK, CrossApplicationService, CROSS_APP_ACTIONS) {
			const DISPATCH_COLUMNS_KEY = 'task_fields';
			const PANNING_COLUMNS_KEY = 'planning_fields';

			const DISPATCH_FILTERS_KEY = 'dispatch-task-list';
			const DISPATCH_GROUPING_KEY = 'taskGridGroupMode';
			const MAP_TABLE_KEY = 'map-task-list';
			const PANNING_GROUPING_AND_SORT_KEY = 'planning';
			const DISPATCH_KEYS = [DISPATCH_COLUMNS_KEY, DISPATCH_FILTERS_KEY, DISPATCH_GROUPING_KEY, MAP_TABLE_KEY];

			var PresetViewsService = {
				presetViewsEnable: false,
				taskTableRelatedKeys: [...DISPATCH_KEYS, PANNING_COLUMNS_KEY, PANNING_GROUPING_AND_SORT_KEY]
			};
			var BringgSDKInstance;

			let defferedResolve;
			const allLoadedPromise = new Promise(function (resolve) {
				defferedResolve = resolve;
			});

			const setAllLoaded = function () {
				defferedResolve();
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_INITIATED, setAllLoaded);
			};
			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_INITIATED, setAllLoaded);

			Authentication.featureFlags().then(function (featureFlags) {
				PresetViewsService.presetViewsEnable = Boolean(featureFlags.enable_preset_views);
			});

			// preset views in ng-app only used in Dispatch and Planning page
			PresetViewsService.enabledForConfigKey = function (gridKey) {
				return (
					PresetViewsService.getPresetViewsEnabled() &&
					PresetViewsService.taskTableRelatedKeys.includes(gridKey)
				);
			};

			PresetViewsService.getPresetViewsEnabled = function () {
				return PresetViewsService.presetViewsEnable;
			};

			PresetViewsService.loadBringgSDKInstance = async function () {
				if (BringgSDKInstance) {
					return Promise.resolve();
				}

				return BringgSDK.getInstancePromise()
					.then(function (sdk) {
						BringgSDKInstance = sdk;
						return Promise.resolve();
					})
					.catch(function (error) {
						console.log(error);
					});
			};

			PresetViewsService.getPresetsReadyPromise = function () {
				return Promise.all([PresetViewsService.loadBringgSDKInstance(), allLoadedPromise]);
			};

			PresetViewsService.getPreset = function (gridKey) {
				if (!window.getActivePresetViewForTasks) {
					return;
				}

				return window.getActivePresetViewForTasks({
					type: DISPATCH_KEYS.includes(gridKey) ? 'dispatch' : 'planning'
				});
			};

			PresetViewsService.combineWithDefaultColumns = function (gridName, presetColumns) {
				// to keep the preset columns up to update with existing on ui
				var defaultTableColumns = GridDefaultColumnsService.getDefaultsColumns(gridName);

				return [
					...defaultTableColumns
						.concat(presetColumns)
						.reduce((map, col) => {
							const coldId = col?.id?.trim(); // some columns may don't have an id or be with id " "
							const colKey = coldId || col?.title || col?.title_translate_key;

							map.set(colKey, Object.assign(map.get(colKey) || {}, col));

							return map;
						}, new Map())
						.values()
				].sort((a, b) => a.position - b.position);
			};

			PresetViewsService.resetPresetViewColumns = function (gridName) {
				var initialPresetColumns = PresetViewsService.getPreset(gridName)?.initialPresetColumns;
				var defaultTableColumns = GridDefaultColumnsService.getDefaultsColumns(gridName);

				return initialPresetColumns?.length
					? PresetViewsService.combineWithDefaultColumns(gridName, initialPresetColumns)
					: defaultTableColumns;
			};

			PresetViewsService.getPresetColumns = function (gridName) {
				var presetColumns = PresetViewsService.getPreset(gridName)?.columns;
				var defaultColumns = GridDefaultColumnsService.getDefaultsColumns(gridName);

				return presetColumns?.length
					? PresetViewsService.combineWithDefaultColumns(gridName, presetColumns)
					: defaultColumns;
			};

			PresetViewsService.setPresetColumns = function (gridName, value, onSave) {
				const presetView = PresetViewsService.getPreset(gridName);
				if (presetView) {
					presetView.updateColumns(value);
					onSave?.();
				}
			};

			PresetViewsService.getPresetGrouping = function (gridName) {
				return PresetViewsService.getPreset(gridName)?.groupMode;
			};

			PresetViewsService.setPresetGrouping = function (gridName, value) {
				const presetView = PresetViewsService.getPreset(gridName);
				if (presetView && presetView.groupMode !== value) {
					presetView.updateGroupMode(value);
				}
			};

			PresetViewsService.getPresetSortOptions = function (gridName) {
				return PresetViewsService.getPreset(gridName)?.sortOptions;
			};

			PresetViewsService.setPresetSortOptions = function (gridName, value) {
				const presetView = PresetViewsService.getPreset(gridName);
				if (presetView && presetView.sortOptions !== value) {
					presetView.updateSortOptions(value);
				}
			};

			// TODO: method not in used because of the code issue with useStoreColumnWidth in slick-grid-wrapper-controller.js
			PresetViewsService.saveColumnsWidth = function (gridName, updatedColumns) {
				const presetColumns = PresetViewsService.getPresetColumns(gridName);

				_.each(presetColumns, function (column) {
					// for each column, find the matching column width by id.
					var columnStoredWidth = _.find(updatedColumns, { id: column.id }) || {};
					// set the width
					_.extend(column, columnStoredWidth);
				});

				PresetViewsService.setPresetColumns(gridName, presetColumns);
			};

			return PresetViewsService;
		}
	);
