'use strict';

angular
	.module('bringgApp')
	.factory('DispatcherAcknowledgeTaskStatusService', function (
		$route,
		$routeParams,
		$rootScope,
		$uibModal,
		MerchantConfigurations,
		Tasks,
		SoundPlayerService,
		Authentication,
		$interval,
		$log
	) {
		var DispatcherAcknowledgeTaskStatusService = {};

		DispatcherAcknowledgeTaskStatusService.init = function () {
			DispatcherAcknowledgeTaskStatusService.tasksById = {};
			DispatcherAcknowledgeTaskStatusService.timersByTaskId = {};
			DispatcherAcknowledgeTaskStatusService.items = [];

			DispatcherAcknowledgeTaskStatusService.disablePopup = false;
			DispatcherAcknowledgeTaskStatusService.showing = false;
		};

		DispatcherAcknowledgeTaskStatusService.addTask = function (
			task,
			flowUiConfig,
			onAcknowledgeClicked,
			onRejectedClicked,
			firstInQueue,
			idInQueue
		) {
			var taskId = task.id;
			if (_.isNumber(idInQueue)) {
				task = _.extend({}, task, { id: idInQueue });
			}
			if (_.isObject(DispatcherAcknowledgeTaskStatusService.tasksById[task.id])) {
				return;
			}

			$log.info('DispatcherAcknowledgeTaskStatusService.addTask, adding task to queue', task);
			var taskToShow = {
				ack_type: flowUiConfig.ack_type,
				task: task,
				taskId: taskId,
				onAcknowledgeClicked: onAcknowledgeClicked,
				onRejectedClicked: onRejectedClicked,
				flowUiConfig: flowUiConfig
			};

			if (firstInQueue) {
				DispatcherAcknowledgeTaskStatusService.items.unshift(taskToShow);
			} else {
				DispatcherAcknowledgeTaskStatusService.items.push(taskToShow);
			}

			DispatcherAcknowledgeTaskStatusService.tasksById[task.id] = task;

			DispatcherAcknowledgeTaskStatusService.showNext();
		};

		DispatcherAcknowledgeTaskStatusService.registerTimerElement = function (timerElement, item) {
			DispatcherAcknowledgeTaskStatusService.timersByTaskId[item.task.id] = timerElement;
		};

		DispatcherAcknowledgeTaskStatusService.removeTask = function (task) {
			if (DispatcherAcknowledgeTaskStatusService.timersByTaskId[task.id]) {
				DispatcherAcknowledgeTaskStatusService.timersByTaskId[task.id].cancel();
				delete DispatcherAcknowledgeTaskStatusService.timersByTaskId[task.id];
			}
			var wasDeleted = !!DispatcherAcknowledgeTaskStatusService.tasksById[task.id];
			delete DispatcherAcknowledgeTaskStatusService.tasksById[task.id];
			DispatcherAcknowledgeTaskStatusService.items = _.reject(
				DispatcherAcknowledgeTaskStatusService.items,
				function (item) {
					return item.task.id === task.id;
				}
			);

			return wasDeleted;
		};

		DispatcherAcknowledgeTaskStatusService.snoozeItem = function (itemToPromote) {
			_.remove(DispatcherAcknowledgeTaskStatusService.items, function (item) {
				return item.task.id === itemToPromote.task.id;
			});
		};

		DispatcherAcknowledgeTaskStatusService.endSnoozeItem = function (itemToPromote) {
			DispatcherAcknowledgeTaskStatusService.items.unshift(itemToPromote);
			DispatcherAcknowledgeTaskStatusService.showNext();
		};

		DispatcherAcknowledgeTaskStatusService.showNext = function () {
			if (
				DispatcherAcknowledgeTaskStatusService.disablePopup ||
				DispatcherAcknowledgeTaskStatusService.showing ||
				_.isEmpty(DispatcherAcknowledgeTaskStatusService.items)
			) {
				return;
			}

			var itemToShow = MerchantConfigurations.enable_kds
				? _.first(DispatcherAcknowledgeTaskStatusService.items)
				: _.last(DispatcherAcknowledgeTaskStatusService.items);

			DispatcherAcknowledgeTaskStatusService.showing = true;
			DispatcherAcknowledgeTaskStatusService.open(itemToShow);

			if (MerchantConfigurations.sound_on_new_order) {
				SoundPlayerService.play(itemToShow);
			}
		};

		DispatcherAcknowledgeTaskStatusService.open = function (item) {
			var modalInstance = $uibModal.open({
				backdrop: 'static',
				keyboard: false,
				windowClass: item.flowUiConfig.modal.windowClass,
				controller: 'DispatcherAcknowledgeTaskStatusController',
				templateUrl: item.flowUiConfig.templateUrl,
				resolve: {
					flowUiConfig: item.flowUiConfig,
					item: function () {
						return item;
					},
					pendingTasks: function () {
						return DispatcherAcknowledgeTaskStatusService.items;
					}
				}
			});

			var onClose = function () {
				DispatcherAcknowledgeTaskStatusService.showing = false;
				DispatcherAcknowledgeTaskStatusService.showNext();
			};

			modalInstance.result.then(
				function () {
					onClose();
				},
				function () {
					onClose();
				}
			);

			return modalInstance;
		};

		$rootScope.$on('$routeChangeSuccess', function (event, next) {
			var enabledPopupWindow = _.get(next, '$$route.enableAckWindow');

			DispatcherAcknowledgeTaskStatusService.disablePopup = !enabledPopupWindow;

			if (enabledPopupWindow) {
				DispatcherAcknowledgeTaskStatusService.showNext();
			}
		});

		DispatcherAcknowledgeTaskStatusService.init();

		return DispatcherAcknowledgeTaskStatusService;
	});
