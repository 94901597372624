'use strict';
/*global WidgetsAverageTimeOnSiteCtrl:false */

angular.module('bringgApp').controller('EmployeeStatsCtrl', function ($scope) {
	$scope.widgets = [
		{
			url: 'views/widgets/employee-average-time-on-site.html',
			title: 'Average Time On Site',
			name: 'employee_average_time_on_site',
			class: 'col-xs-4',
			controller: WidgetsAverageTimeOnSiteCtrl
		}
	];
});
