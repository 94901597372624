'use strict';

angular.module('bringgApp.directives').directive('currencyDropDown', function ($log, CurrenciesService) {
	return {
		transclude: true,
		scope: {
			settings: '='
		},
		link: function (scope) {
			scope.currencies = CurrenciesService.currencies;

			scope.$on('currencies updated', function (event, currencies) {
				if (_.isArray(currencies)) {
					$log.info('currencies updated with: ', currencies);
					scope.currencies = currencies;
				}
			});
		},
		template:
			'<select name="currency_code" class="form-control" ng-model="settings">' +
			'<option value="" disabled="true">Select Currency</option>' +
			'<option ng-repeat="currency in currencies track by currency.code" value="{{currency.code}}">{{currency.title}}</option>' +
			'</select>'
	};
});
