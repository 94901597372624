'use strict';

angular.module('bringgApp').factory('AddEmployeeService', function ($q, $uibModal) {
	var AddEmployeeService = {};

	AddEmployeeService.addEmployee = function (merchant) {
		var deferred = $q.defer();
		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'AddEmployeeController',
			templateUrl: 'scripts/features/add_employee/add-employee.html',
			resolve: {
				merchant: function () {
					return merchant;
				}
			}
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	AddEmployeeService.addEmployeeCSV = function () {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			controller: 'AddUserCSVController',
			templateUrl: 'scripts/features/users/import_csv/add-users-csv.html',
			backdrop: 'static'
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return AddEmployeeService;
});
