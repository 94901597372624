'use strict';

angular
	.module('bringgApp.directives')
	.directive('hexValidation', function () {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attributes, ngModel) {
				scope.$watch(
					function () {
						return element.prop('disabled');
					},
					function () {
						ngModel.$validate();
					}
				);

				/**
				 * validate hex format is okay
				 * @param modelValue
				 * @returns {boolean}
				 */
				ngModel.$validators.color = function (modelValue) {
					if (element.prop('disabled')) {
						return true;
					}

					return /^#+([a-fA-F0-9]{6})$/.test(modelValue);
				};
			}
		};
	})

	.directive('hexBrightWarning', function () {
		/**
		 * helpr function to convert hex to rgb
		 * @param hex
		 * @returns {*}
		 */
		var hexToRgb = function (hex) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result
				? {
						r: parseInt(result[1], 16),
						g: parseInt(result[2], 16),
						b: parseInt(result[3], 16)
				  }
				: null;
		};

		/***
		 *
		 * @param r
		 * @param g
		 * @param b
		 * @returns {{h: *, s: number, v: number}}
		 * @constructor
		 */
		var RGBtoHSV = function (r, g, b) {
			if (arguments.length === 1) {
				(g = r.g), (b = r.b), (r = r.r);
			}
			var max = Math.max(r, g, b),
				min = Math.min(r, g, b),
				d = max - min,
				h,
				s = max === 0 ? 0 : d / max,
				v = max / 255;

			switch (max) {
				case min:
					h = 0;
					break;
				case r:
					h = g - b + d * (g < b ? 6 : 0);
					h /= 6 * d;
					break;
				case g:
					h = b - r + d * 2;
					h /= 6 * d;
					break;
				case b:
					h = r - g + d * 4;
					h /= 6 * d;
					break;
			}

			return {
				h: h,
				s: s,
				v: v
			};
		};

		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				hexBrightWarning: '=',
				ngModel: '='
			},
			link: function (scope, element, attributes, ngModel) {
				scope.$watch('ngModel', function (value) {
					if (!ngModel.$valid || element.prop('disabled')) {
						scope.hexBrightWarning = false;
						return;
					}

					var rgb = hexToRgb(value);
					var hsv = RGBtoHSV(rgb.r, rgb.g, rgb.b);
					var brightness = hsv.v;
					var saturation = hsv.s;

					var tooBright = brightness > 0.5 && saturation < 0.5;
					scope.hexBrightWarning = tooBright;
				});
			}
		};
	});
