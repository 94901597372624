'use strict';

angular.module('bringgApp').service('Customers', function (BringgSDK, $rootScope, $translate) {
	const _this = this;
	_this.all = all;
	_this.getAll = getAll;
	_this.addNewCustomer = addNewCustomer;
	_this.get = get;
	_this.getBatch = getBatch;
	_this.find = find;
	_this.tasks = tasks;
	_this.totalTasks = totalTasks;
	_this.update = update;
	_this.create = create;
	_this.getCustomerKinds = getCustomerKinds;

	let _customersSdk;

	BringgSDK.getInstancePromise().then(function (bringg) {
		_customersSdk = bringg.v2.customers;

		_customersSdk.onCreate(newCustomer => {
			$rootScope.$broadcast('newCustomerAdded', newCustomer);
		});
	});

	function customersApi(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.v2.customers[method].apply(bringg.v2.customers, args);
			});
		}.bind(this);
	}

	function getCustomerKinds() {
		return [
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.CUSTOMER'), value: 0 },
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.WAREHOUSE'), value: 1 },
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.STORE'), value: 2 },
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.LOCKER'), value: 3 },
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.DISTRIBUTION_HUB'), value: 4 },
			{ label: $translate.instant('CUSTOMER.KIND_OPTIONS.BUSINESS'), value: 5 }
		];
	}

	function all(params, successCallback) {
		return customersApi('loadAll')(params, { useCache: false }).then(
			customers => successCallback && successCallback(customers)
		);
	}

	function getAll() {
		return _customersSdk.getAll();
	}

	function addNewCustomer(newCustomer) {
		if (!_customersSdk) {
			customersApi('add')(newCustomer);
		} else {
			_customersSdk.add(newCustomer);
		}
	}

	function get(id, successCallback, errorCallback) {
		return customersApi('load')(id)
			.then(customer => {
				successCallback && successCallback(customer);
				return customer;
			})
			.catch(() => errorCallback && errorCallback());
	}

	function getBatch(ids, successCallback) {
		return customersApi('loadMany')(ids, { useCache: true, chunkSize: 300 }).then(
			customers => successCallback && successCallback(customers)
		);
	}

	function find(params) {
		return customersApi('find')(params).then(customers => ({ results: customers }));
	}

	function tasks(params, successCallback) {
		return customersApi('getTasks')(params.id, params.page).then(
			tasks => successCallback && successCallback(tasks)
		);
	}

	function totalTasks(params, successCallback) {
		return customersApi('getTotalTasks')(params.id).then(count => successCallback && successCallback({ count }));
	}

	function update(customer, successCallback) {
		return createOrUpdate(false, customer, successCallback);
	}

	function create(customer, successCallback) {
		return createOrUpdate(true, customer, successCallback);
	}

	function createOrUpdate(isCreate, customer, successCallback) {
		const funcName = isCreate ? 'create' : 'update';

		return customersApi(funcName)(customer)
			.then(customer => successCallback && successCallback({ customer, success: true }))
			.catch(error => successCallback && successCallback({ message: error.message, success: false }));
	}
});
