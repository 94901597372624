'use strict';
// todo: remove this service after multi-token release
angular.module('bringgApp').factory('SingleSignOn', function (WEB_API_URL) {
	var SingleSignOn = {};

	SingleSignOn.getLogoutUrl = function (token) {
		var merchantUUID = localStorage.getItem('sso.merchant-uuid');
		if (!merchantUUID) {
			return;
		}
		return WEB_API_URL + '/users/saml/idp_sign_out?merchant_uuid=' + merchantUUID + '&token=' + token;
	};

	SingleSignOn.clearSSOIdentifiers = function () {
		localStorage.removeItem('sso.merchant-uuid');
	};

	SingleSignOn.enableSSOFlow = function (merchantUuid) {
		localStorage.setItem('sso.merchant-uuid', merchantUuid);
	};

	return SingleSignOn;
});
