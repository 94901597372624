'use strict';

angular
	.module('bringgApp')
	.factory('EmployeeShiftsScheduleResource', function (WEB_API_URL, $resource) {
		var EmployeeShiftsScheduleResource = $resource(
			WEB_API_URL + '/users/:id/user_shifts_schedule/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/users/:id/user_shifts_schedule/' }
			}
		);

		return EmployeeShiftsScheduleResource;
	})
	.factory('EmployeeShiftsScheduleService', function (EmployeeShiftsScheduleResource, $log, $q) {
		var EmployeeShiftsScheduleService = {};

		EmployeeShiftsScheduleService.get = function (driverId) {
			var deferred = $q.defer();

			EmployeeShiftsScheduleResource.get({ id: driverId }, function (result) {
				if (result.success) {
					deferred.resolve({ data: result.shift_schedules, custom: result.custom });
				} else {
					deferred.reject(result);
				}
			});

			return deferred.promise;
		};

		EmployeeShiftsScheduleService.update = function (driverId, configuration, resetToDefault) {
			var deferred = $q.defer();

			var newConfig = new EmployeeShiftsScheduleResource({
				id: driverId,
				configuration: configuration,
				reset_to_default: resetToDefault
			});
			newConfig.$update(
				function (data, status, headers, config) {
					deferred.resolve({ data: data.shift_schedules, custom: data.custom });
				},
				function (data, status, headers, config) {
					deferred.reject('Failed updating merchant');
				}
			);

			return deferred.promise;
		};

		return EmployeeShiftsScheduleService;
	});
