'use strict';

angular
	.module('bringgApp')
	.controller(
		'reportDisplayController',
		function (
			$scope,
			reportsSerivce,
			bringg_utils,
			MerchantConfigurations,
			$location,
			toastr,
			$window,
			WEB_API_URL
		) {
			$scope.getStatus = bringg_utils.getStatusFromTask;
			$scope.WEB_API_URL = WEB_API_URL;

			$scope.$on('generating report', function (event) {
				$scope.shortCode = null;
				$scope.data = null;
				$scope.meta = null;
				$scope.report = null;
				$scope.templateUrl = null;
				$scope.header = null;
				$scope.subField = null;
				$scope.sub_header = null;
				$scope.subFooter = null;
				$scope.footer = null;
				$scope.dataRow = null;
				$scope.taskListHeaders = null;
			});

			$scope.$on('report error', function (errorObject, errorMessage) {
				toastr.error(errorMessage);
			});

			$scope.$on('report ready', function (event, reportIndex) {
				$scope.data = reportsSerivce.get(reportIndex).data;
				$scope.meta = reportsSerivce.get(reportIndex).meta;
				$scope.report = reportsSerivce.getReportDetails($scope.meta.id);
				$location.search('short_code', $scope.meta.shortCode);
				if (reportsSerivce.getTemplateUrl($scope.meta.id)) {
					$scope.templateUrl = reportsSerivce.getTemplateUrl($scope.meta.id);
				} else {
					$scope.templateUrl = null;
					$scope.header = reportsSerivce.getHeader($scope.meta.id);
					$scope.subField = reportsSerivce.getSubField($scope.meta.id);
					$scope.sub_header = reportsSerivce.getSubHeader($scope.meta.id);
					$scope.subFooter = reportsSerivce.getSubFooter($scope.meta.id);
					$scope.footer = reportsSerivce.getFooter($scope.meta.id);
					$scope.dataRow = reportsSerivce.getDataRowTemplateUrl($scope.meta.id);
				}
				$scope.taskListHeaders = reportsSerivce.getFields($scope.meta.id);
			});

			$scope.exportToCSV = function () {
				var csv = '',
					first = true;

				$('table[name="data_table"]').each(function () {
					if ($(this).data('driver-name')) {
						csv = csv.concat(
							reportsSerivce.table2CSV(this, {
								delivery: 'value',
								exportHeader: first,
								perLineFirstColumn: $(this).data('driver-name'),
								perLineFirstColumnHeader: 'Driver'
							})
						);
					} else {
						csv = csv.concat(
							reportsSerivce.table2CSV(this, {
								delivery: 'value',
								exportHeader: first
							})
						);
					}

					if (first) {
						first = false;
					}
					csv = csv.concat('\n');
				});

				var csvName = $scope.report && $scope.report.id ? $scope.report.id + '.csv' : 'filename.csv';
				csv = '\uFEFF' + csv; // fix utf-8 issue in Excel
				if ($window.navigator.msSaveBlob) {
					// IE 10+
					var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
					$window.navigator.msSaveBlob(blob, csvName);
				} else {
					var a = document.createElement('a');
					a.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv);
					a.target = '_blank';
					a.download = csvName;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				}
			};
		}
	);
