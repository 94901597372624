'use strict';
/*global $:false */
/*global google:false */

var google;

if (google === undefined) {
	google = {
		maps: {
			LatLng: function () {},
			MapTypeId: {
				ROADMAP: 1
			},
			Geocoder: function () {},
			Map: function () {}
		}
	};
}

var allowAutomaticResize = true;

function setAutomaticResize(toggle) {
	allowAutomaticResize = toggle;
}

function resizeMap() {
	var h = $(window).height();

	$('#small_map_canvas').css('height', (h * 50) / 100);
}

function resizeMapToResizableContainer() {
	var h = $('#map_resizeable').height();

	$('#map_canvas').css('height', h);
}

function initialize() {
	$(window).on('resize', resizeMap).trigger('resize');
}

initialize();

function find_in_array(arr, id) {
	id = parseInt(id, 10);
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].id === id) {
			return i;
		}
	}

	return -1;
}

function merge_objects(obj1, obj2) {
	for (var attrname in obj2) {
		obj1[attrname] = obj2[attrname];
	}
}

//google.maps.event.addDomListener(window, 'load', initialize);
function iconFromStatus(sub_status) {
	if (_.isNull(sub_status) || _.isEmpty(sub_status)) {
		sub_status = 'free';
	}
	var iconFileName = '';
	var iconsFolder = '/images/';
	//http://stackoverflow.com/questions/2771891/list-all-google-map-marker-images
	switch (sub_status.toLowerCase()) {
		case 'free':
			iconFileName = 'pin_free.png';
			break;
		case 'assigned':
			iconFileName = 'pin_free.png';
			break;
		case 'accepted':
			iconFileName = 'pin_free.png';
			break;
		case 'checked-in':
			iconFileName = 'pin_busy.png';
			break;
		case 'started':
			iconFileName = 'pin_busy.png';
			break;
		case 'late':
			iconFileName = 'pin_late.png';
			break;
		default:
			iconFileName = 'pin_free.png';
			break;
	}
	return iconsFolder + iconFileName;
}

// info here: https://developers.google.com/chart/image/docs/gallery/dynamic_icons?csw=1#pins
// http://chart.apis.google.com/chart?chst=d_map_spin&chld=0.75|0|FFFF42|11|b|Lior
function driverIconFromStatus(driverName, status) {
	if (_.isNull(status) || _.isEmpty(status)) {
		status = 'free';
	}
	var statusColors = {
		free: '#76c258',
		assigned: '#76c258',
		accepted: '#76c258',
		'checked-in': '#658cd2',
		started: '#658cd2',
		late: '#e65963'
	};
	var defaultStatusColor = '#76c258';
	var markerWidth = 12;
	var markerHeight = 3 * markerWidth;
	var markerTipWidth = 2;
	var markerPath = [
		'M',
		0,
		markerHeight,
		'c',
		0,
		(-2 * markerHeight) / 3,
		markerWidth,
		(-2 * markerHeight) / 3,
		markerWidth,
		-markerHeight,
		'c',
		0,
		(-4 * markerWidth) / 3,
		-2 * markerWidth - markerTipWidth,
		(-4 * markerWidth) / 3,
		-markerTipWidth * markerWidth - 3,
		0,
		'c',
		0,
		markerHeight / 3,
		markerWidth,
		markerHeight / 3,
		markerWidth,
		markerHeight,
		'z'
	].join(' ');
	var iconColor = statusColors[status] || defaultStatusColor;
	return {
		path: markerPath,
		fillColor: iconColor,
		fillOpacity: 0.8,
		anchor: new google.maps.Point(0, markerHeight),
		strokeWeight: 2,
		strokeColor: '#000000',
		scale: 1
	};
}

function updateMarker(marker, employee, googleMap, leaveTrail) {
	if (_.isUndefined(marker)) {
		return;
	}
	// first time we get here employee lat,lng will be null since they come from db and not real time component
	if (employee.lat && employee.lng && employee.status !== 'offline') {
		var pos = marker.getPosition();

		if (_.isNil(pos)) {
			return;
		}

		var newLat = parseFloat(employee.lat);
		var newLng = parseFloat(employee.lng);

		if (newLat === 0.0 || newLng === 0.0) {
			console.log('Employee [' + employee.id + '] updated location to 0,0 - disregard');
			return;
		}

		console.log(
			'Updating marker of employee [' +
				employee.id +
				'] from [' +
				pos.lat() +
				',' +
				pos.lng() +
				'] to [' +
				employee.lat +
				',' +
				employee.lng +
				']'
		);

		if (pos.lat() != newLat || pos.lng() != newLng) {
			console.log('update marker for ' + employee.id);
			if (google) {
				var oldLocation = marker.getPosition(),
					location = new google.maps.LatLng(newLat, newLng);
				googleMap.setCenter(location);
				marker.setPosition(location);

				if (leaveTrail) {
					var flightPath = new google.maps.Polyline({
						path: [oldLocation, location],
						geodesic: true,
						strokeColor: '#4574c9',
						strokeOpacity: 1.0,
						strokeWeight: 4
					});

					flightPath.setMap(googleMap);
				}
			}
		}
	}
}

function clearMarkers(markerArray) {
	_.each(markerArray, function (marker) {
		marker.setMap(null);
		google.maps.event.clearListeners(marker, 'click');
	});

	markerArray.length = 0;
}

function resizeMapByMarkers(map, markerArray) {
	if (!map) {
		return;
	}
	if (!allowAutomaticResize) {
		return;
	}
	if (markerArray.length === 0) {
		return;
	}
	var latlngbounds = new google.maps.LatLngBounds();

	markerArray.forEach(function (marker) {
		if (!_.isUndefined(marker) && !_.isUndefined(marker.getPosition())) {
			latlngbounds.extend(marker.getPosition());
		}
	});

	map.fitBounds(latlngbounds);
	map.setCenter(latlngbounds.getCenter());
}

function getkeyBy(array, name, value) {
	if (_.isUndefined(array)) {
		return -1;
	}
	for (var i = 0; i < array.length; i++) {
		if (array[i][name] == value) {
			return i;
		}
	}
	return -1;
}

function searchInSortedArray(arr, targetProperty, value, compareFunction) {
	compareFunction =
		compareFunction ||
		function (a, b) {
			return a - b;
		};

	var left = 0;
	var right = arr.length - 1;
	while (left <= right) {
		var mid = left + Math.floor((right - left) / 2);
		var currentObjectProperty = arr[mid][targetProperty];
		var compareFunctionValue = compareFunction(currentObjectProperty, value);

		// found it, return the index
		if (compareFunctionValue == 0) {
			return mid;
		}

		if (compareFunctionValue < 0) {
			left = mid + 1;
		} else {
			right = mid - 1;
		}
	}
	return -1;
}

function createMarker(map, lat, lng, icon, user_id, markerClicked) {
	if (!map) {
		return;
	}
	var newMarker = new google.maps.Marker({
		position: new google.maps.LatLng(lat, lng),
		map: map,
		icon: icon,
		optimized: false
	});

	if (user_id) {
		newMarker.set('user_id', user_id);
	}

	if (markerClicked) {
		var listenrId = google.maps.event.addListener(newMarker, 'click', function () {
			markerClicked(newMarker);
		});
		newMarker.set('listener_id', listenrId);
	}

	return newMarker;
}

function updateMarkers(all_markers, all_employees, activeSet, googleMap, markerClicked) {
	if (_.isUndefined(all_employees) || _.isUndefined(activeSet)) {
		console.log('updateMarkers no employees exist yet');
		return;
	}
	if (_.isUndefined(googleMap)) {
		console.log('updateMarkers no googleMap exist yet');
		return;
	}
	if (all_employees.promise && !all_employees.$resolved) {
		console.log("Can't update markers before employees are accepted");
	}
	var first_real_position_in_loop = true,
		activeMarkers = [];

	for (var i = 0; i < activeSet.length; i++) {
		var user_id = (activeSet[i].id = parseInt(activeSet[i].id));
		if (all_markers[user_id] === undefined && activeSet[i].status !== 'offline' && activeSet[i].active_shift_id) {
			if (
				activeSet[i].lat != null &&
				activeSet[i].lng != null &&
				activeSet[i].lat != 0 &&
				activeSet[i].lng != 0
			) {
				if (first_real_position_in_loop) {
					console.log(
						'Centering map by user ' + user_id + ' to ' + activeSet[i].lat + ':' + activeSet[i].lng
					);
					first_real_position_in_loop = false;
					var updated_ll = new google.maps.LatLng(activeSet[i].lat, activeSet[i].lng);
					googleMap.setCenter(updated_ll);
				}
				console.log('creating new marker for ' + user_id);
				var newMarker = createMarker(
					googleMap,
					activeSet[i].lat,
					activeSet[i].lng,
					driverIconFromStatus(activeSet[i].name, activeSet[i].sub),
					user_id,
					markerClicked
				);
				activeMarkers.push(newMarker);
				all_markers[user_id] = newMarker;
			} else {
				if (activeSet[i]) {
					console.log('Userid: ' + user_id + ' still has no location');
				} else {
					console.log('Index [' + i + ']: ' + ' has no data & location');
				}
			}
		} else {
			var marker = all_markers[user_id];

			if (
				activeSet[i].lat === null ||
				activeSet[i].lng === null ||
				activeSet[i].status === 'offline' ||
				activeSet[i].lat === 0 ||
				activeSet[i].lng === 0
			) {
				if (activeSet[i].status !== 'offline') {
					console.log(
						'No location data (' +
							activeSet[i].lat +
							', ' +
							activeSet[i].lng +
							') or active status is offline (' +
							activeSet[i].status +
							') for employee id (' +
							activeSet[i].id +
							') at location (' +
							i +
							'). Updating to offline'
					);
				}
				activeSet[i].status = 'offline';
			} else if (marker) {
				activeMarkers.push(marker);
				var pos = marker.getPosition();
				var newLat = parseFloat(activeSet[i].lat);
				var newLng = parseFloat(activeSet[i].lng);

				console.log(
					'checking for map marker update for (' +
						user_id +
						') from (' +
						pos.lat() +
						',' +
						pos.lng() +
						') to (' +
						newLat +
						',' +
						newLng +
						')'
				);

				if (
					pos.lat().toPrecision(9) != newLat.toPrecision(9) ||
					pos.lng().toPrecision(9) != newLng.toPrecision(9)
				) {
					console.log('update marker for id[' + user_id + ']');

					marker.setPosition(new google.maps.LatLng(newLat, newLng));
					marker.setIcon(driverIconFromStatus(activeSet[i].name, activeSet[i].sub));
				}
			}
		}
	}

	resizeMapByMarkers(googleMap, activeMarkers);

	// hide the rest
	if (!_.isEmpty(all_markers)) {
		var missingEmployees = _.difference(all_employees, activeSet);

		_.forEach(missingEmployees, function (missingEmployee) {
			var markerId = missingEmployee.id;
			if (!_.isUndefined(all_markers[markerId])) {
				google.maps.event.clearListeners(all_markers[markerId], 'click');
				all_markers[markerId].setMap(null);
				delete all_markers[markerId];
				all_markers[markerId] = undefined;
			}
		});
	}
}

function center_map(map, employee) {
	if (_.isUndefined(employee) || _.isUndefined(map)) {
		return false;
	}
	if (employee.lat !== undefined && employee.lat !== null && employee.lat !== 0 && employee.lat !== 0) {
		var updated_ll = new google.maps.LatLng(employee.lat, employee.lng);
		map.setCenter(updated_ll);
	}
}

function getSuccessCallback(args) {
	// the following is taken from angular-resource.js
	var params = {},
		data,
		success,
		error;
	switch (args.length) {
		case 4:
			error = args[3];
			success = args[2];
		//fallthrough
		case 3:
		case 2:
			if (_.isFunction(args[1])) {
				if (_.isFunction(args[0])) {
					success = args[0];
					error = args[0];
					break;
				}

				success = args[1];
				error = args[2];
				//fallthrough
			} else {
				params = args[0];
				data = args[1];
				success = args[2];
				break;
			}
		case 1:
			if (_.isFunction(args[0])) {
				success = args[0];
			}
			// else if (hasBody) data = arguments[0];
			else {
				params = args[0];
			}
			break;
		case 0:
			break;
	}

	return success;
}

function getErrorCallback(args) {
	// the following is taken from angular-resource.js
	var params = {},
		data,
		success,
		error;
	switch (args.length) {
		case 4:
			error = args[3];
			success = args[2];
		//fallthrough
		case 3:
		case 2:
			if (_.isFunction(args[1])) {
				if (_.isFunction(args[0])) {
					success = args[0];
					error = args[0];
					break;
				}

				success = args[1];
				error = args[2];
				//fallthrough
			} else {
				params = args[0];
				data = args[1];
				success = args[2];
				break;
			}
		case 1:
			if (_.isFunction(args[0])) {
				success = args[0];
			}
			// else if (hasBody) data = arguments[0];
			else {
				params = args[0];
			}
			break;
		case 0:
			break;
	}

	return error;
}

Array.prototype.move = function (old_index, new_index) {
	if (new_index >= this.length) {
		var k = new_index - this.length;
		while (k-- + 1) {
			this.push(undefined);
		}
	}
	this.splice(new_index, 0, this.splice(old_index, 1)[0]);
	return this; // for testing purposes
};

//$(function() {
//    var pressed = false;
//    var start = undefined;
//    var startX, startWidth;
//
//    $("table th").mousedown(function(e) {
//        start = $(this);
//        pressed = true;
//        startX = e.pageX;
//        startWidth = $(this).width();
//        $(start).addClass("resizing");
//    });
//
//    $(document).mousemove(function(e) {
//        if(pressed) {
//            $(start).width(startWidth+(e.pageX-startX));
//        }
//    });
//
//    $(document).mouseup(function() {
//        if(pressed) {
//            $(start).removeClass("resizing");
//            pressed = false;
//        }
//    });
//});
