'use strict';

angular
	.module('bringgApp')
	.factory(
		'MerchantConfigurationService',
		function (BringgSDK, MerchantConfigurations, CrossApplicationService, CROSS_APP_ACTIONS, $q) {
			function merchantConfigurationService(method) {
				return function () {
					var args = arguments;
					return BringgSDK.getInstancePromise().then(function (bringg) {
						return bringg.merchantConfiguration[method].apply(bringg.merchantConfiguration, args);
					});
				}.bind(this);
			}

			// There is a lot of places where the Merchant Configuration can be updated
			// so it is better to patch 'update' exactly here in the service
			function crossAppMerchantConfigurationUpdate() {
				var args = arguments;
				return BringgSDK.getInstancePromise()
					.then(function (bringg) {
						return bringg.merchantConfiguration.update.apply(bringg.merchantConfiguration, args);
					})
					.then(merchantConfiguration => {
						// notify react about successfully merchant configuration update
						CrossApplicationService.emit(CROSS_APP_ACTIONS.MERCHANT_CONFIGURATION_UPDATE, {
							merchantConfiguration
						});

						return merchantConfiguration;
					});
			}

			function dashboardTableConfigurationUpdate(gridId, field, nextValue) {
				// Need to have specific manipulations with data before sending to API
				// This is the reason of specific method instead of using generic function above: merchantConfigurationService(method)

				// So many _.cloneDeep to brake JS object references which interfere config compare in a bad way
				var currentConfig = _.cloneDeep(
					_.get(MerchantConfigurations, ['dashboard_ui_configuration', 'table_configuration'], {})
				);
				var valuePath = [gridId, field];
				var isSameConfigValue = _.isEqual(_.get(currentConfig, valuePath), nextValue);

				if (isSameConfigValue) {
					return $q.resolve();
				}

				var nextConfig = _.set(currentConfig || {}, valuePath, _.cloneDeep(nextValue));

				return BringgSDK.getInstancePromise()
					.then(function (bringg) {
						return bringg.merchantConfiguration.dashboardTableConfigurationUpdate(nextConfig);
					})
					.then(function () {
						// update locally saved config after successful saving in DB
						MerchantConfigurations.dashboard_ui_configuration.table_configuration = nextConfig;
					});
			}

			return {
				get: merchantConfigurationService('get'),
				update: crossAppMerchantConfigurationUpdate,
				resetDeliveryPin: merchantConfigurationService('resetDeliveryPin'),
				resetDestinationPin: merchantConfigurationService('resetDestinationPin'),
				getCSVFields: merchantConfigurationService('getCsvFields'),
				setCSVFields: merchantConfigurationService('setCsvFields'),
				getUserCSVFields: merchantConfigurationService('getUserCsvFields'),
				setUserCSVFields: merchantConfigurationService('setUserCsvFields'),
				deliveryPinChange: merchantConfigurationService('deliveryPinChange'),
				resetEmailLogo: merchantConfigurationService('resetEmailLogo'),
				resetClientAppLogo: merchantConfigurationService('resetClientAppLogo'),
				getRatingReasonConfiguration: merchantConfigurationService('getRatingReasonConfiguration'),
				setRatingReasonConfiguration: merchantConfigurationService('setRatingReasonConfiguration'),
				requestBetaProgram: merchantConfigurationService('requestBetaProgram'),
				getTipDriverConfiguration: merchantConfigurationService('getTipDriverConfiguration'),
				setTipDriverConfiguration: merchantConfigurationService('setTipDriverConfiguration'),
				getMobileTaskForm: merchantConfigurationService('getMobileTaskForm'),
				setMobileTaskForm: merchantConfigurationService('setMobileTaskForm'),
				dashboardTableConfigurationUpdate: _.throttle(dashboardTableConfigurationUpdate, 500, {
					trailing: true
				})
			};
		}
	)

	.factory(
		'MerchantConfigurations',
		function (
			$rootScope,
			localStorageService,
			BringgSDK,
			$translate,
			$log,
			tmhDynamicLocale,
			$q,
			CurrenciesService,
			ReactUserSettings,
			TranslationService
		) {
			var merchantConfiguration = {},
				locale = localStorageService.get('locale'),
				MERCHANT_TYPES = {
					RESTAURANT: 1,
					RDS: 2,
					ROUTE: 3
				},
				APPEARANCE_OPTIONS = {
					DISABLED: 0,
					ON_DRIVER_LIST: 1,
					ON_DRIVER_PAGE: 2,
					ON_ALL_LOCATIONS: 3,
					DEFAULT_FROM_MERCHANT: 4
				},
				GRAB_MODES = {
					OFF: 0,
					AUTOMATIC: 1,
					ON_DEMAND: 2
				};
			var merchantConfigurationLoadPromise = $q.defer();
			merchantConfiguration.$promise = merchantConfigurationLoadPromise.promise;

			if (locale) {
				$log.info('Using saved locale ', locale);
				moment.locale(locale);
			}

			$rootScope.$on('currentUserUpdated', function (event, user) {
				merchantConfiguration.$promise.then(function () {
					merchantConfiguration.$setLocale(user);
				});
			});

			merchantConfiguration.MERCHANT_TYPE = MERCHANT_TYPES;
			merchantConfiguration.APPEARANCE_OPTIONS = APPEARANCE_OPTIONS;
			merchantConfiguration.GRAB_MODES = GRAB_MODES;

			function normalizeData(data) {
				for (var property in data) {
					merchantConfiguration[property] = data[property];
				}
				var taskFieldsConfiguration = merchantConfiguration.applications.find(function (app) {
					return app.configuration && app.configuration.hasOwnProperty('task_fields');
				});
				var taskFields = taskFieldsConfiguration && taskFieldsConfiguration['configuration']['task_fields'];
				if (taskFields) {
					if (
						_.isUndefined(merchantConfiguration.task_fields) ||
						_.isNull(merchantConfiguration.task_fields)
					) {
						merchantConfiguration.task_fields = [];
					}
					if (merchantConfiguration.task_fields.start_new) {
						merchantConfiguration.task_fields.fields =
							merchantConfiguration.task_fields.fields.concat(taskFields);
					} else {
						merchantConfiguration.task_fields = merchantConfiguration.task_fields.concat(taskFields);
					}
				}

				return merchantConfiguration.$setLocale($rootScope.sdk.session.user).then(function () {
					$rootScope.$broadcast('config updated');
					return merchantConfigurationLoadPromise.resolve(merchantConfiguration);
				});
			}

			merchantConfiguration.$refresh = function () {
				return BringgSDK.getInstancePromise().then(function (sdk) {
					return normalizeData(sdk.session.merchantConfiguration);
				});
			};

			merchantConfiguration.$refetch = function () {
				return BringgSDK.getInstancePromise()
					.then(function (sdk) {
						return sdk.merchantConfiguration.fetchConfiguration();
					})
					.then(function () {
						return merchantConfiguration.$refresh();
					});
			};

			function onLocaleLoaded(locale) {
				TranslationService.clearCache();

				// fallback to english
				moment.locale([locale, 'en']);

				$log.log('Saving locale ', locale);
				localStorageService.set('locale', locale);
				ReactUserSettings.setLanguage(locale);
			}

			function updateLocale(locale) {
				if (locale === $translate.use() && locale === moment.locale()) {
					return $q.resolve();
				}

				return $translate
					.use(locale)
					.then(function () {
						onLocaleLoaded(locale);
					})
					.catch(function () {
						onLocaleLoaded(locale);
					});
			}

			merchantConfiguration.$setLocale = function (currentUser) {
				var locale = merchantConfiguration.country_code;
				switch (merchantConfiguration.country_code) {
					case 'ao':
					case 'cv':
					case 'gw':
					case 'mz':
					case 'st':
					case 'tl':
						locale = 'pt';
						break;
					case 'ae':
					case 'bh':
					case 'dz':
					case 'eg':
					case 'eh':
					case 'iq':
					case 'jo':
					case 'km':
					case 'kw':
					case 'lb':
					case 'ly':
					case 'ma':
					case 'mr':
					case 'om':
					case 'ps':
					case 'qa':
					case 'sa':
					case 'sd':
					case 'sy':
					case 'tn':
					case 'ye':
						locale = 'ar';
						break;
					case 'bf':
					case 'bi':
					case 'bj':
					case 'bl':
					case 'cd':
					case 'cf':
					case 'cg':
					case 'ci':
					case 'cm':
					case 'dj':
					case 'ga':
					case 'gf':
					case 'gn':
					case 'gp':
					case 'ht':
					case 'mc':
					case 'mf':
					case 'ml':
					case 'mq':
					case 'nc':
					case 'ne':
					case 'pf':
					case 'pm':
					case 're':
					case 'sc':
					case 'sn':
					case 'td':
					case 'tf':
					case 'tg':
					case 'wf':
					case 'yt':
						locale = 'fr';
						break;
					case 'md':
						locale = 'ru';
						break;
					case 'us':
						locale = 'en';
						break;
					case 'br':
						locale = 'pt-br';
						break;
					case 'il':
						locale = 'he';
						break;
					case 'bg':
						locale = 'bg';
						break;
					case 'dk':
						locale = 'da';
						break;
					case 'vn':
						locale = 'vi-vn';
						break;
					case 'id':
						locale = 'id';
						break;
					case 'ar':
					case 'cl':
					case 'es':
					case 'co':
					case 'mx':
					case 'pe':
					case 'ec':
					case 'uy':
					case 'cr':
					case 'pa':
					case 'pr':
						locale = 'es';
						break;
					case 'fi':
						locale = 'fi';
						break;
					case 'nz':
						locale = 'en-au';
						break;
					case 'gb':
					case 'au':
					case 'ca':
						locale = 'en-' + merchantConfiguration.country_code;
						break;
					case 'gr':
						locale = 'el';
						break;
					case 'ch':
					case 'at':
						locale = 'de';
						break;
				}

				var selectedLangauge = currentUser.language || locale || 'en';
				merchantConfiguration.language_code = selectedLangauge;

				// load bower_i18n dynamically
				tmhDynamicLocale.set(selectedLangauge).then(function () {
					CurrenciesService.setCurrencyCode(merchantConfiguration.price_currency);
				});

				return updateLocale(selectedLangauge);
			};

			merchantConfiguration.$restaurantMode = function () {
				return (
					[merchantConfiguration.MERCHANT_TYPE.RESTAURANT].indexOf(merchantConfiguration.merchant_mode) !== -1
				);
			};

			merchantConfiguration.$rdsMode = function () {
				return [merchantConfiguration.MERCHANT_TYPE.RDS].indexOf(merchantConfiguration.merchant_mode) !== -1;
			};

			merchantConfiguration.$routeMode = function () {
				return [merchantConfiguration.MERCHANT_TYPE.ROUTE].indexOf(merchantConfiguration.merchant_mode) !== -1;
			};

			return merchantConfiguration;
		}
	);
