'use strict';

angular
	.module('bringgApp')
	.controller('CreateTagController', function (
		$scope,
		Tag,
		$uibModalInstance,
		toastr,
		ReasonToCancelTask,
		$translate
	) {
		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope._handleCreateTagResult = function (result) {
			if (result.success) {
				$uibModalInstance.close(result.tag);
			} else {
				if (result.message) {
					toastr.error(result.message);
				} else {
					toastr.error($translate.instant('ERRORS.ERROR_OCCURRED'));
				}
			}
		};

		$scope.handleSaveClick = function () {
			Tag.create_tag(
				{
					tag: $scope.tagName,
					with_app_styling: $scope.withAppStyling,
					with_customer_configuration: $scope.withCustomerConfiguration,
					with_rules_configuration: $scope.withRulesConfiguration
				},
				function (result) {
					if (result.success && $scope.withCancellationFlowConfiguration) {
						return ReasonToCancelTask.createTagReasonsToCancelTaskConfiguration({
							tag_id: result.tag.id
						}).$promise.then(function (result) {
							return $scope._handleCreateTagResult(result);
						});
					} else {
						$scope._handleCreateTagResult(result);
					}
				}
			);
		};
	});
