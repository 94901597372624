'use strict';

angular
	.module('bringgApp')
	.directive('optimizeSelectDrivers', function () {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				drivers: '=',
				selectedTasks: '=',
				selectedDriversByTeam: '=',
				preserveCurrentRuns: '='
			},
			templateUrl: 'scripts/features/optimize/steps/select-drivers.html',
			controller: 'OptimizeSelectDriversController'
		};
	})
	.controller('OptimizeSelectDriversController', function ($scope, $q, Teams, bringg_utils) {
		//==============================================
		// Left side team list
		//==============================================
		$scope.activeTeamIndex = 0;
		$scope.areAllDriverSelectedByTeam = {};
		$scope.isTeamSelected = function (idx) {
			return $scope.activeTeamIndex === idx;
		};

		$scope.selectTeam = function (idx) {
			$scope.activeTeamIndex = idx;
		};

		$scope.hasAnySelection = function (team) {
			return _.some(team.drivers, 'selected');
		};

		$scope.toggleAllDriversSelection = function () {
			var team = $scope.usersByTeam[$scope.activeTeamIndex];

			_.each(team.drivers, function (driver) {
				driver.selected = $scope.areAllDriverSelectedByTeam[team.id];
			});

			$scope.updateSelectedDrivers();
		};

		$scope.checkIfAllDriversSelected = function (selectedTeam) {
			if (!selectedTeam && !$scope.usersByTeam) {
				return;
			}

			var team = selectedTeam ? selectedTeam : $scope.usersByTeam[$scope.activeTeamIndex];
			var driversCount = _.filter($scope.drivers, function (driver) {
				return _.includes(driver.team_ids, team.id);
			}).length;
			var driversSelected = _.filter(team.drivers, function (driver) {
				return driver.selected;
			}).length;

			if (driversSelected === driversCount && driversSelected !== 0) {
				$scope.areAllDriverSelectedByTeam[team.id] = true;
			}
			if (driversSelected === 0 || driversSelected !== driversCount) {
				$scope.areAllDriverSelectedByTeam[team.id] = false;
			}
		};

		$scope.getSelectedDriversFromTeam = function (team) {
			return _.filter(team.drivers, { selected: true });
		};

		$scope.updateSelectedDrivers = function () {
			var selectedDriversByTeams = [];

			_.each($scope.usersByTeam, function (team) {
				var selectedDrivers = $scope.getSelectedDriversFromTeam(team);

				if (_.some(selectedDrivers)) {
					selectedDriversByTeams.push({ id: team.id, name: team.name, drivers: selectedDrivers });
				}

				$scope.checkIfAllDriversSelected(team);
			});

			//this is done in order to make the optimize modal see the change.
			//if i do $scope.selectedDriversByTeam it will just break the watcher
			$scope.selectedDriversByTeam.length = 0; // this is the fastest way to clear an array
			$scope.selectedDriversByTeam.push.apply($scope.selectedDriversByTeam, selectedDriversByTeams); //this is a way to add the selected drivers by teams without changing the array pointer
		};

		//==============================================
		// Handle the user selecting unselecting the drivers
		//==============================================
		$scope.handleChange = function () {
			$scope.updateSelectedDrivers();
		};

		$scope.initTeamData = function (teams, drivers, teamId) {
			var team = _.pick(_.find(teams, { id: teamId }), ['id', 'name']);

			var teamDrivers = _.chain(drivers)
				.filter(function (driver) {
					return _.includes(driver.team_ids, teamId);
				})
				.map(_.clone)
				.value();
			bringg_utils.naturalSortByKey(teamDrivers, 'name');

			//this part is responsible for if the user clicks back. The UI should show the
			//selection user made earlier
			if ($scope.selectedDriversByTeam.length) {
				var selectedTeam = _.find($scope.selectedDriversByTeam, { id: team.id });
				if (selectedTeam) {
					_.each(teamDrivers, function (driver) {
						driver.selected = _.includes(_.map(selectedTeam.drivers, 'id'), driver.id);
						return driver;
					});
				}
				$scope.checkIfAllDriversSelected(selectedTeam);
			}

			_.defaults(team, { drivers: teamDrivers });

			return team;
		};

		$scope.initSelectedDrivers = function () {
			if (!$scope.preserveCurrentRuns) {
				return;
			}

			_.map($scope.drivers, function (driver) {
				driver.selected = undefined;
			});

			_.chain($scope.selectedTasks)
				.map('user_id')
				.compact()
				.uniq()
				.each(function (userId) {
					var driver = _.find($scope.drivers, { id: userId });
					driver.selected = true;
				})
				.value();
		};

		$scope.init = function () {
			$q.all([Teams.all()]).then(function (results) {
				var teamIds = _.chain($scope.drivers).map('team_ids').compact().flatten().uniq().value();
				$scope.initSelectedDrivers();
				$scope.usersByTeam = _.map(teamIds, function (teamId) {
					return $scope.initTeamData(results[0], $scope.drivers, teamId);
				});
				$scope.usersByTeam = _.filter($scope.usersByTeam, function (team) {
					return team.hasOwnProperty('name');
				});

				bringg_utils.naturalSortByKey($scope.usersByTeam, 'name');

				$scope.updateSelectedDrivers();
			});
		};

		$scope.init();
	});
