'use strict';

angular.module('bringgApp').factory('VehicleTypesService', function (BringgSDK) {
	function vehiclesService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.vehicleTypes[method].apply(bringg.vehicleTypes, args);
			});
		}.bind(this);
	}

	var VehicleTypesService = {};

	VehicleTypesService.getAll = vehiclesService('getAll');
	VehicleTypesService.get = vehiclesService('get');

	VehicleTypesService.getVehicleTypeByVehicleIds = function (vehicles, vehicleTypes) {
		var vehicleTypeByVehicleId = {};
		if (!vehicles) {
			return vehicleTypeByVehicleId;
		}
		var vehicleTypesById = _.keyBy(vehicleTypes, 'id');
		vehicles.forEach(function (vehicle) {
			var vehicleTypeId = vehicle.vehicle_type_id;
			vehicleTypeByVehicleId[vehicle.id] = vehicleTypesById[vehicleTypeId];
		});
		return vehicleTypeByVehicleId;
	};

	return VehicleTypesService;
});
