'use strict';

angular
	.module('bringgApp.directives')
	.directive(
		'signedInVer2',
		function (Authentication, ClientStatsService, SubmitTicketService, $timeout, $rootScope) {
			return {
				templateUrl: 'scripts/new_navigation/signed_in_ver2/signed-in-ver2.html',
				scope: {
					currentUser: '=',
					merchant: '='
				},
				link: function (scope, element, attrs) {
					scope.signOutClicked = function () {
						Authentication.logout();
					};

					scope.submitTicket = function () {
						SubmitTicketService.open();
					};

					scope.toggled = open => {
						scope.menuOpened = open;

						if (open) {
							const dropdown = element.find('.dropdown-menu');
							dropdown.css({
								top: -dropdown.height() - 5
							});
						}
					};

					scope.handleMerchantInfoClicked = function () {
						ClientStatsService.downloadLogs();
					};

					scope.toggleMenuCollapse = () => {
						$rootScope.topClass = $rootScope.topClass === 'collapsed' ? null : 'collapsed';
					};

					$timeout(() => {
						const userNameElem = element.find('.user-name')[0];
						scope.isUserNameEllipsisActive = userNameElem.scrollWidth > userNameElem.clientWidth;
					});
				}
			};
		}
	);
