/**
 * Created by liorsion on 5/12/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('ApplicationsConfigurationController', function (
		$scope,
		Authentication,
		MerchantConfigurations,
		Application
	) {
		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			$scope.merchantConfiguration = MerchantConfigurations;
			Application.all(function (applications) {
				$scope.allApplications = applications;
				_.forEach($scope.merchantConfiguration.applications, function (app) {
					$scope.publicUninstalledApplications = _.without(
						$scope.allApplications,
						_.find($scope.merchantConfiguration.applications, { id: app.id })
					);
				});
			});
		});

		$scope.addApp = function (app) {
			Application.add({ id: app.id })
				.$promise.then(function (result) {
					if (result.success) {
						$scope.merchantConfiguration.applications.push(app);
						alert(app.name + ' added successfully');
					} else {
						alert('Error adding app to merchant: ' + result.message);
					}
				})
				.catch(function (error) {
					alert('Error adding app to merchant');
				});
		};

		$scope.deleteApp = function (app) {
			if (confirm('Are you sure you want to delete ' + app.name + '?')) {
				Application.delete({ id: app.id })
					.$promise.then(function (result) {
						if (result.success) {
							$scope.merchantConfiguration.applications = _.without(
								$scope.merchantConfiguration.applications,
								_.find($scope.merchantConfiguration.applications, { id: app.id })
							);
							alert(app.name + ' removed successfully');
						} else {
							alert('Error removing app from merchant: ' + result.message);
						}
					})
					.catch(function (error) {
						alert('Error removing app from merchant');
					});
			}
		};
	});
