'use strict';

angular.module('bringgApp').service('Locations', function () {
	let _this = this;
	_this.validateLatLng = validateLatLng;
	_this.validateLatLngOnWayPoint = validateLatLngOnWayPoint;
	_this.validateToFarTasks = validateToFarTasks;

	function validateLatLng(lat, lng) {
		if (!lat || !lng) {
			return false;
		}
		return lat > -90 && lat < 90 && lng > -180 && lng < 180;
	}

	function validateLatLngOnWayPoint(task) {
		const taskWithNoWayPointLocation = task.way_points.filter(
			wayPoint => !validateLatLng(wayPoint.lat, wayPoint.lng)
		);
		return !taskWithNoWayPointLocation.length;
	}

	function validateToFarTasks(task, team, maxDistanceFromCenterInMeters) {
		if (!team || !validateLatLng(team.lat, team.lng)) {
			return false;
		}

		const isDistanceTooFar = task.way_points.some(wayPoint => {
			if (wayPoint.lat != undefined && wayPoint.lng != undefined) {
				var distance = geolib.getDistance(
					{ latitude: team.lat, longitude: team.lng },
					{ latitude: wayPoint.lat, longitude: wayPoint.lng }
				);
				return distance >= maxDistanceFromCenterInMeters;
			}
			return false;
		});

		return isDistanceTooFar;
	}
});
