'use strict';

angular.module('bringgApp').constant('TASK_DEFAULT_TYPES', {
	PICKUP: 2,
	RETURN_TASK: 3,
	DELIVERY: 4,
	PICKUP_AND_DELIVERY: 5,
	CLICK_AND_COLLECT: 6
});

angular.module('bringgApp').constant('TASK_TYPE_TRANSLATIONS', {
	PICKUP: 'TASK_TYPE_NAMES.PICKUP',
	RETURN: 'TASK_TYPE_NAMES.RETURN',
	DELIVERY: 'TASK_TYPE_NAMES.DELIVERY',
	PICKUP_DELIVERY: 'TASK_TYPE_NAMES.PICKUP_DELIVERY',
	CONSUMER_COLLECTION: 'TASK_TYPE_NAMES.CONSUMER_COLLECTION',
	SERVICE: 'TASK_TYPE_NAMES.SERVICE',
	CANVASSING: 'TASK_TYPE_NAMES.CANVASSING',
	UNDEFINED: 'TASK_TYPE_NAMES.UNDEFINED'
});

angular.module('bringgApp').constant('TASK_TYPE_MAP', {
	2: 'PICKUP',
	3: 'RETURN',
	4: 'DELIVERY',
	5: 'PICKUP_DELIVERY',
	6: 'CONSUMER_COLLECTION',
	7: 'SERVICE',
	8: 'CANVASSING'
});
