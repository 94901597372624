'use strict';

angular
	.module('bringgApp')
	.service('PartialRunsRunner', function (RunsService, $q, Authentication, PartialRunsActions, ReactModals, Tasks) {
		var _this = this;
		_this.runWithAllRunTasks = runWithAllRunTasks;

		function runWithAllRunTasks(selectedItems, allTasks, partialRunsSource) {
			if (
				Authentication.currentUser().feature_flags.dont_enforce_full_runs ||
				!RunsService.isPlannedRunsEnabled()
			) {
				return $q.resolve(selectedItems);
			}

			var partialRunIds = RunsService.getPartialRunIdsByTasks(selectedItems, allTasks);

			if (Object.keys(partialRunIds).length === 0) {
				return $q.resolve(selectedItems);
			}

			return askShouldLoad(partialRunsSource).then(function (result) {
				if (result.partialRunsAction === PartialRunsActions.NONE) {
					return;
				}

				if (result.partialRunsAction === PartialRunsActions.SELECT_ALL_IN_RUN) {
					return getAllTasksOfRuns(selectedItems, allTasks, partialRunIds);
				}

				if (result.partialRunsAction === PartialRunsActions.REMOVE_SELECTED_FROM_RUN) {
					return detachTasksFromRuns(selectedItems, partialRunIds);
				}
			});
		}

		function getAllTasksOfRuns(selectedItems, allTasks, partialRunIds) {
			var allTasksOfRuns = [];
			var selectedTaskIds = new Set(
				selectedItems.map(function (task) {
					return task.id;
				})
			);

			allTasks.forEach(function (task) {
				if (selectedTaskIds.has(task.id) || partialRunIds[task.run_id]) {
					allTasksOfRuns.push(task);
				}
			});

			return allTasksOfRuns;
		}

		function detachTasksFromRuns(selectedItems, partialRunIds) {
			var promises = selectedItems.map(function (task) {
				if (partialRunIds[task.run_id]) {
					return Tasks.removeFromRun(task);
				}
			});

			return $q.all(promises).then(function () {
				return selectedItems;
			});
		}

		function askShouldLoad(partialRunsAction) {
			return ReactModals.openPartialRunsActionsModal(partialRunsAction);
		}
	});
