'use strict';

angular.module('bringgApp').service('LinkedTasksService', function (TasksData) {
	const _this = this;
	_this.getMissingLinkedTasks = getMissingLinkedTasks;

	function getMissingLinkedTasks(selectedTasks) {
		const linkedTasks = new Set();

		selectedTasks.forEach(task => {
			if (task.linked_task_id) {
				if (linkedTasks.has(task.id)) {
					linkedTasks.delete(task.id);
				} else {
					linkedTasks.add(task.linked_task_id);
				}
			}
		});

		return Array.from(linkedTasks).map(linkedTaskId => TasksData.get(linkedTaskId));
	}
});
