'use strict';

angular
	.module('bringgApp')
	.controller('PaymentsController', function (
		$scope,
		toastr,
		Authentication,
		MerchantService,
		Merchant,
		dialogSrv
	) {});
