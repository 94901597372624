'use strict';

app.controller(
	'CustomerCareController',
	function (
		$scope,
		Support,
		Authentication,
		$window,
		ITEMS_PER_PAGE,
		localStorageService,
		$log,
		MerchantConfigurations
	) {
		// pagination
		$scope.totalItems = 0;
		$scope.numPerPage = ITEMS_PER_PAGE;
		$scope.loading = false;
		$scope.lastSearch = null;
		$scope.data = [];

		$scope.gridOptions = {
			enableSorting: true,
			showColumnFooter: false,
			columnDefs: [
				{
					field: 'external_id',
					displayName: 'CUSTOMER_CARE.ORDER_NUMBER',
					headerCellFilter: 'translate',
					cellTemplate:
						'<div class="ui-grid-cell-contents"><a ng-click="grid.appScope.impersonateByTask(row.entity)">{{row.entity.external_id}}</a></div>',
					sortable: true
				},
				{
					field: 'way_points[0].customer.name',
					displayName: 'CUSTOMER_CARE.CUSTOMER_NAME',
					headerCellFilter: 'translate',
					sortable: true
				},
				{
					field: 'way_points[0].customer.phone',
					displayName: 'CUSTOMER_CARE.CUSTOMER_PHONE',
					headerCellFilter: 'translate',
					sortable: true
				},
				{
					field: 'way_points[0].customer.address',
					displayName: 'CUSTOMER_CARE.DESTINATION',
					headerCellFilter: 'translate',
					sortable: true
				},
				{
					field: 'way_points[0].scheduled_at',
					displayName: 'CUSTOMER_CARE.SCHEDULED_AT',
					headerCellFilter: 'translate',
					cellFilter: 'human_time',
					sortable: true
				},
				{
					field: 'status',
					displayName: 'CUSTOMER_CARE.STATUS',
					headerCellFilter: 'translate',
					cellFilter: 'statusString',
					sortable: true
				}
			],
			data: 'data'
		};

		$scope.impersonateByTask = function (task) {
			var teamId = _.first(task.team_ids);
			Authentication.impersonateToSupportByTeamId(teamId)
				.then(function (user) {
					localStorageService.set('init_route', '/history/' + task.id);
					localStorageService.set('impersonated_by_task', true);
					MerchantConfigurations.$refresh().then(function () {
						$window.location.reload();
					});
				})
				.catch(function (err) {
					$log.error('failed to impersonate ', err);
				});
		};

		$scope.searchTasks = function (text, action) {
			$scope.loading = true;

			var query = {
				query: text,
				pgver: 2
			};

			if (action) {
				query.page_action = action;
				query.cursor = action === 'next' ? $scope.next_page_cursor : $scope.previous_page_cursor;
			}

			Support.supportTasks(query).$promise.then(function (result) {
				$scope.data = result.tasks;
				$scope.next_page_cursor = result.next_page_cursor;
				$scope.previous_page_cursor = result.previous_page_cursor;
				$scope.loading = false;
				$scope.totalItems = result.tasks?.length ?? 0;

				$scope.lastSearch = text;
			});
		};

		$scope.isNextButtonDisabled = function () {
			return $scope.loading || _.isNil($scope.next_page_cursor);
		};

		$scope.isPreviousButtonDisabled = function () {
			return $scope.loading || _.isNil($scope.previous_page_cursor);
		};
	}
);
