'use strict';
/*global $:false */

var spinnerCounter = 0;

angular
	.module('bringgApp')
	.config(function ($httpProvider) {
		$httpProvider.interceptors.push('myHttpInterceptor');
		var spinnerFunction = function (data, headersGetter) {
			spinnerCounter++;
			$('.ajax-spinner').show();
			return data;
		};
		$httpProvider.defaults.transformRequest.push(spinnerFunction);
	})
	// register the interceptor as a service, intercepts ALL angular ajax http calls
	.factory('myHttpInterceptor', function ($q) {
		return {
			addManualRequest: function () {
				spinnerCounter++;
				$('.ajax-spinner').show();
			},
			response: function (response) {
				spinnerCounter--;
				if (spinnerCounter === 0) {
					$('.ajax-spinner').hide();
				}
				return response;
			},
			responseError: function (rejection) {
				// do something on error
				spinnerCounter--;
				if (spinnerCounter === 0) {
					$('.ajax-spinner').hide();
				}
				return $q.reject(rejection);
			}
		};
	});
