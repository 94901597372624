'use strict';

angular
	.module('bringgApp')
	.controller('SelectReasonIconController', function ($scope, $uibModalInstance, currentSelection) {
		$scope.data = { currentSelection: currentSelection };

		$scope.items = [
			{ src: 'images/post_rating/damage.png' },
			{ src: 'images/post_rating/dispatch.png' },
			{ src: 'images/post_rating/emoticon_1.png' },
			{ src: 'images/post_rating/emoticon_2.png' },
			{ src: 'images/post_rating/emoticon_3.png' },
			{ src: 'images/post_rating/service.png' },
			{ src: 'images/post_rating/technical.png' },
			{ src: 'images/post_rating/time.png' }
		];

		$scope.handleDoubleClick = function (item) {
			$uibModalInstance.close(item.src);
		};

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};

		$scope.handleSaveClick = function () {
			$uibModalInstance.close($scope.data.currentSelection);
		};
	});
