'use strict';
/* global getkeyBy:false */

angular.module('bringgApp').factory('CustomerSpecialFeature', function ($resource, WEB_API_URL) {
	var CustomerSpecialFeature = $resource(
		WEB_API_URL + '/customers/:id',
		{ id: '@id' },
		{
			all: { method: 'GET', url: WEB_API_URL + '//customer_special_features/', isArray: true }
		}
	);

	return CustomerSpecialFeature;
});
