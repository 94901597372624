'use strict';

angular
	.module('bringgApp')
	.controller(
		'MerchantConfigurationController',
		function (
			$scope,
			MerchantConfigurationService,
			Authentication,
			Application,
			FileUploader,
			WEB_API_URL,
			dialogSrv,
			localStorageService,
			MerchantConfigurations,
			toastr,
			DriverConfigurations,
			bringg_utils,
			STRIPE_CLIENT_ID,
			$location
		) {
			$scope._data = { impersonateSupermode: true };
			$scope.tabs = [];
			$scope.stripe_client_id = STRIPE_CLIENT_ID;

			Application.all(function (applications) {
				$scope.applications = applications;
				$scope.$emit('applications ready', null);
			});

			DriverConfigurations.get().then(function (data) {
				$scope.driverConfiguration = data;
			});

			var logoUploader = ($scope.logo_image_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				})),
				destinationUploader = ($scope.destination_image_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				})),
				deliveryUploader = ($scope.delivery_image_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				})),
				emailLogoUploader = ($scope.email_logo_image_uploader = new FileUploader({
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				}));

			logoUploader.filters.push(bringg_utils.imageFilter());
			destinationUploader.filters.push(bringg_utils.imageFilter());
			deliveryUploader.filters.push(bringg_utils.imageFilter());
			emailLogoUploader.filters.push(bringg_utils.imageFilter());

			function afterAddingFileFn(itemId, uploader) {
				return function (addedFile) {
					console.info('afterAddingFileFn', addedFile);
					$('#' + itemId).triggerHandler('$new_file', addedFile);
					uploader.queue[0].url = WEB_API_URL + '/merchant/merchant_configuration/' + itemId;

					uploader.uploadAll();
				};
			}

			function afterUploadingFileFn(itemId) {
				return function (item, response, status, headers) {
					if (status === 200 && response.success) {
						$scope.images_merchantConfiguration[itemId] = response.url;
						$scope.merchantConfiguration[itemId] = response.url;
						dialogSrv.notifyDialog('Image was added successfully', '');
						MerchantConfigurations.$refresh();
					}
				};
			}

			$scope.resetDeliveryPin = function () {
				$scope.merchantConfiguration
					.$reset_delivery_pin()
					.then(function (url) {
						$scope.images_merchantConfiguration.delivery_pin = url;
						dialogSrv.notifyDialog('Image was reset successfully', '');
						MerchantConfigurations.$refresh();
					})
					.catch(function (error) {
						alert(error);
					});
			};

			$scope.resetDestinationPin = function () {
				$scope.merchantConfiguration
					.$reset_destination_pin()
					.then(function (url) {
						$scope.images_merchantConfiguration.destination_pin = url;
						dialogSrv.notifyDialog('Image was reset successfully', '');
					})
					.catch(function (error) {
						alert(error);
					});
			};

			logoUploader.onAfterAddingFile = afterAddingFileFn('logo', logoUploader);
			deliveryUploader.onAfterAddingFile = afterAddingFileFn('delivery_pin', deliveryUploader);
			destinationUploader.onAfterAddingFile = afterAddingFileFn('destination_pin', destinationUploader);
			emailLogoUploader.onAfterAddingFile = afterAddingFileFn('email_logo', emailLogoUploader);

			logoUploader.onSuccessItem = afterUploadingFileFn('logo');
			deliveryUploader.onSuccessItem = afterUploadingFileFn('delivery_pin');
			destinationUploader.onSuccessItem = afterUploadingFileFn('destination_pin');
			emailLogoUploader.onSuccessItem = afterUploadingFileFn('email_logo');

			$scope.loadETADisplay = function () {
				$scope.listOfETAMethods = [
					{ MethodName: 'Smart', MethodId: 'smart' },
					{ MethodName: 'Absolute', MethodId: 'absolute' },
					{ MethodName: 'Countdown', MethodId: 'countdown' },
					{ MethodName: 'NoETA', MethodId: 'noETA' }
				];
				if (!this.merchantConfiguration.consumer_app_eta_display_method) {
					this.merchantConfiguration.consumer_app_eta_display_method = { method: 'smart', threshold: 30 };
				}
				$scope.eta_display = this.merchantConfiguration.consumer_app_eta_display_method;
				$scope.selectedETAMethod = $scope.eta_display.method;
				if ($scope.selectedETAMethod) {
					if ($scope.selectedETAMethod === 'smart') {
						$scope.selectedETAThreshold = $scope.eta_display.threshold;
					}
				} else {
					$scope.selectedETAMethod = 'smart';
					$scope.selectedETAThreshold = 30;
				}
			};

			$scope.updateETADisplay = function (etaMethod, etaThreshold) {
				if ($scope.eta_display) {
					$scope.eta_display.method = etaMethod;
					if (etaMethod === 'smart') {
						if (etaThreshold) {
							$scope.selectedETAThreshold = etaThreshold;
						} else {
							$scope.selectedETAThreshold = 30;
						}
					} else {
						$scope.selectedETAThreshold = null;
					}
					$scope.eta_display.threshold = $scope.selectedETAThreshold;
				}
			};

			//when the user changes the delivery pin from external popup this screen should update.
			$scope.$on('alternateDeliveryPinUpdated', function (event, data) {
				$scope.images_merchantConfiguration.delivery_pin = data;
			});

			var configReady = $scope.$on('config ready', function () {
				// remove listener
				configReady();
				$scope.merchantConfiguration.application_merchant_configurations.forEach(function (appConfig) {
					var appDetails = _.find($scope.merchantConfiguration.applications, {
							id: appConfig.application_id
						}),
						appName;
					// it's possible appDetails will not exist, if old configuration still exists but app is not installed
					if (appDetails) {
						appName = appDetails.name;
						var newTab = { title: appName, active: false, appId: appDetails.id };
						newTab.content = [];
						for (var appConfigKey in appConfig.data) {
							newTab.content.push({
								title: appConfigKey,
								fieldId: appConfigKey,
								value: appConfig.data[appConfigKey]
							});
						}
						$scope.tabs.push(newTab);
					}
				});
			});

			$scope.impersonate = function (otherUserId) {
				otherUserId = otherUserId.replace(',', '');
				Authentication.impersonate(otherUserId, function () {
					if ($scope._data.impersonateSupermode) {
						Authentication.currentUser().beta = true;
						Authentication.currentUser().debug = true;
						localStorageService.set('overvyoo_user', Authentication.currentUser());
					}
					alert('User ' + otherUserId + ' identitiy taken, please refresh');
				});
			};

			$scope.updateApp = function (appId) {
				var updateData = {};
				$('[id^="' + appId + '_"').each(function () {
					var field = this.id.substr(this.id.indexOf('_') + 1);
					updateData[field] = this.value;
				});

				Application.updateConfig(appId, updateData)
					.then(function (result) {
						if (result.success) {
							$scope.merchantConfiguration.applications.push(app);
							alert(app.name + ' updated successfully');
						} else {
							alert('Error updating app: ' + result.message);
						}
					})
					.catch(function (error) {
						alert('Error updating app');
					});
			};

			$scope.addApp = function (app) {
				Application.add({ id: app.id })
					.$promise.then(function (result) {
						if (result.success) {
							$scope.merchantConfiguration.applications.push(app);
							alert(app.name + ' added successfully');
						} else {
							alert('Error adding app to merchant: ' + result.message);
						}
					})
					.catch(function (error) {
						alert('Error adding app to merchant');
					});
			};

			$scope.deleteApp = function (app) {
				if (confirm('Are you sure you want to delete ' + app.name + '?')) {
					Application.delete({ id: app.id })
						.$promise.then(function (result) {
							if (result.success) {
								$scope.merchantConfiguration.applications = _.without(
									$scope.merchantConfiguration.applications,
									_.find($scope.merchantConfiguration.applications, { id: app.id })
								);
								alert(app.name + ' removed successfully');
							} else {
								alert('Error removing app from merchant: ' + result.message);
							}
						})
						.catch(function (error) {
							alert('Error removing app from merchant');
						});
				}
			};

			$scope.editApp = function (app) {
				$location.search('appUuid', app.uuid);
			};

			$scope.updateDriverConfiguration = function () {
				DriverConfigurations.update($scope.driverConfiguration)
					.then(function () {
						toastr.success('Update saved, thank you');
					})
					.catch(function () {
						toastr.error('Failed updating merchant');
					});
			};

			$scope.initActiveTab = function () {
				var selectedTab = $location.search().selectedTab;
				$scope.activeTab = selectedTab ? parseInt(selectedTab) : 0;
			};

			$scope.onTabSelect = function (tabNumber) {
				$scope.activeTab = tabNumber;
				$location.search({ selectedTab: tabNumber });
			};

			$scope.configSubmit = function () {
				MerchantConfigurationService.update($scope.merchantConfiguration)
					.then(function () {
						//TODO: update cookiestore merchant info here
						toastr.success('Update saved, thank you!');
					})
					.catch(function () {
						toastr.error('Failed updating merchant settings.');
					});
			};
		}
	);
