'use strict';

angular.module('bringgApp.directives').directive('collapseMenuButton', function ($rootScope, localStorageService) {
	return {
		restrict: 'E',
		replace: true,
		template: `<div class="collapse-button"><i 
				class="glyphicon" 
				ng-class="{'glyphicon-chevron-left': opened, 'glyphicon-chevron-right': !opened}" 
				aria-hidden="true" 
				ng-click="handleCollapseButtonClicked()"></i></div>`,
		link: function (scope) {
			scope.handleCollapseButtonClicked = function () {
				$rootScope.topClass = $rootScope.topClass === 'collapsed' ? null : 'collapsed';
				scope.opened = !scope.opened;
				localStorageService.set('topClass', $rootScope.topClass);
			};

			$rootScope.topClass = localStorageService.get('topClass');
		}
	};
});
