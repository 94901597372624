'use strict';

angular.module('bringgApp').service('WayPointsService', function (PICKUP_DROPOFF_OPTION, TASK_DEFAULT_TYPES) {
	const _this = this;
	_this.getDropOffWayPoint = getDropOffWayPoint;
	_this.getActionableWaypoint = getActionableWaypoint;
	_this.getTasksDropOffPoints = getTasksDropOffPoints;

	function getDropOffWayPoint(task) {
		return _.find(task.way_points, { position: 2 });
	}

	function getActionableWaypoint(task) {
		var activePickupDropoff;

		var wayPoints = _.filter(task.way_points, function (wp) {
			return !wp.hidden;
		});

		var pickupDropOffOptions = PICKUP_DROPOFF_OPTION();
		switch (task.task_type_id) {
			case TASK_DEFAULT_TYPES.PICKUP:
				activePickupDropoff = pickupDropOffOptions.pick_up;
				break;
			case TASK_DEFAULT_TYPES.DELIVERY:
				activePickupDropoff = pickupDropOffOptions.drop_off;
				break;
			case TASK_DEFAULT_TYPES.PICKUP_AND_DELIVERY:
				activePickupDropoff = pickupDropOffOptions.both;
				break;
		}

		var actionableWayPoint = _.find(wayPoints, function (wp) {
			return _.isNumber(activePickupDropoff) && wp.pickup_dropoff_option === activePickupDropoff;
		});

		return actionableWayPoint || _.last(wayPoints);
	}

	function getTasksDropOffPoints(tasks) {
		var tasksDropOffWayPoints = [];

		_.each(tasks, function (task) {
			var dropOffWayPoint = getDropOffWayPoint(task);

			if (dropOffWayPoint) {
				tasksDropOffWayPoints.push(dropOffWayPoint);
			}
		});

		return tasksDropOffWayPoints;
	}
});
