'use strict';

angular
	.module('bringgApp')
	.controller(
		'IndexController',
		function (
			$scope,
			localStorageService,
			$rootScope,
			AuthenticationUtils,
			Authentication,
			ManagerActionAuthorizationService,
			SubmitTicketService,
			CrossApplicationService,
			CROSS_APP_ACTIONS,
			IdentityProvider,
			BringgSDK
		) {
			BringgSDK.initSdkConfiguration();

			$scope.newNavigation = localStorageService.get('newNavigation');

			$rootScope.$watch(Authentication.isLoggedIn, function () {
				if (Authentication.isLoggedIn()) {
					ManagerActionAuthorizationService.init();

					Authentication.featureFlags().then(function (featureFlags) {
						$scope.newNavigation = _.isUndefined(featureFlags.new_navigation)
							? $scope.newNavigation
							: featureFlags.new_navigation;
						localStorageService.set('newNavigation', $scope.newNavigation);
					});
				}
			});

			IdentityProvider.byToken().catch(function () {});

			$rootScope.$watch(AuthenticationUtils.isPartialLogin, function (isPartial) {
				$scope.isPartial = !!isPartial;
			});

			CrossApplicationService.on(CROSS_APP_ACTIONS.SUBMIT_TICKET, function () {
				SubmitTicketService.open();
			});
		}
	);
