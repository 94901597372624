'use strict';

angular
	.module('bringgApp')
	.controller(
		'reports2Controller',
		function (
			$scope,
			uiGridExporterConstants,
			$log,
			toastr,
			Authentication,
			ReportPreferences,
			$timeout,
			Employees,
			reportsSerivce,
			$location,
			localStorageService,
			MerchantConfigurations,
			Teams,
			$q,
			$filter,
			$translate,
			$routeParams,
			ReportsService2,
			PRIVILEGES_TYPES,
			PdfExportService
		) {
			$scope.enableReports = Authentication.currentUser().has_access('reports_new');
			$scope.canAccessOnlyCompanyUsers =
				!Authentication.currentUser().admin &&
				Authentication.currentUser().has_access(
					PRIVILEGES_TYPES.ACCESS_USERS,
					PRIVILEGES_TYPES.ACCESS_COMPANY_USERS_ONLY
				);

			$scope.selectUsersConfig = {
				valueField: 'id',
				labelField: 'name',
				searchField: 'name',
				plugins: ['remove_button'],
				// delimiter: '|',
				placeholder: $translate.instant('REPORTS.CHOOSE_EMPLOYEES')
			};

			$scope.selectTeamsConfig = {
				valueField: 'id',
				labelField: 'name',
				searchField: 'name',
				plugins: ['remove_button'],
				// delimiter: '|',
				placeholder: $translate.instant('REPORTS.CHOOSE_TEAMS')
			};

			/**
			 * init report form short code
			 */
			$scope._initFromShortCode = function () {
				var shortCode = $location.search().short_code;
				if (!shortCode) {
					return;
				}
				ReportsService2.generateReport(
					null,
					[],
					[],
					$scope.dateRange,
					shortCode,
					$scope.selectDateLabelIndex,
					$scope.selectTeams
				);
			};

			/**
			 *
			 * @param shortCode
			 */
			ReportsService2.updateShortCode = function (shortCode) {
				$location.search('short_code', shortCode);
			};

			/**
			 * update report data and columns
			 */
			var updateReport = function (reportOptions) {
				_.extend($scope.gridOptions, reportOptions);
				// wait for render before expand
				$timeout($scope.gridApi.treeBase.expandAllRows);
			};

			/**
			 * restore search query from saved report
			 */
			$scope.restoreFilters = function (options) {
				// selected report
				$log.info('restoring filters using report id: ' + options.id);
				$log.info('searching report in reports list: ' + _.map($scope.reports, 'id'));

				$scope.selectedReport = _.find($scope.reports, { id: options.id });
				$scope.initReportSupportOptions();
				if (!options.date_range) {
					// load times
					$scope.dateRange.startDate = moment.unix(options.startDate).format();
					$scope.dateRange.endDate = moment.unix(options.endDate).format();
				} else {
					var dates = _.toArray($scope.dateSettings.ranges)[options.date_range - 1];
					$scope.dateRange.startDate = dates[0].format();
					$scope.dateRange.endDate = dates[1].format();
				}

				// load employees filter
				$scope.selectedUsersIds = options.all_employees ? [] : options.employee_ids;

				// load teams filter
				$scope.selectedTeamsIds = options.all_teams ? [] : options.team_ids;

				$scope.selectTeams = options.all_teams || !_.isEmpty($scope.selectedTeamsIds);

				// update exported files names
				$scope.gridOptions.exporterCsvFilename = $scope.selectedReport.id + '.csv';
				$scope.gridOptions.exporterPdfFilename = $scope.selectedReport.id + '.pdf';
			};

			/**
			 * on report ready
			 */
			$scope.$on('report ready', function (event, reportsLength) {
				var report = reportsSerivce.get(reportsLength);
				$scope.reportInProcess = false;
				ReportsService2.updateShortCode(report.meta.shortCode);
				updateReport(report.data);
				$scope.restoreFilters(report.meta);
				// Restore previously saved state.
				$scope.defaultReportColumnDefs = _.map(report.data.columnDefs, _.clone);
				$scope.restoreState();
				$scope.reportReady = true;
			});

			/**
			 * on report generate
			 */
			$scope.$on('report error', function (event, error) {
				$scope.reportInProcess = false;
				$log.error('report error');
				$log.error(error);
				toastr.error(error);
			});

			/**
			 * generate report
			 */
			$scope.generateReport = function (report, usersIds, teamsIds, dateRange, shortCode) {
				$scope.reportInProcess = true;
				ReportsService2.generateReport(
					report,
					usersIds,
					teamsIds,
					dateRange,
					shortCode,
					$scope.selectDateLabelIndex,
					$scope.selectTeams
				);
			};

			/**
			 * restore default columns state
			 */
			$scope.restoreDefaultColumns = function () {
				$scope.gridOptions.columnDefs.splice(0, $scope.gridOptions.columnDefs.length);
				$timeout(function () {
					_.each($scope.defaultReportColumnDefs, function (columnDef) {
						$scope.gridOptions.columnDefs.push(_.clone(columnDef));
					});
					$scope.isCustomColumnDefs = false;
					$scope.deleteSavedState();
				});
			};

			/**
			 * delete saved state
			 */
			$scope.deleteSavedState = function () {
				if (!$scope.selectedReport) {
					return;
				}
				localStorageService.remove('gridState.' + $scope.selectedReport.id);
			};

			/**
			 * save and restore state
			 */
			$scope.saveState = function () {
				if (!$scope.selectedReport) {
					return;
				}

				$scope.isCustomColumnDefs = true;
				var state = $scope.gridApi.saveState.save();
				localStorageService.set('gridState.' + $scope.selectedReport.id, state);
			};

			/**
			 * restore saved state
			 */
			$scope.restoreState = function () {
				if (!$scope.selectedReport) {
					return;
				}

				var state = localStorageService.get('gridState.' + $scope.selectedReport.id);
				if (state) {
					$timeout(function () {
						$scope.gridApi.saveState.restore($scope, state);
					});
				}
			};

			/**
			 * exports
			 */
			$scope.exportToCSV = function () {
				$timeout(function () {
					$scope.gridApi.exporter.csvExport(uiGridExporterConstants.VISIBLE, uiGridExporterConstants.VISIBLE);
				}, 100);
			};

			$scope.exportToPDF = function () {
				PdfExportService.export($scope.gridApi, [
					uiGridExporterConstants.VISIBLE,
					uiGridExporterConstants.VISIBLE
				]);
			};

			/**
			 * report name click
			 */
			$scope.reportNameClicked = function () {
				$timeout(function () {
					$scope.reportTypeSelect.open = true;
				});
			};

			/**
			 * remove all selected
			 */
			$scope.removeAllSelected = function () {
				$scope.selectedUsersIds = [];
				$scope.selectedTeamsIds = [];
			};

			$scope.selectReportType = function () {
				$scope.selectTeams = false;
				$scope.selectedUsersIds = [];
				$scope.selectedTeamsIds = [];
				$scope.setDateSettings();
				$scope.initReportSupportOptions();
			};

			$scope.initReportSupportOptions = function () {
				if ($scope.selectedReport) {
					$scope.supportTeams =
						$scope.selectedReport.support_teams &&
						MerchantConfigurations.enable_teams &&
						!$scope.canAccessOnlyCompanyUsers;
					$scope.supportUsers = $scope.selectedReport.support_users;
					if ($scope.supportTeams && !$scope.supportUsers) {
						$scope.selectTeams = true;
					}
				} else {
					$scope.supportTeams = false;
					$scope.supportUsers = false;
				}
			};

			$scope.setDateSettings = function () {
				$scope.dateSettings = {
					locale: {
						format: 'MM/DD/YYYY',
						separator: ' - ',
						applyLabel: $translate.instant('REPORTS.DATE_PICKER.APPLY'),
						cancelLabel: $translate.instant('REPORTS.DATE_PICKER.CANCEL'),
						fromLabel: $translate.instant('REPORTS.DATE_PICKER.FROM'),
						toLabel: $translate.instant('REPORTS.DATE_PICKER.TO'),
						customRangeLabel: $translate.instant('REPORTS.DATE_PICKER.CUSTOM'),
						ranges: {
							Today: $translate.instant('REPORTS.DATE_PICKER.TODAY'),
							Yesterday: $translate.instant('REPORTS.DATE_PICKER.YESTERDAY'),
							'Last 7 Days': $translate.instant('REPORTS.DATE_PICKER.LAST_7_DAYS'),
							'Last 30 Days': $translate.instant('REPORTS.DATE_PICKER.LAST_30_DAYS')
						}
					},
					ranges: {
						Today: [moment().startOf('day'), moment().endOf('day')],
						Yesterday: [
							moment().subtract(1, 'days').startOf('day'),
							moment().subtract('days', 1).endOf('day')
						],
						'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
						'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')]
					},
					eventHandlers: {
						'apply.daterangepicker': function (event, picker) {
							var dateRangesArray = _.toArray(_.keys($scope.dateSettings.ranges));
							$scope.selectDateLabelIndex = dateRangesArray.indexOf(picker.chosenLabel);
							$scope.selectDateLabelIndex++;
						}
					},
					format: 'MM/DD/YYYY hh:mm a',
					timePicker: true,
					maxDate: moment().endOf('day'),
					minDate: moment().subtract(29, 'days').startOf('day')
				};

				// BRNGG-21215 limit legacy reports to 30 days back
				const has30DaysLimit =
					Boolean($scope.selectedReport) &&
					Boolean($scope.selectedReport.id) &&
					['delivery_orders_report_new', 'all_orders_new'].some(
						report => report === $scope.selectedReport.id
					);
				if (!has30DaysLimit) {
					Object.assign($scope.dateSettings.locale.ranges, {
						'This Month': $translate.instant('REPORTS.DATE_PICKER.THIS_MONTH'),
						'Last Month': $translate.instant('REPORTS.DATE_PICKER.LAST_MONTH')
					});
					Object.assign($scope.dateSettings.ranges, {
						'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
						'Last Month': [
							moment().subtract(1, 'month').startOf('month').startOf('day'),
							moment().subtract(1, 'month').endOf('month').endOf('day')
						]
					});

					$scope.dateSettings.minDate = moment().subtract(3, 'month').startOf('day');
				}

				// this logic is required because ranges language isn't supported in bootstrap's dateRangePicker
				var newRanges = {};
				var translatedKeys = Object.values($scope.dateSettings.locale.ranges);
				_.forEach(Object.keys($scope.dateSettings.ranges), function (old_key, i) {
					var rangesDescriptor = Object.getOwnPropertyDescriptor($scope.dateSettings.ranges, old_key);
					Object.defineProperty(newRanges, translatedKeys[i], rangesDescriptor);
				});
				$scope.dateSettings.ranges = newRanges;

				$scope.dateRange = {
					startDate: moment().subtract(29, 'days').startOf('day'),
					endDate: moment().endOf('day')
				};
			};

			$scope.saveReportPreference = function () {
				var state = $scope.gridApi.saveState.save();
				var hiddenColumns = _.chain(state.columns)
					.reject(function (column) {
						return column.visible;
					})
					.map('name')
					.value();
				var order = _.map(state.columns, 'name');
				if (order.length && hiddenColumns.length) {
					ReportPreferences.upsert({
						report_preference: {
							report_name: $scope.selectedReport.id,
							preference: { order: order, hidden_columns: hiddenColumns }
						}
					});
				}
			};

			/**
			 * init function
			 */
			var init = function () {
				//default ui setting
				$scope.reportInProcess = false;
				$scope.reportTypeSelect = null;
				$scope.showReportSelection = false;
				$scope.isCustomColumnDefs = false;
				$scope.defaultReportColumnDefs = null;
				$scope.selectedUsersIds = [];
				$scope.selectedTeamsIds = [];
				// select teams/employees in UI
				$scope.selectTeams = false;
				$scope.reportReady = false;
				$scope.reports = [];
				$scope.gridApi = null;
				$scope.columnDefs = [{ name: ' ' }];
				// default date setting, to avoid NaN issue
				$scope.dateSettings = {
					locale: {
						format: 'MM/DD/YYYY',
						separator: ' - ',
						applyLabel: $translate.instant('REPORTS.DATE_PICKER.APPLY'),
						cancelLabel: $translate.instant('REPORTS.DATE_PICKER.CANCEL'),
						fromLabel: $translate.instant('REPORTS.DATE_PICKER.FROM'),
						toLabel: $translate.instant('REPORTS.DATE_PICKER.TO'),
						customRangeLabel: $translate.instant('REPORTS.DATE_PICKER.CUSTOM')
					},
					eventHandlers: {
						'apply.daterangepicker': function (event, picker) {
							var dateRangesArray = _.toArray(_.keys($scope.dateSettings.ranges));
							$scope.selectDateLabelIndex = dateRangesArray.indexOf(picker.chosenLabel);
							$scope.selectDateLabelIndex++;
						}
					},
					format: 'MM/DD/YYYY hh:mm a',
					timePicker: true,
					maxDate: moment().endOf('day'),
					minDate: moment().subtract(29, 'days').startOf('day')
				};

				$scope.gridOptions = {
					exporterOlderExcelCompatibility: true,
					excessRows: 50,
					flatEntityAccess: true,
					fastWatch: true,
					enableFiltering: false,
					enableRowHashing: true,
					enableGridMenu: true,
					gridMenuTitleFilter: function (displayName) {
						return $translate.instant(displayName);
					},
					enableColumnResizing: true,
					onRegisterApi: function (gridApi) {
						$scope.gridApi = gridApi;

						// Setup events so we're notified when grid state changes.
						gridApi.colMovable.on.columnPositionChanged($scope, $scope.saveState);
						gridApi.colResizable.on.columnSizeChanged($scope, $scope.saveState);
						gridApi.grouping.on.aggregationChanged($scope, $scope.saveState);
						gridApi.grouping.on.groupingChanged($scope, $scope.saveState);
						gridApi.core.on.columnVisibilityChanged($scope, $scope.saveState);
						gridApi.core.on.filterChanged($scope, $scope.saveState);
						gridApi.core.on.sortChanged($scope, $scope.saveState);
					},
					//fix bug: https://github.com/angular-ui/ui-grid/issues/3136
					columnDefs: $scope.columnDefs,

					// to enable filter for each column
					exporterFieldCallback: function (grid, row, col, input) {
						if (!_.isEmpty(col.cellFilter)) {
							col.cellSplitedCellFilted = col.cellSplitedCellFilted || col.cellFilter.split(':');
							return $filter(col.cellSplitedCellFilted[0])(input, col.cellSplitedCellFilted[1]);
						}
						return input;
					},

					// translate header for exported data
					exporterHeaderFilter: function (displayName) {
						return $translate.instant(displayName);
					}
				};

				var promises = [reportsSerivce.getNewReportsList(), Employees.allEmployees, Teams.all()];

				$q.all(promises).then(function (results) {
					var getNewReportsResponse = results[0].data;

					// DT-228 For some reason selectize keep rerender when working with objects with embedded objects
					// we need to recreate each result and it works just fine
					$scope.users = (results[1] || []).map(function (result) {
						return Object.assign({}, result);
					});

					$scope.teams = results[2] || [];

					if (getNewReportsResponse.success) {
						$scope.reports = getNewReportsResponse.reports;

						var selectedReportId = $location.search().id;
						if (selectedReportId) {
							$scope.selectedReport = $scope.reports.find(function (item) {
								return item.id === selectedReportId;
							});
						}

						$scope.selectedReport = $scope.selectedReport || $scope.reports[0];
						$scope.initReportSupportOptions();
						$scope.setDateSettings();

						if (
							!_.isEmpty(MerchantConfigurations.only_show_report_ids) &&
							MerchantConfigurations.only_show_report_ids.length > 0
						) {
							var sideReports = [];
							_.each(MerchantConfigurations.only_show_report_ids, function (report_id) {
								var report = _.find($scope.reports, { id: report_id });
								if (!_.isUndefined(report)) {
									sideReports.push(report);
								}
							});
							$scope.reports = sideReports;
						}
					}

					$scope._initFromShortCode();
				});
			};
			/**
			 * start init process
			 */
			init();
		}
	);
