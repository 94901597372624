'use strict';

var CURRENCIES = [
	{
		title: 'Australian Dollar',
		code: 'AUD',
		symbol: '$'
	},
	{
		title: 'Brazilian Real',
		code: 'BRL',
		symbol: '$'
	},
	{
		title: 'Canadian Dollar',
		code: 'CAD',
		symbol: '$'
	},
	{
		title: 'Czech Koruna',
		code: 'CZK',
		symbol: 'Kč'
	},
	{
		title: 'Chilean Peso',
		code: 'CLP',
		symbol: '$'
	},
	{
		title: 'Danish Krone',
		code: 'DKK',
		symbol: 'kr'
	},
	{
		title: 'Euro',
		code: 'EUR',
		symbol: '€'
	},
	{
		title: 'Hong Kong Dollar',
		code: 'HKD',
		symbol: '$'
	},
	{
		title: 'Hungarian Forint',
		code: 'HUF',
		symbol: 'Ft'
	},
	{
		title: 'Japanese Yen',
		code: 'JPY',
		symbol: '¥'
	},
	{
		title: 'Malaysian Ringgit',
		code: 'MYR',
		symbol: 'RM'
	},
	{
		title: 'Mexican Peso',
		code: 'MXN',
		symbol: '$'
	},
	{
		title: 'Norwegian Krone',
		code: 'NOK',
		symbol: 'kr'
	},
	{
		title: 'New Zealand Dollar',
		code: 'NZD',
		symbol: '$'
	},
	{
		title: 'Philippine Peso',
		code: 'PHP',
		symbol: '₱'
	},
	{
		title: 'Polish Zloty',
		code: 'PLN',
		symbol: 'zł'
	},
	{
		title: 'Pound Sterling',
		code: 'GBP',
		symbol: '£'
	},
	{
		title: 'Singapore Dollar',
		code: 'SGD',
		symbol: '$'
	},
	{
		title: 'Swedish Krona',
		code: 'SEK',
		symbol: 'kr'
	},
	{
		title: 'Swiss Franc',
		code: 'CHF',
		symbol: 'CHF'
	},
	{
		title: 'Taiwan New Dollar',
		code: 'TWD',
		symbol: '$'
	},
	{
		title: 'Thai Baht',
		code: 'THB',
		symbol: '฿'
	},
	{
		title: 'Turkish Lira',
		code: 'TRY',
		symbol: '₺'
	},
	{
		title: 'U.S. Dollar',
		code: 'USD',
		symbol: '$'
	},
	{
		title: 'Sri Lankan rupee',
		code: 'LKR',
		symbol: '₨'
	},
	{
		title: 'Kuwaiti Dinar',
		code: 'KWD',
		symbol: 'ك'
	},
	{
		title: 'New Israeli Shekel',
		code: 'ILS',
		symbol: '₪'
	},
	{
		title: 'Jamaican Dollar',
		code: 'JMD',
		symbol: 'J$'
	},
	{
		title: 'Romanian Leu',
		code: 'RON',
		symbol: 'lei'
	},
	{
		title: 'South African Rand',
		code: 'ZAR',
		symbol: 'R'
	},
	{
		title: 'Colombian Peso',
		code: 'COP',
		symbol: '$'
	},
	{
		title: 'Argentine Peso',
		code: 'ARS',
		symbol: '$'
	},
	{
		title: 'Peruvian Sol',
		code: 'PEN',
		symbol: 'S/.'
	},
	{
		title: 'Uruguayan Peso',
		code: 'UYU',
		symbol: '$U'
	},
	{
		title: 'Panamanian Balboa',
		code: 'PAB',
		symbol: 'B/.'
	},
	{
		title: 'Costa Rican Colon',
		code: 'CRC',
		symbol: '₡'
	}
];

angular.module('bringgApp').factory('CurrenciesService', function ($locale, $rootScope) {
	var CurrenciesService = {};

	CurrenciesService.currenciesMap = {};
	CurrenciesService.currencies = [];

	CurrenciesService.setCurrencies = function (currencies) {
		CurrenciesService.currencies = currencies;
		_.each(CurrenciesService.currencies, function (currency) {
			CurrenciesService.currenciesMap[currency.code] = currency;
		});

		$rootScope.$broadcast('currencies updated', CurrenciesService.currencies);
	};

	CurrenciesService.setCurrencyCode = function (code) {
		code = code || 'USD';

		var currency = CurrenciesService.currenciesMap[code] || {};

		if ($locale && $locale.NUMBER_FORMATS) {
			$locale.NUMBER_FORMATS.CURRENCY_SYM = currency.symbol || '';
		}
	};

	$rootScope.$on('config updated', function () {
		CurrenciesService.setCurrencies(CURRENCIES);
	});

	return CurrenciesService;
});
