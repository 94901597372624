'use strict';

angular
	.module('bringgApp')
	.controller('CustomerBillingWelcomeMessageController', function ($scope, $uibModalInstance, $location, merchantId) {
		$scope.merchantId = merchantId;
		$scope.handleCloseClick = function () {
			$uibModalInstance.dismiss();
		};

		$scope.handleShowSettingsClick = function () {
			$location.path('/merchant/');
			$uibModalInstance.dismiss();
		};
	});
