'use strict';

angular
	.module('bringgApp')
	.factory('ManagerActionAuthorizationService', function (
		ActionInterceptorService,
		$uibModal,
		MerchantConfigurations,
		$q,
		INTERCEPTABLE_ACTIONS,
		$injector,
		$filter,
		bringg_utils
	) {
		var ManagerActionAuthorizationService = {};

		//==============================================
		// DEMO OF A SINGLE RULE!
		//==============================================
		/**{
      action: MANAGER_AUTHORIZATION_ACTIONS.TASK_ASSIGMENT, <- the type of the action
      rule_name: 'taskAssignEmployeeExtID1' or 'employeeCacheOut', <- name of the rule(FILTER) that check if it needs to be validated or not
      description: 'Assigning to Manager One needs to be authorized', <- the text that is written on the popup
      validation: '^[a-zA-Z]{7}$' <- the validation of the user input of the popup
    }**/

		ManagerActionAuthorizationService.run = function (action, data) {
			var resolve = $q.resolve(true);
			var managerial_authorization_rules = bringg_utils.getValueFromApplicationMerchantConfiguration(
				'managerial_authorization_rules'
			);
			if (!managerial_authorization_rules) {
				return resolve;
			}

			var rule = _.find(managerial_authorization_rules, { action: action });

			if (!rule || !$injector.has(rule.rule_name + 'Filter')) {
				return resolve;
			}

			return $filter(rule.rule_name)(data).then(function (result) {
				if (result) {
					return $uibModal.open({
						backdrop: 'static',
						keyboard: false,
						controller: 'ManagerActionAuthorizationController',
						templateUrl: 'scripts/features/manager_action_authorization/manager-action-authorization.html',
						resolve: {
							actionDescription: function () {
								return rule.description;
							},
							validation: function () {
								return rule.validation;
							}
						}
					}).result;
				}

				return true;
			});
		};

		ManagerActionAuthorizationService.init = function () {
			_.each(INTERCEPTABLE_ACTIONS, function (action) {
				ActionInterceptorService.interceptOn(action, ManagerActionAuthorizationService.run);
			});
		};

		return ManagerActionAuthorizationService;
	});
