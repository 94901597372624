'use strict';
/*global Stripe:false */

angular
	.module('bringgApp')
	.controller('AddCreditCardController', function (
		$scope,
		Payments,
		$uibModalInstance,
		toastr,
		STRIPE_KEY,
		LazyLoadJsService,
		$log
	) {
		$scope.cardForm = {};
		$scope.card = { number: null, cvc: null, exp_month: undefined, exp_year: undefined };
		$scope.sending = false;
		$scope.loading = true;

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope._handlePaymentsResponse = function (status, result) {
			if (result.error) {
				toastr.error(result.error.message);
				$scope.sending = false;
			} else {
				Payments.add_credit_card({ card_token: result.id }, function (response) {
					if (response.success) {
						$uibModalInstance.close(response.data);
					} else {
						toastr.error(response.message);
						$scope.sending = false;
					}
				});
			}
		};

		try {
			if (Stripe) {
				$scope.loading = false;
				$log.info('Stripe was loaded');
			}
		} catch (err) {
			LazyLoadJsService.loadFile('https://js.stripe.com/v2/').then(function (result) {
				$scope.loading = false;
			});
		}

		$scope.handleSaveClick = function () {
			$scope.sending = true;
			Stripe.setPublishableKey(STRIPE_KEY);
			Stripe.card.createToken(
				{
					number: $scope.card.number,
					cvc: $scope.card.cvc,
					exp_month: $scope.card.exp_month,
					exp_year: $scope.card.exp_year,
					name: $scope.card.name
				},
				$scope._handlePaymentsResponse
			);
		};
	});
