'use strict';

angular
	.module('bringgApp')
	.constant('EXTERNAL_FLEET_TYPE_NAME', {
		1: 'Uber',
		2: 'Deliv',
		3: 'Loggi',
		4: 'Dicom',
		5: 'DoorDash',
		6: 'IFood',
		7: 'Rappi',
		8: 'Mensajeros',
		9: 'PedidosYa',
		10: 'Glovo',
		11: 'Domicilios',
		12: 'Postmates',
		13: 'Fedex',
		14: 'Stuart',
		15: 'Bringg',
		16: 'Deliveright'
	})
	.constant('INTERNAL_FLEET_TYPE', 33)
	.factory('Fleets', function (
		BringgSDK,
		MerchantConfigurations,
		EXTERNAL_FLEET_TYPE_NAME,
		INTERNAL_FLEET_TYPE,
		TranslationService
	) {
		function fleetsService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.fleets[method].apply(bringg.fleets, args);
				});
			};
		}

		var Fleets = {};

		Fleets.getAll = fleetsService('getAll');
		Fleets.getById = fleetsService('getById');
		Fleets.getByTask = fleetsService('getByTask');
		Fleets.askForQuote = fleetsService('askForQuote');

		Fleets.mappedById = {};

		Fleets.getName = function (fleetId) {
			if (!Fleets.mappedById[fleetId]) {
				return 'Unknown Fleet';
			}

			if (Fleets.mappedById[fleetId].fleet_type === INTERNAL_FLEET_TYPE) {
				return TranslationService.instant('ASSIGN_FLEET.INTERNAL_FLEET_TEXT');
			}

			return (
				Fleets.mappedById[fleetId].name ||
				EXTERNAL_FLEET_TYPE_NAME[Fleets.mappedById[fleetId].fleet_type] ||
				'Unknown Fleet'
			);
		};

		Fleets.initPromise = null;

		Fleets.init = function () {
			if (!Fleets.initPromise) {
				Fleets.initPromise = Fleets.getAll().then(function (fleets) {
					Fleets.mappedById = _.keyBy(fleets, 'id');
				});
			}
			return Fleets.initPromise;
		};

		// auto load fleets only if we have the application installed
		if (MerchantConfigurations.enable_fleets) {
			Fleets.init();
		}

		return Fleets;
	});
