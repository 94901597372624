'use strict';

angular
	.module('bringgApp')
	.controller('RatingReasonController', function (
		$scope,
		MerchantConfigurationService,
		toastr,
		SelectReasonIconService,
		MerchantService,
		Payments,
		CustomerConfiguration,
		MerchantConfigurations,
		Authentication,
		$q
	) {
		$scope.loading = true;
		$scope.saving = false;
		$scope.maxRatingReasons = 6;

		$scope.stars = [
			{ value: 1, description: '1 Star' },
			{ value: 2, description: '2 Stars' },
			{ value: 3, description: '3 Stars' },
			{ value: 4, description: '4 Stars' },
			{ value: 5, description: '5 Stars' }
		];

		$scope.addRatingReason = function () {
			if ($scope.ratingReasonsConfiguration.rating_reasons.length < $scope.maxRatingReasons) {
				var item = { text: '' };
				$scope.ratingReasonsConfiguration.rating_reasons.push(item);
			}
		};

		$scope.removeRatingReason = function (item) {
			$scope.ratingReasonsConfiguration.rating_reasons = _.without(
				$scope.ratingReasonsConfiguration.rating_reasons,
				item
			);
		};

		$scope.saveRatingReasonsConfiguration = function () {
			if ($scope.saving) {
				return;
			}

			MerchantService.proFeaturesEnabled().then(function (result) {
				if (result) {
					$scope.saving = true;
					$q.all([
						MerchantConfigurationService.setRatingReasonConfiguration($scope.ratingReasonsConfiguration),
						MerchantConfigurationService.update($scope.merchantConfiguration),
						CustomerConfiguration.update($scope.customerConfiguration).$promise
					]).then(
						function (results) {
							$scope.saving = false;
						},
						function (reason) {
							toastr.error('Failed saving configuration');
						}
					);
				} else {
					Payments.openUpdateToProWindow();
				}
			});
		};

		//LOAD DATA
		$q.all([
			MerchantConfigurationService.getRatingReasonConfiguration(),
			CustomerConfiguration.get().$promise,
			MerchantConfigurationService.get()
		]).then(
			function (results) {
				$scope.ratingReasonsConfiguration = results[0];
				$scope.customerConfiguration = results[1];
				$scope.merchantConfiguration = results[2];
				$scope.newRatingScreenEnabled = Authentication.currentUser().feature_flags.new_rating_screen || false;
				$scope.loading = false;
			},
			function (reason) {
				toastr.error('Failed loading configuration');
			}
		);
	});
