'use strict';

angular
	.module('bringgApp')
	.factory(
		'RunsService',
		function (
			$rootScope,
			BringgSDK,
			toastr,
			$translate,
			MerchantConfigurations,
			Application,
			TimeRangeOverlapChecker,
			Authentication
		) {
			const DEFAULT_PAGE_SIZE = 200;
			let _runsSdk;

			function runsService(method) {
				return function () {
					var args = arguments;
					return BringgSDK.getInstancePromise().then(function (bringg) {
						const sdk = shouldUseDashboardSdkApi() ? bringg.v2.runs : bringg.runs;
						return sdk[method].apply(sdk, args);
					});
				}.bind(this);
			}

			var NO_ROUTE = 'NO_ROUTE';
			var RunsService = {};

			RunsService.assignDriver = runsService('assignDriver');
			RunsService.unassignDriver = runsService('unassignDriver');
			RunsService.markDonePlanning = runsService('markDonePlanning');
			RunsService.getActualBreaks = runsService('getActualBreaks');

			BringgSDK.getInstancePromise().then(function (bringg) {
				_runsSdk = shouldUseDashboardSdkApi() ? bringg.v2.runs : bringg.runs;
				RunsService.onCreate = _runsSdk.onCreate;
				RunsService.onUpdate = _runsSdk.onUpdate;
				RunsService.onDelete = _runsSdk.onDelete;
			});

			RunsService.load = function (id, force) {
				return BringgSDK.getInstancePromise().then(function (bringg) {
					if (shouldUseDashboardSdkApi()) {
						return bringg.v2.runs.load(id, { useCache: !force });
					} else {
						const promise = force ? bringg.runs.forceLoad(id) : bringg.runs.load(id);
						return promise.then(runModel => {
							runModel.run.task_ids = Array.from(runModel.taskIds);
							return runModel.run;
						});
					}
				});
			};

			RunsService.loadAll = options => {
				return BringgSDK.getInstancePromise().then(function (bringg) {
					if (options.ids && options.ids.length === 0) {
						return;
					}

					options.items = options.items || DEFAULT_PAGE_SIZE;

					shouldUseDashboardSdkApi() ? bringg.v2.runs.loadMany(options) : bringg.runs.loadAll(options);
				});
			};

			RunsService.getAllFromStore = () => {
				return shouldUseDashboardSdkApi()
					? _runsSdk.getAll()
					: [..._runsSdk.runStore.store.getItemsMap().values()].map(runModel => runModel.run);
			};

			RunsService.getFromStore = id => {
				if (shouldUseDashboardSdkApi()) {
					return _runsSdk.get(id);
				} else {
					const runModel = _runsSdk.runStore.store.getItem(id);

					if (runModel) {
						runModel.run.task_ids = Array.from(runModel.taskIds);
						return runModel.run;
					}
				}
			};

			RunsService.isPlannedRunsEnabled = function () {
				return (
					MerchantConfigurations.enable_planned_routes ||
					Application.isInstalledByUuidSync(Application.APPLICATIONS.RouteOptimizer2)
				);
			};

			RunsService.assignDriverToRun = function (runId, driverId) {
				if (driverId) {
					return RunsService.assignDriver(runId, driverId)
						.then(function (resp) {
							toastr.success($translate.instant('DISPTACH.RUN_DRIVER_ASSIGNED'));

							return resp;
						})
						.catch(function (error) {
							toastr.error($translate.instant('DISPTACH.ERROR_ASSIGNING_DRIVER_TO_RUN'), error.details);

							return error;
						});
				} else {
					return RunsService.unassignDriver(runId)
						.then(function (resp) {
							toastr.success($translate.instant('DISPTACH.RUN_DRIVER_UNASSIGNED'));

							return resp;
						})
						.catch(function (error) {
							toastr.error(
								$translate.instant('DISPTACH.ERROR_UNASSIGNING_DRIVER_FROM_RUN'),
								error.details
							);

							return error;
						});
				}
			};

			RunsService.markDonePlanningToRuns = function (runIds) {
				if (runIds.length > 0) {
					return RunsService.markDonePlanning(runIds)
						.then(function (response) {
							toastr.success($translate.instant('DISPATCH.MARK_DONE_PLANNING_RUNS'));
							return response;
						})
						.catch(function (error) {
							toastr.error($translate.instant('DISPATCH.ERROR_MARK_DONE_PLANNING'), error.details);
							return error;
						});
				}
			};

			function updateRunTasks(tasks, runId) {
				return _.filter(tasks, function (task) {
					return task.run_id === runId;
				});
			}

			RunsService.bulkUpdateRunTasks = function (tasks, runId, updateTaskFn) {
				var filteredTasks = updateRunTasks(tasks, runId);

				if (filteredTasks.length) {
					_.forEach(filteredTasks, function (task) {
						updateTaskFn(task);
					});
				}
			};

			RunsService.getPartialRunIdsByTasks = function (selectedTasks, allTasks) {
				var selectedTasksByRun = groupTasksByRun(selectedTasks);
				var tasksByRun = groupTasksByRun(allTasks);
				var partialRunIds = {};

				Object.keys(selectedTasksByRun).filter(function (runId) {
					if (runId !== NO_ROUTE && selectedTasksByRun[runId].length !== tasksByRun[runId].length) {
						partialRunIds[runId] = true;
					}
				});

				return partialRunIds;
			};

			RunsService.getRunFromTask = function (task) {
				if (!task.run_id) {
					return;
				}

				return RunsService.getFromStore(task.run_id);
			};

			function groupTasksByRun(tasks) {
				return _.groupBy(tasks, task => task.run_id || NO_ROUTE);
			}

			RunsService.getOverlappedRunIds = function (runs) {
				return TimeRangeOverlapChecker.getOverlappedIds(
					runs,
					'scheduled_start_time',
					'scheduled_end_time',
					'id'
				);
			};

			function shouldUseDashboardSdkApi() {
				return Authentication.currentUser() && Authentication.currentUser().feature_flags.runs_v2_api;
			}

			return RunsService;
		}
	);
