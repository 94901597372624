'use strict';

angular
	.module('bringgApp')
	.factory(
		'CollapsedLinkedTasksService',
		function (
			MerchantConfigurations,
			Authentication,
			CROSS_APP_ACTIONS,
			TaskGroupTypes,
			Task,
			TASK_STATUS,
			$rootScope
		) {
			const CollapsedLinkedTasksService = {};

			const ORIGIN = {
				PLANNING: 'planning',
				DISPATCH: 'dispatch'
			};

			const planningTasks = new Map();
			const dispatchTasks = new Map();
			const collapseLinkTasksMap = new Map();
			const planningTaskWithCollapseIdsByRunId = new Map();
			const dispatchTaskWithCollapseIdsByRunId = new Map();
			const taskIdToCollapseTaskId = new Map();

			let service;

			CollapsedLinkedTasksService.getTaskIdToCollapseTaskId = function (id) {
				return taskIdToCollapseTaskId.get(id);
			};

			CollapsedLinkedTasksService.generateService = function () {
				if (service) {
					return;
				}
				service = $rootScope.sdk.crossAppTransport.emit(
					CROSS_APP_ACTIONS.GENERATE_COLLAPSE_LINKED_TASKS_SERVICE
				)[0];
				CollapsedLinkedTasksService.subscribeRealtimeEvents();
			};

			CollapsedLinkedTasksService.subscribeRealtimeEvents = function () {
				Task.onCreate(mappedStorableItem =>
					CollapsedLinkedTasksService.onTaskCreatedOrUpdated(mappedStorableItem)
				);
				Task.onUpdate(mappedStorableItem =>
					CollapsedLinkedTasksService.onTaskCreatedOrUpdated(mappedStorableItem)
				);
			};

			CollapsedLinkedTasksService.getPlanningTasks = function () {
				return Array.from(planningTasks.values());
			};

			CollapsedLinkedTasksService.getDispatchTasks = function () {
				return Array.from(dispatchTasks.values());
			};

			CollapsedLinkedTasksService.getCollapseLinkedTasksById = function (id) {
				return collapseLinkTasksMap.get(id);
			};

			CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled = function () {
				const useClusterLinkedOrders =
					MerchantConfigurations.dashboard_ui_configuration?.use_cluster_linked_orders;

				return (
					Authentication.currentUser() &&
					Authentication.currentUser().feature_flags.expose_web_clustering_config_of_sequential_stops &&
					(useClusterLinkedOrders == null ? true : useClusterLinkedOrders)
				);
			};

			CollapsedLinkedTasksService.buildPlanningTasks = function (tasks) {
				if (!service) {
					CollapsedLinkedTasksService.generateService();
				}
				service.buildTasks(
					tasks,
					planningTasks,
					planningTaskWithCollapseIdsByRunId,
					collapseLinkTasksMap,
					taskIdToCollapseTaskId
				);
				return CollapsedLinkedTasksService.getPlanningTasks();
			};

			CollapsedLinkedTasksService.buildDispatchTasks = function (tasks) {
				if (!service) {
					CollapsedLinkedTasksService.generateService();
				}
				service.buildTasks(
					tasks,
					dispatchTasks,
					dispatchTaskWithCollapseIdsByRunId,
					collapseLinkTasksMap,
					taskIdToCollapseTaskId
				);

				return CollapsedLinkedTasksService.getDispatchTasks();
			};

			CollapsedLinkedTasksService.buildTasks = function (item, tasks, origin) {
				if (!CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
					return;
				}
				const collapsedTasks = tasks.filter(task => task.id < 0);
				const tasksWithoutCollapsed = tasks.filter(task => task.id > 0);
				const innerTasks = [];
				collapsedTasks.forEach(collapseTask => {
					const collapseTaskData = collapseLinkTasksMap.get(collapseTask.id);
					innerTasks.push(...collapseTaskData.tasks);
				});
				let updatedTasks = [...tasksWithoutCollapsed, ...innerTasks];
				if (item.value === TaskGroupTypes.Route || item.value === TaskGroupTypes.TeamRoute) {
					if (origin === ORIGIN.DISPATCH) {
						return CollapsedLinkedTasksService.buildDispatchTasks(updatedTasks);
					} else {
						return CollapsedLinkedTasksService.buildPlanningTasks(updatedTasks);
					}
				}
				return updatedTasks;
			};

			CollapsedLinkedTasksService.extractTasksFromCollapsedTasks = tasks => {
				if (!service) {
					CollapsedLinkedTasksService.generateService();
				}

				return service.extractTasksFromCollapsedTasks(tasks, collapseLinkTasksMap);
			};

			CollapsedLinkedTasksService.onTaskCreatedOrUpdated = function (mappedStorableItem) {
				if (!service) {
					CollapsedLinkedTasksService.generateService();
				}
				const updatedTask = mappedStorableItem.current;
				const tasksInCurrentPage = updatedTask.ready_to_execute ? dispatchTasks : planningTasks;
				const tasksNotInCurrentPage = updatedTask.ready_to_execute ? planningTasks : dispatchTasks;
				const tasksInCurrentPageByRunId = updatedTask.ready_to_execute
					? dispatchTaskWithCollapseIdsByRunId
					: planningTaskWithCollapseIdsByRunId;
				const tasksNotInCurrentPageByRunId = updatedTask.ready_to_execute
					? planningTaskWithCollapseIdsByRunId
					: dispatchTaskWithCollapseIdsByRunId;

				service.onTaskCreatedOrUpdated(
					updatedTask,
					tasksInCurrentPage,
					tasksNotInCurrentPage,
					tasksInCurrentPageByRunId,
					tasksNotInCurrentPageByRunId,
					collapseLinkTasksMap,
					taskIdToCollapseTaskId
				);
			};

			CollapsedLinkedTasksService.onTaskDeleted = function (mappedStorableItem) {
				if (!service) {
					CollapsedLinkedTasksService.generateService();
				}
				const deletedTask = mappedStorableItem.current;
				const tasksInCurrentPage = deletedTask.ready_to_execute ? dispatchTasks : planningTasks;
				const tasksInCurrentPageByRunId = deletedTask.ready_to_execute
					? dispatchTaskWithCollapseIdsByRunId
					: planningTaskWithCollapseIdsByRunId;
				service.onTaskDeleted(
					deletedTask,
					tasksInCurrentPage,
					tasksInCurrentPageByRunId,
					collapseLinkTasksMap,
					taskIdToCollapseTaskId
				);
			};

			return CollapsedLinkedTasksService;
		}
	);
