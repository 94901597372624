'use strict';
angular.module('bringgApp').value('TaskGroupTypes', {
	Team: 'Team',
	TeamDriver: 'TeamDriver',
	TeamRoute: 'TeamRoute',
	Driver: 'Driver',
	Route: 'Route',
	Fleet: 'Fleet',
	FleetTeam: 'FleetTeam'
});
