'use strict';

angular.module('bringgApp').constant('TASK_STATUS', {
	CREATED: 0,
	ASSIGNED: 1,
	ON_THE_WAY: 2,
	CHECKED_IN: 3,
	DONE: 4,
	ACCEPTED: 6,
	CANCELLED: 7,
	REJECTED: 8,
	UNACKNOWLEDGED: 9,
	PENDING: 10
});
