'use strict';
/*global app:false */
/*global io:false */

angular.module('bringgApp').factory('BringgSDK', function ($rootScope, $log, $q, $window, ENV_CONFIGURATION) {
	var instanceDefer = $q.defer();
	var socketPromise = $q.defer();
	var bringg; // testing utility
	$window.sdkInstance = instanceDefer.promise;

	function setGlobalConfigValues(config) {
		window['ENV_CONFIG'] = config;
		window['envConfig'] = config;
	}

	/**
	 * testing utility
	 * @return {BringgDashboardSDK}
	 */
	function bringgFacade() {
		return bringg || BringgDashboardSDK;
	}

	function initWithAuthToken(facade, region, authToken) {
		facade
			.initWithAuthToken(region, authToken, window._bringg)
			.then(function (sdk) {
				onSdkInit(facade, sdk);
			})
			.catch(function (e) {
				$log.error('Failed to init SDK with token', e);
				instanceDefer.reject(e);
			});
	}

	function initWithSessionCode(facade, code, session) {
		return facade
			.initWithSessionCode(code, session, window._bringg)
			.then(function (sdk) {
				onSdkInit(facade, sdk);
			})
			.catch(function (e) {
				$log.error('Failed to init SDK with session code', e);
				instanceDefer.reject(e);
			});
	}

	function initWithOneTimeCode(facade, region, token, secret) {
		return facade
			.initWithOneTimeCode(region, token, secret, window._bringg)
			.then(function (sdk) {
				onSdkInit(facade, sdk);
			})
			.catch(function (e) {
				$log.error('Failed to init SDK with one-time code', e);
				instanceDefer.reject(e);
			});
	}

	function onSdkInit(facade, sdk) {
		sdk.session.setEventHandler(function (eventName, message) {
			$rootScope.$broadcast(eventName, message);
		});

		socketPromise.resolve(sdk.session.persistentConnection.connection.socket);
		instanceDefer.resolve(sdk);
		setGlobalConfigValues(facade.getFullConfiguration());
	}

	return {
		initSdkConfiguration: function () {
			bringgFacade().setEnvironment(window['envConfig'].environment);
			var newConfigProvider = bringgFacade().newConfigurationProvider({
				config: window['envConfig'],
				commonRegionConfigKeys: window['COMMON_REGION_CONFIG_KEYS']
			});
			bringgFacade().setConfigurationProvider(newConfigProvider);
		},
		resetSdkPromise: function () {
			instanceDefer = $q.defer();
			socketPromise = $q.defer();
			$window.sdkInstance = instanceDefer.promise;
		},
		// testing utility
		setBringg: function (_bringg) {
			bringg = _bringg;
		},
		/**
		 *
		 * @param {string} region
		 * @param {string} authToken
		 */
		login: function (email, password, merchant_uuid, region, recaptcha_token, recaptcha_qa_token) {
			var facade = bringgFacade();
			facade.delegateLogger($log);

			return facade
				.initWithEmail(
					email,
					password,
					merchant_uuid,
					recaptcha_token,
					recaptcha_qa_token,
					region,
					window._bringg
				)
				.then(function (sdk) {
					sdk.session.setEventHandler(function (eventName, message) {
						$rootScope.$broadcast(eventName, message);
					});

					socketPromise.resolve(sdk.session.persistentConnection.connection.socket);
					instanceDefer.resolve(sdk);
					setGlobalConfigValues(facade.getFullConfiguration());
					return sdk;
				});
		},
		loginWithToken: function (region, authToken) {
			var facade = bringgFacade();

			facade.delegateLogger($log);

			if (window._bringg) {
				initWithAuthToken(facade, region, authToken);
			} else {
				window.initSdk = () => {
					return initWithAuthToken(facade, region, authToken);
				};
			}

			return this.getInstancePromise();
		},

		loginWithSessionCode: function (code, session) {
			var facade = bringgFacade();

			facade.delegateLogger($log);

			if (window._bringg) {
				initWithSessionCode(facade, code, session);
			} else {
				window.initSdk = () => {
					return initWithSessionCode(facade, code, session);
				};
			}

			return this.getInstancePromise();
		},

		loginWithOneTimeCode: function (region, token, secret) {
			var facade = bringgFacade();

			facade.delegateLogger($log);

			if (window._bringg) {
				initWithOneTimeCode(facade, region, token, secret);
			} else {
				window.initSdk = () => {
					return initWithOneTimeCode(facade, region, token, secret);
				};
			}

			return this.getInstancePromise();
		},

		/**
		 * @return {Promise<import('@bringg/dashboard-sdk').BringgDashboardSDK>}
		 */
		getInstancePromise: function () {
			return instanceDefer.promise;
		},

		socket: function () {
			return socketPromise.promise;
		},
		resendConfirmationEmail: function (loginParams) {
			var facade = bringgFacade();
			facade.delegateLogger($log);

			return facade.resendConfirmationEmail({
				region: loginParams.region,
				email: loginParams.email,
				password: loginParams.password,
				merchant_uuid: loginParams.merchantUuid,
				recaptchaToken: loginParams.recaptchaToken,
				recaptcha_qa_token: loginParams.qaToken
			});
		}
	};
});
