'use strict';

angular.module('bringgApp').factory('EditMapOverlayService', function ($q, $uibModal) {
	var EditMapOverlayService = {};
	EditMapOverlayService.open = function (mapOverlay) {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			controller: 'EditMapOverlayController',
			templateUrl: 'scripts/features/configuration/map_overlays/edit-map-overlay.html',
			resolve: {
				mapOverlay: function () {
					return mapOverlay;
				}
			}
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return EditMapOverlayService;
});
