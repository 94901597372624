/*global google:false */
/*global resizeMapByMarkers:false */
/*global createMarker:false */
/*global clearMarkers:false */
/*global center_map:false */

'use strict';

angular
	.module('bringgApp')
	.directive('dispatchMap', function () {
		return {
			replace: true,
			restrict: 'E',
			scope: {
				mapObject: '=?',
				selectedDriver: '=?',
				selectedTeam: '=?',
				selectedTeamsIds: '=?',
				driverStatusFilter: '=?',
				tasksFilter: '=?',
				filterObject: '=?',
				teams: '=?',
				filteredTasks: '=?'
			},
			templateUrl: 'scripts/directives/dispatch-map/dispatch-map.html',
			controller: 'DispatchMapController'
		};
	})
	.controller(
		'DispatchMapController',
		function (
			$scope,
			MerchantConfigurations,
			$q,
			$timeout,
			Employees,
			Authentication,
			Teams,
			$templateRequest,
			Task,
			Tasks,
			TasksLoader,
			map_utils,
			dispatchMapIconsService,
			MapOverlays,
			$compile,
			DEFAULT_RT_THROTTLE,
			Application,
			CrossApplicationService,
			$rootScope,
			TasksData,
			ServiceAreasService
		) {
			$scope.onMapTypeChange = function (mapTypeId) {
				map_utils.saveInitialMapType(mapTypeId);
			};

			let isDispatchMapImprovedFocusEnabled = false;
			//default value
			$scope.mapOptions = {
				zoom: 10,
				center: new google.maps.LatLng(35.784, -78.67),
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_LEFT
				},
				mapTypeId: map_utils.getInitialMapType(),
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				scaleControl: true,
				streetViewControl: true,
				streetViewControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				}
			};
			$scope.markers = {
				drivers: {},
				tasks: {},
				teams: {}
			};
			$scope.inited = false;
			$scope.circle = null;
			$scope.circleMarker = null;
			$scope.infowindow = {};
			$scope.showDriverPhoneInDriverMarkerInMapTab = false;

			$scope.mapClicked = function () {
				$scope.clearCircle();

				if ($scope.infowindow) {
					$scope.infowindow.close();
				}
			};

			$scope.manualMapChange = $scope.$parent.manualMapChange;

			/**
			 * handle marker clicks
			 * @param marker
			 */
			$scope.destinationMarkerClicked = function (marker) {
				var templates = {
					templateUrl: 'scripts/directives/dispatch-map/infoWindows/destinationMarker.html'
				};
				$templateRequest(templates.templateUrl).then(function (html) {
					var scope = $scope.$new(true, $scope);
					scope.task = _.find($scope.tasks, { id: marker.get('id') });
					var template = angular.element(html);
					$compile(template)(scope);

					$timeout(function () {
						$scope.infowindow.setContent(template.html());
						$scope.infowindow.open($scope.map, marker);
					});
				});
			};

			$scope.driverMarkerClicked = function (marker) {
				var templates = {
					templateUrl: 'scripts/directives/dispatch-map/infoWindows/driverMarker.html'
				};
				$templateRequest(templates.templateUrl).then(function (html) {
					var scope = $scope.$new(true, $scope);
					scope.showDriverPhoneInDriverMarkerInMapTab = $scope.showDriverPhoneInDriverMarkerInMapTab;
					scope.driverTasks = Tasks.openForUser(marker.get('id'));
					scope.driver = _.find($scope.drivers, { id: marker.get('id') });

					var template = angular.element(html);
					$compile(template)(scope);

					$timeout(function () {
						$scope.infowindow.setContent(template.html());
						$scope.infowindow.open($scope.map, marker);
					});
				});
			};

			/**
			 * calculate if there a position fix
			 * @param location
			 * @param marker
			 * @returns {boolean}
			 */
			var isPositionDifference = function (location, marker) {
				if (!location || !_.isNumber(location.lat) || !_.isNumber(location.lng)) {
					return false;
				}

				if (!marker || !marker.position) {
					return true;
				}

				// seems each one of them 'round' the fixed number
				var markerPosition = {
					lat: marker.position.lat().toFixed(8),
					lng: marker.position.lng().toFixed(8)
				};

				return markerPosition.lat !== location.lat.toFixed(8) || markerPosition.lng !== location.lng.toFixed(8);
			};

			var centerMapByVisibleMarkers = function () {
				var visibleMarkers = _.filter(
					[]
						.concat(_.values($scope.markers.drivers))
						.concat(_.values($scope.markers.tasks))
						.concat(_.values($scope.markers.teams)),
					{ visible: true }
				);
				resizeMapByMarkers($scope.map, visibleMarkers);
			};

			$scope._closeInfoWindowIfRelatedMarkerIsHidden = function () {
				if (!$scope.infowindow || !$scope.infowindow.anchor || $scope.infowindow.anchor.visible) {
					return;
				}
				$scope.infowindow.close();
			};

			$scope.isTeamVisible = function (team) {
				var selectedTeamsWithChildren = Teams.getTeamIdsWithChildIds($scope.selectedTeamsIds);
				return (
					(_.isEmpty($scope.selectedTeamsIds) && !$scope.emptyFilteringEnabled) ||
					_.includes(selectedTeamsWithChildren, team.id)
				);
			};

			$scope.refreshTeamMarkersByFilters = function (auto_center_map) {
				if ($scope.loading) {
					return;
				}

				_.each($scope.teams, function (team) {
					if (!team.id) {
						return;
					}
					var teamVisible = $scope.isTeamVisible(team);

					var marker = $scope.markers.teams[team.id];
					if (!marker) {
						var visible = teamVisible && _.isNumber(team.lat) && _.isNumber(team.lng);
						$scope.markers.teams[team.id] = dispatchMapIconsService.createMarker(
							$scope.map,
							team.lat,
							team.lng,
							team.name,
							dispatchMapIconsService.getTeamIcon(team),
							team.id,
							null,
							visible
						);
						return;
					}

					if (
						marker.visible !== team &&
						marker.position &&
						!_.isNaN(marker.position.lat()) &&
						!_.isNaN(marker.position.lng())
					) {
						marker.setVisible(teamVisible);
					}
				});

				if (auto_center_map) {
					centerMapByVisibleMarkers();
				}
			};

			var refreshDestinationMarkersByFilters = function (auto_center_map) {
				if ($scope.loading) {
					return;
				}

				var selectedDriverId = $scope.selectedDriver && $scope.selectedDriver.id;
				var selectedTeamIds = $scope.selectedTeamsIds;

				var driverVisibleArgs = {
					selectedDriver: $scope.selectedDriver,
					selectedTeamsIds: $scope.selectedTeamsIds,
					driversStatusFilter: $scope.driverStatusFilter
				};

				var visibleDriverIds = _.map(
					($scope.drivers || []).filter(function (driver, index, allDrivers) {
						return isDriverVisible(driver, allDrivers, driverVisibleArgs);
					}),
					'id'
				);

				_.each($scope.tasks, function (task) {
					var taskVisible = true;
					if ($scope.filteredTasks) {
						taskVisible = $scope.filteredTasks.indexOf(task) >= 0;
					} else {
						if (selectedDriverId || selectedTeamIds) {
							if (task.user_id) {
								taskVisible = _.includes(visibleDriverIds, task.user_id);
							} else if (selectedDriverId) {
								taskVisible = false;
							}
						}

						taskVisible =
							taskVisible && ($scope.tasksFilter ? $scope.tasksFilter(task, $scope.filterObject) : true);
						if (taskVisible && !_.isEmpty(selectedTeamIds)) {
							taskVisible = !!_.intersection(task.team_ids, selectedTeamIds).length;
						}
					}

					var marker = $scope.markers.tasks[task.id];
					if (!marker) {
						var location = map_utils._extractLocationFromTask(task);
						var visible = !!(taskVisible && location && location.lat && location.lng);
						location = location || {};
						$scope.markers.tasks[task.id] = dispatchMapIconsService.createMarker(
							$scope.map,
							location.lat,
							location.lng,
							'',
							dispatchMapIconsService.getTaskIcon(task),
							task.id,
							$scope.destinationMarkerClicked,
							visible
						);
						return;
					}

					if (taskVisible) {
						if (marker.icon !== dispatchMapIconsService.getTaskIcon(task)) {
							marker.setIcon(dispatchMapIconsService.getTaskIcon(task));
						}

						var _location = map_utils._extractLocationFromTask(task);
						if (isPositionDifference(_location, marker)) {
							marker.setPosition(new google.maps.LatLng(_location.lat, _location.lng));
						}
					}

					if (
						marker.visible !== taskVisible &&
						marker.position &&
						!_.isNaN(marker.position.lat()) &&
						!_.isNaN(marker.position.lng())
					) {
						// when hiding the marker of the circle, hide marker as well.
						if ($scope.circleMarker && $scope.circleMarker === marker) {
							$scope.clearCircle();
						}
						marker.setVisible(taskVisible);
					}
				});

				if (auto_center_map) {
					centerMapByVisibleMarkers();
				}

				$scope._closeInfoWindowIfRelatedMarkerIsHidden();
			};

			function isDriverVisible(driver, allDrivers, options) {
				options = _.defaults(options || {}, {
					selectedDriver: null,
					selectedTeamsIds: [],
					driversStatusFilter: null
				});

				if (options.driversStatusFilter && !options.driversStatusFilter(driver, allDrivers, $scope.tasks)) {
					return false;
				}

				if (options.selectedDriver) {
					return options.selectedDriver.id === driver.id;
				}

				var selectedTeamIdsWithChildren = Teams.getTeamIdsWithChildIds(options.selectedTeamsIds);

				return !!(
					(_.isEmpty(options.selectedTeamsIds) && !$scope.emptyFilteringEnabled) ||
					_.intersection(selectedTeamIdsWithChildren, driver.team_ids).length
				);
			}

			/**
			 * hide or show markers by configurations
			 */
			var refreshDriversMarkersByFilters = function (auto_center_map) {
				if ($scope.loading) {
					return;
				}

				var driverVisibleArgs = {
					selectedDriver: $scope.selectedDriver,
					selectedTeamsIds: $scope.selectedTeamsIds,
					driversStatusFilter: $scope.driverStatusFilter
				};

				_.each($scope.drivers, function (driver, i, array) {
					if (!driver.id) {
						return;
					}
					var driverVisible = isDriverVisible(driver, array, driverVisibleArgs);

					var marker = $scope.markers.drivers[driver.id];
					if (!marker) {
						var visible = !!(driverVisible && driver.lat && driver.lng);
						$scope.markers.drivers[driver.id] = dispatchMapIconsService.createMarker(
							$scope.map,
							driver.lat,
							driver.lng,
							driver.name,
							dispatchMapIconsService.getDriverIcon(driver),
							driver.id,
							$scope._handleDriverMarkerClicked,
							visible
						);
						return;
					}

					if (driverVisible) {
						if (dispatchMapIconsService.driverIconNeedsRefresh(driver, marker.icon)) {
							marker.setIcon(dispatchMapIconsService.getDriverIcon(driver));
						}

						if (isPositionDifference(driver, marker)) {
							marker.setPosition(new google.maps.LatLng(driver.lat, driver.lng));
						}
					}

					if (
						marker.visible !== driverVisible &&
						marker.position &&
						!_.isNaN(marker.position.lat()) &&
						!_.isNaN(marker.position.lng())
					) {
						marker.setVisible(driverVisible);
					}
				});

				if (auto_center_map) {
					centerMapByVisibleMarkers();
				}

				$scope._closeInfoWindowIfRelatedMarkerIsHidden();
			};

			$scope.$watch('selectedDriver', function () {
				// zoom in only when there isnt selected Driver
				$scope.refreshMarkers({ auto_center_map: true });
			});
			$scope.$watch('driverStatusFilter', function () {
				$scope.refreshMarkers({ auto_center_map: true });
			});

			$scope.$watch('selectedTeamsIds', function () {
				$scope.refreshMarkers({ auto_center_map: true });
			});

			/**
			 * refresh markers
			 * @param auto_center_map
			 */
			$scope.refreshMarkers = function (options) {
				options = options || {};
				$timeout(function () {
					refreshDestinationMarkersByFilters(options.auto_center_map);
					refreshDriversMarkersByFilters(options.auto_center_map);
					$scope.refreshTeamMarkersByFilters(options.auto_center_map);

					$scope.$broadcast('redrawLegend');
				});
			};

			var throttledRefreshMarkers = _.throttle($scope.refreshMarkers, DEFAULT_RT_THROTTLE);

			/**
			 * on data updates - tasks
			 */
			$scope.onTaskDone = function (event, task) {
				var marker = $scope.markers.tasks[task.id];
				if (marker) {
					marker.setMap(null);
					google.maps.event.clearListeners(marker, 'click');
					delete $scope.markers.tasks[task.id];

					const index = $scope.tasks.findIndex(currentTask => currentTask.id === task.id);

					if (index > -1) {
						$scope.tasks.splice(index, 1);
					}
				}
				throttledRefreshMarkers();
			};

			/**
			 * on data updates - drivers
			 */

			$scope.onDriverDeleted = function (event, driver) {
				var marker = $scope.markers.drivers[driver.id];
				if (marker) {
					marker.setMap(null);
					google.maps.event.clearListeners(marker, 'click');
					delete $scope.markers.drivers[driver.id];
				}

				throttledRefreshMarkers();
			};

			/**
			 * add default location to map
			 */
			$scope.setMapLocation = function () {
				// default merchant coordinates
				var lat = MerchantConfigurations.lat;
				var lng = MerchantConfigurations.lng;

				// next, take team coordinates
				if (MerchantConfigurations.enable_teams) {
					var team = _.find($scope.teams, { id: _.last(Authentication.currentUser().team_ids) });
					if (team && team.lat && team.lng) {
						lat = team.lat;
						lng = team.lng;
					}
				}

				$timeout(function () {
					$scope.map.setCenter(new google.maps.LatLng(lat, lng));
				});
			};

			/**
			 * draw over lays
			 */
			var drawOverlays = function () {
				MapOverlays.all(function (result) {
					var mapOverlays = result.map_overlays;
					for (var i = 0; i !== mapOverlays.length; i++) {
						var mapOverlay = mapOverlays[i];
						if (!mapOverlay.hidden && mapOverlay.map_overlay) {
							$scope.map.data.addGeoJson(mapOverlay.map_overlay);
							// mapOverlay.map_overlay.features.forEach(addFeatureLabel);
							$scope.map.data.setStyle(function (feature) {
								return {
									fillColor: feature.getProperty('fill'),
									fillOpacity: feature.getProperty('fill-opacity'),
									strokeColor: feature.getProperty('stroke'),
									strokeWeight: feature.getProperty('stroke-width'),
									strokeOpacity: feature.getProperty('stroke-opacity')
								};
							});
						}
					}
				});
			};

			$scope.sendDataToMapbox = _.debounce(
				function (tasks) {
					CrossApplicationService.emit('DISPATCH_MAPBOX_DATA_RESPONSE', {
						openTasks: tasks
					});
				},
				500,
				{ maxWait: 1500 }
			);

			/**
			 * load map and data
			 */
			var init = function () {
				var loadingCounters = 2;
				$scope.loading = true;
				$scope.merchantConfiguration = MerchantConfigurations;

				Authentication.featureFlags().then(function (featureFlags) {
					$scope.showDriverPhoneInDriverMarkerInMapTab =
						featureFlags.show_driver_phone_in_driver_marker_in_map_tab || false;
					$scope.mapboxEnabled = featureFlags.enable_mapbox || false;
					isDispatchMapImprovedFocusEnabled = featureFlags.dispatch_map_improved_focus;
					if ($scope.mapboxEnabled) {
						var mapboxLoaded = false;
						var emptyOpenTasksList = false;
						var tasksToSend = [];

						$scope.tasks = TasksData.getOpenTasks();

						TasksLoader.loadOpenForTable({ columnsKey: 'task_fields' });

						var sendTasks = function () {
							if (tasksToSend.length || emptyOpenTasksList) {
								$scope.sendDataToMapbox(tasksToSend);
								tasksToSend = [];
							}
						};

						var mapboxSelectedTask = function (event, selectedTask) {
							CrossApplicationService.emit('DISPATCH_MAPBOX_SELECTED_TASK', {
								selectedTask: selectedTask
							});
						};

						$rootScope.$on('done fetching open tasks pages', function (event, tasks) {
							if (!tasks.length) {
								emptyOpenTasksList = true;
							}

							tasksToSend = tasks;

							if (mapboxLoaded) {
								sendTasks();
							}
						});

						$rootScope.$on('task list next page update', function (event, tasks) {
							if (!tasks.length) {
								emptyOpenTasksList = true;
							}

							tasksToSend = tasks;

							if (mapboxLoaded) {
								sendTasks();
							}
						});

						$rootScope.$on('task list update', function () {
							$scope.sendDataToMapbox(TasksData.getOpenTasks());
						});

						CrossApplicationService.on('DISPATCH_MAPBOX_DATA_REQUEST', function () {
							mapboxLoaded = true;

							sendTasks();
						});

						$scope.$on('dispatchMapTaskSelected', mapboxSelectedTask);
					}
				});

				$scope.showTimeToBase = MerchantConfigurations && MerchantConfigurations.show_eta_to_home;

				$scope.onMapLoaded = function (map) {
					if (!map) {
						return;
					}

					$scope.$emit('map_initiated', map);
					$scope.map = map;
					loadingCounters--;

					var centerControlDiv = document.createElement('div');
					var centerControl = new map_utils.CenterControl(centerControlDiv, $scope.map);
					centerControlDiv.index = 1;
					$scope.map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

					var elementId = 'defaultZoomButton';
					if (!document.getElementById(elementId)) {
						var defaultZoomButton = new map_utils.createDefaultZoomButton(
							$scope.map,
							elementId,
							centerMapByVisibleMarkers,
							setAutomaticResize
						);
						$scope.map.controls[google.maps.ControlPosition.TOP_LEFT].push(defaultZoomButton);
					}

					if (loadingCounters === 0) {
						$timeout(render);
					}
				};

				TasksLoader.loadOpenForTable({ columnsKey: 'task_fields' }).then(function () {
					$scope.refreshMarkers({ auto_center_map: true });
				});

				var promises = [
					Employees.driversWithCustomers({}),
					Teams.all(),
					Authentication.featureFlags(),
					ServiceAreasService.loadAll()
				];

				// load all employees and teams
				$q.all(promises).then(function (results) {
					$scope.drivers = results[0];
					$scope.teams = results[1];
					$scope.emptyFilteringEnabled = results[2].empty_filtering;
					$scope.tasks = TasksData.getOpenTasks();
					loadingCounters--;
					if (loadingCounters === 0 && !$scope.mapboxEnabled) {
						$timeout(render);
					}
				});

				$scope.$watch(
					'filterObject',
					function (filterObject) {
						var getFilteredTasks = function (filterObject, tasks) {
							if (_.isEmpty(filterObject.selectedTeams)) {
								return tasks;
							}
							var selectedTeams = _.keyBy(filterObject.selectedTeams);
							return _.filter(tasks, function (task) {
								return _.some(task.team_ids, function (teamId) {
									return teamId in selectedTeams;
								});
							});
						};

						if ($scope.mapboxEnabled) {
							var filteredTasks = getFilteredTasks(filterObject, TasksData.getOpenTasks());
							$scope.sendDataToMapbox(filteredTasks);
						} else {
							$scope.refreshMarkers({ auto_center_map: true });
						}
					},
					true
				);

				$scope.$watch('filteredTasks', throttledRefreshMarkers);
			};

			$scope.clearCircle = function () {
				$scope.circleMarker = null;
				if ($scope.circle) {
					$scope.circle.setVisible(false);
				}
			};

			$scope.onTaskSelect = function (event, selectedTask) {
				if (!selectedTask) {
					$scope.clearCircle();
					return;
				}

				var marker = $scope.markers.tasks[selectedTask.id];
				var markerPosition = marker && marker.getPosition();
				var markerHasLocation =
					!_.isNil(markerPosition) && !_.isNil(markerPosition.lat()) && !_.isNil(markerPosition.lng());
				if (!markerHasLocation) {
					$scope.clearCircle();
					// goto default view
					$scope.setMapLocation();
					return;
				}

				$scope.circleMarker = marker;
				var latLng = new google.maps.LatLng(markerPosition.lat(), markerPosition.lng());
				$scope.circle.setPosition(latLng);
				$scope.circle.setVisible(true);
				if (isDispatchMapImprovedFocusEnabled) {
					$scope.map.setZoom(11);
				}
				$scope.map.panTo(latLng);
			};

			/**
			 * init all drivers
			 */
			var initDriversMarkers = function () {
				_.each($scope.drivers, function (driver) {
					var visible = !!(
						driver.lat &&
						driver.lng &&
						driver.active_shift_id &&
						Employees.filterIsOnline(driver)
					);
					$scope.markers.drivers[driver.id] = dispatchMapIconsService.createMarker(
						$scope.map,
						driver.lat,
						driver.lng,
						driver.name,
						dispatchMapIconsService.getDriverIcon(driver),
						driver.id,
						$scope.driverMarkerClicked,
						visible
					);
				});
			};

			/**
			 * init all teams
			 */
			var initTeamsMarkers = function () {
				_.chain($scope.teams)
					.filter(function (team) {
						var visible = _.isNumber(team.lat) && _.isNumber(team.lng);
						return (
							visible && (_.isEmpty($scope.selectedTeamsIds) || $scope.selectedTeamsIds.includes(team.id))
						);
					})
					.each(function (team) {
						$scope.markers.teams[team.id] = dispatchMapIconsService.createMarker(
							$scope.map,
							team.lat,
							team.lng,
							team.name,
							dispatchMapIconsService.getTeamIcon(team),
							team.id,
							$scope.teamMarkerClicked,
							true
						);
					})
					.value();
			};

			/**
			 * init all tasks
			 */
			var initTasksMarkers = function () {
				_.each($scope.tasks, function (task) {
					var location = map_utils._extractLocationFromTask(task);
					var visible = !!(location && location.lat && location.lng);
					location = location || {};
					$scope.markers.tasks[task.id] = dispatchMapIconsService.createMarker(
						$scope.map,
						location.lat,
						location.lng,
						'',
						dispatchMapIconsService.getTaskIcon(task),
						task.id,
						$scope.destinationMarkerClicked,
						visible
					);
				});
			};

			/**
			 * render it all
			 */
			var render = function () {
				$scope.setMapLocation();

				if (!_.isUndefined(google.maps.InfoWindow)) {
					$scope.infowindow = new google.maps.InfoWindow({});
				}

				if (Task.shouldUseNewApi() && $scope.tasks) {
					$scope.tasks.length = 0;
					Array.prototype.push.apply($scope.tasks, TasksData.getOpenTasks());
				}

				initDriversMarkers();
				initTasksMarkers();
				initTeamsMarkers();
				drawOverlays();

				$scope.circle = new google.maps.Marker({
					map: $scope.map,
					clickable: false,
					icon: {
						anchor: new google.maps.Point(140, 150),
						scaledSize: new google.maps.Size(280, 280),
						scale: 0.6,
						url: 'images/circle.svg'
					},
					zIndex: 1001,
					optimized: true
				});

				if (Task.shouldUseNewApi() && $scope.tasks) {
					$scope.tasks.length = 0;
					Array.prototype.push.apply($scope.tasks, TasksData.getOpenTasks());
				}

				$scope.$on('task list update', () => {
					if (Task.shouldUseNewApi() && $scope.tasks) {
						$scope.tasks.length = 0;
						Array.prototype.push.apply($scope.tasks, TasksData.getOpenTasks());
					}

					throttledRefreshMarkers();
				});
				var firstLazyTaskUpdateUnsubscribe = $scope.$on('lazy task list update', function () {
					$scope.refreshMarkers({ auto_center_map: true });
					firstLazyTaskUpdateUnsubscribe();
				});
				$scope.$on('task done', $scope.onTaskDone);

				$scope.$on('newEmployeeAdded', throttledRefreshMarkers);
				$scope.$on('employeeUpdated', throttledRefreshMarkers);
				$scope.$on('employeeDeleted', $scope.onDriverDeleted);
				$scope.$on('dispatchMapTaskSelected', $scope.onTaskSelect);
				/**
				 * filters
				 */

				$timeout(function () {
					$scope.loading = false;
					$scope.refreshMarkers({ auto_center_map: true });

					$timeout(function () {
						$scope.inited = true;
					});
				});
			};

			init();
		}
	);
