'use strict';

angular.module('bringgApp').directive('optimizeSelectRoleDrivers', function () {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			userType: '='
		},
		templateUrl: 'scripts/features/optimize/steps/select-role-drivers.html',
		link: function (scope) {
			scope.filterStr = '';
			scope.userType.fulfilled = !scope.userType.quantity;
			scope.updateSelectionCount = function () {
				scope.userType.fulfilled =
					_.filter(scope.userType.drivers, 'selected').length === scope.userType.quantity;
			};

			scope.updateSelectionCount();
		}
	};
});
