'use strict';

angular.module('bringgApp.directives').directive('phoneNumberValidator', function ($q, Employee, $timeout) {
	return {
		require: 'ngModel',
		link: function (scope, element, attrs, ngModel) {
			ngModel.$asyncValidators.phone = function (modelValue, viewValue) {
				if (!modelValue || modelValue.length === 0) {
					return $q.resolve(true);
				}

				return Employee.validatePhone(modelValue).then(function (response) {
					$timeout(function () {
						ngModel.$setValidity('phone', response);
					});

					// return response.phone_valid ? $q.resolve(true) : $q.reject(false);
					return $q.resolve(true);
				});
			};
		}
	};
});
