'use strict';

angular.module('bringgApp.directives').directive('customerTooltip', function () {
	return {
		templateUrl: 'scripts/directives/customer-tooltip/customer-tooltip-directive.html',
		replace: true,
		restrict: 'E',
		controller: function ($scope) {
			function formatTimeRange(startTime, endTime) {
				var formattedStartTime = moment.utc(startTime).format('HH:mm');
				var formattedEndTime = moment.utc(endTime).format('HH:mm');
				return formattedStartTime + ' - ' + formattedEndTime;
			}

			$scope.formatDay = function (dayOfWeekNum) {
				return moment()
					.isoWeekday(dayOfWeekNum + 1)
					.format('ddd');
			};

			$scope.formatAvailabilityHours = function (availabilityHours) {
				var formattedAvailabilityHours = {};
				var sortedAvailabilityHours = _.orderBy(availabilityHours, ['day_of_week', 'start_time']);

				sortedAvailabilityHours.forEach(function (availabilityHour) {
					var formattedDay = $scope.formatDay(availabilityHour.day_of_week);

					if (!formattedAvailabilityHours[formattedDay]) {
						formattedAvailabilityHours[formattedDay] = '';
					} else {
						formattedAvailabilityHours[formattedDay] += ', ';
					}

					formattedAvailabilityHours[formattedDay] += formatTimeRange(
						availabilityHour.start_time,
						availabilityHour.end_time
					);
				});

				return formattedAvailabilityHours;
			};
		}
	};
});
