'use strict';

angular.module('bringgApp').service('TimeRangeOverlapChecker', function () {
	/* https://stackoverflow.com/questions/32056952/algorithm-to-detect-overlapping-of-dates-in-list */
	this.getOverlappedIds = function (items, startParam, endParam, idParam) {
		const sortedItemsByTime = _.sortBy(items, [startParam, endParam]);

		let currentRange;
		let currentItems = [];
		const totalOverlappedItems = [];

		sortedItemsByTime.forEach(item => {
			const newRange = { start: _.get(item, startParam), end: _.get(item, endParam) };
			if (!currentRange) {
				currentRange = newRange;
				currentItems.push(item);
			} else {
				if (areRangesOverlapped(newRange, currentRange)) {
					currentRange = getExpandedRange(currentRange, newRange);
					currentItems.push(item);
				} else {
					currentRange = newRange;
					if (currentItems.length > 1) {
						totalOverlappedItems.push(...currentItems);
					}
					currentItems = [item];
				}
			}
		});
		if (currentItems.length > 1) {
			totalOverlappedItems.push(...currentItems);
		}

		return totalOverlappedItems.map(item => _.get(item, idParam));
	};

	function areTimesOverlapped(a_start, a_end, b_start, b_end) {
		if (a_start >= b_start && a_start < b_end) return true; // a starts in b
		if (b_start >= a_start && b_start < a_end) return true; // b starts in a

		return false;
	}

	function areRangesOverlapped(newRange, currentRange) {
		const a_start = newRange.start;
		const a_end = newRange.end;

		const b_start = currentRange.start;
		const b_end = currentRange.end;

		return areTimesOverlapped(a_start, a_end, b_start, b_end);
	}

	function getExpandedRange(currentRange, newRange) {
		const currentStart = currentRange.start;
		const currentEnd = currentRange.end;
		const newStart = newRange.start;
		const newEnd = newRange.end;

		const minStart = currentStart < newStart ? currentStart : newStart;
		const maxEnd = currentEnd > newEnd ? currentEnd : newEnd;
		return { start: minStart, end: maxEnd };
	}
});
