'use strict';
/*global app:false */
/*global io:false */

angular.module('bringgApp').factory('SocketPubSub', function ($rootScope, $log, ClientStatsService, BringgSDK) {
	var socket,
		bringgUserId,
		connected = false,
		loggedIn = false,
		loggingIn = false,
		disconnectedAt = null,
		gracePeriod = 3,
		loggingInTimeout = null,
		firstLogin = true;

	$rootScope.announcement_exists = false;
	$rootScope.announcement = '';

	function doUserLoggedInEvent(isLoggedIn) {
		if (isLoggedIn && !loggedIn && !loggingIn) {
			loggingIn = true;
			if (!loggingInTimeout) {
				loggingInTimeout = setTimeout(function () {
					loggingInTimeout = null;
					if (!loggedIn && loggingIn) {
						$log.debug('Logging in for more than 10 seconds but still not logged in, restarting');
						loggingIn = false;
					}
				}, 10000);
			}
		}
	}

	var bucketEmtied = true;
	var callbacks = [];
	var bucket = [];
	var initEvents = [
		'new task',
		'task removed',
		'task done',
		'waypoint updated',
		'waypoint deleted',
		'waypoint added'
	];

	//this will purge the events from the bucket once the CACHE_REALTIME_EVENTS changes from true to false (only)
	$rootScope.$watch('CACHE_REALTIME_EVENTS', function (newValue, oldValue) {
		if (newValue === oldValue) {
			return;
		}

		if (newValue && !oldValue) {
			//meaning it was changed from no caching to start cachine
			bucketEmtied = false; //set the bucket is still waiting for being emptied
			return;
		}

		//if we got here meaning !newValue && oldValue
		//meaning it was do caching and was change to stop caching
		if (!bucket.length) {
			bucketEmtied = true; //set the bucket emptied as we did not have any events in queue
			$log.info('nothing to purge from bucket');
			return;
		}

		$log.info('purging ' + bucket.length + ' events');
		while (bucket.length) {
			var event = bucket.shift();
			event.callback.apply(socket, event.arguments);
		}

		bucketEmtied = true;
		$log.info('purging finished');
	});

	function onServerEvent(event, callback) {
		callbacks.push({
			event: event,
			actualCallback: function () {
				//we will cache the events while
				//1. CACHE_REALTIME_EVENTS is true
				//2. bucket was not emptied yet
				//3. and the event type is related to tasks updates
				if (($rootScope.CACHE_REALTIME_EVENTS || !bucketEmtied) && _.includes(initEvents, event)) {
					$log.info('CACHE_REALTIME_EVENTS event: ' + event);
					bucket.push({ callback: callback, arguments: arguments, event: event });
				} else {
					callback.apply(socket, arguments);
				}
			},
			callback: callback
		});
		return callbacks[callbacks.length - 1].actualCallback;
	}

	function websocketsLogin() {
		if (connected) {
			return;
		}

		connected = true;
		$log.info('Real time connection established, waiting for login notification..[' + socket.id + ']');
		$rootScope.$watch($rootScope.isLoggedIn, function () {
			$log.log('Got login notification: ' + $rootScope.isLoggedIn);
			doUserLoggedInEvent($rootScope.isLoggedIn);
		});
	}

	var currentGracePeriodIdx = 0,
		lastAlertSent = null,
		timeElapsed;

	function resetTimers() {
		currentGracePeriodIdx = 0;
		lastAlertSent = null;
		timeElapsed = undefined;
	}

	setInterval(function () {
		if ($rootScope.isLoggedIn && connected) {
			if (!loggedIn) {
				doUserLoggedInEvent($rootScope.isLoggedIn);
			}
		}
	}, 10000);

	function resetRealtimeAlerts() {
		lastAlertSent = null;
		$rootScope.$broadcast('remove realtime warning', 0);
	}

	var MIN_SECOND_TIME = 15;
	var MAX_SECOND_TIME = 20;

	var randomDelay = Math.ceil((Math.random() * (MAX_SECOND_TIME - MIN_SECOND_TIME + 1) + MIN_SECOND_TIME) * 1000);

	setInterval(function () {
		if ($rootScope.isLoggedIn) {
			if (socket && !socket.connected && disconnectedAt) {
				timeElapsed = Date.now() - disconnectedAt;
				$log.warn('Detected long time disconnection');
				if (currentGracePeriodIdx < gracePeriod) {
					currentGracePeriodIdx++;
				} else {
					currentGracePeriodIdx = 0;
					$rootScope.$broadcast('realtime disconnected for too long');
				}
			}

			if (!_.isNull(lastAlertSent) && timeElapsed < 30000) {
				resetRealtimeAlerts();
			} else if (timeElapsed > 30000 && timeElapsed < 45000) {
				$log.error('Real time disconnected for [' + timeElapsed + ' ms], need to review what is going on');
			} else if (timeElapsed > 45000 && timeElapsed < 75000) {
				$log.error(
					'Warning Real time disconnected for [' + timeElapsed + ' ms], need to review what is going on'
				);
				$rootScope.$broadcast('realtime warning', { level: 'warning', reconnectAttemptCount: 0 });
				lastAlertSent = new Date();
			} else if (timeElapsed > 75000) {
				$log.error(
					'Danger Real time disconnected for [' + timeElapsed + ' ms], need to review what is going on'
				);
				$rootScope.$broadcast('realtime warning', { level: 'danger', reconnectAttemptCount: 0 });
				lastAlertSent = new Date();
			}
		}
	}, randomDelay);

	$rootScope.$on('loggedin', function () {
		connectSocket();
		firstLogin = false;
	});

	$rootScope.$on('loggedout', function (nothing, newAlert) {
		if (firstLogin) {
			return;
		}

		$log.info('Services - Logged out received, loggin out of websocket');
		try {
			callbacks = [];
			socket.disconnect();
			setTimeout(function () {
				socket.removeAllListeners();
				resetTimers();
			}, 2000);
		} catch (e) {
			console.log("couldn't disconnect", e);
		}
	});

	var socketConnectedInitialized = false;
	function setListeners() {
		disconnectedAt = null;
		socket.connecting = false;
		resetRealtimeAlerts();
		ClientStatsService.socketConnected();

		if (socketConnectedInitialized) {
			return;
		}

		socketConnectedInitialized = true;

		socket.on('disconnect', function () {
			disconnectedAt = Date.now();
			ClientStatsService.socketDisconnected();
			$log.debug('Disconnected received');
			connected = false;
			loggedIn = false;
			loggingIn = false;
			bringgUserId = null;
		});

		socket.on('announcement', function (data) {
			$rootScope.announcement_exists = true;
			$rootScope.announcement = data;
		});

		socket.on('employee announcement', function () {
			$log.info('user: ' + $rootScope.sdk.session.user.id);
			var announcement = arguments[0];

			if (announcement.id === $rootScope.sdk.session.user.id) {
				$rootScope.announcement_exists = true;
				$rootScope.announcement = announcement.message;
			}
		});

		$rootScope.$on('subscribed as admin', function (connectData) {
			resetRealtimeAlerts();
			$log.info('User connected (' + connectData.bringg_user_id + ') Subscribing as admin with data');
			loggedIn = true;
			loggingIn = false;
			loggingInTimeout = null;
			bringgUserId = connectData.bringg_user_id;
			$rootScope.CACHE_REALTIME_EVENTS = false;
		});

		callbacks.forEach(function (callback) {
			if (_.isUndefined(socket._callbacks[callback.event])) {
				socket.on(callback.event, callback.actualCallback);
			}
		});

		loggingInTimeout = null;

		websocketsLogin();
		doUserLoggedInEvent($rootScope.isLoggedIn);
	}

	function connectSocket() {
		BringgSDK.socket().then(function (resolvedSocket) {
			socket = resolvedSocket;
			if (socket.connected) {
				setListeners();
			}
			socket.on('connect', setListeners);

			socket.on('connecting', function (transport_type) {
				$log.info('Connecting  with transport: ' + transport_type);
			});

			socket.on('reconnecting', function () {
				socket.connecting = true;
			});
		});
	}

	connectSocket();

	var messageCounter = 0;

	return {
		on: function (eventName, callback) {
			BringgSDK.socket().then(function () {
				socket.on(eventName, onServerEvent(eventName, callback));
			});
		},
		removeListener: function (eventName, removedCallback) {
			for (var callbackIdx = 0; callbackIdx < callbacks.length; callbackIdx++) {
				if (callbacks[callbackIdx].callback === removedCallback) {
					socket.removeListener(eventName, callbacks[callbackIdx].actualCallback);
					callbacks.splice(callbackIdx, 1);
				}
			}
		},
		emit: function (eventName, data, callback) {
			BringgSDK.socket().then(function () {
				socket.emit(eventName, data, function () {
					var args = arguments;
					if (callback) {
						callback.apply(socket, args);
					}
				});
			});
		},
		isConnected: function () {
			return socket && socket.connected;
		},
		currentTransport: function () {
			if (socket && socket.connected && socket.io.engine && socket.io.engine.transport) {
				return socket.io.engine.transport.name;
			}
			return 'none';
		}
	};
});
