'use strict';
/*global google:false */

angular.module('bringgApp').factory('TravelTimeService', function ($q, $timeout, MerchantConfigurations) {
	var defaultOptions = {
		travelMode: google.maps.TravelMode.DRIVING,
		unitSystem: google.maps.UnitSystem.METRIC,
		durationInTraffic: true,
		avoidHighways: true,
		avoidTolls: true
	};

	var service = new google.maps.DistanceMatrixService();

	var calculate = function (originLat, originLng, destinationLat, destinationLng, options) {
		var deferred = $q.defer();

		if (!originLat || !originLng || !destinationLat || !destinationLng) {
			deferred.reject('missing arguments');
			return deferred.promise;
		}
		var origin = new google.maps.LatLng(originLat, originLng);
		var destination = new google.maps.LatLng(destinationLat, destinationLng);

		var params = _.chain({
			origins: [origin],
			destinations: [destination]
		})
			.extend(defaultOptions)
			.extend(options)
			.value();

		service.getDistanceMatrix(params, function (response, status) {
			if (status !== google.maps.DistanceMatrixStatus.OK) {
				return deferred.reject(status);
			}
			var duration = response.rows[0].elements[0].duration;
			if (!duration || !duration.value) {
				return deferred.reject('missing duration');
			}
			return deferred.resolve(duration.value);
		});
		return deferred.promise;
	};

	var calculateWithCacheCleanTimers = {};

	var calculateWithCache = _.memoize(calculate, function (
		originLat,
		originLng,
		destinationLat,
		destinationLng,
		options
	) {
		options = options || {};
		var hashKey = [
			Number(originLat || 0).toFixed(3),
			Number(originLng || 0).toFixed(3),
			destinationLat,
			destinationLng,
			options.travelMode
		].join('|');
		// clear cache for this result after 10 minutes
		setClearCalculateCacheResultsTimer(hashKey);
		return hashKey;
	});

	var setClearCalculateCacheResultsTimer = function (hashKey) {
		// timer to clean result already exists
		if (calculateWithCacheCleanTimers[hashKey]) {
			return;
		}

		// in 10 minutes, clean cached result for this coordinates
		calculateWithCacheCleanTimers[hashKey] = $timeout(function () {
			delete calculateWithCache.cache[hashKey];
			delete calculateWithCacheCleanTimers[hashKey];
		}, 600000);
	};

	return {
		calculate: calculate,
		calculateWithCache: calculateWithCache
	};
});
