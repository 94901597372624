'use strict';

angular.module('bringgApp').controller('RequestDispatchController', function ($scope, WEB_API_URL, $http) {
	$scope.data = {
		user_id: 1
	};
	$scope.requestDispatch = function () {
		$http
			.get(WEB_API_URL + '/dispatcher/request_dispatch?user_id=' + $scope.data.user_id)
			.success(function (data) {
				$scope.customers = data;
			})
			.error(function (data, status) {
				console.error('Error getting customers - ' + data);
			});
	};
});
