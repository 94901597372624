'use strict';

angular
	.module('bringgApp')
	.directive(
		'fixGeocodingAlertVer2',
		function ($rootScope, $timeout, $location, FixGeocodingModalService, FixGeocodingDataService) {
			return {
				restrict: 'E',
				templateUrl: 'scripts/new_navigation/geocoding_ver2/fix-geocoding-alert-ver2.html',
				scope: {
					// loadTasks - should we load the list of not goeocded
					// tasks
					loadTasks: '=',
					tasks: '=?',
					badgeType: '@',
					onWayPointsLengthChanged: '&'
				},
				link: function (scope, element) {
					scope.notGeocodedWayPoints = [];

					scope.openFixWayPointsGeocodingModal = function () {
						FixGeocodingModalService.open(scope.notGeocodedWayPoints);
					};

					function updateNotGeocodedWayPoints(tasks) {
						scope.notGeocodedWayPoints = FixGeocodingDataService.findWayPointsWithoutGeolocation(tasks);
					}

					function listenForTaskChanges() {
						scope.$watch('tasks', function () {
							if (_.isEmpty(scope.tasks)) return;

							updateNotGeocodedWayPoints(scope.tasks);
						});
					}

					function getCurrentStatus() {
						var currentLocation = $location.path();

						if (currentLocation === '/planning/') {
							return 'planning';
						} else if (currentLocation === '/map/' || currentLocation === '/list/') {
							return 'open';
						}

						return null;
					}

					function onNotGeocodedWaypointsUpdate(evt, notGeocodedWayPoints) {
						scope.notGeocodedWayPoints = notGeocodedWayPoints;
						$timeout(function () {
							scope.$apply();
						});
					}

					function fetchTasks() {
						var status = getCurrentStatus();

						if (status) {
							scope.notGeocodedWayPoints = FixGeocodingDataService.all(status);
						}
					}

					function initTasks() {
						fetchTasks();

						scope.$on('not geocoded waypoints update', onNotGeocodedWaypointsUpdate);

						// Listening to location change success here -
						// This will fire only for moving between dispatch/planning
						scope.$on('$locationChangeSuccess', fetchTasks);

						scope.$on('$destroy', function () {
							FixGeocodingDataService.dispose();
						});
					}

					function init() {
						if (!scope.loadTasks) {
							// listen for `scope.tasks` changes
							listenForTaskChanges();
						} else {
							// fetch not geocoded tasks from remote
							initTasks();
						}

						element.on('click', scope.openFixWayPointsGeocodingModal);
					}

					scope.$watch('notGeocodedWayPoints', newVal => {
						scope.onWayPointsLengthChanged({ length: newVal.length });
					});

					init();
				}
			};
		}
	);
