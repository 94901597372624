'use strict';
/*global ov_widget:false */

function WidgetsTaskslatelastmonthCtrl($scope, $rootScope, Authentication, ov_widget, $routeParams) {
	$scope.value = 0;

	$rootScope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
		if (isLoggedIn) {
			ov_widget.registerCallback(
				'WidgetOpenTasksController',
				'merchant:' + Authentication.currentUser().merchant.id + ':times_late_last_30_days',
				'times_late_last_30_days ' + $routeParams.id,
				function (data) {
					if (data.data === null) {
						return;
					}

					var currentdate = new Date();
					$scope.updatedAtMessage =
						currentdate.getHours() + ':' + currentdate.getMinutes() + ':' + currentdate.getSeconds();

					$scope.value = data.data;
				}
			);
		}
	});
}
// ]);

WidgetsTaskslatelastmonthCtrl.$inject = ['$scope', '$rootScope', 'Authentication', 'ov_widget', '$routeParams'];
