'use strict';

angular
	.module('bringgApp')
	.controller(
		'CustomerTasksController',
		function CustomerTasksController(
			$scope,
			$routeParams,
			Tasks,
			$rootScope,
			Shift,
			dialogSrv,
			Task,
			Employee,
			ITEMS_PER_PAGE,
			Customers,
			bringg_utils,
			$translate
		) {
			$scope.currentPage = 0;
			$scope.numPerPage = ITEMS_PER_PAGE;
			$scope.totalItems = 0;
			$scope.allTasks = [];

			$scope.getStatusFromTask = bringg_utils.getStatusFromTask;

			$rootScope.$watch($rootScope.isLoggedIn, function () {
				if ($rootScope.isLoggedIn) {
					Customers.get($routeParams.id, function (data) {
						$scope.customer = data;
					});

					Customers.tasks({ id: $routeParams.id, page: 0 }, function (data) {
						$scope.allTasks[1] = data;
						$scope.currentPage = 1;
					});

					Customers.totalTasks({ id: $routeParams.id }, function (result) {
						$scope.totalItems = result.count;
					});
				}
			});

			$scope.headers = [
				['id', $translate.instant('CUSTOMER_ORDERS.ID')],
				['title', $translate.instant('CUSTOMER_ORDERS.TITLE')],
				['name', $translate.instant('CUSTOMER_ORDERS.NAME')],
				['phone', $translate.instant('CUSTOMER_ORDERS.PHONE')],
				['address', $translate.instant('CUSTOMER_ORDERS.ADDRESS')],
				['scheduled_at', $translate.instant('CUSTOMER_ORDERS.SCHEDULED_AT')],
				['status', $translate.instant('CUSTOMER_ORDERS.STATUS')],
				['driver', $translate.instant('CUSTOMER_ORDERS.DRIVER')]
			];

			$scope.$watch('currentPage + numPerPage', function () {
				var begin = ($scope.currentPage - 1) * $scope.numPerPage,
					end = begin + $scope.numPerPage;

				if ($scope.currentPage === 0) {
					return;
				}

				$scope.tasks = $scope.allTasks[$scope.currentPage];

				if (!$scope.tasks) {
					var bringPage = $scope.currentPage;
					Customers.tasks({ id: $routeParams.id, page: bringPage }, function (data) {
						$scope.tasks = data;
						$scope.allTasks[bringPage] = data;
					});
				}
			});
		}
	);
