'use strict';
/* global google:false */
angular
	.module('bringgApp')
	.controller(
		'AddTeamController',
		function ($scope, $uibModalInstance, Teams, toastr, MerchantConfigurations, $rootScope) {
			$scope.merchantConfigurations = MerchantConfigurations;

			$scope.close = function () {
				$uibModalInstance.dismiss();
			};

			$scope.team = {};

			$scope.sending = false;

			$scope.save = function () {
				if ($scope.team.name) {
					Teams.all(function (teams) {
						var externalIdOccupied = teams.some(function (team) {
							return $scope.team.external_id && team.external_id === $scope.team.external_id;
						});

						if (externalIdOccupied) {
							// exact copy of server error, texting might be better
							toastr.error(
								'External ID duplicate existing, please provide another value',
								'Error - bad format'
							);
						} else {
							$scope.sending = true;
							Teams.add($scope.team, function (response) {
								if (response.success) {
									toastr.success('Team was added successfully!');
									$uibModalInstance.close();
								} else {
									$scope.sending = false;
									toastr.error(response.message, 'Error - missing data or bad format');
								}
							});
						}
					});
				}
			};

			$scope.handleAddressSelected = function (item) {
				if (item) {
					$scope.team.lat = item.geometry.location.lat();
					$scope.team.lng = item.geometry.location.lng();
					$scope.team.address = item.formatted_address;
				} else {
					$scope.team.lat = null;
					$scope.team.lng = null;
					$scope.team.address = null;
				}
			};
		}
	);
