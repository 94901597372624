'use strict';
/*global escape:false */
angular
	.module('bringgApp')
	.factory('ClientStatsServiceBase', function () {
		var ClientStatsServiceBase = {};
		ClientStatsServiceBase.logs = [];
		ClientStatsServiceBase._maxLogSize = 200;

		ClientStatsServiceBase.addLog = function (log) {
			if (ClientStatsServiceBase.logs.length === ClientStatsServiceBase._maxLogSize) {
				ClientStatsServiceBase.logs.shift();
			}
			ClientStatsServiceBase.logs.push(log);
		};

		return ClientStatsServiceBase;
	})
	.factory('ClientStatsService', function ($window, ClientStatsServiceBase, $rootScope, CrossApplicationService) {
		var ClientStatsService = {};

		ClientStatsService.socket = {};
		ClientStatsService.downloadLogs = function () {
			var logs = ClientStatsService.createLogs();
			var a = document.createElement('a');
			a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(logs.join('\r\n'));
			a.target = '_blank';
			a.download = 'dashboard_logs_' + moment().format('MM-DD-YY hh:mm:ss:ms') + '.log';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		};
		CrossApplicationService.on('get_stats_logs', function (callback) {
			callback(ClientStatsService.createLogs());
		});

		ClientStatsService._formatLogs = function (logs) {
			return _.map(logs, function (args) {
				var stringBuilder = '';

				_.each(args, function (logArg) {
					if (typeof logArg === 'string') {
						stringBuilder += logArg;
					} else {
						stringBuilder += JSON.stringify(logArg);
					}

					stringBuilder += ' ';
				});

				return stringBuilder;
			});
		};

		ClientStatsService.createLogs = function () {
			var info = [
				'client: ' + $window.navigator.appCodeName,
				'user_agent: ' + $window.navigator.userAgent,
				'platform: ' + $window.navigator.platform,
				'num_cores: ' + $window.navigator.hardwareConcurrency,
				'is_online: ' + $window.navigator.onLine,
				'==================================================',
				'last_socket_connection: ' + ClientStatsService.socket.socket_last_connected,
				'last_socket_disconnection: ' + ClientStatsService.socket.socket_last_disconnected,
				'socket_reconnect_count: ' + ClientStatsService.socket.socket_reconnect_count,
				'==================================================',
				'user_id: ' + $rootScope.sdk.session.user.id,
				'user_name: ' + $rootScope.sdk.session.user.name,
				'user_email: ' + $rootScope.sdk.session.user.email,
				'user_merchant_id : ' + $rootScope.sdk.session.user.merchant_id,
				'merchant_env_type : ' + $rootScope.sdk.session.merchant.env_type,
				'region: ' + $rootScope.sdk.session.user.region,
				'user_beta : ' + $rootScope.sdk.session.user.beta,
				'=================================================='
			];

			var logs = ClientStatsService._formatLogs(ClientStatsService.logs);
			return info.concat(logs);
		};

		ClientStatsService.socketConnected = function () {
			ClientStatsService.socket.socket_last_connected = moment().format('MM-DD-YY hh:mm:ss:ms');
		};

		ClientStatsService.socketDisconnected = function () {
			ClientStatsService.socket.socket_last_disconnected = moment().format('MM-DD-YY hh:mm:ss:ms');
		};

		ClientStatsService.socketReconectCount = function (count) {
			ClientStatsService.socket.socket_reconnect_count = count;
		};

		return ClientStatsService;
	});
