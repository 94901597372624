'use strict';

angular
	.module('bringgApp')
	.service('TaskReassignReasonsService', function (
		MerchantConfigurationService,
		TeamConfigurationService,
		Teams,
		ENABLE_REASSIGN_REASONS_TYPES,
		REASSIGN_REASON_TYPES,
		INTERCEPTABLE_ACTIONS,
		ActionInterceptorService,
		$q,
		$uibModal,
		Task
	) {
		var TaskReassignReasonsService = {};

		TaskReassignReasonsService.askReason = function (reassignReasonTypes) {
			return $uibModal.open({
				backdrop: true,
				keyboard: true,
				controller: 'TaskReassignReasonsController',
				templateUrl: 'scripts/features/configuration/task_reassign_reasons/task-reassign-reasons.html',
				resolve: {
					reassignReasonTypes: function () {
						return reassignReasonTypes;
					}
				}
			}).result;
		};

		TaskReassignReasonsService.getTaskEnableReassignReasonConfig = function (task) {
			var teamConfiguration = TeamConfigurationService.getByTeamIdLocally(_.head(task.team_ids));
			if (teamConfiguration && !_.isNil(teamConfiguration.enable_reassign_reasons)) {
				return teamConfiguration.enable_reassign_reasons;
			}
			return TaskReassignReasonsService.merchantConfigEnableReassignReasons;
		};

		TaskReassignReasonsService.needToAskReassignReason = function (task, newUserId) {
			var taskWasManuallyAssigned = !task.automatically_assigned;
			var taskDidNotChangeUser = task.user_id === newUserId; //on mass assign the order may be set to the same user

			if (taskWasManuallyAssigned || taskDidNotChangeUser) {
				return false;
			}

			var reassignConfig = TaskReassignReasonsService.getTaskEnableReassignReasonConfig(task);

			switch (reassignConfig) {
				case ENABLE_REASSIGN_REASONS_TYPES.REASSIGN_ACTIVE_TASK:
					return !!(task.user_id && Task.isActive(task));
				case ENABLE_REASSIGN_REASONS_TYPES.REASSIGN_AUTO_ASSIGNED_TASK:
					return !!task.user_id;
				case ENABLE_REASSIGN_REASONS_TYPES.ANY_MANUAL_ASSIGN:
					return true;
				default:
					return false;
			}
		};

		TaskReassignReasonsService.getReassignReasonType = function (task) {
			if (task.user_id && Task.isActive(task)) {
				return REASSIGN_REASON_TYPES.ACTIVE_TASK;
			}
			if (task.user_id) {
				return REASSIGN_REASON_TYPES.MANUAL_REASSIGNMENT;
			}
			return REASSIGN_REASON_TYPES.MANUAL_ASSIGNMENT;
		};

		TaskReassignReasonsService.onReassign = function (i, params) {
			var tasks = params.tasks || [params.task]; //tasks on mass assign, task on single assign
			var newUserId = parseInt(params.user_id, 10);
			var tasksWithReassignReasonNeeded = _.filter(tasks, function (task) {
				return TaskReassignReasonsService.needToAskReassignReason(task, newUserId);
			});
			if (_.isEmpty(tasksWithReassignReasonNeeded)) {
				return $q.resolve(true);
			}

			var reassignReasonTypes = _.chain(tasksWithReassignReasonNeeded)
				.map(function (task) {
					return TaskReassignReasonsService.getReassignReasonType(task);
				})
				.compact()
				.uniq()
				.value();

			return TaskReassignReasonsService.askReason(reassignReasonTypes).then(
				function (result) {
					params.options.reason_to_reassign_task_id = result.id;
					params.options.reason_to_reassign_task_text = result.text;
					return $q.resolve(true);
				},
				function () {
					return $q.reject(false); //dismissed
				}
			);
		};

		TaskReassignReasonsService._shouldInitService = function (merchantConfig, teamsConfigs) {
			var anyTeamConfigEnabledReassignReason = _.some(teamsConfigs, function (teamConfig) {
				return (
					!_.isNil(teamConfig.enable_reassign_reasons) &&
					teamConfig.enable_reassign_reasons !== ENABLE_REASSIGN_REASONS_TYPES.DISABLED
				);
			});
			var merchantConfigEnabledReassignReason =
				!_.isNil(merchantConfig.enable_reassign_reasons) &&
				merchantConfig.enable_reassign_reasons !== ENABLE_REASSIGN_REASONS_TYPES.DISABLED;

			return anyTeamConfigEnabledReassignReason || merchantConfigEnabledReassignReason;
		};

		TaskReassignReasonsService.init = function () {
			return MerchantConfigurationService.get().then(function (merchantConfig) {
				TeamConfigurationService.loadAll().then(function () {
					var shouldInitService = TaskReassignReasonsService._shouldInitService(
						merchantConfig,
						TeamConfigurationService.getAllLocally()
					);
					if (shouldInitService) {
						TaskReassignReasonsService.merchantConfigEnableReassignReasons =
							merchantConfig.enable_reassign_reasons;
						ActionInterceptorService.interceptOn(
							INTERCEPTABLE_ACTIONS.TASK_ASSIGMENT,
							TaskReassignReasonsService.onReassign
						);
					}
				});
			});
		};

		return TaskReassignReasonsService;
	});
