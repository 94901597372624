'use strict';

angular.module('bringgApp').service('ScopeApplier', function () {
	var _this = this;
	_this.safeApply = safeApply;

	function safeApply(scope) {
		var phase = scope.$root.$$phase;
		if (phase !== '$apply' && phase !== '$digest') {
			scope.$apply();
		}
	}
});
