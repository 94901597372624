'use strict';

angular
	.module('bringgApp')
	.controller('christmasPointerChangeController', function (
		$scope,
		$log,
		MerchantConfigurations,
		MerchantConfigurationService,
		$uibModalInstance,
		$rootScope,
		CustomerConfiguration,
		CustomerSpecialFeature
	) {
		//==============================================
		// Init
		//==============================================
		$scope.featureName = 'xmass snow';
		$scope.data = { selectedPointer: '', showSnow: false };
		$scope._pinUpdated = false;
		$scope._showSnowUpdated = false;
		$scope.data.selectedPointer = MerchantConfigurations.delivery_pin;

		CustomerSpecialFeature.all(function (data) {
			//find the xmas snow feature
			$scope.snowSpecialFeature = _.find(data, function (feature) {
				if (feature.name === $scope.featureName) {
					return feature;
				}
			});
		});
		CustomerConfiguration.get(function (data) {
			$scope.customerConfiguration = data;
			//see if the xmas feature is in customer configuration
			var customerSpecialFeature = _.find($scope.customerConfiguration.customer_special_features, function (
				feature
			) {
				if (feature.name === $scope.featureName) {
					return feature;
				}
			});
			$scope.data.showSnow = angular.isDefined(customerSpecialFeature);
		});

		$scope.$on('config updated', function () {
			$scope.data.selectedPointer = MerchantConfigurations.delivery_pin;
		});
		//==============================================
		// User interaction
		//==============================================
		$scope.handleApplyClick = function () {
			//update the delivery pin
			if (MerchantConfigurations.delivery_pin !== $scope.data.selectedPointer) {
				MerchantConfigurations.alternate_delivery_pin = $scope.data.selectedPointer;
				MerchantConfigurationService.deliveryPinChange($scope.data.selectedPointer)
					.then(function (result) {
						//This one is emited in order to notify the merchantConfigurationController
						$rootScope.$emit('alternateDeliveryPinUpdated', result.alternate_delivery_pin);
						$scope._pinUpdated = true;
						$scope.handleApplyFlow();
					})
					.catch(function (reason) {
						$log.error(reason);
					});
			} else {
				$scope._pinUpdated = true;
			}

			//update the show snow
			$scope.customerConfiguration.special_features = [];
			$scope.customerConfiguration.special_features.push({
				id: $scope.snowSpecialFeature.id,
				approved: $scope.data.showSnow
			});
			$scope.customerConfiguration.$update(
				function (result) {
					$scope._showSnowUpdated = true;
					$scope.handleApplyFlow();
				},
				function (reason) {
					$log.error(reason);
				}
			);
		};

		$scope.handleApplyFlow = function () {
			if ($scope._pinUpdated && $scope._showSnowUpdated) {
				MerchantConfigurations.$refresh();
				$uibModalInstance.close();
			}
		};

		$scope.handleResetToDefault = function () {
			MerchantConfigurations.alternate_delivery_pin = null;
			MerchantConfigurationService.deliveryPinChange().then(
				function (result) {
					//This one is emited in order to notify the merchantConfigurationController
					MerchantConfigurations.$refresh();
				},
				function (reason) {
					$log.error(reason);
				}
			);
		};

		$scope.handleCancelClick = function () {
			$log.info('Christmas Poiner Change modal was closed by user');
			$uibModalInstance.dismiss();
		};
	});
