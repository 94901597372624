'use strict';

angular
	.module('bringgApp')
	.controller('ConnectToStripeController', function ($scope, $http, toastr, WEB_API_URL, $routeParams) {
		$scope.loading = true;
		$scope.result = '';

		return $http
			.post(WEB_API_URL + '/webhooks/stripe_oauth', {
				code: $routeParams.code
			})
			.success(function (data, status, headers, config) {
				$scope.loading = false;
				if (data.success) {
					$scope.result = 'Connected successfully!';
					toastr.success('Connected successfully!');
				} else {
					$scope.result = data.message;
					toastr.error(data.message);
				}
			})
			.error(function (data, status, headers, config) {
				$scope.loading = false;
				$scope.result = 'Stripe connection error!!';
				toastr.error('Stripe connection error!!');
			});
	});
