'use strict';

angular.module('bringgApp').factory('ObjectUtilsService', function () {
	const ObjectUtilsService = {};

	/**
	 * Mutates 'target' in a way that it contains only keys which are present in 'sample'
	 *
	 * @param {Object} target
	 * @param {Object} sample
	 * */
	ObjectUtilsService.deleteMissingKeys = function (target, sample) {
		const sampleKeysSet = new Set(Object.keys(sample));

		for (let targetKey of Object.keys(target || {})) {
			if (!sampleKeysSet.has(targetKey)) {
				delete target[targetKey];
			}
		}
	};

	return ObjectUtilsService;
});
