'use strict';

angular
	.module('bringgApp')
	.controller('clientReminderController', function (
		$scope,
		toastr,
		Employee,
		vcRecaptchaService,
		RECAPTCHA_SITE_KEY
	) {
		$scope.display = true;
		$scope.show_captcha = false;

		$scope.recaptcha = null;
		$scope.widgetId = null;
		$scope.model = {
			key: RECAPTCHA_SITE_KEY
		};

		$scope.setRecaptcha = function (recaptcha) {
			$scope.recaptcha = recaptcha;
		};
		$scope.setWidgetId = function (widgetId) {
			$scope.widgetId = widgetId;
			if ($scope.show_captcha) {
				vcRecaptchaService.reload($scope.widgetId);
			}
		};
		$scope.cbExpiration = function () {
			$scope.recaptcha = null;
		};

		$scope.remind = function () {
			var params = {
				email: $scope.email,
				recaptcha: $scope.show_captcha ? vcRecaptchaService.getResponse($scope.widgetId) : ''
			};

			Employee.password_reminder(params, function (response) {
				if (response.success) {
					$scope.display = false;
				} else if (response.recaptcha_stoken) {
					$scope.stoken = response.recaptcha_stoken;
					$scope.show_captcha = true;
					if ($scope.widgetId !== null) {
						vcRecaptchaService.reload($scope.widgetId);
					}
				} else {
					$scope.show_captcha = false;
					toastr.error(response.message);
				}
			});
		};
	});
