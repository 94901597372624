'use strict';

angular.module('bringgApp').constant('SHARED_LOCATION_TYPES', {
	CREATED: 0,
	START: 1,
	CHECKED_IN: 2,
	CHECKED_OUT: 3,
	ROUTE: 4,
	LATE: 5,
	RESCHEDULE: 6,
	READY_FOR_PICKPUP: 7,
	SCHEDULE_READY: 8,
	ACKNOWLEDGED: 9,
	SCHEDULE: 10
});
