'use strict';
/*global Stripe:false */

angular
	.module('bringgApp')
	.controller(
		'clientRegisterController',
		function (
			$scope,
			$location,
			Authentication,
			Employee,
			Merchant,
			$rootScope,
			LazyLoadJsService,
			STRIPE_KEY,
			$timeout,
			$log,
			dialogSrv,
			MainNavigationVer2Service
		) {
			$scope.doingRegisteration = false;
			$scope.cardForm = {};
			$scope.user = {};
			$scope.merchant = {};
			$scope.loading = true;
			$scope.card = { number: null, cvc: null, exp_month: undefined, exp_year: undefined };

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (isLoggedIn) {
					$location.path('/map/');
				}
			});

			$scope.login = function (loginFormData) {
				Authentication.login(loginFormData.email, loginFormData.password);
			};

			$scope.handleNextButtonClick = function () {
				$scope.nextClicked = true;
				$timeout(function () {
					//angular.element('body,html,document').scrollTop(form.offset().top);
					angular
						.element('body,html,document')
						.animate({ scrollTop: angular.element('#cardForm').offset().top }, 1000);
				}, 1);
			};

			try {
				if (Stripe) {
					$scope.loading = false;
					$log.info('Stripe was loaded');
				}
			} catch (err) {
				LazyLoadJsService.loadFile('https://js.stripe.com/v2/').then(
					function (result) {
						$scope.loading = false;
					},
					function (reason) {
						toastr.error('Error occurred, please refresh your browser.');
					}
				);
			}

			$scope.handleRegisterClick = function () {
				$scope.doingRegisteration = true;
				dialogSrv.waitDialog('please wait', null, 'Registering');

				Stripe.setPublishableKey(STRIPE_KEY);
				Stripe.card.createToken(
					{
						number: $scope.card.number,
						cvc: $scope.card.cvc,
						exp_month: $scope.card.exp_month,
						exp_year: $scope.card.exp_year,
						name: $scope.card.name
					},
					$scope._handlePaymentsResponse
				);
			};

			$scope._handlePaymentsResponse = function (status, result) {
				if (result.error) {
					$scope.doingRegisteration = false;
					$rootScope.$broadcast('wait.complete');
					toastr.error(result.error.message);
					$scope.$apply();
				} else {
					var user = $scope.user;
					var merchant = $scope.merchant;
					Employee.save_with_merchant(
						{ user: user, merchant: merchant, credit_card_id: result.id },
						function (response) {
							$scope.doingRegisteration = false;
							$rootScope.$broadcast('wait.complete');
							if (response.success) {
								Authentication.login(user.email, user.password);
								$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
							} else {
								var errorString = '';
								for (var messageIdx in response.message) {
									errorString += messageIdx + ':' + response.message[messageIdx][0] + '\r\n';
								}
								alert(errorString);
							}
						},
						function (error) {
							$scope.doingRegisteration = false;
							toastr.error('Failed to register merchant, please try again');
						}
					);
				}
			};
		}
	);
