'use strict';
/*global merge_objects:false */
angular.module('bringgApp').factory('MerchantPrototypeService', function ($rootScope, $q, BringgSDK) {
	let _merchantPrototypeSdk;

	function MerchantPrototypeService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.merchantPrototype[method].apply(bringg.merchantPrototype, args);
			});
		}.bind(this);
	}

	BringgSDK.getInstancePromise().then(function (bringg) {
		_merchantPrototypeSdk = bringg.merchantPrototype;
	});

	var merchantPrototype = {};

	merchantPrototype.getAll = MerchantPrototypeService('getAll');
	merchantPrototype.createMerchantByAccountManager = MerchantPrototypeService('createMerchantByAccountManager');

	return merchantPrototype;
});
