'use strict';

angular
	.module('bringgApp')
	.factory('DriverConfiguration', function (BringgSDK) {
		function driverConfigurationService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.userConfigurations[method].apply(bringg.userConfigurations, args);
				});
			}.bind(this);
		}

		return {
			get: driverConfigurationService('get'),
			update: driverConfigurationService('update')
		};
	})
	.factory(
		'DriverConfigurations',
		function (DriverConfiguration, $rootScope, Authentication, $q, CLUSTER_MODE_TYPES) {
			var service = {};
			var driverConfiguration;
			var refreshPromise;

			service.refresh = function () {
				var deferred = $q.defer();
				DriverConfiguration.get().then(function (data) {
					driverConfiguration = data;
					$rootScope.$broadcast('driverConfigUpdated');
					deferred.resolve(data);
				});
				refreshPromise = deferred.promise;
				return refreshPromise;
			};

			service.get = function () {
				if (driverConfiguration) {
					return $q(function (resolve) {
						resolve(driverConfiguration);
					});
				}
				if (refreshPromise) {
					return refreshPromise;
				}
				return service.refresh();
			};

			service.update = DriverConfiguration.update.bind(DriverConfiguration);

			service.isClusterModeEnabled = function () {
				return service.get().then(configuration => {
					return (
						configuration.cluster_mode &&
						[CLUSTER_MODE_TYPES.INVENTORY_PICKUP, CLUSTER_MODE_TYPES.INVENTORY_PICKUP_DROPOFF].includes(
							configuration.cluster_mode_type
						)
					);
				});
			};

			$rootScope.$watch(Authentication.isLoggedIn, function () {
				if ($rootScope.isLoggedIn) {
					service.refresh();
				} else {
					driverConfiguration = undefined;
					refreshPromise = undefined;
				}
			});

			return service;
		}
	);
