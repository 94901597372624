/*global moment:false */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('locale_distance_km', function (MerchantConfigurations) {
		return function (distanceInput) {
			var distance = parseFloat(distanceInput);
			if (isNaN(distance)) {
				return 'N/A';
			}
			if (MerchantConfigurations.country_code === 'us') {
				return (distance * 0.621371).toFixed(2) + ' miles';
			} else {
				return distance.toFixed(2) + ' km';
			}
		};
	})
	.filter('locale_distance_km_without_measurement', function (MerchantConfigurations) {
		return function (distanceInput) {
			var distance = parseFloat(distanceInput);
			if (isNaN(distance)) {
				return 'N/A';
			}
			if (MerchantConfigurations.country_code === 'us') {
				return (distance * 0.621371).toFixed(2);
			} else {
				return distance.toFixed(2);
			}
		};
	})
	.filter('locale_distance_m', function (MerchantConfigurations) {
		return function (distanceInput) {
			var distance = parseFloat(distanceInput);
			if (isNaN(distance)) {
				return 'N/A';
			}
			if (MerchantConfigurations.country_code === 'us') {
				return (distance * 3.280839895).toFixed(2) + ' feet';
			} else {
				return distance.toFixed(2) + ' m';
			}
		};
	});
