/*global Stripe:false */
'use strict';

angular
	.module('bringgApp')
	.controller(
		'clientConfirmationController',
		function (
			$scope,
			$routeParams,
			Employee,
			toastr,
			Authentication,
			$rootScope,
			localStorageService,
			STRIPE_KEY,
			dialogSrv,
			LazyLoadJsService,
			$log,
			$location,
			MainNavigationVer2Service
		) {
			$scope.confirmation_token = $routeParams.confirmation_token;
			$scope.loading = true;
			$scope.creditCardRequired = false;
			$scope.doingRegisteration = false;

			try {
				if (Stripe) {
					$scope.loading = false;
					$log.info('Stripe was loaded');
				}
			} catch (err) {
				LazyLoadJsService.loadFile('https://js.stripe.com/v2/').then(
					function (result) {
						$scope.loading = false;
					},
					function (reason) {
						toastr.error('Error occurred, please refresh your browser.');
					}
				);
			}

			$scope._handleConfirmEmailReturn = function (response) {
				if (response.success) {
					$scope.emailConfirmed = true;
					$scope.doingRegisteration = false;
					if (Authentication.isLoggedIn && Authentication.currentUser() && response.confirmed_at) {
						//save the confirmed at on rootScope
						Authentication.currentUser().confirmed_at = response.confirmed_at;
						//save the cunfirmed at in local storage
						localStorageService.set('overvyoo_user', Authentication.currentUser());
						//
					}
					$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
				} else {
					if (response.rc && response.rc === 1) {
						$scope.creditCardRequired = true;
					} else {
						if (angular.isObject(response.message)) {
							response.message = [response.message];
						}
						if (angular.isArray(response.message)) {
							toastr.error(
								_.keys(response.message[0])[0] +
									': ' +
									response.message[0][_.keys(response.message[0])[0]][0]
							);
						} else {
							toastr.error(response.message);
						}
						$scope.emailConfirmed = false;
					}
				}
				$scope.loading = false;
			};

			$scope._handlePaymentsResponse = function (status, result) {
				if (result.error) {
					$scope.doingRegisteration = false;
					$rootScope.$broadcast('wait.complete');
					toastr.error(result.error.message);
					$scope.$apply();
				} else {
					Employee.confirm_email(
						{
							confirmation_token: $scope.confirmation_token,
							credit_card_id: result.id
						},
						$scope._handleConfirmEmailReturn
					);
				}
			};

			$scope.handleRegisterClick = function () {
				$scope.doingRegisteration = true;
				dialogSrv.waitDialog('Please wait', null, 'Registering');

				Stripe.setPublishableKey(STRIPE_KEY);
				Stripe.card.createToken(
					{
						number: $scope.card.number,
						cvc: $scope.card.cvc,
						exp_month: $scope.card.exp_month,
						exp_year: $scope.card.exp_year,
						name: $scope.card.name
					},
					$scope._handlePaymentsResponse
				);
			};

			Employee.confirm_email({ confirmation_token: $scope.confirmation_token }, $scope._handleConfirmEmailReturn);

			$scope.handleResendClick = function () {
				Employee.resend_confirmation_instructions(function (response) {
					if (response.success) {
						toastr.success('New email with confirmation link was sent.');
					} else {
						toastr.error('Error sending a confirmation email.');
					}
				});
			};
		}
	);
