'use strict';
/*global app:false */
/*global io:false */

angular.module('bringgApp').factory('DispatcherAlertSubscription', function ($resource, WEB_API_URL) {
	var DispatcherAlertSubscription = $resource(
		WEB_API_URL + '/dispatcher_alert_subscriptions/',
		{},
		{
			all: { method: 'GET', isArray: true, url: WEB_API_URL + '/dispatcher_alert_subscriptions/' },
			mass_update: { method: 'POST', url: WEB_API_URL + '/dispatcher_alert_subscriptions/mass_update/' }
		}
	);
	return DispatcherAlertSubscription;
});

angular.module('bringgApp').constant('DISPATCHER_ALERT_SUBSCRIPTION_TYPE', {
	screen: 0,
	phone: 1,
	email: 2
});
