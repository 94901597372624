'use strict';

angular
	.module('bringgApp.services')
	.factory(
		'Payments',
		function ($resource, WEB_API_URL, Authentication, $uibModal, toastr, SUBSCRIPTION_TYPE, $log, MerchantService) {
			var service = $resource(
				WEB_API_URL + '/merchant/payments/',
				{},
				{
					add_credit_card: { method: 'POST', url: WEB_API_URL + '/merchant/payments/add_credit_card' },
					create: { method: 'POST', url: WEB_API_URL + '/merchant/payments' },
					change_default_credit_card: {
						method: 'POST',
						url: WEB_API_URL + '/merchant/payments/change_default_credit_card'
					},
					delete_credit_card: { method: 'POST', url: WEB_API_URL + '/merchant/payments/delete_credit_card' },
					get_trial_end_date: { method: 'GET', url: WEB_API_URL + '/merchant/payments/trial_end_date' },
					changeSubscriptionType: {
						method: 'POST',
						url: WEB_API_URL + '/merchant/payments/change_subscription_type'
					},
					billing_changes_history: {
						method: 'GET',
						url: WEB_API_URL + '/merchant/payments/billing_changes_history'
					},
					redeem_coupon: { method: 'POST', url: WEB_API_URL + '/merchant/payments/redeem_coupon' },
					update_email_recipient: {
						method: 'POST',
						url: WEB_API_URL + '/merchant/payments/update_email_recipient'
					}
				}
			);

			service.initStripeConfiguration = function () {
				if (Authentication.isLoggedIn() && Authentication.currentUser()) {
					MerchantService.get().then(
						function (merchant) {
							if (!merchant.stripe_id && !merchant.custom_payments) {
								service.create(function (result) {
									if (result.success) {
										var modalInstance = $uibModal.open({
											backdrop: 'static',
											keyboard: true,
											controller: 'CustomerBillingWelcomeMessageController',
											templateUrl:
												'scripts/features/configuration/billing/customer-billing-welcome-message.html',
											size: 'sm',
											resolve: {
												merchantId: function () {
													return merchant.id;
												}
											}
										});

										modalInstance.result.then(
											function (result) {},
											function (reason) {
												$log.info('Modal dismissed at: ' + new Date());
											}
										);
									} else {
										if (result.message && merchant.subscription_type !== SUBSCRIPTION_TYPE.O2M) {
											toastr.error(result.message);
										}
									}
								});
							}
						},
						function (reason) {}
					);
				}
			};

			service.openChangeSubscriptionTypeWindow = function (currentSubscription) {
				$uibModal.open({
					backdrop: 'static',
					keyboard: true,
					controller: 'ChangeTierSubscriptionTypeController',
					templateUrl:
						'scripts/features/configuration/billing/change_subscription_type/change-tier-subscription-type.html',
					resolve: {
						currentSubscription: function () {
							return currentSubscription;
						}
					}
				});
			};

			service.openUpdateToProWindow = function () {
				$uibModal.open({
					backdrop: true,
					keyboard: true,
					controller: 'UpgradeNowController',
					templateUrl: 'scripts/features/configuration/billing/upgrade_now/upgrade-now.html'
				});
			};

			service.getConfigurationName = function (pricingPlan) {
				switch (pricingPlan) {
					case 0:
						return 'Per Driver';
					case 1:
						return 'Essential';
					case 2:
						return 'Starter';
					case 3:
						return 'Basic';
					case 4:
						return 'Pro';
					default:
						return 'Unknown';
				}
			};

			return service;
		}
	);
