'use strict';

angular.module('bringgApp').factory('CashOutService', function (PrinterService, MerchantConfigurations) {
	var CashOutService = {};

	CashOutService.print = function (data, employee, totals, footers, cashOutDate) {
		if (!cashOutDate) {
			//the date that the cashout was done should be passed. if none passed use now.
			cashOutDate = new Date();
		}

		var printData = {
			data: data,
			employee: employee,
			config: { logo: MerchantConfigurations.logo, color: MerchantConfigurations.header_color },
			totals: totals,
			footers: footers,
			date: cashOutDate
		};

		PrinterService.printCashOutFromDirective(printData);
	};

	return CashOutService;
});
