'use strict';

angular.module('bringgApp').factory('HtmlDecoder', function () {
	// decode HTML and also protect from XSS as all HTML tags are removed
	var memoizedDecodeHtml = _.memoize(function memoizedDecodeHtml(input) {
		var doc = new DOMParser().parseFromString(input, 'text/html');
		return _.get(doc, 'documentElement.textContent');
	});

	return {
		// Not memorizing this function as most of the inputs don't have `&` or `<`
		// so no need to store them for later possible computation
		decodeHtml: function decodeHtml(input) {
			// Checking for & as according to MDN an HTML entity must start with & (https://developer.mozilla.org/en-US/docs/Glossary/Entity)
			if (typeof input === 'string' && (input.indexOf('&') === -1 || input.indexOf('<') === -1)) {
				return input;
			}

			return memoizedDecodeHtml(input);
		}
	};
});
