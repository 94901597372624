'use strict';

angular.module('bringgApp.services').factory('dialogSrv', function ($uibModal) {
	return {
		// Error Dialog - for display of error messages.
		errorDialog: function (errmsg, correction) {
			$uibModal.open({
				resolve: {
					errmsg: function () {
						return angular.copy(errmsg);
					},
					correction: function () {
						return angular.copy(correction);
					}
				},
				controller: 'ErrorDialogCtrl',
				templateUrl: 'scripts/features/dialogs/error_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end errorDialog

		// Wait Dialog - for displaying a message and progress bar as the application performs a task(s)
		waitDialog: function (msg, progress, title) {
			$uibModal.open({
				resolve: {
					msg: function () {
						return angular.copy(msg);
					},
					progress: function () {
						return angular.copy(progress);
					},
					title: function () {
						return angular.copy(title);
					}
				},
				controller: 'WaitDialogCtrl',
				templateUrl: 'scripts/features/dialogs/wait_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end waitDialog

		//Notify Dialog - simple dialog to display a message to the user
		notifyDialog: function (header, msg) {
			$uibModal.open({
				resolve: {
					header: function () {
						return angular.copy(header);
					},
					msg: function () {
						return angular.copy(msg);
					}
				},
				controller: 'NotifyDialogCtrl',
				templateUrl: 'scripts/features/dialogs/notify_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end notifyDialog

		addDataDialog: function (header, msg, question_data, evt, data) {
			$uibModal.open({
				resolve: {
					header: function () {
						return angular.copy(header);
					},
					msg: function () {
						return angular.copy(msg);
					},
					question_data: function () {
						return angular.copy(question_data);
					},
					evt: function () {
						return angular.copy(evt);
					},
					data: function () {
						return angular.copy(data);
					}
				},
				controller: 'AddDataDialogCtrl',
				templateUrl: 'scripts/features/dialogs/add_data_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end confirmDialog

		confirmDialog: function (header, msg, evt, data) {
			$uibModal.open({
				resolve: {
					header: function () {
						return angular.copy(header);
					},
					msg: function () {
						return angular.copy(msg);
					},
					evt: function () {
						return angular.copy(evt);
					},
					data: function () {
						return angular.copy(data);
					}
				},
				controller: 'ConfirmDialogCtrl',
				templateUrl: 'scripts/features/dialogs/confirm_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end confirmDialog

		confirmDialogAsync: function (options) {
			return $uibModal.open({
				resolve: {
					options: function () {
						return angular.copy(options);
					}
				},
				controller: 'ConfirmDialogAsyncCtrl',
				templateUrl: 'scripts/features/dialogs/confirm_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		},

		richHtmlDialog: function (header, htmlData, evt, data) {
			return $uibModal.open({
				resolve: {
					header: function () {
						return angular.copy(header);
					},
					htmlData: function () {
						return angular.copy(htmlData);
					},
					evt: function () {
						return angular.copy(evt);
					},
					data: function () {
						return angular.copy(data);
					}
				},
				controller: 'RichHTMLDialogController',
				templateUrl: 'scripts/features/dialogs/rich_html_dialog.html',
				keyboard: false,
				backdrop: 'static'
			});
		}, // end richHtmlDialog

		createDialog: function (templateUrl, controller, data) {
			$uibModal.open({
				resolve: {
					data: function () {
						return angular.copy(data);
					}
				},
				controller: controller,
				templateUrl: templateUrl
			});
		}, // end createDialog
		dynamicFormDialog: function (header, fields, evt, data) {
			$uibModal.open({
				resolve: {
					header: function () {
						return angular.copy(header);
					},
					fields: function () {
						return angular.copy(fields);
					},
					evt: function () {
						return angular.copy(evt);
					},
					data: function () {
						return angular.copy(data);
					}
				},
				controller: 'DynamicFormDialogController',
				templateUrl: 'scripts/features/dialogs/dynamic_form_dialog.html'
			});
		} // end inputDialog
	}; // end return
}); // end dialogSrv / dialogs.services
