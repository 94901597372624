'use strict';

var MARGIN_FROM_ELEMENT = 10;
var TOOLTIP_MAX_WIDTH = 280;
var TOOLTIP_ID_SUFFIX = '_tooltip';

angular.module('bringgApp.directives').service('CustomSlickgridTooltip', function ($compile, $rootScope) {
	var CustomSlickgridTooltip = {};
	var scope = $rootScope.$new();

	function calculatePositions(element, tooltip) {
		var elementLocation = element.getBoundingClientRect();
		var widthFactor =
			elementLocation.width > element.parentNode.clientWidth
				? element.parentNode.clientWidth
				: elementLocation.width;
		var _left = elementLocation.x + widthFactor / 2;
		var tooltipRect = tooltip.getBoundingClientRect();
		var _isOverflow = false,
			_right = '',
			_className = '';

		// getting the actual tooltip height to calculate the top offset
		var _top = elementLocation.y - MARGIN_FROM_ELEMENT - tooltip.clientHeight + window.scrollY;

		var isOverflowingLeft = _left - tooltipRect.width / 2 <= 0;

		if (isOverflowingLeft) {
			_left = MARGIN_FROM_ELEMENT;
			_isOverflow = true;

			// fix the tooltip arrow location
			if (tooltipRect.width === TOOLTIP_MAX_WIDTH) {
				_className = ' on-left-column';
			}
		}

		var isOverflowingRight = tooltipRect.width + _left > window.innerWidth;

		if (isOverflowingRight) {
			_left = '';
			_right = window.innerWidth - (elementLocation.x + widthFactor);
			_isOverflow = true;

			// fix the tooltip arrow location
			if (tooltipRect.width === TOOLTIP_MAX_WIDTH && widthFactor - MARGIN_FROM_ELEMENT < tooltipRect.width / 2) {
				_className = ' on-right-column';
			}
		}

		return { isOverflow: _isOverflow, left: _left, right: _right, top: _top, className: _className };
	}

	function createTooltip(elementId, content) {
		var element = angular.element('#' + elementId)[0];

		var tooltip = document.createElement('div');

		tooltip.innerHTML = content;
		tooltip.id = element.id + TOOLTIP_ID_SUFFIX;
		tooltip.className = 'slickgrid-tooltip';

		tooltip.style.position = 'absolute';
		tooltip.style.transform = 'translateX(-50%)';
		tooltip.style.visibility = 'hidden';

		var compiledTooltip = $compile(tooltip)(scope)[0];

		angular.element(document.body).append(compiledTooltip);

		var tooltipPositions = calculatePositions(element, tooltip);

		tooltip.style.top = tooltipPositions.top + 'px';

		if (tooltipPositions.isOverflow) {
			tooltip.style.transform = '';
		}

		tooltip.setAttribute('class', tooltip.className + ' ' + tooltipPositions.className);
		tooltip.style.left = tooltipPositions.left + 'px';
		tooltip.style.right = tooltipPositions.right + 'px';
		tooltip.style.visibility = '';
	}

	CustomSlickgridTooltip.open = function (elementId, content) {
		var tooltip = document.getElementById(elementId + TOOLTIP_ID_SUFFIX);

		if (!tooltip) {
			createTooltip(elementId, content);
		}
	};

	CustomSlickgridTooltip.remove = function (elementId) {
		var tooltip = document.getElementById(elementId + TOOLTIP_ID_SUFFIX);

		if (tooltip) {
			tooltip.remove();
		}
	};

	CustomSlickgridTooltip.removeAll = function () {
		var tooltips = document.querySelectorAll('[id*=' + TOOLTIP_ID_SUFFIX + ']');

		if (tooltips.length) {
			_.each(tooltips, function (tooltip) {
				tooltip.remove();
			});
		}
	};

	return CustomSlickgridTooltip;
});
