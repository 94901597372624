'use strict';

angular.module('bringgApp').factory('TeamConfigurationService', function ($rootScope, BringgSDK) {
	function teamConfigurationService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.teamConfiguration[method].apply(bringg.teamConfiguration, args);
			});
		}.bind(this);
	}

	var TeamConfiguration = {
		configurations: {}
	};

	TeamConfiguration.get = teamConfigurationService('get');
	TeamConfiguration.getAll = teamConfigurationService('getAll');
	TeamConfiguration.update = teamConfigurationService('update');

	TeamConfiguration.loadAll = function () {
		return TeamConfiguration.getAll().then(function (response) {
			var teamsConfigurations = response.teams_configurations;
			TeamConfiguration.configurations = _.reduce(
				teamsConfigurations,
				function (teamsConfigs, teamConfig) {
					teamsConfigs[teamConfig.team_id] = teamConfig;
					return teamsConfigs;
				},
				{}
			);
		});
	};

	TeamConfiguration.getByTeamIdLocally = function (teamId) {
		return TeamConfiguration.configurations[teamId];
	};

	TeamConfiguration.getAllLocally = function () {
		return TeamConfiguration.configurations;
	};

	return TeamConfiguration;
});
