'use strict';

angular
	.module('bringgApp')
	.directive('applicationAction', function () {
		return {
			scope: {
				supported: '@',
				selectedItems: '=?'
			},
			replace: true,
			templateUrl: 'scripts/directives/application_action/application-action.html',
			controller: 'ApplicationActionController'
		};
	})
	.controller('ApplicationActionController', function (
		$scope,
		$rootScope,
		ApplicationActionService,
		dialogSrv,
		toastr,
		$translate,
		$log,
		MerchantConfigurations,
		ReactModals
	) {
		$scope.onApplicationActionClick = function (applicationAction) {
			if (applicationAction.value && applicationAction.value === 'form') {
				$scope.handleFormRequest(applicationAction);
			} else if (applicationAction.options && applicationAction.options.confirm) {
				dialogSrv.confirmDialog(
					applicationAction.title + '.CONFIRM_TITLE',
					applicationAction.title + '.CONFIRM_MESSAGE',
					'applicationAction.executeAsyncConfirm',
					applicationAction
				);
			} else {
				$scope._executeAsync(applicationAction);
			}
		};

		$scope.handleFormRequest = function (applicationAction) {
			ReactModals.openDynamicForm(
				$translate.instant(applicationAction.title + '.FORM_TITLE'),
				applicationAction.fields,
				$scope.onFormSubmit(applicationAction),
				'Send'
			);
		};

		$scope.onFormSubmit = function (applicationAction) {
			return function (values) {
				$scope._executeAsync(_.extend({}, applicationAction, { fields: values }));
			};
		};

		$rootScope.$on('applicationAction.executeAsyncConfirm', function (event, applicationAction) {
			$scope._executeAsync(applicationAction);
		});

		$scope._executeAsync = function (applicationAction) {
			ApplicationActionService.executeAsync(applicationAction.action, {
				task_ids: _.map($scope.selectedItems, 'id'),
				fields: applicationAction.fields || {}
			})
				.then(function () {
					toastr.success($translate.instant(applicationAction.title + '.SUCCESS'));
				})
				.catch(function (error) {
					toastr.error($translate.instant(applicationAction.title + '.FAILED'));
					$log.error(
						'ApplicationActionController:executeBackgroundTaskAsync failed with [' +
							JSON.stringify(error) +
							']'
					);
				});
		};

		$rootScope.$on('application actions loaded', function () {
			$scope.applicationActions = ApplicationActionService.getActionsThatSupportLabel($scope.supported);
		});

		//loads the application actions
		MerchantConfigurations.$refresh();
	});
