/**
 * Created by liorsion on 1/2/17.
 */
'use strict';

angular.module('bringgApp').constant('CANCEL_REASON_ORDER_TYPES', {
	NONE: 0,
	PICKUP: 1,
	DROPOFF: 2,
	PICKUP_AND_DROPOFF: 3,
	CURBSIDE: 6
});

angular.module('bringgApp').factory('ReasonToCancelTask', function ($resource, WEB_API_URL, $translate) {
	var ReasonToCancelTask = $resource(
		WEB_API_URL + '/reason_to_cancel_tasks',
		{ id: '@id' },
		{
			all: { method: 'GET', isArray: false, url: WEB_API_URL + '/reason_to_cancel_tasks' }, // notice isArray is false here
			create: { method: 'POST', url: WEB_API_URL + '/reason_to_cancel_tasks' },
			deleteTagReasonsToCancelTaskConfiguration: {
				method: 'DELETE',
				url: WEB_API_URL + '/reason_to_cancel_tasks/delete_tag_reason_to_cancel_tasks_configuration'
			},
			createTagReasonsToCancelTaskConfiguration: {
				method: 'POST',
				url: WEB_API_URL + '/reason_to_cancel_tasks/create_tag_reason_to_cancel_tasks_configuration'
			},
			updateTagReasonsToCancelTask: {
				method: 'POST',
				url: WEB_API_URL + '/reason_to_cancel_tasks/update_tag_reasons_to_cancel_task'
			}
		}
	);

	ReasonToCancelTask.USER_TYPES = {
		BOTH: 0,
		DRIVER: 1,
		DISPATCHER: 2,
		ADMIN: 3
	};

	ReasonToCancelTask.user_types = [
		{ value: ReasonToCancelTask.USER_TYPES.BOTH, label: $translate.instant('TASK_CANCEL_CONFIGURATION.ALL_USERS') },
		{
			value: ReasonToCancelTask.USER_TYPES.DRIVER,
			label: $translate.instant('TASK_CANCEL_CONFIGURATION.ONLY_DRIVER')
		},
		{
			value: ReasonToCancelTask.USER_TYPES.DISPATCHER,
			label: $translate.instant('TASK_CANCEL_CONFIGURATION.ONLY_DISPATCHER')
		},
		{
			value: ReasonToCancelTask.USER_TYPES.ADMIN,
			label: $translate.instant('TASK_CANCEL_CONFIGURATION.ONLY_ADMIN')
		}
	];

	ReasonToCancelTask.flagsToUserType = function (cancelReason) {
		if (cancelReason.driver && cancelReason.dispatcher) {
			cancelReason.user_type = ReasonToCancelTask.USER_TYPES.BOTH;
		} else if (cancelReason.driver) {
			cancelReason.user_type = ReasonToCancelTask.USER_TYPES.DRIVER;
		} else if (cancelReason.dispatcher) {
			cancelReason.user_type = ReasonToCancelTask.USER_TYPES.DISPATCHER;
		} else {
			cancelReason.user_type = ReasonToCancelTask.USER_TYPES.ADMIN;
		}
	};

	ReasonToCancelTask.userTypeToFlags = function (cancelReason) {
		cancelReason.driver = false;
		cancelReason.dispatcher = false;
		switch (cancelReason.user_type) {
			case ReasonToCancelTask.USER_TYPES.BOTH:
				cancelReason.driver = true;
				cancelReason.dispatcher = true;
				break;
			case ReasonToCancelTask.USER_TYPES.DRIVER:
				cancelReason.driver = true;
				break;
			case ReasonToCancelTask.USER_TYPES.DISPATCHER:
				cancelReason.dispatcher = true;
				break;
		}
	};

	return ReasonToCancelTask;
});
