'use strict';

angular.module('bringgApp').factory('InventoryManagementService', function (InventoryManagement) {
	var InventoryManagementService = {};

	InventoryManagementService.allInventories = [];

	InventoryManagementService.all = function () {
		return (InventoryManagementService.allInventories = InventoryManagement.all(function (result) {
			return result;
		}).$promise);
	};

	return InventoryManagementService;
});
