'use strict';

angular
	.module('bringgApp')
	.controller('DefaultShiftConfiguration', function (
		$scope,
		MerchantShiftScheduleService,
		MerchantScheduledBreaksService,
		Authentication,
		$log,
		toastr,
		$q
	) {
		$scope.loading = true;
		$scope.sending = false;

		//==============================================
		// Shifts
		//==============================================
		var loadTeamShiftsSchedules = function () {
			$scope.loading = true;
			$q.all([MerchantShiftScheduleService.get(), MerchantScheduledBreaksService.get()]).then(
				function (results) {
					$scope.loading = false;
					_applyShiftsDataToUI(results[0], results[1]);
				},
				function (reason) {
					$scope.loading = false;
					toastr.error('Failed loading shifts configuration');
				}
			);
		};

		//update the ui based on the stuff from serv
		var _applyShiftsDataToUI = function (shifts, breaks) {
			$scope.uiShiftsPerDay = MerchantShiftScheduleService.translateFromDb(shifts, breaks.scheduled_breaks);
		};

		//update the ui based on the stuff from serv
		$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
			if (isLoggedIn && Authentication.currentUser()) {
				loadTeamShiftsSchedules();
			}
		});

		$scope.handleUpdateDefaultShiftConfigClicked = function () {
			$scope.sending = true;
			var result = MerchantShiftScheduleService.translateToDb($scope.uiShiftsPerDay);

			$q.all([
				MerchantShiftScheduleService.update(result.shifts),
				MerchantScheduledBreaksService.update(result.lunchBreaks)
			]).then(
				function (results) {
					$scope.sending = false;
					toastr.success('updated successfully');
				},
				function (reason) {
					$scope.sending = false;
					toastr.error('Failed updating merchant');
				}
			);
		};
	});
