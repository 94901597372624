'use strict';

angular
	.module('bringgApp.directives')
	.constant('ALL_TEAMS_CHOICE_ID', -1)
	.constant('NO_TEAMS_CHOICE_ID', -2)
	.directive('teamsSelect', function (
		MerchantConfigurations,
		Authentication,
		Teams,
		ALL_TEAMS_CHOICE_ID,
		NO_TEAMS_CHOICE_ID
	) {
		return {
			templateUrl: 'scripts/directives/teams/teams-select.html',
			scope: {
				onSelect: '&'
			},
			link: function (scope, element, attrs) {
				var allTeamsChoice = {
					name: 'MAP.ALL_TEAMS',
					id: ALL_TEAMS_CHOICE_ID
				};

				var noTeamsChoice = {
					name: 'MAP.NO_TEAMS',
					id: NO_TEAMS_CHOICE_ID
				};

				scope.onSelectedItem = function (item) {
					if (!item || item.id === allTeamsChoice.id) {
						scope.selectedTeam = allTeamsChoice;
					} else {
						scope.selectedTeam = item;
					}

					scope.onSelect({ selectedTeam: scope.selectedTeam });
				};

				if (MerchantConfigurations.enable_teams) {
					scope.teams = [allTeamsChoice];

					Promise.all([Teams.all(), Authentication.featureFlags()])
						.then(function (results) {
							var teams = results[0];
							var emptyFiltering = results[1].empty_filtering;
							scope.teams = [allTeamsChoice, noTeamsChoice].concat(teams);
							if (emptyFiltering) {
								scope.selectedTeam = noTeamsChoice;
							} else {
								scope.selectedTeam = allTeamsChoice;
							}
						})
						.catch(function (err) {
							console.error('failed initializing teams select', err);
						});
				}

				scope.$on('config updated', function () {
					if (!MerchantConfigurations.enable_teams) {
						scope.teams = [];
					}
				});
			}
		};
	});
