'use strict';

angular
	.module('bringgApp')
	.controller('MapOverlaysController', function (
		$scope,
		$rootScope,
		toastr,
		MapOverlays,
		AddMapOverlayService,
		EditMapOverlayService,
		ITEMS_PER_PAGE
	) {
		$scope.currentPage = 1;
		$scope.numPerPage = ITEMS_PER_PAGE;
		$scope.totalItems = 0;
		$scope.mapOverlays = [];
		$scope.loading = true;
		$scope.headers = [
			['id', 'ID'],
			['title', 'Title'],
			['description', 'Description'],
			['filename', 'Filename'],
			['hidden', 'Hidden']
		];

		$scope.sort = {
			column: 'id',
			descending: false,
			index: 0
		};

		function updateMapOverlaysInScope(result) {
			$scope.loading = false;
			$scope.mapOverlays = result.map_overlays;
			$scope.totalItems = result.count || 0;
		}

		$scope.$on('config updated', function () {
			MapOverlays.all(updateMapOverlaysInScope);
		});

		$rootScope.$watch($rootScope.isLoggedIn, function () {
			if ($rootScope.isLoggedIn) {
				MapOverlays.all(updateMapOverlaysInScope);
			}
		});

		$scope.$on('newMapOverlayAdded', function (event, mapOverlay) {
			if ($scope.currentPage === 1) {
				$scope.mapOverlays.unshift(mapOverlay);
			}
			$scope.totalItems++;
		});

		$scope.$watch('currentPage + numPerPage', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			var begin = $scope.currentPage * $scope.numPerPage,
				end = begin + $scope.numPerPage;

			if ($scope.currentPage === 0) {
				return;
			}

			MapOverlays.all({ page: $scope.currentPage, query: $scope._getQueryObject() }, function (data) {
				$scope.mapOverlays = data.mapOverlays;
				$scope.totalItems = data.count;
			});
		});

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.headers[columnIdx][0]) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.headers[columnIdx][0];
				sort.descending = false;
				sort.index = columnIdx;
			}
		};

		$scope.selectedCls = function (columnIdx) {
			if (columnIdx === $scope.sort.index) {
				if ($scope.sort.descending) {
					return '&#9660;';
				} else {
					return '&#9650;';
				}
			}
		};

		$scope.handleAddMapOverlayClick = function () {
			AddMapOverlayService.open().then(
				function (result) {
					if (result === 'added') {
						$scope.totalItems++;
					}
				},
				function (reason) {}
			);
		};

		$scope.handleEditMapOverlayClick = function (mapOverlay) {
			EditMapOverlayService.open(mapOverlay).then(
				function (result) {
					if (result === 'deleted') {
						$scope.totalItems--;
					}
				},
				function (reason) {}
			);
		};
	});
