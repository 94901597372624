'use strict';

angular
	.module('bringgApp')
	.directive('vehiclesTable', function () {
		return {
			restrict: 'E',
			templateUrl: 'scripts/features/vehicles/vehicles.html',
			scope: {
				teamId: '<'
			},
			controller: 'VehiclesController'
		};
	})
	.controller('VehiclesController', function (
		$scope,
		$rootScope,
		VehiclesService,
		AddVehicleService,
		VehicleTypesService,
		$q,
		$translate,
		toastr
	) {
		$scope.vehicles = [];
		$scope.loading = true;
		$scope.isAdmin = $rootScope.sdk.session.user.admin;
		$scope.headers = [
			['id', 'VEHICLES.ID'],
			['name', 'VEHICLES.NAME'],
			['type', 'VEHICLES.TYPE'],
			['license_plate', 'VEHICLES.LICENSE_PLATE'],
			['model', 'VEHICLES.MODEL'],
			['color', 'VEHICLES.COLOR']
		];
		$scope.checkboxState = {
			allVehiclesCheckbox: false,
			selectedVehicles: {},
			isAnySelected: function () {
				return Object.values(this.selectedVehicles).indexOf(true) !== -1;
			},
			selectAll: function () {
				if ($scope.checkboxState.allVehiclesCheckbox) {
					_.forEach($scope.vehicles, function (vehicle) {
						$scope.checkboxState.selectedVehicles[vehicle.id] = true;
					});
				} else {
					$scope.checkboxState.selectedVehicles = {};
				}
			},
			isChecked: function (vehicleId) {
				return this.selectedVehicles[vehicleId] === true;
			},
			selectAllIfNeeded: function () {
				var isAllItemsExists = Object.keys(this.selectedVehicles).length === $scope.vehicles.length;

				if (!isAllItemsExists) {
					this.allVehiclesCheckbox = false;
					return;
				}

				var isAllSelected = Object.values(this.selectedVehicles).indexOf(false) === -1;

				this.allVehiclesCheckbox = isAllSelected;
			}
		};

		var MARKER_DOWN = '&#9660;',
			MARKER_UP = '&#9650;';

		$scope.sort = { column: 'id', descending: true, index: 0 };

		$scope.getVehicleIdsToDelete = function () {
			var vehiclesIdsToDelete = [];

			if ($scope.checkboxState.allVehiclesCheckbox) {
				vehiclesIdsToDelete = _.map($scope.vehicles, 'id');
			} else {
				_.forEach($scope.checkboxState.selectedVehicles, function (checkboxValue, vehicleId) {
					if (checkboxValue) {
						vehiclesIdsToDelete.push(parseInt(vehicleId));
					}
				});
			}

			return vehiclesIdsToDelete;
		};

		$scope.handleVehicleDeletion = function (vehicleId) {
			return VehiclesService.delete(vehicleId)
				.then(function () {
					var indexOfVehicle = _.findIndex($scope.vehicles, { id: vehicleId });

					$scope.vehicles.splice(indexOfVehicle, 1);
					delete $scope.checkboxState.selectedVehicles[vehicleId];
				})
				.catch(function (e) {
					console.error('failed to remove vehicle - ' + vehicleId, e);
					toastr.error($translate.instant('VEHICLES.FAILED_TO_DELETE') + ' - ' + vehicleId);
				});
		};

		$scope.filterUnassignedVehicle = function (vehicleId) {
			var vehicle = _.find($scope.vehicles, { id: vehicleId });

			return Boolean(vehicle.user_id) === false;
		};

		$scope.deleteSelectedVehicles = function () {
			if (!confirm($translate.instant('VEHICLES.CONFIRM_DELETE'))) {
				return;
			}

			var vehiclesIdsToDelete = $scope.getVehicleIdsToDelete();

			var vehiclesIdsWithoutUsers = _.filter(vehiclesIdsToDelete, $scope.filterUnassignedVehicle);
			var deleteVehiclesPromises = _.map(vehiclesIdsWithoutUsers, $scope.handleVehicleDeletion);

			var onAllVehiclesDeleted = function () {
				var amountOfVehiclesAssigned = vehiclesIdsToDelete.length - deleteVehiclesPromises.length;

				if (amountOfVehiclesAssigned) {
					toastr.error(
						amountOfVehiclesAssigned +
							'/' +
							vehiclesIdsToDelete.length +
							' ' +
							$translate.instant('VEHICLES.REMOVE_PART_ONLY')
					);
				} else {
					toastr.success($translate.instant('VEHICLES.DELETED_SUCCESSFULLY'));
				}
			};

			return $q
				.all(deleteVehiclesPromises)
				.then(onAllVehiclesDeleted)
				.catch(function (e) {
					console.error('failed to delete all vehicles', e);
					toastr.error($translate.instant('VEHICLES.FAILED_TO_DELETE_ALL_VEHICLES'));
				});
		};

		$scope.loadVehicles = function () {
			return VehiclesService.getAll().then(function (vehicles) {
				$scope.vehicles = _.filter(vehicles, function (vehicle) {
					return $scope.shouldShowVehicle(vehicle);
				});
			});
		};

		$scope.loadVehicleTypes = function () {
			return VehicleTypesService.getAll().then(function (vehicleTypes) {
				$scope.vehicleTypes = vehicleTypes;
			});
		};

		$scope.getVehicleTypeName = function (vehicleTypeId) {
			var vehicleType = _.find($scope.vehicleTypes, { id: vehicleTypeId });

			return vehicleType ? vehicleType.title : null;
		};

		$scope.vehicleAdded = function (event, newVehicle) {
			if ($scope.shouldShowVehicle(newVehicle)) {
				$scope.vehicles.unshift(newVehicle);
			}
		};

		$scope.onLoggedInChanged = function () {
			if ($rootScope.isLoggedIn) {
				return $scope.loadVehicles();
			}
		};

		$scope.shouldShowVehicle = function (vehicle) {
			return vehicle.team_id === $scope.teamId && !vehicle.is_trailer;
		};

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.headers[columnIdx][0]) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.headers[columnIdx][0];
				sort.descending = false;
				sort.index = columnIdx;
			}
		};

		$scope.addSortMarkerIfNeeded = function (columnIdx) {
			if (columnIdx !== $scope.sort.index) {
				return;
			}
			return $scope.sort.descending ? MARKER_DOWN : MARKER_UP;
		};

		$scope.handleAddVehicleClicked = function () {
			return AddVehicleService.open($scope.teamId).then(function () {});
		};

		$scope.addVehiclesCSV = function () {
			VehiclesService.addVehiclesCSV();
		};

		$scope.init = function () {
			var promises = [$scope.loadVehicles(), $scope.loadVehicleTypes()];

			$q.all(promises).then(
				function () {
					$scope.loading = false;
				},
				function (error) {
					toastr.error(error, $translate.instant('VEHICLES.VEHICLE_LOAD_FAILED'));
				}
			);
		};

		$scope.init();

		$rootScope.$watch($rootScope.isLoggedIn, $scope.onLoggedInChanged);

		$scope.$on('newVehicleAdded', $scope.vehicleAdded);

		$scope.$on('config updated', $scope.init);
	});
