'use strict';

angular.module('bringgApp').directive('distanceLocaleInput', function (MerchantConfigurations) {
	return {
		require: 'ngModel',
		link: function (scope, element, attr, ngModel) {
			// toFixed returns a string and we must convert it back to number thats why duplicated "parseFloat"

			ngModel.$formatters.push(function (distanceTraveled) {
				if (!distanceTraveled) {
					return distanceTraveled;
				}

				if (MerchantConfigurations.country_code === 'us') {
					return parseFloat((parseFloat(distanceTraveled) * 0.621371).toFixed(2));
				}

				return parseFloat(parseFloat(distanceTraveled).toFixed(2));
			});

			ngModel.$parsers.push(function (distanceTraveled) {
				if (!distanceTraveled) {
					return distanceTraveled;
				}

				if (MerchantConfigurations.country_code === 'us') {
					return parseFloat((parseFloat(distanceTraveled) / 0.621371).toFixed(2));
				}

				return parseFloat(parseFloat(distanceTraveled).toFixed(2));
			});
		}
	};
});
