'use strict';

angular
	.module('bringgApp')
	.service(
		'RouteAssigner',
		function (
			$uibModal,
			RunsService,
			ReactModals,
			Teams,
			$q,
			TranslationService,
			toastr,
			Authentication,
			AssignDriverValidations,
			TasksData
		) {
			var _this = this;
			_this.canOpenPlannedRunsUserAssigner = canOpenPlannedRunsUserAssigner;
			_this.assignVehicle = assignVehicle;
			_this.assignDriver = assignDriver;
			_this.isRouteAssignAllowed = isRouteAssignAllowed;
			_this.assignDriverForPlannedRun = assignDriverForPlannedRun;
			this.allowedActions = {
				assign: 'assign',
				unassign: 'unassign',
				merge: 'merge',
				newRouteCreation: 'new_route_creation'
			};

			function assignVehicle(
				data,
				tasks,
				handleListUpdate,
				hideUnassignVehicleButton,
				doNotNotifyServerOnAssign
			) {
				const defer = $q.defer();

				ReactModals.openRunVehicleAssigner({
					data: {
						...data,
						tasks: data.tasksIds.map(taskId => TasksData.get(taskId))
					},
					tasks: tasks,
					handleListUpdate: handleListUpdate,
					hideUnassignVehicleButton: hideUnassignVehicleButton,
					doNotNotifyServerOnAssign: doNotNotifyServerOnAssign,
					onClose: defer.resolve
				});

				return defer.promise;
			}

			function assignDriver(eventData, handleListUpdate, data, params) {
				if (data.sourceTasks && !data.sourceRun && isAssigningSingleFullRouteByTasks(data)) {
					const runId = data.sourceTasks[0].run_id;
					data.sourceTasks = undefined;
					data.sourceRun = RunsService.getFromStore(runId);
				}

				let sourceTasks =
					data.sourceTasks || data.tasks.filter(task => task.run_id === data.sourceRun.id) || [];
				const isAllowedAnswer = AssignDriverValidations.isAllowedToAssignSelectedTasks(sourceTasks);

				if (isAllowedAnswer.errMessage?.length > 0) {
					toastr.error(TranslationService.instant(isAllowedAnswer.errMessage));
				}
				if (!isAllowedAnswer.canAssign || isAllowedAnswer.assignableTasks?.length === 0) {
					return;
				}

				if (data.sourceTasks) {
					data.assignableTasks = isAllowedAnswer.assignableTasks;
				}

				if (canOpenPlannedRunsUserAssigner()) {
					assignDriverForPlannedRun(data, params);
				} else {
					assignDriverForNonPlannedRun(eventData, data.tasks, handleListUpdate);
				}
			}

			function canOpenPlannedRunsUserAssigner() {
				return (
					Authentication.currentUser()?.feature_flags.assign_run_modal && RunsService.isPlannedRunsEnabled()
				);
			}

			function assignDriverForNonPlannedRun(eventData, tasks, handleListUpdate) {
				assign(eventData, tasks, handleListUpdate, {
					templateUrl: 'scripts/features/assign_to_route/assign_driver_to_route_modal/template.html',
					assignFunc: RunsService.assignDriverToRun,
					updateFunc: function (task, selectedDriverId) {
						task.user_id = selectedDriverId;
					}
				});
			}

			function checkIfAllRunWasSelected(selectedRun, selectedTasks, allTasksByRun) {
				if (selectedRun) {
					return true;
				}

				const selectedTasksByRun = {};
				selectedTasks.forEach(task => {
					if (selectedTasksByRun[task.run_id]) {
						selectedTasksByRun[task.run_id].push(task.id);
					} else {
						selectedTasksByRun[task.run_id] = [task.id];
					}
				});
				return Object.entries(selectedTasksByRun).every(([key, value]) => {
					return allTasksByRun?.[key]?.length !== value.length;
				});
			}

			const getRouteTasks = (sourceRun, allTasksByRun) => {
				if (!sourceRun) {
					return undefined;
				}

				return allTasksByRun[sourceRun.id];
			};

			function assignDriverForPlannedRun(data, params) {
				var teamsIdsOfRunToLoad = {};
				var promises = [];

				return addRunTasks(data.sourceRun, data.tasks, params.readyToExecute, params.isUnsavedRun).then(
					function (allTasks) {
						if (data.sourceRun) {
							promises = allTasks.map(function (task) {
								var isSourceRun = task.run_id === data.sourceRun.id;

								if (isSourceRun && task.team_ids[0] && !teamsIdsOfRunToLoad[task.team_ids[0]]) {
									teamsIdsOfRunToLoad[task.team_ids[0]] = true;
									return Teams.getDrivers(task.team_ids[0]);
								}
							});
						} else {
							promises.push(Teams.getDrivers(data.sourceTasks[0].team_id));
						}
						const defer = $q.defer();
						$q.all(promises).then(function (driversOfTeams) {
							const allTasksByRun = {};
							allTasks.forEach(task => {
								if (allTasksByRun[task.run_id]) {
									allTasksByRun[task.run_id].push(task);
								} else {
									allTasksByRun[task.run_id] = [task];
								}
							});
							const isAllRunWasSelected = checkIfAllRunWasSelected(
								data.sourceRun,
								data.tasks,
								allTasksByRun
							);

							ReactModals.openRunUserAssigner({
								onClose: defer.resolve,
								source: {
									run: data.sourceRun,
									tasks: data.assignableTasks,
									isAllRunWasSelected,
									runTasks: getRouteTasks(data.sourceRun, allTasksByRun)
								},
								data: {
									tasksRunIds: _.chain(allTasks)
										.map('run_id')
										.uniq()
										.filter(runId => typeof runId === 'number')
										.value(),
									drivers: _.chain(driversOfTeams).flatten().uniqBy('id').compact().value(),
									allowedActions: params.allowedActions,
									doNotNotifyServerOnAssign: params.doNotNotifyServerOnAssign
								},
								readyToExecute: params.readyToExecute
							});
						});
						return defer.promise;
					}
				);
			}

			function addRunTasks(sourceRun, tasks, readyToExecute, isUnsavedRun) {
				var allTasks = tasks.slice();

				if (!sourceRun || isUnsavedRun) {
					return $q.resolve(allTasks);
				}

				return RunsService.load(sourceRun.id, true).then(function (run) {
					run.tasks_count = run.tasks.length;

					run.tasks.forEach(function (task) {
						if (task.ready_to_execute !== readyToExecute) {
							allTasks.push(task);
						}
					});

					return allTasks;
				});
			}

			function assign(data, tasks, handleListUpdate, params) {
				var runId = data.runId;
				var itemId = data.itemId;
				var tasksIds = data.tasksIds;

				var resolveData = {};

				Object.keys(data).forEach(function (field) {
					resolveData[field] = _.constant(data[field]);
				});

				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: params.templateUrl,
					controller: 'AssignToRouteModalController',
					controllerAs: '$ctrl',
					size: 'lg',
					appendTo: angular.element(document.body),
					windowClass: 'assign-to-route-modal-parent',
					resolve: resolveData
				});

				return modalInstance.result.then(function (selectedItem) {
					if (!selectedItem) {
						return;
					}

					params.assignFunc(runId, selectedItem.id, itemId, tasksIds).then(function (response) {
						if (response && response.success) {
							RunsService.bulkUpdateRunTasks(tasks, runId, function (task) {
								params.updateFunc(task, selectedItem.id);
							});

							handleListUpdate();
						}
					});
				});
			}

			function isAssigningSingleFullRouteByTasks(data) {
				const runIds = new Set(data.sourceTasks.map(task => task.run_id));
				const firstRunId = data.sourceTasks[0].run_id;

				if (firstRunId && runIds.size === 1) {
					const partialRuns = RunsService.getPartialRunIdsByTasks(data.sourceTasks, data.tasks);
					return Object.keys(partialRuns).length === 0;
				}
			}

			function isRouteAssignAllowed(tasks) {
				return (
					canOpenPlannedRunsUserAssigner() &&
					AssignDriverValidations.isAllowedToAssignSelectedTasks(tasks).canAssign
				);
			}
		}
	);
