'use strict';

angular
	.module('bringgApp')
	.constant('WAY_POINT_TYPES', {
		ANY: 0,
		FIRST: 1,
		LAST: 2
	})
	.constant('WAY_POINT_ARRIVAL_TYPES', {
		ANYTIME: 0,
		BEFORE_ARRIVAL: 1,
		AFTER_ARRIVAL: 2
	})
	.controller(
		'TaskCancelReasonsController',
		function (
			$scope,
			MerchantConfigurationService,
			Authentication,
			toastr,
			SelectReasonIconService,
			MerchantService,
			Payments,
			CustomerConfiguration,
			$q,
			Application,
			MerchantConfigurations,
			ReasonToCancelTask,
			$translate,
			TaskCancelReasonActionsProvider,
			WAY_POINT_TYPES,
			WAY_POINT_ARRIVAL_TYPES
		) {
			$scope.loading = true;
			$scope.saving = false;
			$scope.failedDeliveryApp = null;
			$scope.maxCancelReasons = 15;
			var failedDeliveryApp = null;
			$scope.data = {
				failedDeliveryApplicationInstalled: false,
				disableReturnOrder: false
			};
			var failedDeliveryAmcData = {};

			$scope.waypointArrivalTypeEnabled = false;

			$scope.reason_types = [
				{ value: 0, label: $translate.instant('TASK_CANCEL_CONFIGURATION.DEFAULT') },
				{ value: 1, label: $translate.instant('TASK_CANCEL_CONFIGURATION.PICKUP') },
				{ value: 2, label: $translate.instant('TASK_CANCEL_CONFIGURATION.DROPOFF') },
				{ value: 3, label: $translate.instant('TASK_CANCEL_CONFIGURATION.PICK_UP_AND_DROPOFF') },
				{ value: 6, label: $translate.instant('TASK_CANCEL_CONFIGURATION.CURBSIDE') }
			];

			$scope.way_point_types = [
				{ value: WAY_POINT_TYPES.ANY, label: $translate.instant('TASK_CANCEL_CONFIGURATION.ANY_WAYPOINT') },
				{ value: WAY_POINT_TYPES.FIRST, label: $translate.instant('TASK_CANCEL_CONFIGURATION.FIRST_WAYPOINT') },
				{ value: WAY_POINT_TYPES.LAST, label: $translate.instant('TASK_CANCEL_CONFIGURATION.LAST_WAYPOINT') }
			];

			$scope.way_point_arrival_types = [
				{
					value: WAY_POINT_ARRIVAL_TYPES.ANYTIME,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.ANYTIME')
				},
				{
					value: WAY_POINT_ARRIVAL_TYPES.BEFORE_ARRIVAL,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.BEFORE_ARRIVAL')
				},
				{
					value: WAY_POINT_ARRIVAL_TYPES.AFTER_ARRIVAL,
					label: $translate.instant('TASK_CANCEL_CONFIGURATION.AFTER_ARRIVAL')
				}
			];

			$scope.user_types = ReasonToCancelTask.user_types;
			$scope.planning_phase_exists = MerchantConfigurations.planning_phase_exists;
			$scope.reason_numbers = _.range(30);
			$scope.days_ahead_numbers = _.map(_.range(-1, 30), function (number) {
				return {
					value: number,
					text: number === -1 ? $translate.instant('TASK_CANCEL_CONFIGURATION.SAME_RUN') : number
				};
			});

			$scope._shouldEnableSendToDispatch = function (reason) {
				var enableSendToDispatch =
					(_.isNil(reason.days_ahead) && reason.reschedule_as_virtual_from === 0) || reason.days_ahead === 0;
				if (!enableSendToDispatch && reason.send_to_dispatch) {
					reason.send_to_dispatch = false;
				}
				return enableSendToDispatch;
			};

			$scope._initialize = function () {
				failedDeliveryApp = _.find(MerchantConfigurations.applications, {
					uuid: Application.APPLICATIONS.FailedDeliveryManager
				});
				$scope.data.failedDeliveryApplicationInstalled = !_.isUndefined(failedDeliveryApp);

				let appConfigPromise;
				if ($scope.data.failedDeliveryApplicationInstalled) {
					appConfigPromise = Application.getConfigByAppUuid(Application.APPLICATIONS.FailedDeliveryManager);
				}

				// LOAD DATA
				$q.all([ReasonToCancelTask.all().$promise, appConfigPromise, Authentication.featureFlags()]).then(
					([reasons, amc, featureFlags]) => {
						failedDeliveryAmcData = amc?.data ?? {};
						$scope.data.disableReturnOrder = amc?.data?.disable_return_order ?? false;

						$scope.cancelReasonsConfiguration = reasons.reasons_to_cancel_task;
						$scope.actionDataByReason = TaskCancelReasonActionsProvider.getByReason(
							$scope.cancelReasonsConfiguration
						);
						TaskCancelReasonActionsProvider.filterExcluded(
							$scope.cancelReasonsConfiguration,
							$scope.actionDataByReason
						);

						$scope.waypointArrivalTypeEnabled = featureFlags.waypoint_arrival_type_enabled === true;

						_.each($scope.cancelReasonsConfiguration, function (cancelReason) {
							cancelReason.way_point_type = cancelReason.way_point_type || WAY_POINT_TYPES.ANY;
							cancelReason.return_order = cancelReason.return_order ?? true;
							cancelReason.days_ahead = cancelReason.days_ahead || 0; // server default
							cancelReason.way_point_arrival_type =
								cancelReason.way_point_arrival_type || WAY_POINT_ARRIVAL_TYPES.ANYTIME;

							ReasonToCancelTask.flagsToUserType(cancelReason);
						});
						$scope.loading = false;
					},
					function () {
						toastr.error('Failed loading configuration');
					}
				);
			};

			$scope._initialize();

			$scope.$on('config updated', $scope._initialize);

			$scope.addCancelReason = function () {
				if ($scope.cancelReasonsConfiguration.length < $scope.maxCancelReasons) {
					$scope.cancelReasonsConfiguration.push({
						reason: '',
						order_type: $scope.reason_types[0].value,
						way_point_type: WAY_POINT_TYPES.ANY,
						way_point_arrival_type: WAY_POINT_ARRIVAL_TYPES.ANYTIME,
						user_type: ReasonToCancelTask.USER_TYPES.BOTH,
						days_ahead: 0,
						return_order: true
					});
				}
			};

			$scope.removeCancelReason = function (item) {
				$scope.cancelReasonsConfiguration = _.without(
					$scope.cancelReasonsConfiguration,
					_.find($scope.cancelReasonsConfiguration, item)
				);
			};

			$scope.onChangeOrderType = function (reason) {
				if ($scope.isCurbside(reason)) {
					reason.days_ahead = null;
					reason.reschedule_as_virtual_from = null;
					reason.max_attempts = null;
				} else {
					reason.days_ahead = reason.days_ahead || 0;
				}
			};

			$scope.isCurbside = function (reason) {
				return reason.order_type === 6;
			};

			$scope.isSameRun = function (reason) {
				return reason.days_ahead === -1;
			};

			$scope.onDaysAheadChange = function (reason) {
				if ($scope.isSameRun(reason)) {
					reason.reschedule_as_virtual_from = null;
					reason.max_attempts = null;
				}
			};

			$scope.saveCancelReasonsConfiguration = function () {
				if ($scope.saving) {
					return;
				}

				$scope.saving = true;

				_.each($scope.cancelReasonsConfiguration, function (cancelReason) {
					ReasonToCancelTask.userTypeToFlags(cancelReason);
				});

				var installOrRemoveApp = $q.resolve();

				if ($scope.data.failedDeliveryApplicationInstalled && !failedDeliveryApp) {
					installOrRemoveApp = Application.add({ uuid: Application.APPLICATIONS.FailedDeliveryManager });
				} else if (!$scope.data.failedDeliveryApplicationInstalled && failedDeliveryApp) {
					installOrRemoveApp = Application.delete({ id: failedDeliveryApp.id });
				}

				var cancelReasonsToSave = TaskCancelReasonActionsProvider.appendExcluded(
					$scope.cancelReasonsConfiguration,
					$scope.actionDataByReason
				);

				let updateAppConfigPromise = Promise.resolve();
				if ($scope.data.failedDeliveryApplicationInstalled && failedDeliveryApp) {
					updateAppConfigPromise = Application.updateConfig(failedDeliveryApp.id, {
						...failedDeliveryAmcData,
						disable_return_order: $scope.data.disableReturnOrder
					});
				}

				$q.all([
					ReasonToCancelTask.create({ cancel_reasons: cancelReasonsToSave }).$promise,
					installOrRemoveApp,
					updateAppConfigPromise
				]).then(
					function (results) {
						toastr.success($translate.instant('TASK_CANCEL_CONFIGURATION.SAVED'));
						var reasonToCancelTaskResult = results[0];
						$scope.saving = false;
						if (
							reasonToCancelTaskResult.success &&
							reasonToCancelTaskResult.task_cancel_reasons &&
							reasonToCancelTaskResult.task_cancel_reasons.length > 0
						) {
							_.each(reasonToCancelTaskResult.task_cancel_reasons, function (task_cancel_reason) {
								var existing_task_cancel_reason = _.find($scope.cancelReasonsConfiguration, {
									reason: task_cancel_reason.reason
								});

								if (!_.isUndefined(existing_task_cancel_reason)) {
									existing_task_cancel_reason.id = task_cancel_reason.id;
								}
							});
							var newTaskCancelReasons = TaskCancelReasonActionsProvider.getByReason(
								reasonToCancelTaskResult.task_cancel_reasons
							);
							Object.assign($scope.actionDataByReason, newTaskCancelReasons);
						}
					},
					function () {
						$scope.saving = false;
						toastr.error('Failed saving configuration');
					}
				);
			};
		}
	);
