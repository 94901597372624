'use strict';

angular.module('bringgApp').factory('GeocodingService', function ($rootScope, BringgSDK) {
	function geocodingService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.geocoding[method].apply(bringg.geocoding, args);
			});
		}.bind(this);
	}

	var GeocodingService = {};

	GeocodingService.autoComplete = geocodingService('autoComplete');
	GeocodingService.getPlaceDetails = geocodingService('getPlaceDetails');

	return GeocodingService;
});
