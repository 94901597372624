'use strict';
/*global google:false */

angular
	.module('bringgApp')
	.controller(
		'CustomerController',
		function (
			$scope,
			Customers,
			$routeParams,
			Task,
			toastr,
			Lightbox,
			FileUploader,
			WEB_API_URL,
			Authentication,
			localStorageService,
			bringg_utils,
			Geolocation,
			MerchantConfigurations,
			Teams,
			$translate,
			PhoneNumberService,
			IMAGE_SIZE
		) {
			$scope.loading = true;
			$scope.notFound = false;
			$scope.teamsEnabled = MerchantConfigurations.enable_teams || false;
			$scope.forceCustomerLinkedToTeam = MerchantConfigurations.force_customer_linked_to_team || false;
			$scope.teams = [];
			$scope.customerTeams = [];
			$scope.customerKinds = Customers.getCustomerKinds();

			$scope.formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

			$scope.updateCustomer = function () {
				if ($scope.forceCustomerLinkedToTeam) {
					this.customer.team_ids = _.map($scope.customerTeams, 'id');
				}

				Customers.update(
					this.customer,
					function (data, status, headers, config) {
						if (data.success) {
							toastr.success('Customer was updated!');
							$scope._renderCustomer(data.customer);
							$scope.editMode = false;
						} else {
							toastr.error(data.message);
						}
					},
					function (data, status, headers, config) {
						toastr.error('Failed updating customer');
					}
				);
			};

			$scope.customerSubmit = function () {
				if ($routeParams.id) {
					this.updateCustomer();
				} else {
					this.registerCustomer();
				}
			};

			var geocoder = new google.maps.Geocoder();

			$scope.geocodeAddress = function () {
				geocoder.geocode({ address: this.customer.address }, function (results, status) {
					if (status === google.maps.GeocoderStatus.OK) {
						$scope.$apply(function () {
							$scope.customer.lat = results[0].geometry.location.lat();
							$scope.customer.lng = results[0].geometry.location.lng();
						});
					} else {
						toastr.error('Geocode was not successful for the following reason: ' + status);
					}
				});
			};

			$scope.handleUploadButtonClick = function () {
				angular.element('#uploadMe').trigger('click');
			};

			//==============================================
			// Image uploading
			//==============================================
			$scope._createUploader = function () {
				var uploader = ($scope.image_uploader = new FileUploader({
					url: WEB_API_URL + '/customers/' + $scope.customer.id + '/profile_image',
					withCredentials: true,
					headers: {
						'x-ov-user-email': Authentication.currentUser().email,
						Authorization: localStorageService.get('auth_key')
					}
				}));

				uploader.filters.push(bringg_utils.imageFilter());
				uploader.filters.push(bringg_utils.sizeFilter(IMAGE_SIZE.DEFAULT));

				uploader.onWhenAddingFileFailed = function (item, filter, options) {
					if (filter.name === 'imageFilter') {
						toastr.error('Only image files please. SUPPORTED: jpg, jpeg, png, bmp, gif');
					} else if (filter.name === 'sizeFilter') {
						toastr.error('Your image is too big please provide a different one. MAX SIZE: 2 MB');
					}
				};

				uploader.onAfterAddingFile = function (addedFile) {
					$scope.image_uploader.queue.push(addedFile);
					$scope.image_uploader.uploadAll();
				};

				uploader.onCompleteItem = function (item, response) {
					if (response.success) {
						$scope.customer.image = response.profile_image.url;
						toastr.success($translate.instant('CUSTOMERS.IMAGE_UPLOADED_MESSAGE'));
					}
				};
			};

			$scope.filterCustomerTeamsByIds = function (teamIds) {
				return _.filter($scope.teams, function (team) {
					return _.indexOf(teamIds, team.id) !== -1;
				});
			};

			$scope.onTeamSelect = function (team) {
				$scope.customerTeams.push(team);
			};

			$scope.onTeamRemove = function (team) {
				var teamIndex = _.indexOf($scope.customerTeams, team);
				if (teamIndex !== -1) {
					$scope.customerTeams.splice(teamIndex, 1);
				}
			};

			$scope.loadCustomer = function (routeId) {
				Customers.get(routeId, $scope._renderCustomer, function () {
					$scope.loading = false;
					$scope.notFound = true;
				});
			};

			$scope._renderCustomer = function (data) {
				if (data) {
					$scope.loading = false;
					$scope._originalCustomer = data;
					$scope.customer = angular.copy(data);

					$scope.customerKindLabel = $scope.customerKinds.find(
						customerKind => customerKind.value === $scope.customer.kind
					)?.label;
					if ($scope.forceCustomerLinkedToTeam) {
						$scope.customerTeams = $scope.filterCustomerTeamsByIds($scope.customer.team_ids);
					}

					$scope._createUploader();
				} else {
					$scope.loading = false;
					$scope.notFound = true;
				}
			};

			if ($routeParams.id) {
				if ($scope.forceCustomerLinkedToTeam) {
					Teams.all(function (teams) {
						$scope.teams = teams;
						$scope.loadCustomer($routeParams.id);
					});
				} else {
					$scope.loadCustomer($routeParams.id);
				}
			}

			$scope.editMode = false;
			$scope.handleEditCustomerClick = function () {
				$scope.editMode = true;
			};

			$scope.handleCancelClick = function () {
				$scope.editMode = false;
				$scope._renderCustomer(angular.copy($scope._originalCustomer));
			};

			$scope.handleSeeInMapClick = function () {
				Lightbox.openModal(
					[
						{
							url: Geolocation.generateStaticMap(
								$scope.customer.lat,
								$scope.customer.lng,
								$scope.customer.lat,
								$scope.customer.lng
							)
						}
					],
					0
				);
			};
		}
	);
