/**
 * Created by liorsion on 1/18/17.
 */
'use strict';

angular
	.module('bringgApp')
	.factory('Privilege', function ($resource, WEB_API_URL) {
		var Privilege = $resource(
			WEB_API_URL + '/privileges/:id',
			{ id: '@id' },
			{
				all: { method: 'GET', isArray: false, url: WEB_API_URL + '/privileges/' },
				update: { method: 'PATCH', url: WEB_API_URL + '/privileges/:id' },
				byUser: { method: 'GET', isArray: true, url: WEB_API_URL + '/users/:user_id/privilege' },
				getList: { method: 'GET', isArray: false, url: WEB_API_URL + '/privileges/get_list' }
			}
		);

		return Privilege;
	})
	.factory('Privileges', function ($rootScope, Privilege, $q, Authentication) {
		var isAdmin = Authentication.currentUser().admin;
		var Privileges = {
			privileges: {
				privileges: [],
				count: 0
			},
			emptyPrivilege: { id: 0, title: 'No Privileges' }
		};

		$rootScope.$on('loggedout', function () {
			Privileges.reset();
			delete Privileges.privileges.$resolved;
		});

		Privileges.reset = function () {
			Privileges.privileges = {
				privileges: [],
				count: 0
			};
			Privileges.defaultPrivilege = null;
		};

		Privileges._initPrivileges = function () {
			if (isAdmin) {
				Privileges.privileges = Privilege.all();
				Privileges.privileges.$promise.then(function (result) {
					result.privileges.unshift(Privileges.emptyPrivilege);
				});
			}
		};

		$rootScope.$on('config updated', Privileges._initPrivileges);
		Privileges._initPrivileges();

		Privileges.add = function (privilegeData, cb) {
			var privilege = new Privilege(privilegeData);
			privilege.$save(function (result) {
				if (result.success) {
					Privileges.privileges.privileges.push(result.privilege);
					Privileges.privileges.count++;
				}
				if (cb) {
					cb(result);
				}
			});
		};

		Privileges.update = function (data) {
			return Privilege.update(data).$promise.then(function (response) {
				if (!response.success) {
					return $q.reject(new Error(response.message));
				}

				return response.privilege;
			});
		};

		Privileges.all = function () {
			var cb = getSuccessCallback(arguments);

			return Privileges.privileges.$promise.then(function (privileges) {
				if (cb) {
					cb(Privileges.privileges);
				}
				return privileges;
			});
		};

		return Privileges;
	});
