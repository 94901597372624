'use strict';

angular.module('bringgApp').factory('VehiclesService', function (BringgSDK, $uibModal, $q, VehicleTypesService) {
	function vehiclesService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.v2.vehicles[method].apply(bringg.v2.vehicles, args);
			});
		}.bind(this);
	}

	var VehiclesService = {};

	VehiclesService.load = vehiclesService('load');
	VehiclesService.getAll = vehiclesService('loadAll');
	VehiclesService.create = vehiclesService('create');
	VehiclesService.update = vehiclesService('update');
	VehiclesService.delete = vehiclesService('delete');
	VehiclesService.onUnassigned = vehiclesService('onUnassigned');
	VehiclesService.onUpdate = vehiclesService('onUpdate');
	VehiclesService.onDelete = vehiclesService('onDelete');

	VehiclesService.getAllByTeams = function (teamIds, vehicles) {
		return _.filter(vehicles, function (vehicle) {
			if (!vehicle.team_id) {
				return true;
			}
			return _.includes(teamIds, vehicle.team_id);
		});
	};

	VehiclesService.byUser = function (user) {
		return VehiclesService.getAll().then(function (vehicles) {
			return _.find(vehicles, { id: user.vehicle_id });
		});
	};

	VehiclesService.getVehicleTypeByVehicleId = function (vehicle_id) {
		return VehiclesService.load(vehicle_id).then(function (vehicle) {
			if (vehicle && vehicle.vehicle_type_id) {
				return VehicleTypesService.get(vehicle.vehicle_type_id);
			}
			return null;
		});
	};

	VehiclesService.getVehiclesById = function (vehicles) {
		return _.keyBy(vehicles, 'id');
	};

	VehiclesService.addVehiclesCSV = function () {
		var deferred = $q.defer();

		var modalInstance = $uibModal.open({
			controller: 'CSVUploadModalController',
			templateUrl: 'scripts/features/csv_upload_modal/csv-upload-modal.html',
			backdrop: 'static',
			resolve: {
				csvUploadPath: function () {
					return '/vehicles/import_from_csv';
				},
				csvTestUploadPath: function () {
					return null;
				},
				importedResult: function () {
					return 'imported_vehicles';
				}
			}
		});

		modalInstance.result.then(
			function (result) {
				deferred.resolve(result);
			},
			function (reason) {
				deferred.reject();
			}
		);

		return deferred.promise;
	};

	return VehiclesService;
});
