/*global moment:false */
'use strict';

angular.module('bringgApp.filters').filter('alertTypeFilter', function ($translate) {
	return function (alert, configurations) {
		if (!alert) {
			return;
		}
		switch (parseInt(alert.alert_type)) {
			case 1:
				return $translate.instant('ALERTS.ORDER_NOT_ACCEPTED_YET');
			case 2:
				return $translate.instant('ALERTS.ORDER_IS_ABOUT_TO_LET');
			case 3:
				return $translate.instant('ALERTS.SMS_SENDING_FAILED');
			case 4:
				return $translate.instant('ALERTS.MISSED_CLIENT_MESSAGE');
			case 5:
				return $translate.instant('ALERTS.FAILED_CONSUMER_APP');
			case 6:
				return $translate.instant('ALERTS.STAYS_TOO_LONG_ON_SITE');
			case 7:
				return $translate.instant('ALERTS.UNKNOWN');
			case 8:
				return $translate.instant('ALERTS.PHONE_STOPPED_REPORTING');
			case 9:
				return $translate.instant('ALERTS.DRIVER_LOW_BATTERY');
			case 10:
				return $translate.instant('ALERTS.DRIVER_NEEDS_TO_LOG_IN');
			case 11:
				return $translate.instant('ALERTS.DRIVER_LOST_GPS');
			case 12:
				return $translate.instant('ALERTS.ROGUE_CUSTOMER_REGISTRATION_SUSPECTED');
			case 13:
				return $translate.instant('ALERTS.ROGUE_ORDER_SUSPECTED');
			case 14:
				return $translate.instant('ALERTS.FAILED_EMAIL');
			case 15:
				return $translate.instant('ALERTS.DRIVER_FORCE_CLOSED_APP');
			case 16:
				return $translate.instant('ALERTS.ADDRESS_CANT_BE_FOUND');
			case 17:
				return $translate.instant('ALERTS.DONE_LAST_ORDER');
			case 18:
				return $translate.instant('ALERTS.DRIVER_OFF_ROUTE');
			case 19:
				return $translate.instant('ALERTS.CHECKIN_FAR_FROM_LOCATION');
			case 20:
				return $translate.instant('ALERTS.SCANNING_OUTSIDE_GEOFENCE');
			case 21:
				return $translate.instant('ALERTS.DRIVER_ENDS_SHIFT_WITH_OPEN_TASKS');
			case 22:
				return $translate.instant('ALERTS.COULD_NOT_CREATE_RETURNING_TASK');
			case 23:
				return $translate.instant('ALERTS.DRIVER_ENDS_SHIFT_BEFORE_SCHEDULE');
			case 24:
				return $translate.instant('ALERTS.DRIVER_DEPOSIT_CLIENT_DEPOSIT_AMOUNT_DIFFERENT_FROM_SERVER');
			case 25:
				return $translate.instant(
					'ALERTS.DRIVER_DEPOSIT_CLIENT_DEPOSIT_AMOUNT_BASED_ON_TASK_IDS_DIFFERENT_FROM_SERVIER'
				);
			case 26:
				return $translate.instant('ALERTS.REQUIRE_MORE_DRIVERS');
			case 27:
				return $translate.instant('ALERTS.TOO_MANY_DRIVERS');
			case 28:
				return $translate.instant('ALERTS.USER_RATED_BELOW_THRESHOLD', alert && alert.extra_data);
			case 29:
				return $translate.instant('ALERTS.WAYPOINT_NOT_GEOCODED');
			case 30:
				return $translate.instant('ALERTS.DRIVER_BLUETOOTH_IS_OFF');
			case 31:
				return $translate.instant('ALERTS.ETA_TO_HOME');
			case 32:
				return $translate.instant('ALERTS.UNASSIGNED_TASK_THAT_MUST_LEAVE');
			case 33:
				return $translate.instant('ALERTS.GPS_IS_OFF');
			case 34:
				return $translate.instant('ALERTS.FLEET_FAILED_TO_FIND_DRIVER');
			case 35:
				return $translate.instant('ALERTS.FLEET_UNABLE_TO_DELIVER');
			case 36:
				return $translate.instant('ALERTS.UNASSIGNED_BEFORE_SCHEDULE');
			case 37:
				return $translate.instant('ALERTS.UNACCEPTED_BEFORE_SCHEDULE');
			case 38:
				return $translate.instant('ALERTS.NOT_STARTED_BEFORE_SCHEDULE');
			case 39:
				return $translate.instant('ALERTS.ROUTE_OPTIMIZATION_FAILED', alert && alert.extra_data);
			case 40:
				var reason = 'Unknown';
				if (alert && alert.extra_data && alert.extra_data.reason) {
					reason = $translate.instant(
						'ROUTE_OPTIMIZATION_FAILED_TO_ASSIGNED_REASONS.' + alert.extra_data.reason,
						alert.extra_data
					); // added extra data in case need nesting fields
				}
				return $translate.instant('ALERTS.VRP_AUTO_DISPATCH_FAILED_TO_ASSIGN_TASK', { reason: reason });
			case 41:
				return $translate.instant('ALERTS.DRIVER_STARTED_UNPRIORITIZED_TASK');
			case 42:
				return $translate.instant('ALERTS.DRIVER_CANCELLED_ORDER');
			case 43:
				if (!alert || !alert.extra_data || !alert.extra_data.reason) {
					return $translate.instant('ALERTS.BAD_OPTIMIZATION_PARAMETERS');
				}
				return $translate.instant('ALERTS.BAD_OPTIMIZATION_PARAMETERS.' + alert.extra_data.reason, {
					invalid_params: alert.extra_data.invalid_params
				});
			case 44:
				return $translate.instant('ALERTS.FAILED_TO_CREATE_FLEET_DELIVERY');
			case 45:
				if (!alert || !alert.extra_data || !alert.extra_data.reason) {
					return $translate.instant('ALERTS.ROUTE_OPTIMIZATION_ORDERS_LEFT_OUTSIDE_OPTIMIZATION');
				}
				return $translate.instant(
					'ALERTS.ROUTE_OPTIMIZATION_ORDERS_LEFT_OUTSIDE_OPTIMIZATION.' + alert.extra_data.task_ids
				);
			case 46:
				var reason = alert && alert.extra_data && alert.extra_data.reason;
				if (alert.task) {
					return $translate.instant('ALERTS.ROUTE_OPTIMIZATION_TASKS_REMOVED_BEFORE_OPTIMIZATION.' + reason, {
						task_id: alert.task.id
					});
				}
				return $translate.instant('ALERTS.ROUTE_OPTIMIZATION_TASKS_REMOVED_BEFORE_OPTIMIZATION.' + reason);
			case 47:
				if (alert.extra_data) {
					return (
						alert.extra_data.fleet_name +
						' ' +
						$translate.instant('ALERTS.FAILED_TO_CANCEL_FLEET_DELIVERY') +
						' ' +
						alert.extra_data.task_external_id
					);
				}
				return $translate.instant('ALERTS.FAILED_TO_CANCEL_FLEET_DELIVERY');
			/**
			 * the following alert list (starting with 100 and prefixed TMP) is temporary. it was defined this way to allow delivering https://bringg.atlassian.net/browse/SERVER-37682 on time
			 * in the near future they will be replaced with custom alerts with different subtypes to represent the different alerts
			 */
			case 100:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_ORDER_TOTAL_WEIGHT_CHANGED');
			case 101:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_INVENTORY_SIZE_CHANGED');
			case 102:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_PICKUP_ORDER_TIME_WINDOW_CHANGED');
			case 103:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_DELIVERY_ORDER_TIME_WINDOW_CHANGED');
			case 104:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_PICKUP_ORDER_SCHEDULED_TIME_CHANGED');
			case 105:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_DELIVERY_ORDER_SCHEDULED_TIME_CHANGED');
			case 106:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_ORDER_DANGEROUS_GOODS_STATUS_CHANGED');
			case 107:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_PICKUP_ORDER_LOCATION_CHANGED');
			case 108:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_DELIVERY_ORDER_LOCATION_CHANGED');
			case 109:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_ORDER_SERVICE_PLAN_CHANGED');
			case 110:
				return $translate.instant('ALERTS.TMP_DAY_AND_ROSS_ORDER_REQUIRED_SKILLS_CHANGED');
			case 111:
				return $translate.instant('ALERT_TYPES.TMP_DAY_AND_ROSS_NUMBER_OF_PALLETS_CHANGED');
			case 112:
				return $translate.instant('ALERT_TYPES.TMP_DAY_AND_ROSS_RUN_BREACHED_BY_WEIGHT');
			case 113:
				return $translate.instant('ALERT_TYPES.TMP_DAY_AND_ROSS_RUN_BREACHED_BY_PALLETS');
			default:
				var confData =
					configurations &&
					configurations.find(function (conf) {
						return conf.id === alert.alert_type;
					});
				return (
					(alert.extra_data && alert.extra_data.custom_title) ||
					(confData && confData.title) ||
					alert.text ||
					$translate.instant('GLOBAL.NA')
				);
		}
	};
});
