/**
 * Created by liorsion on 11/7/16.
 */

'use strict';

angular.module('bringgApp').factory('OptimizationProcessDatum', function ($resource, WEB_API_URL) {
	var OptimizationProcessDatum = $resource(WEB_API_URL + '/optimization_process_data/:id', { id: '@id' }, {});

	return OptimizationProcessDatum;
});
