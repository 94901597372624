'use strict';

angular.module('bringgApp').directive('default3inchCashOutTemplate', function ($translate, $timeout) {
	return {
		restrict: 'E',
		scope: {
			data: '=',
			employee: '=',
			config: '=',
			totals: '=',
			footers: '=',
			date: '=',
			printCallback: '='
		},
		link: function (scope, element) {
			$timeout(scope.printCallback, 100, true, element);
		},
		templateUrl: 'scripts/features/print/cash_out/default-3inch-cash-out-template.html'
	};
});
