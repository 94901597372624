/**
 * Created by liorsion on 8/31/15.
 */
'use strict';

/**
 * extend google maps polygon
 * @returns {google.maps.LatLngBounds}
 */

if (google.maps.Polygon) {
	google.maps.Polygon.prototype.getBounds = function () {
		var bounds = new google.maps.LatLngBounds();
		this.getPath().forEach(function (element) {
			bounds.extend(element);
		});
		return bounds;
	};
}

angular
	.module('bringgApp')
	.directive('teamMap', function () {
		return {
			replace: true,
			restrict: 'E',
			templateUrl: 'scripts/features/team/team-map/team-map.html',
			scope: {
				team: '=',
				editable: '=',
				canDrawHomeArea: '='
			},
			controller: 'TeamMapController'
		};
	})
	.controller('TeamMapController', function ($scope, $timeout, localStorageService, MerchantService) {
		$scope.polygonListeners = [];
		$scope.marker = undefined;
		$scope.mapCoordinates = undefined;
		$scope.zoomLevel = 14;

		$scope.init = function () {
			$scope.setCoordinates();
			$scope._initMap();

			$scope.$watch('team.home_area', function (homeArea) {
				if (_.isEmpty(homeArea)) {
					$scope.cleanPolygons();
					$scope.drawFirstPolygonForEdit();
				}
			});

			$scope.$watch('editable', $scope.onEditChanged);
			$scope.refreshMap();
		};

		$scope.onEditChanged = function (editMode) {
			if (editMode) {
				//on edit
				if ($scope.polygon) {
					$scope.polygon.setOptions({ editable: editMode });
					$scope.registerToPolygonChanges();
				} else {
					$scope.drawFirstPolygonForEdit();
				}
			} else {
				//on save
				$scope.cleanDrawingManager();
				if ($scope.polygon) {
					$scope.polygon.setOptions({ editable: editMode });
					$scope.cleanPolygonEvents();
				}

				$scope.setCoordinates();
				$scope.refreshMap();
			}
		};

		$scope.cleanDrawingManager = function () {
			if ($scope.drawingManager) {
				$scope.drawingManager.setDrawingMode(null);
				$scope.drawingManager.setOptions({
					drawingControl: false
				});
				$scope.drawingManager = null;
			}
		};

		/**
		 * clean all polygons
		 */
		$scope.cleanPolygons = function () {
			$scope.cleanDrawingManager();

			if ($scope.polygon) {
				$scope.polygon.setMap(null);
				$scope.polygon = null;
			}
		};

		$scope.drawTeamPolygon = function () {
			if (!$scope.teamMap || !$scope.team || _.isEmpty($scope.team.home_area)) {
				return;
			}

			$scope.polygon = new google.maps.Polygon({
				paths: $scope.team.home_area,
				strokeColor: '#000000',
				strokeOpacity: 0.5,
				strokeWeight: 1,
				fillColor: '#000000',
				fillOpacity: 0.25
			});

			$scope.polygon.setMap($scope.teamMap);

			$scope.teamMap.fitBounds($scope.polygon.getBounds());
		};

		/**
		 * update team home area from polygon
		 * @param polygon
		 */
		$scope.updateTeamPolygon = function () {
			var result = [];
			var path = $scope.polygon.getPath();

			for (var i = 0; i != path.getLength(); i++) {
				var vertex = path.getAt(i);
				result.push({ lat: vertex.lat(), lng: vertex.lng() });
			}

			$timeout(function () {
				$scope.team.home_area = result;
			});
		};

		/**
		 * register to polygon changes
		 * @param polygon
		 */
		$scope.registerToPolygonChanges = function () {
			$scope.polygonListeners.push(
				google.maps.event.addListener($scope.polygon.getPath(), 'insert_at', $scope.updateTeamPolygon)
			);
			$scope.polygonListeners.push(
				google.maps.event.addListener($scope.polygon.getPath(), 'set_at', $scope.updateTeamPolygon)
			);

			$scope.updateTeamPolygon();
		};

		/**
		 * clean all polygon events
		 * @param polygon
		 */
		$scope.cleanPolygonEvents = function () {
			_.each($scope.polygonListeners, function (polygonListener) {
				polygonListener.remove();
			});

			$scope.polygonListeners = [];
		};

		/**
		 * draw polygon when edit when no polygon
		 */
		$scope.drawFirstPolygonForEdit = function () {
			if (!$scope.teamMap) {
				return;
			}

			if (!$scope.canDrawHomeArea) {
				return;
			}

			$scope.drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode: false,
				drawingControl: true,
				drawingControlOptions: {
					position: google.maps.ControlPosition.TOP_CENTER,
					drawingModes: ['polygon']
				},
				polygonOptions: {
					fillColor: '#000000',
					fillOpacity: 0.25,
					strokeOpacity: 0.5,
					strokeWeight: 1,
					editable: true,
					geodesic: true,
					zIndex: 1
				}
			});

			google.maps.event.addListener($scope.drawingManager, 'overlaycomplete', function (event) {
				$scope.polygon = event.overlay;
				$scope.registerToPolygonChanges();
				$scope.drawingManager.setOptions({
					drawingControl: false
				});
			});

			$scope.drawingManager.setMap($scope.teamMap);
		};

		/**
		 * update map coordinates
		 */
		$scope.updateMapCenter = function () {
			if (!$scope.teamMap || !$scope.mapCoordinates.lat || !$scope.mapCoordinates.lng) {
				return;
			}

			$scope.teamMap.setCenter(new google.maps.LatLng($scope.mapCoordinates));
		};

		/***
		 *  Init marker on map according to current team coordinates
		 */
		$scope.initMarker = function () {
			if (!$scope.team.lat || !$scope.team.lng) {
				if ($scope.marker) {
					$scope.marker.setPosition(null);
					$scope.marker = undefined;
				}

				return;
			}

			if ($scope.marker) {
				$scope.marker.setPosition($scope.mapCoordinates);

				return;
			}

			$scope.marker = new google.maps.Marker({
				position: $scope.mapCoordinates,
				map: $scope.teamMap
			});
		};

		/**
		 *  Init map */
		$scope._initMap = function () {
			$scope.mapOptions = {
				center: new google.maps.LatLng($scope.mapCoordinates),
				zoom: $scope.zoomLevel,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_LEFT
				},
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				scaleControl: true,
				streetViewControl: true,
				streetViewControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				}
			};
		};

		/**
    Set Map Coordiants - If no team coordinates show merchant coordinates, and if none show world map.
   */
		$scope.setCoordinates = function () {
			$scope.mapCoordinates = { lat: 0, lng: 0 };

			if ($scope.team.lat && $scope.team.lng) {
				$scope.mapCoordinates = {
					lat: $scope.team.lat,
					lng: $scope.team.lng
				};
			} else {
				MerchantService.get().then(function (merchant) {
					if (merchant.lat && merchant.lng) {
						$scope.mapCoordinates = { lat: merchant.lat, lng: merchant.lng };
					} else {
						$scope.zoomLevel = 1;
					}
				});
			}
		};

		/**
    Refresh Map
   */
		$scope.refreshMap = function () {
			$timeout(function () {
				$scope.updateMapCenter();
				$scope.drawTeamPolygon();
				$scope.initMarker();
			});
		};

		$scope.init();
	});
