'use strict';
angular.module('bringgApp.directives').directive('cxRoute', function () {
	return {
		templateUrl: 'scripts/directives/customer-experience/route-directive.html',
		restrict: 'E',
		scope: {
			color: '@'
		}
	};
});
