'use strict';

angular
	.module('bringgApp')
	.constant('DEFAULT_ACKNOWLEDGMENT_SCREEN', {
		task_fields: [{ field: 'total_price', label: 'ORDER.TOTAL_PRICE', filter: 'currency' }],
		way_points_fields: [
			{ field: 'address', label: 'ORDER.ADDRESS', advanced_filter: 'ackWayPointAddress' },
			{ field: 'scheduled_at', label: 'ORDER.SCHEDULED_FOR', filter: 'human_time' },
			{ field: 'customer.name', label: 'ORDER_INVOICE.CUSTOMER', advanced_filter: 'ackWayPointCustomerName' }
		],
		ack_type: 0,
		sound_index: 0,
		templateUrl: 'scripts/features/dispatcher_acknowledge/dispatcher-acknowledge-task-status.html',
		remindMe: { showing: true, translation: 'DISPATCHER_ACKNOWLEDGE.REMIND_ME_LATER' },
		reject: { showing: false, translation: 'DISPATCHER_ACKNOWLEDGE.REJECT' },
		acknowledge: { showing: true, translation: 'DISPATCHER_ACKNOWLEDGE.ACKNOWLEDGE' },
		modal: { windowClass: 'acknowledgment' }
	})
	.factory(
		'StatusConfirmationService',
		function (
			$rootScope,
			DEFAULT_ACKNOWLEDGMENT_SCREEN,
			DispatcherAcknowledgeTaskStatusService,
			MerchantConfigurations,
			Tasks,
			TasksLoader,
			TasksData,
			Task,
			$q,
			$log,
			Authentication
		) {
			var StatusConfirmationService = {};

			StatusConfirmationService._addTask = function (task) {
				DispatcherAcknowledgeTaskStatusService.addTask(
					task,
					_.extend({}, DEFAULT_ACKNOWLEDGMENT_SCREEN, MerchantConfigurations.acknowledge_flow_ui_config),
					function acknowledgeTask(task) {
						return Tasks.acknowledgeTask(task.id);
					},
					function rejectTask(task) {
						return Task.cancel(task.id);
					}
				);
			};

			StatusConfirmationService._shouldRun = function () {
				return (
					MerchantConfigurations.enable_task_acknowledge_flow &&
					Authentication.currentUser().dispatcher &&
					Authentication.currentUser().has_access('acknowledge')
				);
			};

			StatusConfirmationService._init = function () {
				if (StatusConfirmationService._shouldRun()) {
					TasksLoader.loadOpenForTable({ columnsKey: 'task_fields' });

					_.each(TasksData.getUnacknowledged(), function (task) {
						StatusConfirmationService._addTask(task);
					});

					$rootScope.$on('new unacknowledged task', function (event, newTask) {
						$log.info(
							'StatusConfirmationService, StatusConfirmationService._addTask called with ',
							newTask
						);
						StatusConfirmationService._addTask(newTask);
					});

					$rootScope.$on('task removed from unacknowleged', function (event, task) {
						$log.info('StatusConfirmationService, removing task from unacknowleged', task);
						DispatcherAcknowledgeTaskStatusService.removeTask(task);
					});
				}
			};

			$rootScope.$watch(Authentication.isLoggedIn, function () {
				if ($rootScope.isLoggedIn) {
					MerchantConfigurations.$promise.then(function () {
						StatusConfirmationService._init();
					});
				}
			});

			return StatusConfirmationService;
		}
	);
