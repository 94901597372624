'use strict';

angular
	.module('bringgApp.directives')
	.directive('supportButton', function (Authentication, $location, $window, $route) {
		return {
			templateUrl: 'scripts/directives/support_button/support-button.html',
			restrict: 'E',
			scope: true,
			link: function ($scope) {
				$scope.defaultSupportUrl = _.constant('https://support.bringg.com/hc/en-us/categories/360001522872');
				$scope.supportSectionUrl = _.constant('https://support.bringg.com/hc/en-us/sections/');

				$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
					$scope.isLoggedIn = isLoggedIn;
				});

				$scope.handleLocationChanged = function () {
					var supportSectionId = $route.current.supportSectionId;
					$scope.menuSupportUrl = $scope.getSupportSectionUrl(supportSectionId);
				};

				$scope.getSupportSectionUrl = function (sectionId) {
					if (_.isUndefined(sectionId)) {
						return $scope.defaultSupportUrl();
					}
					return $scope.supportSectionUrl() + sectionId;
				};

				$scope.goToSupportUrl = function () {
					var data = {
						userId: Authentication.currentUser().id,
						email: Authentication.currentUser().email,
						currentTab: $location.absUrl()
					};
					$window.open($scope.menuSupportUrl, '_blank', 'noopener');
				};

				$scope.handleLocationChanged();

				$scope.unsubscribeLocationChange = $scope.$on('$locationChangeSuccess', $scope.handleLocationChanged);

				$scope.$on('$destroy', function () {
					$scope.unsubscribeLocationChange();
				});
			}
		};
	});
