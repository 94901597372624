'use strict';

app.controller(
	'AllTasksController',
	function AllTasksController(
		$scope,
		$routeParams,
		Tasks,
		Customers,
		$rootScope,
		Shift,
		Task,
		Employee,
		ITEMS_PER_PAGE,
		bringg_utils,
		$translate,
		MerchantConfigurations,
		Authentication
	) {
		$scope.advancedSearch = false;
		$scope._searchDisabled = false;
		$scope.loading = true;
		$scope.tasks = [];
		$scope.limit = 50;
		$scope.enableNewHistoryTab = Authentication.currentUser()
			? Authentication.currentUser().feature_flags.enable_new_history_tab || false
			: false;

		$scope.paginationData = {
			next_page_cursor: null,
			previous_page_cursor: null
		};

		$rootScope.$watch($rootScope.isLoggedIn, function () {
			if ($rootScope.isLoggedIn && !$scope.enableNewHistoryTab) {
				$scope.loadTasks('next');
			}
		});

		$scope.sort = {
			column: 'external_id',
			descending: true,
			index: 0
		};

		$scope.selectedCls = function (columnIdx) {
			if (columnIdx === $scope.sort.index) {
				if ($scope.sort.descending) {
					return '&#9660;';
				} else {
					return '&#9650;';
				}
			}
		};

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.taskListHeaders[columnIdx].field) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.taskListHeaders[columnIdx].field;
				sort.descending = false;
				sort.index = columnIdx;
			}
			return true;
		};

		$scope.getStatus = bringg_utils.getStatusFromTask;

		$scope.taskListHeaders = [
			{
				field: 'external_id',
				title: $translate.instant('HISTORY.ID'),
				templateUrl: 'views/tasks/cell_templates/task-id.html'
			},
			{
				field: 'scheduled_at',
				title: $translate.instant('HISTORY.SCHEDULED_FOR'),
				templateUrl: 'scripts/features/tasks/all_tasks/cell_templates/task-schedule.html'
			},
			{
				field: 'title',
				title: $translate.instant('HISTORY.ORDER'),
				templateUrl: 'views/tasks/cell_templates/task-title.html'
			},
			{
				field: 'customer.name',
				title: $translate.instant('HISTORY.CUSTOMER_NAME'),
				templateUrl: 'views/tasks/cell_templates/customer-name.html'
			},
			{
				field: 'customer.phone',
				title: $translate.instant('HISTORY.CUSTOMER_PHONE'),
				templateUrl: 'views/tasks/cell_templates/customer-phone.html'
			},
			{
				field: 'full_address',
				title: $translate.instant('HISTORY.ADDRESS'),
				templateUrl: 'views/tasks/cell_templates/task-last-address.html'
			},
			{
				field: 'user.name',
				title: $translate.instant('HISTORY.DRIVER_NAME'),
				templateUrl: 'views/tasks/cell_templates/user-name.html'
			},
			{
				field: 'status',
				title: $translate.instant('HISTORY.STATUS'),
				templateUrl: 'views/tasks/cell_templates/task-status-name.html'
			}
		];

		$scope.$on('config updated', function () {
			$scope.merchantConfiguration = MerchantConfigurations;
			$scope.enableTeams = MerchantConfigurations.enable_teams;
		});

		$scope.handleSearchButtonClick = function () {
			if (
				!$scope.orderTitle &&
				!$scope.externalId &&
				!$scope.customerName &&
				!$scope.customerPhone &&
				!$scope.customerAddress &&
				!$scope.driverName
			) {
				toastr.error($translate.instant('TASK.MUST_USE_AT_LEAST_ONE_FIELD_SEARCH'));
				return;
			}

			if ($scope._searchDisabled) {
				toastr.error('Please wait few seconds before doing another search.');
				return;
			}

			$scope._searchDisabled = true;
			$scope.loadTasks();
		};

		$scope._getQueryObject = function () {
			if (!$scope.advancedSearch) {
				return $scope.orderTitle;
			} else {
				return {
					title: $scope.orderTitle,
					name: $scope.customerName,
					phone: $scope.customerPhone,
					address: $scope.customerAddress,
					external_id: $scope.externalId,
					driver_name: $scope.driverName,
					advanced: true
				};
			}
		};

		$scope.handleAdvancedSearchButtonClick = function () {
			$scope.advancedSearch = !$scope.advancedSearch;
		};

		$scope.isNextButtonDisabled = function () {
			return $scope.loading || _.isNil($scope.paginationData.next_page_cursor);
		};

		$scope.isPreviousButtonDisabled = function () {
			return $scope.loading || _.isNil($scope.paginationData.previous_page_cursor);
		};

		$scope.loadTasks = function (action) {
			$scope.loading = true;
			var initialFetch = _.isNil(action);
			var params = {
				query: $scope._getQueryObject(),
				limit: $scope.limit,
				pgver: '2'
			};
			if (initialFetch) {
				_.extend(params, { cursor: null, page_action: 'next' });
			} else {
				var next = action === 'next';
				var cursor = next ? $scope.paginationData.next_page_cursor : $scope.paginationData.previous_page_cursor;
				_.extend(params, { cursor: cursor, page_action: action });
			}

			return Task.closedTasksWithQuery(params, $scope.updateTasksCallback);
		};

		$scope.updateTasksCallback = function (data) {
			var customersIds = _.chain(data.tasks).map('customer_id').uniq().compact().value();

			Customers.getBatch(customersIds, function () {
				_.each(data.tasks, function (task) {
					Tasks.getTaskCompleteDetails(task);

					// adding missing property just for table sorting
					task.scheduled_at = task.way_points[0].scheduled_at;
				});
			});

			$scope.tasks = data.tasks;
			_.extend($scope.paginationData, {
				next_page_cursor: data.next_page_cursor,
				previous_page_cursor: data.previous_page_cursor
			});

			$scope.loading = false;
			$scope._searchDisabled = false;
		};
	}
);
