'use strict';
angular
	.module('bringgApp')
	.service(
		'TasksLoader',
		function (
			Tasks,
			TasksTableColumns,
			Task,
			CollapsedLinkedTasksService,
			Customers,
			RunsService,
			MerchantConfigurations,
			AirbrakeService,
			Authentication,
			TasksData,
			ManagedAttributesService,
			$rootScope,
			$q,
			myHttpInterceptor,
			$log
		) {
			const _this = this;
			_this.loadPlanningForTable = loadPlanningForTable;
			_this.loadOpenForTable = loadOpenForTable;
			_this.removeInvisibleTasks = removeInvisibleTasks;

			const _pageSize = MerchantConfigurations.tasks_page_size || 2500;
			const _initialPageSize = MerchantConfigurations.initial_tasks_page_size || 100;
			let _allOpenTasksPromise;
			let _allPlanningTasksPromise;

			function loadPlanningForTable(options) {
				options = options || {};

				if (_allPlanningTasksPromise) {
					return _allPlanningTasksPromise;
				}

				var deferred = $q.defer();
				_allPlanningTasksPromise = deferred.promise;

				return TasksTableColumns.columnsConfigReady().then(() =>
					getAllTasksByStepsKeySet(
						TasksTableColumns.getTasksColumns(options.columnsKey),
						deferred,
						Task.planningTasksKeySet,
						TasksData.getPlanningTasks,
						buildPlanningCollapse,
						options.grouping,
						() => (_allPlanningTasksPromise = null),
						options.teamIds,
						options.emptyFilteringEnabled
					)
				);
			}

			function loadOpenForTable(options) {
				options = options || {};

				if (_allOpenTasksPromise) {
					return _allOpenTasksPromise;
				}

				var deferred = $q.defer();
				_allOpenTasksPromise = deferred.promise;

				TasksData.setUnacknowledged([]);

				return TasksTableColumns.columnsConfigReady().then(() =>
					getAllTasksByStepsKeySet(
						TasksTableColumns.getTasksColumns(options.columnsKey),
						deferred,
						Task.openTasksKeySet,
						TasksData.getOpenTasks,
						buildDispatchCollapse,
						options.grouping,
						() => (_allOpenTasksPromise = null)
					)
				);
			}

			function buildDispatchCollapse(tasks) {
				if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
					CollapsedLinkedTasksService.buildDispatchTasks(tasks);
				}
			}

			function getAllTasksByStepsKeySet(
				columns,
				defer,
				taskAction,
				getTaskList,
				buildCollapse,
				grouping,
				resetPromiseChain,
				teamIds,
				emptyFilteringEnabled
			) {
				if (emptyFilteringEnabled && (!teamIds || teamIds.length === 0)) {
					return;
				}

				$log.log('Fetching all open tasks using key set pagination');

				if (Task.shouldUseNewApi()) {
					myHttpInterceptor.addManualRequest();
				}

				const reset = () => {
					if (Task.shouldUseNewApi()) {
						myHttpInterceptor.response();
					}

					resetPromiseChain();
				};

				var baseRequest = _.defaults({}, { pgver: 2, page_action: 'next_only', columns });

				if (emptyFilteringEnabled && teamIds) {
					baseRequest.team_ids = teamIds.map(teamId => (teamId < 0 ? null : teamId));
				}

				TasksData.setFollowersCount(undefined);

				var fetchNextPage = function (tasksResponse) {
					var tasks = tasksResponse.items;
					TasksData.calcFollowersForTasks(tasks);

					$log.log('Fetched ' + tasks.length + ' tasks, next page cursor: ' + tasksResponse.next_page_cursor);

					var promises = [];
					resolveCustomersForTasks(tasks)
						.then(function () {
							return $q.all(
								_.map(tasks, function (task) {
									return Tasks.addNewTask(task, { notifyChanges: false });
								})
							);
						})
						.then(Tasks.notifyChanges);

					const runIds = new Set();

					tasks.forEach(task => {
						if (task.run_id) {
							runIds.add(task.run_id);
						}
					});

					promises.push(
						RunsService.loadAll({ ids: Array.from(runIds) }).catch(error => {
							console.error('error loading runs', error);

							AirbrakeService.notify(error, {
								ids: runIds
							});
						})
					);

					promises.push(ManagedAttributesService.loadForTasks(tasks.map(task => task.id)));

					return $q.all(promises).then(function () {
						buildCollapse(tasks);
						removeInvisibleTasks(getTaskList(grouping));
						$rootScope.$broadcast('lazy task list update');
						$rootScope.$broadcast('task list next page update', getTaskList(grouping));

						if (_.isNil(tasksResponse.next_page_cursor) || _.isEmpty(tasks)) {
							defer.resolve(getTaskList(grouping));
							reset();
							$rootScope.CACHE_REALTIME_EVENTS = false;
							return true;
						}

						var nextRequest = taskAction(
							_.defaults({}, baseRequest, {
								cursor: tasksResponse.next_page_cursor,
								limit: _pageSize
							})
						);
						return nextRequest.then(fetchNextPage, reset);
					});
				};

				$rootScope.CACHE_REALTIME_EVENTS = true;

				var requestPromise = taskAction(_.defaults({}, baseRequest, { limit: _initialPageSize }));

				requestPromise.then(fetchNextPage, err => {
					reset();
					defer.reject(err);
				});

				return defer.promise;
			}

			function buildPlanningCollapse(tasks) {
				if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
					CollapsedLinkedTasksService.buildPlanningTasks(tasks);
				}
			}

			function resolveCustomersForTasks(tasks) {
				var customersIds = getCustomerIdsFromTasks(tasks);
				if (!_.isEmpty(customersIds)) {
					return Customers.getBatch(customersIds);
				}
				return $q.resolve();
			}

			function getCustomerIdsFromTasks(tasks) {
				//build a list of customers
				var customersIds = [];
				_.each(tasks, function (task) {
					_.each(task.way_points, function (wp) {
						if (wp.customer_id && !wp.customer) {
							customersIds.push(wp.customer_id);
						} else if (wp.customer && task.customer_id === wp.customer.id && !task.customer) {
							task.customer = wp.customer;
						}
					});
					if (task.customer_id && !task.customer) {
						customersIds.push(task.customer_id);
					}
				});

				customersIds = _.chain(customersIds).compact().uniq().value();

				return customersIds;
			}

			function removeInvisibleTasks(taskList) {
				if (Task.shouldUseNewApi()) {
					return;
				}

				if (!Authentication.currentUser()) {
					//prevent errors on logoff
					return;
				}
				var i = taskList.length;
				var admin = Authentication.currentUser().admin;

				while (i--) {
					var currentTask = taskList[i];

					if (currentTask.hasOwnProperty('status') && Task.isUnacknowledged(currentTask) && !admin) {
						$log.info(
							'taskList Received new task [' + currentTask.id + '] not unacknowledged - not adding it'
						);
						taskList.splice(i, 1);
					}

					if (!_.isNull(currentTask.group_leader_id) && !_.isUndefined(currentTask.group_leader_id)) {
						$log.info(
							'taskList Received new task [' +
								currentTask.id +
								'] with group leader [' +
								currentTask.group_leader_id +
								'] - not adding it'
						);
						taskList.splice(i, 1);
					}

					if (!Task.isValid(currentTask)) {
						$log.info(
							'taskList Received new task [' +
								currentTask.id +
								'] with invalidated [' +
								currentTask.invalidated +
								'] - not adding it'
						);
						taskList.splice(i, 1);
					}
				}
			}
		}
	);
