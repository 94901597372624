'use strict';

// Controllers
// basically based on http://michaeleconroy.blogspot.co.il/2013/08/creating-application-dialog-service.html
angular
	.module('bringgApp')
	.controller('ErrorDialogCtrl', function ($scope, $uibModalInstance, errmsg, correction) {
		$scope.errmsg = errmsg;
		if (angular.isDefined(correction)) {
			$scope.correction = correction;
		}

		$scope.close = function () {
			$uibModalInstance.close();
		};
	}) // end ErrorDialogCtrl

	.controller('WaitDialogCtrl', function ($scope, $uibModalInstance, msg, progress, title) {
		$scope.msg = msg;
		$scope.title = title !== null ? title : 'Application Busy';
		$scope.progress = angular.isDefined(progress) ? progress : 100;

		$scope.$on('wait.complete', function (evt, args) {
			$uibModalInstance.close();
		});

		$scope.$on('wait.message', function (evt, args) {
			$scope.msg = args.msg;
		});

		$scope.$on('wait.progress', function (evt, args) {
			$scope.progress = args.progress;
		});
	}) // end WaitDialogCtrl

	.controller('NotifyDialogCtrl', function ($scope, $uibModalInstance, header, msg) {
		$scope.header = header;
		$scope.msg = msg;

		$scope.close = function () {
			$uibModalInstance.close();
		};
	}) // end NotifyDialogCtrl

	.controller('AddDataDialogCtrl', function (
		$scope,
		$rootScope,
		$timeout,
		$uibModalInstance,
		header,
		msg,
		question_data,
		evt,
		data
	) {
		$scope.header = header;
		$scope.msg = msg;
		$scope.question_text = question_data.question_text;
		$scope.question_data_result = '';

		$scope.close = function () {
			$uibModalInstance.close();
		};

		$scope.yes = function () {
			$uibModalInstance.close();
			data[question_data.question_id] = this.question_data_result;
			$timeout(function () {
				$rootScope.$broadcast(evt, data);
			}, 1000);
		}; // end yes
	}) // end AddDataDialogCtrl

	.controller('RichHTMLDialogController', function (
		$scope,
		$rootScope,
		$timeout,
		$uibModalInstance,
		header,
		htmlData,
		evt,
		data
	) {
		$scope.header = header;
		$scope.htmlData = htmlData;

		$scope.close = function () {
			$uibModalInstance.close();
		};

		$scope.yes = function () {
			$uibModalInstance.close();

			$timeout(function () {
				$rootScope.$broadcast(evt, data);
			}, 1000);
		}; // end yes
	}) // end AddDataDialogCtrl

	.controller('ConfirmDialogCtrl', function (
		$scope,
		$rootScope,
		$timeout,
		$uibModalInstance,
		header,
		msg,
		evt,
		data
	) {
		$scope.header = header;
		$scope.msg = msg;

		$scope.close = function () {
			$uibModalInstance.close();
		};

		$scope.yes = function () {
			$uibModalInstance.close();
			$timeout(function () {
				$rootScope.$broadcast(evt, data);
			}, 1000);
		}; // end yes
	}) // end ConfirmDialogCtrl
	.controller('ConfirmDialogAsyncCtrl', function ($scope, $uibModalInstance, options) {
		options = options || {};

		$scope.header = options.title;
		$scope.msg = options.message;

		$scope.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.yes = function () {
			$uibModalInstance.close(options.data);
		};
	});
// end modals.controllers
