'use strict';

angular
	.module('bringgApp')
	.constant('CUSTOM_ATTRIBUTES_CONSTS', {
		// ATTENTION: the same var with the same value used in libs/legacy/bringg-web/src/lib/features/filter-bar/filter-bar.ts
		// this vars should have same value as they used are creating filter item key
		TASK_COLUMNS_PREFIX: 'custom-attribute-'
	})
	.factory(
		'CustomAttributesService',
		function (
			BringgSDK,
			Authentication,
			AirbrakeService,
			CrossApplicationService,
			CROSS_APP_ACTIONS,
			CUSTOM_ATTRIBUTES_CONSTS,
			$q
		) {
			const ENUM_TYPE = 3;
			let getAllPromise;
			let subscribedToCrossApp = false;

			const CustomAttributesService = {
				allAttributesAreFetched: false,
				attributesEnabled: false,
				allCustomAttributes: [],
				taskRelatedAttributes: []
			};

			Authentication.featureFlags().then(function (featureFlags) {
				CustomAttributesService.attributesEnabled = Boolean(featureFlags.enable_custom_attributes);
			});

			function setAttributes(attributes) {
				CustomAttributesService.allCustomAttributes = attributes;
				CustomAttributesService.taskRelatedAttributes = attributes.filter(function ({
					entity_type,
					suggestion
				}) {
					// entity_type === 0 stands for custom attr Task type
					// suggeested attrs cannot be used with Tasks
					return entity_type === 0 && !suggestion;
				});
			}

			function subscribeToCrossApplicationEvents() {
				if (subscribedToCrossApp) {
					return;
				}
				CrossApplicationService.on(CROSS_APP_ACTIONS.CUSTOM_ATTRIBUTES_SET, setAttributes);
				subscribedToCrossApp = true;
			}

			function getEnumItemNameByValue(enum_definition, value) {
				return enum_definition.find(definitionItem => definitionItem.value === value)?.name;
			}

			CustomAttributesService.getAll = function () {
				if (CustomAttributesService.allAttributesAreFetched) {
					return $q.resolve(CustomAttributesService.allCustomAttributes);
				} else if (getAllPromise) {
					return getAllPromise;
				}

				getAllPromise = BringgSDK.getInstancePromise()
					.then(function (bringg) {
						return bringg.customAttributesApi.getAllCustomAttributes();
					})
					.then(function (attributes) {
						CustomAttributesService.allAttributesAreFetched = true;
						setAttributes(attributes);

						subscribeToCrossApplicationEvents();

						return $q.resolve(attributes);
					})
					.catch(function (error) {
						CustomAttributesService.allAttributesAreFetched = true;
						setAttributes([]);

						AirbrakeService.notify({
							error,
							params: { message: 'Failed to fetch custom attributes' }
						});
						console.warn('Failed to fetch custom attributes');

						return $q.resolve([]);
					});

				return getAllPromise;
			};

			CustomAttributesService.generateTaskColumns = function () {
				return CustomAttributesService.attributesEnabled
					? CustomAttributesService.taskRelatedAttributes.map(function ({
							name,
							path,
							id,
							data_type,
							enum_definition
					  }) {
							return {
								// be aware this will not be available if field is present in stored config
								// but related attribute is removed, since back-end doesn't save this field
								customAttributeId: id,
								translate: false,
								active: false,
								sortable: true,
								getSearchValue: function (item) {
									return _.get(item, path);
								},
								id: `${CUSTOM_ATTRIBUTES_CONSTS.TASK_COLUMNS_PREFIX}${id}`,
								title: name,
								width: 100,
								getValue: function (item) {
									const value = _.get(item, path);
									return data_type === ENUM_TYPE && enum_definition
										? getEnumItemNameByValue(enum_definition, value)
										: value;
								}
							};
					  })
					: [];
			};

			// Filter / update task fields configuration data
			CustomAttributesService.normalizeTaskFields = function (fields) {
				return fields.reduce(function (acc, field) {
					const { id: fieldId, customAttributeId } = field;
					const match = String(fieldId).match(/^custom-attribute-(\d+)$/);

					// not related to custom attribute
					if (!match) {
						acc.push(field);
						return acc;
					}

					const id = Number(match[1]);

					// Can't yet use this approach as back-end doesn't allow to store customAttributeId
					//  under merchant_configuration.task_fields
					// if (!customAttributeId) {
					// 	return true;
					// }
					//
					// const id = Number(customAttributeId);

					const attribute =
						CustomAttributesService.attributesEnabled &&
						_.find(CustomAttributesService.taskRelatedAttributes, { id });

					if (attribute) {
						// add column with the latest attribute name
						acc.push({ ...field, title: attribute.name });
					}

					return acc;
				}, []);
			};

			return CustomAttributesService;
		}
	);
