'use strict';

angular
	.module('bringgApp')
	.factory(
		'PreviewMapService',
		function (
			WayPointsService,
			Optimization,
			Authentication,
			toastr,
			TranslationService,
			ServiceAreasService,
			$q,
			Teams,
			OptimizationPreviewService,
			RunsService,
			TASK_DEFAULT_TYPES,
			$log,
			OPTIMIZATION_TYPES,
			ReactModals,
			RouteAssigner,
			MerchantConfigurations,
			Application,
			optimizationTypesService
		) {
			var PreviewMapService = {};
			var currentUser = Authentication.currentUser();
			var enableRoutesPlanner = false;
			var manualPreviewMapBreaksEnabled = false;

			Authentication.featureFlags().then(function (featureFlags) {
				enableRoutesPlanner = MerchantConfigurations.applications.some(Application.isRouteOptimizer2);
				manualPreviewMapBreaksEnabled = featureFlags.manual_preview_map_breaks_enabled || false;
			});

			PreviewMapService.filterTasksForRoutesPlaner = function (tasks) {
				const filteredTasks = tasks.filter(task => {
					if (task.task_type_id === TASK_DEFAULT_TYPES.RETURN_TASK) {
						return false;
					}
					const taskDropOfWayPoint = WayPointsService.getDropOffWayPoint(task);
					if (!taskDropOfWayPoint) {
						return false;
					}
					return true;
				});

				return filteredTasks;
			};

			PreviewMapService.open = function (selectedItems) {
				const teamId = selectedItems[0].team_id;
				selectedItems = PreviewMapService.filterTasksForRoutesPlaner(selectedItems);

				if (enableRoutesPlanner) {
					ReactModals.openRoutesPlannerModalManually({
						optimizationType: optimizationTypesService.getConfiguredOptimizationType(teamId),
						selectedTasks: selectedItems
					});
				} else {
					var teamIds = _.uniq(_.map(selectedItems, 'team_id'));

					if (_.includes(teamIds, 0)) {
						toastr.error(TranslationService.instant('PLANNING.ONLY_TASKS_WITH_TEAM'));
						return;
					}

					if (teamIds.length > 1) {
						toastr.error(TranslationService.instant('PLANNING.ONLY_ONE_TEAM'));
						return;
					}
					var tasksDropOffWayPoints = WayPointsService.getTasksDropOffPoints(selectedItems);

					if (
						selectedItems.length &&
						(tasksDropOffWayPoints.length === 0 || tasksDropOffWayPoints.length !== selectedItems.length)
					) {
						toastr.error(TranslationService.instant('PLANNING.TASK_WITHOUT_DROP_OFF_WAY_POINT'));
						return;
					}

					var partitionedTasks = _.partition(selectedItems, function (task) {
						return !!task.run_id;
					});

					const tasksWithNoRun = partitionedTasks[1];

					if (tasksWithNoRun.length) {
						toastr.error(TranslationService.instant('PLANNING.ONLY_TASKS_WITH_RUN'));
						return;
					}

					if (!areTasksInSameDay(tasksDropOffWayPoints, 'scheduled_at', 'no_earlier_than')) {
						toastr.error(TranslationService.instant('PLANNING.ONLY_SAME_DATE'));
						return;
					}

					const selectedTeamId = teamIds[0];
					const optimizationType =
						optimizationTypesService.getConfiguredOptimizationType(selectedTeamId) ||
						OPTIMIZATION_TYPES.ANONYMOUS;

					ServiceAreasService.getServiceAreaById([selectedTeamId]).then(
						function (serviceAreasById) {
							return PreviewMapService._getNormalizedBreaks(selectedItems).then(
								function (breaks) {
									var team = Teams.teamsByIdMap[selectedTeamId];

									return OptimizationPreviewService.open(
										null, // no optimization data on manual mode
										team,
										optimizationType,
										selectedItems[0].ready_to_execute,
										angular.copy(selectedItems),
										undefined,
										breaks,
										tasksDropOffWayPoints.length
											? _.first(tasksDropOffWayPoints).scheduled_at
											: moment(),
										serviceAreasById,
										true
									).then(function (userChanges) {
										// if from dispatch do not change ready_do_execute
										Optimization.assignUserManualOptimization({
											user_id: currentUser.id,
											merchant_id: currentUser.merchant_id,
											team_id: selectedTeamId,
											data: userChanges
										}).$promise.then(
											function () {
												toastr.success(
													TranslationService.instant(
														'PLANNING.ASSIGN_USERS_MANUAL_OPTIMIZATION_SUCCESS'
													)
												);
											},
											function (error) {
												toastr.error(
													TranslationService.instant(
														'PLANNING.ASSIGN_USERS_MANUAL_OPTIMIZATION_FAIL'
													),
													error
												);
											}
										);
									});
								},
								function (error) {
									$log.error('failed to get breaks', error);
									toastr.error(
										TranslationService.instant(
											'PLANNING.GET_BREAKS_BEFORE_OPEN_MANUAL_OPTIMIZATION_FAIL'
										),
										error
									);
								}
							);
						},
						function (error) {
							$log.error('failed to get service areas', error);
							toastr.error(TranslationService.instant('PLANNING.GET_SERVICE_AREAS_FAIL'), error);
						}
					);
				}
			};

			PreviewMapService._getNormalizedBreaks = function (tasks) {
				if (manualPreviewMapBreaksEnabled === false) {
					return $q.resolve({});
				}

				var runIds = _.uniq(
					tasks.map(function (task) {
						return task.run_id;
					})
				);

				return RunsService.getActualBreaks(runIds).then(
					function (response) {
						$log.log('Got response of actual_break on manual preview map');

						//normalization
						for (var runId in response.run_id_to_break) {
							var actualBreak = response.run_id_to_break[runId];
							actualBreak.estimated_start_time = moment(actualBreak.estimated_start_time).unix();
							actualBreak.estimated_end_time = moment(actualBreak.estimated_end_time).unix();
							actualBreak.scheduled_start_time = moment(actualBreak.scheduled_start_time).unix();
							actualBreak.scheduled_end_time = moment(actualBreak.scheduled_end_time).unix();
							actualBreak.type = 'actual_break';
							actualBreak.route_idx = parseInt(runId);
						}

						return response.run_id_to_break;
					},
					function (error) {
						$log.error('failed to get breaks of runs', error);
					}
				);
			};

			function areTasksInSameDay(tasksDropOffWayPoints, propertyName, secondaryPropertyName) {
				var dates = getDropOffWayPointsDates(tasksDropOffWayPoints, propertyName, secondaryPropertyName);
				return areAllDatesInTheSameDay(dates);
			}

			function getDropOffWayPointsDates(dropOffWayPoints, propertyName, secondaryPropertyName) {
				return _.map(dropOffWayPoints, function (wayPoint) {
					return wayPoint[propertyName]
						? moment(wayPoint[propertyName])
						: moment(wayPoint[secondaryPropertyName]);
				});
			}

			function areAllDatesInTheSameDay(dates) {
				for (var i = 0; i < dates.length - 1; i++) {
					if (!dates[i].isSame(dates[i + 1], 'day')) {
						return false;
					}
				}
				return true;
			}

			return PreviewMapService;
		}
	);
