'use strict';

angular
	.module('bringgApp.directives')
	.directive('bulkAssignDrivers', function (Employees, EmployeesTasks, TranslationService) {
		return {
			restrict: 'E',
			templateUrl: 'scripts/directives/bulk-assign-drivers/bulk-assign-drivers.html',
			scope: {
				employees: '=',
				onDriverSelect: '=?'
			},
			link: function ($scope, $elem) {
				function placeholderText() {
					return TranslationService.instant('DISPATCH_LIST.ASSIGN_TO') + '...';
				}

				// EmployeesDataSource will allow lazy-loading of employees
				// this allows some kind of "lazy-loading" the select2 to handle large amount of users
				class EmployeesDataSource {
					constructor() {
						this.reset();
						this.pageSize = 150;
					}

					query({ term, page, matcher, callback }) {
						this.initializeEmployees();

						if (!_.isEqual(term, this.term)) {
							// If the term is changed, store it for later access..
							this.term = term;

							// Reset the employees
							this.employees = null;
							this.initializeEmployees();

							// Filter employees
							this.employees = _.filter(this.employees, employee => matcher(this.term, employee.name));
						}

						// get the offsets
						const skip = (page - 1) * this.pageSize;
						const data = _.chain(this.employees).drop(skip).take(this.pageSize).valueOf();

						callback({
							results: data,
							more: !_.isEmpty(data)
						});
					}

					initializeEmployees() {
						if (!this.employees) {
							this.employees = EmployeesTasks.optimizedEmployeeListForTask(
								EmployeesTasks.allEmployeesWithUnassigned($scope.employees || [], null, true)
							);
						}
					}

					reset() {
						this.employees = null;
						this.term = '';
					}
				}

				const dataSource = new EmployeesDataSource();

				const select2Options = {
					placeholder: placeholderText(),
					formatSelection: placeholderText,
					dropdownAutoWidth: true,
					dropdownCssClass: 'bulk-assign-drivers',

					formatResult: employee => EmployeesTasks.formatEmployeeSelection(employee),
					sortResults: results => EmployeesTasks.optimizedEmployeeListForTask(results),

					query: opts => dataSource.query(opts)
				};

				const $driverSelect = $elem.find('#driverSelect');
				$driverSelect.select2(select2Options).on('change', event => {
					const selectedEmployee = event.added;

					// reset the selection, so we can choose the same driver when the dropdown will be open again
					$driverSelect.val(null);
					dataSource.reset();

					$scope.onDriverSelect(selectedEmployee);
				});

				$elem.on('$destroy', () => {
					$driverSelect.select2('destroy');
				});
			}
		};
	});
