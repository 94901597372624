'use strict';
/*global app:false */
/*global io:false */

angular
	.module('bringgApp')
	.factory('DispatcherAlert', function ($resource, WEB_API_URL) {
		var DispatcherAlert = $resource(
			WEB_API_URL + '/dispatcher_alerts/',
			{},
			{
				all: { method: 'GET', isArray: true, url: WEB_API_URL + '/dispatcher_alerts/' },
				dismiss: { method: 'DELETE', params: { id: 'id' }, url: WEB_API_URL + '/dispatcher_alerts/:id' }
			}
		);

		return DispatcherAlert;
	})
	.factory('DispatcherAlerts', function ($rootScope, DispatcherAlert, $log, SocketPubSub) {
		var DispatcherAlerts = {};

		DispatcherAlerts.alerts = [];

		DispatcherAlerts.all = function () {
			DispatcherAlerts.alerts = DispatcherAlert.all();
			return DispatcherAlerts.alerts;
		};

		DispatcherAlerts.add = function (alertData, cb) {
			var alert = new DispatcherAlert(alertData);
			var existingAlert = _.find(DispatcherAlerts.alerts, function (alert) {
				return alert.id === alertData.id;
			});

			if (_.isUndefined(existingAlert)) {
				DispatcherAlerts.alerts.unshift(alert);
			}
		};

		DispatcherAlerts.remove = function (alertID) {
			var alertIdx = find_in_array(DispatcherAlerts.alerts, alertID);
			if (alertIdx !== -1) {
				DispatcherAlerts.alerts.splice(alertIdx, 1);
			}
		};

		SocketPubSub.on('dispatcher alert created', function (newDispatcherAlert) {
			newDispatcherAlert.id = parseInt(newDispatcherAlert.id, 10);
			$log.info('dispatcher alert (' + newDispatcherAlert.id + ') created', newDispatcherAlert);
			DispatcherAlerts.add(newDispatcherAlert);
		});

		return DispatcherAlerts;
	});

angular.module('bringgApp').constant('DISPATCHER_ALERTS_TYPES', {
	wrong_coupon_code: 0,
	order_not_accepted_x_minutes_after_creation: 1,
	order_not_started_x_minutes_after_creation: 2,
	order_not_started_x_minutes_before_schedule_time: 3,
	customer_last_order_more_than_x_days_ago: 4,
	customer_open_app_and_not_create_order: 5,
	driver_leaving_route: 6,
	driver_long_time_on_site: 7,
	driver_ended_shift_with_orders: 8,
	driver_scans_outside_way_point_geofence: 9,
	driver_checkin_far_from_location: 10,
	driver_ended_shift_prematurely: 11,
	order_not_assigned_x_minutes_before_schedule_time: 12,
	order_not_accepted_x_minutes_before_schedule_time: 13,
	failed_to_create_fleet_delivery: 44
});
