'use strict';

angular
	.module('bringgApp')
	.controller('EditTaskController', function EditTaskController(
		$scope,
		task,
		$uibModalInstance,
		Employee,
		$location
	) {
		$scope.task = task;

		if ($location.url().indexOf('future') !== -1) {
			//      Employee.recommended({
			//        id: task.id
			//      }, function (data) {
			//        $scope.close_employees = data;
			//      });
			//    } else if ($location.url().indexOf('past') !== -1) {
			//      Employee.recommended({
			//        id: task.id
			//      }, function (data) {
			//        $scope.close_employees = data;
			//      });
			//    } else {
			//      Employee.closer({
			//        id: task.id
			//      }, function (data) {
			//        $scope.close_employees = data;
			//      });
		}

		$scope.upload_image = function (task) {
			// var myData = {task_image: $( '#fileupload' ).val()};
			// $.ajax({
			//             type: 'POST',
			//             enctype: 'multipart/form-data',
			//             url: '/api/tasks/' + task.id +'/photos',
			//             data: myData,
			//             success: function( data )
			//             {
			//                  alert( data );
			//             }
			//        });
			$('#fileupload').fileupload({
				dataType: 'json',
				done: function (e, data) {
					$.each(data.result.files, function (index, file) {
						$('<p/>').text(file.name).appendTo(document.body);
					});
				}
			});
		};

		$scope.save = function () {
			$uibModalInstance.close($scope.task);
		};

		$scope.close = function () {
			$uibModalInstance.close(undefined);
		};
	});
