'use strict';

angular.module('bringgApp').factory('ReasonToChangeInventoryModalService', function ($uibModal) {
	var ReasonToChangeInventoryModal = {};

	ReasonToChangeInventoryModal.open = function (tagId) {
		return $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			windowClass: 'reason-to-change-inventory-modal',
			controller: 'ReasonToChangeInventoryController',
			templateUrl: 'scripts/features/configuration/reason_to_change_inventory/reason-to-change-inventory.html',
			size: 'xs',
			resolve: {
				tagId: function () {
					return tagId;
				}
			}
		}).result;
	};

	return ReasonToChangeInventoryModal;
});
