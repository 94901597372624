'use strict';

angular
	.module('bringgApp')
	.controller(
		'CashOutController',
		function (
			$scope,
			Employees,
			$routeParams,
			uiGridConstants,
			uiGridGroupingService,
			uiGridGroupingConstants,
			Employee,
			toastr,
			POST_DELIVERY_TIP_TYPE,
			MerchantConfigurations,
			$filter,
			$translate,
			uiGridExporterConstants,
			DELIVERY_METHOD_TYPE,
			Authentication,
			$q,
			CashOutService,
			Tags,
			ActionInterceptorService,
			INTERCEPTABLE_ACTIONS,
			PdfExportService
		) {
			$scope.runsCount = 0;
			$scope.tasksCount = 0;
			$scope.leftToBePaidAmount = 0;
			$scope.cashoutSuccessful = false;

			$scope.settlementEnabled = MerchantConfigurations.settlement_enabled;

			function addTagColumn(featureFlags) {
				var showCashoutTag = featureFlags.show_cashout_tag || false;
				if (showCashoutTag) {
					$scope.gridOptions.columnDefs.splice(2, 0, {
						name: 'tag_id',
						displayName: $translate.instant('CASH_OUT.TAG'),
						cellFilter: 'cashoutTagName',
						enableColumnMenu: false,
						enableCellEdit: false,
						sortable: true
					});
				}
			}

			$q.all([Employees.get({ id: $routeParams.id }), Authentication.featureFlags()]).then(function (results) {
				$scope.employee = results[0];
				var featureFlags = ($scope.featureFlags = results[1]);
				addTagColumn(featureFlags);

				var showCashOutDoneProcessing = featureFlags.show_cashout_done_processing || false;
				$scope.useNewPrinting =
					featureFlags.enable_cash_out_templating || featureFlags.edit_printer_type || false;
				// get the done and not cashed out tasks of the driver
				Employee.doneNotCashedOut(
					{ id: $scope.employee.id },
					function (result) {
						if (result.success) {
							_.each(result.tasks, function (item) {
								item.distance_editable =
									!item.distance_traveled ||
									Authentication.currentUser().has_access('cashout_edit_distance');
								item.delivery_method = convertActivity($scope.employee);
								item.show_done_processing = showCashOutDoneProcessing;
							});

							$scope.data = result.tasks;
							$scope.tasksCount = result.tasks.length;
							$scope.runsCount = _.uniq(_.filter(_.map(result.tasks, 'run_uuid'))).length;
							$scope.mileagePayBase = result.distance_pay_per_km ? result.distance_pay_per_km : 0.25;
							$scope.leftToBePaidAmount = result.tasks.reduce(
								(acc, task) => (acc += task.left_to_be_paid),
								0
							);

							$scope._calculateTotalDistance();
							$scope._calculateMileagePay();
							$scope._calculateCreditCardTips();
							$scope._calculateDriverPayout();

							// enabling grouping in the table after data received to avoid grid-ui misgrouping with { groupPriority: 0 } on the same column
							if ($scope.settlementGridApi) {
								$scope.settlementGridApi.grouping.groupColumn('run_external_id');
							}
						} else {
							toastr.error($translate.instant('CASH_OUT.FAILED_FETCHING_DATA'));
						}
					},
					function () {
						toastr.error($translate.instant('CASH_OUT.FAILED_FETCHING_DATA'));
					}
				);
			});

			$scope._calculateTotalDistance = function () {
				$scope.totalDistance = 0;
				$scope.payoutMileage = 0;
				$scope.nonPayoutMileage = 0;

				_.each($scope.data, function (task) {
					var distance_traveled = !_.isNaN(parseFloat(task.distance_traveled))
						? parseFloat(task.distance_traveled)
						: 0;
					if (task.delivery_method === DELIVERY_METHOD_TYPE.PRIVATE_VEHICLE) {
						$scope.payoutMileage += distance_traveled;
					} else {
						$scope.nonPayoutMileage += distance_traveled;
					}

					$scope.totalDistance += distance_traveled;
				});
			};

			$scope._calculateMileagePay = function () {
				var total = 0;

				if (!$scope.payoutMileage) {
					$scope.mileagePay = 0;
					return total;
				}

				var localDistance = $filter('distanceByMerchant')($scope.payoutMileage);

				$scope.mileagePay = $scope.mileagePayBase * localDistance;
			};

			$scope._calculateDriverPayout = function () {
				$scope.driverPayout = 0;
				_.each($scope.data, function (item) {
					if (item.post_delivery_tip_type === POST_DELIVERY_TIP_TYPE.CARD) {
						$scope.driverPayout += item.post_delivery_tip;
					}
					$scope.driverPayout += item.pre_delivery_tip;
				});

				$scope.driverPayout += parseFloat($scope.mileagePay);
			};

			$scope._calculateCreditCardTips = function () {
				//reset the global counters
				$scope.creditCardTips = 0;
				$scope.cashTips = 0;
				//recalculate the global counters
				_.each($scope.data, function (item) {
					if (item.post_delivery_tip_type === POST_DELIVERY_TIP_TYPE.CASH) {
						$scope.cashTips += item.post_delivery_tip;
					} else {
						$scope.creditCardTips += item.post_delivery_tip;
					}

					$scope.creditCardTips += item.pre_delivery_tip;
				});
			};

			$scope.driverPayout = 0;
			$scope.creditCardTips = 0;
			$scope.cashTips = 0;
			$scope.nonPayoutMileage = 0;
			$scope.payoutMileage = 0;
			$scope.totalDistance = 0;
			$scope.mileagePay = 0;

			function getDoneProcessingTemplate() {
				return (
					'<span style="color: red; margin-left: 10px;" class="glypicon glyphicon glyphicon-flag"' +
					'ng-if="row.entity.show_done_processing && !row.entity.done_processing"></span>'
				);
			}

			$scope.export = function () {
				if ($scope.useNewPrinting) {
					var totals = {
						runsCount: $scope.runsCount,
						payoutMileage: $scope.payoutMileage,
						nonPayoutMileage: $scope.nonPayoutMileage,
						totalDistance: $scope.totalDistance,
						creditCardTips: $scope.creditCardTips,
						cashTips: $scope.cashTips,
						mileagePay: $scope.mileagePay,
						driverPayout: $scope.driverPayout
					};

					var footers = {
						external_id_aggregation: angular.element('#external_id_aggregation').text(),
						total_price_aggregation: angular.element('#total_price_aggregation').text(),
						pre_delivery_tip_aggregation: angular.element('#pre_delivery_tip_aggregation').text(),
						post_delivery_tip_aggregation: angular.element('#post_delivery_tip_aggregation').text(),
						tip_aggregation: angular.element('#tip_aggregation').text(),
						distance_traveled_aggregation: angular.element('#distance_traveled_aggregation').text()
					};

					CashOutService.print($scope.data, $scope.employee, totals, footers, $scope.cashOutDate);
				} else {
					const currentGrid = $scope.settlementEnabled ? $scope.settlementGridApi : $scope.gridApi;

					PdfExportService.export(currentGrid, [uiGridExporterConstants.ALL]);
				}
			};

			$scope.gridOptions = {
				enableSorting: true,
				showColumnFooter: true,
				enableCellEditOnFocus: true,
				exporterPdfTableHeaderStyle: { fontSize: 10, bold: true },
				exporterPdfCustomFormatter: function (docDefinition) {
					docDefinition.content[0].table.body.push([
						angular.element('#external_id_aggregation').text(),
						'',
						angular.element('#total_price_aggregation').text(),
						angular.element('#pre_delivery_tip_aggregation').text(),
						angular.element('#post_delivery_tip_aggregation').text(),
						'',
						angular.element('#tip_aggregation').text(),
						'',
						angular.element('#distance_traveled_aggregation').text()
					]);
					return docDefinition;
				},
				exporterPdfHeader: function (currentPage, pageCount) {
					if (currentPage === 1) {
						return {
							margin: [20, 20, 20, 20],
							text: 'CashOut: ' + $scope.employee.name,
							fontSize: 14,
							bold: true
						};
					}
				},
				exporterPdfFooter: function (currentPage, pageCount) {
					if (currentPage === pageCount) {
						return {
							columns: [
								[
									$translate.instant('CASH_OUT.RUNS') + ':  ' + $scope.runsCount,
									$translate.instant('CASH_OUT.PAYOUT_MILEAGE') +
										':  ' +
										$filter('locale_distance_km')($scope.payoutMileage),
									$translate.instant('CASH_OUT.NON_PAYOUT_MILEAGE') +
										':  ' +
										$filter('locale_distance_km')($scope.nonPayoutMileage),
									$translate.instant('CASH_OUT.TOTAL_MILEAGE') +
										':  ' +
										$filter('locale_distance_km')($scope.totalDistance),
									$translate.instant('CASH_OUT.CREDIT_CARD_TIPS') +
										':  ' +
										$filter('currency')($scope.creditCardTips),
									$translate.instant('CASH_OUT.CASH_TIPS') +
										':  ' +
										$filter('currency')($scope.cashTips),
									$translate.instant('CASH_OUT.DISTANCE_PAY') +
										':  ' +
										$filter('currency')($scope.mileagePay),
									$translate.instant('CASH_OUT.TOTAL_DRIVER_PAYOUT') +
										':  ' +
										$filter('currency')($scope.driverPayout)
								]
							],
							margin: [20, -80, 20, 20]
						};
					}
				},
				exporterFieldCallback: function (grid, row, col, input) {
					if (col.name === 'external_id') {
						return input;
					} else if (col.name === 'run_uuid') {
						return input;
					} else if (
						col.name === 'total_price' ||
						col.name === 'pre_delivery_tip' ||
						col.name === 'post_delivery_tip' ||
						col.name === 'tip'
					) {
						return $filter('currency')(input);
					} else if (col.name === 'post_delivery_tip_type') {
						return $filter('cashCardFilter')(input);
					} else if (col.name === 'distance_traveled') {
						return $filter('locale_distance_km')(input);
					} else if (col.name === 'delivery_method') {
						return $filter('deliveryMethodFilter')(input);
					}
				},
				columnDefs: [
					{
						name: 'external_id',
						displayName: $translate.instant('CASH_OUT.ORDER_NUMBER'),
						enableColumnMenu: false,
						enableCellEdit: false,
						aggregationTemplate: 'total',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope"><a href="#/history/{{row.entity.id}}">{{COL_FIELD CUSTOM_FILTERS}}</a></div>',
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" translate="CASH_OUT.TOTAL" id="external_id_aggregation"></div>'
					},
					{
						name: 'run_uuid',
						displayName: $translate.instant('CASH_OUT.RUN_ID'),
						enableColumnMenu: false,
						enableCellEdit: false,
						sortable: true,
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope" tooltip-placement="right" uib-tooltip="{{COL_FIELD}}">{{COL_FIELD CUSTOM_FILTERS}}</div>'
					},
					{
						name: 'total_price',
						displayName: $translate.instant('CASH_OUT.PRICE'),
						enableColumnMenu: false,
						enableCellEdit: false,
						cellFilter: 'currency',
						aggregationType: uiGridConstants.aggregationTypes.sum,
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="total_price_aggregation">{{col.getAggregationValue() | currency }}</div>'
					},
					{
						name: 'pre_delivery_tip',
						displayName: $translate.instant('CASH_OUT.PRE_TIP'),
						enableColumnMenu: false,
						enableCellEdit: false,
						cellFilter: 'currency',
						aggregationType: uiGridConstants.aggregationTypes.sum,
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="pre_delivery_tip_aggregation">{{col.getAggregationValue() | currency }}</div>'
					},
					{
						name: 'post_delivery_tip',
						displayName: $translate.instant('CASH_OUT.POST_DELIVERY'),
						enableColumnMenu: false,
						editableCellTemplate:
							'<div><form name="inputForm"><input type="INPUT_TYPE" ui-grid-editor ng-model="MODEL_COL_FIELD" min="0" smart-float></form></div>',
						cellEditableCondition: function (gridScope) {
							return !$scope.cashoutSuccessful;
						},
						width: '86',
						cellClass: 'post-delivery-tip',
						headerCellClass: 'post-delivery-header',
						cellFilter: 'currency',
						aggregationType: uiGridConstants.aggregationTypes.sum,
						footerCellClass: 'post-delivery-tip-footer',
						cellTemplate:
							'<div class="ui-grid-cell-contents" title="TOOLTIP">{{COL_FIELD CUSTOM_FILTERS}} <span class="glyphicon glyphicon-pencil"></span></div>',
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="post_delivery_tip_aggregation">{{col.getAggregationValue() | currency }}</div>'
					},
					{
						name: 'post_delivery_tip_type',
						editModelField: 'post_delivery_tip_type',
						displayName: $translate.instant('CASH_OUT.TIP'),
						enableColumnMenu: false,
						cellEditableCondition: function (gridScope) {
							// cashout has not been done yet, and in case its cash type, you cannot set card
							return !$scope.cashoutSuccessful && gridScope.row.entity.tip_driver_enabled;
						},
						editableCellTemplate:
							'<div><form name="inputForm"><select class="form-control" ui-grid-edit-dropdown2 ng-model="MODEL_COL_FIELD" ng-options="field[editDropdownIdLabel] as field[editDropdownValueLabel] CUSTOM_FILTERS for field in editDropdownOptionsArray"></select></form></div>',
						width: '80',
						editDropdownOptionsArray: [
							{ id: POST_DELIVERY_TIP_TYPE.CASH, name: $translate.instant('CASH_OUT.CASH') },
							{ id: POST_DELIVERY_TIP_TYPE.CARD, name: $translate.instant('CASH_OUT.CARD') }
						],
						editDropdownValueLabel: 'name',
						cellFilter: 'cashCardFilter',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope">{{COL_FIELD CUSTOM_FILTERS}}  <span class="caret"></span></div>'
					},
					{
						name: 'tip',
						displayName: $translate.instant('CASH_OUT.TOTAL_TIP'),
						cellFilter: 'currency',
						enableColumnMenu: false,
						enableCellEdit: false,
						aggregationType: uiGridConstants.aggregationTypes.sum,
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="tip_aggregation">{{col.getAggregationValue() | currency }}</div>'
					},
					{
						name: 'delivery_method',
						displayName: $translate.instant('CASH_OUT.DELIVERY_METHOD'),
						cellEditableCondition: function (gridScope) {
							return !$scope.cashoutSuccessful;
						},
						editableCellTemplate:
							'<div><form name="inputForm"><select class="form-control" ui-grid-edit-dropdown2 ng-model="MODEL_COL_FIELD" ng-options="field[editDropdownIdLabel] as field[editDropdownValueLabel] CUSTOM_FILTERS for field in editDropdownOptionsArray"></select></form></div>',
						width: '150',
						editDropdownOptionsArray: [
							{
								id: DELIVERY_METHOD_TYPE.PRIVATE_VEHICLE,
								name: $translate.instant('CASH_OUT.PRIVATE_VEHICLE')
							},
							{
								id: DELIVERY_METHOD_TYPE.COMPANY_VEHICLE,
								name: $translate.instant('CASH_OUT.COMPANY_VEHICLE')
							},
							{ id: DELIVERY_METHOD_TYPE.CYCLING, name: $translate.instant('CASH_OUT.CYCLING') },
							{ id: DELIVERY_METHOD_TYPE.WALKING, name: $translate.instant('CASH_OUT.WALKING') }
						],
						editDropdownValueLabel: 'name',
						cellFilter: 'deliveryMethodFilter',
						cellTemplate:
							'<div class="ui-grid-cell-contents ng-binding ng-scope">{{COL_FIELD CUSTOM_FILTERS}}  <span class="caret"></span></div>'
					},
					{
						name: 'distance_traveled',
						displayName: $translate.instant('CASH_OUT.DISTANCE'),
						enableColumnMenu: false,
						cellFilter: 'locale_distance_km',
						cellEditableCondition: function (gridScope) {
							return gridScope.row.entity.distance_editable && !$scope.cashoutSuccessful;
						},
						editableCellTemplate:
							'<div><form name="inputForm"><input class="cashoutDistanceInput" type="INPUT_TYPE" ui-grid-editor  distance-locale-input ng-model="MODEL_COL_FIELD" min="0"></form></div>',
						aggregationType: uiGridConstants.aggregationTypes.sum,
						cellTemplate:
							'<div class="ui-grid-cell-contents" title="TOOLTIP">' +
							'{{COL_FIELD CUSTOM_FILTERS}} <span class="glyphicon glyphicon-pencil" ng-if="row.entity.distance_editable"></span>' +
							getDoneProcessingTemplate() +
							'</div>',
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="distance_traveled_aggregation" >{{col.getAggregationValue() | locale_distance_km }}</div>'
					}
				],
				data: 'data'
			};

			$scope.gridOptions.onRegisterApi = function (gridApi) {
				//set gridApi on scope
				$scope.gridApi = gridApi;
				gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
					//update the row
					rowEntity.tip = rowEntity.pre_delivery_tip + rowEntity.post_delivery_tip;

					$scope._calculateTotalDistance();
					$scope._calculateCreditCardTips();
					$scope._calculateMileagePay();
					$scope._calculateDriverPayout();
					$scope.$apply();
				});
			};

			$scope.settlementGridOptions = {
				enableSorting: true,
				showColumnFooter: true,
				enableCellEditOnFocus: false,
				columnDefs: [
					{
						name: 'run_external_id',
						displayName: $translate.instant('CASH_OUT.RUN_EXTERNAL_ID'),
						enableColumnMenu: false,
						enableGrouping: true,
						sortable: true,
						customTreeAggregationFinalizerFn: function (aggregation) {
							aggregation.rendered = aggregation.groupVal;
						},
						footerCellTemplate: `<div class="ui-grid-cell-contents" id="run_external_id_aggregation"><span translate="CASH_OUT.RUNS"></span>: {{grid.appScope.runsCount}}</div>`
					},
					{
						name: 'external_id',
						displayName: $translate.instant('CASH_OUT.ORDER_NUMBER'),
						enableColumnMenu: false,
						treeAggregationType: uiGridGroupingConstants.aggregation.COUNT,
						customTreeAggregationFinalizerFn: function (aggregation) {
							aggregation.rendered = `${aggregation.value} ${$translate.instant('CASH_OUT.ORDERS')}`;
						},
						cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope">
							<a ng-if="!row.groupHeader" href="#/history/{{row.entity.id}}">{{COL_FIELD CUSTOM_FILTERS}}</a>
							<span ng-if="row.groupHeader">{{COL_FIELD CUSTOM_FILTERS}}</span>
						</div>`,
						footerCellTemplate: `<div class="ui-grid-cell-contents" id="order_count_aggregation"><span translate="CASH_OUT.TOTAL"></span>: {{grid.appScope.tasksCount}}</div>`
					},
					{
						name: 'customer_name',
						displayName: $translate.instant('CASH_OUT.CUSTOMER_NAME'),
						enableColumnMenu: false
					},
					{
						name: 'ended_time',
						displayName: $translate.instant('CASH_OUT.ACTUAL_DELIVERY'),
						enableColumnMenu: false,
						cellFilter: `date : 'medium'`
					},
					{
						name: 'left_to_be_paid',
						displayName: $translate.instant('CASH_OUT.LEFT_TO_BE_PAID'),
						enableColumnMenu: false,
						enableCellEdit: false,
						cellFilter: 'currency',
						treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
						customTreeAggregationFinalizerFn: function (aggregation) {
							aggregation.rendered = aggregation.value;
						},
						aggregationType: uiGridConstants.aggregationTypes.sum,
						footerCellTemplate:
							'<div class="ui-grid-cell-contents" id="left_to_be_paid_aggregation"><span translate="CASH_OUT.TOTAL"></span>: {{grid.appScope.leftToBePaidAmount | currency}}</div>'
					}
				],
				exporterPdfHeader: $scope.gridOptions.exporterPdfHeader,
				exporterPdfCustomFormatter: function (docDefinition) {
					docDefinition.content[0].table.body.push([
						{ text: angular.element('#run_external_id_aggregation').text(), style: 'tableHeader' },
						{ text: angular.element('#order_count_aggregation').text(), style: 'tableHeader' },
						'',
						'',
						{ text: angular.element('#left_to_be_paid_aggregation').text(), style: 'tableHeader' }
					]);
					return docDefinition;
				},
				exporterFieldCallback: function (grid, row, col, input) {
					if (col.name === 'left_to_be_paid') {
						return $filter('currency')(input);
					}

					if (col.name === 'ended_time') {
						return $filter('date')(input, 'medium');
					}

					return input;
				},
				data: 'data'
			};

			$scope.settlementGridOptions.onRegisterApi = function (settlementGridApi) {
				//set gridApi on scope
				$scope.settlementGridApi = settlementGridApi;
			};

			function checkDistanceTraveled() {
				return _.find($scope.data, { distance_traveled: null });
			}

			$scope.handleCashOutClick = function (markHome) {
				if (!$scope.settlementEnabled && checkDistanceTraveled()) {
					toastr.error($translate.instant('CASH_OUT.PLEASE_FILL_OUT_THE_DISTANCE_IN_ALL_TASKS'));
					return;
				}

				return ActionInterceptorService.run(INTERCEPTABLE_ACTIONS.EMPLOYEE_CASH_OUT, {}).then(function () {
					$scope.cashoutSuccessful = true;
					Employee.cashOut(
						{
							id: $scope.employee.id,
							tasks: $scope.data,
							distance_pay_per_km: $scope.mileagePayBase,
							mark_home: markHome,
							total_pay: $scope.driverPayout
						},
						function (result) {
							if (result.success) {
								toastr.success($translate.instant('CASH_OUT.CASH-OUT_REPORT_WAS_SUBMITTED'));
								$scope.cashOutDate = new Date();
								return;
							}

							toastr.error(
								result.message || $translate.instant('CASH_OUT.FAILED_SUBMITTING_THE_CASH-OUT_REPORT')
							);
							$scope.cashoutSuccessful = false;
						},
						function () {
							toastr.error($translate.instant('CASH_OUT.FAILED_SUBMITTING_THE_CASH-OUT_REPORT'));
							$scope.cashoutSuccessful = false;
						}
					);
				});
			};

			function convertActivity(user) {
				if (user.private_vehicle) {
					return DELIVERY_METHOD_TYPE.PRIVATE_VEHICLE;
				}

				switch (user.default_user_activity) {
					case 2: //walking
					case 3: //running
						return DELIVERY_METHOD_TYPE.WALKING;
					case 4: // bicycle
						return DELIVERY_METHOD_TYPE.CYCLING;
					default:
						return DELIVERY_METHOD_TYPE.COMPANY_VEHICLE;
				}
			}
		}
	)
	.filter('cashCardFilter', function (POST_DELIVERY_TIP_TYPE, $translate) {
		return function (input) {
			return input === POST_DELIVERY_TIP_TYPE.CASH
				? $translate.instant('CASH_OUT.CASH')
				: $translate.instant('CASH_OUT.CARD');
		};
	})
	.filter('titleize', function () {
		return function (input) {
			return !!input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
		};
	})
	.filter('cashoutTagName', function (Tags) {
		var tags = Tags.all();
		var filter = function (propName) {
			return tags.$resolved ? (propName ? _.find(tags, { id: propName }).tag : '') : 'Loading...';
		};
		filter.$stateful = true;
		return filter;
	})
	.constant('POST_DELIVERY_TIP_TYPE', {
		CASH: 0,
		CARD: 1
	})
	.filter('deliveryMethodFilter', function (DELIVERY_METHOD_TYPE, $translate) {
		return function (input) {
			switch (input) {
				case DELIVERY_METHOD_TYPE.PRIVATE_VEHICLE:
					return $translate.instant('CASH_OUT.PRIVATE_VEHICLE');
				case DELIVERY_METHOD_TYPE.COMPANY_VEHICLE:
					return $translate.instant('CASH_OUT.COMPANY_VEHICLE');
				case DELIVERY_METHOD_TYPE.CYCLING:
					return $translate.instant('CASH_OUT.CYCLING');
				case DELIVERY_METHOD_TYPE.WALKING:
					return $translate.instant('CASH_OUT.WALKING');
			}
		};
	})
	.constant('DELIVERY_METHOD_TYPE', {
		PRIVATE_VEHICLE: 0,
		COMPANY_VEHICLE: 1,
		CYCLING: 2,
		WALKING: 3
	});
