'use strict';
/*global tinycolor:false*/

angular.module('bringgApp').factory('CustomerExperienceConfig', function () {
	var defaultLogo = '/images/.*logo.png';
	var defaultDestinationMarker = '/images/.*pin_destination.png';
	var defaultDriverMarker = '/images/.*pin_delivery.png';
	var newCXDefaultLogo = '/images/cx-bringg-logo-app.svg';
	var newCXDefaultDestinationMarker = '/images/cx-task-marker.svg';
	var newCXDefaultDriverMarker = '/images/cx-driver-marker.svg';
	var defaultPrimaryColor = 'black';
	var defaultTextColor = 'white';

	var CustomerExperienceConfig = {};

	CustomerExperienceConfig.getCXConfig = function (merchantConfiguration, disableCxColorManipulation) {
		var customerAppLogo = merchantConfiguration.logo;

		if (!_.isNil(new RegExp(defaultLogo).exec(customerAppLogo))) {
			customerAppLogo = newCXDefaultLogo;
		}

		var clientLocationPin = merchantConfiguration.destination_pin;
		var defaultLocationPin = false;

		if (!_.isNil(new RegExp(defaultDestinationMarker).exec(clientLocationPin))) {
			clientLocationPin = newCXDefaultDestinationMarker;
			defaultLocationPin = true;
		}

		var driverLocationPin = merchantConfiguration.delivery_pin;
		var defaultDriverPin = false;

		if (!_.isNil(new RegExp(defaultDriverMarker).exec(driverLocationPin))) {
			driverLocationPin = newCXDefaultDriverMarker;
			defaultDriverPin = true;
		}

		var primaryColor = merchantConfiguration.header_color || defaultPrimaryColor;
		var headerColor = primaryColor;
		var textColor = merchantConfiguration.eta_title_color || defaultTextColor;
		var menuItemsColor = textColor;

		if (
			!disableCxColorManipulation &&
			tinycolor(primaryColor).isLight() &&
			tinycolor(textColor).isDark() &&
			textColor
		) {
			primaryColor = merchantConfiguration.eta_title_color;
			textColor = merchantConfiguration.header_color;
			menuItemsColor = primaryColor;
		}

		return {
			customerAppLogo: customerAppLogo,
			clientLocationPin: clientLocationPin,
			defaultLocationPin: defaultLocationPin,
			driverLocationPin: driverLocationPin,
			defaultDriverPin: defaultDriverPin,
			primaryColor: primaryColor,
			textColor: textColor,
			headerColor: headerColor,
			menuItemsColor: menuItemsColor
		};
	};

	return CustomerExperienceConfig;
});
