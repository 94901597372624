'use strict';

function ClockController($scope, ov_widget) {
	$scope.value = 0;

	function formatTime(i) {
		if (i < 10) {
			return '0' + i;
		} else {
			return i;
		}
	}

	function startTime() {
		var today = new Date();

		var h = today.getHours();
		var m = today.getMinutes();
		var s = today.getSeconds();
		m = formatTime(m);
		s = formatTime(s);
		$scope.time = h + ':' + m + ':' + s;
		$scope.date = today.toDateString();
		$scope.$apply();
	}

	setInterval(startTime, 500);
}

ClockController.$inject = ['$scope', 'ov_widget'];
