'use strict';

angular
	.module('bringgApp')
	.directive('manualTriggers', function () {
		return {
			scope: {
				selectedItems: '=?'
			},
			restrict: 'E',
			templateUrl: 'scripts/directives/manual_triggers/manual-triggers.html',
			controller: 'ManualTriggersController'
		};
	})
	.controller(
		'ManualTriggersController',
		function ($scope, BringgSDK, $log, ReactActions, toastr, appReadyIndicator) {
			let taskSelectionLimit;

			$scope.toolTipText = undefined;

			appReadyIndicator.whenReady().then(function () {
				getManualTriggers().then(function (manualTriggersResponse) {
					$scope.manualTriggers = manualTriggersResponse.triggers;
					taskSelectionLimit = manualTriggersResponse.max_tasks_allowed;
				});
			});

			$scope.$watch('selectedItems.length', function () {
				const selectedItems = $scope.selectedItems || [];

				$scope.disableTriggers = !selectedItems.length || selectedItems.length > taskSelectionLimit;

				if (selectedItems.length > taskSelectionLimit) {
					$scope.toolTipText = 'AUTOMATION.TRIGGER.MANUAL.SELECTION_LIMIT_TEXT';
				} else {
					$scope.toolTipText = undefined;
				}
			});

			$scope.onTriggerClick = function (trigger) {
				const taskIds = $scope.selectedItems.map(task => task.id);
				ReactActions.manualTriggerClicked(trigger, taskIds);
			};

			function getManualTriggers() {
				return BringgSDK.getInstancePromise()
					.then(function (bringg) {
						return bringg.v2.workflows().getManualTriggers();
					})
					.catch(function (error) {
						$log.error('Failed to load manual triggers', error);
						toastr.error('Failed to load manual triggers');
					});
			}
		}
	);
