'use strict';

angular.module('bringgApp.directives').directive('input', function () {
	return {
		require: '?ngModel',
		restrict: 'E',
		link: function (scope, element, attrs, ngModel) {
			if (attrs.type === 'date' && ngModel) {
				element.on('change', function () {
					scope.$apply(function () {
						ngModel.$setViewValue(element.val());
					});
				});
			}
		}
	};
});

// On esc event
angular.module('bringgApp.directives').directive('onEsc', function () {
	return function (scope, elm, attr) {
		elm.on('keydown', function (e) {
			if (e.keyCode === 27) {
				scope.$apply(attr.onEsc);
			}
		});
	};
});

// // On enter event
angular.module('bringgApp.directives').directive('onEnter', function () {
	return function (scope, elm, attr) {
		elm.on('keypress', function (e) {
			if (e.keyCode === 13) {
				scope.$apply(attr.onEnter);
			}
		});
	};
});

// from http://icelab.com.au/articles/levelling-up-with-angularjs-building-a-reusable-click-to-edit-directive/
angular.module('bringgApp.directives').directive('clickToEdit', function () {
	var editorTemplate =
		'<div class="click-to-edit">' +
		'<div ng-hide="view.editorEnabled">' +
		'<span ng-click="enableEditor()">{{value}}</span>' +
		'</div>' +
		'<div ng-show="view.editorEnabled"">' +
		'<input ng-model="view.editableValue" class="input-sm" on-enter="save()" on-esc="disableEditor()">' +
		'<a class="btn btn-sm btn-success" href="#" ng-click="save()">Save</a>' +
		' or ' +
		'<a class="btn btn-sm btn-warning" ng-click="disableEditor()">cancel</a>' +
		'</div>' +
		'</div>';

	return {
		restrict: 'A',
		replace: true,
		template: editorTemplate,
		scope: {
			value: '=clickToEdit',
			handleSave: '&onSave'
		},
		controller: [
			'$scope',
			'$timeout',
			function ($scope, $timeout) {
				$scope.view = {
					editableValue: $scope.value,
					editorEnabled: false
				};

				$scope.enableEditor = function () {
					$scope.view.editorEnabled = true;
					$scope.view.editableValue = $scope.value;
					$timeout(function () {
						$scope.view.focus();
					}, 0);
				};

				$scope.disableEditor = function () {
					$scope.view.editorEnabled = false;
				};

				$scope.save = function () {
					$scope.value = $scope.view.editableValue;
					$scope.disableEditor();
					if ($scope.handleSave) {
						$scope.handleSave({ title: $scope.value });
					}
				};
			}
		]
	};
});
