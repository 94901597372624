'use strict';

angular.module('bringgApp.directives').directive('cxDriverMarker', function () {
	return {
		templateUrl: 'scripts/directives/customer-experience/driver-marker-directive.html',
		replace: true,
		restrict: 'E',
		scope: {
			primaryColor: '@',
			secondaryColor: '@'
		}
	};
});
