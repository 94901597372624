'use strict';

angular
	.module('bringgApp')
	.constant('ALERT_TASK_START_TRIGGER', {
		ETA: 'eta',
		START: 'start'
	})
	.controller(
		'merchantCustomerConfigurationController',
		function (
			$scope,
			toastr,
			Authentication,
			CustomerConfiguration,
			MerchantService,
			MerchantConfigurations,
			MerchantConfigurationService,
			ALERT_TASK_START_TRIGGER
		) {
			$scope.proFeaturesEnabled = false;
			$scope.merchantConfiguration = MerchantConfigurations;
			$scope.ALERT_TASK_START_TRIGGER = ALERT_TASK_START_TRIGGER;
			$scope.notificationTemplatesEnabled = Authentication.currentUser().feature_flags.use_notification_templates;
			$scope.graphemeSplitter = new GraphemeSplitter();

			// default value
			$scope.alertCustomerOnOrderOptions = {
				trigger: ALERT_TASK_START_TRIGGER.START
			};

			$scope.updateSampleText = function (origin, result) {
				if ($scope.customerConfiguration[origin]) {
					$scope[result] = $scope.customerConfiguration[origin];
					$scope[result] = $scope[result].replace('#customer', Authentication.currentUser().name);
					$scope[result] = $scope[result].replace('#user', 'Driver');
					$scope[result] = $scope[result].replace('#link', 'https://app.bringg.com/s.html?9f67d952');
					$scope[result] = $scope[result].replace('#job', 'Job Description');
					$scope[result] = $scope[result].replace('#merchant', $scope.merchantConfiguration.name);
					$scope[result] = $scope[result].replace('#title', 'Order title');
					$scope[result] = $scope[result].replace('#id', 'Order ID');
					$scope[result] = $scope[result].replace('#way_point_customer', 'Customer Name');
					$scope[result] = $scope[result].replace('#first_name', 'Customer First Name');
					$scope[result] = $scope[result].replace('#time_window', '[From - To]');
					$scope[result] = $scope[result].replace('#address', 'Street, number and address');
					$scope[result] = $scope[result].replace('#team_description', 'Team Description');
					$scope[result] = $scope[result].replace('#uuid', 'Unique ID');
					$scope[result] = $scope[result].replace('#verification_pin_code', '0000');
				}

				MerchantService.proFeaturesEnabled().then(function (proFeaturesEnabled) {
					$scope.proFeaturesEnabled = proFeaturesEnabled;
				});
			};

			$scope.getTextMessageSize = function (field) {
				if (!$scope[field]) {
					return;
				}
				var segmentedMessage = new SegmentedMessage($scope[field], $scope.graphemeSplitter);
				return segmentedMessage.segments.length;
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn, oldValue) {
				if (isLoggedIn) {
					CustomerConfiguration.get(function (data) {
						$scope.customerConfiguration = data;
						// convert alert_customer_minutes_before_eta to enum if used
						$scope.alertCustomerOnOrderOptions.trigger =
							_.isNumber($scope.customerConfiguration.alert_customer_minutes_before_eta) &&
							$scope.customerConfiguration.alert_customer_minutes_before_eta > 0
								? ALERT_TASK_START_TRIGGER.ETA
								: ALERT_TASK_START_TRIGGER.START;

						$scope.updateSampleText('shared_alert_text', 'sample_sms');
						$scope.updateSampleText('alert_on_checkin_string', 'sample_checkin_sms');
						$scope.updateSampleText('alert_on_checkout_string', 'sample_checkout_sms');
						$scope.updateSampleText('alert_on_route_started_string', 'sample_alert_on_route_sms');
						$scope.updateSampleText('alert_customer_on_schedule_text', 'sample_reschedule_sms');
						$scope.updateSampleText('alert_customer_schedule_ready_text', 'sample_schedule_sms');
						$scope.updateSampleText('alert_on_task_created_string', 'sample_alert_on_task_created_sms');
						$scope.updateSampleText('alert_on_customer_pickup_string', 'sample_customer_pickup_sms');
					});
				}
			});

			$scope.updateCustomerAppConfiguration = function () {
				// reset alert_customer_minutes_before_eta once not using eta
				if ($scope.alertCustomerOnOrderOptions.trigger != ALERT_TASK_START_TRIGGER.ETA) {
					$scope.customerConfiguration.alert_customer_minutes_before_eta = null;
				}

				CustomerConfiguration.update($scope.customerConfiguration, function (response) {
					if (response.success) {
						Promise.all([
							MerchantConfigurationService.update($scope.merchantConfiguration),
							CustomerConfiguration.installRequiredApplications($scope.customerConfiguration)
						])
							.then(function () {
								//TODO: update cookiestore merchant info here
								toastr.success('Update saved, thank you');
								//$window.history.back();
							})
							.catch(function () {
								toastr.error('Failed updating');
							});
					} else {
						var errorString = '';
						if (typeof response.message === 'string') {
							errorString = response.message;
						} else {
							for (var messageIdx in response.message) {
								errorString += messageIdx + ':' + response.message[messageIdx][0] + '\r\n';
							}
						}
						toastr.error(errorString);
					}
				});
			};

			$scope.sharingModeValues = [
				'All methods',
				'Only via email',
				'Only via SMS',
				'Do not share with customer',
				'Only webhook'
			];
			$scope.selectedSharingMode = MerchantConfigurations.sharing_mode;
			$scope.alertOnCheckoutValues = ['Never', 'On every checkout', 'On every checkout if was not rated'];
			$scope.alertOnCheckoutMode = MerchantConfigurations.alert_on_checkout;
		}
	);
