'use strict';

angular.module('bringgApp').factory('Optimization', function (Authentication, BringgSDK, $resource, WEB_API_URL) {
	var Optimization = $resource(
		WEB_API_URL + '/route_optimizations/',
		{},
		{
			recalculateEta: { method: 'POST', url: WEB_API_URL + '/route_optimizations/recalculate_eta' },
			optimizeAssignUsersByUserTypes: {
				method: 'POST',
				url: WEB_API_URL + '/route_optimizations/assign_users_by_user_types'
			},
			optimizeAssignUsersByIds: {
				method: 'POST',
				url: WEB_API_URL + '/route_optimizations/assign_users_by_ids'
			},
			createRunsByRoutes: {
				method: 'POST',
				url: WEB_API_URL + '/route_optimizations/create_runs_by_optimization_routes'
			},
			assignUserManualOptimization: {
				method: 'POST',
				url: WEB_API_URL + '/route_optimizations/assign_user_manual_optimization'
			}
		}
	);

	Optimization.optimize = function (optimizationRequest) {
		return BringgSDK.getInstancePromise().then(function (bringg) {
			return bringg.routeOptimizationApi.optimize(optimizationRequest);
		});
	};

	Optimization.updateOptimizationModifiedData = function (request) {
		return BringgSDK.getInstancePromise().then(function (bringg) {
			return bringg.routeOptimizationApi.updateOptimizationModifiedData(request);
		});
	};

	Optimization.optimizationResult = function (requestUuid) {
		return BringgSDK.getInstancePromise().then(function (bringg) {
			return bringg.routeOptimizationApi.optimizationResult(requestUuid);
		});
	};

	return Optimization;
});
