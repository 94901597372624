/**
 * Created by liorsion on 12/24/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller('ChangeTierSubscriptionTypeController', function (
		$scope,
		Authentication,
		Payments,
		$uibModalInstance,
		currentSubscription,
		toastr
	) {
		$scope.data = {};
		$scope.current_user = Authentication.currentUser();
		$scope.data.originalSubscriptionType = Payments.getConfigurationName(currentSubscription).toLowerCase();
		$scope.data.selectedSubscriptionType = Payments.getConfigurationName(currentSubscription).toLowerCase();

		$scope.handleChangeSubscriptionTypeResult = function (result) {
			if (result.success) {
				toastr.success('Your plan was successfully changed.');
				$uibModalInstance.close();
			} else {
				if (result.message) {
					toastr.error(result.message);
				} else {
					toastr.error('There was a problem changing your subscription type.');
				}
			}
		};

		$scope.handleSaveClick = function () {
			if ($scope.data.originalSubscriptionType === $scope.data.selectedSubscriptionType) {
				return; //this should not happen the view should be disabled
			}

			Payments.changeSubscriptionType(
				{ selected_subscription_type: $scope.data.selectedSubscriptionType },
				$scope.handleChangeSubscriptionTypeResult
			);
		};

		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};
	});
