'use strict';

angular
	.module('bringgApp')
	.factory('Teams', function ($rootScope, MerchantConfigurations, Merchant, $q, $translate, BringgSDK) {
		function teamsService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.teams[method].apply(bringg.teams, args);
				});
			}.bind(this);
		}

		function getDriversByTeam(teamId) {
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.users.getDriversByTeam(teamId);
			});
		}

		var Teams = {
			teams: [],
			teamsByIdMap: {},
			teamsChildren: {},
			isFetched: false
		};

		var teamIdsWithTrailers = {};

		Teams.get = teamsService('get');
		Teams.update = teamsService('update');
		Teams.create = teamsService('create');
		Teams.getAll = teamsService('getAll');
		Teams.getDrivers = getDriversByTeam;
		Teams.byMerchantId = teamsService('byMerchantId');

		$rootScope.$on('loggedout', function () {
			Teams.teams = [];
			Teams.isFetched = false;
			delete Teams.teams.$resolved;
		});

		/**
		 * update team
		 */
		Teams.updateTeam = function (updatedTeam) {
			var team = _.find(Teams.teams, { id: updatedTeam.id });
			if (team) {
				_.extend(team, updatedTeam);
			}
		};

		Teams.all = function () {
			var cb = getSuccessCallback(arguments);

			if (Teams.isFetched) {
				if (cb) {
					cb(Teams.teams);
				}

				return $q.resolve(Teams.teams);
			}

			if (!MerchantConfigurations.enable_teams) {
				if (cb) {
					cb();
				}
				return $q.resolve();
			}

			return Teams._fetchAll().then(function (teams) {
				if (cb) {
					cb(teams);
				}
				return $q.resolve(teams);
			});
		};

		// Caching the `_fetchAll` promise so we can coalesce the handlers
		// and do the work only once.
		Teams._fetchAllPromise = null;

		Teams._fetchAll = function () {
			if (!Teams._fetchAllPromise) {
				Teams._fetchAllPromise = Teams.getAll()
					.then(function (teams) {
						Teams._fetchAllPromise = null;

						if (teams.length === 0) {
							Teams.teams.push({ id: 0, name: 'Default Team' });
						}

						Teams.teams = teams;
						Teams.teams.$resolved = true;
						Teams.isFetched = true;
						Teams.teamsByIdMap = _.keyBy(teams, 'id');
						Teams._initTeamsChildren(Teams.teamsByIdMap);

						return $q.resolve(teams);
					})
					.catch(error => {
						Teams._fetchAllPromise = null;

						return $q.reject(error);
					});
			}

			return Teams._fetchAllPromise;
		};

		Teams.getTeamIdsWithChildIds = function (teamIds) {
			return _.reduce(
				teamIds,
				function (acc, teamId) {
					acc.push(teamId);
					// eslint-disable-next-line no-prototype-builtins
					if (Teams.teamsChildren.hasOwnProperty(teamId)) {
						acc = acc.concat(Teams.teamsChildren[teamId]);
					}
					return acc;
				},
				[]
			);
		};

		Teams._initTeamsChildren = function (teamsById) {
			Teams.teamsChildren = {};
			Object.values(teamsById).forEach(function (team) {
				Teams._addChildrenToParentTeam(teamsById, team);
			});
		};

		Teams._addChildrenToParentTeam = function (teamsById, team) {
			if (_.isNil(team.parent_team_id)) {
				return;
			}

			// eslint-disable-next-line no-prototype-builtins
			if (!Teams.teamsChildren.hasOwnProperty(team.parent_team_id)) {
				Teams.teamsChildren[team.parent_team_id] = [];
			}
			Teams.teamsChildren[team.parent_team_id].push(team.id);
		};

		Teams._initTeams = function () {
			if (MerchantConfigurations.enable_teams) {
				Teams._fetchAll().catch(function () {
					Teams.teams.push({ id: 0, name: 'Default Team' });
				});
			} else {
				Teams.teams.$resolved = true;
				Teams.teams.push({ id: 0, name: 'Default Team' });
			}
		};

		$rootScope.$on('config updated', Teams._initTeams);
		Teams._initTeams();

		Teams.add = function (teamData, cb) {
			Merchant.get().then(function (merchant) {
				teamData.merchant_id = merchant.id;
				Teams.create(teamData)
					.then(function (team) {
						Teams.teams.push(team);
						if (cb) {
							cb({ success: true, team: team });
						}
					})
					.catch(function (error) {
						if (cb) {
							// this logic done on purpose to handle the real reason(message) why the team wasn't created
							// sometimes message from BE returning as Array<string> and dashboard-sdk casting it to string with index of message in array
							// this is why `.replace(/^0 */, '')` used to remove first symbols that are not related to real message
							const message = error.details ? String(error.details).replace(/^0 */, '') : error.message;
							cb({ success: false, message });
						}
					});
			});
		};

		Teams.fetch = function (id) {
			return _.find(Teams.teams, function (team) {
				return team.id === id;
			});
		};

		Teams.getByIds = function (ids) {
			return Teams.all().then(function (teams) {
				if (Teams.teamsByIdMap) {
					return _.chain(ids)
						.map(function (id) {
							return Teams.teamsByIdMap[id];
						})
						.compact()
						.value();
				} else {
					return _.filter(teams, function (team) {
						return _.includes(ids, team.id);
					});
				}
			});
		};

		Teams.byUser = function (employee, cb) {
			return Teams.getByIds(employee.team_ids).then(function (employeeTeams) {
				if (_.isFunction(cb)) {
					cb(employeeTeams);
				}

				return employeeTeams;
			});
		};

		Teams.featureFlags = function () {
			if (
				!MerchantConfigurations.enable_teams ||
				($rootScope.sdk.session.user && $rootScope.sdk.session.user.admin)
			) {
				return $q.resolve({});
			}

			return Teams.all().then(function () {
				var userTeamsFeatureFlags = {};
				_.each(Teams.teams, function (team) {
					_.extend(userTeamsFeatureFlags, team.feature_flags);
				});
				return userTeamsFeatureFlags;
			});
		};

		Teams.teamsNamesById = function (ids) {
			return _.map(ids, function (teamId) {
				return _.get(Teams.teamsByIdMap[teamId], 'name');
			});
		};

		function isValidTrailer(vehicle, vehicleTypesByVehicleId) {
			return (
				vehicle.vehicle_type_id &&
				vehicle.is_trailer &&
				vehicleTypesByVehicleId[vehicle.id].max_total_weight > 0
			);
		}

		Teams.setTeamHasTrailer = function (teams, vehicles, vehicleTypesByVehicleId) {
			_.forEach(vehicles, function (vehicle) {
				if (isValidTrailer(vehicle, vehicleTypesByVehicleId)) {
					teamIdsWithTrailers[vehicle.team_id] = true;
				}
			});
		};

		Teams.hasTrailer = function (teamId) {
			return teamIdsWithTrailers[teamId];
		};

		return Teams;
	});
