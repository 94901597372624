'use strict';

angular.module('bringgApp').service('MaxLoadCalculationIndicator', function (Authentication) {
	const _this = this;
	_this.shouldCalcLoadRecursively = shouldCalcLoadRecursively;

	function shouldCalcLoadRecursively() {
		return Authentication.currentUser().feature_flags.calc_task_inventory_load_recursively || false;
	}
});
