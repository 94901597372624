'use strict';

var BRINGG_NOW_REDIRECT = 'https://support.bringg.com/hc/en-us/community/topics';

angular
	.module('bringgApp')
	.factory(
		'SubmitTicketService',
		function (
			$q,
			$uibModal,
			$resource,
			WEB_API_URL,
			MerchantService,
			SUBSCRIPTION_TYPE,
			$window,
			ReactModals,
			ClientStatsService
		) {
			var Ticket = $resource(WEB_API_URL + '/ticket');

			var SubmitTicketService = {};

			SubmitTicketService.getTicketFields = function (ticketFields) {
				return {
					title: () => (ticketFields ? ticketFields.title : ''),
					origin: () => (ticketFields ? ticketFields.origin : ''),
					type: () => (ticketFields ? ticketFields.type : ''),
					description: () => (ticketFields ? ticketFields.description : ''),
					affectedFunctionality: () => (ticketFields ? ticketFields.affectedFunctionality : '')
				};
			};

			SubmitTicketService.open = function (ticketFields) {
				MerchantService.get().then(function (merchant) {
					if (merchant.subscription_type === SUBSCRIPTION_TYPE.O2M) {
						$window.open(BRINGG_NOW_REDIRECT, '_blank', 'noopener noreferrer');
						return;
					}
					const prepopulatedFields = SubmitTicketService.getTicketFields(ticketFields);

					if (merchant && merchant.feature_flags && merchant.feature_flags.enable_new_support_ticket) {
						ReactModals.openSubmitTicketModal({
							ticketFields: prepopulatedFields
						});
					} else {
						return $uibModal.open({
							windowClass: 'submit-ticket-modal',
							backdropClass: 'submit-ticket-modal-backdrop',
							controller: 'SubmitTicketController',
							templateUrl: 'scripts/features/submit_ticket/submit-ticket.html',
							resolve: prepopulatedFields
						}).result;
					}
				});
			};

			SubmitTicketService.send = function (params) {
				var deferred = $q.defer();

				var ticket = new Ticket(params);
				ticket.$save(function (result) {
					if (result.success) {
						deferred.resolve();
					} else {
						deferred.reject(result.message);
					}
				});

				return deferred.promise;
			};

			return SubmitTicketService;
		}
	);
