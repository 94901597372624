'use strict';

angular.module('bringgApp').config(function ($provide) {
	$provide.decorator('$log', function ($delegate, ClientStatsServiceBase) {
		var enabled = false;

		var formatLog = function (logString) {
			if (logString.stack) {
				return logString + ' :: ' + logString.stack;
			} else {
				return logString;
			}
		};

		var createTimePrefix = function () {
			return moment().format('MM-DD-YY hh:mm:ss:ms') + '::';
		};

		var writeLog = function (logFunc, args) {
			var argsArr = [].slice.call(args); // just like "Array.from(args)" but in ES5 ...
			argsArr[0] = formatLog(args[0]);
			argsArr.unshift(createTimePrefix());
			logFunc.apply(null, argsArr);
			ClientStatsServiceBase.addLog(argsArr);
		};

		var methods = {
			error: function () {
				writeLog(console.error, arguments);
			},
			log: function () {
				if (enabled) {
					writeLog(console.log, arguments);
				}
			},
			info: function () {
				if (enabled) {
					writeLog(console.info, arguments);
				}
			},
			warn: function () {
				if (enabled) {
					writeLog(console.warn, arguments);
				}
			},
			debug: function () {
				if (enabled) {
					writeLog(console.debug, arguments);
				}
			},
			enable: function (flag) {
				enabled = !!flag;
			}
		};

		return methods;
	});
});
