/**
 * Created by liorsion on 1/24/17.
 */

'use strict';

angular
	.module('bringgApp')
	.controller(
		'PrivilegeController',
		function ($scope, Privileges, Privilege, $routeParams, toastr, $q, Authentication) {
			$scope.loading = true;
			$scope.notFound = false;
			$scope.showDriverPrivilege = false;
			$scope.allPrivileges = {};
			$scope.data = {
				authorization_flags: {}
			};

			$scope._init = function () {
				if ($routeParams.id) {
					$q.all([Privilege.get({ id: $routeParams.id }).$promise, Privilege.getList().$promise])
						.then(function (results) {
							var specificPrivilege = results[0];
							$scope._renderPrivilege(specificPrivilege);
							$scope.allPrivileges = results[1].privileges;
							$scope.normalizePrivilege(specificPrivilege);
							$scope.loading = false;
						})
						.catch(function () {
							$scope.loading = false;
							$scope.notFound = true;
						});
				}

				$scope.headers = [['id', 'ID'], ['name', 'Name'], ['']];
			};

			$scope._init();

			$scope.normalizePrivilege = function (specificPrivilege) {
				const VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE_KEY = 'view_consumer_verification_code';

				_.each($scope.allPrivileges, function (privilege) {
					if (specificPrivilege.authorization_flags) {
						// this part of the code(if/else) needs to filter out VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE until FF won't be released
						if (
							privilege.field === VIEW_CONSUMER_VERIFICATION_CODE_PRIVILEGE_KEY &&
							!Authentication.currentUser().feature_flags.enable_driver_verify_pin_code
						) {
							return;
						}

						var currentPrivilege = specificPrivilege.authorization_flags[privilege.field] || {};
						var privilegeObject = {
							title: privilege.title
						};

						if (privilege.sub_values) {
							privilegeObject.sub_values = privilege.sub_values;
							privilegeObject.default = privilege.default;

							privilege.values.forEach(function (subPrivlege) {
								privilegeObject[subPrivlege.field] = subPrivlege;
							});

							privilegeObject.value = _.first(Object.keys(currentPrivilege));
						} else {
							var flag = currentPrivilege.value;

							if (_.isUndefined(flag) && !_.isNull(privilege.default)) {
								flag = privilege.default;
							}

							if (!_.isBoolean(flag)) {
								flag = true;
							}

							privilegeObject.value = flag;
						}
						privilege.is_driver_related && ($scope.showDriverPrivilege = true);
						privilegeObject.isDriverRelated = privilege.is_driver_related ?? false;
						$scope.data.authorization_flags[privilege.field] = privilegeObject;
					}
				});
			};

			$scope.updatePrivilege = function () {
				var authFlags = angular.copy($scope.data.authorization_flags);
				var privilegeNames = Object.keys($scope.allPrivileges);

				privilegeNames.forEach(function (privilegeKey) {
					var privilegeName = $scope.allPrivileges[privilegeKey].field;
					var privilege = authFlags[privilegeName];

					// We add this key to separate Dispatcher and Driver Privilege.
					// Before sending the API request, we remove it from the object.
					privilege && delete privilege.isDriverRelated;
					if (privilege && privilege.sub_values) {
						var privilegeField = privilege.value;

						authFlags[privilegeName] = {
							title: privilege.title
						};

						authFlags[privilegeName][privilegeField] = true;
					}
				});

				Privileges.update({ id: $scope.privilege.id, authorization_flags: authFlags })
					.then(function (updatedPrivilege) {
						$scope.privilege = updatedPrivilege;
						$scope.normalizePrivilege(updatedPrivilege);
						toastr.success('Privilege was updated!');
					})
					.catch(function () {
						$scope.normalizePrivilege($scope.privilege);
						toastr.error('Failed updating privileges');
					});
			};

			$scope.privilegeSubmit = function () {
				if ($routeParams.id) {
					$scope.updatePrivilege();
				}
			};

			$scope._renderPrivilege = function (data) {
				if (data) {
					$scope.privilege = angular.copy(data);
				} else {
					$scope.loading = false;
					$scope.notFound = true;
				}
			};
		}
	);
