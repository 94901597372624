'use strict';

angular.module('bringgApp.directives').directive('routesMonitoring', function () {
	return {
		restrict: 'E',
		replace: true,
		template: '<div id="monitoring-portal" class="react-component" params="orderDetailsParams"></div>',
		controller: 'TaskController'
	};
});
