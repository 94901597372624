'use strict';

angular.module('bringgApp.directives').directive('safeHighlight', function () {
	return {
		restrict: 'A',
		scope: {
			option: '=',
			query: '='
		},
		templateUrl: 'scripts/directives/safe-highlight/safe-highlight.html',
		link: function (scope) {
			var escapeRegexp = function (queryToEscape) {
				return ('' + queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
			};

			scope.highlight = function () {
				var regex = new RegExp(escapeRegexp(scope.query), 'gi');
				var lastIndex = 0;
				scope.parts = [];
				var match = regex.exec(scope.option);
				while (match) {
					var part = scope.option.substring(lastIndex, match.index);
					scope.parts.push({ text: part, highlighted: match[0] });
					lastIndex = match.index + match[0].length;
					match = regex.exec(scope.option);
				}
				scope.lastPart = scope.option.substring(lastIndex);
			};

			scope.$watchGroup(['query', 'option'], function () {
				if (!scope.query || !scope.option) {
					return;
				}
				scope.highlight(scope.option, scope.query);
			});
		}
	};
});
