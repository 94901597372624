'use strict';
/*global app:false */
/*global io:false */

angular
	.module('bringgApp')
	.factory('Alert', function ($resource, REAL_TIME_URL, WEB_API_URL) {
		var Alert = $resource(
			WEB_API_URL + '/alerts/',
			{},
			{
				all: { method: 'GET', url: WEB_API_URL + '/alerts' },
				dismiss: { method: 'DELETE', params: { uuid: 'uuid' }, url: WEB_API_URL + '/alerts/:uuid' },
				dismiss_all: { method: 'POST', url: WEB_API_URL + '/alerts/dismiss_all' },
				dismiss_by_ids: { method: 'POST', url: WEB_API_URL + '/alerts/dismiss_by_ids' }
			}
		);
		return Alert;
	})
	.factory(
		'Alerts',
		function (SocketPubSub, $rootScope, Alert, $q, $log, DEFAULT_RT_THROTTLE, CrossApplicationService, BringgSDK) {
			var Alerts = {
				receivedApproval: false,
				alerts: [],
				latestLimit: 10,
				totalAlerts: 0
			};

			var notifyChanges = _.throttle(function () {
				$rootScope.$broadcast('alerts update');
			}, DEFAULT_RT_THROTTLE);

			function subscribeForAlerts() {
				SocketPubSub.on('subscribe:alerts:success', function () {
					Alerts.receivedApproval = true;
					if (shouldLoadAllAlerts) {
						Alerts.loadAll().then(function () {
							shouldLoadAllAlerts = false;
						});
					} else if (shouldLoadLatestAlerts) {
						Alerts.loadLatest().then(function () {
							shouldLoadLatestAlerts = false;
						});
					}
				});

				SocketPubSub.emit('subscribe:alerts', {});

				var alertInterval = setInterval(function () {
					if (!Alerts.receivedApproval) {
						SocketPubSub.emit('subscribe:alerts', {});
					} else {
						clearInterval(alertInterval);
					}
				}, 2000);
			}

			Alerts.dismissAll = function () {
				var deferred = $q.defer();
				Alert.dismiss_all(function (result) {
					if (result.success) {
						Alerts.alerts.length = 0;
						Alerts.totalAlerts = 0;
						notifyChanges();
						deferred.resolve(result.message);
					} else {
						deferred.reject(result.message);
					}
				});
				return deferred.promise;
			};

			Alerts.dismissByIds = function (ids) {
				var deferred = $q.defer();

				Alert.dismiss_by_ids({ ids: ids }, function (result) {
					if (result.success) {
						deferred.resolve(result.message);
					} else {
						deferred.reject(result.message);
					}
				});
				return deferred.promise;
			};

			Alerts.parseAlert = function (alert) {
				alert.task_ids = alert.extra_data.task_ids || [];
				alert.external_ids = alert.extra_data.external_ids || [];

				if (alert.task_id && !_.includes(alert.task_ids, alert.task_id)) {
					alert.task_ids.push(alert.task_id);
				}

				if (
					alert.extra_data.task_external_id &&
					!_.includes(alert.external_ids, alert.extra_data.task_external_id)
				) {
					alert.external_ids.push(alert.extra_data.task_external_id);
				}

				return alert;
			};

			SocketPubSub.on('new alert', function (newAlerts) {
				addAlerts(newAlerts);
			});

			SocketPubSub.on('remove alert', function (alert) {
				$log.info('Removing alert [' + alert.uuid + ']');
				var indexOfAlertToRemove = _.findIndex(Alerts.alerts, { uuid: alert.uuid });
				if (indexOfAlertToRemove !== -1) {
					Alerts.alerts.splice(indexOfAlertToRemove, 1);
				}

				// even if not found, still need to reduce counter
				Alerts.totalAlerts--;
				if (Alerts.totalAlerts < Alerts.alerts.length) {
					Alerts.totalAlerts = Alerts.alerts.length;
				}
				notifyChanges();
			});

			Alerts.handleMassRemove = function (options) {
				// just remove all alerts
				if (options.remove_all) {
					$log.info('Removing all alerts');
					Alerts.alerts.length = 0;
					Alerts.totalAlerts = 0;
				}
				// remove only team related alerts
				else {
					var team_ids = options.team_ids;
					var alertsUUidToRemove = _.chain(Alerts.alerts)
						.filter(function (alert) {
							return _.some(_.intersection(team_ids, alert.team_ids));
						})
						.map('uuid')
						.valueOf();

					_.each(alertsUUidToRemove, function (alertUUID) {
						var indexOfAlertToRemove = _.findIndex(Alerts.alerts, { uuid: alertUUID });
						if (indexOfAlertToRemove !== -1) {
							Alerts.alerts.splice(indexOfAlertToRemove, 1);
						}
					});

					Alerts.totalAlerts -= options.totalToRemove;
				}

				if (Alerts.totalAlerts < Alerts.alerts.length) {
					Alerts.totalAlerts = Alerts.alerts.length;
				}
				notifyChanges();
			};

			SocketPubSub.on('remove alerts', Alerts.handleMassRemove);

			$rootScope.$on('loggedout', function (nothing, newAlert) {
				Alerts.alerts = [];
			});

			$rootScope.$on('loggedin', function (nothing, newAlert) {
				subscribeForAlerts();
			});

			$rootScope.$on('subscribed as admin', function (connectData) {
				subscribeForAlerts();
			});

			// save add alerts to prevent duplicates
			var addAlerts = function (alertsToAdd) {
				alertsToAdd = _.isArray(alertsToAdd) ? alertsToAdd : [alertsToAdd];
				alertsToAdd = _.map(alertsToAdd, Alerts.parseAlert);
				var alertsAddedCounter = 0;

				_.each(alertsToAdd, function (alertToAdd) {
					var isExistingAlert = _.some(Alerts.alerts, { uuid: alertToAdd.uuid });
					if (!isExistingAlert) {
						Alerts.alerts.unshift(alertToAdd);
						alertsAddedCounter++;
					}
				});

				Alerts.totalAlerts += alertsAddedCounter;
				notifyChanges();
			};

			var allAlertsPromise = null;
			var shouldLoadAllAlerts = false;

			Alerts.loadAll = function () {
				if (!Alerts.receivedApproval) {
					// prevent loading before login event
					shouldLoadAllAlerts = true;
					return $q.when([]);
				}

				if (!allAlertsPromise) {
					allAlertsPromise = Alert.all().$promise.then(function (result) {
						addAlerts(result.alerts);
						Alerts.totalAlerts = result.totalCount;
						return Alerts.alerts;
					});
				}

				return allAlertsPromise;
			};

			var latestAlertsPromise = null;
			var shouldLoadLatestAlerts = false;

			Alerts.loadLatest = function () {
				if (!Alerts.receivedApproval) {
					// prevent loading before login event
					shouldLoadLatestAlerts = true;
					return $q.when([]);
				}

				if (!latestAlertsPromise) {
					latestAlertsPromise = Alert.all({ limit: Alerts.latestLimit }).$promise.then(function (result) {
						addAlerts(result.alerts);
						Alerts.totalAlerts = result.totalCount;
						return Alerts.alerts;
					});
				}

				return latestAlertsPromise;
			};

			Alerts.getLatestAlerts = function () {
				return _.take(Alerts.alerts, Alerts.latestLimit);
			};

			return Alerts;
		}
	);
