'use strict';

angular.module('bringgApp').service('appReadyIndicator', function ($q, CrossApplicationService) {
	var _defer;
	var _this = this;

	_this.whenReady = whenReady;
	_this.init = init;

	function init() {
		_defer = $q.defer();
		CrossApplicationService.on('REACT_DONE_RENDERED', _defer.resolve);
	}

	function whenReady() {
		return _defer ? _defer.promise : $q.resolve();
	}
});
