'use strict';

angular
	.module('bringgApp')
	.controller(
		'reportsController',
		function (
			$scope,
			$rootScope,
			reportsSerivce,
			Employees,
			localStorageService,
			Merchant,
			Authentication,
			$location,
			$q,
			$timeout,
			ReportsService2,
			$log,
			TimeManagerService,
			Teams,
			Tags,
			bringg_utils,
			PRIVILEGES_TYPES
		) {
			$scope.getStatusFromTask = bringg_utils.getStatusFromTask;
			$scope.canAccessOnlyCompanyUsers =
				!Authentication.currentUser().admin &&
				Authentication.currentUser().has_access(
					PRIVILEGES_TYPES.ACCESS_USERS,
					PRIVILEGES_TYPES.ACCESS_COMPANY_USERS_ONLY
				);

			$scope.data = {
				selectedEmployees: [],
				selectedTeams: [],
				selectedTags: [],
				selectedReport: ''
			};

			$scope.reportInProcess = false;
			$scope.shortCode = $location.search().short_code;

			$scope.date_range = localStorageService.get('reports_date_range');
			$scope.availableReports = reportsSerivce.availableReports();
			$scope.scheduled_at = localStorageService.get('reports_scheduled_at');
			if (_.isUndefined($scope.scheduled_at) || _.isNull($scope.scheduled_at)) {
				$scope.scheduled_at =
					TimeManagerService.format(moment().subtract('days', 29), 'MM/DD/YYYY hh:mm a') +
					' - ' +
					TimeManagerService.format(moment(), 'MM/DD/YYYY hh:mm a');
			}

			var selectedReportId = $location.search().id;

			var onReportChange = function (newReport) {
				if (!newReport) {
					return;
				}

				$scope.showEmployeeSelect = _.find($scope._hideSelecteEmoloyeeReportsIds, function (item) {
					return item === newReport.id;
				});

				$scope.showTeamsSelect = _.find($scope._showSelecteTeamReportsIds, function (item) {
					return item === newReport.id;
				});

				$scope.showTagsSelect = _.find($scope._showSelecteTagReportsIds, function (item) {
					return item === newReport.id;
				});

				$scope.showExportToCSV = _.find($scope._hideExportToCSVReportsIds, function (item) {
					return item === newReport.id;
				});
			};

			var report;
			if (selectedReportId) {
				report = reportsSerivce.getReportDetails(selectedReportId);
			}

			$scope.data.selectedReport = report || localStorageService.get('selected_report');

			if (_.isUndefined($scope.data.selectedReport) || _.isNull($scope.data.selectedReport)) {
				$scope.data.selectedReport = '';
			}
			$scope.currentUser = Authentication.currentUser();

			$scope.$on('config updated', function () {
				Teams.all(function (teams) {
					$scope.teams = teams;
				});
			});

			$scope.handleSelectAllClick = function () {
				$scope.data.selectedEmployees = _.clone($scope.employees);
			};
			$scope.handleSelectAllTeamsClick = function () {
				$scope.data.selectedTeams = $scope.teams;
			};
			$scope.handleSelectAllTagsClick = function () {
				$scope.data.selectedTags = $scope.tags;
			};

			$scope.removeSelection = function () {
				$scope.data.selectedEmployees.length = 0;
			};
			$scope.removeTeamsSelection = function () {
				$scope.data.selectedTeams.length = 0;
			};
			$scope.removeTagsSelection = function () {
				$scope.data.selectedTags.length = 0;
			};

			$scope.setDaterangepicker = function () {
				const options = {
					ranges: {
						Today: [moment().startOf('day'), moment().endOf('day')],
						Yesterday: [
							moment().subtract(1, 'days').startOf('day'),
							moment().subtract('days', 1).endOf('day')
						],
						'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
						'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')]
					},
					startDate: moment().subtract(29, 'days').startOf('day'),
					endDate: moment(),
					maxDate: moment().endOf('day'),
					minDate: moment().subtract(29, 'days').startOf('day'),
					format: 'MM/DD/YYYY hh:mm a',
					timePicker: true,
					locale: {
						format: 'MM/DD/YYYY hh:mm a',
						separator: ' - ',
						applyLabel: 'Apply',
						cancelLabel: 'Cancel',
						weekLabel: 'W',
						customRangeLabel: 'Custom Range',
						daysOfWeek: moment.weekdaysMin(),
						monthNames: moment.monthsShort(),
						firstDay: moment.localeData().firstDayOfWeek()
					}
				};
				const callback = function (start, end, label) {
					let dateRange;
					switch (label) {
						case 'Today':
							dateRange = 1;
							break;
						case 'Yesterday':
							dateRange = 2;
							break;
						case 'Last 7 Days':
							dateRange = 3;
							break;
						case 'Last 30 Days':
							dateRange = 4;
							break;
						case 'This Month':
							dateRange = 5;
							break;
						case 'Last Month':
							dateRange = 6;
							break;
						default:
							dateRange = undefined;
							break;
					}

					$('#reportrange span').html(
						start.format('MM/DD/YYYY HH:MM a') + ' - ' + end.format('MM/DD/YYYY HH:MM a')
					);
					$('#reportrange').trigger('input');

					$scope.date_range = dateRange;
				};

				// BRNGG-21215 limit legacy reports to 30 days back
				const has30DaysLimit = [
					'times_per_waypoint',
					'all_orders',
					'customers_per_day',
					'cancelled_tasks',
					'delivery_orders_report'
				].some(report => report === $scope.data.selectedReport.id);
				if (!has30DaysLimit) {
					Object.assign(options.ranges, {
						'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
						'Last Month': [
							moment().subtract(1, 'month').startOf('month').startOf('day'),
							moment().subtract(1, 'month').endOf('month').endOf('day')
						]
					});

					options.minDate = moment().subtract(3, 'month').startOf('day');
				}

				// hard reset to 'Last 30 Days' from 'This Month' and 'Last Month'
				if (has30DaysLimit && ($scope.date_range == 5 || $scope.date_range == 6)) {
					const start = moment().subtract(29, 'days').startOf('day');
					const end = moment().endOf('day');

					$scope.scheduled_at = start.format('MM/DD/YYYY HH:MM a') + ' - ' + end.format('MM/DD/YYYY HH:MM a');
					callback(start, end, 'Last 30 Days');
				}

				$('#reportrange').daterangepicker(options, callback);
			};

			$scope.showEmployeeSelect = true;
			$scope.showTeamsSelect = false;
			$scope.showTagsSelect = false;
			$scope.showExportToCSV = false;
			$scope._hideSelecteEmoloyeeReportsIds = [
				'orders_by_tag',
				'orders_created_assigned_started',
				'daily_engagement_report',
				'cancelled_tasks'
			];
			$scope._showSelecteTeamReportsIds = [
				'tasks_per_shift',
				'done_vs_cancelled_per_team',
				'detailed_delivery_times_per_tag_per_team'
			];
			$scope._showSelecteTagReportsIds = ['detailed_delivery_times_per_tag_per_team'];
			$scope._hideExportToCSVReportsIds = ['daily_engagement_report', 'alerts_report'];
			$scope.setDaterangepicker();

			onReportChange($scope.data.selectedReport);
			$scope.$watch('data.selectedReport', function (newValue, oldValue) {
				if (!newValue || newValue === oldValue) {
					return;
				}
				onReportChange(newValue);
				$scope.setDaterangepicker();
			});

			$scope.$on('saved report received', function (event, savedReport) {
				$scope.data.selectedReport = _.find($scope.availableReports, { id: savedReport.report_id });
				$scope.data.selectedEmployees = _.filter($scope.employees, function (employee) {
					return _.includes(savedReport.employee_ids, employee.id);
				});

				$scope.data.selectedTeams = _.filter($scope.teams, function (team) {
					return _.includes(savedReport.team_ids, team.id);
				});
			});

			var convertDateRangeToString = function (dateRange) {
				var dateRangeToString = {
					1: 'Today',
					2: 'Yesterday',
					3: 'Last 7 Days',
					4: 'Last 30 Days',
					5: 'This Month',
					6: 'Last Month'
				};

				return dateRangeToString[dateRange];
			};

			/**
			 * on report ready
			 */
			$scope.$on('report ready', function (event, reportsLength) {
				var report = reportsSerivce.get(reportsLength);

				$timeout(function () {
					if (report.meta.startDate && report.meta.endDate) {
						var startDate = TimeManagerService.format(
							moment.unix(report.meta.startDate),
							'MM/DD/YYYY HH:mm a'
						);
						var endDate = TimeManagerService.format(moment.unix(report.meta.endDate), 'MM/DD/YYYY HH:mm a');
						report.meta.timePeriod = startDate + ' - ' + endDate;
					} else {
						report.meta.timePeriod = convertDateRangeToString(report.meta.date_range);
					}

					$scope.meta = report.meta;
					$scope.report = report.data;
					$scope.reportInProcess = false;
				});
			});

			$scope.generateReport = function (regenerateNew) {
				if (_.isUndefined(regenerateNew)) {
					regenerateNew = true;
				}
				if (regenerateNew) {
					$scope.shortCode = undefined;
				}

				$log.info('restoring filters using report id: ' + $scope.data.selectedReport);
				$log.info('searching report in reports list: ' + _.map($scope.availableReports, 'id'));

				var reports = _.filter($scope.availableReports, function (report) {
					return report.id === $scope.data.selectedReport.id;
				});

				if (!reports.length && !$scope.shortCode) {
					return;
				}
				$location.search('short_code', undefined);

				localStorageService.set('reports_date_range', $scope.date_range);
				localStorageService.set('reports_scheduled_at', $scope.scheduled_at);
				localStorageService.set('selected_report', $scope.data.selectedReport);

				var employee_ids = _.map($scope.data.selectedEmployees, 'id');
				var team_ids = _.map($scope.data.selectedTeams, 'id');

				var options = {
					reportsList: reports,
					timePeriod: $scope.scheduled_at,
					employee_ids: employee_ids,
					allEmployees: _.isEmpty(employee_ids),
					tags: $scope.data.selectedTags,
					// for all reports, when teams is empty take all teams, even if no teams ui displayed
					allTeams: _.isEmpty(team_ids),
					team_ids: null,
					dateRange: $scope.date_range,
					shortCode: $scope.shortCode
				};

				if ($scope.showTeamsSelect) {
					options.allTeams = _.isEmpty(team_ids);
					options.team_ids = team_ids;
				}

				$scope.reportInProcess = true;
				reportsSerivce.generateReport(options);
			};

			var postInit = function () {
				if (!_.isUndefined($scope.shortCode)) {
					$scope.generateReport(false);
				}
			};

			if ($scope.currentUser.partner_user) {
				Merchant.all(function (response) {
					if (response.success) {
						$scope.employees = response.merchants;
					}
					postInit();
				});
			} else {
				$q.all([
					Employees.fetchAllAdminsAndDispatchers(),
					Employees.allEmployees,
					Tags.all(),
					Teams.all()
				]).then(function (results) {
					$scope.employees = results[1];
					$scope.tags = results[2];
					$scope.teams = results[3];

					postInit();
				});
			}
		}
	);
