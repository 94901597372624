'use strict';

angular
	.module('bringgApp')
	.constant('HOUR_FORMAT', {
		Default: 0,
		'12_h': 1,
		'24_h': 2
	})
	.service('TimeManagerService', function (MerchantConfigurations, HOUR_FORMAT) {
		var TimeManagerService = {};

		var isDurationObject = function (date) {
			return date && date.constructor && date.constructor.name === 'Duration';
		};

		var isMomentObject = function (date) {
			return date instanceof moment || (date && date._isAMomentObject) || isDurationObject(date) === true
				? true
				: false;
		};

		var handleLT = function (format) {
			var newFormat = format;

			var isLTS = _.includes(newFormat, 'LTS');
			var isLS = _.includes(newFormat, 'LT');

			if (isLS && !isLTS) {
				newFormat = newFormat.replace('LT', 'H:mm A');
			}
			if (isLTS) {
				newFormat = newFormat.replace('LTS', 'H:mm:ss A');
			}

			return newFormat;
		};

		TimeManagerService.format = function (date, format, options) {
			options = _.defaults(options, {});

			if (MerchantConfigurations.hour_format === HOUR_FORMAT['24_h']) {
				format = handleLT(format);
				format = format.replace(/h/g, 'H').replace(/ A/g, '');
			}

			if (MerchantConfigurations.hour_format === HOUR_FORMAT['12_h']) {
				format = handleLT(format);
				format = format.replace(/H/g, 'h');
			}

			// if we receive moment object we don't want to recreate to not lose timezone
			return isMomentObject(date) ? date.format(format, options) : moment(date).format(format, options);
		};

		return TimeManagerService;
	});
