'use strict';

angular.module('bringgApp.directives').directive('alertsCounter', function () {
	return {
		restrict: 'E',
		scope: {
			count: '='
		},
		template:
			'<div class="alerts-counter"><span class="badge header-alerts-badge" ng-if="count">{{ count }}</span></div>'
	};
});
