'use strict';

angular
	.module('bringgApp')
	.controller('AlertsController', function ($scope, $rootScope, Alerts, Alert, Authentication) {
		Alerts.loadAll().then(function (alerts) {
			$scope.alerts = alerts;
		});

		$rootScope.$on('alerts update', function () {
			$scope.alerts = Alerts.alerts;
		});

		$scope.headers = [
			['type', 'Alert'],
			['user_name', 'Driver Name'],
			['task_name', 'Order Ids'],
			['customer_name', 'Customer Name'],
			['created_at', 'Created At'],
			['', 'Dismiss']
		];

		$scope.sort = {
			column: 'created_at',
			descending: true,
			index: 0
		};

		$scope.selectedCls = function (columnIdx) {
			if (columnIdx === $scope.sort.index) {
				if ($scope.sort.descending) {
					return '&#9660;';
				} else {
					return '&#9650;';
				}
			}
		};

		$scope.changeSorting = function (columnIdx) {
			var sort = $scope.sort;
			if (sort.column === $scope.headers[columnIdx][0]) {
				sort.descending = !sort.descending;
			} else {
				sort.column = $scope.headers[columnIdx][0];
				sort.descending = false;
				sort.index = columnIdx;
			}
		};

		$scope.dismiss = function (alertData) {
			Alert.dismiss({ uuid: alertData.uuid }, function (deletedAlert) {
				if (deletedAlert.success) {
					alert('Deleted successfully');
				} else {
					alert('Failed deleting alert: ' + deletedAlert.message);
				}
			});
		};

		$scope.handleRemoveAllAlertsClick = function () {
			Alerts.dismissAll().then(
				function (result) {
					toastr.success(result);
				},
				function (reason) {
					toastr.error(reason);
				}
			);
		};
	});
