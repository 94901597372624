'use strict';

angular.module('bringgApp.directives').directive('floatingPlaceholderInput', [
	'$log',
	function ($log) {
		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			link: function (scope, el) {
				var input = el.find('input');
				if (input.length === 0) {
					$log.warn('no input inside floating-placeholder directive');
					return false;
				}
				scope.shouldFloat = function (modelValue) {
					var domInputValue = input.val();
					if (domInputValue !== '' || modelValue) return true;
					return false;
				};
			},
			scope: {
				inputValue: '=',
				placeholder: '@',
				className: '=?'
			},
			templateUrl: 'scripts/directives/floating-placeholder-input/floating-placeholder-input.html'
		};
	}
]);
