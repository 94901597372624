'use strict';

angular.module('bringgApp').factory('AddVehicleService', function ($q, $uibModal) {
	var AddVehicleService = {};

	AddVehicleService.open = function (teamId) {
		var modalInstance = $uibModal.open({
			backdrop: 'static',
			keyboard: true,
			windowClass: 'add-vehicle-modal',
			controller: 'AddVehicleController',
			templateUrl: 'scripts/features/add_vehicle/add-vehicle.html',
			resolve: {
				teamId: function () {
					return teamId;
				}
			}
		}).result;

		return modalInstance;
	};
	return AddVehicleService;
});
