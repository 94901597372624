/**
 * Created by liorsion on 6/7/15.
 */
'use strict';
/*global google:false */

angular
	.module('bringgApp')
	.controller(
		'AddUserCSVController',
		function AddUserCSVController(
			$scope,
			$uibModalInstance,
			Tasks,
			FileUploader,
			WEB_API_URL,
			MerchantConfigurationService,
			Merchant,
			Authentication,
			localStorageService,
			toastr,
			$log
		) {
			$scope.data = {
				lastImportErrors: null,
				testData: null
			};

			$scope.csvUploader = new FileUploader({
				url: WEB_API_URL + '/users/import_from_csv',
				withCredentials: true,
				headers: {
					'x-ov-user-email': Authentication.currentUser().email,
					Authorization: localStorageService.get('auth_key')
				},
				autoUpload: false,
				queueLimit: 1
			});

			$scope.handleChooseFileClick = function () {
				setTimeout(function () {
					angular.element('#csvUploader').trigger('click');
				}, 0);
			};

			$scope.csvUploader.onErrorItem = function (item, response, status, headers) {
				$scope.csvUploader.isUploading = false;
				$log.log('=====================================');
				$log.log('AddUserCSVController: onErrorItem');
				$log.log(response);
				$log.log(status);
				$log.log(headers);
				$log.log('=====================================');
			};

			$scope.csvUploader.onProgressItem = function (item, progress) {
				$log.log('=====================================');
				$log.log('AddUserCSVController: onProgressItem');
				$log.log(item);
				$log.log(progress);
				$log.log('=====================================');
			};

			$scope.csvUploader.onSuccessItem = function (item, response, status, headers) {
				$scope.csvUploader.isUploading = false;
				$log.log('=====================================');
				$log.log('AddTasksCSVController: onSuccessItem');
				$log.log(response);
				$log.log(status);
				$log.log(headers);
				$log.log('=====================================');
			};

			$scope.csvUploader.onCompleteAll = function () {
				$log.log('=====================================');
				$log.log('AddUserCSVController: onCompleteAll');
				$log.log('=====================================');
			};

			$scope.csvUploader.onCompleteItem = function (requestData, response) {
				if (response && response.success) {
					toastr.success('CSV of users updated, thank you!');
					if (requestData.url.indexOf('test_import_one_line_from_csv') !== -1) {
						$scope.data.testData = response.data;
						$scope.csvUploader.queue[0].isSuccess = false;
						$scope.csvUploader.queue[0].isUploaded = false;
						$scope.csvUploader.queue[0].progress = 0;
					} else {
						$scope.data.lastImportErrors = response.errors;
						$scope.data.failedLines = response.failed_importing;
						$scope.data.successLines = response.imported_orders;
						$scope.data.success = true;
					}
					$scope.$apply();
				} else {
					if (response && response.errors) {
						$scope.data.lastImportErrors = response.errors;
					}
					toastr.error('User update failed!');
					$scope.data.success = false;
				}
			};

			$scope.uploadFile = function () {
				if (!$scope.csvUploader.queue[0]) {
					toastr.error('Please add a CSV file to import');
					return;
				}

				$scope.data.lastImportErrors = null;
				$scope.data.testData = null;

				$scope.csvUploader.queue[0].removeAfterUpload = true;
				$scope.csvUploader.queue[0].url = WEB_API_URL + '/users/import_from_csv';
				$scope.csvUploader.uploadItem($scope.csvUploader.queue[0]);
			};

			$scope.testUploadFile = function () {
				if (!$scope.csvUploader.queue[0]) {
					toastr.error('Please add a CSV file to import');
					return;
				}

				$scope.data.lastImportErrors = null;
				$scope.data.testData = null;
				$scope.csvUploader.queue[0].url = WEB_API_URL + '/users/test_import_one_line_from_csv';
				$scope.csvUploader.uploadItem($scope.csvUploader.queue[0]);
			};

			$scope.close = function () {
				$uibModalInstance.close();
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (isLoggedIn) {
					MerchantConfigurationService.getCSVFields().then(function (csvUploadFields) {
						$scope.data.CSVFields = csvUploadFields;
						$scope.loading = false;
					});
					Merchant.get(function (data) {
						$scope.localTimezone = data.time_zone;
					});
				}
			});
		}
	);
