'use strict';

angular
	.module('bringgApp')
	.factory('MerchantScheduledBreaksResource', function ($resource, WEB_API_URL) {
		var MerchantScheduledBreaksResource = $resource(
			WEB_API_URL + '/merchant/:id/merchant_scheduled_breaks/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/merchant/:id/merchant_scheduled_breaks/' }
			}
		);

		return MerchantScheduledBreaksResource;
	})
	.factory('MerchantScheduledBreaksService', function (MerchantScheduledBreaksResource, Authentication, $q) {
		var MerchantScheduledBreaksService = {};

		MerchantScheduledBreaksService.get = function () {
			return MerchantScheduledBreaksResource.get({ id: Authentication.currentUser().merchant_id }).$promise;
		};

		MerchantScheduledBreaksService.update = function (configuration) {
			var newConfig = new MerchantScheduledBreaksResource({
				id: Authentication.currentUser().merchant_id,
				configuration: configuration
			});
			return newConfig.$update();
		};

		return MerchantScheduledBreaksService;
	});
