'use strict';

angular.module('bringgApp').directive('bringgplusTaskInvoice', function ($timeout) {
	return {
		restrict: 'E',
		scope: {
			tasks: '=',
			config: '=',
			printedCopies: '=',
			printCallback: '='
		},
		link: function (scope, element) {
			scope.pages = [];
			scope.numberPerPage = 30;

			function toMatrix(arr, width) {
				return arr.reduce(function (rows, key, index) {
					return (index % width == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
				}, []);
			}

			_.each(scope.tasks, function (task) {
				scope.pages[task.id] = toMatrix(task.task_inventories, scope.numberPerPage);
			});

			$timeout(scope.printCallback, 100, true, element);
		},
		templateUrl: 'scripts/features/print/invoices/bringg-plus-task-invoice.html'
	};
});
