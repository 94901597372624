'use strict';

angular.module('bringgApp').service('dispatchMapIconsService', function (MerchantConfigurations, localStorageService) {
	var statusColors = {
		free: '#76c258',
		assigned: '#76c258',
		accepted: '#76c258',
		'checked-in': '#658cd2',
		started: '#658cd2',
		late: '#e65963',
		offline: '#ccc',
		unavailable: '#ff8715'
	};
	var defaultStatusColor = '#76c258';
	var markerWidth = 12;
	var markerHeight = 3 * markerWidth;
	var markerTipWidth = 1;
	var markerPath = [
		'M',
		0,
		markerHeight,
		'c',
		0,
		(-2 * markerHeight) / 3,
		markerWidth,
		(-2 * markerHeight) / 3,
		markerWidth,
		-markerHeight,
		'c',
		0,
		(-4 * markerWidth) / 3,
		-2 * markerWidth - markerTipWidth,
		(-4 * markerWidth) / 3,
		-2 * markerWidth - markerTipWidth,
		0,
		'c',
		0,
		markerHeight / 3,
		markerWidth,
		markerHeight / 3,
		markerWidth,
		markerHeight,
		'z'
	].join(' ');
	var self = {
		colors: [
			'#3cb247',
			'#e99b6f',
			'#d3bf53',
			'#35cf70',
			'#bc428e',
			'#5c7cb9',
			'#41a561',
			'#d9422f',
			'#d83145',
			'#41a889',
			'#326a38',
			'#b1699c',
			'#a089e7',
			'#6786e2',
			'#4883ef',
			'#805f27',
			'#78aa1f',
			'#714d94',
			'#a52d68',
			'#f08db5',
			'#bea5ea',
			'#3295e9',
			'#8c9252',
			'#6ece5c',
			'#44b6b7',
			'#c18e22',
			'#ddb026',
			'#e26cb2',
			'#d6216b',
			'#d3ad72',
			'#b6c142',
			'#853dab',
			'#b4bd78',
			'#ac379f',
			'#829fdd',
			'#416098',
			'#e03fa6',
			'#ec6568',
			'#5370ed',
			'#bf6acd',
			'#3ecd8f',
			'#42ceaf',
			'#ac3a18',
			'#f64085',
			'#bdbc21',
			'#d795b8',
			'#b93d3f',
			'#576524',
			'#ca6b5d',
			'#eb9519',
			'#6ac884',
			'#947422',
			'#a05db0',
			'#8b73b9',
			'#ba5f2c',
			'#d02f56',
			'#8d4a78',
			'#6f8623',
			'#4e59bd',
			'#e78e8e',
			'#8e60da',
			'#c6607b',
			'#9b573e',
			'#d191cd',
			'#bb9845',
			'#a2c635',
			'#b07947',
			'#dc8de7',
			'#517f33',
			'#e5682b',
			'#348ebf',
			'#a3394a',
			'#435ca6',
			'#ea6a8c',
			'#389225',
			'#b39420',
			'#7a781f',
			'#974d5e',
			'#2baad3',
			'#b8721d',
			'#d56ae0',
			'#4fd256',
			'#79ac3d',
			'#d8487a',
			'#61b928',
			'#f0b255',
			'#2a775f',
			'#65a64b',
			'#a2b356',
			'#974327',
			'#88b86c',
			'#a3a02f',
			'#3e8c5e',
			'#3dd0e2',
			'#e28c3b',
			'#91cf65',
			'#7ec095',
			'#f27c5e',
			'#2d7a2c'
		],
		taskIconsBase: localStorageService.get('dipatch-map-icons-base') || null,
		taskSVGPath:
			'M 56.525 24.533 c 0 -13.548 -10.982 -24.528 -24.529 -24.528 c -13.356 0 -24.2 10.683 -24.501 23.968 c -0.009 -0.004 -0.02 -0.004 -0.028 -0.004 c -0.006 0.12 0.013 0.241 0.01 0.362 c -0.001 0.067 -0.01 0.135 -0.01 0.202 c 0 0.244 0.03 0.479 0.037 0.72 C 7.78 41.191 25.795 58.009 31.996 63.995 c 17.415 -14.119 22.485 -27.216 23.95 -34.199 c 0.053 -0.245 0.1 -0.489 0.145 -0.734 c 0.141 -0.752 0.239 -1.426 0.307 -2.004 c 0 -0.007 0.002 -0.014 0.002 -0.021 C 56.583 25.423 56.525 24.533 56.525 24.533 Z'
	};

	/**
	 * @param map
	 * @param lat
	 * @param lng
	 * @param label
	 * @param icon
	 * @param id
	 * @param markerClicked
	 * @returns {Window.google.maps.Marker}
	 */
	self.createMarker = function (map, lat, lng, label, icon, id, markerClicked, visible) {
		var newMarker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
			map: map,
			icon: icon,
			label: label,
			optimized: true
		});

		if (id) {
			newMarker.set('id', id);
		}
		newMarker.set('visible', visible);

		if (markerClicked) {
			var listenrId = google.maps.event.addListener(newMarker, 'click', function () {
				markerClicked(newMarker);
			});
			newMarker.set('listener_id', listenrId);
		}

		google.maps.event.addListener(newMarker, 'dblclick', function () {
			map.setZoom(17);
			map.panTo(newMarker.getPosition());
		});
		return newMarker;
	};

	self.getTaskIconByNumber = function (number) {
		var color = self.colors[number % self.colors.length];

		// default value for empty stuff
		if (!number) {
			color = 'black';
		}

		return {
			path: self.taskSVGPath,
			strokeWeight: 1,
			anchor: new google.maps.Point(30, 70),
			scaledSize: new google.maps.Size(49, 64),
			scale: 0.6,
			fillColor: color,
			fillOpacity: 1,
			strokeColor: color
		};
	};

	self.setTaskIconsBase = function (taskIconsBase) {
		self.taskIconsBase = taskIconsBase;
		localStorageService.set('dipatch-map-icons-base', taskIconsBase);
	};

	self.getTaskIconByOrderStatus = function (status) {
		return self.getTaskIconByNumber(status);
	};

	self.getTaskIconByDriver = function (task) {
		return self.getTaskIconByNumber(task.user_id);
	};

	self.getTaskIconByTag = function (tagId) {
		return self.getTaskIconByNumber(tagId);
	};

	self.getTaskIconByTeam = function (teamId) {
		return self.getTaskIconByNumber(teamId);
	};

	self.getTaskIconByStopNumber = function (number) {
		return self.getTaskIconByNumber(number);
	};

	/**
	 * return task icon
	 * @returns {string}
	 */
	self.getTaskIcon = function (task) {
		var destinationMarker = '/images/pin_destination.png';
		if (!_.isUndefined(MerchantConfigurations.destinationMarkerImage)) {
			destinationMarker = MerchantConfigurations.destinationMarkerImage;
		}

		switch (self.taskIconsBase) {
			case 'order_status':
				destinationMarker = self.getTaskIconByOrderStatus(task.status);
				break;
			case 'driver':
				destinationMarker = self.getTaskIconByDriver(task);
				break;
			case 'tag':
				destinationMarker = self.getTaskIconByTag(task.tag_id);
				break;
			case 'team':
				destinationMarker = self.getTaskIconByTeam(task.team_id);
				break;
			case 'stop_number':
				var activeWayPointPosition = task.activeWayPoint && task.activeWayPoint.position;
				destinationMarker = self.getTaskIconByStopNumber(activeWayPointPosition || 1);
			default:
		}

		return destinationMarker;
	};

	function getDriverIconColor(driver) {
		var status = driver.status;

		if (driver.status == 'online') {
			if (driver.current_unavailability_id) {
				status = 'unavailable';
			} else if (driver.sub) {
				status = driver.sub.toLowerCase();
			}
		}

		return statusColors[status] || defaultStatusColor;
	}

	self.driverIconNeedsRefresh = function (driver, icon) {
		if (!icon) {
			return true;
		}
		return icon.fillColor !== getDriverIconColor(driver);
	};

	/**
	 * return driver icon
	 */
	self.getDriverIcon = function (driver) {
		var iconColor = getDriverIconColor(driver);
		return {
			path: markerPath,
			fillColor: iconColor,
			fillOpacity: 0.8,
			anchor: new google.maps.Point(0, markerHeight),
			strokeWeight: 2,
			strokeColor: '#444444',
			strokeOpacity: 0.6,
			scale: 1
		};
	};

	/**
	 * return task icon
	 *   wait for team icon design..
	 */
	self.getTeamIcon = function (team) {
		return '/images/base_icon.png';
	};

	return self;
});
