'use strict';
/*global $:false */
/*global google:false */
/*global find_in_array:false */

angular
	.module('bringgApp')
	.controller(
		'MapsController',
		function (
			$scope,
			$location,
			Announcement,
			Tasks,
			$rootScope,
			MerchantConfigurations,
			$routeParams,
			Employees,
			EmployeesTasks,
			$timeout,
			Employee,
			localStorageService,
			toastr,
			Authentication,
			$translate,
			$sanitize,
			$window,
			$q,
			DEFAULT_RT_THROTTLE,
			Tags,
			SkillsService,
			CrossApplicationService,
			CustomAttributesService,
			ManagedAttributesService,
			Task,
			TasksData,
			PresetViewsService,
			CROSS_APP_ACTIONS,
			ObjectUtilsService
		) {
			localStorageService.set('last_known_location', 'map');

			var event_triggerring = false;

			$scope.displayDriversFilter = undefined;
			$scope.tags = Tags.all();
			$scope.beta = Authentication.currentUser().beta;
			$scope.enableTeams = MerchantConfigurations.enable_teams;
			$scope.markHomeEnabled = false;
			$scope.cashOutFlowEnabled = false;
			$scope.onlineFilter = Employees.filterIsOnline;
			$scope.offlineFilter = Employees.filterIsOffline;
			$scope.filterObject = {};
			$scope.grid = {};
			$scope.gaPage = 'DispatchView';
			$scope.mapObject = null;
			$scope.filteredTasks = { values: [] };
			$scope.employeeFiteredTasks = { values: [] };
			$scope.selectedTeamsIds = [];
			$scope.employeeNameQuery = '';
			$scope.allowSearchingEmployeesInMapTab = false;

			$scope.$on('map_initiated', function (event, map) {
				$scope.mapObject = map;
				event.stopPropagation();
			});

			$scope.$on('center_map', function (event, selectedEmployee) {
				center_map($scope.mapObject, selectedEmployee);
				event.stopPropagation();
			});

			$scope.teamNameFormat = function (team) {
				return $sanitize(team.name);
			};

			$scope._offlineAndOnShitEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'offline' && Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._offShiftEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return !Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._onShiftEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._onlineEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'online';
				}
				return false;
			};

			$scope.teamFilter = function (employee) {
				if (_.isEmpty($scope.selectedTeamsIds)) {
					return true;
				}
				if (employee && employee.team_ids && employee.team_ids.length > 0) {
					return !!_.intersection(employee.team_ids, $scope.selectedTeamsIds).length;
				}
				return false;
			};

			$scope.taskTeamFilter = function (task) {
				if (_.isEmpty($scope.selectedTeamsIds)) {
					return true;
				}
				if (task && task.team_ids && task.team_ids.length > 0) {
					return !!_.intersection(task.team_ids, $scope.selectedTeamsIds).length;
				}
				return false;
			};

			$scope.filterEmployees = function () {
				refreshDisplayedEmployees();

				if ($scope.employeeNameQuery.trim().toLowerCase() === '') {
					return;
				}

				if ($scope.displayedEmployees.filtered.length !== 1) {
					return;
				}

				const employeeToSelect = $scope.displayedEmployees.filtered[0];

				$scope.pickEmployee(
					// If only have one employee then select it and focus it in the map
					employeeToSelect,

					// Don't update the url to avoid flickering (it's not nice to see re-rendering while typing
					false
				);

				$scope.$broadcast('autoLocateSelectedEmployee');
				$scope.$emit('autoLocateSelectedEmployee');
			};

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.markHomeEnabled =
					featureFlags.markHomeFromMapEnabled ||
					featureFlags.feature_flag_only_dashboard_home_events ||
					false;
				$scope.displayBeaconAtHomeIcons = featureFlags.display_beacon_at_home_icons || false;
				$scope.feature_flag_only_dashboard_home_events = featureFlags.feature_flag_only_dashboard_home_events;
				$scope.showDriverRoleInDispatch = featureFlags.show_driver_role_in_dispatch || false;
				$scope.displayVRPADAnalytics = featureFlags.display_vrp_auto_dispatch_analytics || false;
				$scope.allowSearchingEmployeesInMapTab = featureFlags.allow_searching_employees_in_map_tab || false;
			});

			$scope.driverLeftHome = function (driver) {
				var driverId = driver.id;
				if (_.isUndefined(driverId)) {
					return;
				}
				driver.sending_home_event = true;
				Employee.leftHome(
					{ user_id: driverId, id: driverId },
					function (data, status, headers, config) {
						driver.sending_home_event = false;
						if (data.success) {
							driver.at_home = false;
							toastr.success(data.message);
						} else {
							driver.at_home = true;
							toastr.error(data.message);
						}
					},
					function (data, status, headers, config) {
						driver.at_home = true;
						driver.sending_home_event = false;
						if (data.message) {
							toastr.error('Could not leave home:', data.message);
						} else {
							toastr.error('Could not leave home');
						}
					}
				);
			};

			$scope.driverGotHome = function (driver) {
				var driverId = driver.id;
				driver.sending_home_event = true;
				Employee.gotHome(
					{ user_id: driverId, id: driverId },
					function (data, status, headers, config) {
						driver.sending_home_event = false;
						if (data.success) {
							driver.at_home = true;
							toastr.success(data.message);
						} else {
							driver.at_home = false;
							toastr.error(data.message);
						}
					},
					function (data) {
						driver.sending_home_event = false;
						driver.at_home = false;
						if (data.message) {
							toastr.error('Could not arrive home: ', data.message);
						} else {
							toastr.error('Could not arrive home');
						}
					}
				);
			};
			$scope.startShift = function (driver) {
				if (_.isNull(driver)) {
					return;
				}
				Employee.startShift({ id: driver.id }, function (data, status, headers, config) {
					if (data.success) {
						driver.active_shift_id = data.shift.id;
						toastr.success('Shift started successfully');
					} else {
						if (data.message) {
							toastr.error('Shift could not be started: ', data.message);
						} else {
							toastr.error('Shift could not be started');
						}
					}
				});
			};

			$scope.endShift = function (driver) {
				if (_.isNull(driver)) {
					return;
				}
				Employee.endShift({ id: driver.id }, function (data, status, headers, config) {
					if (data.success) {
						driver.active_shift_id = null;
						toastr.success('Shift ended successfully');
					} else {
						if (data.message) {
							toastr.error('Shift could not be ended: ', data.message);
						} else {
							toastr.error('Shift could not be ended');
						}
					}
				});
			};

			$scope.unpickEmployee = function () {
				$location.search({});
				$scope.$broadcast('employeeUnselected', null);
				$scope.$emit('employeeUnselected', null);
				$scope.selectedEmployee = null;
				event_triggerring = true;
				setAutomaticResize(true);
				event_triggerring = false;
				$timeout($scope.grid.resizeCanvas);
			};

			var refreshFiltersOnFiltersChange = function (event, removeMissingKeysFromPrevFilters) {
				if (removeMissingKeysFromPrevFilters) {
					ObjectUtilsService.deleteMissingKeys($scope.filterObject, event);
				}

				Object.assign($scope.filterObject, event);
				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}
			};

			CrossApplicationService.on('FILTER_CHANGE', refreshFiltersOnFiltersChange);

			// cleaner
			$scope.$on('$destroy', function () {
				CrossApplicationService.off('FILTER_CHANGE', refreshFiltersOnFiltersChange);
			});

			$scope.handleTeamSelected = function (selectedTeam, model, wayPoint) {
				$scope.selectedTeamsIds = [selectedTeam.id];

				//when selecting all teams the value is -1 but needs to be undefined in order for the filter to show all tasks
				$scope.filterObject.selectedTeams = selectedTeam.id === -1 ? [] : $scope.selectedTeamsIds;
				$scope.grid.refreshFilters();

				$scope.unpickEmployee();
				$timeout($scope.grid.resizeCanvas);
				$timeout($scope.grid.resizeCanvas);
				refreshDisplayedEmployees();
			};

			function closeSelectEmployee(task) {
				$('#s2id_list_task_' + task.id + '_employee_list').hide();
				$('#list_task_' + task.id + '_employee').show();
			}

			$scope.assignEmployee = function (task, employee_id) {
				return Tasks.assignTask(task, employee_id).then(
					function (result) {
						if (task.user_id) {
							toastr.success('Task assigned.');
						} else {
							toastr.success('Task unassigned.');
						}
					},
					function (reason) {
						toastr.error(reason);
					}
				);
			};

			$scope.selectEmployee = function (task) {
				if ($scope.readonly) {
					return;
				}
				$('#list_task_' + task.id + '_employee').hide();
				var existingSelection = $('#s2id_list_task_' + task.id + '_employee_list');
				if (existingSelection.length !== 0) {
					// isEmpty doesn't work here
					existingSelection.show();
					existingSelection.select2('open');
					return;
				}
				$('#list_task_' + task.id + '_employee_list')
					.show()
					.select2({
						data: {
							results: EmployeesTasks.allEmployeesWithUnassigned($scope.employees, task.team_ids, true),
							text: 'name'
						},
						formatResult: function (employee) {
							return EmployeesTasks.formatEmployeeSelection(employee, task.teams_ids);
						},
						formatSelection: function (employee) {
							return EmployeesTasks.formatEmployeeSelection(employee, task.teams_ids);
						},
						dropdownAutoWidth: true,
						//        query: function(query){ query.callback(); }
						sortResults: EmployeesTasks.optimizedEmployeeListForTask
					})
					.on('change', function (e) {
						closeSelectEmployee(task);
						$scope.assignEmployee(task, e.val).then(function () {
							$(this).select2('refresh');
						});
					})
					.on('select2-close', function () {
						closeSelectEmployee(task);
					})
					.select2('open');
			};

			/**
			 * handle employees updates
			 */

			$scope.displayedEmployees = {
				all: [],
				filtered: [],
				onlineCount: 0,
				onShiftAndOfflineCount: 0,
				onShiftCount: 0,
				offShiftCount: 0
			};

			$scope.filterByTeams = function (employees) {
				return _.filter(employees, function (employee) {
					return !_.isEmpty(_.intersection(employee.team_ids, $scope.filterObject.selectedTeams));
				});
			};

			$scope.getDisplayTeams = function () {
				return $scope.filterObject.selectedTeams && $scope.filterObject.selectedTeams.length
					? _.filter($scope.teams, function (team) {
							return _.includes($scope.filterObject.selectedTeams, team.id);
					  })
					: $scope.teams;
			};

			var refreshDisplayedEmployees = function () {
				if (!$scope.employees) {
					return;
				}

				var displayedEmployees = $scope.employees;
				$scope.selectedTeamsIds = $scope.filterObject.selectedTeams;
				if (
					$scope.emptyFilteringEnabled ||
					($scope.filterObject.selectedTeams && $scope.filterObject.selectedTeams.length)
				) {
					displayedEmployees = $scope.filterByTeams(displayedEmployees);
				}
				// counters
				var onOnlineEmployees = _.filter(displayedEmployees, $scope.onlineFilter);
				var onShiftEmployees = _.filter(displayedEmployees, $scope._onShiftEmployeeFilter);
				var onShiftAndOfflineEmployees = _.filter(onShiftEmployees, $scope.offlineFilter);
				var offShiftEmployees = _.filter(displayedEmployees, $scope._offShiftEmployeeFilter);

				if ($scope.displayDriversFilter) {
					displayedEmployees = _.filter(displayedEmployees, $scope.displayDriversFilter);
				}

				const driverQuery = $scope.employeeNameQuery.trim().toLowerCase();

				$scope.displayedEmployees = {
					all: displayedEmployees,
					filtered:
						driverQuery === ''
							? displayedEmployees
							: displayedEmployees.filter(item => item.name.toLowerCase().includes(driverQuery)),
					onlineCount: onOnlineEmployees.length,
					onShiftAndOfflineCount: onShiftAndOfflineEmployees.length,
					onShiftCount: onShiftEmployees.length,
					offShiftCount: offShiftEmployees.length
				};
			};

			$scope.$on(
				'employees list update',
				_.throttle(function () {
					Employees.drivers({}, function (result) {
						$scope.employees = result;
						refreshDisplayedEmployees();
					});
				}, DEFAULT_RT_THROTTLE)
			);

			/**
			 *  end of handle employees updates
			 */

			$scope.sort = {
				column: 'id',
				descending: true,
				index: 0
			};

			$scope.selectedCls = function (columnIdx) {
				if (columnIdx === $scope.sort.index) {
					if ($scope.sort.descending) {
						return '&#9660;';
					} else {
						return '&#9650;';
					}
				}
			};

			$scope.$watch(Authentication.isLoggedIn, function () {
				if ($rootScope.canLogin) {
					if ($routeParams.employee) {
						var routeEmployeeId = parseInt($routeParams.employee, 10);

						var oneTimeEmployeesWatch = $scope.$watch('employees', function () {
							if (_.isUndefined($scope.employees)) {
								return;
							}
							oneTimeEmployeesWatch();
							Employees.drivers({}, function (employees) {
								refreshDisplayedEmployees();
								employees.forEach(function (employee) {
									if (employee.id === routeEmployeeId) {
										$scope.pickEmployee(employee);
										return;
									}
								});
							});
						});

						$scope.changeEmployeesFilter('on_shift');
					} else {
						$scope.unpickEmployee();
					}

					$scope.merchantConfiguration = MerchantConfigurations;

					$scope.$on('config updated', function () {
						$scope.merchantConfiguration = MerchantConfigurations;
						$scope.enableTeams = MerchantConfigurations.enable_teams;
					});
				}
			});

			$scope.getCurrentlySelectedTasks = function () {
				var selectedTasks = [];
				if ($scope.team_tasks) {
					$scope.team_tasks.forEach(function (teamTasks) {
						selectedTasks = selectedTasks.concat(_.filter(teamTasks, $scope.taskTeamFilter));
					});
				}
				return selectedTasks;
			};

			$scope.user_status = ['Free', 'Assigned', 'Started', 'Checked-In', 'Late'];

			$scope.markers = {};

			$scope.orderProp = 'name';
			$scope.statuses = ['all', 'online', 'offline'];

			var resizeMapAndCanvas = function () {
				if (_.isFunction($scope.grid.resizeCanvas)) {
					$scope.grid.resizeCanvas();
				}
				var gridHeight = $('#table_container').height();
				var pageHeight = $('#dispatch-page').height();
				var result = pageHeight - gridHeight - 170;
				$('#map_resizeable').height(result);
				$('#map_canvas').height(result);
			};
			// resize grid canvas when height has changed
			$scope.$on('angular-resizable.resizing', function (event, args) {
				if (args.height) {
					resizeMapAndCanvas();
				}
			});

			// resize on window resize
			angular.element($window).on('resize', resizeMapAndCanvas);

			$scope.init_map = function () {
				$('#map_canvas').show();
				resizeMapAndCanvas();
			};

			/**
			 *
			 * @param employee employee to pick
			 * @param updateUrl whether to update the url with the selected employee
			 *                  this can cause flickering
			 */
			$scope.pickEmployee = function (employee, updateUrl = true) {
				event_triggerring = true;
				if (updateUrl) {
					$location.search({ employee: employee.id });
				}
				$scope.selectedEmployee = employee;
				$scope.$broadcast('employeeSelected', employee);
				$scope.$emit('employeeSelected', employee);
				event_triggerring = false;
			};

			$scope.employeeFilterTypes = {
				all: { filter: null, label: $translate.instant('MAP.ALL_DRIVERS') },
				online: { filter: $scope._onlineEmployeeFilter, label: $translate.instant('MAP.ONLINE') },
				on_shift: { filter: $scope._onShiftEmployeeFilter, label: $translate.instant('MAP.ON_SHIFT') },
				on_shift_offline: {
					filter: $scope._offlineAndOnShitEmployeeFilter,
					label: $translate.instant('MAP.ON_SHIFT_AND_OFFLINE')
				},
				off_shift: { filter: $scope._offShiftEmployeeFilter, label: $translate.instant('MAP.OFF_SHIFT') }
			};

			$scope.driverListFilterByOnShift = function () {
				return (
					$scope.displayDriversFilterLabel === $scope.employeeFilterTypes['on_shift_offline'].label ||
					$scope.displayDriversFilterLabel === $scope.employeeFilterTypes['on_shift'].label
				);
			};

			$scope.changeEmployeesFilter = function (employeeFilterType) {
				var filter = $scope.employeeFilterTypes.all.filter,
					label = $scope.employeeFilterTypes.all.label;

				if (!_.isUndefined($scope.employeeFilterTypes[employeeFilterType])) {
					filter = $scope.employeeFilterTypes[employeeFilterType].filter;
					label = $scope.employeeFilterTypes[employeeFilterType].label;
				}

				$scope.displayDriversFilter = filter;
				$scope.displayDriversFilterLabel = label;
				localStorageService.set('displayDriversFilterLabel', employeeFilterType);
				$scope.unpickEmployee();
				refreshDisplayedEmployees();
			};

			var preSetFilter = localStorageService.get('displayDriversFilterLabel');
			if (!_.isUndefined(preSetFilter) && !_.isNull(preSetFilter)) {
				$scope.changeEmployeesFilter(preSetFilter);
			} else {
				$scope.changeEmployeesFilter('on_shift');
			}

			$scope.sendAnnouncement = function (user_id, announcement_text) {
				var announcement = new Announcement({ user_id: user_id, message: announcement_text });
				announcement.$create_for_employee();
			};

			$scope.manualMapChange = function () {
				if (event_triggerring) {
					return;
				}
				setAutomaticResize(false);
			};

			$scope.onRowSelect = function () {
				var selectedItem = _.first($scope.selectedItems);
				$scope.$broadcast('dispatchMapTaskSelected', selectedItem);
			};

			$scope.onGridInited = function () {
				$scope.$on(
					'task list update',
					_.throttle(function () {
						const newDataFunc = Task.shouldUseNewApi() && TasksData.getOpenTasks;
						$scope.grid.refreshData(newDataFunc);
					}, DEFAULT_RT_THROTTLE)
				);
				$scope.grid.refreshFilters();
			};

			$scope.init = function () {
				var featureFlagsPromise = Authentication.featureFlags();
				var promises = [
					Employees.drivers(),
					featureFlagsPromise,
					SkillsService.getAllSkills(),
					ManagedAttributesService.loadAttributes()
				];

				featureFlagsPromise.then(function () {
					const featurePromises = [];
					const presetEnabled = PresetViewsService.enabledForConfigKey('map-task-list');

					if (CustomAttributesService.attributesEnabled) {
						featurePromises.push(CustomAttributesService.getAll());
					}
					if (presetEnabled) {
						featurePromises.push(PresetViewsService.getPresetsReadyPromise());
					}

					if (featurePromises.length) {
						return Promise.allSettled(featurePromises).then(function () {
							presetEnabled ? $scope.updateViewWithPresetConfig() : $scope.$broadcast('columns updated');
						});
					}
				});

				$q.all(promises).then(function (values) {
					$scope.employees = values[0];
					refreshDisplayedEmployees();

					$scope.emptyFilteringEnabled = values[1].empty_filtering;
					SkillsService.setSkillsById(values[2]);

					$scope.inited = true;
				});
			};

			$scope.updateViewWithPresetConfig = function () {
				const preset = PresetViewsService.getPreset('map-task-list');
				if (preset) {
					refreshFiltersOnFiltersChange(preset.filters, true);
					$scope.$broadcast('columns updated');
				}
			};

			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope.updateViewWithPresetConfig);

			// cleaner
			$scope.$on('$destroy', function () {
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope.updateViewWithPresetConfig);
			});

			$scope.init();
		}
	);
