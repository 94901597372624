/**
 * Created by liorsion on 8/18/15.
 */
'use strict';

angular
	.module('bringgApp')
	.factory('Origin', function ($resource, WEB_API_URL) {
		var Origin = $resource(
			WEB_API_URL + '/origins/:id',
			{ id: '@id' },
			{
				all: { method: 'GET', isArray: true, url: WEB_API_URL + '/origins/' },
				clients: { method: 'GET', isArray: true, url: WEB_API_URL + '/origins/clients' }
			}
		);

		return Origin;
	})
	.factory('Origins', function ($rootScope, Origin) {
		var Origins = { origins: [] };

		$rootScope.$on('loggedout', function (nothing, newAlert) {
			Origins.origins = null;
		});

		$rootScope.$on('loggedin', function (nothing, newAlert) {
			Origins._initTeams();
		});

		Origins._initTeams = function () {
			Origins.origins = Origin.all();
		};

		Origins._initTeams();

		Origins.all = function (cb) {
			if (Origins.origins.$resolved) {
				if (cb) {
					cb(Origins.origins);
				}
				return Origins.origins;
			} else {
				var _self = this;
				Origins.origins.$promise.then(function () {
					return _self.all(cb);
				});
				return Origins.origins;
			}
		};

		return Origins;
	});
