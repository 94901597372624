'use strict';

angular.module('bringgApp').factory('autobind', function () {
	return function (obj, context) {
		context = context || obj;
		_.each(Object.keys(obj), function (v) {
			if (typeof obj[v] === 'function') {
				obj[v] = obj[v].bind(context);
			}
		});
		return obj;
	};
});
