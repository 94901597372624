'use strict';

angular
	.module('bringgApp')
	.constant('PAGE_FIELDS', {
		PLANNING_FIELDS: 'planning_fields',
		DISPATCH_FIELDS: 'task_fields'
	})
	.factory(
		'GridDefaultColumnsService',
		function (
			Authentication,
			MerchantConfigurations,
			Employees,
			$filter,
			Fleets,
			AutoDispatchAnalytic,
			TimeManagerService,
			TranslationService,
			Tasks,
			TasksData,
			WayPointsService,
			PAGE_FIELDS,
			RunsService,
			CustomAttributesService,
			ManagedAttributesService,
			LoadConstraintsService,
			ServiceAreasService,
			CollapsedLinkedTasksService,
			PhoneNumberService
		) {
			/**
			 * new code to get defaults columns - right now used only by dispatcher
			 */
			function getDefaultsColumns(columnsKey) {
				var currentUser = Authentication.currentUser();
				// default values
				var defaults = [
					{
						id: 'id',
						field: 'external_id',
						width: 55,
						filter: 'taskIdGridFilter',
						title: 'DISPATCH_LIST.ID',
						toolTip: 'ID if the task',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.external_id;
						}
					},
					{
						id: 's',
						field: 'status',
						width: 30,
						filter: 'taskGridStatusFilter',
						title: 'DISPATCH_LIST.STATUS',
						translate: true,
						sortable: true
					},
					{
						id: 'order',
						noFilter: true,
						field: 'title',
						title: 'DISPATCH_LIST.ORDER',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.title;
						}
					},
					{
						id: 'tag',
						noFilter: true,
						field: 'tag_id',
						title: 'DISPATCH_LIST.TAG',
						translate: true,
						sortable: true,
						filter: 'tagName',
						getSearchValue: function (item) {
							return $filter('tagName')('tag')(null, null, null, null, item);
						}
					},
					{
						id: 'priority',
						title: 'DISPATCH_LIST.PRIORITY',
						translate: true,
						active: false,
						position: 4,
						getValue: function (item) {
							const collapseTaskData = CollapsedLinkedTasksService.getCollapseLinkedTasksById(item.id);
							return collapseTaskData ? collapseTaskData.priorities : item.priority;
						}
					},
					{
						id: 'rank',
						title: 'DISPATCH_LIST.RANK',
						active: false,
						translate: true,
						sortable: true
					},
					{
						id: 'planning_published',
						title: 'DISPATCH_LIST.PLANNING_PUBLISHED',
						active: false,
						translate: true,
						sortable: true
					},
					{
						id: 'orderCreated',
						field: 'created_at',
						width: 100,
						filter: 'taskGridScheduledForFilter',
						title: 'DISPATCH_LIST.ORDER_CREATED',
						translate: true,
						sortable: true
					},
					{
						id: 'assignedTo',
						field: 'user_id',
						width: 130,
						filter: 'taskGridDriverFilter',
						filterField: 'assignedTo',
						customSortField: 'user_id',
						title: 'DISPATCH_LIST.ASSIGNED_TO',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							var value = 'Unassigned';
							if (item.user_id) {
								var employee = Employees.employeeByIdMap[item.user_id];
								value = employee ? employee.name : value;
							}

							return value;
						}
					},
					{
						id: 'taskProgress',
						field: 'external_id',
						width: 100,
						filter: 'taskGridProgressFilter',
						requiredFields: [
							'way_points.all.id',
							'way_points.all.late',
							'way_points.all.done',
							'active_way_point_id',
							'status'
						],
						title: 'DISPATCH_LIST.PROGRESS',
						translate: true,
						sortable: false
					},
					{
						id: 'nextStop',
						field: 'address',
						requiredFields: ['active_way_point_id', 'way_points.all.id', 'way_points.all.address'],
						filter: 'taskGridAddressFilter',
						title: 'DISPATCH_LIST.NEXT_STOP',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.address;
						}
					},
					{
						id: 'done_planning',
						field: 'planning_done',
						filter: 'taskDonePlanningFilter',
						title: 'DISPATCH_LIST.DONE_PLANNING',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return item.done_planning;
						}
					},
					{
						id: ' ',
						title: ' ',
						field: 'late',
						width: 30,
						filter: 'taskGridLateFilter',
						translate: false,
						sortable: true
					},
					{
						id: 'scheduledFor',
						field: 'scheduled_at',
						title: 'DISPATCH_LIST.SCHEDULED_FOR',
						filter: 'taskGridScheduledForFilter',
						formatterField: 'scheduledFor',
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.address',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).address;
						}
					},
					{
						id: 'way_points.first.full_address',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_FULL_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).full_address;
						}
					},
					{
						id: 'way_points.first.scheduled_at',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_SCHEDULED_AT',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.eta',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ETA',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.etl',
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ETL',
						postDefaultFilter: 'taskGridScheduledForFilter',
						active: false,
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.customer.phone',
						field: 'way_points.first.phone',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_PHONE',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).phone;
						},
						getValue: function (item) {
							return PhoneNumberService.formatPhoneNumber(_.first(item.way_points)?.phone);
						}
					},
					{
						id: 'way_points.first.customer.name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_CUSTOMER_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: true,
						filter: 'customerNameFilter',
						getSearchValue: function (item) {
							return (
								_.first(item.way_points) &&
								_.first(item.way_points).customer &&
								_.first(item.way_points).customer.name
							);
						}
					},
					{
						id: 'way_points.first.zipcode',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_ZIPCODE',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).zipcode;
						}
					},
					{
						id: 'way_points.last.address',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).address;
						}
					},
					{
						id: 'way_points.last.full_address',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_FULL_ADDRESS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).full_address;
						}
					},
					{
						id: 'way_points.last.scheduled_at',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_SCHEDULED_AT',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.eta',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ETA',
						active: false,
						postDefaultFilter: 'taskGridScheduledForFilter',
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.etl',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ETL',
						postDefaultFilter: 'taskGridScheduledForFilter',
						active: false,
						width: 140,
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.last.customer.phone',
						field: 'way_points.last.phone',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_PHONE',
						width: 140,
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).phone;
						},
						getValue: function (item) {
							return PhoneNumberService.formatPhoneNumber(_.last(item.way_points)?.phone);
						}
					},
					{
						id: 'way_points.last.customer.name',
						active: false,
						isFirstWayPoint: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_CUSTOMER_NAME',
						translate: true,
						sortable: true,
						filter: 'customerNameFilter',
						getSearchValue: function (item) {
							return (
								_.last(item.way_points) &&
								_.last(item.way_points).customer &&
								_.last(item.way_points).customer.name
							);
						}
					},
					{
						id: 'way_points.last.zipcode',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ZIPCODE',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).zipcode;
						}
					},
					{
						id: 'print_receipt',
						active: false,
						filter: 'taskGridPrintReceiptFilter',
						field: 'id',
						title: 'DISPATCH_LIST.PRINT_RECEIPT',
						translate: true,
						sortable: true
					},
					{
						id: 'way_points.first.time_until_scheduled',
						title: 'DISPATCH_LIST.TIME_UNTIL_FIRST_WAY_POINT_SCHEDULED',
						active: false,
						getValue: function (item) {
							var activeWayPoint = _.find(item.way_points, { id: item.active_way_point_id });
							if (_.isNil(activeWayPoint)) {
								return '';
							}

							var scheduledTime = activeWayPoint.no_later_than || item.scheduled_at;
							if (_.isNil(scheduledTime)) {
								return '';
							}

							var now = moment().valueOf();
							var scheduledTimeValue = moment(scheduledTime).valueOf();

							if (scheduledTimeValue < now) {
								return '';
							}

							var result = '';
							if (scheduledTimeValue > moment(now).add(1, 'days').valueOf()) {
								result = TranslationService.instant('DISPATCH.FUTURE');
							} else {
								var diff = moment.utc(scheduledTimeValue - now);
								diff = diff.seconds(Math.ceil(diff.seconds() / 60) * 60);
								result = TimeManagerService.format(diff, 'HH:mm');
							}

							return result;
						},
						width: 140,
						translate: true,
						sortable: true
					},
					{
						active: false,
						id: 'time_window',
						title: 'DISPATCH_LIST.TIME_WINDOW',
						translate: true,
						filter: 'taskGridTimeWindowFilter',
						requiredFields: [
							'active_way_point_id',
							'way_points.all.id',
							'way_points.all.no_later_than',
							'way_points.all.no_earlier_than'
						],
						sortable: true,
						getValue: function (item) {
							var wp = _.last(item.way_points);
							if (wp && !_.isNull(wp.no_earlier_than) && !_.isUndefined(wp.no_earlier_than)) {
								return new Date(wp.no_earlier_than).getTime();
							}

							return -1;
						}
					},
					{
						id: 'origin_title',
						title: 'DISPATCH_LIST.ORIGIN_TITLE',
						translate: true,
						active: false,
						sortable: true
					},
					{
						id: 'service_plan_name',
						title: 'DISPATCH_LIST.SERVICE_PLAN_NAME',
						translate: true,
						active: false,
						sortable: true,
						field: 'service_plan',
						getValue: function (item) {
							return _.get(item, 'service_plan.name');
						}
					},
					{
						id: 'address_type_first',
						title: 'DISPATCH_LIST.ADDRESS_TYPE_NAME_FIRST',
						translate: true,
						active: false,
						sortable: true,
						requiredFields: ['way_points.all.address_type'],
						getValue: function (item) {
							return $filter('addressTypeName')(
								_.first(item.way_points) && _.first(item.way_points).address_type
							);
						}
					},
					{
						id: 'address_type_last',
						title: 'DISPATCH_LIST.ADDRESS_TYPE_NAME_LAST',
						translate: true,
						active: false,
						sortable: true,
						requiredFields: ['way_points.all.address_type'],
						getValue: function (item) {
							return $filter('addressTypeName')(
								_.last(item.way_points) && _.last(item.way_points).address_type
							);
						}
					},
					{
						id: 'order_tag',
						title: 'DISPATCH_LIST.ORDER_TAG',
						translate: true,
						active: false,
						sortable: true,
						requiredFields: ['way_points.all.ui_data'],
						filter: 'orderTagFilter'
					},
					{
						id: 'required_skills',
						active: false,
						filter: 'requiredSkillsFilter',
						title: 'DISPATCH_LIST.SKILLS',
						translate: true,
						sortable: true
					},
					{
						id: 'total_weight',
						title: 'DISPATCH_LIST.TOTAL_WEIGHT',
						translate: true,
						active: false,
						sortable: true,
						getValue: function (item) {
							return (
								item.aggregations &&
								item.aggregations.max_weight &&
								Math.round(item.aggregations.max_weight * 100) / 100
							);
						}
					},
					{
						id: 'total_quantity',
						title: 'DISPATCH_LIST.TOTAL_QUANTITY',
						translate: true,
						active: false,
						sortable: true,
						getValue: function (item) {
							return LoadConstraintsService.calcTaskInventoriesTotalQuantity(item);
						}
					},
					{
						id: 'task_type_id',
						title: 'DISPATCH_LIST.TASK_TYPE',
						translate: true,
						active: false,
						sortable: true,
						getValue: item => Tasks.getTaskTypeName(item.task_type_id)
					},
					{
						id: 'print_label',
						active: false,
						filter: 'taskGridPrintLabelFilter',
						field: 'id',
						title: 'DISPATCH_LIST.PRINT_LABEL',
						translate: true,
						sortable: true
					},
					{
						id: 'teams_ids',
						title: 'DISPATCH_LIST.TEAM',
						translate: true,
						active: false,
						sortable: true,
						getValue: item => Tasks.getTeamName(item),
						getSearchValue: function (item) {
							return Tasks.getTeamName(item);
						}
					},
					{
						id: 'preparation_ready_for_pickup_time_actual',
						title: 'DISPATCH_LIST.READY_FOR_PICKUP',
						filter: 'taskGridScheduledForFilter',
						translate: true,
						active: false,
						sortable: true
					},
					{
						id: 'way_points.first.borough',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_BOROUGH',
						translate: true,
						sortable: true,
						isFirstWayPoint: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).borough;
						}
					},
					{
						id: 'way_points.first.location_name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_LOCATION_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: true,
						getSearchValue: function (item) {
							return _.first(item.way_points) && _.first(item.way_points).location_name;
						}
					},
					{
						id: 'way_points.last.borough',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_BOROUGH',
						translate: true,
						sortable: true,
						isFirstWayPoint: false,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).borough;
						}
					},
					{
						id: 'way_points.last.location_name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_LOCATION_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: false,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).location_name;
						}
					},
					{
						id: 'way_points.last.company_name',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_COMPANY_NAME',
						translate: true,
						sortable: true,
						isFirstWayPoint: false,
						getSearchValue: function (item) {
							return _.last(item.way_points) && _.last(item.way_points).company_name;
						}
					},
					{
						id: 'way_points.last.etos',
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_ETOS',
						active: false,
						width: 140,
						translate: true,
						sortable: true,
						getValue: function (item) {
							const etosSeconds = item.way_points?.[item.way_points?.length - 1]?.etos;
							return etosSeconds ? timeConvert(etosSeconds) : undefined;
						}
					},
					// Custom attributes related columns
					...CustomAttributesService.generateTaskColumns(),

					...ManagedAttributesService.generateTaskColumns()
				];

				var handlingUnits = MerchantConfigurations.custom_units_definition || {};

				for (var unitName in handlingUnits) {
					var getValueOfUnit = function (unitName) {
						return function (item) {
							return (
								item.aggregations &&
								item.aggregations.max_handling_units &&
								item.aggregations.max_handling_units[unitName]
							);
						};
					};

					defaults.push({
						id: 'handling_units_aggregation_' + unitName,
						title: handlingUnits[unitName].name,
						getValue: getValueOfUnit(unitName),
						sortable: true,
						translate: false
					});
				}

				defaults.push({
					id: 'group_follower_count',
					active: false,
					title: 'DISPATCH_LIST.GROUP_FOLLOWERS',
					translate: true,
					sortable: true,
					getValue: function (task) {
						const followersCount = TasksData.getFollowersCount(task.id);
						return followersCount || undefined;
					}
				});

				defaults.push(
					{
						id: 'way_points.first.service_area_ids',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_FIRST_SERVICE_AREA',
						translate: true,
						sortable: false,
						getSearchValue: function (task) {
							const serviceAreaIds = task.way_points?.[0]?.service_area_ids || [];
							return ServiceAreasService.getByIds(serviceAreaIds)
								.map(serviceArea => serviceArea?.name)
								.filter(Boolean)
								.join('');
						},
						getValue: function (task) {
							const serviceAreaIds = task.way_points?.[0]?.service_area_ids || [];
							return ServiceAreasService.getByIds(serviceAreaIds)
								.map(serviceArea => serviceArea?.name)
								.filter(Boolean)
								.join(', ');
						}
					},
					{
						id: 'way_points.last.service_area_ids',
						active: false,
						title: 'DISPATCH_LIST.WAY_POINTS_LAST_SERVICE_AREA',
						translate: true,
						sortable: false,
						getSearchValue: function (task) {
							const serviceAreaIds = _.last(task.way_points)?.service_area_ids || [];
							return ServiceAreasService.getByIds(serviceAreaIds)
								.map(serviceArea => serviceArea?.name)
								.filter(Boolean)
								.join('');
						},
						getValue: function (task) {
							const serviceAreaIds = _.last(task.way_points)?.service_area_ids || [];
							return ServiceAreasService.getByIds(serviceAreaIds)
								.map(serviceArea => serviceArea?.name)
								.filter(Boolean)
								.join(', ');
						}
					}
				);

				// total price in case enabled in merchant configurations
				if (MerchantConfigurations.enable_task_price) {
					defaults.push({
						id: 'total_price',
						title: 'DISPATCH_LIST.PRICE',
						field: 'total_price',
						width: 30,
						sortable: true,
						translate: true,
						getSearchValue: function (item) {
							return item.total_price;
						}
					});
				}

				// geohash
				if (MerchantConfigurations.$rdsMode() && currentUser.beta) {
					defaults.push({
						id: 'geohash',
						title: 'DISPATCH_LIST.GEOHASH',
						translate: true,
						field: 'geohash',
						width: 30,
						sortable: true
					});

					defaults.push({
						id: 'geohash_origin',
						title: 'DISPATCH_LIST.GEOHASH_ORIGIN',
						field: 'geohash_origin',
						active: false,
						width: 150,
						translate: true,
						sortable: true
					});

					defaults.push({
						id: 'geohash_destination',
						title: 'DISPATCH_LIST.GEOHASH_DESTINATION',
						field: 'geohash_destination',
						active: false,
						width: 150,
						translate: true,
						sortable: true
					});
				}

				// fleets
				if (MerchantConfigurations.enable_fleets) {
					defaults.push({
						id: 'assignedToFleet',
						field: 'fleet_id',
						active: true,
						filter: 'taskGridFleetFilter',
						customSortField: 'fleet_id',
						title: 'DISPATCH_LIST.ASSIGNED_TO_FLEET',
						translate: true,
						sortable: true,
						getSearchValue: function (item) {
							var value = 'Unassigned';
							if (item.fleet_id) {
								value = Fleets.getName(item.fleet_id);
							}

							return value;
						}
					});
				}

				if (MerchantConfigurations.enable_planned_routes) {
					defaults.push({
						id: 'run.planned_route.run_title',
						field: 'run_id',
						active: false,
						title: 'DISPATCH_LIST.ROUTE_NAME',
						translate: true,
						sortable: true,
						getValue: function (task) {
							const run = RunsService.getRunFromTask(task);
							return run && run.route_name;
						}
					});
				}

				if (AutoDispatchAnalytic.isVrpAutoDispatchEnabled()) {
					defaults.push({
						id: 'autoDispatch',
						active: false,
						width: 130,
						filter: 'taskAutoDispatchFilter',
						customSortField: 'auto_dispatch',
						title: 'DISPATCH_LIST.AUTO_DISPATCH',
						translate: true,
						sortable: true
					});
				}

				if (columnsKey === PAGE_FIELDS.PLANNING_FIELDS) {
					defaults.push({
						id: 'first_attempt_promise_no_earlier_than',
						title: 'DISPATCH_LIST.PROMISE_START_TIME',
						width: 100,
						filter: 'taskPromiseTimeFilter',
						translate: true,
						sortable: true,
						getValue: function (task) {
							return _.get(
								WayPointsService.getActionableWaypoint(task),
								'first_attempt_promise_no_earlier_than'
							);
						}
					});

					defaults.push({
						id: 'first_attempt_promise_no_later_than',
						title: 'DISPATCH_LIST.PROMISE_END_TIME',
						width: 100,
						filter: 'taskPromiseTimeFilter',
						translate: true,
						sortable: true,
						getValue: function (task) {
							return _.get(
								WayPointsService.getActionableWaypoint(task),
								'first_attempt_promise_no_later_than'
							);
						}
					});
				}

				defaults.push({
					id: 'reservedUntil',
					field: 'reserved_until',
					title: 'FILTER_BAR.RESERVED_UNTIL',
					filter: 'taskGridScheduledForFilter',
					width: 100,
					translate: true,
					sortable: true
				});

				// set position for each one
				_.each(defaults, function (defaultColumn, index) {
					defaultColumn.position = index;
				});

				return defaults;
			}

			function timeConvert(seconds) {
				const hours = seconds / 3600;
				const rhours = Math.floor(hours);
				const minutes = (hours - rhours) * 60;
				const rminutes = Math.round(minutes);

				return `${rhours.toString().padStart(2, '0')}:${rminutes.toString().padStart(2, '0')}`;
			}

			return {
				getDefaultsColumns: getDefaultsColumns
			};
		}
	);
