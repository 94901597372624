'use strict';

angular
	.module('bringgApp')
	.controller('SubmitTicketController', function (
		$scope,
		$uibModalInstance,
		SubmitTicketService,
		Authentication,
		MerchantService,
		ClientStatsService,
		$translate,
		toastr,
		title,
		origin,
		type,
		description
	) {
		$scope.ticket = {};
		$scope.sending = false;
		$scope.handleCancelClick = function () {
			$uibModalInstance.dismiss();
		};

		MerchantService.get().then(function (merchant) {
			var user = Authentication.currentUser();
			$scope.ticket.userName = user.name;
			$scope.ticket.userEmail = user.email;
			$scope.ticket.userPhone = user.phone ? user.phone : merchant.phone;
			$scope.ticket.title = title;
			$scope.ticket.origin = origin;
			$scope.ticket.type = type;
			$scope.ticket.description = description;
		});

		$scope.handleSubmitClick = function () {
			$scope.sending = true;
			$scope.ticket.logs = ClientStatsService.createLogs();
			SubmitTicketService.send($scope.ticket).then(
				function (result) {
					toastr.success($translate.instant('SUBMIT_TICKET.SUBMIT_SUCCESS'));
					$uibModalInstance.dismiss();
				},
				function (reason) {
					$scope.sending = false;
					toastr.error($translate.instant('SUBMIT_TICKET.SUBMIT_ERROR') + ' ' + reason);
				}
			);
		};
	});
