'use strict';

angular
	.module('bringgApp')
	.factory(
		'OptimizeModalService',
		function (
			$uibModal,
			$rootScope,
			MerchantConfigurations,
			Teams,
			dialogSrv,
			$log,
			TASK_DEFAULT_TYPES,
			WayPointsService
		) {
			var self = {};

			self.startOptimization = function (options) {
				return $uibModal.open({
					backdrop: 'static',
					keyboard: true,
					size: 'lg',
					windowClass: 'optimize-tasks',
					resolve: {
						automaticOptimization: function () {
							return options.automaticOptimization;
						},
						selectedTasks: function () {
							return self.filterTasksForPreview(options.selectedTasks);
						},
						dispatch: function () {
							return options.dispatch;
						},
						optimizeDrivers: function () {
							return options.drivers;
						},
						optimizationType: function () {
							return options.type;
						},
						tasksScheduledDate: function () {
							return options.tasksScheduledDate;
						},
						preserveCurrentRuns: function () {
							return options.preserveCurrentRuns;
						}
					},
					controller: 'OptimizeModalController',
					templateUrl: 'scripts/features/optimize/optimize-modal.html'
				});
			};

			self.openLocationErrorModalIfNeeded = function (selectedTasks) {
				return self.haltIfNotGeocoded(selectedTasks) && self.haltIfTasksTooFar(selectedTasks);
			};

			/***
			 * open optimize modal service
			 */
			self.open = function (options) {
				options = options || {};

				// skip all validations
				if (options.automaticOptimization) {
					return self.startOptimization(options);
				}

				if (self.openLocationErrorModalIfNeeded(options.selectedTasks)) {
					return self.startOptimization(options);
				}
			};

			self.filterTasksForPreview = function (selectedTasks) {
				const filteredTasks = selectedTasks.filter(task => {
					if (task.task_type_id === TASK_DEFAULT_TYPES.RETURN_TASK) {
						return false;
					}
					const taskDropOfWayPoint = WayPointsService.getDropOffWayPoint(task);
					if (!taskDropOfWayPoint) {
						return false;
					}
					return true;
				});
				return filteredTasks;
			};

			self.haltIfNotGeocoded = function (tasks) {
				var tasksByValidity = _.partition(tasks, function (task) {
					return _.every(task.way_points, function (wayPoint) {
						return wayPoint && wayPoint.lat && wayPoint.lng;
					});
				});

				if (tasksByValidity[1].length) {
					var scope = $rootScope.$new(true);
					scope.validTasks = tasksByValidity[0];
					scope.invalidTasks = tasksByValidity[1];
					scope.getTaskAddressFromWayPoint = function (task) {
						return _.chain(task.way_points)
							.filter(function (wayPoint) {
								return !(wayPoint.lat && wayPoint.lng);
							})
							.map(function (wayPoint) {
								return wayPoint.address || 'no_address';
							})
							.join(' | ')
							.value();
					};
					$uibModal.open({
						backdrop: 'static',
						keyboard: true,
						size: 'lg',
						windowClass: 'optimize-tasks',
						scope: scope,
						templateUrl: 'scripts/features/optimize/optimize-missing-geo-modal.html'
					});

					return false;
				}

				return true;
			};

			/**
			 * return list of tasks that are distance are too far from their team (if they have team)
			 * @param tasks
			 */
			self.getTooFarTasks = function (tasks) {
				var maxDistanceFromCenterInMeters = MerchantConfigurations.max_distance_for_optimization_in_meters; // in meters

				return _.filter(tasks, function (task) {
					// validate task
					var team = Teams.teamsByIdMap[_.first(task.team_ids)];
					if (!team) {
						$log.warn('task ' + task.id + ' could not find team: ' + _.first(task.team_ids));
						return;
					}

					// will be true if there is any waypoint that the distance from the team is higher then maxDistanceFromCenterInMeters
					var isDistanceTooFar = _.some(task.way_points, function (wayPoint) {
						var distance = geolib.getDistance(
							{ latitude: team.lat, longitude: team.lng },
							{ latitude: wayPoint.lat, longitude: wayPoint.lng }
						);
						return distance >= maxDistanceFromCenterInMeters;
					});

					return isDistanceTooFar;
				});
			};

			self.haltIfTasksTooFar = function (tasks) {
				var tooFarTasks = self.getTooFarTasks(tasks);
				if (!_.isEmpty(tooFarTasks)) {
					var taskErrors = _.map(tooFarTasks, function (task) {
						return 'Task ' + task.external_id;
					});
					dialogSrv.errorDialog('The following task are too far from their team: ', taskErrors.join('\n'));
					return false;
				}

				return true;
			};

			return self;
		}
	);
