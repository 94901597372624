'use strict';

angular.module('bringgApp').factory('QrCodeGeneratorService', function ($uibModal, Token, toastr, REGION) {
	var QrCodeGeneratorService = {};

	QrCodeGeneratorService.open = function (userId, userName) {
		Token.gen_driver_qr_code({ user_id: userId })
			.$promise.then(function (result) {
				$uibModal.open({
					backdrop: true,
					keyboard: true,
					templateUrl: 'scripts/features/qr_code_generator/qr-code-generator.html',
					openedClass: 'qr-code-modal',
					resolve: {
						accessObject: function () {
							return { token: result.access_token, secret: result.secret, region: REGION() };
						},
						userName: function () {
							return userName;
						}
					},
					controller: function ($scope, accessObject, userName, $uibModalInstance) {
						$scope.accessObject = accessObject;
						$scope.userName = userName;

						$scope.handleCloseClick = function () {
							$uibModalInstance.dismiss('close');
						};
					}
				});
			})
			.catch(function (err) {
				toastr.error('Error occurred');
			});
	};

	QrCodeGeneratorService.appearance_options = {
		DISABLED: 0,
		ON_DRIVER_LIST: 1,
		ON_DRIVER_PAGE: 2,
		ON_ALL_LOCATIONS: 3,
		DEFAULT_FROM_MERCHANT: 4
	};

	return QrCodeGeneratorService;
});
