'use strict';

angular
	.module('bringgApp')
	.controller(
		'MainNavigationVer2Controller',
		function (
			$scope,
			$rootScope,
			$location,
			Authentication,
			Alerts,
			MerchantConfigurations,
			SocketPubSub,
			localStorageService,
			$uibModal,
			$log,
			Payments,
			AlertsPopoverService,
			MerchantService,
			Idle,
			toastr,
			MainNavigationVer2Service,
			REGION,
			$timeout
		) {
			$scope.region = REGION;
			$scope.showAcceleratorsMenu = Authentication.currentUser().admin;

			$scope.getLogoStyle = function (url) {
				if (!url || url.indexOf('http') === -1) {
					url = 'images/logo-v3.png';
				}

				return 'background-image: url(' + url + ');';
			};

			//set the logo on the navigation left side
			$scope.navigationLogo = $scope.getLogoStyle(localStorageService.get('merchantLogo'));

			$scope.merchantConfigurations = MerchantConfigurations;

			$rootScope.$watch(Authentication.isLoggedIn, function () {
				$scope.isLoggedIn = Authentication.isLoggedIn();
				if ($scope.isLoggedIn) {
					$scope.current_user = Authentication.currentUser();

					if (MerchantConfigurations.logo) {
						$scope.navigationLogo = $scope.getLogoStyle(MerchantConfigurations.logo);
					}

					Payments.initStripeConfiguration();

					MerchantService.get().then(function (merchant) {
						$scope.merchant = merchant;
					});

					$scope.totalAlerts = 0;
					Alerts.loadLatest().then(function () {
						$scope.totalAlerts = Alerts.totalAlerts;
					});

					$rootScope.$on('alerts update', function () {
						$timeout(function () {
							$scope.totalAlerts = Alerts.totalAlerts;
						});
					});
				} else {
					Idle.unwatch();
				}
			});

			$rootScope.$watch(SocketPubSub.currentTransport, function () {
				$scope.currentTransport = SocketPubSub.currentTransport();
			});

			setInterval(function () {
				$scope.lastSocketUpdated = SocketPubSub.isConnected() ? new Date() : null;
			}, 2000);

			$scope.$on('config updated', function () {
				$scope.navigationLogo = $scope.getLogoStyle(MerchantConfigurations.logo);
				if ($scope.navigationLogo) {
					localStorageService.set('merchantLogo', $scope.navigationLogo);
				}

				if (MerchantConfigurations.secured_setup) {
					var idle =
						$scope.current_user && $scope.current_user.admin
							? MerchantConfigurations.logout_idle_admin_minutes || 30
							: MerchantConfigurations.logout_idle_dispatcher_minutes || 9 * 60;
					Idle.setIdle(idle * 60); // seconds
					Idle.setTimeout(10); // seconds
					Idle.watch();
					$log.info('MainNavigationController setup idle service:', idle, 'minutes');
				}
			});

			var menuLocations = _.map(MainNavigationVer2Service.navbarItems, 'location');

			$scope.isPlanningOrDispatch = isPlanningOrDispatch($location.path());
			$scope.$on('$locationChangeSuccess', function (evt, current) {
				$scope.isPlanningOrDispatch = isPlanningOrDispatch(current);

				var found = false;
				menuLocations.forEach(function (menuLocation) {
					if (current.indexOf(menuLocation) !== -1) {
						$scope.menuLocation = menuLocation;
						found = true;
						return;
					}
				});
				if (!found) {
					$scope.menuLocation = 'manage';
				}
			});

			$scope.signOutClicked = function () {
				Authentication.logout();
			};

			$scope.handleAlertsButtonClick = function (event) {
				if (!$scope.totalAlerts) {
					return $location.path('/alerts/');
				}
				AlertsPopoverService.showPopover(event);
			};

			$scope.handleChristmasBannerClicked = function () {
				$uibModal.open({
					backdrop: true,
					keyboard: true,
					templateUrl: 'views/dialogs/christmas-pointer-change.html',
					controller: 'christmasPointerChangeController',
					windowClass: 'christmas-modal'
				});
			};

			$scope.$on('IdleStart', function () {
				$scope.idle_toastr = toastr.warning(
					'Interact with the site to continue',
					'User inactivity was detected',
					{
						progressBar: true,
						timeOut: Idle.getTimeout() * 1000,
						extendedTimeOut: 0
					}
				);
			});

			$scope.$on('IdleTimeout', function () {
				Authentication.logout(false);
			});

			$scope.$on('IdleEnd', function () {
				if ($scope.idle_toastr) {
					toastr.clear($scope.idle_toastr);
				}
			});

			$scope.onGeocodingWayPointsAlertsLengthChanged = length => {
				$scope.geoCodingAlertsExists = length;
			};

			$scope.onFixOrderAlertsLengthChanged = length => {
				$scope.fixOrderAlertsExists = length;
			};

			$scope.isImpersonateVisible = visible => {
				$scope.isImpersonateEnabled = visible;
			};

			$scope.hasRequiredFeatures = navItem => {
				return (
					$rootScope.sdk.session.merchantConfiguration &&
					MainNavigationVer2Service.hasRequiredFeatures(navItem)
				);
			};

			$scope.isLocationActive = function (location) {
				const path = $location.path();
				return path === `/${location}/` || path === `/${location}`;
			};

			$scope.isChildLocationActive = function (childLocations) {
				const path = $location.path();
				return childLocations.some(childPath => path === `/${childPath}/` || path === `/${childPath}`);
			};

			$scope.isActiveNav = function (navItem) {
				return (
					$scope.isLocationActive(navItem.location) ||
					(navItem.childLocations && $scope.isChildLocationActive(navItem.childLocations))
				);
			};

			$scope.isChatNavItem = function (navItem) {
				return navItem.id === 'top_menu_sendbird_chat';
			};

			function isPlanningOrDispatch(currentLocation) {
				var isPlanning = currentLocation.indexOf('/planning') >= 0;
				var isDispatch = currentLocation.indexOf('/map') >= 0;
				isDispatch = isDispatch || currentLocation.indexOf('/list') >= 0;

				return isPlanning || isDispatch;
			}

			$scope.navbarItems = MainNavigationVer2Service.navbarItems;

			$scope.openAcceleratorsPage = function () {
				return $location.path('/addons/');
			};

			$scope.isAcceleratorsPageOpened = function () {
				const path = $location.path();
				return path && path.includes('/addons/');
			};
		}
	)
	.directive('mainNavigationVer2', function () {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'scripts/new_navigation/main_navigation_ver2/main-navigation-ver2.html',
			controller: 'MainNavigationVer2Controller'
		};
	});
