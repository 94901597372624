/**
 * Created by liorsion on 4/26/15.
 */
'use strict';

angular.module('serviceSetupService', ['ngResource']).factory('ServiceSetup', function ($resource, WEB_API_URL) {
	var ServiceSetup = $resource(
		WEB_API_URL + '/services_setup/:id',
		{ id: '@id' },
		{
			all: { method: 'GET', isArray: true, url: WEB_API_URL + '/services_setup/' },
			list: { method: 'GET', isArray: true, url: WEB_API_URL + '/services_setup/list' }
		}
	);

	return ServiceSetup;
});
