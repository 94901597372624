/*global google:false */
/*global createMarker:false */
/*global resizeMapByMarkers:false */

'use strict';

angular
	.module('bringgApp')
	.controller('DispatcherPrintController', function (
		$scope,
		$rootScope,
		localStorageService,
		MerchantConfigurations,
		Authentication,
		Teams,
		$window,
		$timeout,
		$log,
		$translate
	) {
		$scope.wayPointIndex = 1;
		$scope.loading = true;
		$scope.generatedAt = new Date();
		$scope.logo = MerchantConfigurations.logo;
		$scope.teams = Teams;
		$scope.removeInventoryFromPrint = localStorageService.get('removeInventoryFromPrint');
		var data = localStorageService.get('dispatchListPrintData');
		$scope.driverIds = _.keys(data);
		$scope.drivers = data;
		$rootScope.hideMenu = true;

		$scope.handleCloseClicked = function () {
			$scope.clearLocalStorage();
			$window.close();
		};

		$scope.handlePrintClicked = function () {
			$window.print();
		};

		$scope.getDirectionLabel = function (direction) {
			if (direction) {
				return $translate.instant('ORDER_INVENTORY.PICK_UP');
			} else {
				return $translate.instant('ORDER_INVENTORY.DROP_OFF');
			}
		};

		$scope.clearLocalStorage = function () {
			localStorageService.remove('dispatchListPrintData');
			localStorageService.remove('removeInventoryFromPrint');
		};

		$scope.$on('$destroy', function () {
			$rootScope.hideMenu = false;
		});

		$window.onbeforeunload = $scope.clearLocalStorage;
	});
