'use strict';
/*global $:false */

// see http://stackoverflow.com/questions/16512551/angular-directive-newbie-how-to-add-date-time-picker-to-ngmodel-so-that-it-ca
angular
	.module('bringgApp.directives')
	.directive('dateTime', function ($log) {
		return {
			restrict: 'A',
			require: '?ngModel',
			link: function (scope, element, attrs, ngModel) {
				if (!ngModel) {
					$log.error('no model, returning');
					return;
				}

				function read() {
					ngModel.$setViewValue(element.val());
				}

				$(element).on('changeDate', function (data) {
					ngModel.$setViewValue(data.date);
				});

				element.on('blur keyup change', function () {
					scope.$apply(read);
				});

				read();
			}
		};
	})
	.directive('secondsToHoursMinutes', function ($filter) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attr, ctrl) {
				ctrl.$formatters.push(function (modelValue) {
					return $filter('seconds_to_hh_mm')(modelValue);
				});

				ctrl.$parsers.push(function (viewValue) {
					return moment.duration(viewValue).asSeconds();
				});
			}
		};
	});
