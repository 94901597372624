'use strict';

angular.module('bringgApp.directives').directive('driverCosts', function ($log, Employee) {
	return {
		templateUrl: 'scripts/directives/driver-costs/driver-costs.html',
		scope: {
			driverId: '<'
		},
		link: function (scope) {
			scope.driverCosts = null;

			if (!scope.driverId) {
				$log.error('No driver Id was provided');
				return;
			}

			Employee.getDriverCost(scope.driverId)
				.then(function (result) {
					scope.driverCosts = result.driver_cost;
				})
				.catch(function (error) {
					$log.error(error);
				});
		}
	};
});
