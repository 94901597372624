'use strict';

angular
	.module('bringgApp')
	.controller('DynamicFormDialogController', function (
		$scope,
		$rootScope,
		$timeout,
		$uibModalInstance,
		header,
		fields,
		evt,
		data
	) {
		$scope.header = header;
		$scope.fields = fields;

		$scope.formFields = _.reduce(
			fields,
			function (formFields, field) {
				formFields[field.key] = field.default_value || '';
				return formFields;
			},
			{}
		);

		$scope.getFieldType = function (type) {
			switch (type) {
				case 0:
					return 'text';
				case 1:
					return 'number';
				default:
					return 'text';
			}
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.send = function () {
			$uibModalInstance.close();
			_.extend(data, { fields: $scope.formFields });
			$timeout(function () {
				$rootScope.$broadcast(evt, data);
			}, 1000);
		};
	});
