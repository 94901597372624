'use strict';

angular.module('bringgApp.directives').directive('ensureValidPhone', function (Employee, $timeout) {
	var checking = null;

	function validatePhone(c, phone) {
		if (phone === undefined) {
			return;
		}
		if (!checking) {
			checking = $timeout(function () {
				Employee.validatePhone(phone).then(function (response) {
					c.$setValidity('phone', response);
					checking = null;
				});
			}, 300);
		}
	}

	return {
		require: 'ngModel',
		link: function (scope, ele, attrs, c) {
			ele.on('blur', function () {
				var phone = c.$modelValue;
				if (phone === undefined) {
					return;
				}

				if (!attrs.required && phone === '') {
					return;
				}

				Employee.validatePhone(phone).then(function (response) {
					c.$setValidity('phone', response);
					checking = null;
				});
			});
		}
	};
});
