'use strict';

angular.module('bringgApp.directives').directive('ngThumb', [
	'$window',
	function ($window) {
		var helper = {
			support: !!($window.FileReader && $window.CanvasRenderingContext2D),
			isFile: function (item) {
				return angular.isObject(item) && item instanceof $window.File;
			},
			isImage: function (file) {
				var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
				return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
			}
		};

		function loadFile(element, params) {
			if (!helper.isFile(params._file)) {
				return;
			}
			if (!helper.isImage(params._file)) {
				return;
			}

			var image = element[0];
			var reader = new FileReader();

			function onLoadFile(event) {
				//        var img = new Image();
				//        img.src = event.target.result;
				image.src = event.target.result;
			}

			reader.onload = onLoadFile;
			reader.readAsDataURL(params._file);
		}

		return {
			restrict: 'A',
			link: function (scope, element, attributes) {
				if (!helper.support) {
					return;
				}

				var params = scope.$eval(attributes.ngThumb);

				$('#' + element[0].id).on('$new_file', function (event, params) {
					loadFile(element, params);
				});

				loadFile(element, params);
			}
		};
	}
]);
