'use strict';

angular.module('bringgApp.filters').filter('priceBeforeTaxWithDeliveryFee', function () {
	return function (task) {
		if (_.isUndefined(task.delivery_price) || _.isNull(task.delivery_price)) {
			return task.price_before_tax;
		}

		return task.price_before_tax + task.delivery_price;
	};
});
