'use strict';
angular
	.module('bringgApp')
	.directive('onFileChange', function () {
		return {
			restrict: 'A',
			scope: {
				onFileChange: '='
			},
			link: function (scope, element, attrs) {
				element.on('change', function ($event) {
					scope.onFileChange($event.currentTarget);
				});
			}
		};
	})
	.controller('AddMapOverlayController', function ($scope, $uibModalInstance, MapOverlays, GeoJsonService, toastr) {
		$scope.close = function () {
			$uibModalInstance.dismiss();
		};

		$scope.sending = false;
		$scope.geoJsonFileContent = '';

		$scope.handleGeoJsonFileSelect = function (evt) {
			var file = evt.files[0];
			var reader = new FileReader();

			reader.onload = function (result) {
				var content = result.target.result;
				if (GeoJsonService.isValidGeoJson(content)) {
					$scope.geoJsonFileContent = content;
					$scope.filename = file.name;
					$scope.$apply();
				} else {
					toastr.error('file is not a valid geojson');
				}
			};

			reader.readAsText(file);
		};

		$scope.save = function () {
			if ($scope.cantSave()) {
				return;
			}

			$scope.sending = true;

			MapOverlays.add(
				{
					title: $scope.title,
					description: $scope.description,
					hidden: $scope.hidden,
					map_overlay: $scope.geoJsonFileContent,
					filename: $scope.filename
				},
				function (result) {
					if (result.success) {
						toastr.success('Map overlay was added successfully!');
						$uibModalInstance.close('added');
					} else {
						$scope.sending = false;
						toastr.error(result.message);
					}
				},
				function (errorResult) {
					$scope.sending = false;
					toastr.error('Error - missing data or bad format');
				}
			);
		};

		$scope.cantSave = function () {
			return _.isEmpty($scope.title) || _.isEmpty($scope.filename) || $scope.sending;
		};
	});
