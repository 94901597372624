'use strict';

angular
	.module('bringgApp')
	.factory('BecomeDeveloperForm', function ($resource, WEB_API_URL) {
		var BecomeDeveloperForm = $resource(
			WEB_API_URL + '/become_developer_form',
			{},
			{
				create: { method: 'POST', url: WEB_API_URL + '/become_developer_form' }
			}
		);

		return BecomeDeveloperForm;
	})
	.factory('BecomeDeveloperService', function ($uibModal, toastr, $q, BecomeDeveloperForm) {
		var BecomeDeveloperService = {};
		BecomeDeveloperService._deferred = null;
		BecomeDeveloperService.open = function () {
			BecomeDeveloperService._deferred = $q.defer();
			var modalInstance = $uibModal.open({
				backdrop: 'static',
				keyboard: true,
				controller: 'BecomeDeveloperController',
				templateUrl: 'scripts/features/become_developer/become-developer.html'
			});

			modalInstance.result.then(
				function (result) {
					BecomeDeveloperService._deferred.resolve(result);
				},
				function (reason) {
					BecomeDeveloperService._deferred.reject();
				}
			);

			return BecomeDeveloperService._deferred.promise;
		};

		BecomeDeveloperService._submitDeferred = null;

		BecomeDeveloperService.submit = function (info) {
			BecomeDeveloperService._submitDeferred = $q.defer();
			var form = new BecomeDeveloperForm(info);
			form.$create(function (result) {
				if (result.success) {
					BecomeDeveloperService._deferred.resolve(result);
					BecomeDeveloperService._submitDeferred.resolve(result);
				} else {
					BecomeDeveloperService._deferred.reject();
					BecomeDeveloperService._submitDeferred.reject();
				}
			});

			return BecomeDeveloperService._submitDeferred.promise;
		};

		return BecomeDeveloperService;
	});
