'use strict';
/* global google:false */
angular
	.module('bringgApp')
	.controller(
		'AddCustomerController',
		function ($rootScope, $scope, $uibModalInstance, toastr, Lightbox, Customers, Geolocation) {
			$scope.handleAddressSelected = function (item, model) {
				if (item) {
					$scope.lat = item.geometry.location.lat();
					$scope.lng = item.geometry.location.lng();
					$scope.address = item.formatted_address;
					$scope.ok = true;
				} else {
					$scope.lat = null;
					$scope.lng = null;
					$scope.address = null;
					$scope.ok = false;
				}
			};

			var geocoder = new google.maps.Geocoder();

			$scope.close = function () {
				$uibModalInstance.dismiss();
			};

			$scope.sending = false;

			$scope.save = function () {
				var customer = {
					name: $scope.customerName,
					external_id: $scope.externalId,
					address: $scope.address,
					phone: $scope.customerPhone,
					email: $scope.customerEmail,
					image: $scope.customerImage,
					address_second_line: $scope.customerAddressSecondLine,
					kind: $scope.customerKind,
					lat: $scope.lat,
					lng: $scope.lng,
					rank: $scope.customerRank
				};

				$scope.sending = true;
				Customers.create(
					customer,
					function (result) {
						if (result.success) {
							toastr.success('Customer was added successfully!');
							$rootScope.$broadcast('newCustomerAdded', result.customer);
							$uibModalInstance.close();
						} else {
							$scope.sending = false;
							toastr.error(result.message);
						}
					},
					function (errorResult) {
						$scope.sending = false;
						toastr.error('Error - missing data or bad format');
					}
				);
			};

			$scope.handleSeeInMapClick = function () {
				Lightbox.openModal(
					[{ url: Geolocation.generateStaticMap($scope.lat, $scope.lng, $scope.lat, $scope.lng) }],
					0
				);
			};

			$scope.customerKinds = Customers.getCustomerKinds();
		}
	);
