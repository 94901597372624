'use strict';

angular.module('bringgApp').service('AlertsPopoverService', function ($uibModal, $window) {
	var AlertsPopoverService = {};
	AlertsPopoverService.popoverShown = false;

	AlertsPopoverService.showPopover = function (event) {
		if (AlertsPopoverService.popoverShown) {
			return;
		}
		AlertsPopoverService.popoverShown = true;
		var modalInstance = $uibModal.open({
			backdrop: true,
			keyboard: true,
			templateUrl: 'scripts/features/alerts_popover/alerts-popover.html',
			controller: 'alertsPopoverController',
			windowClass: 'alerts-popover-class',
			backdropClass: 'alerts-popover-backdrop-class',
			animation: true,
			resolve: {
				ypos: function () {
					return event.clientY;
				},
				xpos: function () {
					return event.clientX;
				}
			}
		});

		modalInstance.result.then(
			function () {
				AlertsPopoverService.popoverShown = false;
			},
			function () {
				AlertsPopoverService.popoverShown = false;
			}
		);
	};

	return AlertsPopoverService;
});
