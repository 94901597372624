'use strict';
/*global jQuery:false */

angular
	.module('bringgApp.services')
	.factory(
		'reportsSerivce',
		function ($http, WEB_API_URL, $rootScope, AvailableReportsService, $log, CancelTaskService, $translate) {
			var reports = [];
			var availableReports = AvailableReportsService.availableReports;

			var getNewReportsList = function () {
				return $http({
					method: 'GET',
					cache: true,
					url: WEB_API_URL + '/reports/list'
				});
			};

			var getReportDetails = function (id) {
				return _.find(availableReports, function (report) {
					return report.id === id;
				});
			};
			var getReportSingleDetail = function (id, field) {
				var details = getReportDetails(id);
				if (_.isUndefined(details) || _.isNull(details)) {
					return null;
				}
				return details[field];
			};

			var getReportsPath = function () {
				return '/reports/';
			};

			/**
			 * init cancel task service
			 */
			CancelTaskService._getReasons().then(function (reasonsData) {
				CancelTaskService._reasons = reasonsData;
			});

			var generateErrorMessage = function (response) {
				var errorMsg = $translate.instant('REPORTS.REPORT_GENERATION_FAILED');
				var reason;

				switch (response.rc) {
					case 1:
						reason =
							response.usage > 1
								? $translate.instant('REPORTS.USER_OVER_QUOTA_MULTIPLE', response)
								: $translate.instant('REPORTS.USER_OVER_QUOTA_SINGLE');
						break;
					case 2:
						reason = $translate.instant('REPORTS.MERCHANT_OVER_QUOTA', response);
						break;
					case 3:
						reason = $translate.instant('REPORTS.MERCHANT_OVER_QUOTA', response);
						break;
					case 4:
						reason = $translate.instant('REPORTS.INVALID_REPORT_PARAMS', response);
						break;
					case 5:
						reason = $translate.instant('REPORTS.ALREADY_IN_PROCESS');
						break;
					default:
						reason = response.message || '';
				}

				if (reason) {
					return errorMsg + ': ' + reason;
				}
				return errorMsg;
			};

			var trimExcelMacros = function (string) {
				var symbols = ['=', '+', '-', '@'];
				var trimmedString = string.trim();

				_.each(symbols, function (symbol) {
					// avoid adding '-' to  --:--
					if (trimmedString.charAt(0) === symbol && trimmedString.charAt(1) !== symbol) {
						trimmedString = trimmedString.replace(symbol, "'" + symbol + "'");
					}
				});

				return trimmedString;
			};

			return {
				reports: reports,
				generateErrorMessage: generateErrorMessage,
				getNewReportsList: getNewReportsList,
				trimExcelMacros: trimExcelMacros,
				table2CSV: function (table, defaultOptions) {
					var options = jQuery.extend(
						{
							separator: ',',
							header: [],
							exportHeader: true,
							delivery: 'popup' // popup, value
						},
						defaultOptions
					);

					var csvData = [];
					var headerArr = [];
					var el = table;

					//header
					var numCols = options.header.length;
					var tmpRow = []; // construct header available array

					function row2CSV(tmpRow) {
						var tmp = tmpRow.join(''); // to remove any blank rows
						// alert(tmp);
						if (tmpRow.length > 0 && tmp !== '') {
							var mystr = tmpRow.join(options.separator);
							csvData[csvData.length] = mystr;
						}
					}

					function formatData(input) {
						// replace " with â€œ
						var regexp = new RegExp(/["]/g);
						var output = input.replace(regexp, 'â€œ');
						//HTML
						regexp = new RegExp(/\<[^\<]+\>/g);
						output = output.replace(regexp, '');
						if (output === '') {
							return '';
						}

						output = trimExcelMacros(output);

						return '"' + output.trim() + '"';
					}

					function popup(data) {
						var generator = window.open('', 'csv', 'height=400,width=600');
						generator.document.write('<html><head><title>CSV</title>');
						generator.document.write('</head><body >');
						generator.document.write('<textArea cols=70 rows=15 wrap="off" >');
						generator.document.write(data);
						generator.document.write('</textArea>');
						generator.document.write('</body></html>');
						generator.document.close();
						return true;
					}

					if (options.exportHeader) {
						if (options.perLineFirstColumnHeader) {
							tmpRow[tmpRow.length] = '"' + options.perLineFirstColumnHeader + '"';
						}
						if (numCols > 0) {
							for (var i = 0; i < numCols; i++) {
								tmpRow[tmpRow.length] = formatData(options.header[i]);
							}
						} else {
							$(el)
								.find('th')
								.each(function () {
									tmpRow[tmpRow.length] = formatData($(this).html());
								});
						}
					}

					row2CSV(tmpRow);

					// actual data
					$(el)
						.find('tr')
						.each(function () {
							var tmpRow = [];
							if (options.perLineFirstColumn) {
								tmpRow.push('"' + options.perLineFirstColumn + '"');
							}
							$(this)
								.find('td')
								.each(function () {
									tmpRow[tmpRow.length] = formatData($(this).html());
								});
							// if all that is in the row is the extea field we added, remove it
							if (options.perLineFirstColumn && tmpRow.length === 1) {
								tmpRow = [];
							}
							row2CSV(tmpRow);
						});
					var mydata;
					if (options.delivery === 'popup') {
						mydata = csvData.join('\n');
						return popup(mydata);
					} else {
						mydata = csvData.join('\n');
						return mydata;
					}
				},
				availableReports: function () {
					return availableReports;
				},
				get: function (index) {
					return reports[index];
				},
				getReportDetails: getReportDetails,
				getTitle: function (id) {
					return getReportSingleDetail(id, 'title');
				},
				getHeader: function (id) {
					return getReportSingleDetail(id, 'headerTemplateUrl');
				},
				getFooter: function (id) {
					return getReportSingleDetail(id, 'footerTemplateUrl');
				},
				getSubFooter: function (id) {
					return getReportSingleDetail(id, 'subFooterTemplateUrl');
				},
				getDataRowTemplateUrl: function (id) {
					return getReportSingleDetail(id, 'dataRowTemplateUrl');
				},
				getSubHeader: function (id) {
					return getReportSingleDetail(id, 'subHeaderTemplateUrl');
				},
				getSubField: function (id) {
					return getReportSingleDetail(id, 'subField');
				},
				getFields: function (id) {
					return getReportSingleDetail(id, 'fields');
				},
				getTemplateUrl: function (id) {
					return getReportSingleDetail(id, 'templateUrl');
				},
				generateReport: function (options) {
					var startDate = null,
						endDate = null,
						selectedEmployees = [],
						selectedTeams = [],
						selectedTags = [];

					$rootScope.$broadcast('generating report', reports.length - 1);

					if (!_.isUndefined(options.timePeriod) && !_.isEmpty(options.timePeriod)) {
						var seperator_pos = options.timePeriod.indexOf(' - '),
							start_date = options.timePeriod.substr(0, seperator_pos),
							end_date = options.timePeriod.substr(seperator_pos + 3);

						// date filters
						startDate = start_date && !isNaN(Date.parse(start_date)) ? Date.parse(start_date) : 0;
						endDate =
							end_date && !isNaN(Date.parse(end_date)) ? Date.parse(end_date) : new Date().getTime();
					}

					selectedTags = _.map(options.tags, 'tag');

					return $http
						.post(WEB_API_URL + getReportsPath(), {
							reports_list: _.map(options.reportsList, 'id'),
							start_date: startDate,
							end_date: endDate,
							date_range: options.dateRange,
							all_employees: options.allEmployees,
							all_teams: options.allTeams,
							short_code: options.shortCode,
							employee_ids: options.employee_ids,
							team_ids: options.team_ids,
							tags: selectedTags
						})
						.success(function (response) {
							if (!response.success) {
								var errorMsg = generateErrorMessage(response);
								$log.error(errorMsg);
								$rootScope.$broadcast('report error', errorMsg);
								return;
							}
							// the following code is for backward compatibility, will be removed once new rails is deployed
							var savedReport = response.saved_report;

							var meta = {
								timePeriod: options.timePeriod,
								shortCode: savedReport.short_code,
								all_employees: savedReport.all_employees,
								all_teams: savedReport.all_teams,
								team_ids: savedReport.team_ids,
								employee_ids: savedReport.employee_ids,
								date_range: savedReport.date_range,
								endDate: moment(savedReport.end_date).unix(),
								startDate: moment(savedReport.start_date).unix()
							};

							if (options.shortCode) {
								reports.push({
									meta: _.extend(meta, { id: response.report_id }),
									data: _.isObject(response.result) ? response.result : JSON.parse(response.result)
								});
							} else {
								reports.push({
									meta: _.extend(meta, { id: options.reportsList[0].id }),
									data: _.isObject(response.result) ? response.result : JSON.parse(response.result)
								});
							}
							$log.log('Report generation submitted');
							$rootScope.$broadcast('report ready', reports.length - 1);
							$rootScope.$broadcast('saved report received', savedReport);
						})
						.error(function (data, status) {
							var errorMsg;
							if (data && data.message) {
								errorMsg = 'Report generation failed with ' + status + ': ' + data.message;
							} else {
								errorMsg = 'Report generation failed with [' + status + ']';
							}
							$log.error(errorMsg);
							$rootScope.$broadcast('report error', errorMsg);
						});
				}
			};
		}
	);
