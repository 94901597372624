'use strict';

angular.module('bringgApp').factory('CrewsService', function ($rootScope, BringgSDK) {
	function crewsService(method) {
		return function () {
			var args = arguments;
			return BringgSDK.getInstancePromise().then(function (bringg) {
				return bringg.crews[method].apply(bringg.crews, args);
			});
		}.bind(this);
	}

	var Crews = {};

	Crews.getAllByTeam = crewsService('getAllByTeam');

	return Crews;
});
