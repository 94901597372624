'use strict';
/*global google:false */
/* global getkeyBy:false */

angular
	.module('bringgApp')
	.controller(
		'PresentController',
		function (
			$scope,
			$location,
			$routeParams,
			Task,
			Employees,
			bringg_utils,
			Teams,
			localStorageService,
			Authentication,
			toastr,
			MerchantService,
			$log,
			MerchantConfigurations,
			Application,
			TasksModalService,
			PRIVILEGES_TYPES,
			$injector
		) {
			function initStatusConfirmationService() {
				$injector.get('StatusConfirmationService');
			}

			initStatusConfirmationService();

			$scope.currentDate = Date.now();
			$scope.gaPage = 'DispatchView';
			$scope.enableTeams = MerchantConfigurations.enable_teams || false;
			$scope.useReactDriverList = undefined;

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.useReactDriverList = featureFlags.use_react_driver_list_in_map || false;
			});

			$scope.enableRouteOptimization =
				_.some(MerchantConfigurations.applications, Application.isRouteOptimizer) &&
				Authentication.currentUser().has_access(PRIVILEGES_TYPES.OPTIMIZE_ORDERS);

			if (Authentication.currentUser()) {
				$scope.currentUser = Authentication.currentUser();
				$scope.readonly = Authentication.currentUser().partner_user;
			} else {
				$location.path('/login/');
			}

			if ($location.path() === '/present/') {
				var last_known_location = localStorageService.get('last_known_location');

				if (last_known_location) {
					$scope.showMap = last_known_location === 'map' ? true : false;
					$location.path(last_known_location);
					$location.replace();
				} else {
					$location.path('/map');
					$location.replace();
				}
			} else {
				if ($routeParams.employee) {
					$scope.showMap = true;
				} else {
					if ($routeParams.map && $routeParams.map === 'false') {
						$scope.showMap = false;
						// default
					} else {
						$scope.showMap = $location.path() === '/map/';
					}
				}
			}

			$scope.$on('employeeSelected', function (event, args) {
				$scope.selectedEmployee = args;
			});

			$scope.clearAllOrders = function () {
				if (confirm('Are you sure you want to cancel ALL orders?')) {
					if (confirm('Making sure, are you really sure you want to cancel ALL orders?')) {
						Task.cancelAll(
							function (data) {
								if (!data.success) {
									console.error(data.message);
									toastr.error('Failed cancelling all orders');
								} else {
									toastr.success('All orders cancelled');
								}
							},
							function (error) {
								toastr.error('Failed cancelling all orders');
							}
						);
					}
				}
			};

			$scope._resizeMapFunction = function () {
				setTimeout(function () {
					var myMap = document.getElementById('map_canvas');
					if (!_.isUndefined(google.maps.event) && myMap) {
						google.maps.event.trigger(myMap, 'resize');
					}
				}, 20);
			};
			$scope.$watch('showMap', $scope._resizeMapFunction);

			$scope.getStatus = bringg_utils.getStatusFromTask;

			$scope.addTaskCSV = function () {
				if ($scope.readonly) {
					return;
				}

				MerchantService.get().then(function (data) {
					//if (data.unpaid) {
					//  ga('send', 'event', 'addTaskStopped', 'unpaidMerchant');
					//  toastr.error('Sorry but it seems you have a suspended account. Please contact our support team at support@bringg.com');
					//} else {

					TasksModalService.addTaskCSV();
					//}
				});
			};

			$scope.addTask = function () {
				if ($scope.readonly) {
					return;
				}

				TasksModalService.addTask($scope.selectedEmployee);
			};

			$scope.nonDefaultTeamsExist = false;

			function add_task_to_team(task) {
				if ($scope.enableTeams) {
					task.team_id = bringg_utils.getActiveTeam(task);
				} else {
					task.team_id = 0;
				}

				if (_.isUndefined($scope.team_tasks[task.team_id])) {
					$scope.team_tasks[task.team_id] = [];
				}
				$scope.team_tasks[task.team_id].push(task);
			}

			function remove_task_from_team(task) {
				if (!task.team_id) {
					task.team_id = bringg_utils.getActiveTeam(task);
				}
				if (_.isUndefined($scope.team_tasks[task.team_id])) {
					return;
				}
				var foundTaskIdx = getkeyBy($scope.team_tasks[task.team_id], 'id', task.id);

				if (foundTaskIdx !== -1) {
					$scope.team_tasks[task.team_id].splice(foundTaskIdx, 1);
				}
			}

			$scope.isDone = Task.isDone;

			function resetTaskList(tasks) {
				if (!tasks || !tasks.$resolved) {
					return;
				}
				$scope.team_tasks = [];

				tasks.forEach(function (task) {
					if (localStorageService.get('followed_tasks:' + task.id) === 'true') {
						task.followed = true;
					}
					add_task_to_team(task);
				});
				$scope.$broadcast('task list changed');
				$scope.totalTasks = tasks.length;
			}

			$scope.$on('config updated', function () {
				$scope.enableTeams = MerchantConfigurations.enable_teams || false;
				$scope.massActionData = [{ id: -1, name: 'Unassigned' }];
				$scope.smartGrouping = { 0: false };
				if ($scope.enableTeams) {
					Teams.all(function (teams) {
						$scope.teams = teams;
						if (teams.length > 1) {
							$scope.nonDefaultTeamsExist = true;
							teams.forEach(function (team) {
								$scope.massActionData[team.id] = { id: -1, name: 'Unassigned' };
								$scope.smartGrouping[team.id] = false;
							});
						}
					});
				} else {
				}
			});

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (isLoggedIn) {
					Employees.drivers({}, function (result) {
						$scope.employees = result;
					});

					$scope.team_tasks = [];
					$scope.massActionData = [{ id: -1, name: 'Unassigned' }];
					$scope.smartGrouping = { 0: false };

					if ($scope.enableTeams) {
						Teams.all(function (teams) {
							$scope.teams = teams;

							if (teams.length > 1) {
								$scope.nonDefaultTeamsExist = true;
								teams.forEach(function (team) {
									$scope.massActionData[team.id] = { id: -1, name: 'Unassigned' };
									$scope.smartGrouping[team.id] = false;
								});
							}
						});
					} else {
						$scope.teams = [{ id: 0, name: 'Default Team' }];
					}
				}
			});

			$scope.assignEmployee = function (task, employee) {
				return Task.assignTask(task, employee.id).then(
					function (result) {
						if (task.user_id) {
							toastr.success('Task assigned.');
						} else {
							toastr.success('Task unassigned.');
						}
					},
					function (reason) {
						toastr.error(reason);
					}
				);
			};

			$scope.$on('$destroy', function iVeBeenDismissed() {
				$log.debug('PresentController destroyed');
			});
		}
	);
