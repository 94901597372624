'use strict';

angular.module('bringgApp').factory('CustomerConfiguration', function ($resource, WEB_API_URL, Application) {
	var customerConfiguration = $resource(
		WEB_API_URL + '/merchant/customer_configuration/',
		{},
		{
			update: { method: 'PUT' }
		}
	);

	customerConfiguration.installRequiredApplications = function (customerConfiguration) {
		var customerExperienceInstall = Promise.resolve();

		if (customerConfiguration?.alert_on_customer_pickup) {
			customerExperienceInstall = Application.isInstalledByUuid(
				Application.APPLICATIONS.CustomerExperienceApp
			).then(function (isInstalled) {
				if (!isInstalled) {
					return Application.addApplicationByUuid(Application.APPLICATIONS.CustomerExperienceApp);
				}
			});
		}

		return customerExperienceInstall;
	};

	return customerConfiguration;
});
