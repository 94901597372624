'use strict';

angular
	.module('bringgApp')
	.factory(
		'Application',
		function ($resource, WEB_API_URL, $q, MerchantConfigurationService, MerchantConfigurations, BringgSDK) {
			var Application = $resource(
				WEB_API_URL + '/applications/:id',
				{ id: '@id' },
				{
					all: { method: 'GET', url: WEB_API_URL + '/applications/', isArray: true },
					add: { method: 'POST', url: WEB_API_URL + '/merchant/applications/' },
					delete: { method: 'DELETE', url: WEB_API_URL + '/merchant/applications/:id' }
				}
			);

			function applicationService(method) {
				return function () {
					var args = arguments;
					return BringgSDK.getInstancePromise().then(function (bringg) {
						return bringg.applicationMerchantConfiguration[method].apply(
							bringg.applicationMerchantConfiguration,
							args
						);
					});
				};
			}

			Application.APPLICATIONS = {
				FailedDeliveryManager: 'de7f883b-bab4-4e25-a830-83bd1c5cb270',
				DetectDriverOffRoute: 'dd2ad97a-ffee-11e5-86aa-5e5517507c66',
				RouteOptimizer: '99826b69-28fe-4e58-874e-04c70b4f5dd0',
				RouteOptimizer2: '9dd3a937-b51e-41ed-afb4-6c3d97cdd620',
				ManagerAuthorizationRules: '2a634eb8-145a-4269-978c-515878beda80',
				RealtimeEta: '2fd6d6c6-f4b4-4b85-854c-4ee193512899',
				ETA: '9369f5d4-57d8-4806-b2c9-fcc6f4e51531',
				WebhookApp: '52228df2-2759-432d-8865-2593d2ba6c12',
				CustomerExperienceApp: 'caf4e836-e507-4f68-bb9c-c4511bb69750',
				CustomerExperienceWebApp: 'a4338daf-babe-4fc4-a2d4-9050da8c1782',
				FloatingInventoryApp: 'd7409473-fc0b-4d1e-a085-04634f5acd4f'
			};

			Application.isRouteOptimizer = function (application) {
				return (
					application.uuid === Application.APPLICATIONS.RouteOptimizer ||
					application.uuid === Application.APPLICATIONS.RouteOptimizer2
				);
			};
			Application.isRouteOptimizer2 = function (application) {
				return application.uuid === Application.APPLICATIONS.RouteOptimizer2;
			};
			Application.isInstalledByUuid = function (appUuid) {
				var deferred = $q.defer();

				MerchantConfigurationService.get().then(function (merchantConfigurations) {
					deferred.resolve(!!getApplication(appUuid, merchantConfigurations));
				});

				return deferred.promise;
			};

			Application.isInstalledByUuidSync = function (appUuid) {
				return !!getApplication(appUuid);
			};

			Application.addApplicationByUuid = applicationService('addApplicationByUuid');
			Application.getConfigByAppUuid = applicationService('getByAppUuid');
			Application.updateConfig = applicationService('updateConfig');

			function getApplication(appUuid, merchantConfigurations) {
				return _.find((merchantConfigurations || MerchantConfigurations).applications, { uuid: appUuid });
			}

			return Application;
		}
	);
