'use strict';

angular
	.module('bringgApp.directives')
	.directive('selectDrivers', function (Employees, $timeout, EmployeesTasks, $rootScope) {
		return {
			templateUrl: 'scripts/directives/select-drivers/select-drivers.html',
			scope: {
				driverId: '=',
				teamIds: '=',
				onDriverSelect: '=?',
				onOpenClose: '=?'
			},
			link: function ($scope, $elem) {
				$scope.unassignedDriver = { id: null, name: 'Unassigned' };
				$scope.drivers = [];
				$scope.selectedDriver = {};
				$scope.isOpened = true;
				$scope.loading = true;
				$scope.formatEmployeeSelection = function (employee) {
					return EmployeesTasks.formatEmployeeSelection(employee, $scope.teamIds);
				};

				function init() {
					Employees.drivers().then(function (drivers) {
						$scope.drivers = EmployeesTasks.allEmployeesWithUnassigned(drivers, $scope.teamIds, true);
						$scope.selectedDriver =
							_.find($scope.drivers, { id: $scope.driverId }) || $scope.unassignedDriver;
						$scope.loading = false;

						$timeout(function () {
							$scope.uiSelectScope = angular
								.element($($elem[0]).find('.ui-select-container'))
								.scope()
								.$select.toggle();
						}, 200);
					});
				}

				$rootScope.$on('employees list update', function () {
					Employees.drivers().then(function (drivers) {
						$scope.users = EmployeesTasks.allEmployeesWithUnassigned(drivers, $scope.teamIds, true);
					});
				});

				$scope.onDriverAutocompleteSelect = function (driver) {
					$scope.selectedDriver = driver;
					$scope.isOpened = false;
					$scope.onDriverSelect(driver);
				};

				$scope.onDropDownOpenClose = function (isOpen) {
					$scope.isOpened = isOpen;
					if (_.isFunction($scope.onOpenClose)) {
						$scope.onOpenClose(isOpen);
					}
				};

				init();
			}
		};
	});
