'use strict';
/*global _:false*/

angular
	.module('bringgApp')
	.factory(
		'IdentityProvider',
		function (
			$q,
			AuthenticationUtils,
			$rootScope,
			localStorageService,
			BringgSDK,
			$injector,
			$location,
			SingleSignOn,
			MainNavigationVer2Service,
			Authentication,
			MerchantConfigurations,
			appReadyIndicator
		) {
			var service;
			var LOGIN_ROUTE = '/login/';

			function tokenParams() {
				var path = $location.path();
				var params = $location.search();
				if (path === LOGIN_ROUTE && !_.isNil(params.authentication_token)) {
					return params;
				}
			}

			const oneTimeTokenType = 'one_time';
			function oneTimeCodeParams() {
				const path = $location.path();
				const params = $location.search();
				if (path === LOGIN_ROUTE && params.token_type === oneTimeTokenType) {
					return params;
				}
			}

			function oauthParams() {
				var path = $location.path();
				var params = $location.search();
				if (path === LOGIN_ROUTE && !_.isNil(params.code) && !_.isNil(params.session)) {
					return params;
				}
			}

			function errorCleanup() {
				localStorageService.clearAll();
				AuthenticationUtils.endPartialLogin();
				$rootScope._byTokenInProgress = null;
			}

			function isUserLoggedIn(force) {
				return $rootScope.sdk != null && !force;
			}

			function getPreCheckPromise() {
				let params = oneTimeCodeParams();
				if (!_.isNil(params)) {
					localStorageService.remove('impersonate_original_user');
					AuthenticationUtils.beginPartialLogin();
					return $q.resolve({
						token: params.authentication_token,
						secret: params.secret,
						region: params.region,
						next_url: params.next_url,
						token_type: params.token_type,
						merchant_uuid: params.merchant_uuid,
						need_reload: true
					});
				}

				params = tokenParams();

				if (!_.isNil(params)) {
					localStorageService.remove('impersonate_original_user');
					AuthenticationUtils.beginPartialLogin();
					return $q.resolve({
						next_url: params.next_url,
						region: params.region,
						token: params.authentication_token,
						merchant_uuid: params.merchant_uuid,
						need_reload: true
					});
				}

				params = oauthParams();
				if (!_.isNil(params)) {
					//pseudo-oauth
					localStorageService.remove('impersonate_original_user');
					AuthenticationUtils.beginPartialLogin();
					return $q.resolve({
						code: params.code,
						session: params.session,
						next_url: params.next_url,
						need_reload: true
					});
				}

				//look for cached user
				var local_user = localStorageService.get('overvyoo_user');
				if (local_user == null) {
					return $q.reject(null);
				}

				//we are in valid state, look for cache and set it temporary to make authorization check happy and then refresh cache
				AuthenticationUtils.endPartialLogin();
				return $q.resolve({
					region: local_user.region,
					token: local_user.authentication_token
				});
			}

			function afterLogin(bringgSdk, params) {
				if (localStorageService.get('impersonate_original_user')) {
					bringgSdk.session.user.beta = true;
					bringgSdk.session.user.debug = true;
				}

				AuthenticationUtils.setSdk(bringgSdk);

				return MerchantConfigurations.$refresh().then(() => {
					AuthenticationUtils.doLogin(bringgSdk.session.user);
					if (params.merchant_uuid) {
						SingleSignOn.enableSSOFlow(bringgSdk.session.merchant.uuid);
					}
					$rootScope._byTokenInProgress = null;

					AuthenticationUtils.endPartialLogin();
					$injector.get('StatusConfirmationService');
					return appReadyIndicator.whenReady().then(() => {
						return bringgSdk.session.user;
					});
				});
			}

			service = {
				byToken: function (force) {
					const deferred = $q.defer();

					if (isUserLoggedIn(force)) {
						AuthenticationUtils.endPartialLogin();
						return $q.resolve($rootScope.sdk.session.user);
					}

					if ($rootScope._byTokenInProgress != null) {
						return $rootScope._byTokenInProgress;
					}

					$rootScope._byTokenInProgress = deferred.promise;

					function catchHandler() {
						errorCleanup();
						deferred.resolve(null);
					}

					function afterSSOLogin(bringgSdk, params) {
						deferred.resolve(afterLogin(bringgSdk, params));

						if (params.next_url) {
							$location.path(params.next_url);
						} else if (params.need_reload) {
							if (Authentication.currentUser().feature_flags.redirect_after_sso_login) {
								$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
								$location.search('');
							} else {
								$location.path('/map/');
							}
						}
					}

					getPreCheckPromise()
						.then(function (params) {
							if (!_.isNil(params.code)) {
								return BringgSDK.loginWithSessionCode(params.code, params.session)
									.then(function (bringgSdk) {
										deferred.resolve(afterLogin(bringgSdk, params));

										if (params.next_url) {
											$location.path(params.next_url);
										} else if (
											Authentication.currentUser().feature_flags.redirect_after_sso_login
										) {
											$location.path(MainNavigationVer2Service.getFirstAvailableViewLocation());
											$location.search('');
										}
									})
									.catch(catchHandler);
							}

							if (params.token_type === oneTimeTokenType) {
								return BringgSDK.loginWithOneTimeCode(params.region, params.token, params.secret)
									.then(function (bringgSdk) {
										afterSSOLogin(bringgSdk, params);
									})
									.catch(catchHandler);
							}

							if (!_.isNil(params.token)) {
								return BringgSDK.loginWithToken(params.region, params.token)
									.then(function (bringgSdk) {
										afterSSOLogin(bringgSdk, params);
									})
									.catch(catchHandler);
							}
						})
						.catch(catchHandler);

					return deferred.promise;
				}
			};

			return service;
		}
	);
