angular.module('bringgApp').constant('PartialRunsSources', {
	MOVE_FROM_PLANNING_TO_DISPATCH: 0,
	MOVE_FROM_DISPATCH_TO_PLANNING: 1,
	OPEN_PREVIEW: 2
});

angular.module('bringgApp').constant('PartialRunsActions', {
	NONE: 0,
	SELECT_ALL_IN_RUN: 1,
	REMOVE_SELECTED_FROM_RUN: 2
});
