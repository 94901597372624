'use strict';

angular
	.module('bringgApp')
	.constant('DAY_WEEK_CONSTS', {
		HOURS_IN_WEEK: 168
	})
	.factory('MerchantShiftScheduleResource', function ($resource, WEB_API_URL) {
		var MerchantShiftScheduleResource = $resource(
			WEB_API_URL + '/merchant/:id/merchant_shifts_schedule/',
			{ id: '@id' },
			{
				update: { method: 'PUT', url: WEB_API_URL + '/merchant/:id/merchant_shifts_schedule/' }
			}
		);

		return MerchantShiftScheduleResource;
	})
	.factory(
		'MerchantShiftScheduleService',
		function (MerchantShiftScheduleResource, Authentication, $q, DAY_WEEK_CONSTS) {
			var MerchantShiftScheduleService = {};

			MerchantShiftScheduleService.get = function () {
				var deferred = $q.defer();

				MerchantShiftScheduleResource.get({ id: Authentication.currentUser().merchant_id }, function (result) {
					if (result.success) {
						deferred.resolve(result.shift_schedules);
					} else {
						deferred.reject(result);
					}
				});

				return deferred.promise;
			};

			MerchantShiftScheduleService.update = function (configuration) {
				var deferred = $q.defer();

				var newConfig = new MerchantShiftScheduleResource({
					id: Authentication.currentUser().merchant_id,
					configuration: configuration
				});
				newConfig.$update(
					function (data, status, headers, config) {
						deferred.resolve('Update saved, thank you');
					},
					function (data, status, headers, config) {
						deferred.reject('Failed updating merchant');
					}
				);

				return deferred.promise;
			};

			MerchantShiftScheduleService.translateToDb = function (uiShifts) {
				var shifts = [];
				var lunchBreaks = [];

				// for each day
				_.each(uiShifts, function (dayShifts) {
					var dayInHours = dayShifts.value * 24;
					// for each shift
					_.each(dayShifts.shifts, function (shift) {
						// filter disabled shifts
						if (!shift.enabled) {
							return;
						}
						// convert shifts and add them
						shifts.push({
							start_hour: shift.startHour + dayInHours,
							end_hour: shift.endHour + dayInHours
						});
					});

					// add lunch break if enabled
					if (dayShifts.lunchBreak && dayShifts.lunchBreak.enabled) {
						lunchBreaks.push({
							start_hour: dayShifts.lunchBreak.startHour + dayInHours,
							end_hour: dayShifts.lunchBreak.endHour + dayInHours
						});
					}
				});

				return {
					shifts: shifts,
					lunchBreaks: lunchBreaks
				};
			};

			MerchantShiftScheduleService.translateFromDb = function (dbShifts, dbScheduledBreaks) {
				var shiftsPerDay = _.map(_.range(7), function (dayNumber) {
					return {
						value: dayNumber,
						display: moment()
							.isoWeekday(dayNumber + 1)
							.format('dddd'), // isoWeekday() is between 1 and 7
						shifts: [],
						lunchBreak: null
					};
				});

				_.each(dbShifts, function (dbShift) {
					var shiftDay = (dbShift.start_hour / 24) >> 0;
					shiftsPerDay[shiftDay].shifts.push({
						startHour: dbShift.start_hour % 24,
						endHour: dbShift.end_hour % 24
					});

					// make sure the first shift of each day comes before the second one
					shiftsPerDay[shiftDay].shifts = _.sortBy(shiftsPerDay[shiftDay].shifts, 'startHour');
				});

				// assign scheduled breaks
				_.each(dbScheduledBreaks, function (dbScheduledBreak) {
					var breakDay = (dbScheduledBreak.start_hour / 24) >> 0;
					shiftsPerDay[breakDay].lunchBreak = {
						startHour: dbScheduledBreak.start_hour % 24,
						endHour: dbScheduledBreak.end_hour % 24
					};
				});

				return shiftsPerDay;
			};

			return MerchantShiftScheduleService;
		}
	);
