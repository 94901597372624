/*global google:false */
/*global clearMarkers:false */
/*global resizeMapByMarkers:false */

'use strict';

angular.module('bringgApp').service('BigMapService', function () {
	var self = {};

	self.getColors = function (length) {
		var result = [];
		var slice = 8.0;

		var hsv2rgb = function (h, s, v) {
			// var h = hsv.hue, s = hsv.sat, v = hsv.val;
			var rgb,
				i,
				data = [];
			if (s === 0) {
				rgb = [v, v, v];
			} else {
				h = h / 60;
				i = Math.floor(h);
				data = [v * (1 - s), v * (1 - s * (h - i)), v * (1 - s * (1 - (h - i)))];
				switch (i) {
					case 0:
						rgb = [v, data[2], data[0]];
						break;
					case 1:
						rgb = [data[1], v, data[0]];
						break;
					case 2:
						rgb = [data[0], v, data[2]];
						break;
					case 3:
						rgb = [data[0], data[1], v];
						break;
					case 4:
						rgb = [data[2], data[0], v];
						break;
					default:
						rgb = [v, data[0], data[1]];
						break;
				}
			}
			return rgb
				.map(function (x) {
					return ('0' + Math.round(x * 255).toString(16)).slice(-2);
				})
				.join('');
		};

		for (var i = 0; i < length; i += 1) {
			result.push(
				hsv2rgb(((i % slice) * 360) / Math.min(slice, length), 0.5 + 0.5 * (i / length), 1 - (i / length) * 0.9)
			);
		}

		return result;
	};

	self.getLabelColor = function (color) {
		var rgb = parseInt(color, 16);
		var r = (rgb >> 16) & 0xff;
		var g = (rgb >> 8) & 0xff;
		var b = (rgb >> 0) & 0xff;

		var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
		return luma < 120 ? 'FFFFFF' : '000000';
	};

	/**
	 * get list of tasks orderd by priority with old an new index
	 * then we return the list of needed changes
	 * @param tasksOrderedByPriority
	 * @param oldIndex
	 * @param newIndex
	 * @returns {{}}
	 */
	self.getPriorityChanges = function (tasksOrderedByPriority, oldIndex, newIndex) {
		var tasksOrderedByPriorityCloned = _.map(tasksOrderedByPriority || [], _.clone);

		if (!_.isUndefined(oldIndex) && !_.isUndefined(newIndex)) {
			tasksOrderedByPriorityCloned.move(oldIndex, newIndex);
		}

		// remove any 'undefined' by move to non existing index
		tasksOrderedByPriorityCloned = _.filter(tasksOrderedByPriorityCloned);

		var tasksPriorityChanges = _.map(tasksOrderedByPriorityCloned, function (task, index) {
			return {
				task_id: task.id,
				// set priority from 1
				priority: index + 1
			};
		});

		// filter all those who already have this priority
		tasksPriorityChanges = _.filter(tasksPriorityChanges, function (taskPriorityChange) {
			var task = _.find(tasksOrderedByPriorityCloned, { id: taskPriorityChange.task_id });
			return task.priority !== taskPriorityChange.priority;
		});

		return tasksPriorityChanges;
	};

	return self;
});
