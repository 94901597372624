'use strict';
angular
	.module('bringgApp')
	.service('TasksData', function (Task, BringgSDK, GroupedTasksService, TaskGroupTypes, CollapsedLinkedTasksService) {
		const _this = this;
		_this.getUnacknowledged = getUnacknowledged;
		_this.setUnacknowledged = setUnacknowledged;
		_this.addUnacknowledged = addUnacknowledged;
		_this.getFollowersCount = getFollowersCount;
		_this.setFollowersCount = setFollowersCount;
		_this.updateFollowersCount = updateFollowersCount;
		_this.calcFollowersForTasks = calcFollowersForTasks;
		_this.getFollowers = getFollowers;
		_this.updateFollowers = updateFollowers;
		_this.getOpenTasks = getOpenTasks;
		_this.getPlanningTasks = getPlanningTasks;
		_this.getInvalidTasks = getInvalidTasks;
		_this.getMap = getMap;
		_this.get = get;
		_this.isOpenTask = isOpenTask;
		_this.isPlanningTask = isPlanningTask;

		let _unacknowledgedTasks = [];
		let _followersCount;
		let _followers = {};
		let _openTasks = [];
		let _planningTasks = [];
		let _invalidTasks = [];
		let _tasksById = {};

		function getUnacknowledged() {
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('main', 5);
			} else {
				return _unacknowledgedTasks;
			}
		}

		function setUnacknowledged(unacknowledgedTasks) {
			if (!Task.shouldUseNewApi()) {
				_unacknowledgedTasks = unacknowledgedTasks;
			}
		}

		function addUnacknowledged(task) {
			if (!Task.shouldUseNewApi()) {
				_unacknowledgedTasks.push(task);
			}
		}

		function getFollowersCount(taskId) {
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('followers', taskId).length;
			} else {
				return _followersCount && _followersCount[taskId];
			}
		}

		function setFollowersCount(followersCount) {
			if (Task.shouldUseNewApi()) {
				return;
			}

			if (!_followersCount || !followersCount) {
				_followersCount = followersCount;
			} else {
				for (const groupLeaderId of Object.keys(followersCount)) {
					_followersCount[groupLeaderId] =
						(_followersCount[groupLeaderId] || 0) + followersCount[groupLeaderId];
				}
			}
		}

		function calcFollowersForTasks(tasks) {
			if (Task.shouldUseNewApi()) {
				return;
			}

			const followersCount = _.countBy(tasks, function (task) {
				return task.group_leader_id;
			});

			delete followersCount[null];
			setFollowersCount(followersCount);
		}

		function updateFollowersCount(newTask, shouldRemove) {
			if (Task.shouldUseNewApi()) {
				return;
			}

			_followersCount = _followersCount || {};

			if (newTask.group_leader_id && !_followersCount[newTask.group_leader_id]) {
				_followersCount[newTask.group_leader_id] = 0;
			}

			if (shouldRemove) {
				if (_followersCount[newTask.group_leader_id] > 0) {
					_followersCount[newTask.group_leader_id]--;
				}
			} else {
				if (newTask.group_leader_id) {
					_followersCount[newTask.group_leader_id]++;
				}

				const currentGroupLeaderId = _followers[newTask.id]?.group_leader_id;

				if (currentGroupLeaderId && _followersCount[currentGroupLeaderId]) {
					_followersCount[currentGroupLeaderId]--;
				}
			}
		}

		function getFollowers() {
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('main', 'followers');
			} else {
				return _followers;
			}
		}

		function updateFollowers(task) {
			if (Task.shouldUseNewApi()) {
				return;
			}

			if (GroupedTasksService.isFollower(task)) {
				if (Task.isDone(task)) {
					delete _followers[task.id];
				} else {
					_followers[task.id] = task;
				}
			} else if (_followers[task.id]) {
				delete _followers[task.id];
			}
		}

		function getOpenTasks(groupingType) {
			if (shouldGetCollapseTasks(groupingType)) {
				return CollapsedLinkedTasksService.getDispatchTasks();
			}
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('main', 1);
			}
			return _openTasks;
		}

		function shouldGetCollapseTasks(groupingType) {
			return (
				(groupingType === TaskGroupTypes.TeamRoute || groupingType === TaskGroupTypes.Route) &&
				CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()
			);
		}

		function getPlanningTasks(groupingType) {
			if (shouldGetCollapseTasks(groupingType)) {
				return CollapsedLinkedTasksService.getPlanningTasks();
			}
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('main', 2);
			}
			return _planningTasks;
		}

		function getInvalidTasks() {
			if (Task.shouldUseNewApi()) {
				return Task.getGroup('main', 4);
			} else {
				return _invalidTasks;
			}
		}

		function getMap() {
			if (Task.shouldUseNewApi()) {
				return Task.getAllById();
			} else {
				return _tasksById;
			}
		}

		function get(taskId) {
			if (Task.shouldUseNewApi()) {
				return Task.getFromStore(taskId);
			} else {
				return _tasksById[taskId];
			}
		}

		function isOpenTask(taskId) {
			if (Task.shouldUseNewApi()) {
				return Task.isInGroup(taskId, 'main', 1);
			} else {
				return _.some(_openTasks, { id: taskId });
			}
		}

		function isPlanningTask(taskId) {
			if (Task.shouldUseNewApi()) {
				return Task.isInGroup(taskId, 'main', 2);
			} else {
				return _.some(_planningTasks, { id: taskId });
			}
		}
	});
