'use strict';

angular
	.module('bringgApp')
	.factory(
		'SlickGridConfigService',
		function (
			Authentication,
			MerchantConfigurations,
			MerchantConfigurationService,
			toastr,
			$translate,
			$log,
			localStorageService,
			CustomAttributesService,
			PRIVILEGES_TYPES,
			GRID_DATA,
			GridDefaultColumnsService,
			PresetViewsService
		) {
			var SlickGridConfigService = {};

			SlickGridConfigService.handleColumnsUpdateFailed = function (reason) {
				toastr.error('Failed updating task fields settings.');
				$log.error('[customizeListColumns.update] task fields failed with error:' + reason);
			};

			SlickGridConfigService.tableColumnsUpdated = function (configName, columnsList, targetGridId, onSave) {
				// Note: Columns related to custom attributes which are not present anymore shouldn't be present here so far
				// as they are removed when column picker options are generated
				// if something is not customized there is no need to save all data

				var savedList = _.chain(columnsList)
					.each(function (column, index) {
						column.position = index;
					})
					.map(function (column) {
						return _.pick(
							column,
							'id',
							'active',
							'position',
							'title',
							'filter',
							'translate',
							'postDefaultFilter',
							'width',
							'customAttributeId'
						);
					})
					.valueOf();

				if (PresetViewsService.enabledForConfigKey(configName)) {
					PresetViewsService.setPresetColumns(configName, savedList, onSave);
				} else if (Authentication.currentUser().admin) {
					SlickGridConfigService.updateMerchantConfiguration(configName, savedList, onSave);
				} else {
					SlickGridConfigService.updateTableFields(targetGridId, savedList, onSave);
				}
			};

			SlickGridConfigService.updateMerchantConfiguration = function (configName, savedList, onSave) {
				MerchantConfigurations[configName] = savedList;

				MerchantConfigurationService.update(MerchantConfigurations)
					.then(function () {
						MerchantConfigurations.$refresh().then(function () {
							onSave();
							toastr.success(
								$translate.instant('MERCHANT_SPECIFIC_CONFIGURATION.UPDATES_SAVED_THANK_YOU')
							);
						});
					})
					.catch(SlickGridConfigService.handleColumnsUpdateFailed);
			};

			SlickGridConfigService.updateTableFields = function (targetGridId, savedList, onSave) {
				MerchantConfigurationService.dashboardTableConfigurationUpdate(targetGridId, 'fields', savedList)
					.then(function () {
						onSave();
						toastr.success($translate.instant('MERCHANT_SPECIFIC_CONFIGURATION.UPDATES_SAVED_THANK_YOU'));
					})
					.catch(SlickGridConfigService.handleColumnsUpdateFailed);
			};

			SlickGridConfigService.getMerchantLevelColumnsConfig = function (configName) {
				return MerchantConfigurations[configName];
			};

			SlickGridConfigService.getInitialColumnsConfig = function (configName) {
				return new Promise(function (resolve) {
					MerchantConfigurations.$refetch().then(function () {
						resolve(SlickGridConfigService.getGridColumns(configName));
					});
				});
			};

			SlickGridConfigService.saveSortData = function (gridId, sortOptions) {
				if (PresetViewsService.enabledForConfigKey(gridId)) {
					PresetViewsService.setPresetSortOptions(gridId, sortOptions);
				} else {
					localStorageService.set('grid.sorted.column.' + gridId, sortOptions);
					if (!Authentication.isImpersonatedMode()) {
						MerchantConfigurationService.dashboardTableConfigurationUpdate(
							gridId,
							'sortOptions',
							sortOptions
						);
					}
				}
			};

			SlickGridConfigService.getSortData = function (gridId) {
				var sortOptionsServer;
				if (PresetViewsService.enabledForConfigKey(gridId)) {
					sortOptionsServer = PresetViewsService.getPresetSortOptions(gridId);
					return sortOptionsServer || null;
				} else {
					sortOptionsServer = _.get(
						MerchantConfigurations,
						['dashboard_ui_configuration', 'table_configuration', gridId, 'sortOptions'],
						null
					);

					return sortOptionsServer || localStorageService.get('grid.sorted.column.' + gridId) || null;
				}
			};

			SlickGridConfigService.saveGrouping = function (gridId, localStorageKey, value) {
				if (PresetViewsService.enabledForConfigKey(gridId)) {
					PresetViewsService.setPresetGrouping(gridId, value);
				} else {
					localStorageService.set(localStorageKey, value);
					if (!Authentication.isImpersonatedMode()) {
						MerchantConfigurationService.dashboardTableConfigurationUpdate(gridId, 'groupMode', value);
					}
				}
			};

			SlickGridConfigService.getGrouping = function (gridId, localStorageKey) {
				if (PresetViewsService.enabledForConfigKey(gridId)) {
					return PresetViewsService.getPresetGrouping(gridId) || undefined;
				}
				var groupingModelServer = _.get(
					MerchantConfigurations,
					['dashboard_ui_configuration', 'table_configuration', gridId, 'groupMode'],
					null
				);

				return groupingModelServer || localStorageService.get(localStorageKey) || undefined;
			};

			SlickGridConfigService.getConfigFromLocalStorage = function (columnsKey) {
				return localStorageService.get(columnsKey);
			};

			SlickGridConfigService.getGridColumns = function (columnsKey) {
				return PresetViewsService.enabledForConfigKey(columnsKey)
					? PresetViewsService.getPresetColumns(columnsKey)
					: SlickGridConfigService.getMerchantLevelColumnsConfig(columnsKey);
			};

			/**
			 * update grid columns from settings
			 * @param columnsKey
			 */
			SlickGridConfigService.getColumnsFromMerchantConfigurations = function (columnsKey) {
				var defaultsColumns = GridDefaultColumnsService.getDefaultsColumns(columnsKey);
				var fields;

				if (
					Authentication.currentUser().admin ||
					!Authentication.currentUser().has_access(PRIVILEGES_TYPES.CUSTOMIZE_LIST_LAYOUT)
				) {
					fields = SlickGridConfigService.getGridColumns(columnsKey);
				} else {
					var userFields;
					var gridDescriptor = Object.values(GRID_DATA).find(function ({ fieldsAdminConfigName }) {
						return columnsKey === fieldsAdminConfigName;
					});

					// if there is a matching gridDescriptor, extract user level fields config
					if (gridDescriptor) {
						userFields =
							PresetViewsService.presetViewsEnable &&
							PresetViewsService.taskTableRelatedKeys.includes(columnsKey)
								? PresetViewsService.getPresetColumns(columnsKey)
								: _.get(MerchantConfigurations, [
										'dashboard_ui_configuration',
										'table_configuration',
										gridDescriptor.id,
										'fields'
								  ]);
					}
					// if no user level fields in config fallback to old schema from localStorage, admin fields is the last option
					fields =
						userFields ||
						localStorageService.get(columnsKey) ||
						SlickGridConfigService.getGridColumns(columnsKey);
				}

				// no settings to override
				if (!_.isArray(fields)) {
					_.each(defaultsColumns, function (column) {
						if (_.isUndefined(column.active)) {
							column.active = true;
						}
					});
					return defaultsColumns;
				}

				var mergedColumns = defaultsColumns;

				// filter \ update custom attribute related fields
				fields = CustomAttributesService.normalizeTaskFields(fields);

				// for each default column, merge update results from fields
				_.each(mergedColumns, function (column) {
					// extend the database settings for this one
					var columnMerchantConfig = _.find(fields, { id: column.id });
					_.extend(column, columnMerchantConfig || {});
				});

				// for each task_field thats not exists after merge, add it
				_.each(fields, function (field) {
					// extend the database settings for this one
					var isTaskFieldExists = _.some(mergedColumns, { id: field.id });
					if (!isTaskFieldExists) {
						mergedColumns.push(field);
					}
				});

				// total price in case enabled in merchant configurations
				if (!MerchantConfigurations.enable_task_price) {
					mergedColumns = _.without(mergedColumns, _.find(mergedColumns, { id: 'total_price' }));
				}
				// geohash
				var geohash = MerchantConfigurations.$rdsMode() && Authentication.currentUser().beta;
				if (!geohash) {
					// filter all geohash
					mergedColumns = _.filter(mergedColumns, function (column) {
						return !_.includes(['geohash_destination', 'geohash_origin', 'geohash'], column.id);
					});
				}

				return mergedColumns;
			};

			return SlickGridConfigService;
		}
	);
