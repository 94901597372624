/**
 * Created by liorsion on 5/3/16.
 */

'use strict';

angular
	.module('bringgApp')
	.controller('MerchantLogsController', function MerchantLogsController($scope, MerchantLog, $translate, toastr) {
		MerchantLog.all(
			function (response) {
				if (response.success) {
					$scope.logs = response.logs;
				} else {
					toastr.error('Failed getting merchant logs: ' + response.message);
				}
			},
			function (data, status, headers, config) {
				toastr.error('Failed loading logs');
			}
		);
	});
