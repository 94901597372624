'use strict';

angular.module('bringgApp').directive('optimizeSelectDeliveryTime', function ($translate, Tasks) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			result: '=',
			selectedDriversByTeam: '=',
			automaticOptimization: '=',
			selectedTasks: '=',
			preserveCurrentRuns: '=',
			tasksScheduledDate: '='
		},
		templateUrl: 'scripts/features/optimize/steps/select-delivery-time.html',
		link: function (scope) {
			scope.result.optimize_for = scope.result.optimize_for || 'all_open_tasks';
			scope.result.ui_period_times = scope.result.ui_period_times || {};

			/**
			 * when automaticOptimization is true, show only "next shift" , on any other case show only "custom"
			 */
			if (scope.automaticOptimization) {
				scope.result.optimize_for = 'next_shift';
			}

			scope.customDeliveryTime = {
				title: 'Custom',
				value: 'custom',
				text: $translate.instant('OPTIMIZE_TASKS.CUSTOM_TEXT'),
				custom_periods_settings: {
					hourStep: 1,
					minuteStep: 1,
					isMeridian: false
				},
				selected: scope.result.optimize_for === 'custom',
				disabled: scope.automaticOptimization
			};

			if (!_.isUndefined(scope.tasksScheduledDate)) {
				scope.customDeliveryTime.custom_periods = {
					start_period: moment(scope.tasksScheduledDate).startOf('day').toDate(),
					end_period: moment(scope.tasksScheduledDate).endOf('day').toDate()
				};
			} else {
				scope.customDeliveryTime.custom_periods = {
					start_period: moment(scope.result.ui_period_times.start_period).startOf('hour').toDate(),
					end_period: moment(scope.result.ui_period_times.start_period)
						.startOf('hour')
						.add(1, 'hour')
						.toDate()
				};
			}

			scope.shouldDisableDateAndTime = function () {
				return !scope.customDeliveryTime.selected || scope.preserveCurrentRuns;
			};

			scope.deliveryTimes = [
				{
					title: $translate.instant('OPTIMIZE_SELECTED_ROUTE.AUTOMATIC'),
					value: 'all_open_tasks',
					text: $translate.instant('OPTIMIZE_TASKS.AUTOMATIC_DESC'),
					disabled: true,
					selected: scope.result.optimize_for === 'all_open_tasks'
				},
				{
					title: $translate.instant('OPTIMIZE_SELECTED_ROUTE.TODAY'),
					value: 'today',
					text: $translate.instant('OPTIMIZE_TASKS.TODAY_DESC'),
					subtext: true,
					disabled: true,
					selected: scope.result.optimize_for === 'today'
				},
				{
					title: $translate.instant('OPTIMIZE_SELECTED_ROUTE.TOMORROW'),
					value: 'tomorrow',
					text: $translate.instant('OPTIMIZE_TASKS.TOMORROW_DESC'),
					subtext: true,
					disabled: true,
					selected: scope.result.optimize_for === 'tomorrow'
				},
				{
					title: $translate.instant('OPTIMIZE_SELECTED_ROUTE.NEXT_SHIFT'),
					value: 'next_shift',
					text: $translate.instant('OPTIMIZE_TASKS.NEXT_SHIFT_DESC'),
					subtext: true,
					disabled: !scope.automaticOptimization,
					selected: scope.result.optimize_for === 'next_shift'
				}
			];

			scope.updateDeliveryTime = function (selectedDeliveryTime) {
				_.each(scope.deliveryTimes, function (deliveryTime) {
					deliveryTime.selected = false;
				});
				scope.result.optimize_for = selectedDeliveryTime.value;
			};

			function handleCustomDateChange(newValue, oldValue) {
				if (newValue === oldValue) {
					return;
				}

				scope.selectDeliveryTime(scope.customDeliveryTime);
			}

			scope.$watch('customDeliveryTime.custom_periods.start_period', handleCustomDateChange);
			scope.$watch('customDeliveryTime.custom_periods.end_period', handleCustomDateChange);

			scope.selectDeliveryTime = function (selectedDeliveryTime) {
				_.each(scope.deliveryTimes, function (deliveryTime) {
					deliveryTime.selected = false;
				});
				scope.customDeliveryTime.selected = false;

				selectedDeliveryTime.selected = true;
				scope.result.optimize_for = selectedDeliveryTime.value;

				if (selectedDeliveryTime.value === 'custom') {
					var hoursInWeek = 7 * 24;
					var startTime = moment(selectedDeliveryTime.custom_periods.start_period),
						endTime = moment(selectedDeliveryTime.custom_periods.end_period);
					_.extend(scope.result, {
						custom_period: {
							start_hour: ((startTime.isoWeekday() - 1) * 24 + startTime.hour()) % hoursInWeek,
							end_hour: ((endTime.isoWeekday() - 1) * 24 + endTime.hour()) % hoursInWeek
						},
						ui_period_times: {
							start_period: selectedDeliveryTime.custom_periods.start_period,
							end_period: selectedDeliveryTime.custom_periods.end_period
						},
						scheduled_date: scope.datePicker.date
					});
				} else {
					delete scope.result.custom_period;
				}
			};

			var init = function () {
				var selectedDeliveryTime = _.find(scope.deliveryTimes, { selected: true }) || scope.customDeliveryTime;

				// if no selected delivery time is selected, take the first
				if (!selectedDeliveryTime) {
					selectedDeliveryTime = _.first(scope.deliveryTimes);
				}

				// in case user select 'today' or any other disabled item and then went back to check drivers
				if (selectedDeliveryTime.disabled) {
					selectedDeliveryTime = scope.customDeliveryTime;
				}

				scope.selectDeliveryTime(selectedDeliveryTime);
			};

			scope.datePicker = {
				format: 'yyyy-MM-dd',

				inlineOptions: {
					minDate: new Date(),
					showWeeks: true
				},

				dateOptions: {
					formatYear: 'yy',
					minDate: new Date(),
					startingDay: 1
				},

				open: function () {
					scope.datePicker.popup.opened = true;
				},

				popup: {
					opened: false
				}
			};

			if (scope.tasksScheduledDate) {
				scope.datePicker.date = scope.customDeliveryTime.custom_periods.start_period;
			} else {
				scope.datePicker.date = new Date();
			}

			init();
		}
	};
});
