'use strict';

angular
	.module('bringgApp.services')
	.constant('WINDOW_EVENT_TYPES', {
		RequestCredentials: 'request_creds',
		RequestInitialData: 'request_initial_data',
		Credentials: 'creds',
		InitialData: 'initial_date',
		LocationChanged: 'location_changed',
		OpenWindow: 'open_window'
	})
	.factory('WindowCommunicationService', function ($rootScope, ENV_CONFIGURATION, $timeout, WINDOW_EVENT_TYPES) {
		var WindowCommunicationService = {};
		var isDev =
			ENV_CONFIGURATION.environment === 'development' ||
			_.includes(ENV_CONFIGURATION.regions[ENV_CONFIGURATION.default_region].web_api_url, 'qa.bringg.com');

		var ORIGIN_SUFFIX = 'bringg.com';

		var messageHandler = function (currentWindow, type, data) {
			return function (event) {
				if (!event.data && !event.origin) {
					return;
				}
				if (!event.origin.endsWith(ORIGIN_SUFFIX) && !isDev) {
					var msg = 'Received unwanted message from ' + event.origin;
					console.error(msg);
					return;
				}

				if (event.data === type) {
					currentWindow.postMessage(data, '*');
				}
			};
		};

		var postMessage = function (currentWindow, type, data) {
			if (!currentWindow) {
				currentWindow = document.querySelector('iframe').contentWindow;
			}

			var messageEventHandler = messageHandler(currentWindow, type, data);

			window.addEventListener('message', messageEventHandler, false);

			setTimeout(function () {
				window.removeEventListener('message', messageEventHandler);
			}, 15000);
		};

		var sendCredentials = function (currentWindow) {
			postMessage(currentWindow, WINDOW_EVENT_TYPES.RequestCredentials, {
				type: WINDOW_EVENT_TYPES.Credentials,
				token: $rootScope.sdk.session.user.authentication_token.toString(),
				region: $rootScope.sdk.session.user.region.toString()
			});
		};

		var sendInitialData = function (currentWindow, data) {
			data.type = WINDOW_EVENT_TYPES.InitialData;
			postMessage(currentWindow, WINDOW_EVENT_TYPES.RequestInitialData, data);
		};

		var postGenericMessage = function (currentWindow, type, info) {
			currentWindow.postMessage(Object.assign({}, { type: type }, info), '*');
		};

		var subscribeOnGeneric = function (eventType, callback, responseType) {
			var onGenericHandler = function (event) {
				if (!event.data && !event.origin) {
					return;
				}
				if (!event.origin.endsWith(ORIGIN_SUFFIX) && !isDev) {
					return;
				}
				if (event.data.type === eventType) {
					var postResponseMessage = function (info) {
						return postGenericMessage(event.source, responseType || eventType, info);
					};
					callback(event.data, postResponseMessage);
				}
			};
			window.addEventListener('message', onGenericHandler, false);
			return function () {
				window.removeEventListener('message', onGenericHandler);
			};
		};

		var subscribeOnLocationChanges = function (callback) {
			return subscribeOnGeneric(WINDOW_EVENT_TYPES.LocationChanged, function (data) {
				callback(data.path);
			});
		};

		var subscribeOnOpenWindow = function (callback) {
			return subscribeOnGeneric(WINDOW_EVENT_TYPES.OpenWindow, function (data) {
				callback(data.path);
			});
		};

		var sendNewLocation = function (currentWindow, path) {
			postGenericMessage(currentWindow, WINDOW_EVENT_TYPES.LocationChanged, { path: path });
		};

		WindowCommunicationService.sendCredentials = sendCredentials;
		WindowCommunicationService.sendInitialData = sendInitialData;
		WindowCommunicationService.subscribeOnLocationChanges = subscribeOnLocationChanges;
		WindowCommunicationService.sendNewLocation = sendNewLocation;
		WindowCommunicationService.subscribeOnOpenWindow = subscribeOnOpenWindow;

		WindowCommunicationService.subscribeOnGeneric = subscribeOnGeneric;
		WindowCommunicationService.postGenericMessage = postGenericMessage;

		return WindowCommunicationService;
	});
