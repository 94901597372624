'use strict';

angular
	.module('bringgApp')
	.value('vehicleTypes', null)
	.controller('AssignToRouteModalController', function ($uibModalInstance, itemId, runId, teamIds, vehicleTypes) {
		var $ctrl = this;
		$ctrl.itemId = itemId;
		$ctrl.runId = runId;
		$ctrl.teamIds = teamIds;
		$ctrl.vehicleTypes = vehicleTypes;

		$ctrl.onSelect = function (selectedItem) {
			$uibModalInstance.close(selectedItem);
		};

		$ctrl.onSelectOpenClose = function (isOpen) {
			if (!isOpen) {
				$uibModalInstance.close();
			}
		};
	});
