'use strict';

/**
 * Limits decimal input after the point to two numbers
 * works on paste too
 * example: 6.67
 */
angular.module('bringgApp.directives').directive('smartFloat', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, ele, attrs, ctrl) {
			scope._handleKeyPress = function (e) {
				if (
					$(this)
						.val()
						.search(/(.*)\.[0-9][0-9]/) === 0
				) {
					e.preventDefault();
				}
			};
			scope.setView = function (ctrl, input) {
				ctrl.$setViewValue(input);
				ctrl.$render();
			};

			scope._handlePaste = function (e) {
				e.preventDefault();
				var data = e.originalEvent.clipboardData.getData('Text');
				var floated = parseFloat(data).toFixed(2);
				scope.setView(ctrl, floated);
			};

			ele.on('keypress', scope._handleKeyPress);
			ele.on('paste', scope._handlePaste);
		}
	};
});
