'use strict';

// angular.module('bringgApp').
// 	config(['$httpProvider', function($httpProvider) {
// 		authToken = $("meta[name=\"csrf-token\"]").attr("content");
// 		$httpProvider.defaults.headers.common["X-CSRF-TOKEN"] = authToken;
// 	}]);

angular.module('bringgApp').config(function ($sceDelegateProvider) {
	$sceDelegateProvider.resourceUrlWhitelist([
		// Allow same origin resource loads.
		'self',
		// Allow loading from our assets domain.  Notice the difference between * and **.
		'http://assets.overvyoo.com/**'
	]);
});

angular.module('bringgApp').config(function ($httpProvider) {
	$httpProvider.defaults.withCredentials = true;
	$httpProvider.defaults.useXDomain = true;
	$httpProvider.defaults.headers.common.Accept = 'application/bringg.client.v2';
	$httpProvider.defaults.headers.common.Client = 'Bringg Dashboard';
	delete $httpProvider.defaults.headers.common['X-Requested-With'];
});
