'use strict';

angular.module('bringgApp.directives').directive('weekShifts', function () {
	return {
		templateUrl: 'scripts/directives/shifts/week-shifts.html',
		require: 'ngModel',
		scope: {
			ngModel: '=',
			isDisabled: '='
		},
		link: function () {
			/***
			 * init function
			 */
			var init = function () {};

			init();
		}
	};
});
