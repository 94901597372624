'use strict';
/*global merge_objects:false*/

angular
	.module('bringgApp')
	.controller(
		'EmployeesController',
		function (
			$scope,
			Task,
			SocketPubSub,
			$uibModal,
			$rootScope,
			$filter,
			Tasks,
			MerchantConfigurations,
			Teams,
			bringg_utils,
			Employee,
			toastr,
			SendAnnouncmentService,
			$translate,
			$log,
			$timeout,
			ReactModals,
			TranslationService,
			Authentication
		) {
			var directionsDisplay;

			$scope.onNewTask = function (userTask) {
				if (!userTask.ready_to_execute) {
					return;
				}
				if (!userTask.team_id) {
					userTask.team_id = bringg_utils.getActiveTeam(userTask);
				}
				if (_.isNil($scope.team_tasks[userTask.team_id])) {
					$scope.team_tasks[userTask.team_id] = [];
				}
				if (_.isNil($scope.userTaskIds)) {
					$scope.userTaskIds = {};
				}

				$scope.team_tasks[userTask.team_id].push(userTask);
				$scope.userTaskIds[userTask.id] = null;
			};

			$scope.toggleLateList = true;
			$scope.toggleAssignedList = false;
			$scope.toggleCurrentList = false;
			$scope.toggleStartedList = false;
			$scope.showLatedTab = true;
			var queryWatcher;

			$scope.taskListHeaders = [
				{
					field: 'priority',
					title: $translate.instant('MAP_USER.PRIORITY'),
					templateUrl: 'views/tasks/cell_templates/task-extra.html'
				},
				{
					field: 'id',
					title: $translate.instant('MAP_USER.ID'),
					templateUrl: 'views/tasks/cell_templates/task-id.html'
				},
				{
					field: 'status',
					title: $translate.instant('MAP.STATUS'),
					templateUrl: 'views/tasks/cell_templates/task-status.html'
				},
				{
					field: 'title',
					title: $translate.instant('MAP_USER.ORDER'),
					templateUrl: 'views/tasks/cell_templates/task-title.html'
				},
				{
					field: 'address',
					title: $translate.instant('MAP_USER.DESTINATION'),
					templateUrl: 'views/tasks/cell_templates/task-address.html'
				},
				{
					field: 'scheduled_at',
					title: $translate.instant('MAP_USER.SCHEDULED_FOR'),
					templateUrl: 'views/tasks/cell_templates/task-schedule.html'
				},
				{
					field: 'user.name',
					title: $translate.instant('MAP_USER.DRIVER'),
					templateUrl: 'views/tasks/cell_templates/employee-assignment.html'
				}
			];

			function handleReply(eventDetails) {
				return function (data) {
					if (data.success) {
						toastr.success(eventDetails.successMessage);
					} else {
						toastr.error(eventDetails.failureMessage || data.message);
					}
				};
			}

			$scope.emailCurrentOrderList = function () {
				Employee.sendOrderList(
					{ id: $scope.selectedEmployee.id },
					handleReply({
						gaEvent: 'task_context_menu',
						gaEventValue: 'email_current_order_list',
						successMessage: 'Orders emailed successfully'
					}),
					function (data) {
						if (data.message) {
							toastr.error('Could not email order list:', data.message);
						} else {
							toastr.error('Could not email order list');
						}
					}
				);
			};

			$scope.finishAllActiveOrders = function () {
				if (confirm('Are you sure you want to finish all active orders?')) {
					Employee.endActiveOrders(
						{ id: $scope.selectedEmployee.id },
						handleReply({
							gaEvent: 'task_context_menu',
							gaEventValue: 'finish_active_orders',
							successMessage: 'Orders finished successfully'
						}),
						function (data) {
							if (data.message) {
								toastr.error('Could not finish all orders:', data.message);
							} else {
								toastr.error('Could not finish all orders');
							}
						}
					);
				}
			};

			$scope.localAutoRoute = function () {
				var directionsService = new google.maps.DirectionsService(),
					start = new google.maps.LatLng(
						MerchantConfigurations.lat || $scope.selectedEmployee.lat,
						MerchantConfigurations.lng || $scope.selectedEmployee.lng
					),
					end = start,
					employeeTasks = _.chain($scope.team_tasks).at($scope.employee.team_ids).flatten().compact().value(),
					wayPoints = _.map(employeeTasks, function (task) {
						if (task.way_points[0].lat && task.way_points[0].lng) {
							return {
								location: new google.maps.LatLng(task.way_points[0].lat, task.way_points[0].lng),
								stopover: true
							};
						} else {
							return null;
						}
					}),
					request = {
						origin: start,
						destination: end,
						waypoints: wayPoints,
						optimizeWaypoints: true,
						travelMode: google.maps.TravelMode.DRIVING
					};

				if (!wayPoints || _.isEmpty(wayPoints)) {
					toastr.warning('Found no way points to route.');
					return;
				}
				directionsService.route(request, function (optimizationResult, status) {
					if (status === google.maps.DirectionsStatus.OK) {
						directionsDisplay = new google.maps.DirectionsRenderer();
						directionsDisplay.setDirections(optimizationResult);
						directionsDisplay.setMap($scope.map);
						var updatePrioritiesData = { data: [] };
						_.each(optimizationResult.routes[0].waypoint_order, function (priority, taskIndex) {
							var task = employeeTasks[taskIndex];
							task.priority = ++priority;
							updatePrioritiesData.data.push({
								task_id: task.id,
								priority: task.priority
							});
						});
						Task.update_priorities(updatePrioritiesData)
							.$promise.then(function (updateResult) {
								if (updateResult.success) {
									$log.info(
										'Successfully auto task priorities according to Google routing results. data: ',
										updatePrioritiesData.data
									);
									toastr.success($translate.instant('MAP_USER.SUCCESSFULLY_ROUTED_ORDERS'));
								} else {
									$log.error(
										'Failed to update task priorities by Google routing results. data: ',
										updateResult.message
									);
									toastr.error($translate.instant('MAP_USER.FAILED_TO_SAVE_NEW_ORDER_POSITION'));
								}
							})
							.catch(function (e) {
								$log.error('Failed to update task priorities by Google routing results. data: ', e);
								toastr.error($translate.instant('MAP_USER.FAILED_TO_SAVE_NEW_ORDER_POSITION'));
							});
					} else {
						toastr.error($translate.instant('MAP_USER.FAILED_TO_GET_ROUTE_FOR_ORDERS') + status);
					}
				});
			};

			$scope.notifySelectDriverTeamAction = function (isSuccess) {
				if (isSuccess) {
					toastr.success(TranslationService.instant('DRIVERS.TOAST_STARTED_SUCCESSFULLY'));
				} else {
					toastr.error(TranslationService.instant('DRIVERS.TOAST_COULD_NOT_START'));
				}
			};

			$scope.startDriverShift = function (driver, teamId = null) {
				Employee.startShift(
					{ id: driver.id, active_team_id: teamId },
					handleReply({
						gaEvent: 'task_context_menu',
						gaEventValue: 'start shift',
						successMessage: 'Shift started successfully'
					}),

					function (data) {
						toastr.error(
							TranslationService.instant('DRIVERS.TOAST_COULD_NOT_START') + ' ' + data.message ?? ''
						);
					}
				);
			};

			$scope.shouldUserShowAllTeams = function () {
				return (
					MerchantConfigurations.allow_dispatcher_to_access_all_teams || Authentication.currentUser().admin
				);
			};

			$scope.startShift = function () {
				if ($scope.selectedEmployee === null) {
					return;
				} else if ($scope.selectedEmployee.team_ids.length === 1) {
					$scope.startDriverShift($scope.selectedEmployee);
				} else {
					const userTeamIds = $scope.currentUser.team_ids;
					const overlapTeamsIds = $scope.shouldUserShowAllTeams()
						? $scope.selectedEmployee.team_ids
						: $scope.selectedEmployee.team_ids.filter(item => userTeamIds.includes(item));
					if (!MerchantConfigurations.allow_start_shift_on_all_teams && overlapTeamsIds.length === 1) {
						$scope.startDriverShift({ id: $scope.selectedEmployee.id });
					} else {
						ReactModals.openSelectDriverTeam({
							driverUserOverlapTeamsIds: overlapTeamsIds,
							driverId: $scope.selectedEmployee.id,
							onCloseModal: $scope.notifySelectDriverTeamAction
						});
					}
				}
			};

			$scope.endShift = function () {
				if ($scope.selectedEmployee === null) {
					return;
				}
				Employee.endShift(
					{ id: $scope.selectedEmployee.id },
					handleReply({
						gaEvent: 'task_context_menu',
						gaEventValue: 'end shift',
						successMessage: 'Shift ended successfully'
					}),

					function (data) {
						if (data.message) {
							toastr.error('Shift could not be ended: ', data.message);
						} else {
							toastr.error('Shift could not be ended');
						}
					}
				);
			};

			$scope.taskListMiddleFilterSingle = function (task) {
				return task.late;
			};

			$scope.$on('employeeUnselected', function () {
				// alert PARENT that task list changed
				$scope.$emit('task list changed');
			});

			$scope.onEmployeeSelected = function () {
				if ($scope.selectedEmployee === null) {
					return;
				}
				$scope.employee = $scope.selectedEmployee;
				$scope.starsArray = bringg_utils.getStars($scope.selectedEmployee);

				Teams.byUser($scope.employee, function (teams) {
					if (teams.length > 0) {
						$scope.employeeTeams = teams;
					} else {
						$scope.employeeTeams = [{ id: 0, name: 'Default Team' }];
					}
				});

				$scope.team_tasks = [];
				//This could just as much be a Set of the task ids, but Set is not supported on our EcmaScript version.
				//TODO: When upgrading the ECMAScript version, replace with Set.
				$scope.userTaskIds = {};

				Tasks.latest(
					{
						user_id: $scope.employee.id
					},
					function (userTasks) {
						userTasks = _.filter(userTasks, $scope.taskTeamFilter);

						if ($scope.employeeFiteredTasks && $scope.employeeFiteredTasks.values) {
							if (queryWatcher) {
								queryWatcher();
							}
							queryWatcher = $scope.$watch('query', function (newQuery) {
								$scope.employeeFiteredTasks.values = $filter('filter')(userTasks, newQuery);
							});
						}

						_.forEach(userTasks, function (userTask) {
							$scope.onNewTask(userTask);
						});

						if ($scope.newTaskEvent) {
							$scope.newTaskEvent();
						}
						$scope.newTaskEvent = $scope.$on('new task', function (event, newTask) {
							if ($scope.selectedEmployee && newTask.user_id === $scope.selectedEmployee.id) {
								$log.info('EmployeesController received a new task: ', newTask);
								var existingTask = _.find(
									$scope.team_tasks[bringg_utils.getActiveTeam(newTask)],
									function (task) {
										return task.id === newTask.id;
									}
								);

								if (_.isUndefined(existingTask)) {
									$scope.onNewTask(newTask);
								}
							}
						});
					}
				);
			};

			$scope.$on('employeeSelected', $scope.onEmployeeSelected);

			$scope.$on('autoLocateSelectedEmployee', function () {
				if (!$scope.selectedEmployee) {
					return;
				}

				$scope.locate(false);
			});

			$scope.onRemovedTask = function (removedTask) {
				var taskTeam = bringg_utils.getActiveTeam(removedTask);
				$log.info('EmployeesController received removed task: ', removedTask.id, ' and team ', taskTeam);
				_.remove($scope.team_tasks[taskTeam], function (existingTask) {
					return existingTask.id === removedTask.id;
				});

				delete $scope.userTaskIds[removedTask.id];
			};

			function add_or_update_task(all_tasks, new_task) {
				for (var taskIdx = 0; taskIdx < all_tasks.length; taskIdx++) {
					if (all_tasks[taskIdx].id === new_task.id) {
						if (!Task.shouldUseNewApi()) {
							merge_objects(all_tasks[taskIdx], new_task);
						}
						return;
					}
				}
				$scope.onNewTask(new_task);
			}

			$scope.onTaskUpdated = function (e, updatedTask) {
				if (_.isNil($scope.selectedEmployee)) {
					return;
				}

				if (_.isUndefined(updatedTask.status)) {
					return;
				}

				if (updatedTask.user_id === $scope.selectedEmployee.id && !Task.isDone(updatedTask)) {
					if ($scope.team_tasks && $scope.team_tasks[updatedTask.team_ids[0]]) {
						$log.info('EmployeesController task update received: ', updatedTask);
						add_or_update_task($scope.team_tasks[updatedTask.team_ids[0]], updatedTask);
					} else {
						$log.info('EmployeesController new task received: ', updatedTask);
						$scope.onNewTask(updatedTask);
					}
					//userTaskIds is used as a Set, no need to check value.
				} else if (updatedTask.id in $scope.userTaskIds) {
					$scope.onRemovedTask(updatedTask);
				}
			};

			$scope.selectedCls = function (columnIdx) {
				if (columnIdx === $scope.sort.index) {
					if ($scope.sort.descending) {
						return '&#9660;';
					} else {
						return '&#9650;';
					}
				}
			};

			$scope.changeSorting = function (columnIdx) {
				var sort = $scope.sort;
				sort.index = columnIdx;
				if (sort.column === $scope.taskListHeaders[columnIdx].field) {
					sort.descending = !sort.descending;
				} else {
					sort.column = $scope.taskListHeaders[columnIdx].field;
					sort.descending = false;
				}
			};

			$scope.locate = function (failOnMissingLocation = true) {
				if ($scope.selectedEmployee === null) {
					return;
				}
				if (_.isNull($scope.selectedEmployee.lat) || $scope.selectedEmployee.lat === 0) {
					if (failOnMissingLocation) {
						toastr.error($scope.selectedEmployee.name + ' has no current location');
					}
					return;
				}
				return $timeout(function () {
					$scope.$emit('center_map', $scope.selectedEmployee);
				});
			};

			$log.debug('EmployeesController subscribing on employee update');

			$scope.onEmployeeUpdated = function (updatedEmployee) {
				if (!$scope.employee) {
					$log.debug('EmployeesController Employee update but no employee selected.. discarded.');
					return;
				} else if (!updatedEmployee.id) {
					$log.error('EmployeesController Employee update with unknown id.. discarded.');
					return;
				}
				$log.info('EmployeesController Got employee update: ', updatedEmployee);
				if (parseInt(updatedEmployee.id) === $scope.employee.id) {
					$log.info(
						'EmployeesController Employee (' + updatedEmployee.id + ') received update: %j ',
						updatedEmployee
					);

					merge_objects($scope.employee, updatedEmployee);
				}
			};

			$scope.assignEmployee = function (task, employee) {
				Teams.byUser(employee, function (teams) {
					if (teams.length > 0) {
						$scope.employeeTeams = teams;
					} else {
						$scope.employeeTeams = [{ id: 0, name: 'Default Team' }];
					}
				});

				Tasks.assignTask(task, employee.id).then(
					function () {
						if (task.user_id) {
							toastr.success($translate.instant('GLOBAL.TASK_ASSIGNED'));
						} else {
							toastr.success($translate.instant('GLOBAL.TASK_UNASSIGNED'));
						}
					},
					function (reason) {
						if (reason) {
							toastr.error(reason);
						}
					}
				);
			};

			$scope.editTask = function (task) {
				var editDialogOptions = {
					backdrop: true,
					keyboard: true,
					controller: 'EditTaskController',
					templateUrl: 'views/tasks/quick-edit.html',
					resolve: {
						task: function () {
							return $scope.task;
						}
					}
				};

				$scope.task = task;
				var d = $uibModal.open(editDialogOptions);
				d.result.then(function (result) {
					if (result) {
						task = new Task(result);
						task.$update(function (updateResponse) {
							if (updateResponse.success === false) {
								var errorString = '';
								for (var messageIdx in updateResponse.message) {
									errorString += messageIdx + ':' + updateResponse.message[messageIdx][0] + '\r\n';
								}
								toastr.error(errorString);
							}
						});
					}
				});
			};

			$scope.sendAnnouncement = function () {
				SendAnnouncmentService.open($scope.employee);
			};

			$scope.$on('config updated', function () {
				$scope.merchantConfiguration = MerchantConfigurations;
				if (MerchantConfigurations.enable_task_price) {
					// should probably search and see it doesn't exists yet
					$scope.taskListHeaders.push({
						field: 'total_price',
						title: $filter('currencyStringToSign')(MerchantConfigurations.price_currency) || '$',
						templateUrl: 'views/tasks/cell_templates/task-price.html'
					});
				}
			});

			$scope.$on('config updated', function () {
				$scope.merchantConfiguration = MerchantConfigurations;
				$scope.enableTeams = MerchantConfigurations.enable_teams;
			});

			SocketPubSub.on('employee update', $scope.onEmployeeUpdated);

			$rootScope.$on('task update', $scope.onTaskUpdated);

			$scope.$on('$destroy', function iVeBeenDismissed() {
				if ($scope.newTaskEvent) {
					$scope.newTaskEvent();
				}

				if (queryWatcher) {
					queryWatcher();
				}

				SocketPubSub.removeListener('employee update', $scope.onEmployeeUpdated);
				$log.info('EmployeesController onTaskUpdated destroyed');
			});
		}
	);
