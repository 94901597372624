angular
	.module('bringgApp')
	.provider('bringgConfiguration', function (ENV_CONFIGURATION, $provide) {
		var bringgConfiguration = {};

		var regionFetcher = function () {
			return localStorage.getItem('bringg-region');
		};

		bringgConfiguration.initialize = function (configuration) {
			if (configuration === undefined) {
				configuration = ENV_CONFIGURATION;
			}

			window['envConfig'] = configuration;

			var currentRegion = localStorage.getItem('bringg-region') || configuration.default_region;
			var config = configuration.regions[currentRegion] || configuration.regions[configuration.default_region];

			Object.keys(config).forEach(function (key) {
				var configKey = key.toUpperCase(),
					configValue = config[key];

				$provide.value(configKey, configValue);
			});

			$provide.value('REGION', regionFetcher);
			$provide.value('CURRENT_REGION', currentRegion);
			$provide.value(
				'DEFAULT_REGION_API_END_POINT',
				configuration.regions[configuration.default_region]['web_api_url']
			);
			$provide.value('DEFAULT_RT_THROTTLE', 1000);
			$provide.value('GOOGLE_STATIC_MAP_API_KEY', 'AIzaSyA1q-qDAFCGKYVZtmk1laLFkulueQe05NA');
		};

		bringgConfiguration.$get = _.noop;

		return bringgConfiguration;
	})
	.config(function (bringgConfigurationProvider) {
		bringgConfigurationProvider.initialize();
	});
