'use strict';
/*global Slick:false */

angular.module('bringgApp').directive('selectRolesPlannedRoutes', function (bringg_utils) {
	return {
		restrict: 'E',
		scope: {
			plannedRoutesByTeam: '=',
			selectedTasks: '=',
			preserveCurrentRuns: '='
		},
		templateUrl: 'scripts/features/optimize/steps/select-roles-planned-routes.html',
		link: function (scope) {
			scope.isTeamSelected = function (teamIndex) {
				return scope.activeTeamIndex === teamIndex;
			};

			scope.onTeamSelect = function (teamIndex) {
				scope.activeTeamIndex = teamIndex;
				scope.currentPlannedRoutes = scope.plannedRoutesByTeam[teamIndex].plannedRoutes;
			};

			scope.hasAnySelection = function (team) {
				return _.some(team.plannedRoutes, 'selected');
			};

			scope.toggleAllRoutesSelect = function () {
				_.each(scope.currentPlannedRoutes, function (role) {
					role.selected = scope.allSelected[scope.activeTeamIndex];
				});
			};

			scope.init = function () {
				scope.allSelected = {};
				scope.activeTeamIndex = 0;
				bringg_utils.naturalSortByKey(scope.plannedRoutesByTeam, 'name');
				scope.plannedRoutesByTeam[scope.activeTeamIndex].plannedRoutes =
					scope.plannedRoutesByTeam &&
					bringg_utils.naturalSortByKey(
						scope.plannedRoutesByTeam[scope.activeTeamIndex].plannedRoutes,
						'title'
					);

				scope.currentPlannedRoutes = scope.plannedRoutesByTeam[scope.activeTeamIndex].plannedRoutes;
				scope.selectAll();
			};

			scope.selectAll = function () {
				_.each(scope.plannedRoutesByTeam, function (teamPlannedRoutes, index) {
					scope.allSelected[index] = true;

					if (teamPlannedRoutes.plannedRoutes) {
						var routesToSelect = teamPlannedRoutes.plannedRoutes;

						if (scope.preserveCurrentRuns) {
							// Create an array of routes names from selected tasks
							var routeNames = _.chain(scope.selectedTasks).map('route_name').compact().value();

							// Set the routes that would be selected in the modal according to selected tasks
							routesToSelect = _.filter(routesToSelect, function (route) {
								return _.includes(routeNames, route.title);
							});
						}

						_.each(routesToSelect, function (plannedRoute) {
							plannedRoute.selected = true;
						});
					}
				});
			};

			scope.init();
		}
	};
});
