'use strict';

angular.module('bringgApp').controller('employeeDropdownCtrl', function ($scope, Employee, $rootScope) {
	Employees.drivers({}, function (result) {
		$scope.employees = result;
	});

	$rootScope.$on('employees list update', function () {
		Employees.drivers().then(function (drivers) {
			$scope.employees = drivers;
		});
	});
});
