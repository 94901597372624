/**
 * Created by liorsion on 8/31/15.
 */
'use strict';

angular
	.module('bringgApp')
	.controller(
		'AllTeamsController',
		function (
			$scope,
			$routeParams,
			$rootScope,
			$filter,
			Teams,
			ITEMS_PER_PAGE,
			AddTeamService,
			Lightbox,
			MerchantConfigurations,
			Geolocation,
			Authentication,
			PhoneNumberService,
			$translate
		) {
			$scope.replaceIdByExternalId = false;
			$scope.totalItems = 0;
			// contains all teams
			$scope.teams = [];
			// contains only filtered teams
			$scope.shownTeams = [];
			$scope.pageSize = ITEMS_PER_PAGE;
			$scope.loading = true;
			$scope.advancedSearch = false;
			$scope._searchDisabled = false;
			$scope.headers = [
				['id', $translate.instant('TEAMS.ID')],
				['name', $translate.instant('TEAMS.NAME')],
				['description', $translate.instant('TEAMS.DESCRIPTION')],
				['address', $translate.instant('TEAMS.ADDRESS')],
				['location', $translate.instant('TEAMS.LOCATION')],
				['contact_phone', $translate.instant('TEAMS.CONTACT_PHONE')],
				['time_zone', $translate.instant('TEAMS.TIME_ZONE')]
			];

			Authentication.featureFlags().then(function (featureFlags) {
				$scope.replaceIdByExternalId = Boolean(featureFlags.enable_external_id_display);
				if ($scope.replaceIdByExternalId) {
					$scope.headers = [['external_id', $translate.instant('TEAMS.EXTERNAL_ID')]].concat(
						$scope.headers.filter(function ([headerId]) {
							return headerId !== 'id';
						})
					);

					$scope.sort = {
						column: 'external_id',
						descending: true,
						index: 0
					};
				}
			});

			$scope.merchantConfigurations = MerchantConfigurations;

			if ($scope.merchantConfigurations.cash_out_flow_enabled) {
				$scope.headers.push(['distance_pay_per_km', 'Mileage Pay']);
			}

			$scope.sort = {
				column: 'id',
				descending: true,
				index: 0
			};

			function initialize() {
				Teams.all(teams => {
					$scope.loading = false;
					$scope.teams = teams;
					$scope.shownTeams = teams;
					$scope.totalItems = teams.length;
					$scope.offset = 0;
				});
			}

			$scope.$on('config updated', function () {
				initialize();
			});

			$rootScope.$watch($rootScope.isLoggedIn, function () {
				if ($rootScope.isLoggedIn) {
					initialize();
				}
			});

			$scope.formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

			$scope.changeSorting = function (columnIdx) {
				var sort = $scope.sort;
				if (sort.column === $scope.headers[columnIdx][0]) {
					sort.descending = !sort.descending;
				} else {
					sort.column = $scope.headers[columnIdx][0];
					sort.descending = false;
					sort.index = columnIdx;
				}
			};

			$scope.selectedCls = function (columnIdx) {
				if (columnIdx === $scope.sort.index) {
					if ($scope.sort.descending) {
						return '&#9660;';
					} else {
						return '&#9650;';
					}
				}
			};

			$scope.handleAddTeamClick = function () {
				AddTeamService.open().then(
					function (result) {},
					function (reason) {}
				);
			};

			$scope.handleLocationClicked = function (team) {
				Lightbox.openModal([{ url: Geolocation.generateStaticMap(team.lat, team.lng, team.lat, team.lng) }], 0);
			};

			$scope.onSearchQueryChanged = function (teamQuery) {
				$scope.offset = 0;

				if (_.isEmpty(teamQuery)) {
					$scope.shownTeams = $scope.teams;
				} else {
					$scope.shownTeams = $filter('filter')($scope.teams, teamQuery);
				}
			};

			$scope.isPreviousButtonDisabled = () => $scope.offset == 0;
			$scope.goToPreviousPage = function () {
				$scope.offset -= ITEMS_PER_PAGE;
			};

			$scope.isNextButtonDisabled = function () {
				return $scope.offset + ITEMS_PER_PAGE >= $scope.shownTeams.length;
			};
			$scope.goToNextPage = function () {
				$scope.offset += ITEMS_PER_PAGE;
			};
		}
	)
	.filter('startFrom', function () {
		return function (input, start) {
			start = +start; //parse to int
			return input.slice(start);
		};
	});
