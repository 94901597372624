'use strict';

angular.module('bringgApp').constant('OPTIMIZATION_TYPES', {
	ANONYMOUS: 1,
	NAMED_VRP: 2,
	NAMED_PDP: 3,
	ROUTE_BASED: 4,
	CREW_BASED: 5,
	COMBINED: 6
});

angular
	.module('bringgApp')
	.service('optimizationTypesService', function (OPTIMIZATION_TYPES, Authentication, bringg_utils, Teams) {
		const _this = this;
		_this.isNamed = isNamed;
		_this.isUnnamed = isUnnamed;
		_this.getConfiguredOptimizationType = getConfiguredOptimizationType;

		const namedOptimizationTypes = [
			OPTIMIZATION_TYPES.NAMED_VRP,
			OPTIMIZATION_TYPES.NAMED_PDP,
			OPTIMIZATION_TYPES.CREW_BASED,
			OPTIMIZATION_TYPES.COMBINED
		];

		function isNamed(optimizationType) {
			return namedOptimizationTypes.includes(optimizationType);
		}

		function isUnnamed(optimizationType) {
			return !isNamed(optimizationType);
		}

		function getConfiguredOptimizationType(teamId) {
			if (
				teamId &&
				Teams.teamsByIdMap[teamId].feature_flags &&
				Teams.teamsByIdMap[teamId].feature_flags.combined_optimization_rollout
			) {
				return OPTIMIZATION_TYPES.COMBINED;
			}

			return bringg_utils.getValueFromApplicationMerchantConfiguration('optimization_type');
		}
	});
