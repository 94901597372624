'use strict';

const EXPIRED = 'expired';
const INVALID_TOKEN = 'token is invalid';
const expiredMessage =
	'Looks like your reset password token has expired, please go to the <a href="/#/remind/">Reset Password</a>';

angular
	.module('bringgApp')
	.controller(
		'passwordResetController',
		function ($scope, Employee, $routeParams, $location, dialogSrv, ENV_CONFIGURATION, $http) {
			$scope.resetToken = $routeParams.token;
			$scope.resetEmail = $routeParams.email;
			const initial = $routeParams.initial;
			$scope.headerText = initial ? 'RESET.SET_INITIAL' : 'RESET.RESETTING';
			$scope.buttonText = initial ? 'RESET.BTN_SET_INITIAL' : 'RESET.BTN_RESET';
			$scope.region = $routeParams.region;

			$scope.reset = function () {
				$http
					.post(ENV_CONFIGURATION.regions[$scope.region].web_api_url + '/users/reset/', {
						email: $scope.resetEmail,
						token: $scope.resetToken,
						password: $scope.password
					})
					.then(function (response) {
						if (response.data && response.data.success) {
							$location.path('/login/');
						} else {
							const errorMessage = Array.isArray(response.data.message)
								? response.data.message.join('. ')
								: response.data.message;

							// For expired tokens Backend sends always hardcoded message which includes expired word.
							const expiredPassword =
								errorMessage.includes(EXPIRED) || errorMessage.includes(INVALID_TOKEN);

							if (expiredPassword) {
								dialogSrv.richHtmlDialog('Failed to change password', expiredMessage);
							} else {
								dialogSrv.errorDialog(errorMessage);
							}
						}
					})
					.catch(function () {
						dialogSrv.errorDialog(
							'Failed changing password. Please approach our support at support@bringg.com'
						);
					});
			};
		}
	);
