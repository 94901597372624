'use strict';

angular.module('bringgApp.directives').directive('loopSoundType', function ($translate) {
	return {
		restrict: 'E',
		scope: {
			type: '='
		},
		template:
			'<select id="looping_alert_sound_type"  class="form-control" ng-model="type" ng-options="soundType.value as soundType.name for soundType in soundTypes"></select>',
		link: function (scope) {
			scope.soundTypes = [
				{ name: $translate.instant('SOUND_TYPE.UNASSIGNED'), value: 0 },
				{ name: $translate.instant('SOUND_TYPE.UNACKNOWLEDGED'), value: 1 }
			];
		}
	};
});
