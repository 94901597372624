'use strict';

angular
	.module('bringgApp')
	.factory('UserSession', function ($http, WEB_API_URL) {
		var UserSession = function (options) {
			angular.extend(this, options);
		};

		UserSession.prototype.$get = function (success_function, error_function) {
			return $http
				.get(WEB_API_URL + '/tokens?auth_token=' + this.authentication_token, {})
				.success(function (data, status, headers, config) {
					success_function(data, status, headers, config);
				})
				.error(function (data, status, headers, config) {
					error_function(data, status, headers, config);
				});
		};

		UserSession.prototype.$save = function (success_function, error_function) {
			return $http
				.post(WEB_API_URL + '/tokens/', {
					email: this.email,
					password: this.password,
					merchant_id: this.merchantId,
					remember_me: this.remember_me || true,
					recaptcha: this.recaptcha || '',
					recaptcha_qa_token: this.recaptcha_qa_token || ''
				})
				.success(function (data, status, headers, config) {
					success_function(data, status, headers, config);
				})
				.error(function (data, status, headers, config) {
					error_function(data, status, headers, config);
				});
		};

		UserSession.prototype.$saveWithCode = function (success_function, error_function) {
			return $http
				.post(WEB_API_URL + '/tokens/', {
					code: this.code,
					session: this.session
				})
				.success(function (data, status, headers, config) {
					success_function(data, status, headers, config);
				})
				.error(function (data, status, headers, config) {
					error_function(data, status, headers, config);
				});
		};

		UserSession.prototype.$destroy = function () {
			return $http.delete(WEB_API_URL + '/tokens/');
		};

		return UserSession;
	})
	.factory('Token', function ($resource, WEB_API_URL) {
		var Token = $resource(
			WEB_API_URL + '/tokens/',
			{},
			{
				createDeveloperTokens: { method: 'GET', url: WEB_API_URL + '/tokens/developer' },
				getDeveloperKeys: { method: 'POST', url: WEB_API_URL + '/tokens/developer_keys' },
				impersonate: { method: 'POST', url: WEB_API_URL + '/tokens/impersonate' },
				switch_accounts: { method: 'POST', url: WEB_API_URL + '/tokens/switch_accounts' },
				gen_driver_qr_code: { method: 'GET', url: WEB_API_URL + '/tokens/gen_driver_qr_code' }
			}
		);
		return Token;
	});
