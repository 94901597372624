'use strict';

angular
	.module('bringgApp')
	.controller('BiController', function (
		$scope,
		$http,
		$rootScope,
		ENV_CONFIGURATION,
		MerchantConfigurations,
		WindowCommunicationService,
		$window,
		$sce
	) {
		if (!MerchantConfigurations.enable_bi_dashboard) {
			$window.history.back();
		}

		$scope.getBiUrl = function () {
			var url = ENV_CONFIGURATION.bi_dashboard_url + '?usedAsIframe=true';

			return $sce.trustAsResourceUrl(url);
		};

		$scope.sendCredentials = function () {
			var biIframe = document.querySelector('iframe#bi');
			var biIframeWindow = biIframe ? biIframe.contentWindow : undefined;

			WindowCommunicationService.sendCredentials(biIframeWindow);
		};
	});
