'use strict';

angular.module('bringgApp').factory('EmployeeTask', function ($resource, WEB_API_URL) {
	var Task = $resource(
		WEB_API_URL + '/api/tasks/:id',
		{ id: '@id' },
		{
			checkin: { method: 'POST', params: { id: '@id' }, url: WEB_API_URL + '/api/tasks/:id/checkin' },
			checkout: { method: 'POST', params: { id: '@id' }, url: WEB_API_URL + '/api/tasks/:id/checkout' },
			start: { method: 'POST', params: { id: '@id' }, url: WEB_API_URL + '/api/tasks/:id/start' },
			latest: { method: 'GET', isArray: true, params: {}, url: WEB_API_URL + '/api/tasks/latest' },
			open: { method: 'GET', isArray: true, params: {}, url: WEB_API_URL + '/api/tasks/open' }
		}
	);
	return Task;
});
