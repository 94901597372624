'use strict';

angular
	.module('bringgApp')
	.constant('FORM_FIELD_CONTROL_TYPES', {
		TEXT_INPUT: '0',
		SWITCH: '2',
		DROPDOWN_LIST: '4',
		SIGNATURE: '5'
	})
	.controller('MobileTaskFormController', function (
		$scope,
		MerchantConfigurationService,
		toastr,
		$translate,
		FORM_FIELD_CONTROL_TYPES
	) {
		$scope.loading = true;

		$scope.data = { use_mobile_task_form: 'off' };

		$scope.$watch('data.use_mobile_task_form', function (newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			if ($scope.mobile_task_form) {
				$scope.mobile_task_form.dynamic_form = newValue;
			}
		});

		$scope._init = function () {
			MerchantConfigurationService.getMobileTaskForm()
				.then(function (mobileTaskForm) {
					$scope.loading = false;

					//New Merchants sometimes initialized as an object
					if (!_.isArray(mobileTaskForm.form_fields)) {
						mobileTaskForm.form_fields = [];
					}

					$scope.data.use_mobile_task_form = mobileTaskForm.dynamic_form;
					_.forEach(mobileTaskForm.form_fields, function (formField) {
						if (formField.list_values) {
							formField.list_values = formField.list_values.toString();
						}
					});

					$scope.mobile_task_form = mobileTaskForm;
				})
				.catch(function () {
					toastr.error('Could not load Mobile Task Form Configurations.');
				});
		};

		$scope.handleDeleteFormField = function (item) {
			$scope.mobile_task_form.form_fields = _.without(
				$scope.mobile_task_form.form_fields,
				_.find($scope.mobile_task_form.form_fields, item)
			);
		};

		$scope.handleAddFieldClicked = function () {
			$scope.mobile_task_form.form_fields.push({
				type: '',
				key: '',
				label: '',
				control: '',
				mandatory: false,
				default_value: null,
				list_values: '',
				disclaimer: ''
			});
		};

		$scope.handleSaveClick = function () {
			var data = angular.copy($scope.mobile_task_form);
			_.each(data.form_fields, function (formField) {
				if (formField.list_values) {
					formField.list_values = formField.list_values.match(/(?=\S)[^,]+?(?=\s*(,|$))/g);
				}
			});

			// data.form_fields = JSON.stringify(data.form_fields);

			MerchantConfigurationService.setMobileTaskForm(data)
				.then(function () {
					toastr.success('Mobile Task Form was updated successfully!');
				})
				.catch(function () {
					toastr.error('Could not save the setting at this time.');
				});
		};

		$scope.formDisplayModes = [
			{ value: 0, description: $translate.instant('MOBILE_TASK_FORM.MANUALLY') },
			{ value: 1, description: $translate.instant('MOBILE_TASK_FORM.AFTER_CHECK_IN') },
			{ value: 2, description: $translate.instant('MOBILE_TASK_FORM.BEFORE_CHECK_OUT') }
		];

		$scope.controlTypes = [
			{ value: 0, description: $translate.instant('MOBILE_TASK_FORM.TEXT_INPUT') },
			{ value: 2, description: $translate.instant('MOBILE_TASK_FORM.SWITCH') },
			{ value: 4, description: $translate.instant('MOBILE_TASK_FORM.DROPDOWN_LIST') },
			{ value: 5, description: $translate.instant('MOBILE_TASK_FORM.SIGNATURE') }
		];

		$scope.formFieldControlTypes = FORM_FIELD_CONTROL_TYPES;

		$scope._init();
	});
