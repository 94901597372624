/*global Slick:false */

'use strict';

angular
	.module('bringgApp')
	.service(
		'InProcessOptimization',
		function (
			$rootScope,
			Authentication,
			SocketPubSub,
			BringgSDK,
			$log,
			Optimization,
			$timeout,
			$translate,
			toastr
		) {
			var inProcessOptimization = {};

			inProcessOptimization.OPTIMIZATION_POLLING_INTERVAL = 3000;
			inProcessOptimization.pendingOptimizationUUIDs = [];
			inProcessOptimization.pendingOptimizationUUIDsProperties = {};

			function onOptimizationCancelled() {
				inProcessOptimization.cancelAllPolling();
			}

			inProcessOptimization.cancelAllOptimizations = function () {
				return BringgSDK.getInstancePromise()
					.then(function (bringg) {
						return bringg.routeOptimizationApi.cancelRequests(
							inProcessOptimization.pendingOptimizationUUIDs
						);
					})
					.then(function (result) {
						onOptimizationCancelled();

						if (result.success) {
							toastr.success($translate.instant('OPTIMIZE_TASKS.SENT_ROUTE_CANCELLATION_REQUEST'));
							$rootScope.$broadcast('all optimizations cancelled');
						} else {
							$log.error(
								'inProcessOptimization.cancelOptimizations got success false from server',
								result
							);
							toastr.error($translate.instant('OPTIMIZE_TASKS.FAILED_TO_SEND_CANCELLATION_REQUEST'));
						}
					})
					.catch(function (error) {
						$log.error('inProcessOptimization.cancelOptimizations failed to cancel', error);
						toastr.error($translate.instant('OPTIMIZE_TASKS.FAILED_TO_SEND_CANCELLATION_REQUEST'));
						onOptimizationCancelled();
					});
			};

			inProcessOptimization.getProgress = function () {
				var iteration = 0,
					totalIterations = 0;

				_.each(inProcessOptimization.pendingOptimizationUUIDsProperties, function (optimizationProperties) {
					iteration += optimizationProperties.iteration;
					totalIterations += optimizationProperties.totalIterations;
				});

				return {
					iteration: iteration,
					totalIterations: totalIterations
				};
			};

			inProcessOptimization.onNewOptimizationRequest = function (requestUuid) {
				$log.info('onNewOptimizationRequest - got new request for uuid: ' + requestUuid);

				inProcessOptimization.pollOptimizationResult(requestUuid, inProcessOptimization.onOptimizationDone);
			};

			inProcessOptimization.cancelPolling = function (requestUuid) {
				inProcessOptimization.pendingOptimizationUUIDs = _.without(
					inProcessOptimization.pendingOptimizationUUIDs,
					requestUuid
				);
			};

			inProcessOptimization.cancelAllPolling = function () {
				inProcessOptimization.pendingOptimizationUUIDs = [];
			};

			inProcessOptimization.pollOptimizationResult = function (requestUuid, successCb) {
				var logPrefix = 'pollOptimizationStatus, request_uuid: ' + requestUuid;

				if (!_.includes(inProcessOptimization.pendingOptimizationUUIDs, requestUuid)) {
					$log.info(logPrefix + ' request is not in pending list, no need to poll for it');
					return;
				}

				Optimization.optimizationResult(requestUuid)
					.then(function (result) {
						if (!result.success) {
							$log.error(logPrefix + 'failed with error -' + result.message + ', retrying');
							$timeout(function () {
								inProcessOptimization.pollOptimizationResult(requestUuid, successCb);
							}, inProcessOptimization.OPTIMIZATION_POLLING_INTERVAL);
							return;
						}
						if (!result.route_optimization_result) {
							$log.info(logPrefix + ' still got no result, message - ' + result.message + ', retrying');
							$timeout(function () {
								inProcessOptimization.pollOptimizationResult(requestUuid, successCb);
							}, inProcessOptimization.OPTIMIZATION_POLLING_INTERVAL);
							return;
						}
						$log.info(logPrefix + ' got result');
						successCb(result.route_optimization_result);
					})
					.catch(function (error) {
						$log.error(logPrefix + 'failed with error - ', error);
					});
			};

			inProcessOptimization.onOptimizationDone = function (optimizationResult) {
				var optimizationUUID = optimizationResult.request_uuid;
				$log.info('onOptimizationDone - got optimization result for uuid: ' + optimizationUUID);

				if (!_.includes(inProcessOptimization.pendingOptimizationUUIDs, optimizationUUID)) {
					// unknown optimization, skip
					$log.info(
						'onOptimizationDone - the optimization result uuid: ' +
							optimizationUUID +
							' is unknown, ignoring.'
					);
					return;
				}

				// remove uuid from pending
				inProcessOptimization.pendingOptimizationUUIDs = _.without(
					inProcessOptimization.pendingOptimizationUUIDs,
					optimizationUUID
				);
				delete inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID];

				$rootScope.$broadcast('optimization done', optimizationResult);

				// all optimizations done
				if (_.isEmpty(inProcessOptimization.pendingOptimizationUUIDs)) {
					$log.info('onOptimizationDone - all optimizations done');
					$rootScope.$broadcast('all optimizations done');
					inProcessOptimization.pendingOptimizationUUIDsProperties = {};
				}
			};

			inProcessOptimization.onOptimizationProgress = function (progress) {
				var optimizationUUID = progress.requestUuid;
				$log.info('onOptimizationProgress - got optimization result for uuid: ' + optimizationUUID);

				if (!_.includes(inProcessOptimization.pendingOptimizationUUIDs, optimizationUUID)) {
					// unknown optimization, skip
					$log.info(
						'onOptimizationProgress - the optimization result uuid: ' +
							optimizationUUID +
							' is unknown, ignoring.'
					);
					return;
				}

				inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID] =
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID] || {};
				inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].lastUpdate =
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].lastUpdate ||
					progress.timestamp;

				// to make sure its the new update
				if (
					progress.timestamp >=
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].lastUpdate
				) {
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].lastUpdate =
						progress.timestamp;
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].iteration =
						progress.iteration;
					inProcessOptimization.pendingOptimizationUUIDsProperties[optimizationUUID].totalIterations =
						progress.totalIterations;

					$rootScope.$broadcast('optimization progress', inProcessOptimization.getProgress());
				}
			};

			inProcessOptimization.etaRecalculateDone = function (data) {
				$log.info('etaRecalculateDone - got eta recalculate result', data);
				$rootScope.$broadcast('eta recalculate done', data);
			};

			SocketPubSub.on('optimization done', inProcessOptimization.onOptimizationDone);
			SocketPubSub.on('optimization progress', inProcessOptimization.onOptimizationProgress);
			SocketPubSub.on('eta recalculate done', inProcessOptimization.etaRecalculateDone);

			return inProcessOptimization;
		}
	);
