'use strict';

angular
	.module('bringgApp')
	.controller('SendAnnouncementController', function ($scope, Announcement, employee, $uibModalInstance) {
		$scope.employee = employee;
		$scope.announcement = new Announcement({
			user_id: $scope.employee.id,
			message: ''
		});

		$scope.send = function () {
			$scope.announcement.$create_for_employee();
			$uibModalInstance.close();
		};

		$scope.close = function () {
			$uibModalInstance.close(undefined);
		};
	});
