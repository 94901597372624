'use strict';

angular
	.module('bringgApp')
	.controller(
		'AddTasksCSVController',
		function AddTasksCSVController(
			$scope,
			$rootScope,
			$uibModalInstance,
			FileUploader,
			WEB_API_URL,
			MerchantConfigurationService,
			Merchant,
			Authentication,
			localStorageService,
			toastr,
			$log,
			ENV,
			AddTasksCSVService,
			options
		) {
			$scope.$on('taskImportDone', function (event, data) {
				$scope.data.lastImportErrors = data.errors;
				$scope.data.failedLines = data.failed_importing;
				$scope.data.successLines = data.imported_orders;
				$scope.data.success = true;
				$scope.importing = false;
			});

			var importCsvUrl = WEB_API_URL + '/tasks/import_from_csv';

			$scope.data = {
				lastImportErrors: null,
				testData: null
			};

			$scope.importing = false;

			$scope.csvUploader = new FileUploader({
				url: importCsvUrl,
				withCredentials: true,
				headers: {
					'x-ov-user-email': Authentication.currentUser().email,
					Authorization: localStorageService.get('auth_key')
				},
				autoUpload: false,
				queueLimit: 1
			});

			$scope.handleChooseFileClick = function () {
				angular.element('#csvUploader').trigger('click');
			};

			$scope.csvUploader.onCompleteItem = function (requestData, response) {
				if (response && response.success) {
					toastr.success('CSV of orders updated, thank you!');
					if (requestData.url.indexOf('test_import_one_line_from_csv') !== -1) {
						$scope.data.testData = response.data;
						$scope.csvUploader.queue[0].isSuccess = false;
						$scope.csvUploader.queue[0].isUploaded = false;
						$scope.csvUploader.queue[0].progress = 0;
					} else if (response.imported_orders || response.failed_importing) {
						$scope.data.lastImportErrors = response.errors;
						$scope.data.failedLines = response.failed_importing || 0;
						$scope.data.successLines = response.imported_orders;
						$scope.data.success = true;
						$scope.importing = false;
					}
					$scope.$apply();
				} else {
					if (response && response.errors) {
						$scope.data.lastImportErrors = response.errors;
						$scope.data.failedLines = response.failed_importing;
					}
					toastr.error('Task update failed!');
					$scope.data.success = false;
					$scope.importing = false;
				}
			};

			$scope.uploadFile = function () {
				if (!$scope.csvUploader.queue[0]) {
					toastr.error('Please add a CSV file to import');
					return;
				}

				$scope.data.lastImportErrors = null;
				$scope.data.testData = null;

				$scope.csvUploader.queue[0].removeAfterUpload = true;
				$scope.csvUploader.queue[0].url = importCsvUrl;
				$scope.csvUploader.uploadItem($scope.csvUploader.queue[0]);
				$scope.importing = true;
			};

			$scope.testUploadFile = function () {
				if (!$scope.csvUploader.queue[0]) {
					toastr.error('Please add a CSV file to import');
					return;
				}

				$scope.data.lastImportErrors = null;
				$scope.data.testData = null;
				$scope.csvUploader.queue[0].url = WEB_API_URL + '/tasks/test_import_one_line_from_csv';
				$scope.csvUploader.uploadItem($scope.csvUploader.queue[0]);
			};

			$scope.close = function () {
				$uibModalInstance.close();
			};

			$scope.$watch(Authentication.isLoggedIn, function (isLoggedIn) {
				if (isLoggedIn) {
					MerchantConfigurationService.getCSVFields().then(function (csvUploadFields) {
						$scope.data.CSVFields = csvUploadFields;
						$scope.loading = false;
					});
					Merchant.get(function (data) {
						$scope.localTimezone = data.time_zone;
					});
				}
			});
		}
	);
