/*global $:false */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('onShift', function () {
		return function (employeeList) {
			return _.filter(employeeList, function (employee) {
				return employee.active_shift_id ? true : false;
			});
		};
	})
	.filter('notAtHome', function () {
		return function (employeeList) {
			return _.filter(employeeList, function (employee) {
				return !employee.at_home;
			});
		};
	})
	.filter('offShift', function () {
		return function (employeeList) {
			return _.filter(employeeList, function (employee) {
				return employee.active_shift_id ? false : true;
			});
		};
	})
	.filter('employeeInTeamsFilter', function (MerchantConfigurations) {
		return function (employeeList, teamslist) {
			// in case teams is disabled return all users
			if (!MerchantConfigurations.enable_teams) {
				return employeeList;
			}

			var teamsIds = _.map(teamslist, 'id');

			return _.filter(employeeList, function (employee) {
				return !_.isEmpty(_.intersection(teamsIds, employee.team_ids));
			});
		};
	})
	.filter('driverOnShift', function (Employees) {
		return function (employee) {
			return Employees.isOnShift(employee);
		};
	})
	.filter('driverUserTypeId', function () {
		return function (employee) {
			return employee.user_type_id || null;
		};
	});
