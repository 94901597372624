/**
 * Created by liorsion on 5/5/15.
 */
'use strict';

angular
	.module('bringgApp.filters')
	.filter('taskQueryFilter', function ($filter) {
		return function (input, search) {
			try {
				if (search.indexOf(':') !== -1) {
					var searchObject = {};
					if (search.indexOf(':') !== -1) {
						search = search.split(' ');
					} else {
						search = [search];
					}

					search.forEach(function (searchTerm) {
						var searchTerms = searchTerm.split(':');
						searchObject[searchTerms[0]] = searchTerms[1];
					});
					search = searchObject;
				}
			} catch (error) {}

			//if (_.isObject(search)) {
			//  return _.find(input, search);
			//} else if (search){
			return $filter('filter')(input, search);
			//} else {
			//  return input;
			//}
		};
	})
	.filter('taskCancelSource', function ($translate, Fleets) {
		return function (task) {
			if (!task) {
				return null;
			}

			switch (task.automatically_cancelled) {
				case 0:
					return $translate.instant('ORDER.CANCEL_MANUAL');
				case 1:
					return $translate.instant('ORDER.CANCEL_AUTOMATIC');
				case 2:
					return $translate.instant('ORDER.CANCEL_FROM_APPLICATION');
				case 3:
					return $translate.instant('ORDER.CANCEL_FROM_LINKED_TASK');
				case 4:
					return $translate.instant('ORDER.CANCEL_FROM_API');
				case 5: {
					var cancelFromFleet = $translate.instant('ORDER.CANCEL_FROM_FLEET');
					if (_.isNumber(task.fleet_id)) {
						var fleetName = Fleets.getName(task.fleet_id);
						cancelFromFleet += ' ' + fleetName;
					}
					return cancelFromFleet;
				}
				default:
					break;
			}
		};
	});
