'use strict';
/*global merge_objects:false */

angular
	.module('employeesTasksServices', [])
	.factory('EmployeesTasks', function (Employees, Tasks, $sanitize, MerchantConfigurations, $translate) {
		var OFFLINE_ON_SHIFT_TIME = new Date(2030, 1, 1).getTime(),
			OFF_SFIFT_TIME = new Date(2031, 1, 1).getTime(),
			OFFLINE_OFF_SHIFT_TIME = new Date(2032, 1, 1).getTime(),
			NEVER_SIGNED_IN_TIME = new Date(2033, 1, 1).getTime();

		var EmployeesTasksService = {
			formatEmployeeSelection: function (employee, teamIds) {
				var employeeName = $sanitize(employee.name);
				if (!employee.id) {
					return $translate.instant('GLOBAL.UNASSIGNED');
				}

				const isActiveTeamMatching = Array.isArray(teamIds)
					? _.isNull(employee.active_team_id) || teamIds.includes(employee.active_team_id)
					: true;

				if (employee.is_on_break) {
					employee.sub = 'on_break';
				} else if (!employee.sub) {
					employee.sub = 'Free';
				}

				const activeTeamMismatchClass = isActiveTeamMatching ? '' : ' active_team_mismatch';
				const subStatusClass = employee.current_unavailability_id
					? ' sub_status_driver-unavailable'
					: ' sub_status_' + employee.sub.toLowerCase();

				var formattedLine =
					'<span class="status_' + employee.status + activeTeamMismatchClass + subStatusClass + '">';
				var lastTask = EmployeesTasksService.getLastTask(Tasks.openForUser(employee.id)),
					employeeFreeTime = lastTask ? EmployeesTasksService.getTimeFromTask(lastTask) : undefined,
					employeeLastAddress = lastTask
						? EmployeesTasksService.getAddressFromTask(lastTask)
						: employee.estimated_last_address;

				if (employee.disabled) {
					formattedLine += '<i class="bringg-icon bringg-icon-block" aria-hidden="true"></i>';
				}

				formattedLine +=
					'<span class="circle"></span><span data-test-id="driverName">' + employeeName + '</span>';

				if (employee.is_on_break) {
					formattedLine += ' <span data-test-id="driverOnBreak">[Break] </span>';
				} else if (employeeFreeTime && employeeFreeTime !== 0) {
					formattedLine +=
						' <span data-test-id="driverFreeTime">[' + moment(employeeFreeTime).fromNow() + ' ]</span>';
				} else {
					formattedLine += ' <span data-test-id="driverIsFree">[Free] </span>';
				}

				if (employeeLastAddress) {
					formattedLine += '<span data-test-id="lastAddress"> (' + employeeLastAddress + ')</span>';
				}

				if (employee.vehicle_type_title) {
					formattedLine +=
						'<span style="margin-right: 3px" data-test-id="vehicleTitle"> (' +
						employee.vehicle_type_title +
						')</span>';
				}

				if (!employee.last_sign_in_at) {
					return (
						'<span><b data-test-id="driverNotLoggedIn">' +
						$translate.instant('DRIVER_ASSIGNMENT_DROP_DOWN.NEVER_LOGGED_IN') +
						'</b> - <span data-test-id="driverName">' +
						employeeName +
						'</span></span>'
					);
				}

				if (!employee.active_shift_id || !isActiveTeamMatching) {
					formattedLine += '<b data-test-id="driverOffShift">Off-Shift</b>';
				} else if (employee.current_unavailability_id) {
					formattedLine += `<b data-test-id="driverUnavailable">${$translate.instant(
						'DISPATCH.DRIVER_UNAVAILABLE'
					)}</b>`;
				}

				if (employee.hasAvailavleInventory) {
					formattedLine +=
						'<img src="images/employees_list/driver_with_available_floating_inventory.png" class="employee-available-inventory-img" data-test-id="hasAvailableInventory"/>';
				}

				return formattedLine;
			},
			onShiftEmployees: function (employees, task) {
				var onShiftEmployees = _.reject(employees, function (employee) {
					return employee.active_shift_id === null;
				});

				if (task.team_ids && task.team_ids.length > 0) {
					onShiftEmployees = _.filter(onShiftEmployees, function (employee) {
						return !_.isEmpty(_.intersection(task.team_ids, employee.team_ids));
					});
				}

				return onShiftEmployees;
			},
			onShiftEmployeesWithUnassigned: function (employees, teamsIds) {
				var onShiftEmployees = _.reject(employees, function (employee) {
					return employee.active_shift_id === null;
				});

				// if there are team ids and the default one is not there
				if (teamsIds && teamsIds.length > 0 && teamsIds.indexOf(0) !== -1) {
					onShiftEmployees = _.filter(onShiftEmployees, function (employee) {
						return !_.isEmpty(_.intersection(teamsIds, employee.team_ids));
					});
				}

				onShiftEmployees.push({ id: null, name: 'Unassigned' });

				return onShiftEmployees;
			},
			allEmployeesWithUnassigned: function (empleyees, teamsIds, addUnassigned) {
				var result = _.clone(empleyees);
				// if there are team ids and the default one is not there
				if (
					MerchantConfigurations.enable_teams &&
					teamsIds &&
					teamsIds.length > 0 &&
					!_.includes(teamsIds, 0)
				) {
					result = _.filter(result, function (employee) {
						return !_.isEmpty(_.intersection(teamsIds, employee.team_ids));
					});
				}

				if (addUnassigned) {
					var hasUnassigned = _.find(result, function (item) {
						return _.isNull(item.id);
					});
					if (!hasUnassigned) {
						result.push({ id: null, name: 'Unassigned' });
					}
				}

				return result;
			},
			getLastTask: function (tasks) {
				if (_.isUndefined(tasks) || tasks.length === 0) {
					return;
				}
				var lastTask = _.max(tasks, function (task) {
					var wayPointWithMaxEtl = _.max(task.way_points, function (wayPoint) {
						if (wayPoint.etl) {
							return new Date(wayPoint.etl).getTime();
						} else {
							return Number.MIN_VALUE;
						}
					});
					if (
						wayPointWithMaxEtl &&
						!_.isUndefined(wayPointWithMaxEtl.etl) &&
						!_.isNull(wayPointWithMaxEtl.etl)
					) {
						return new Date(wayPointWithMaxEtl.etl).getTime();
					}
					if (task.scheduled_at) {
						return new Date(task.scheduled_at).getTime();
					} else {
						return Number.MIN_VALUE;
					}
				});

				return lastTask;
			},
			getAddressFromTask: function (task) {
				if (_.isUndefined(task)) {
					return;
				}
				if (
					task.way_points &&
					task.way_points.length > 0 &&
					task.way_points[task.way_points.length - 1].address
				) {
					return task.way_points[task.way_points.length - 1].address;
				}
			},
			getTimeFromTask: function (task) {
				if (task.way_points && task.way_points.length > 0 && task.way_points[task.way_points.length - 1].etl) {
					return new Date(task.way_points[task.way_points.length - 1].etl).getTime();
				}
				if (task.scheduled_at) {
					return new Date(task.scheduled_at).getTime();
				}

				return Number.MAX_VALUE;
			},
			getLastTaskTime: function (tasks) {
				if (_.isUndefined(tasks) || tasks.length === 0) {
					return 0;
				}
				var lastTask = this.getLastTask(tasks);

				return this.getTimeFromTask(lastTask);
			},
			optimizedEmployeeListForTask: function (employees, container, query) {
				var sortedList = _.sortBy(employees, function (sortedEmployee) {
					var employee;

					if (!_.isNull(sortedEmployee.id)) {
						employee = Employees.employeeByIdMap[sortedEmployee.id];
					}
					if (_.isUndefined(employee)) {
						return Number.MAX_VALUE;
					}
					if (!employee.sub) {
						employee.sub = 'Free';
					}

					if (employee.status === 'offline' && !_.isNull(employee.last_sign_in_at)) {
						if (!_.isNull(employee.active_shift_id)) {
							return OFFLINE_ON_SHIFT_TIME;
						}
						return OFFLINE_OFF_SHIFT_TIME;
					} else if (_.isNull(employee.last_sign_in_at)) {
						return NEVER_SIGNED_IN_TIME;
					} else if (_.isNull(employee.active_shift_id)) {
						return OFF_SFIFT_TIME;
					} else if (employee.sub === 'Free') {
						return 0;
					} else {
						var employeeTask = Tasks.openForUser(employee.id),
							lastETL = EmployeesTasksService.getLastTaskTime(employeeTask);

						return lastETL;
					}
				});
				return sortedList;
			}
		};

		return EmployeesTasksService;
	});
