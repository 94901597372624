'use strict';

angular
	.module('bringgApp')
	.filter('taskAssignEmployeeExtID1', function (Employees, $q) {
		return function (data) {
			if (_.isNull(data.user_id)) {
				return $q.resolve(false);
			}
			return $q.resolve(
				Employees.employeeByIdMap[data.user_id] && Employees.employeeByIdMap[data.user_id].external_id === '1'
			);
		};
	})
	.filter('employeeCashOut', function ($q) {
		return function () {
			//for future purposes
			return $q.resolve(true);
		};
	});
