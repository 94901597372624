'use strict';
/*global $:false */
/*global google:false */
/*global find_in_array:false */

angular
	.module('bringgApp')
	.controller(
		'NewMapsController',
		function (
			$scope,
			$location,
			Announcement,
			Tasks,
			$rootScope,
			MerchantConfigurations,
			$routeParams,
			Employees,
			EmployeesTasks,
			$timeout,
			Employee,
			localStorageService,
			toastr,
			Authentication,
			$translate,
			$sanitize,
			$window,
			$q,
			DEFAULT_RT_THROTTLE,
			Tags,
			SkillsService,
			CrossApplicationService,
			CustomAttributesService,
			ManagedAttributesService,
			Task,
			TasksData,
			PresetViewsService,
			CROSS_APP_ACTIONS,
			ObjectUtilsService
		) {
			localStorageService.set('last_known_location', 'map');

			var event_triggerring = false;

			$scope.displayDriversFilter = undefined;
			$scope.tags = Tags.all();
			$scope.onlineFilter = Employees.filterIsOnline;
			$scope.offlineFilter = Employees.filterIsOffline;
			$scope.filterObject = {};
			$scope.grid = {};
			$scope.gaPage = 'DispatchView';
			$scope.mapObject = null;
			$scope.filteredTasks = { values: [] };
			$scope.employeeFiteredTasks = { values: [] };
			$scope.selectedTeamsIds = [];
			$scope.employeeNameQuery = '';
			$scope.employeeTasksFilter = undefined;

			$scope.$on('map_initiated', function (event, map) {
				$scope.mapObject = map;
				event.stopPropagation();
			});

			$scope.$on('center_map', function (event, selectedEmployee) {
				center_map($scope.mapObject, selectedEmployee);
				event.stopPropagation();
			});

			$scope._offlineAndOnShitEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'offline' && Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._offShiftEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return !Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._onShiftEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return Employees.isOnShift(employee);
				}
				return false;
			};

			$scope._onlineEmployeeFilter = function (employee) {
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'online';
				}
				return false;
			};

			$scope.taskTeamFilter = function (task) {
				if (_.isEmpty($scope.selectedTeamsIds)) {
					return true;
				}
				if (task && task.team_ids && task.team_ids.length > 0) {
					return !!_.intersection(task.team_ids, $scope.selectedTeamsIds).length;
				}
				return false;
			};

			function filterEmployees({ query }) {
				$scope.employeeNameQuery = query;

				refreshDisplayedEmployees();

				if ($scope.employeeNameQuery.trim().toLowerCase() === '') {
					return;
				}

				if ($scope.displayedEmployees.filtered.length !== 1) {
					return;
				}

				const employeeToSelect = $scope.displayedEmployees.filtered[0];

				$scope.pickEmployee(
					// If only have one employee then select it and focus it in the map
					employeeToSelect,

					// Don't update the url to avoid flickering (it's not nice to see re-rendering while typing
					false
				);

				$scope.$broadcast('autoLocateSelectedEmployee');
				$scope.$emit('autoLocateSelectedEmployee');
			}

			$scope.driverLeftHome = function (driver) {
				var driverId = driver.id;
				if (_.isUndefined(driverId)) {
					return;
				}
				driver.sending_home_event = true;
				Employee.leftHome(
					{ user_id: driverId, id: driverId },
					function (data, status, headers, config) {
						driver.sending_home_event = false;
						if (data.success) {
							driver.at_home = false;
							toastr.success(data.message);
						} else {
							driver.at_home = true;
							toastr.error(data.message);
						}
					},
					function (data, status, headers, config) {
						driver.at_home = true;
						driver.sending_home_event = false;
						if (data.message) {
							toastr.error('Could not leave home:', data.message);
						} else {
							toastr.error('Could not leave home');
						}
					}
				);
			};

			$scope.driverGotHome = function (driver) {
				var driverId = driver.id;
				driver.sending_home_event = true;
				Employee.gotHome(
					{ user_id: driverId, id: driverId },
					function (data, status, headers, config) {
						driver.sending_home_event = false;
						if (data.success) {
							driver.at_home = true;
							toastr.success(data.message);
						} else {
							driver.at_home = false;
							toastr.error(data.message);
						}
					},
					function (data) {
						driver.sending_home_event = false;
						driver.at_home = false;
						if (data.message) {
							toastr.error('Could not arrive home: ', data.message);
						} else {
							toastr.error('Could not arrive home');
						}
					}
				);
			};

			$scope.unpickEmployee = function () {
				$location.search({});
				$scope.$broadcast('employeeUnselected', null);
				$scope.$emit('employeeUnselected', null);
				$scope.selectedEmployee = null;
				CrossApplicationService.emit('employee_list_selected_employee_updated', $scope.selectedEmployee);

				event_triggerring = true;
				setAutomaticResize(true);
				event_triggerring = false;
				$timeout($scope.grid.resizeCanvas);
			};

			var refreshFiltersOnFiltersChange = function (event, removeMissingKeysFromPrevFilters) {
				if (removeMissingKeysFromPrevFilters) {
					ObjectUtilsService.deleteMissingKeys($scope.filterObject, event);
				}

				Object.assign($scope.filterObject, event);
				if ($scope.grid.refreshFilters) {
					$scope.grid.refreshFilters();
				}
			};

			function employeeListRegister({
				setDisplayedEmployees,
				setDriverListFilterByOnShift,
				setSelectedEmployee,
				finish
			}) {
				setDisplayedEmployees($scope.displayedEmployees);
				setDriverListFilterByOnShift($scope.driverListFilterByOnShift);
				setSelectedEmployee($scope.selectedEmployee);

				finish();
			}

			function closeSelectEmployee(task) {
				$('#s2id_list_task_' + task.id + '_employee_list').hide();
				$('#list_task_' + task.id + '_employee').show();
			}

			$scope.assignEmployee = function (task, employee_id) {
				return Tasks.assignTask(task, employee_id).then(
					function (result) {
						if (task.user_id) {
							toastr.success('Task assigned.');
						} else {
							toastr.success('Task unassigned.');
						}
					},
					function (reason) {
						toastr.error(reason);
					}
				);
			};

			$scope.selectEmployee = function (task) {
				if ($scope.readonly) {
					return;
				}
				$('#list_task_' + task.id + '_employee').hide();
				var existingSelection = $('#s2id_list_task_' + task.id + '_employee_list');
				if (existingSelection.length !== 0) {
					// isEmpty doesn't work here
					existingSelection.show();
					existingSelection.select2('open');
					return;
				}
				$('#list_task_' + task.id + '_employee_list')
					.show()
					.select2({
						data: {
							results: EmployeesTasks.allEmployeesWithUnassigned($scope.employees, task.team_ids, true),
							text: 'name'
						},
						formatResult: function (employee) {
							return EmployeesTasks.formatEmployeeSelection(employee, task.teams_ids);
						},
						formatSelection: function (employee) {
							return EmployeesTasks.formatEmployeeSelection(employee, task.teams_ids);
						},
						dropdownAutoWidth: true,
						//        query: function(query){ query.callback(); }
						sortResults: EmployeesTasks.optimizedEmployeeListForTask
					})
					.on('change', function (e) {
						closeSelectEmployee(task);
						$scope.assignEmployee(task, e.val).then(function () {
							$(this).select2('refresh');
						});
					})
					.on('select2-close', function () {
						closeSelectEmployee(task);
					})
					.select2('open');
			};

			/**
			 * handle employees updates
			 */

			$scope.displayedEmployees = {
				all: [],
				filtered: [],
				onlineCount: 0,
				onShiftAndOfflineCount: 0,
				onShiftCount: 0,
				offShiftCount: 0
			};

			$scope.filterByTeams = function (employees) {
				return _.filter(employees, function (employee) {
					return !_.isEmpty(_.intersection(employee.team_ids, $scope.filterObject.selectedTeams));
				});
			};

			var refreshDisplayedEmployees = function () {
				if (!$scope.employees) {
					CrossApplicationService.emit('employee_list_employees_updated', $scope.displayedEmployees);
					return;
				}

				var displayedEmployees = $scope.employees;
				$scope.selectedTeamsIds = $scope.filterObject.selectedTeams;
				if (
					$scope.emptyFilteringEnabled ||
					($scope.filterObject.selectedTeams && $scope.filterObject.selectedTeams.length)
				) {
					displayedEmployees = $scope.filterByTeams(displayedEmployees);
				}
				// counters
				var onOnlineEmployees = _.filter(displayedEmployees, $scope.onlineFilter);
				var onShiftEmployees = _.filter(displayedEmployees, $scope._onShiftEmployeeFilter);
				var onShiftAndOfflineEmployees = _.filter(onShiftEmployees, $scope.offlineFilter);
				var offShiftEmployees = _.filter(displayedEmployees, $scope._offShiftEmployeeFilter);

				if ($scope.displayDriversFilter) {
					displayedEmployees = displayedEmployees.filter((employee, index, array) =>
						$scope.displayDriversFilter(employee, array)
					);
				}

				const driverQuery = $scope.employeeNameQuery.trim().toLowerCase();

				$scope.displayedEmployees = {
					all: displayedEmployees,
					filtered:
						driverQuery === ''
							? displayedEmployees
							: displayedEmployees.filter(item => item.name.toLowerCase().includes(driverQuery)),
					onlineCount: onOnlineEmployees.length,
					onShiftAndOfflineCount: onShiftAndOfflineEmployees.length,
					onShiftCount: onShiftEmployees.length,
					offShiftCount: offShiftEmployees.length
				};
				CrossApplicationService.emit('employee_list_employees_updated', $scope.displayedEmployees);
			};

			$scope.$on(
				'employees list update',
				_.throttle(function () {
					Employees.drivers({}, function (result) {
						$scope.employees = result;
						refreshDisplayedEmployees();
					});
				}, DEFAULT_RT_THROTTLE)
			);

			/**
			 *  end of handle employees updates
			 */

			$scope.sort = {
				column: 'id',
				descending: true,
				index: 0
			};

			$scope.selectedCls = function (columnIdx) {
				if (columnIdx === $scope.sort.index) {
					if ($scope.sort.descending) {
						return '&#9660;';
					} else {
						return '&#9650;';
					}
				}
			};

			$scope.$watch(Authentication.isLoggedIn, function () {
				if ($rootScope.canLogin) {
					if ($routeParams.employee) {
						var routeEmployeeId = parseInt($routeParams.employee, 10);

						var oneTimeEmployeesWatch = $scope.$watch('employees', function () {
							if (_.isUndefined($scope.employees)) {
								return;
							}
							oneTimeEmployeesWatch();
							Employees.drivers({}, function (employees) {
								refreshDisplayedEmployees();
								employees.forEach(function (employee) {
									if (employee.id === routeEmployeeId) {
										$scope.pickEmployee(employee);
										return;
									}
								});
							});
						});

						// In react we set the current filter to on-shift
					} else {
						$scope.unpickEmployee();
					}

					$scope.merchantConfiguration = MerchantConfigurations;

					$scope.$on('config updated', function () {
						$scope.merchantConfiguration = MerchantConfigurations;
					});
				}
			});

			var resizeMapAndCanvas = function () {
				if (_.isFunction($scope.grid.resizeCanvas)) {
					$scope.grid.resizeCanvas();
				}
				var gridHeight = $('#table_container').height();
				var pageHeight = $('#dispatch-page').height();
				var result = pageHeight - gridHeight - 170;
				$('#map_resizeable').height(result);
				$('#map_canvas').height(result);
			};
			// resize grid canvas when height has changed
			$scope.$on('angular-resizable.resizing', function (event, args) {
				if (args.height) {
					resizeMapAndCanvas();
				}
			});

			// resize on window resize
			angular.element($window).on('resize', resizeMapAndCanvas);

			$scope.init_map = function () {
				$('#map_canvas').show();
				resizeMapAndCanvas();
			};

			/**
			 *
			 * @param employee employee to pick
			 * @param updateUrl whether to update the url with the selected employee
			 *                  this can cause flickering
			 */
			$scope.pickEmployee = function (employee, updateUrl = true) {
				event_triggerring = true;
				if (updateUrl) {
					$location.search({ employee: employee.id });
				}
				$scope.selectedEmployee = employee;
				CrossApplicationService.emit('employee_list_selected_employee_updated', $scope.selectedEmployee);

				$scope.$broadcast('employeeSelected', employee);
				$scope.$emit('employeeSelected', employee);
				event_triggerring = false;
			};

			$scope.employeeFilterTypes = {
				all: { filter: null, label: $translate.instant('MAP.ALL_DRIVERS') },
				online: { filter: $scope._onlineEmployeeFilter, label: $translate.instant('MAP.ONLINE') },
				on_shift: { filter: $scope._onShiftEmployeeFilter, label: $translate.instant('MAP.ON_SHIFT') },
				on_shift_offline: {
					filter: $scope._offlineAndOnShitEmployeeFilter,
					label: $translate.instant('MAP.ON_SHIFT_AND_OFFLINE')
				},
				off_shift: { filter: $scope._offShiftEmployeeFilter, label: $translate.instant('MAP.OFF_SHIFT') }
			};

			$scope.driverListFilterByOnShift = function () {
				return (
					$scope.displayDriversFilterLabel === $scope.employeeFilterTypes['on_shift_offline'].label ||
					$scope.displayDriversFilterLabel === $scope.employeeFilterTypes['on_shift'].label
				);
			};

			function createFilter(availabilityFilter) {
				// To avoid getting all the tasks everytime
				const employeesArrayToOpenTasks = new WeakMap();

				function predicate(employee, allEmployees, openTasks) {
					if (!availabilityFilter && !$scope.employeeAvailabilityFilter) {
						return true;
					}

					if (availabilityFilter && !availabilityFilter(employee)) {
						return false;
					}

					if (!$scope.employeeAvailabilityFilter) {
						return true;
					}

					openTasks = openTasks || employeesArrayToOpenTasks.get(allEmployees);

					if (!openTasks) {
						openTasks = TasksData.getOpenTasks();
						employeesArrayToOpenTasks.set(allEmployees, openTasks);
					}

					return $scope.employeeAvailabilityFilter === 'has_tasks'
						? openTasks.some(task => task.user_id === employee.id)
						: openTasks.every(task => task.user_id !== employee.id);
				}

				predicate.recreate = function () {
					return createFilter(availabilityFilter);
				};

				return predicate;
			}

			function changeEmployeesFilterType(employeeFilterType) {
				var filter = $scope.employeeFilterTypes.all.filter,
					label = $scope.employeeFilterTypes.all.label;

				if (!_.isUndefined($scope.employeeFilterTypes[employeeFilterType])) {
					filter = $scope.employeeFilterTypes[employeeFilterType].filter;
					label = $scope.employeeFilterTypes[employeeFilterType].label;
				}

				const firstTime = $scope.displayDriversFilter === undefined;
				$scope.displayDriversFilter = createFilter(filter);
				$scope.displayDriversFilterLabel = label;
				if (!firstTime) {
					$scope.unpickEmployee();
				}
				refreshDisplayedEmployees();
			}

			function changeEmployeesAvailabilityStateFilter(taskStateFilter) {
				$scope.employeeAvailabilityFilter = taskStateFilter;
				if ($scope.displayDriversFilter && $scope.displayDriversFilter.recreate) {
					$scope.displayDriversFilter = $scope.displayDriversFilter.recreate();
				} else {
					$scope.displayDriversFilter = createFilter();
				}
				refreshDisplayedEmployees();
			}

			$scope.onRowSelect = function () {
				var selectedItem = _.first($scope.selectedItems);
				$scope.$broadcast('dispatchMapTaskSelected', selectedItem);
			};

			$scope.onGridInited = function () {
				$scope.$on(
					'task list update',
					_.throttle(function () {
						const newDataFunc = Task.shouldUseNewApi() && TasksData.getOpenTasks;
						$scope.grid.refreshData(newDataFunc);
					}, DEFAULT_RT_THROTTLE)
				);
				$scope.grid.refreshFilters();
			};

			$scope.init = function () {
				var featureFlagsPromise = Authentication.featureFlags();
				var promises = [
					Employees.drivers(),
					featureFlagsPromise,
					SkillsService.getAllSkills(),
					ManagedAttributesService.loadAttributes()
				];

				featureFlagsPromise.then(function () {
					const featurePromises = [];
					const presetEnabled = PresetViewsService.enabledForConfigKey('map-task-list');

					if (CustomAttributesService.attributesEnabled) {
						featurePromises.push(CustomAttributesService.getAll());
					}
					if (presetEnabled) {
						featurePromises.push(PresetViewsService.getPresetsReadyPromise());
					}

					if (featurePromises.length) {
						return Promise.allSettled(featurePromises).then(function () {
							presetEnabled ? $scope.updateViewWithPresetConfig() : $scope.$broadcast('columns updated');
						});
					}
				});

				$q.all(promises).then(function (values) {
					$scope.employees = values[0];
					// TODO - should listen in react for employee list update realtime instead

					refreshDisplayedEmployees();

					$scope.emptyFilteringEnabled = values[1].empty_filtering;
					SkillsService.setSkillsById(values[2]);

					$scope.inited = true;
				});
			};

			$scope.updateViewWithPresetConfig = function () {
				const preset = PresetViewsService.getPreset('map-task-list');
				if (preset) {
					refreshFiltersOnFiltersChange(preset.filters, true);
					$scope.$broadcast('columns updated');
				}
			};

			CrossApplicationService.on('FILTER_CHANGE', refreshFiltersOnFiltersChange);
			CrossApplicationService.on('employee_filter_change', filterEmployees);
			CrossApplicationService.on('change_employee_state_filter', changeEmployeesFilterType);
			CrossApplicationService.on(
				'change_employee_availability_state_filter',
				changeEmployeesAvailabilityStateFilter
			);
			CrossApplicationService.on('employee_list_register', employeeListRegister);
			CrossApplicationService.on('employee_list_driver_got_home', $scope.driverGotHome);
			CrossApplicationService.on('employee_list_driver_left_home', $scope.driverLeftHome);
			CrossApplicationService.on('employee_list_pick_employee', $scope.pickEmployee);
			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope.updateViewWithPresetConfig);

			// cleaner
			$scope.$on('$destroy', function () {
				CrossApplicationService.off('FILTER_CHANGE', refreshFiltersOnFiltersChange);
				CrossApplicationService.off('employee_filter_change', filterEmployees);
				CrossApplicationService.off('change_employee_state_filter', changeEmployeesFilterType);
				CrossApplicationService.off(
					'change_employee_availability_state_filter',
					changeEmployeesAvailabilityStateFilter
				);
				CrossApplicationService.off('employee_list_register', employeeListRegister);
				CrossApplicationService.off('employee_list_driver_got_home', $scope.driverGotHome);
				CrossApplicationService.off('employee_list_driver_left_home', $scope.driverLeftHome);
				CrossApplicationService.off('employee_list_pick_employee', $scope.pickEmployee);
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, $scope.updateViewWithPresetConfig);
			});

			$scope.init();
		}
	);
