'use strict';

angular
	.module('bringgApp')
	.controller(
		'FixGeocodingController',
		function (
			$scope,
			$q,
			$translate,
			$uibModal,
			NgMap,
			Tasks,
			Teams,
			Waypoint,
			MerchantService,
			wayPoints,
			$uibModalInstance,
			TasksData
		) {
			var merchant, map, geocoder, selectedWayPointTask;

			$scope.close = function () {
				$uibModalInstance.dismiss(null);
			};

			$scope.getMapCenter = function () {
				// no selected way point
				if (!selectedWayPointTask) {
					// default fallback value
					return [0, 0];
				}

				var teamId = _.first(selectedWayPointTask.team_ids);
				var team = Teams.teamsByIdMap[teamId];
				//  return default Merchant if no such team or no team id
				if (!team || !team.lat || !team.lng) {
					return [merchant.lat, merchant.lng];
				}

				return [team.lat, team.lng];
			};

			$scope.setMapBounds = function (includeWayPoint) {
				var teamMerchantCoordinates = $scope.getMapCenter();
				var teamMerchantPosition = new google.maps.LatLng(
					teamMerchantCoordinates[0],
					teamMerchantCoordinates[1]
				);

				if (!$scope.showMarker || !includeWayPoint) {
					map.setZoom(14);
					map.setCenter(teamMerchantPosition);
					return;
				}

				var bounds = new google.maps.LatLngBounds();

				bounds.extend(teamMerchantPosition);
				bounds.extend(new google.maps.LatLng($scope.markerLat, $scope.markerLng));

				map.fitBounds(bounds);
				$scope.mapCenterDirty = false;
			};

			$scope.resolvedWayPoint = function (wayPoint) {
				return _.isNumber(wayPoint.lat) && _.isNumber(wayPoint.lng);
			};

			$scope.selectWaypoint = function (wayPoint) {
				if ($scope.selectedWaypoint && $scope.waypointDirty) {
					var confirmModal = $uibModal.open({
						backdrop: 'static',
						keyboard: true,
						size: 'sm',
						windowClass: 'fix-waypoints-confirm-save',
						templateUrl: 'scripts/features/geocoding/fix-waypoint-confirm-save.html'
					});

					confirmModal.result.then(
						function () {
							// close
							$scope.updateWaypoint(true);
							$scope.selectWaypoint(wayPoint);
						},
						function () {
							// dismiss
							confirmModal.close();
							$scope.waypointDirty = false;
							$scope.selectWaypoint(wayPoint);
						}
					);
					return;
				}

				if (!wayPoint) {
					$scope.selectedWaypoint = null;
					$scope.editData = {};
					$scope.showMarker = false;
					return;
				}

				$scope.selectedWaypoint = wayPoint;
				selectedWayPointTask = $scope.tasksByWaypointId.get(wayPoint.id);
				$scope.resetWaypoint();
			};

			$scope.findAddress = _.debounce(function () {
				geocoder.geocode({ address: $scope.editData.address }, function (locations) {
					if (locations && locations.length) {
						var location = locations[0];
						$scope.markerLat = location.geometry.location.lat();
						$scope.markerLng = location.geometry.location.lng();
						$scope.showMarker = true;
						$scope.editData.lat = $scope.markerLat;
						$scope.editData.lng = $scope.markerLng;
						$scope.waypointDirty = true;

						$scope.$digest();

						map.setZoom(16);
						map.setCenter(new google.maps.LatLng($scope.markerLat, $scope.markerLng));
					} else {
						$scope.markerLat = null;
						$scope.markerLng = null;
						$scope.showMarker = false;
						$scope.editData.lat = $scope.markerLat;
						$scope.editData.lng = $scope.markerLng;
						$scope.waypointDirty = true;
					}
				});
			}, 100);

			$scope.resetWaypoint = function () {
				$scope.showMarker = false;
				$scope.waypointDirty = false;
				$scope.editData = _.pick($scope.selectedWaypoint, [
					'id',
					'task_id',
					'address',
					'address_second_line',
					'city',
					'borough',
					'zipcode',
					'lat',
					'lng'
				]);

				$scope.setMapBounds();
			};

			$scope.updateWaypoint = function (doNotGoToUpdated) {
				if (!$scope.selectedWaypoint) {
					return;
				}

				$scope.waypointDirty = false;

				// update the selected way point with the edit data
				// don't override in case of the Waypoint.update operation fails
				var wayPointUpdate = _.extend({}, $scope.selectedWaypoint, $scope.editData);

				// set the id
				wayPointUpdate = _.defaults({ id: $scope.selectedWaypoint.id }, wayPointUpdate);

				Waypoint.update({ task_id: $scope.selectedWaypoint.task_id }, wayPointUpdate, function (response) {
					if (response.success) {
						// update teh current way point.
						var wayPointIdx = _.findIndex($scope.wayPoints, function (wayPoint) {
							return wayPoint.id == response.way_point.id;
						});

						$scope.wayPoints[wayPointIdx] = response.way_point;

						if (!doNotGoToUpdated) {
							$scope.selectWaypoint($scope.wayPoints[wayPointIdx]);
						}
					}
				});
			};

			$scope.centerChanged = function () {
				$scope.mapCenterDirty = true;
			};

			$scope.zoomChanged = function () {
				$scope.mapCenterDirty = true;
			};

			function mapClick(event) {
				if (!$scope.selectedWaypoint) {
					return;
				}
				$scope.markerLat = event.latLng.lat();
				$scope.markerLng = event.latLng.lng();
				$scope.showMarker = true;
				$scope.editData.lat = $scope.markerLat;
				$scope.editData.lng = $scope.markerLng;
				$scope.waypointDirty = true;
				geocoder.geocode({ latLng: event.latLng }, function (locations) {
					if (locations && locations.length) {
						$scope.editData.address = locations[0].formatted_address;
					}
					$scope.$digest();
				});
			}

			$scope.getExternalId = function (wayPointId) {
				var task = $scope.tasksByWaypointId.get(wayPointId);
				if (!task || !task.external_id) {
					return;
				}
				return task.external_id;
			};

			function init() {
				$scope.showMarker = false;
				$scope.editData = {};
				$scope.waypointDirty = false;
				$scope.wayPoints = wayPoints;
				$scope.tasksByWaypointId = new Map();

				geocoder = new google.maps.Geocoder();

				$q.all([MerchantService.get(), NgMap.getMap('waypoints-map')]).then(function (results) {
					merchant = results[0];

					map = results[1];

					if (!_.isEmpty($scope.wayPoints)) {
						$scope.selectWaypoint(_.first($scope.wayPoints));
					}

					google.maps.event.addListener(map, 'click', mapClick);

					$scope.$watch(
						'editData',
						function (newEdit, oldEdit) {
							// if this is the same as initial watch
							// or just way point changed  - ignore
							if (newEdit === oldEdit || newEdit.id !== oldEdit.id) return;

							$scope.findAddress();
						},
						true
					);
				});

				_.forEach(wayPoints, function (wayPoint) {
					$scope.tasksByWaypointId.set(wayPoint.id, TasksData.get(wayPoint.task_id));
				});

				$scope.$on('$destroy', function () {
					google.maps.event.clearInstanceListeners(map);
				});
			}

			init();
		}
	);
