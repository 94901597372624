'use strict';

angular
	.module('bringgApp')
	.controller('CSVUploadModalController', function (
		$scope,
		$uibModalInstance,
		csvUploadPath,
		csvTestUploadPath,
		importedResult
	) {
		$scope.csvUploadPath = csvUploadPath;
		$scope.csvTestUploadPath = csvTestUploadPath;
		$scope.importedResult = importedResult;

		$scope.isUploading = false;

		$scope.close = function () {
			$uibModalInstance.close();
		};
	});
