'use strict';

angular
	.module('bringgApp')
	.constant('LOOPING_ALERT_SOUND_TYPES', {
		UNASSIGNED: 0,
		UNACKNOWLEDGED: 1
	})
	.factory(
		'LoopingSoundNotificationService',
		function (
			$rootScope,
			$interval,
			MerchantConfigurations,
			SoundPlayerService,
			LOOPING_ALERT_SOUND_TYPES,
			SOUND_TYPE,
			$log,
			Authentication,
			$injector,
			TasksData
		) {
			var LoopingSoundNotificationService = {};

			LoopingSoundNotificationService._intervalMs = 5500;

			// hacky way to make sure this service does not load Tasks before init - sorry.
			var Tasks = null;

			LoopingSoundNotificationService._loop = function (check) {
				// interval set to 5500 duo to audio file length is 5000
				$interval(function () {
					check(function (loopingAlertSoundType) {
						SoundPlayerService.playSoundAt(loopingAlertSoundType);
					});
				}, LoopingSoundNotificationService._intervalMs);
			};

			LoopingSoundNotificationService._unassignedCheck = function (callback) {
				if (_.filter(TasksData.getOpenTasks(), { user_id: null }).length > 0) {
					return callback(SOUND_TYPE.DOOR_BELL_TWICE);
				}
			};

			LoopingSoundNotificationService._unacknowledgedCheck = function (callback) {
				if (TasksData.getUnacknowledged().length > 0) {
					return callback(SOUND_TYPE.DOOR_BELL_TWICE);
				}
			};

			LoopingSoundNotificationService.init = function () {
				Tasks = Tasks || $injector.get('Tasks');
				return Authentication.featureFlags().then(function () {
					if (!MerchantConfigurations.enable_looping_alert_sound) {
						$log.info('looping sound alert is off');
						return;
					}

					if (MerchantConfigurations.looping_alert_sound_type === LOOPING_ALERT_SOUND_TYPES.UNASSIGNED) {
						$log.info('looping sound alert is on and alert type is unassigned');

						return LoopingSoundNotificationService._loop(LoopingSoundNotificationService._unassignedCheck);
					}
					if (MerchantConfigurations.looping_alert_sound_type === LOOPING_ALERT_SOUND_TYPES.UNACKNOWLEDGED) {
						$log.info('looping sound alert is on and alert type is unacknowledgedCheck');

						return LoopingSoundNotificationService._loop(
							LoopingSoundNotificationService._unacknowledgedCheck
						);
					}
				});
			};

			$rootScope.$watch(
				function () {
					return Authentication.isLoggedIn;
				},
				function watchCallback(newValue, oldValue) {
					if (newValue()) {
						LoopingSoundNotificationService.init();
					}
				}
			);

			return LoopingSoundNotificationService;
		}
	);
