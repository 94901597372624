'use strict';

angular
	.module('bringgApp')
	.constant('CROSS_APP_ACTIONS', {
		DYNAMIC_FORM_MODAL_OPEN: 'open-dynamic-form',
		USER_SETTINGS_UPDATE_LANG: 'update_user_language',
		SDK_UPDATED: 'sdk updated',
		RENDER_TASKS_CSV_IMPORTER_V2: 'open_tasks_csv_importer_v2',
		NEW_TASK_CSV: 'new_task_csv',
		SHOW_RUNS_PARTIAL_ACTIONS_MODAL: 'show_runs_partial_actions_modal',
		SHOW_RUNS_PARTIAL_ACTIONS_MODAL_RESPONSE: 'show_runs_partial_actions_modal_response',
		RUN_USER_ASSIGNMENT: 'run_user_assignment',
		FLEET_ASSIGNMENT: 'fleet_assignment',
		RUN_SELECT_DRIVER_TEAM: 'select_driver_team',
		RUN_VEHICLE_ASSIGNMENT: 'run_vehicle_assignment',
		OPEN_TASKS_ACTIVITY_LOG: 'open_tasks_activity_logs',
		CONFIGURE_SSO_MODAL: 'configure_sso_modal',
		ADD_EMPLOYEE_MODAL: 'add_employee_modal',
		RUN_EDITOR_MODAL: 'run_editor_modal',
		DRIVER_CREATED: 'driver_created',
		OPEN_OPTIMIZATION_MODAL: 'open_optimization_modal',
		OPEN_ROUTES_PLANNER_MODAL: 'open_routes_planner_modal',
		OPEN_ROUTES_PLANNER_MODAL_MANUALLY: 'open_routes_planner_modal_manually',
		OPEN_OPTIMIZATION_ERROR_MODAL: 'open_optimization_error_modal',
		PRINT_LABEL_REQUEST: 'print_label_request',
		MERCHANT_CONFIGURATION_UPDATE: 'merchant_configuration_update',
		CUSTOM_ATTRIBUTES_SET: 'custom_attributes_set',
		MANUAL_TRIGGER_CLICKED: 'manual_trigger_clicked',
		GENERATE_COLLAPSE_LINKED_TASKS_SERVICE: 'generate_collapse_linked_tasks_service',

		// TODO: after FF removed only SUBMIT_TICKET_MODAL should be left, tasks for context https://bringg.atlassian.net/browse/BRNGG-23168, https://bringg.atlassian.net/browse/BRNGG-22152
		SUBMIT_TICKET: 'submit_ticket', // trigger show modal action from React, can show Angular or new React modal based on FF "enable_new_support_ticket"
		SUBMIT_TICKET_MODAL: 'submit_ticket_modal', // actually show new React modal.
		PRESET_VIEWS_UPDATED: 'preset_views_updated',
		PRESET_VIEWS_INITIATED: 'preset_views_initiated',
		OPEN_TASK_CREATION_MODAL: 'open_task_creation_modal',
		OPEN_REASON_TO_CANCEL_TASK_MODAL: 'open_reason_to_cancel_task_modal',
		CUSTOMER_APP_CONFIGURATION_UPDATED: 'customer_app_configuration_updated'
	})
	.factory('CrossApplicationService', function (BringgSDK) {
		function crossApplicationService(method) {
			return function () {
				var args = arguments;
				return BringgSDK.getInstancePromise().then(function (bringg) {
					return bringg.crossAppTransport[method].apply(bringg.crossAppTransport, args);
				});
			};
		}

		return {
			on: crossApplicationService('on'),
			emit: crossApplicationService('emit'),
			off: crossApplicationService('off')
		};
	})
	.factory('ReactModals', function (CrossApplicationService, CROSS_APP_ACTIONS, $q, Authentication) {
		var ReactModals = {};

		ReactModals.openDynamicForm = function (title, formFields, onSubmit, okText) {
			CrossApplicationService.emit(
				CROSS_APP_ACTIONS.DYNAMIC_FORM_MODAL_OPEN,
				title,
				formFields,
				onSubmit,
				okText
			);
		};

		ReactModals.openTasksCsvImporter = function () {
			const showNewModal = Authentication.currentUser().feature_flags.new_csv_upload;

			CrossApplicationService.emit(
				showNewModal ? CROSS_APP_ACTIONS.NEW_TASK_CSV : CROSS_APP_ACTIONS.RENDER_TASKS_CSV_IMPORTER_V2
			);
		};

		ReactModals.openTasksActivityLog = function () {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_TASKS_ACTIVITY_LOG);
		};

		ReactModals.openRunUserAssigner = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.RUN_USER_ASSIGNMENT, params);
		};

		ReactModals.openFleetAssigner = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.FLEET_ASSIGNMENT, params);
		};

		ReactModals.openSelectDriverTeam = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.RUN_SELECT_DRIVER_TEAM, params);
		};

		ReactModals.openRunVehicleAssigner = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.RUN_VEHICLE_ASSIGNMENT, params);
		};

		ReactModals.openSSOConfigurationModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.CONFIGURE_SSO_MODAL, params);
		};

		ReactModals.openEmployeeModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.ADD_EMPLOYEE_MODAL, params);
		};

		ReactModals.openNewOptimizationModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_OPTIMIZATION_MODAL, params);
		};

		ReactModals.openRoutesPlannerModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_ROUTES_PLANNER_MODAL, params);
		};

		ReactModals.openRoutesPlannerModalManually = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_ROUTES_PLANNER_MODAL_MANUALLY, params);
		};

		ReactModals.openOptimizationErrorModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_OPTIMIZATION_ERROR_MODAL, params);
		};

		ReactModals.openPartialRunsActionsModal = function (partialRunsSource) {
			return $q(function (resolve) {
				CrossApplicationService.emit(CROSS_APP_ACTIONS.SHOW_RUNS_PARTIAL_ACTIONS_MODAL, { partialRunsSource });

				CrossApplicationService.on(
					CROSS_APP_ACTIONS.SHOW_RUNS_PARTIAL_ACTIONS_MODAL_RESPONSE,
					function (response) {
						CrossApplicationService.off(CROSS_APP_ACTIONS.SHOW_RUNS_PARTIAL_ACTIONS_MODAL_RESPONSE);
						resolve(response);
					}
				);
			});
		};

		ReactModals.openRunEditorModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.RUN_EDITOR_MODAL, params);
		};

		ReactModals.openSubmitTicketModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.SUBMIT_TICKET_MODAL, params);
		};

		ReactModals.openCreateTaskModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_TASK_CREATION_MODAL, params);
		};

		ReactModals.openReasonToCancelTaskModal = function (params) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.OPEN_REASON_TO_CANCEL_TASK_MODAL, params);
		};

		return ReactModals;
	})
	.factory('ReactActions', function (CrossApplicationService, CROSS_APP_ACTIONS, $q) {
		var ReactActions = {};

		ReactActions.manualTriggerClicked = function (trigger, selectedTaskIds) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.MANUAL_TRIGGER_CLICKED, {
				trigger,
				selectedTaskIds
			});
		};

		return ReactActions;
	})
	.factory('ReactUserSettings', function (CrossApplicationService, CROSS_APP_ACTIONS) {
		var ReactUserSettings = {};

		ReactUserSettings.setLanguage = function (language) {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.USER_SETTINGS_UPDATE_LANG, language);
		};

		return ReactUserSettings;
	})
	.factory('ReactSdkActions', function (CrossApplicationService, CROSS_APP_ACTIONS) {
		var ReactSdkActions = {};

		ReactSdkActions.updateSdk = function () {
			CrossApplicationService.emit(CROSS_APP_ACTIONS.SDK_UPDATED);
		};

		return ReactSdkActions;
	});
