'use strict';

// see here : http://jsfiddle.net/phaas/8jFpV/
// this is not done!!!!
angular.module('bringgApp.directives').directive('searchphrase', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attr, ngModel) {
			function fromUser(text) {
				return (text || '').toUpperCase();
			}

			function toUser(searchObject) {
				if (!searchObject) {
					return '';
				}

				var objectStringified = '',
					newObject = searchObject;

				while (!_.empty(_.keys(newObject))) {
					objectStringified += _.keys(searchObject)[0] + '.';
					newObject = objectStringified[_.keys(searchObject)[0]];
				}
				objectStringified += newObject; // last key
				return objectStringified || '';
			}

			ngModel.$parsers.push(fromUser);
			ngModel.$formatters.push(toUser);
		}
	};
});
