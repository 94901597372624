'use strict';

angular.module('bringgApp').factory('PhoneNumberService', function (Authentication) {
	const getService = () => {
		return window._bringg?.phoneNumberService;
	};

	const phoneNumberFormattingEnabled = () => {
		return Boolean(_.get(Authentication.currentUser()?.feature_flags, 'enable_phone_number_formatting'));
	};

	const formatPhoneNumber = phoneNumber => {
		const phoneNumberService = getService();

		if (phoneNumberFormattingEnabled() && phoneNumber && phoneNumberService) {
			return phoneNumberService.formatPhoneNumber(phoneNumber);
		}

		return phoneNumber;
	};

	return {
		formatPhoneNumber
	};
});
