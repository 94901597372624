'use strict';

angular.module('bringgApp.directives').directive('printerType', function ($translate) {
	return {
		restrict: 'E',
		scope: {
			type: '=',
			isDisabled: '='
		},
		template:
			'<select id="printerType" ng-disabled="isDisabled" class="form-control" ng-model="type" ng-options="printerType.value as printerType.name for printerType in printerTypes"></select>',
		link: function (scope) {
			scope.printerTypes = [
				{ name: $translate.instant('PRINTER_TYPE.DEFAULT'), value: '' },
				{ name: $translate.instant('PRINTER_TYPE.3_INCH'), value: '3inch' },
				{ name: $translate.instant('PRINTER_TYPE.2_INCH'), value: '2inch' }
			];
		}
	};
});
