'use strict';

angular.module('bringgApp').directive('dhlTaskInvoice', function ($translate, $timeout) {
	return {
		restrict: 'E',
		scope: {
			tasks: '=',
			config: '=',
			printedCopies: '=',
			printCallback: '='
		},
		link: function (scope, element, attrs) {
			$timeout(scope.printCallback, 100, true, element);
		},
		templateUrl: 'scripts/features/print/invoices/dhl-task-invoice.html'
	};
});
