'use strict';

angular
	.module('bringgApp.directives')
	.directive(
		'impersonate',
		function (Authentication, $window, localStorageService, Teams, MerchantConfigurations, $rootScope) {
			return {
				templateUrl: 'scripts/directives/impersonate/impersonate.html',
				restrict: 'E',
				scope: true,
				link: function (scope) {
					$rootScope.$on('loggedin', function () {
						scope.inImpersonateMode =
							Authentication.currentUser() && Authentication.currentUser().original_user_id;
						scope.bannerRemoved = false;

						if (scope.inImpersonateMode) {
							Teams.getAll().then(function (teams) {
								scope.teams = teams;
							});
						}
					});

					$rootScope.$on('loggedout', function () {
						scope.inImpersonateMode = false;
						scope.bannerRemoved = false;
					});

					scope.returnToOriginalUser = function () {
						Authentication.returnToOriginalUser();
						localStorageService.set('init_route', '/support/');
						MerchantConfigurations.$refresh().then(function () {
							$window.location.reload();
						});
					};

					scope.removeBanner = function () {
						scope.bannerRemoved = true;
					};
				}
			};
		}
	);
