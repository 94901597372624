'use strict';

angular.module('bringgApp').constant('OPTIMIZATION_REASONS', {
	1: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.REASON_NOT_FOUND',
	2: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.OUT_OF_MAX_DISTANCE',
	3: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NO_DRIVER_IN_TASKS_TIME_WINDOW',
	4: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.LOAD_CONSTRAINS',
	5: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.TIME_CONSTRAINTS',
	6: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.SKILLS_CONSTRAINT',
	7: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.MAX_TIME_IN_VEHICLE_CONSTRAINT',
	8: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.EXCLUDED_FROM_ALL_DRIVERS',
	9: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.DRIVER_THAT_TASK_ASSIGN_TO_NOT_FOUND_IN_OPTIMIZATION',
	10: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.ACTIVE_TASK_NOT_ASSIGNED_TO_DRIVER',
	11: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.BREAK_AFTER_ROUTE_ENDS',
	12: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.BREAK_BEFORE_ROUTE_STARTS',
	13: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.ILLEGAL_COORDINATE',
	14: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.TASK_ASSIGNED_TO_DRIVER_THAT_REJECTED_THE_TASK',
	15: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.TASK_CAN_BE_SERVED_BY_MORE_THAN_ONE_DRIVER_FROM_OTHER_GROUPS',
	16: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.INVALID_CAPACITY_DIMENSIONS_FOR_TASK',
	17: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NEGATIVE_CAPACITY_REQUIREMENTS',
	18: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NEGATIVE_MAX_TIME_IN_VEHICLE',
	19: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.MAX_TIME_IN_VEHICLE_NOT_SUPPORTED_FOR_JOB_TYPE',
	20: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NEGATIVE_SERVICE_TIME',
	21: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NEGATIVE_LOADING_TIME',
	22: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.INVALID_PRIORITY',
	23: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.EXCEED_ALL_DRIVERS_ALLOWED_MAXIMAL_LOAD',
	24: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.EXCEED_ALL_DRIVERS_CONTAINERS',
	25: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.NO_DRIVER_WITH_REQUIRED_SKILLS',
	26: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.BREAK_NOT_IN_DRIVER_TIME_WINDOW',
	27: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.FAILED_TO_PACK',
	28: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.ROUTE_CONSTRAINT',
	29: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.DRIVING_TIMES_LIMITATIONS',
	30: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.DRIVING_TIMES_LIMITATIONS_BY_ACTIVITIES',
	31: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.FAILED_TO_FIND_DRIVER_FOR_CLUSTER',
	32: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.FAILED_TO_INSERT_RELATED_JOB',
	33: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.RELATED_JOB_NOT_IN_REQUEST',
	34: 'ROUTE_OPTIMIZATION_UNASSIGNED_REASON.FAILED_TO_INSERT_DUE_TO_ROUTING_AREA'
});
