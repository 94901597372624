'use strict';
/*global ga:false */
/*global JustGage:false */

angular
	.module('bringgApp')
	.filter('dashboard_filter', function ($filter, TimeManagerService) {
		return function (value, type) {
			switch (type) {
				case 'integer':
					return $filter('number')(value);
				case 'currency':
					return $filter('currency')(value);
				case 'duration':
					return TimeManagerService.format(moment.utc(parseInt(value || 0, 10) * 1000), 'HH:mm:ss').replace(
						/^00:/,
						''
					);
				case 'percentage':
					return $filter('number')(value * 100, 1).replace(/\.0+$/, '') + '%';
				default:
					return value;
			}
		};
	})
	.filter('dashboard_format_minutes', function (TimeManagerService) {
		return function (seconds) {
			var prefix = seconds < -60 ? '-' : '';
			var duration = moment.duration(Math.abs(seconds), 'seconds');
			if (duration.seconds() > 30) {
				duration.add(1, 'minute').subtract(duration.seconds(), 'seconds');
			}
			return prefix + TimeManagerService.format(duration, 'hh:mm', { trim: false });
		};
	})
	.filter('dashboard_number_to_hour', function (TimeManagerService) {
		return function (hour) {
			return TimeManagerService.format(moment(hour, ['hh']), 'hA');
		};
	})
	.controller('DashboardController', function (
		$scope,
		$interval,
		$filter,
		DashboardService,
		DashboardResource,
		ALL_TEAMS_CHOICE_ID,
		$log,
		$timeout,
		toastr
	) {
		var POLLING_INTERVAL = 5 * 60 * 1000;

		var asChartType = function (widgetType) {
			switch (widgetType) {
				case DashboardService.WIDGET_TYPE.LINE:
					return 'Line';
				case DashboardService.WIDGET_TYPE.BARS:
					return 'Bar';
				case DashboardService.WIDGET_TYPE.PIE:
					return 'Pie';
				default:
					return null;
			}
		};

		$scope.widgets = [];
		$scope.selectedTeam = null;

		function _init() {
			DashboardResource.getWidgetsStructure()
				.then(function (widgets) {
					$scope.widgets = _.map(widgets, function (widget) {
						return Object.assign({}, widget, { wasDataFetched: false });
					});
					$scope.widgetsStructure = Object.assign({}, $scope.widgets);
					$scope.reload();
				})
				.catch(function (error) {
					toastr.error('Failed to fetch widgets structure', error.details);
				});
		}

		$scope.onTeamSelected = function (selectedTeam) {
			if (selectedTeam.id == ALL_TEAMS_CHOICE_ID) {
				$scope.selectedTeam = null;
			} else {
				$scope.selectedTeam = selectedTeam.id;
			}

			$scope.reload();
		};

		$scope.isChart = function (widget) {
			return _.isString(widget.chartType);
		};

		$scope.isGauge = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.GAUGE;
		};

		$scope.isTable = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.TABLE;
		};

		$scope.isVerticalTable = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.VERTICAL_TABLE;
		};

		$scope.isCounter = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.COUNTER;
		};

		$scope.isReflectingGauge = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.REFLECTING_GAUGE;
		};

		$scope.isPreparationStatusWidget = function (widget) {
			return widget.type === DashboardService.WIDGET_TYPE.PREPARATION_STATUS;
		};

		$scope.loadChartWidget = function (index, widget) {
			function getData(widget) {
				var data = [];
				for (var i = 1; i !== widget.column_definitions.length; i++) {
					var values = _.chain(widget.values).map(widget.column_definitions[i].field).map(parseFloat).value();
					data.push(values);
				}
				if (widget.widget_type === DashboardService.WIDGET_TYPE.PIE) {
					data = data[0]; // Pie chart supports only 1 series
				}
				return data;
			}

			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				data: getData(widget),
				labels: _.map(widget.values, widget.column_definitions[0].field),
				title: widget.title,
				type: widget.chart_type,
				chartType: asChartType(widget.widget_type)
			});
		};

		$scope.loadTableWidget = function (index, widget) {
			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				showColumnFooter: true,
				data: widget.values,
				columnDefs: widget.column_definitions,
				type: widget.widget_type,
				title: widget.title,
				description: widget.description,
				enableHorizontalScrollbar: 0,
				enableVerticalScrollbar: 2
			});
		};

		$scope.loadVerticalTableWidget = function (index, widget) {
			function getData(widget) {
				return widget.column_definitions.map(function (col_def) {
					var data = _.extend(
						{
							value: widget.values[0][col_def.field]
						},
						col_def
					);
					if (_.isArray(data.ranges)) {
						var reflectingGaugeWidget = _.find($scope.widgets, {
							widget_type: DashboardService.WIDGET_TYPE.REFLECTING_GAUGE
						});
						if (reflectingGaugeWidget && !reflectingGaugeWidget.data) {
							reflectingGaugeWidget.data = data;
							if (!_.isUndefined($scope.reflectingGauge)) {
								$scope.loadGauge(reflectingGaugeWidget.data, 'reflecting-gauge', true);
							}
						}
						var value = parseFloat(data.value);
						var range = _.find(data.ranges, function (range) {
							return range[0] <= value && value <= range[1];
						});
						if (range) {
							data.class = range[2];
						}
					}
					return data;
				});
			}

			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				data: getData(widget),
				type: widget.widget_type,
				title: widget.title
			});
		};

		$scope.loadGaugeWidget = function (index, widget) {
			function getData(widget) {
				return parseFloat(widget.values[0][widget.column_definitions[0].field]);
			}

			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				value: getData(widget),
				columns: widget.column_definitions,
				type: widget.widget_type,
				min: widget.min,
				max: widget.max,
				title: widget.title,
				levelColorsGradient: false,
				id: 'just-gage' + index
			});
		};

		$scope.loadCounterWidget = function (index, widget) {
			function getData(widget) {
				var value = parseFloat(widget.values[0][widget.column_definitions[0].field]);

				//show decimals only if there any!!
				return Math.round(value * 100) / 100;
			}

			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				value: getData(widget),
				type: widget.widget_type,
				title: widget.title
			});
		};

		$scope.loadReflectingGauge = function (index, widget) {
			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				type: widget.widget_type,
				id: 'reflecting-gauge' + ($scope.selectedTeam || '')
			});
		};

		$scope.loadPreparationStatusWidget = function (index, widget) {
			$scope.widgets[index] = Object.assign({}, $scope.widgetsStructure[index], {
				type: widget.widget_type,
				value: widget.values,
				title: widget.title,
				description: widget.description,
				id: 'preparation-status-' + index
			});
		};

		$scope.initGauge = function (widget) {
			$scope.loadGauge(
				{
					ranges: widget.columns[0].ranges,
					value: widget.value,
					type: widget.columns[0].field
				},
				widget.id,
				false
			);
		};

		// we need this in ng-init so when the DOM element is ready
		// we can use it to mount the element on it.
		$scope.reflectingGaugeLoaded = function () {
			var reflectingGaugeWidget = _.find($scope.widgets, {
				type: DashboardService.WIDGET_TYPE.REFLECTING_GAUGE
			});
			if (!reflectingGaugeWidget) {
				return;
			}
			$scope.loadGauge(reflectingGaugeWidget.data, 'reflecting-gauge', true);
		};

		$scope.loadGauge = function (row, id, isReflecting) {
			if (!row) {
				return;
			}

			var withOutRanges = !row.ranges;
			var rangesIsNotArray = !_.isArray(row.ranges);
			if (withOutRanges && rangesIsNotArray) {
				return;
			}

			var emptyRanges = row.ranges.length === 0;
			if (emptyRanges) {
				return;
			}

			var min = row.ranges[0][0];
			var max = _.last(row.ranges)[1];
			var value = row.value;

			if (isReflecting) {
				_.each($scope.widgets, function (widget) {
					if ($scope.isReflectingGauge(widget)) {
						widget.title = row.name;
					}
				});

				id += $scope.selectedTeam || '';
			}

			var renderValue = function (value) {
				return $filter('dashboard_filter')(value, row.type);
			};

			var gaugeConfig = {
				id: id,
				value: value,
				min: min,
				minTxt: renderValue(min),
				max: max,
				maxTxt: renderValue(max),
				levelColorsGradient: false,
				textRenderer: renderValue
			};

			$scope.gauge = $scope.gauge || {};
			$timeout(function () {
				// this is here because the justGage refresh will not work when changing teams
				var gaugeElement = angular.element(document.querySelector('#' + id));
				if (gaugeElement) {
					gaugeElement.empty();
				}
				$scope.gauge[id] = new JustGage(gaugeConfig);
			});
		};

		$scope.reload = function () {
			$log.info('$scope.reload called');
			_.forEach($scope.widgetsStructure, function (widget, i) {
				DashboardResource.getWidgetData(widget.id, $scope.selectedTeam).then(function (response) {
					$scope.widgets[i].values = response.values;
					var widget = $scope.widgets[i];
					$log.info('DashboardController, got widget data', widget);
					switch (widget.widget_type) {
						case DashboardService.WIDGET_TYPE.TABLE:
							$scope.loadTableWidget(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.GAUGE:
							$scope.loadGaugeWidget(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.COUNTER:
							$scope.loadCounterWidget(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.LINE:
						case DashboardService.WIDGET_TYPE.BARS:
						case DashboardService.WIDGET_TYPE.PIE:
							$scope.loadChartWidget(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.VERTICAL_TABLE:
							$scope.loadVerticalTableWidget(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.REFLECTING_GAUGE:
							$scope.loadReflectingGauge(i, widget);
							break;
						case DashboardService.WIDGET_TYPE.PREPARATION_STATUS:
							$scope.loadPreparationStatusWidget(i, widget);
							break;
						default:
							break;
					}

					$scope.widgets[i].wasDataFetched = true;
					$scope.widgets[i].updated_at = response.updated_at;
				});
			});
		};

		$log.info('DashboardController init');

		_init();
		$scope.reload();
		var intervalPromise = $interval($scope.reload, POLLING_INTERVAL);

		$scope.$on('$destroy', function () {
			$log.info('DashboardController $destroy - stopping $scope.reload');
			$interval.cancel(intervalPromise);
		});
	});
